import { Box } from '@mui/material'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const Container = (): JSX.Element => {
  return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>{rJSX_PageContent()}</Box>
}

const rJSX_PageContent = (): JSX.Element => {
  const [us_formData, us_setFormData] = useState<any>(null)
  const [us_loading, us_setLoading] = useState(true)
  const [us_error, us_setError] = useState<string | null>(null)
  const [us_formId, us_setFormId] = useState<string>(useParams().fid || '')

  useEffect(() => {
    try {
      // get the form status data
      cloudFunctionUnauthenticatedRequests({
        function: 'getABPDisclosureFormStatus',
        form_id: us_formId,
      })
        .then((res: any) => {
          if (res.data.success) {
            us_setFormData(res.data.data[0])
            us_setLoading(false)
          }
        })
        .catch((err: any) => {
          if (err.response.data) {
            us_setFormData({ ...err.response.data.data[0], error: err.response.data.error_message })
          } else {
            us_setError(err.response.data.message)
          }
          us_setLoading(false)
        })
    } catch (err: any) {
      us_setError(err)
    }
  }, [])

  if (us_loading) {
    return <Box>Loading...</Box>
  }

  if (us_error) {
    return <Box color="error.main">{us_error}</Box>
  }

  if (!us_formData) {
    return <Box>No form data found</Box>
  }

  return (
    <Box
      sx={{
        p: 4,
        maxWidth: 800,
        mx: 'auto',
        backgroundColor: '#fff',
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Box
        sx={{
          'display': 'grid',
          'gap': 3,
          '& strong': {
            color: '#555',
            minWidth: 160,
            display: 'inline-block',
          },
        }}
      >
        <Box sx={{ borderBottom: '1px solid #eee', pb: 2, color: '#555' }}>
          <strong>Form Status:</strong>
          <span>{us_formData.form_status}</span>
        </Box>

        <Box sx={{ color: '#555' }}>
          <strong>Form Name:</strong> {us_formData.disclosure_form_name}
        </Box>

        {/* <Box sx={{ color: '#555' }}>
          <strong>Reference Number:</strong> {us_formData.reference_number}
        </Box> */}

        {us_formData.form_pdf_url && (
          <Box>
            <strong>PDF URL:</strong>{' '}
            <a
              href={us_formData.form_pdf_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#2196f3',
                textDecoration: 'none',
                // '&:hover': {
                //   textDecoration: 'underline',
                // },
              }}
            >
              Download PDF
            </a>
          </Box>
        )}

        <Box sx={{ color: '#555' }}>
          <strong>E-Sign Email:</strong> {us_formData.esign_email}
        </Box>

        {us_formData.esign_sent_date && (
          <Box sx={{ color: '#555' }}>
            <strong>E-Sign Sent Date:</strong> {us_formData.esign_sent_date}
          </Box>
        )}

        <Box sx={{ color: '#555' }}>
          <strong>E-Sign Received Date:</strong> {us_formData.esign_received_date ? us_formData.esign_received_date : 'Not yet received'}
        </Box>

        <Box sx={{ color: '#555' }}>
          <strong>Step Reached:</strong> {us_formData.step_reached}
        </Box>

        {us_formData.error && (
          <Box sx={{ color: '#555', backgroundColor: '#fdd', p: 2, borderRadius: 2 }}>
            <strong>Error:</strong> {us_formData.error}
          </Box>
        )}
      </Box>
    </Box>
  )
}
