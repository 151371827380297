///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Base
export const DatabaseRef_Modules_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main')
}

export const DatabaseRef_Module_Document = (clientKey: string, moduleKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main', moduleKey)
}

export const DatabaseRef_ActiveModules_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_Module_ImportAliases_Document = (clientKey: string, moduleKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'import_aliases', moduleKey)
}

export const DatabaseRef_Module_ImportAliases_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'import_aliases')
}

export const DatabaseRef_Module_ImportAliases_Query = (clientKey: string, moduleKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: moduleKey }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'import_aliases'), queryOperatorsArray, [], {}, null)
}

// Files
export const DatabaseRef_ModuleFiles_Collection = (clientKey: string, moduleKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main', moduleKey, 'files')
}

export const DatabaseRef_ModuleFile_Document = (clientKey: string, moduleKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main', moduleKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_ModuleForumThreads_Collection = (clientKey: string, moduleKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main', moduleKey, 'forum')
}

export const DatabaseRef_ModuleForumThread_Document = (clientKey: string, moduleKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main', moduleKey, 'forum', threadKey)
}

export const DatabaseRef_ModuleForumMessages_Collection = (clientKey: string, moduleKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main', moduleKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_ModuleForumMessage_Document = (
  clientKey: string,
  moduleKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'modules', 'main', moduleKey, 'forum', threadKey, 'messages', messageKey)
}
