///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Base
export const DatabaseRef_Racking_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main')
}

export const DatabaseRef_Racking_Document = (clientKey: string, rackingKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main', rackingKey)
}

export const DatabaseRef_ActiveRacking_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_Racking_ImportAliases_Document = (clientKey: string, rackingKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'import_aliases', rackingKey)
}

export const DatabaseRef_Racking_ImportAliases_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'import_aliases')
}

export const DatabaseRef_Racking_ImportAliases_Query = (clientKey: string, rackingKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: rackingKey }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'import_aliases'), queryOperatorsArray, [], {}, null)
}

// Files
export const DatabaseRef_RackingFiles_Collection = (clientKey: string, rackingKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main', rackingKey, 'files')
}

export const DatabaseRef_RackingFile_Document = (clientKey: string, rackingKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main', rackingKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_RackingForumThreads_Collection = (clientKey: string, rackingKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main', rackingKey, 'forum')
}

export const DatabaseRef_RackingForumThread_Document = (clientKey: string, rackingKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main', rackingKey, 'forum', threadKey)
}

export const DatabaseRef_RackingForumMessages_Collection = (clientKey: string, rackingKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main', rackingKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_RackingForumMessage_Document = (
  clientKey: string,
  rackingKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'racking', 'main', rackingKey, 'forum', threadKey, 'messages', messageKey)
}
