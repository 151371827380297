///////////////////////////////
// Description
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogContent, Toolbar, Typography } from '@mui/material'
import { returnCurrentLegalVersionNumbers, returnEulaText, rJSX_LegalDocument } from 'app/models/legal/legal_documents_version_history'
import { useContext, useState } from 'react'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_EulaDialog {}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

/* eslint-disable react/prop-types */
export const EulaDialog: React.FC<TsInterface_EulaDialog> = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_acceptingTermsOfService, us_setAcceptingTermsOfService] = useState(false)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect

  // Functions

  // JSX Generation

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Dialog
        className="bp_dialog_xl_width"
        keepMounted
        onClose={() => {
          // uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            {/* <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 1, color: '#fff !important' }}
            >
              <Icon icon="signature" />
            </IconButton> */}
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">{rLIB('END USER LICENSE AGREEMENT (EULA)')}</Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box className="tw-p-4">
            {rJSX_LegalDocument(returnEulaText(returnCurrentLegalVersionNumbers()['EULA']))}
            <Box className="tw-text-center">
              <Button
                variant="contained"
                color="info"
                disabled={us_acceptingTermsOfService}
                startIcon={
                  us_acceptingTermsOfService ? (
                    <Icon
                      icon="spinner"
                      type="solid"
                      className="bp_spin"
                    />
                  ) : (
                    <Icon icon="square-check" />
                  )
                }
                onClick={() => {
                  us_setAcceptingTermsOfService(true)
                  cloudFunctionManageRequest('manageUser', {
                    function: 'agreeToEula',
                    version: returnCurrentLegalVersionNumbers().EULA,
                  })
                    .then((res_CFMUR) => {
                      us_setAcceptingTermsOfService(false)
                      uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    })
                    .catch((rej_CFMUR) => {
                      us_setAcceptingTermsOfService(false)
                      uc_setUserInterface_ErrorDialogDisplay({
                        display: true,
                        error: rej_CFMUR.error,
                      })
                    })
                }}
              >
                {rLIB('I Agree')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
