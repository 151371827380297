///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Card } from '@mui/material/'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import axios from 'axios'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveProtoProjects_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_SalesOpportunities_Collection,
  DatabaseRef_SalesOpportunityDiscoverySession_Collection,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { StorageRef_GHL_FSD_CSV_Uploads } from 'rfbp_aux/services/storage_endpoints/integrations'
import { Json } from 'rfbp_core/components/code_display'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetLiveCollection,
  generateDatabaseQuery,
  StorageUploadFile,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { ProjectListUnlinkedProjectsTab } from '../projects/v2_list_tabs/tab_unlinked_projects'
import { formInputs_NewSalesOpportunity } from './forms/new_sales_opportunity'
import { formInputs_NewSalesOpportunityDiscoverySession } from './forms/opportunity_discovery_session'
import { tableColumns_SalesOpportunities, tableSettings_SalesOpportunities } from './tables/opportunities'
import { tableColumns_OpportunityCampaign, tableSettings_OpportunityDiscovery } from './tables/opportunity_discovery_sessions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminSalesToolsIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

// function analyzePanelsProduction(data: any, numQuintiles: any = 5) {
//   // Helper function to get the quintile ranges
//   function getQuintileRanges(arr: any, numQuintiles: any) {
//     arr.sort((a: any, b: any) => a - b)
//     const quintiles = []
//     const len = arr.length
//     for (let i = 0; i < numQuintiles; i++) {
//       const lowerIndex = Math.floor((i * len) / numQuintiles)
//       const upperIndex = Math.floor(((i + 1) * len) / numQuintiles) - 1
//       quintiles.push({
//         lowerBound: arr[lowerIndex],
//         upperBound: arr[upperIndex],
//       })
//     }
//     return quintiles
//   }

//   // Extract max_panels and production arrays, filtering out non-numeric values
//   const maxPanelsArray = data.map((item: any) => item.google_solar_max_panels).filter((value: any) => typeof value === 'number' && !isNaN(value))
//   const productionArray = data
//     .map((item: any) => item.google_solar_max_production_kwh_for_15_panels)
//     .filter((value: any) => typeof value === 'number' && !isNaN(value))

//   // Get quintile ranges
//   const maxPanelsQuintiles = getQuintileRanges(maxPanelsArray, numQuintiles)
//   const productionQuintiles = getQuintileRanges(productionArray, numQuintiles)

//   // Manually set the upper bound for the largest quintiles
//   maxPanelsQuintiles[numQuintiles - 1].upperBound = Math.max(...maxPanelsArray) // Change made here
//   productionQuintiles[numQuintiles - 1].upperBound = Math.max(...productionArray) // Change made here

//   // Initialize the result array with 25 objects (numQuintiles^2)
//   const result: any[] = []
//   for (let i = 0; i < numQuintiles; i++) {
//     for (let j = 0; j < numQuintiles; j++) {
//       result.push({
//         max_panels_lower_bound: maxPanelsQuintiles[i].lowerBound,
//         max_panels_upper_bound: maxPanelsQuintiles[i].upperBound,
//         production_lower_bound: productionQuintiles[j].lowerBound,
//         production_upper_bound: productionQuintiles[j].upperBound,
//         count: 0,
//       })
//     }
//   }

//   // Count the data points in each quintile range combination
//   data.forEach((item: any) => {
//     for (let i = 0; i < numQuintiles; i++) {
//       for (let j = 0; j < numQuintiles; j++) {
//         if (
//           item.google_solar_max_panels >= result[i * numQuintiles + j].max_panels_lower_bound &&
//           item.google_solar_max_panels <= result[i * numQuintiles + j].max_panels_upper_bound &&
//           item.google_solar_max_production_kwh_for_15_panels >= result[i * numQuintiles + j].production_lower_bound &&
//           item.google_solar_max_production_kwh_for_15_panels <= result[i * numQuintiles + j].production_upper_bound
//         ) {
//           result[i * numQuintiles + j].count++
//         }
//       }
//     }
//   })

//   // Prepare the array property
//   const array: string[][] = []

//   // Add header row with production ranges
//   const headerRow = ['']
//   for (let j = 0; j < numQuintiles; j++) {
//     headerRow.push(`${productionQuintiles[j].lowerBound}-${productionQuintiles[j].upperBound}`)
//   }
//   array.push(headerRow)

//   // Add rows for each max_panels quintile range with counts
//   for (let i = 0; i < numQuintiles; i++) {
//     const row = [`${maxPanelsQuintiles[i].lowerBound}-${maxPanelsQuintiles[i].upperBound}`]
//     for (let j = 0; j < numQuintiles; j++) {
//       row.push(result[i * numQuintiles + j].count.toString())
//     }
//     array.push(row)
//   }

//   return { object: result, array: array }
// }

// type BarChartData = { range: string; count: number }

// function buildBarChartData(numbers: number[], rangeWidth: number): BarChartData[] {
//   const rangeMap: { [key: string]: number } = {}

//   for (const num of numbers) {
//     const start = Math.floor(num / rangeWidth) * rangeWidth
//     const end = start + rangeWidth
//     const range = `${start}-${end}`

//     if (rangeMap[range] === undefined) {
//       rangeMap[range] = 0
//     }
//     rangeMap[range]++
//   }

//   const result: BarChartData[] = Object.entries(rangeMap).map(([range, count]) => ({ range, count }))

//   return result
// }

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_runningUpload, us_setRunningUpload] = useState<boolean>(false)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_unlinkedProjects, us_setUnlinkedProjects] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Tools', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnlinkedProjects(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveProtoProjects_Query(res_GCK.clientKey, 10), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const fileOnSelect = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setRunningUpload(true)
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        let file = files[0]
        if (file != null && typeof file === 'object') {
          promiseArray.push(
            readAsDataURL(file)
              .then((res_RADURL) => {
                readFiles[0] = {
                  file_name: file.name,
                  file: file,
                  data_url: res_RADURL,
                }
              })
              .catch((rej_RADURL) => {
                // Nothing
              }),
          )
        }
        Promise.all(promiseArray).finally(() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              StorageUploadFile(StorageRef_GHL_FSD_CSV_Uploads(res_GCK.clientKey, readFiles[0].file_name), readFiles[0].file, {})
                .then((res_SUF: any) => {
                  console.log('SUCCESS 1')
                  console.log(getProp(res_SUF, 'url', null))
                  let config = {
                    method: 'GET',
                    url: 'https://fsdupload-ghvejdcija-uc.a.run.app',
                    params: {
                      file: getProp(res_SUF, 'url', null),
                    },
                  }
                  axios
                    .request(config)
                    .then((res_A) => {
                      console.log(res_A)
                      resolve(res_A)
                      us_setRunningUpload(false)
                      ur_forceRerender()
                      // Custom Dialog
                      uc_setUserInterface_CustomDialogDisplay({
                        display: true,
                        dialog: {
                          dialog_jsx: (
                            <Box>
                              <Json data={res_A} />
                            </Box>
                          ),
                          settings: {
                            max_width: 'md',
                          },
                        },
                      })
                    })
                    .catch((rej_A) => {
                      console.log('ERROR 1')
                      console.error(rej_A)
                      us_setRunningUpload(false)
                      ur_forceRerender()
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_A.error })
                      reject(rej_A)
                    })
                })
                .catch((rej_SUF) => {
                  console.log('ERROR 2')
                  console.error(rej_SUF)
                  us_setRunningUpload(false)
                  ur_forceRerender()
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SUF.error })
                  reject(rej_SUF)
                })
            })
            .catch((rej_GCK) => {
              console.log('ERROR 3')
              console.error(rej_GCK)
              us_setRunningUpload(false)
              ur_forceRerender()
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              reject({ success: false, error: rej_GCK.error })
            })
        })
      } else {
        console.log('ERROR 4')
        let error = {
          message: rLIB('Failed to upload file'),
          details: rLIB('Invalid document selection'),
          code: 'ER-D-STI-FOS-01',
        }
        us_setRunningUpload(false)
        ur_forceRerender()
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
        reject({ success: false, error: error })
      }
    })
  }

  const createSalesOpportunity = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewSalesOpportunity,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'active'
                  formSubmittedData.timestamp_created = new Date()
                  formSubmittedData.created_by_key = getProp(formHooks.uc_RootData_ClientUser, 'key', null)
                  formSubmittedData.created_by_name = getProp(formHooks.uc_RootData_ClientUser, 'name', null)
                  formSubmittedData.color_config = {
                    min_panel_count: 15,
                    green_cutoff: 7000,
                    yellow_cutoff: 5000,
                  }
                  // TODO: do this on the server side in the future
                  DatabaseAddDocument(DatabaseRef_SalesOpportunities_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DSMD) => {
                      // hit the google and eagleview (prospect) api endpoints
                      cloudFunctionUnauthenticatedRequests({
                        function: 'generateNewOpportunityData',
                        client_key: res_GCK.clientKey,
                        opportunity_key: res_DSMD.key,
                        formSubmittedData: formSubmittedData,
                        generateFlags: {
                          google: true,
                          ev_prospect: false,
                          ev_propose: false,
                        },
                      })
                        .then((res) => {
                          console.log(res)
                        })
                        .catch((rej) => {
                          console.error(rej)
                        })
                      resolve({ success: true })
                      let url = ApplicationPages.AdminSalesOpportunityPage.url(res_DSMD.key)
                      window.open(url, '_blank')
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Sales Opportunity'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableDatabaseEndpoint_SalesOpportunities = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_SalesOpportunities_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createOpportunityDiscovery = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewSalesOpportunityDiscoverySession,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'active'
                  formSubmittedData.timestamp_created = new Date()
                  formSubmittedData.created_by_key = getProp(formHooks.uc_RootData_ClientUser, 'key', null)
                  formSubmittedData.created_by_name = getProp(formHooks.uc_RootData_ClientUser, 'name', null)
                  DatabaseAddDocument(DatabaseRef_SalesOpportunityDiscoverySession_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      directAppNavigation(un_routerNavigation, ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(res_DSMD.key))
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Sales Opportunity Campaign'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableDatabaseEndpoint_OpportunityDiscovery = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_SalesOpportunityDiscoverySession_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_NewOpportunityButton = (shrink: boolean): JSX.Element => {
    let newButton = <></>
    newButton = (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        className="tw-mr-2"
        onClick={() => {
          createSalesOpportunity()
        }}
      >
        {rLIB('New Sales Opportunity')}
      </Button>
    )
    return newButton
  }

  const rJSX_NewOpportunityDiscoveryButton = (shrink: boolean): JSX.Element => {
    let newButton = <></>
    newButton = (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        className="tw-mr-2"
        onClick={() => {
          createOpportunityDiscovery()
        }}
      >
        {rLIB('New Campaign')}
      </Button>
    )
    return newButton
  }

  const rJSX_OpportunitiesTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_SalesOpportunities}
              tableDatabaseEndpoint={tableDatabaseEndpoint_SalesOpportunities}
              tableSettings={tableSettings_SalesOpportunities}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_OpportunityDiscoveryTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_OpportunityCampaign}
              tableDatabaseEndpoint={tableDatabaseEndpoint_OpportunityDiscovery}
              tableSettings={tableSettings_OpportunityDiscovery}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_UnassignedCountBadgeLabel = (count: number): JSX.Element => {
    let labelJSX = <>{count}</>
    if (count >= 10) {
      labelJSX = <>10+</>
    }
    return labelJSX
  }

  const rJSX_UnlinkedProjectsTabHeader = (): JSX.Element => {
    let labelJSX = <></>
    if (objectToArray(us_unlinkedProjects).length > 0) {
      labelJSX = (
        <Box>
          <Badge
            badgeContent={rJSX_UnassignedCountBadgeLabel(objectToArray(us_unlinkedProjects).length)}
            color="error"
          >
            {rLIB('Unlinked Projects')}
          </Badge>
        </Box>
      )
    } else {
      labelJSX = <Box>{rLIB('Unlinked Projects')}</Box>
    }
    return labelJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Tools')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminSalesToolsIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Sales Tools', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rLIB('Sales Opportunities'),
                  tabUrlKey: 'opportunities',
                  tabButtons: [{ fullJSX: rJSX_NewOpportunityButton(false), minJSX: rJSX_NewOpportunityButton(true), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_OpportunitiesTable()}</Box>,
                },
                {
                  tabUrlKey: 'Unlinked_Projects',
                  tabHeader: rJSX_UnlinkedProjectsTabHeader(),
                  tabContent: <ProjectListUnlinkedProjectsTab />,
                  tabButtons: [
                    // { fullJSX: rJSX_NewProtoProjectButton(false), minJSX: rJSX_NewProtoProjectButton(true), sizeCutoff: 730 }
                  ],
                },
                {
                  tabHeader: rLIB('Campaigns'),
                  tabUrlKey: 'campaigns',
                  tabButtons: [{ fullJSX: rJSX_NewOpportunityDiscoveryButton(false), minJSX: rJSX_NewOpportunityDiscoveryButton(true), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_OpportunityDiscoveryTable()}</Box>,
                },
                {
                  tabHeader: rLIB('FSD Upload'),
                  tabUrlKey: 'fsd_upload',
                  tabButtons: [],
                  tabContent: (
                    <Box>
                      <FileUploadButton
                        multiple={true}
                        accept=".csv"
                        onChange={fileOnSelect}
                        button={{
                          text: rLIB('FSD Upload') as JSX.Element,
                          icon: (
                            <Icon
                              icon="cloud-arrow-up"
                              className="tw-mr-2"
                            ></Icon>
                          ),
                          color: 'info',
                          className: '',
                          variant: 'contained',
                          disabled: us_runningUpload,
                        }}
                        additionalFileUploadParams={{}}
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
