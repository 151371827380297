//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_LoadingBar,
  Context_UserInterface_PromptDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import * as XLSX from 'xlsx'
import { Icon } from '../icons/icon'
import {
  TableBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from '../table'

///////////////////////////////
// Typescript
///////////////////////////////

export interface TsInterface_ImportHooksObject {
  // TODO - make strict
  uc_RootData_ClientKey: any
  uc_RootData_ClientPermissions: any
  uc_RootData_ClientUser: any
  uc_RootData_GlobalUser: any
  uc_setRootData_ClientKey: any
  uc_setUserInterface_AlertDialogDisplay: any
  uc_setUserInterface_ConfirmDialogDisplay: any
  uc_setUserInterface_CustomDialogDisplay: any
  uc_setUserInterface_ErrorDialogDisplay: any
  uc_setUserInterface_FormDialogDisplay: any
  uc_setUserInterface_LoadingBarDisplay: any
  uc_setUserInterface_PromptDialogDisplay: any
  un_routerNavigation: any
  ur_forceRerender: any
}

export type TsType_ImportSubmission = (
  spreadsheetData: TsInterface_UnspecifiedObject,
  importAdditionalData: TsInterface_UnspecifiedObject,
  importHooks: TsInterface_ImportHooksObject,
) => TsType_UnknownPromise

export interface TsInterface_BasicImportButtonAndDialog {
  importAdditionalData: TsInterface_UnspecifiedObject
  importButtonColor: TsType_MuiComponentColors
  importButtonDisabled?: boolean
  importButtonShrink: boolean
  importButtonText: string | JSX.Element
  importDialogHeader: string | JSX.Element
  importMappingOptions: {
    [key: string]: {
      automatch_properties: string[]
      key: string
      label: string | JSX.Element
      required: boolean
      possible_values?: string[]
    }
  }
  importSubmission: TsType_ImportSubmission
}

interface TsInterface_BasicImportDialog {
  importAdditionalData: TsInterface_UnspecifiedObject
  importMappingOptions: TsInterface_BasicImportButtonAndDialog['importMappingOptions']
  importDialogHeader: JSX.Element
  importHooks: TsInterface_ImportHooksObject
  importRawData: TsInterface_UnspecifiedObject
  importRawHeaders: string[]
  importSubmission: TsType_ImportSubmission
}

///////////////////////////////
// Variables
///////////////////////////////

// Table
const tableSettings_ImportPreview: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: false,
  // sort_direction: "asc",
  // sort_property_default: "timestamp_recorded",
}

// Custom Dialog
const ImportMappingAndConfirmationDialog: React.FC<TsInterface_BasicImportDialog> = ({
  // eslint-disable-next-line react/prop-types
  importAdditionalData,
  // eslint-disable-next-line react/prop-types
  importDialogHeader,
  // eslint-disable-next-line react/prop-types
  importHooks,
  // eslint-disable-next-line react/prop-types
  importRawHeaders,
  // eslint-disable-next-line react/prop-types
  importRawData,
  // eslint-disable-next-line react/prop-types
  importSubmission,
  // eslint-disable-next-line react/prop-types
  importMappingOptions,
}): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_automappingAttemptComplete, us_setAutomappingAttemptComplete] = useState<boolean>(false)
  const [us_disableImportButton, us_setDisableImportButton] = useState<boolean>(true)
  const [us_importMappedData, us_setImportMappedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_importMapping, us_setImportMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_importingData, us_setImportingData] = useState<boolean>(false)
  const [us_invertedImportMapping, us_setInvertedImportMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tableColumns_ImportPreview, us_setTableColumns_ImportPreview] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    // Initial Attempted Mapping
    if (us_automappingAttemptComplete === false) {
      let mappingData: TsInterface_UnspecifiedObject = {}
      for (let loopRawHeaderIndex in importRawHeaders) {
        let loopHeader = importRawHeaders[loopRawHeaderIndex]
        for (let loopMappingKey in importMappingOptions) {
          let loopMappingData = importMappingOptions[loopMappingKey]
          // Attempt to Automatch
          // eslint-disable-next-line react/prop-types
          if (loopMappingData.automatch_properties.includes(loopHeader)) {
            mappingData[loopHeader] = loopMappingKey
          }
        }
      }
      us_setImportMapping(mappingData)
      us_setAutomappingAttemptComplete(true)
      ur_forceRerender()
    }
  }, [importMappingOptions, importRawHeaders, ur_forceRerender, us_automappingAttemptComplete])

  useEffect(() => {
    // Disable Mapping Options that are already mapped
    let invertedMappingOptions: TsInterface_UnspecifiedObject = {}
    for (let loopMappingKey in us_importMapping) {
      if (us_importMapping[loopMappingKey] != null) {
        invertedMappingOptions[us_importMapping[loopMappingKey]] = loopMappingKey
      }
    }

    us_setInvertedImportMapping(invertedMappingOptions)
  }, [importMappingOptions, us_importMapping])

  useEffect(() => {
    // Change Import Button Disabled Status base on mapping
    let disableImportButton = false
    for (let loopMappingKey in importMappingOptions) {
      // eslint-disable-next-line react/prop-types
      if (importMappingOptions[loopMappingKey].required === true) {
        if (us_invertedImportMapping[loopMappingKey] == null) {
          disableImportButton = true
        }
      }
    }
    us_setDisableImportButton(disableImportButton)
  }, [importMappingOptions, us_importMapping, us_invertedImportMapping])

  useEffect(() => {
    let mappedData: TsInterface_UnspecifiedObject = {}
    for (let loopRawDataKey in importRawData) {
      let loopRawDataRow = importRawData[loopRawDataKey]
      let mappedRow: TsInterface_UnspecifiedObject = {}
      for (let loopMappingKey in us_importMapping) {
        let loopMappingValue = us_importMapping[loopMappingKey]
        if (loopMappingValue != null) {
          mappedRow[loopMappingValue] = loopRawDataRow[loopMappingKey]
        }
      }
      mappedData[loopRawDataKey] = mappedRow
    }
    us_setImportMappedData(mappedData)
  }, [importRawData, us_importMapping, us_invertedImportMapping])

  useEffect(() => {
    let tableColumns: TsInterface_TableColumns = {}
    // eslint-disable-next-line react/prop-types
    for (let loopHeaderIndex = 0; loopHeaderIndex < importRawHeaders.length; loopHeaderIndex++) {
      let loopHeader = importRawHeaders[loopHeaderIndex]
      tableColumns[loopHeader] = {
        header: {
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            let headerJSX = (
              <Box>
                <Box>
                  <Select
                    className="bp_thin_select_input"
                    color="primary"
                    value={us_importMapping[loopHeader] || ''}
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        if (event.target.value === '') {
                          us_setImportMapping((prevState) => {
                            return { ...prevState, [loopHeader]: null }
                          })
                        } else {
                          us_setImportMapping((prevState) => {
                            return { ...prevState, [loopHeader]: event.target.value }
                          })
                        }
                      }
                    }}
                    variant="outlined"
                  >
                    <MenuItem
                      value=""
                      sx={{ color: themeVariables.error_light }}
                    >
                      <em>{rLIB('Remove Mapping')}</em>
                    </MenuItem>
                    {objectToArray(importMappingOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                        disabled={us_invertedImportMapping[option['key']] != null}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Divider className="tw-my-2" />
                <Box sx={{ fontWeight: 'bold', opacity: 0.3 }}>{loopHeader}</Box>
              </Box>
            )
            return headerJSX
          },
          header_sort_by: null,
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = <Box>{rowData[loopHeader]}</Box>
            return cellJSX
          },
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            let cellCSS = ''
            return cellCSS
          },
        },
      }
    }
    us_setTableColumns_ImportPreview(tableColumns)
  }, [importMappingOptions, importRawData, importRawHeaders, us_importMapping, us_invertedImportMapping])

  // Functions

  // JSX Generation
  const rJSX_ConfirmUploadButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={
          us_importingData ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="cloud-arrow-up" />
          )
        }
        disabled={us_disableImportButton || us_importingData}
        onClick={() => {
          us_setImportingData(true)
          importSubmission(us_importMappedData, importAdditionalData, importHooks)
            .then((res_IS) => {
              us_setImportingData(false)
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              ur_forceRerender()
            })
            .catch((rej_IS: TsInterface_UnspecifiedObject) => {
              us_setImportingData(false)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_IS.error })
              ur_forceRerender()
            })
        }}
      >
        {rLIB('Import')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MappedFieldIcon = (mappedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (mappedField != null && mappedField.key != null && us_invertedImportMapping != null && us_invertedImportMapping[mappedField.key] != null) {
      iconJSX = (
        <Icon
          icon="badge-check"
          sx={{ color: themeVariables.success_main, fontSize: '18px' }}
          tooltip={rLIB('Mapped')}
          tooltipPlacement="right"
        />
      )
    } else if (mappedField != null && mappedField.required === true) {
      iconJSX = (
        <Icon
          icon="badge-check"
          sx={{ color: themeVariables.error_main, fontSize: '18px' }}
          tooltip={rLIB('Required')}
          tooltipPlacement="right"
        />
      )
    } else {
      iconJSX = (
        <Icon
          icon="badge-check"
          sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
          tooltip={rLIB('Optional')}
          tooltipPlacement="right"
        />
      )
    }
    return iconJSX
  }

  const rJSX_MappedFieldValue = (mappedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let fieldValueJSX = <></>
    if (mappedField != null && mappedField.key != null && us_invertedImportMapping != null && us_invertedImportMapping[mappedField.key] != null) {
      fieldValueJSX = <>{us_invertedImportMapping[mappedField.key]}</>
    }
    return fieldValueJSX
  }

  const rJSX_PossibleOptions = (mappedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let optionsJSX = <></>
    if (mappedField != null && mappedField.possible_values != null) {
      optionsJSX = (
        <Box
          component={'span'}
          className="tw-ml-1 tw-opacity-30 tw-italic"
        >
          ({mappedField.possible_values.join(', ')})
        </Box>
      )
    }
    return optionsJSX
  }

  const rJSX_MappingFieldsList = (): JSX.Element => {
    let mappingFieldsListJSX = (
      <Card sx={{ margin: '8px' }}>
        <Box
          className="tw-text-center"
          sx={{ padding: '8px' }}
        >
          {rLIB('Fields to Map')}
        </Box>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {objectToArray(importMappingOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '24px' }}>{rJSX_MappedFieldIcon(option)}</TableCell>
                  <TableCell>
                    {option.label}
                    {rJSX_PossibleOptions(option)}
                  </TableCell>
                  <TableCell>{rJSX_MappedFieldValue(option)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    )
    return mappingFieldsListJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="info"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="upload" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">{importDialogHeader}</Box>
              </Typography>
              {rJSX_ConfirmUploadButton()}
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '8px' }}>
            {rJSX_MappingFieldsList()}
            <Card sx={{ margin: '8px' }}>
              <TableBasic
                tableAdditionalData={{}}
                tableColumns={us_tableColumns_ImportPreview}
                tableData={objectToArray(importRawData)}
                tableSettings={tableSettings_ImportPreview}
              />
            </Card>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const BasicImportButtonAndDialog = (props: TsInterface_BasicImportButtonAndDialog): JSX.Element => {
  // Props
  let pr_importAdditionalData: TsInterface_UnspecifiedObject = getProp(props, 'importAdditionalData', {})
  let pr_importButtonColor: TsType_MuiComponentColors = getProp(props, 'importButtonColor', 'info')
  let pr_importButtonDisabled: boolean = getProp(props, 'importButtonDisabled', false)
  let pr_importButtonShrink: boolean = getProp(props, 'importButtonShrink', false)
  let pr_importButtonText: JSX.Element = getProp(props, 'importButtonText', rLIB('Import'))
  let pr_importDialogHeader: JSX.Element = getProp(props, 'importDialogHeader', rLIB('Confirm Import'))
  let pr_importMappingOptions: TsInterface_UnspecifiedObject = getProp(props, 'importMappingOptions', {})
  let pr_importSubmission: TsType_ImportSubmission = getProp(props, 'importSubmission', () => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  })

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_LoadingBarDisplay } = useContext(Context_UserInterface_LoadingBar)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  const importHooks: TsInterface_ImportHooksObject = {
    uc_RootData_ClientKey: uc_RootData_ClientKey,
    uc_RootData_ClientPermissions: uc_RootData_ClientPermissions,
    uc_RootData_ClientUser: uc_RootData_ClientUser,
    uc_RootData_GlobalUser: uc_RootData_GlobalUser,
    uc_setRootData_ClientKey: uc_setRootData_ClientKey,
    uc_setUserInterface_AlertDialogDisplay: uc_setUserInterface_AlertDialogDisplay,
    uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
    uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
    uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
    uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
    uc_setUserInterface_LoadingBarDisplay: uc_setUserInterface_LoadingBarDisplay,
    uc_setUserInterface_PromptDialogDisplay: uc_setUserInterface_PromptDialogDisplay,
    un_routerNavigation: un_routerNavigation,
    ur_forceRerender: ur_forceRerender,
  }

  // Functions
  const openSpreadsheetCustomDialog = (
    event: React.ChangeEvent<HTMLInputElement>,
    additionalFileUploadParams: TsInterface_UnspecifiedObject,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // Process Raw Data
      if (event != null && event.target != null && event.target.files != null && event.target.files.length > 0) {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (readEvent) => {
          if (readEvent != null && readEvent.target != null && typeof readEvent.target.result === 'string') {
            const binaryString = readEvent.target.result
            const workbook = XLSX.read(binaryString, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const originalParsedData: any = XLSX.utils.sheet_to_json(sheet, { header: 1 })
            // Parse Data
            let headersArray: string[] = []
            let unformattedData: any = []
            for (let loopRowIndex = 0; loopRowIndex < originalParsedData.length; loopRowIndex++) {
              if (loopRowIndex === 0) {
                headersArray = originalParsedData[loopRowIndex]
              }
              let loopRow = originalParsedData[loopRowIndex]
              if (loopRow != null && loopRow.length > 0) {
                unformattedData.push(loopRow)
              }
            }
            // Convert Array to Object
            let spreadsheetObjectData: TsInterface_UnspecifiedObject = {}
            for (let loopRowIndex = 1; loopRowIndex < unformattedData.length; loopRowIndex++) {
              let loopRow: any[] = unformattedData[loopRowIndex]
              spreadsheetObjectData[loopRowIndex.toString()] = {}
              for (let loopColIndex = 0; loopColIndex < loopRow.length; loopColIndex++) {
                let colProp = unformattedData[0][loopColIndex]
                let loopColValue = loopRow[loopColIndex]
                if (loopColValue != null && loopColValue !== '') {
                  spreadsheetObjectData[loopRowIndex.toString()][colProp] = loopColValue
                }
              }
            }
            uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: (
                  <ImportMappingAndConfirmationDialog
                    importAdditionalData={pr_importAdditionalData}
                    importDialogHeader={pr_importDialogHeader}
                    importHooks={importHooks}
                    importMappingOptions={pr_importMappingOptions}
                    importRawData={spreadsheetObjectData}
                    importRawHeaders={headersArray}
                    importSubmission={pr_importSubmission}
                  />
                ),
                settings: {
                  max_width: 'lg',
                },
              },
            })
          } else {
            let error = {
              message: rLIB('Failed to import data'),
              details: rLIB('An unknown error occurred'),
              code: 'ER-D-BIBAD-OSCD-01',
            }
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
            reject({ success: false, error: error })
          }

          // Reset the file input value so the same file can be selected again
          if (event.target) {
            event.target.value = ''
          }
        }
        reader.readAsBinaryString(file)
      } else {
        let error = {
          message: rLIB('Failed to import data'),
          details: rLIB('An unknown error occurred'),
          code: 'ER-D-BIBAD-OSCD-02',
        }
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
        reject({ success: false, error: error })
      }
      // Stop Original Button from Spinning
      resolve({ success: true })
    })
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let buttonJSX = <></>
    if (pr_importButtonShrink === false) {
      buttonJSX = (
        <FileUploadButton
          multiple={false}
          button={{
            text: pr_importButtonText,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
                sx={{ fontSize: '20px' }}
              ></Icon>
            ),
            color: pr_importButtonColor,
            className: 'tw-mr-2',
            variant: 'contained',
            disabled: pr_importButtonDisabled,
          }}
          accept=".xlsx, .csv"
          onChange={openSpreadsheetCustomDialog}
          additionalFileUploadParams={pr_importAdditionalData}
        />
      )
    } else {
      buttonJSX = (
        <FileUploadButton
          multiple={false}
          button={{
            text: <></>,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                className=""
                sx={{ fontSize: '20px' }}
              ></Icon>
            ),
            color: pr_importButtonColor,
            className: 'bp_icon_only_button tw-mr-2',
            variant: 'contained',
            disabled: pr_importButtonDisabled,
          }}
          accept=".xlsx, .csv"
          onChange={openSpreadsheetCustomDialog}
          additionalFileUploadParams={pr_importAdditionalData}
        />
      )
    }
    return buttonJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
