///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { viewerLines_User, viewerSettings_User } from 'app/models/users/user_display'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_UserFiles_Collection,
  DatabaseRef_UserFile_Document,
  DatabaseRef_UserForumMessages_Collection,
  DatabaseRef_UserForumMessage_Document,
  DatabaseRef_UserForumThreads_Collection,
  DatabaseRef_UserForumThread_Document,
} from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_ClientUser_Document, DatabaseRef_UserLogs_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { StorageRef_UserFile, StorageRef_User_MessageThread_File } from 'rfbp_aux/services/storage_endpoints/user'
import { DataViewer, TsInterface_DataViewerAdditionalData } from 'rfbp_core/components/data_viewer'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { LogTable } from 'rfbp_core/components/logs/log_table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_ClientPermissions, Context_RootData_ClientUser } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesDatabaseUserViewPage']['key']

// Displayed Translatable Strings
const s_FILES: JSX.Element = <Trans>Files</Trans>
const s_LOGS: JSX.Element = <Trans>Logs</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_USER: JSX.Element = <Trans>User</Trans>
const s_VIEW_USER: JSX.Element = <Trans>View User</Trans>
const se_USER = 'User'

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_viewerData_User, us_setViewerData_User] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)

  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)

  // Hooks - useEffect
  useEffect(() => {
    if (us_viewerData_User && us_viewerData_User['name'] != null) {
      document.title = se_USER + ': ' + us_viewerData_User['name']
    }
  }, [us_viewerData_User])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setViewerData_User(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, pr_itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const viewerAdditionalData_User: TsInterface_DataViewerAdditionalData = {
    client_type: uc_RootData_ClientPermissions.client_type,
    root_client_permissions: uc_RootData_ClientPermissions,
    userKey: pr_itemKey,
  }

  // Functions

  // JSX Generation

  // const rJSX_UserTab = (): JSX.Element => {

  // }

  const rJSX_LogTab = (): JSX.Element => {
    let logTab = <></>
    if (uc_RootData_ClientKey != null) {
      logTab = (
        <Card>
          <LogTable
            logType="user"
            logEndpoint={DatabaseRef_UserLogs_Collection(uc_RootData_ClientKey as string, pr_itemKey)}
          />
        </Card>
      )
    }
    return logTab
  }

  // Notes Tab
  const rJSX_NotesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <ForumBasic
          forumThreadCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_UserForumThreads_Collection(clientKey, pr_itemKey)
          }}
          forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_UserForumThread_Document(clientKey, pr_itemKey, threadKey)
          }}
          forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_UserForumMessages_Collection(clientKey, pr_itemKey, threadKey)
          }}
          forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
            return DatabaseRef_UserForumMessage_Document(clientKey, pr_itemKey, threadKey, messageKey)
          }}
          forumMessageStorageEndpoint={(clientKey, threadKey, messageKey, fileName) => {
            return StorageRef_User_MessageThread_File(clientKey, pr_itemKey, threadKey, fileName)
          }}
          forumSettings={{
            allow_thread_creation: true,
            scrollbar_track_color: '#272b33',
            scrollbar_thumb_color: '#888',
            input_text_color: '#fff',
            base_message_container_offset_px: 336,
          }}
        />
      </Box>
    )
    return tabJSX
  }

  // Files Tab
  const rJSX_FilesTab = (): JSX.Element => {
    let tabJSX = (
      <FileSystemBasic
        fileSystemCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_UserFiles_Collection(clientKey, pr_itemKey)
        }}
        fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
          return DatabaseRef_UserFile_Document(clientKey, pr_itemKey, fileKey)
        }}
        fileSystemStorageEndpoint={(clientKey, fileName) => {
          return StorageRef_UserFile(clientKey, pr_itemKey, fileName)
        }}
        // fileSystemHardCodedFolders={ { drivers_license: { associated_parent_folder_key: null, key: "drivers_license", folder_name: "Driver's License" } } }
        fileSystemHardCodedFolders={{}}
        fileSystemSettings={{
          allow_file_archiving: true,
          allow_file_movement: true,
          allow_file_unarchiving: true,
          allow_file_uploads: true,
          allow_folder_creation: true,
          allow_folder_deletion: true,
          allow_folder_movement: true,
          allow_folder_rename: true,
          archive_filter_visible: true,
        }}
      />
    )
    return tabJSX
  }

  // Page JSX
  // Page JSX
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_VIEW_USER}
        pageKey={pageKey}
        content={
          <Box>
            <Button
              color="inherit"
              variant="outlined"
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesDatabaseUserListPage.url())
              }}
              disableElevation
              startIcon={<Icon icon="chevron-left" />}
            >
              {rLIB('Back to all Users')}
            </Button>
            <Box className="tw-my-0">
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.SalesDatabaseUserViewPage.url(pr_itemKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_USER,
                }}
                tabs={[
                  {
                    tabUrlKey: 'User',
                    tabHeader: s_USER,
                    tabContent: (
                      <Box>
                        <Card className="tw-p-4">
                          <DataViewer
                            viewerAdditionalData={viewerAdditionalData_User}
                            viewerData={us_viewerData_User}
                            viewerLines={viewerLines_User}
                            viewerSettings={viewerSettings_User}
                          />
                        </Card>
                      </Box>
                    ),
                  },
                  {
                    tabUrlKey: 'Notes',
                    tabHeader: s_NOTES,
                    tabContent: rJSX_NotesTab(),
                  },
                  ...(uc_RootData_ClientUser?.task_roles?.hr_admin
                    ? [
                        {
                          tabUrlKey: 'Files',
                          tabHeader: s_FILES,
                          tabContent: rJSX_FilesTab(),
                        },
                      ]
                    : []),
                  {
                    tabUrlKey: 'Logs',
                    tabHeader: s_LOGS,
                    tabContent: <Box>{rJSX_LogTab()}</Box>,
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )

    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
