///////////////////////////////
// Description
///////////////////////////////

import { Box, Checkbox, Link, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_ActiveJurisdictions_Query,
  DatabaseRef_JurisdictionFiles_Collection,
  DatabaseRef_JurisdictionFile_Document,
  DatabaseRef_JurisdictionForumMessages_Collection,
  DatabaseRef_JurisdictionForumMessage_Document,
  DatabaseRef_JurisdictionForumThreads_Collection,
  DatabaseRef_JurisdictionForumThread_Document,
  DatabaseRef_Jurisdictions_Collection,
  DatabaseRef_Jurisdiction_Document,
  DatabaseRef_Jurisdiction_ImportAliases_Collection,
  DatabaseRef_Jurisdiction_ImportAliases_Document,
  DatabaseRef_Jurisdiction_ImportAliases_Query,
} from 'rfbp_aux/services/database_endpoints/directory/jurisdictions'
import { StorageRef_JurisdictionFile, StorageRef_Jurisdiction_MessageThread_File } from 'rfbp_aux/services/storage_endpoints/jurisdictions'
import { tsI_MultipleChoiceOption } from 'rfbp_core/components/direct_edits/direct_multiple_choice_edit'
import { DirectTextEdit } from 'rfbp_core/components/direct_edits/direct_text_edit'
import { TsInterface_FormAdditionalData, TsInterface_FormHooksObject, TsInterface_FormInputs, TsInterface_FormSubmittedData } from 'rfbp_core/components/form'
import { TsInterface_FormData, TsInterface_FormSettings, TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { listOfUnitedStates } from 'rfbp_core/components/form/inputs/multiple_choice_select_state_usa_abbreviated'
import { Icon } from 'rfbp_core/components/icons/icon'
import { TsInterface_ImportHooksObject } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import {
  TableCellBasic,
  TableCellManage,
  TableCellPhone,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TableCellURL } from 'rfbp_core/components/table/cells/table_cell_url'
import { rLIB } from 'rfbp_core/localization/library'
import {
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnDateFromUnknownDateFormat, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { openDeleteDataItemDialog, openMasterDataDialog, openUndeleteDataItemDialog } from './all_master_data'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Helper Functions
function convertExcelDate(excelSerial: string | number | undefined): string {
  if (!excelSerial) return '' // Return empty string if no date is provided
  const baseDate = new Date(1900, 0, 1) // Base date: January 1, 1900
  const serialNumber = Number(excelSerial)

  if (isNaN(serialNumber)) return '' // Handle invalid numbers

  const correctedSerial = serialNumber - 2 // Excel incorrectly counts 1900 as a leap year
  baseDate.setDate(baseDate.getDate() + correctedSerial)

  return baseDate.toISOString().split('T')[0] // Format to YYYY-MM-DD
}

function parseMultiSelectField(fieldValue: string | undefined): { [key: string]: boolean } {
  if (!fieldValue) return {}

  return fieldValue
    .split(',')
    .map((item) =>
      item
        .trim()
        .toLowerCase()
        .replace(/[\s-]+/g, '_'),
    )
    .filter((item) => item !== '')
    .reduce(
      (acc, curr) => {
        acc[curr] = true
        return acc
      },
      {} as { [key: string]: boolean },
    )
}

const normalizeString = (input: string): string => {
  return input.trim().toUpperCase().replace(/\s+/g, '')
}

const capitalizeWords = (input: string): string => {
  return input
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const normalizeJurisdictionData = (name: string, state: string, type: string) => {
  const cleanName = name.replace(/\//g, '_') // Replace slashes with underscores
  const normalizedName = normalizeString(cleanName)
  const normalizedState = normalizeString(state)
  const normalizedType = normalizeString(type)

  const jurisdiction_name = `${normalizedState}_${normalizedType}_${normalizedName}`

  return {
    jurisdiction_name,
    key: jurisdiction_name,
    displayState: state.trim().toUpperCase(),
    displayType: capitalizeWords(type),
    displayName: capitalizeWords(name),
  }
}

// Contact Information Section
const formInputs_ContactInfo: TsInterface_FormInputs = {
  // name: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'name',
  //   label: 'Jurisdiction Name',
  //   placeholder: 'East Peoria, Elgin, New Berlin',
  //   required: true,
  // },
  state: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'state',
    label: 'State',
    required: true,
    options: [
      { key: 'CA', label: 'CA', value: 'CA' },
      { key: 'IL', label: 'IL', value: 'IL' },
      { key: 'TX', label: 'TX', value: 'TX' },
    ],
  },
  type: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'type',
    label: 'Type',
    required: true,
    options: [
      { key: 'city', label: 'City', value: 'City' },
      { key: 'village', label: 'Village', value: 'Village' },
      { key: 'county', label: 'County', value: 'County' },
      { key: 'town', label: 'Town', value: 'Town' },
      { key: 'township', label: 'Township', value: 'Township' },
    ],
  },
  contact_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'contact_name',
    label: 'Department Name',
  },
  address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'address',
    label: 'Address',
  },
  phone_number: {
    data_type: 'string',
    input_type: 'phone_number_usa',
    key: 'phone_number',
    label: 'Phone Number',
  },
  additional_phone_numbers: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'additional_phone_numbers',
    label: 'Additional Contact Information',
  },
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: 'Email',
  },
  website: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'website',
    label: 'Website',
  },
}

// Design Guidance Section
const formInputs_DesignGuidance: TsInterface_FormInputs = {
  fire_setback: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'fire_setback',
    label: 'Fire Setbacks',
  },

  // eave_setback: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'eave_setback',
  //   label: 'Eave Setback',
  // },
  // valley_setback: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'valley_setback',
  //   label: 'Valley Setback',
  // },
  // ridge_setback: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'ridge_setback',
  //   label: 'Ridge Setback',
  // },
  // rake_setback: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'rake_setback',
  //   label: 'Rake Setback',
  // },
  // hip_setback: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'hip_setback',
  //   label: 'Hip Setback',
  // },
  snow_load: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'snow_load',
    label: 'Snow Load',
  },
  wind_load: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'wind_load',
    label: 'Wind Load',
  },
  fire_code: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'fire_code',
    label: 'Fire Code',
  },

  building_code: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'building_code',
    label: 'Building Code',
  },

  international_residential_code: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'international_residential_code',
    label: 'International Residential Code',
  },

  national_electric_code: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'national_electric_code',
    label: 'National Electric Code',
  },

  code_years: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'code_years',
    label: 'Other Codes',
  },

  grounding_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'grounding_requirement_notes',
    label: 'Grounding Requirement Notes',
    placeholder: 'Cold Water Ground Supplemental Ground Rod',
  },
  disconnect_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'disconnect_requirement_notes',
    label: 'Disconnect Requirement Notes',
  },
  wire_sizing_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'wire_sizing_notes',
    label: 'Wire Sizing Notes',
    placeholder: '8 AWG ground throughout',
  },
  conduit_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'conduit_notes',
    label: 'Conduit Notes',
  },
  placard_requirement_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'placard_requirement_notes',
    label: 'Placard Requirement Notes',
  },
}

const submissionModesOptions = [
  { key: 'portal', label: 'Online Portal', value: 'Online Portal' },
  { key: 'mail_in', label: 'Mail-in', value: 'Mail-in' },
  { key: 'counter', label: 'Counter', value: 'Counter' },
  { key: 'email', label: 'Email', value: 'Email' },
]

const attachmentsRequirementOptions = [
  { key: 'permit_sheet', label: 'Permit application sheet', value: 'Permit application sheet' },
  { key: 'sld', label: 'SLD', value: 'SLD' },
  { key: '3ld', label: '3LD', value: '3LD' },
  { key: 'elevation', label: 'Elevation', value: 'Elevation' },
  { key: 'equipment_docs', label: 'Equipment Spec Documents', value: 'Equipment Spec Documents' },
  { key: 'pe_letter', label: 'PE Letter', value: 'PE Letter' },
]

const paymentModesAcceptedOptions = [
  { key: 'cash', label: 'Cash', value: 'Cash' },
  { key: 'check', label: 'Check', value: 'Check' },
  { key: 'card', label: 'Card', value: 'Card' },
  { key: 'ach', label: 'ACH', value: 'ACH' },
]

const eeStampRequirementsOptions = [
  { key: 'digital_stamp', label: 'Digital Stamp', value: 'Digital Stamp' },
  { key: 'wet_stamp', label: 'Wet Stamp', value: 'Wet Stamp' },
  { key: 'always_required', label: 'Always Required', value: 'Always Required' },
  { key: 'required_for_battery_installs', label: 'Required for Battery Installs', value: 'Required for Battery Installs' },
]

const inspectionTypeOptions = [
  { key: 'no_inspection', label: 'No inspection', value: 'No inspection' },
  { key: 'in_person', label: 'In-person', value: 'In-person' },
  { key: 'virtual', label: 'Virtual', value: 'Virtual' },
  { key: 'by_utility', label: 'By utility', value: 'By utility' },
]

// Permit Submission Section
const formInputs_PermitSubmissions: TsInterface_FormInputs = {
  permit_fees: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'permit_fees',
    label: 'Permit Fees',
  },
  estimated_turnaround: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'estimated_turnaround',
    label: 'Estimated Turnaround time (business days)',
  },
  attachments_requirement: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'attachments_requirement',
    label: 'Attachments Requirement',
    options: [
      { key: 'permit_sheet', label: 'Permit application sheet', value: 'Permit application sheet' },
      { key: 'sld', label: 'SLD', value: 'SLD' },
      { key: '3ld', label: '3LD', value: '3LD' },
      { key: 'elevation', label: 'Elevation', value: 'Elevation' },
      { key: 'equipment_docs', label: 'Equipment Spec Documents', value: 'Equipment Spec Documents' },
    ],
  },
  additional_required_attachments: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'additional_required_attachments',
    label: 'Additional Attachments Requirement ',
  },

  submission_modes: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'submission_modes',
    label: 'Submission Modes',
    options: objectToArray(submissionModesOptions),
  },
  solarapp_enabled: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'solarapp_enabled',
    label: 'SolarApp enabled',
  },
  // portal_website_address: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'portal_website_address',
  //   label: 'Portal Website Address',
  // },
  solar_application_link: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'solar_application_link',
    label: 'Solar Application Link',
  },
  hu_application_link: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'hu_application_link',
    label: 'HU Application Link',
  },
  payment_modes_accepted: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'payment_modes_accepted',
    label: 'Payment Modes Accepted',
    options: [
      { key: 'cash', label: 'Cash', value: 'Cash' },
      { key: 'check', label: 'Check', value: 'Check' },
      { key: 'card', label: 'Card', value: 'Card' },
      { key: 'ach', label: 'ACH', value: 'ACH' },
    ],
  },
  pe_stamp_requirement: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'pe_stamp_requirement',
    label: 'PE Stamp Requirement',
    placeholder: 'When do they want PE stamps?',
  },
  ee_stamp_requirements: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'ee_stamp_requirements',
    label: 'EE Stamp Requirements',
    options: [
      { key: 'digital_stamp', label: 'Digital Stamp', value: 'Digital Stamp' },
      { key: 'wet_stamp', label: 'Wet Stamp', value: 'Wet Stamp' },
      { key: 'always_required', label: 'Always Required', value: 'Always Required' },
      { key: 'required_for_battery_installs', label: 'Required for Battery Installs', value: 'Required for Battery Installs' },
    ],
  },
  as_built_requirements: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'as_built_requirements',
    label: 'As Built Requirements',
  },
  hoa_pre_approval_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'hoa_pre_approval_required',
    label: 'HOA Pre-Approval Required',
  },
}

// Inspections Section
const formInputs_Inspections: TsInterface_FormInputs = {
  inspection_type: {
    data_type: 'string',
    input_type: 'multiple_select_dropdown',
    key: 'inspection_type',
    label: 'Inspection Type',
    options: [
      { key: 'no_inspection', label: 'No inspection', value: 'No inspection' },
      { key: 'in_person', label: 'In-person', value: 'In-person' },
      { key: 'virtual', label: 'Virtual', value: 'Virtual' },
      { key: 'by_utility', label: 'By utility', value: 'By utility' },
    ],
  },
  solar_inspection_scheduling_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'solar_inspection_scheduling_notes',
    label: 'Solar Inspection Scheduling Notes',
  },
  hu_inspection_scheduling_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'hu_inspection_scheduling_notes',
    label: 'HU Inspection Scheduling Notes',
    placeholder: 'Call to schedule',
  },

  installation_guidance_for_crews: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'installation_guidance_for_crews',
    label: 'Installation Guidance for Crews',
  },
}

const formInputs_PermitRenewalDate: TsInterface_FormInputs = {
  permit_renewal_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'permit_renewal_date',
    label: 'Permit Renewal Date',
  },
}

const formInputs_ApprovedDate: TsInterface_FormInputs = {
  approved_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'approved_date',
    label: 'Approved Date',
  },
}

// ETW Permit Section
const formInputs_ETWPermit: TsInterface_FormInputs = {
  permit_holder: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'permit_holder',
    label: 'Permit Holder',
  },
  permit_renewal_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'permit_renewal_date',
    label: 'Permit Renewal Date',
  },

  application_renewal_notes: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'application_renewal_notes',
    label: 'Application Renewal Notes',
  },
  coi_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'coi_required',
    label: 'COI Required',
  },
  registration_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'registration_required',
    label: 'Registration Required',
  },

  permit_required: {
    data_type: 'boolean',
    input_type: 'boolean_switch',
    key: 'permit_required',
    label: 'Permit Required',
  },
  // applied_date: {
  //   data_type: 'string',
  //   input_type: 'timestamp_date',
  //   key: 'applied_date',
  //   label: 'Applied Date',
  // },
  approved_date: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'approved_date',
    label: 'Approved Date',
  },
}

// Unique Field Key
let uniqueFieldKey = 'name'

// Document Ref
const documentRef = DatabaseRef_Jurisdiction_Document

// Collection Ref
const collectionRef = DatabaseRef_Jurisdictions_Collection

// Active Collection Ref
const activeCollectionRef = DatabaseRef_ActiveJurisdictions_Query

// Import Aliases Ref
const importAliasesDocumentRef = DatabaseRef_Jurisdiction_ImportAliases_Document
const importAliasesCollectionRef = DatabaseRef_Jurisdiction_ImportAliases_Collection
const importAliasesQuery = DatabaseRef_Jurisdiction_ImportAliases_Query

// Forum Refs
const forumThreadCollectionDatabaseEndpoint = DatabaseRef_JurisdictionForumThreads_Collection
const forumThreadDocumentDatabaseEndpoint = DatabaseRef_JurisdictionForumThread_Document
const forumMessageCollectionDatabaseEndpoint = DatabaseRef_JurisdictionForumMessages_Collection
const forumMessageDocumentDatabaseEndpoint = DatabaseRef_JurisdictionForumMessage_Document
const forumMessageStorageEndpoint = StorageRef_Jurisdiction_MessageThread_File

// Files Refs
const fileSystemCollectionDatabaseEndpoint = DatabaseRef_JurisdictionFiles_Collection
const fileSystemDocumentDatabaseEndpoint = DatabaseRef_JurisdictionFile_Document
const fileSystemStorageEndpoint = StorageRef_JurisdictionFile

// Search Index Ksy
const searchIndexKey = 'jurisdictions'

// View Config
const viewConfig: TsInterface_UnspecifiedObject = {
  HEADER1: {
    key: 'HEADER1',
    type: 'header',
    label: rLIB('Permit Department Information'),
  },
  name: {
    key: 'name',
    label: rLIB('Name'),
    type: 'non_editable_text',
  },
  state: {
    key: 'state',
    label: rLIB('State'),
    type: 'editable_multiple_choice_static',
    options: objectToArray(listOfUnitedStates) as unknown as tsI_MultipleChoiceOption[],
  },
  type: {
    key: 'type',
    label: rLIB('Type'),
    type: 'editable_multiple_choice_static',
    options: [
      { key: 'City', value: 'City' },
      { key: 'Village', value: 'Village' },
      { key: 'County', value: 'County' },
      { key: 'Town', value: 'Town' },
      { key: 'Township', value: 'Township' },
    ],
  },
  contact_name: {
    key: 'contact_name',
    label: rLIB('Department Name / Attn'),
    type: 'editable_text',
  },
  address: {
    key: 'address',
    label: rLIB('Address'),
    type: 'editable_text',
  },
  phone_number: {
    key: 'phone_number',
    label: rLIB('Phone Number'),
    type: 'editable_text',
  },
  additional_phone_number: {
    key: 'additional_phone_number',
    label: rLIB('Additional Contact Information'),
    type: 'editable_text_multiline',
  },
  email: {
    key: 'email',
    label: rLIB('Email'),
    type: 'editable_text',
  },
  website: {
    key: 'website',
    label: rLIB('Website'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      let propKey = 'website'
      if (getProp(item, propKey, null) === '') {
        item[propKey] = null
      }
      let editableFieldJSX = <></>
      if (item[propKey] != null) {
        const urlValue = getProp(item, propKey, '') || ''
        editableFieldJSX = (
          <Link
            href={urlValue.startsWith('http') ? urlValue : `http://${urlValue}`} // Ensure it starts with http:// or https://
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Security
            sx={{
              'opacity': 1,
              'fontWeight': 'normal',
              'color': themeVariables.primary_main,
              '&:hover': {
                opacity: 1,
                cursor: 'pointer',
              },
            }}
          >
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {getProp(item, propKey, '')}
            </Typography>
          </Link>
        )
      } else {
        editableFieldJSX = (
          <Box
            component="span"
            className="tw-opacity-30 tw-italic"
            sx={{ fontWeight: 'normal' }}
          >
            {rLIB('Missing')}
          </Box>
        )
      }
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectTextEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(item, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                if (readOrWrite === 'write') {
                  getClientKey(hooks.uc_RootData_ClientKey, hooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: value,
                      }
                      if (value == '') {
                        updateObject = {
                          [propKey]: null,
                        }
                      }
                      DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                }
              })
            }}
          />
        </Box>
      )
      // Return
      return <Box className="tw-mb-1">{editableFieldJSX}</Box>
    },
  },
  registration_required: {
    key: 'registration_required',
    label: rLIB('Registration Required'),
    type: 'editable_multiple_choice_static',
    options: [
      { key: 'Yes', value: 'Yes' },
      { key: 'No', value: 'No' },
    ],
  },
  permit_required: {
    key: 'permit_required',
    label: rLIB('Permit Required'),
    type: 'editable_multiple_choice_static',
    options: [
      { key: 'Yes', value: 'Yes' },
      { key: 'No', value: 'No' },
    ],
  },
  HEADER2: {
    key: 'HEADER2',
    type: 'header',
    label: rLIB('Design Guidance'),
  },
  fire_setback: {
    key: 'fire_setback',
    label: rLIB('Fire Setback'),
    type: 'editable_text_multiline',
  },
  snow_load: {
    key: 'snow_load',
    label: rLIB('Snow Load'),
    type: 'editable_text_multiline',
  },
  wind_load: {
    key: 'wind_load',
    label: rLIB('Wind Load'),
    type: 'editable_text_multiline',
  },
  building_code: {
    key: 'building_code',
    label: rLIB('Building Code'),
    type: 'editable_text',
  },
  fire_code: {
    key: 'fire_code',
    label: rLIB('Fire Code'),
    type: 'editable_text',
  },
  international_residential_code: {
    key: 'international_residential_code',
    label: rLIB('International Residential Code'),
    type: 'editable_text',
  },
  national_electric_code: {
    key: 'national_electric_code',
    label: rLIB('National Electric Code'),
    type: 'editable_text',
  },
  other_codes: {
    key: 'other_codes',
    label: rLIB('Other Codes'),
    type: 'editable_text_multiline',
  },
  code_years: {
    key: 'code_years',
    label: rLIB('Code Years'),
    type: 'editable_text',
  },
  grounding_requirement_notes: {
    key: 'grounding_requirement_notes',
    label: rLIB('Grounding Requirement Notes'),
    type: 'editable_text_multiline',
  },
  disconnect_requirement_notes: {
    key: 'disconnect_requirement_notes',
    label: rLIB('Disconnect Requirement Notes'),
    type: 'editable_text_multiline',
  },
  wire_sizing_notes: {
    key: 'wire_sizing_notes',
    label: rLIB('Wire Sizing Notes'),
    type: 'editable_text_multiline',
  },
  conduit_notes: {
    key: 'conduit_notes',
    label: rLIB('Conduit Notes'),
    type: 'editable_text_multiline',
  },
  placard_requirement_notes: {
    key: 'placard_requirement_notes',
    label: rLIB('Placard Requirement Notes'),
    type: 'editable_text_multiline',
  },
  HEADER4: {
    key: 'HEADER4',
    type: 'header',
    label: rLIB('Permit Submission'),
  },
  permit_fees: {
    key: 'permit_fees',
    label: rLIB('Permit Fees'),
    type: 'editable_number',
  },
  estimated_turnaround: {
    key: 'estimated_turnaround',
    label: rLIB('Estimated Turnaround Time (Business Days)'),
    type: 'editable_number',
  },
  attachments_requirement: {
    key: 'attachments_requirement',
    label: rLIB('Attachments Requirement'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      const propKey = 'attachments_requirement'
      const currentValues = getProp(item, propKey, {}) || {}
      return (
        <Box className="tw-mb-1">
          {attachmentsRequirementOptions.map((option) => {
            const isChecked = !!currentValues[option.key]
            return (
              <Box
                key={option.key}
                className="tw-inline-block tw-mb-1"
                sx={{ opacity: isChecked ? 1 : 0.3 }}
              >
                <Checkbox
                  id={`${item.key}-${option.key}`}
                  checked={isChecked}
                  onChange={() => {
                    if (readOrWrite === 'write') {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: {
                          ...currentValues,
                          [option.key]: !isChecked,
                        },
                      }
                      getClientKey(hooks.uc_RootData_ClientKey, hooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                            .then(() => {
                              // resolve(true)
                            })
                            .catch((rej_DSD) => {
                              console.error(rej_DSD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }
                  }}
                  size="small"
                  color="primary"
                  className="tw-p-1"
                />
                <Typography
                  variant="body2"
                  component="label"
                  htmlFor={`${item.key}-${option.key}`}
                  className="tw-cursor-pointer"
                >
                  {option.label}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    },
  },
  documents_must_be_combined: {
    key: 'documents_must_be_combined',
    label: rLIB('Documents Must Be Combined'),
    type: 'editable_multiple_choice_static',
    options: [
      { key: 'Yes', value: 'Yes' },
      { key: 'No', value: 'No' },
    ],
  },
  submission_modes: {
    key: 'submission_modes',
    label: rLIB('Submission Modes'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      const propKey = 'submission_modes'
      const currentValues = getProp(item, propKey, {}) || {}
      return (
        <Box className="tw-mb-1">
          {submissionModesOptions.map((option) => {
            const isChecked = !!currentValues[option.key]
            return (
              <Box
                key={option.key}
                className="tw-inline-block tw-mb-1"
                sx={{ opacity: isChecked ? 1 : 0.3 }}
              >
                <Checkbox
                  id={`${item.key}-${option.key}`}
                  checked={isChecked}
                  onChange={() => {
                    if (readOrWrite === 'write') {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: {
                          ...currentValues,
                          [option.key]: !isChecked,
                        },
                      }
                      getClientKey(hooks.uc_RootData_ClientKey, hooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                            .then(() => {
                              // resolve(true)
                            })
                            .catch((rej_DSD) => {
                              console.error(rej_DSD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }
                  }}
                  size="small"
                  color="primary"
                  className="tw-p-1"
                />
                <Typography
                  variant="body2"
                  component="label"
                  htmlFor={`${item.key}-${option.key}`}
                  className="tw-cursor-pointer"
                >
                  {option.label}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    },
  },
  solarapp_enabled: {
    key: 'solarapp_enabled',
    label: rLIB('SolarApp Enabled'),
    type: 'editable_multiple_choice_static',
    options: [
      { key: 'Yes', value: 'Yes' },
      { key: 'No', value: 'No' },
    ],
  },
  solar_application_link: {
    key: 'solar_application_link',
    label: rLIB('Solar Application Link'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      let propKey = 'solar_application_link'
      if (getProp(item, propKey, null) === '') {
        item[propKey] = null
      }
      let editableFieldJSX = <></>
      if (item[propKey] != null) {
        const urlValue = getProp(item, propKey, '') || ''
        editableFieldJSX = (
          <Link
            href={urlValue.startsWith('http') ? urlValue : `http://${urlValue}`} // Ensure it starts with http:// or https://
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Security
            sx={{
              'opacity': 1,
              'fontWeight': 'normal',
              'color': themeVariables.primary_main,
              '&:hover': {
                opacity: 1,
                cursor: 'pointer',
              },
            }}
          >
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {getProp(item, propKey, '')}
            </Typography>
          </Link>
        )
      } else {
        editableFieldJSX = (
          <Box
            component="span"
            className="tw-opacity-30 tw-italic"
            sx={{ fontWeight: 'normal' }}
          >
            {rLIB('Missing')}
          </Box>
        )
      }
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectTextEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(item, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                if (readOrWrite === 'write') {
                  getClientKey(hooks.uc_RootData_ClientKey, hooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: value,
                      }
                      if (value == '') {
                        updateObject = {
                          [propKey]: null,
                        }
                      }
                      DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                }
              })
            }}
          />
        </Box>
      )
      // Return
      return <Box className="tw-mb-1">{editableFieldJSX}</Box>
    },
  },
  hu_application_link: {
    key: 'hu_application_link',
    label: rLIB('HU Application Link'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      let propKey = 'hu_application_link'
      if (getProp(item, propKey, null) === '') {
        item[propKey] = null
      }
      let editableFieldJSX = <></>
      if (item[propKey] != null) {
        const urlValue = getProp(item, propKey, '') || ''
        editableFieldJSX = (
          <Link
            href={urlValue.startsWith('http') ? urlValue : `http://${urlValue}`} // Ensure it starts with http:// or https://
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Security
            sx={{
              'opacity': 1,
              'fontWeight': 'normal',
              'color': themeVariables.primary_main,
              '&:hover': {
                opacity: 1,
                cursor: 'pointer',
              },
            }}
          >
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {getProp(item, propKey, '')}
            </Typography>
          </Link>
        )
      } else {
        editableFieldJSX = (
          <Box
            component="span"
            className="tw-opacity-30 tw-italic"
            sx={{ fontWeight: 'normal' }}
          >
            {rLIB('Missing')}
          </Box>
        )
      }
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectTextEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(item, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                if (readOrWrite === 'write') {
                  getClientKey(hooks.uc_RootData_ClientKey, hooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: value,
                      }
                      if (value == '') {
                        updateObject = {
                          [propKey]: null,
                        }
                      }
                      DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                }
              })
            }}
          />
        </Box>
      )
      // Return
      return <Box className="tw-mb-1">{editableFieldJSX}</Box>
    },
  },
  payment_modes_accepted: {
    key: 'payment_modes_accepted',
    label: rLIB('Payment Modes Accepted'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      const propKey = 'payment_modes_accepted'
      const currentValues = getProp(item, propKey, {}) || {}
      return (
        <Box className="tw-mb-1">
          {paymentModesAcceptedOptions.map((option) => {
            const isChecked = !!currentValues[option.key]
            return (
              <Box
                key={option.key}
                className="tw-inline-block tw-mb-1"
                sx={{ opacity: isChecked ? 1 : 0.3 }}
              >
                <Checkbox
                  id={`${item.key}-${option.key}`}
                  checked={isChecked}
                  onChange={() => {
                    if (readOrWrite === 'write') {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: {
                          ...currentValues,
                          [option.key]: !isChecked,
                        },
                      }
                      getClientKey(hooks.uc_RootData_ClientKey, hooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                            .then(() => {
                              // resolve(true)
                            })
                            .catch((rej_DSD) => {
                              console.error(rej_DSD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }
                  }}
                  size="small"
                  color="primary"
                  className="tw-p-1"
                />
                <Typography
                  variant="body2"
                  component="label"
                  htmlFor={`${item.key}-${option.key}`}
                  className="tw-cursor-pointer"
                >
                  {option.label}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    },
  },
  pe_stamp_requirement: {
    key: 'pe_stamp_requirement',
    label: rLIB('PE Stamp Requirement'),
    type: 'editable_text',
  },
  ee_stamp_requirements: {
    key: 'ee_stamp_requirements',
    label: rLIB('EE Stamp Requirements'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      const propKey = 'ee_stamp_requirements'
      const currentValues = getProp(item, propKey, {}) || {}
      return (
        <Box className="tw-mb-1">
          {eeStampRequirementsOptions.map((option) => {
            const isChecked = !!currentValues[option.key]
            return (
              <Box
                key={option.key}
                className="tw-inline-block tw-mb-1"
                sx={{ opacity: isChecked ? 1 : 0.3 }}
              >
                <Checkbox
                  id={`${item.key}-${option.key}`}
                  checked={isChecked}
                  onChange={() => {
                    if (readOrWrite === 'write') {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: {
                          ...currentValues,
                          [option.key]: !isChecked,
                        },
                      }
                      getClientKey(hooks.uc_RootData_ClientKey, hooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                            .then(() => {
                              // resolve(true)
                            })
                            .catch((rej_DSD) => {
                              console.error(rej_DSD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }
                  }}
                  size="small"
                  color="primary"
                  className="tw-p-1"
                />
                <Typography
                  variant="body2"
                  component="label"
                  htmlFor={`${item.key}-${option.key}`}
                  className="tw-cursor-pointer"
                >
                  {option.label}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    },
  },
  as_built_requirements: {
    key: 'as_built_requirements',
    label: rLIB('As Built Requirements'),
    type: 'editable_text_multiline',
  },
  hoa_pre_approval_required: {
    key: 'hoa_pre_approval_required',
    label: rLIB('HOA Pre-Approval Required'),
    type: 'editable_multiple_choice_static',
    options: [
      { key: 'Yes', value: 'Yes' },
      { key: 'No', value: 'No' },
    ],
  },
  r_and_r_required: {
    key: 'r_and_r_required',
    label: rLIB('Required for R&Rs'),
    type: 'editable_multiple_choice_static',
    options: [
      { key: 'Yes', value: 'Yes' },
      { key: 'No', value: 'No' },
    ],
  },
  HEADER5: {
    key: 'HEADER5',
    type: 'header',
    label: rLIB('Inspections'),
  },
  inspection_type: {
    key: 'inspection_type',
    label: rLIB('Inspection Type'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      const propKey = 'inspection_type'
      const currentValues = getProp(item, propKey, {}) || {}
      return (
        <Box className="tw-mb-1">
          {inspectionTypeOptions.map((option) => {
            const isChecked = !!currentValues[option.key]
            return (
              <Box
                key={option.key}
                className="tw-inline-block tw-mb-1"
                sx={{ opacity: isChecked ? 1 : 0.3 }}
              >
                <Checkbox
                  id={`${item.key}-${option.key}`}
                  checked={isChecked}
                  onChange={() => {
                    if (readOrWrite === 'write') {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: {
                          ...currentValues,
                          [option.key]: !isChecked,
                        },
                      }
                      getClientKey(hooks.uc_RootData_ClientKey, hooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                            .then(() => {
                              // resolve(true)
                            })
                            .catch((rej_DSD) => {
                              console.error(rej_DSD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }
                  }}
                  size="small"
                  color="primary"
                  className="tw-p-1"
                />
                <Typography
                  variant="body2"
                  component="label"
                  htmlFor={`${item.key}-${option.key}`}
                  className="tw-cursor-pointer"
                >
                  {option.label}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )
    },
  },
  solar_inspection_scheduling_notes: {
    key: 'solar_inspection_scheduling_notes',
    label: rLIB('Solar Inspection Scheduling Notes'),
    type: 'editable_text_multiline',
  },
  hu_inspection_scheduling_notes: {
    key: 'hu_inspection_scheduling_notes',
    label: rLIB('HU Inspection Scheduling Notes'),
    type: 'editable_text_multiline',
  },
  installation_guidance_for_crews: {
    key: 'installation_guidance_for_crews',
    label: rLIB('Installation Guidance for Crews'),
    type: 'editable_text_multiline',
  },
  HEADER6: {
    key: 'HEADER6',
    type: 'header',
    label: rLIB('ETW Permit'),
  },
  permit_holder: {
    key: 'permit_holder',
    label: rLIB('Permit Holder'),
    type: 'editable_text',
  },
  permit_renewal_date: {
    key: 'permit_renewal_date',
    label: rLIB('Permit Renewal Date'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      let propKey = 'permit_renewal_date'
      let lineJSX = <></>
      if (getProp(item, propKey, null) != null) {
        lineJSX = <Box className="tw-mb-1 tw-inline-block">{returnFormattedDate(getProp(item, propKey, null), 'MM/DD/YYYY')}</Box>
      } else {
        lineJSX = <Box className="tw-mb-1 tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
      }
      let iconJSX = <></>
      if (readOrWrite === 'write') {
        iconJSX = (
          <Box
            className="edit-icon tw-mb-1 tw-inline-block tw-ml-1"
            sx={{
              'cursor': 'pointer',
              'opacity': 0,
              '&:hover': {
                color: 'success.main',
              },
            }}
            onClick={() => {
              //
              let inputValue = getProp(item, propKey, null)
              if (inputValue != null) {
                inputValue = returnFormattedDate(inputValue, 'YYYY-MM-DD')
              }
              hooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {
                      [propKey]: inputValue,
                    },
                    formInputs: formInputs_PermitRenewalDate,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              // Add 12 hours
                              [propKey]: new Date(returnDateFromUnknownDateFormat(getProp(formSubmittedData, propKey, null)).getTime() + 12 * 60 * 60 * 1000),
                            }
                            DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                              .then(() => {
                                resolve(true)
                              })
                              .catch((rej_DSD) => {
                                reject(rej_DSD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: <>{rLIB('Edit Permit Renewal Date')}</>,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon
              icon="pen-circle"
              sx={{
                opacity: 0,
                transition: 'opacity 0.2s',
              }}
            />
          </Box>
        )
      }
      return (
        <Box
          className="tw-mb-1"
          sx={{
            '&:hover .edit-icon': {
              opacity: 1,
            },
          }}
        >
          {lineJSX}
          {iconJSX}
        </Box>
      )
    },
  },
  coi_required: {
    key: 'coi_required',
    label: rLIB('COI Required'),
    type: 'editable_multiple_choice_static',
    options: [
      { key: 'Yes', value: 'Yes' },
      { key: 'No', value: 'No' },
    ],
  },
  approved_date: {
    key: 'approved_date',
    label: rLIB('Approved Date'),
    type: 'custom_jsx',
    renderCustomViewJSX: (item: TsInterface_UnspecifiedObject, readOrWrite: string, hooks: any): JSX.Element => {
      let propKey = 'approved_date'
      let lineJSX = <></>
      if (getProp(item, propKey, null) != null) {
        lineJSX = <Box className="tw-mb-1 tw-inline-block">{returnFormattedDate(getProp(item, propKey, null), 'MM/DD/YYYY')}</Box>
      } else {
        lineJSX = <Box className="tw-mb-1 tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
      }
      let iconJSX = <></>
      if (readOrWrite === 'write') {
        iconJSX = (
          <Box
            className="edit-icon tw-mb-1 tw-inline-block tw-ml-1"
            sx={{
              'cursor': 'pointer',
              'opacity': 0,
              '&:hover': {
                color: 'success.main',
              },
            }}
            onClick={() => {
              //
              let inputValue = getProp(item, propKey, null)
              if (inputValue != null) {
                inputValue = returnFormattedDate(inputValue, 'YYYY-MM-DD')
              }
              hooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {
                      [propKey]: inputValue,
                    },
                    formInputs: formInputs_ApprovedDate,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              // Add 12 hours
                              [propKey]: new Date(returnDateFromUnknownDateFormat(getProp(formSubmittedData, propKey, null)).getTime() + 12 * 60 * 60 * 1000),
                            }
                            DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, item.key), updateObject)
                              .then(() => {
                                resolve(true)
                              })
                              .catch((rej_DSD) => {
                                reject(rej_DSD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: <>{rLIB('Edit Approved Date')}</>,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon
              icon="pen-circle"
              sx={{
                opacity: 0,
                transition: 'opacity 0.2s',
              }}
            />
          </Box>
        )
      }
      return (
        <Box
          className="tw-mb-1"
          sx={{
            '&:hover .edit-icon': {
              opacity: 1,
            },
          }}
        >
          {lineJSX}
          {iconJSX}
        </Box>
      )
    },
  },
  application_renewal_notes: {
    key: 'application_renewal_notes',
    label: rLIB('Application Renewal Notes'),
    type: 'editable_text_multiline',
  },
}

// Open View Dialog
const openViewDialog = (itemData: TsInterface_UnspecifiedObject, inputHooks: TsInterface_InputHooksObject): void => {
  openMasterDataDialog(
    documentRef,
    collectionRef,
    itemData.key,
    viewConfig,
    {
      importAliasesDocumentRef,
      importAliasesCollectionRef,
      importAliasesQuery,
    },
    {
      forumThreadCollectionDatabaseEndpoint,
      forumThreadDocumentDatabaseEndpoint,
      forumMessageCollectionDatabaseEndpoint,
      forumMessageDocumentDatabaseEndpoint,
      forumMessageStorageEndpoint,
    },
    {
      fileSystemCollectionDatabaseEndpoint,
      fileSystemDocumentDatabaseEndpoint,
      fileSystemStorageEndpoint,
    },
    inputHooks.uc_setUserInterface_CustomDialogDisplay,
    masterData_Jurisdictions,
  )
}

// Text
const textOptions = {
  item_text: rLIB('Jurisdiction'),
  new_item_text: rLIB('New Jurisdiction'),
  edit_item_text: rLIB('Edit Jurisdiction'),
  import_items_text: rLIB('Import Jurisdictions'),
  delete_item_text: rLIB('Delete Jurisdiction'),
  confirm_delete_text: rLIB('Are you sure you want to delete this jurisdiction?'),
  undelete_item_text: rLIB('Undelete Jurisdiction'),
  confirm_undelete_text: rLIB('Are you sure you want to undelete this jurisdiction?'),
}

// New Form
const formInputs_NewItem: TsInterface_FormInputs = {
  name: {
    key: 'name',
    label: rLIB('Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
    placeholder: 'Addison, Alameda, Aurora',
  },
  ...formInputs_ContactInfo,
  ...formInputs_DesignGuidance,
  ...formInputs_PermitSubmissions,
  ...formInputs_Inspections,
  ...formInputs_ETWPermit,
}

// Edit Form
const formInputs_EditItem: TsInterface_FormInputs = {
  ...formInputs_ContactInfo,
  ...formInputs_DesignGuidance,
  ...formInputs_PermitSubmissions,
  ...formInputs_Inspections,
  ...formInputs_ETWPermit,
}

// Import Mapping
const importMappingOptions = {
  name: { key: 'name', required: true, label: rLIB('Name', false), automatch_properties: ['Name'] },
  state: { key: 'state', required: false, label: rLIB('State', false), automatch_properties: ['State'] },
  type: { key: 'type', required: false, label: rLIB('Type', false), automatch_properties: ['Type'] },
  contact_name: {
    key: 'contact_name',
    required: false,
    label: rLIB('Department Name / Attn', false),
    automatch_properties: ['Contact Name', 'Contact name', 'contact name', 'Department Name / Attn', 'Department Name'],
  },

  address: { key: 'address', required: false, label: rLIB('Address', false), automatch_properties: ['Address'] },
  phone_number: { key: 'phone_number', required: false, label: rLIB('Phone Number', false), automatch_properties: ['Phone Number', 'Phone number'] },
  additional_contact_information: {
    key: 'additional_contact_information',
    required: false,
    label: rLIB('Additional Contact Information', false),
    automatch_properties: ['Additional Contact Information'],
  },
  email: { key: 'email', required: false, label: rLIB('Email', false), automatch_properties: ['Email'] },
  website: { key: 'website', required: false, label: rLIB('Website', false), automatch_properties: ['Website'] },
  fire_setback: { key: 'fire_setback', required: false, label: rLIB('Fire Setbacks', false), automatch_properties: ['Fire Setbacks'] },
  snow_load: { key: 'snow_load', required: false, label: rLIB('Snow Load', false), automatch_properties: ['Snow Load'] },
  wind_load: { key: 'wind_load', required: false, label: rLIB('Wind Load', false), automatch_properties: ['Wind Load'] },
  fire_code: { key: 'fire_code', required: false, label: rLIB('Fire Code', false), automatch_properties: ['Fire Code'] },

  building_code: { key: 'building_code', required: false, label: rLIB('Building Code', false), automatch_properties: ['Building Code'] },
  international_residential_code: {
    key: 'international_residential_code',
    required: false,
    label: rLIB('International Residential Code', false),
    automatch_properties: ['International Residential Code'],
  },
  national_electric_code: {
    key: 'national_electric_code',
    required: false,
    label: rLIB('National Electric Code', false),
    automatch_properties: ['National Electric Code'],
  },

  code_years: {
    key: 'code_years',
    required: false,
    label: rLIB('Code Years', false),
    automatch_properties: ['Code Years', 'Code years (Building, Electrical, Fire)', 'Code years'],
  },
  grounding_requirement_notes: {
    key: 'grounding_requirement_notes',
    required: false,
    label: rLIB('Grounding Requirement Notes', false),
    automatch_properties: ['Grounding Requirement Notes'],
  },
  disconnect_requirement_notes: {
    key: 'disconnect_requirement_notes',
    required: false,
    label: rLIB('Disconnect Requirement Notes', false),
    automatch_properties: ['Disconnect Requirement Notes'],
  },
  wire_sizing_notes: { key: 'wire_sizing_notes', required: false, label: rLIB('Wire Sizing Notes', false), automatch_properties: ['Wire Sizing Notes'] },
  conduit_notes: { key: 'conduit_notes', required: false, label: rLIB('Conduit Notes', false), automatch_properties: ['Conduit Notes'] },
  placard_requirement_notes: {
    key: 'placard_requirement_notes',
    required: false,
    label: rLIB('Placard Requirement Notes', false),
    automatch_properties: ['Placard Requirement Notes'],
  },
  permit_fees: { key: 'permit_fees', required: false, label: rLIB('Permit Fees'), automatch_properties: ['Permit Fees', 'Permit fees ($)'] },
  estimated_turnaround: {
    key: 'estimated_turnaround',
    required: false,
    label: rLIB('Estimated Turnaround Time', false),
    automatch_properties: ['Estimated Turnaround Time', 'Estimated Turnarond time (business days)'],
  },
  attachments_requirement: {
    key: 'attachments_requirement',
    required: false,
    label: rLIB('Attachments Requirement', false),
    automatch_properties: ['Attachments Requirement', 'Attachments requirement'],
  },
  submission_modes: {
    key: 'submission_modes',
    required: false,
    label: rLIB('Submission Modes', false),
    automatch_properties: ['Submission Modes', 'Sub. Mode', 'Submission Mode'],
  },
  solarapp_enabled: {
    key: 'solarapp_enabled',
    required: false,
    label: rLIB('SolarApp Enabled', false),
    automatch_properties: ['SolarApp Enabled', 'SolarApp Enabled?'],
  },
  portal_website_address: {
    key: 'portal_website_address',
    required: false,
    label: rLIB('Portal Website Address', false),
    automatch_properties: ['Portal Website Address', 'Portal website address'],
  },
  solar_application_link: {
    key: 'solar_application_link',
    required: false,
    label: rLIB('Solar Application Link', false),
    automatch_properties: ['Solar Application Link', 'Solar application link'],
  },
  hu_application_link: {
    key: 'hu_application_link',
    required: false,
    label: rLIB('HU Application Link', false),
    automatch_properties: ['HU Application Link', 'HU application link'],
  },
  payment_modes_accepted: {
    key: 'payment_modes_accepted',
    required: false,
    label: rLIB('Payment Modes Accepted', false),
    automatch_properties: ['Payment Modes Accepted', 'Payment modes accepted'],
  },
  pe_stamp_requirement: {
    key: 'pe_stamp_requirement',
    required: false,
    label: rLIB('PE Stamp Requirement', false),
    automatch_properties: ['PE Stamp Requirement', 'PE Stamp requirements'],
  },
  ee_stamp_requirements: {
    key: 'ee_stamp_requirements',
    required: false,
    label: rLIB('EE Stamp Requirements', false),
    automatch_properties: ['EE Stamp Requirements', 'EE Stamp requirements'],
  },
  as_built_requirements: {
    key: 'as_built_requirements',
    required: false,
    label: rLIB('As Built Requirements', false),
    automatch_properties: ['As Built Requirements', 'As-built requirements'],
  },
  hoa_pre_approval_required: {
    key: 'hoa_pre_approval_required',
    required: false,
    label: rLIB('HOA Pre-Approval Required', false),
    automatch_properties: ['HOA Pre-Approval Required', 'HOA pre-approval required?'],
  },
  inspection_type: { key: 'inspection_type', required: false, label: rLIB('Inspection Type', false), automatch_properties: ['Inspection Type'] },
  solar_inspection_scheduling_notes: {
    key: 'solar_inspection_scheduling_notes',
    required: false,
    label: rLIB('Solar Inspection Scheduling Notes', false),
    automatch_properties: ['Solar Inspection Scheduling Notes'],
  },
  hu_inspection_scheduling_notes: {
    key: 'hu_inspection_scheduling_notes',
    required: false,
    label: rLIB('HU Inspection Scheduling Notes', false),
    automatch_properties: ['HU Inspection Scheduling Notes'],
  },

  application_renewal_notes: {
    key: 'application_renewal_notes',
    required: false,
    label: rLIB('Application Renewal Notes', false),
    automatch_properties: ['Application Renewal Notes', 'Application / Renewal Notes'],
  },

  installation_guidance_for_crews: {
    key: 'installation_guidance_for_crews',
    required: false,
    label: rLIB('Installation Guidance for Crews', false),
    automatch_properties: ['Installation Guidance for Crews'],
  },
  permit_holder: { key: 'permit_holder', required: false, label: rLIB('Permit Holder', false), automatch_properties: ['Permit Holder', 'Permit holder'] },
  permit_renewal_date: {
    key: 'permit_renewal_date',
    required: false,
    label: rLIB('Permit Renewal Date', false),
    automatch_properties: ['Permit Renewal Date', 'Permit renewal date'],
  },
  coi_required: { key: 'coi_required', required: false, label: rLIB('COI Required', false), automatch_properties: ['COI Required', 'COI required'] },
  registration_required: {
    key: 'registration_required',
    required: false,
    label: rLIB('Registration Required', false),
    automatch_properties: ['Registration Required', 'Registration Reqd?', 'Registration Required?'],
  },

  permit_required: {
    key: 'permit_required',
    required: false,
    label: rLIB('Permit Required', false),
    automatch_properties: ['Permit Required', 'Permit Reqd?', 'Permit Required?'],
  },
  // applied_date: { key: 'applied_date', required: false, label: rLIB('Applied Date', false), automatch_properties: ['Applied Date', 'Applied date'] },
  approved_date: { key: 'approved_date', required: false, label: rLIB('Approved Date', false), automatch_properties: ['Approved Date', 'Approved date'] },
}

// Table Columns
const tableColumns: TsInterface_TableColumns = {
  manage: TableCellManage({
    view_dialog: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: rLIB('View'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openViewDialog(rowData, tableHooks as any)
      },
    },
    // view: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="magnifying-glass"
    //     />
    //   ),
    //   label: rLIB('View (Old)'),
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     if (rowData.key != null) {
    //       directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseJurisdictionViewPage.url(rowData.key as string))
    //     }
    //   },
    // },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      label: rLIB('Delete'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openDeleteDataItemDialog(rowData, tableAdditionalData, tableHooks, textOptions, deleteItem)
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="magic-wand-sparkles"
        />
      ),
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      label: rLIB('Undelete'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openUndeleteDataItemDialog(rowData, tableAdditionalData, tableHooks, textOptions, undeleteItem)
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Name'), 'name'),
  state: TableCellBasic('state', 'State', 'state'),
  type: TableCellBasic('type', 'Type', 'type'),
  phone: TableCellPhone('phone_number', rLIB('Phone'), 'phone_number'),
  email: TableCellBasic('email', rLIB('Email'), 'email'),
  website: TableCellURL('website', 'Website', 'website'),

  permit_fees: TableCellCurrency('permit_fees', 'Permit Fees', 'permit_fees'),
  solar_application_link: TableCellURL('solar_application_link', 'Solar Application Link', 'solar_application_link'),
}

// Table Settings
const rJSX_MasterDataSearchResult = (
  option: TsInterface_UnspecifiedObject,
  searchInputValue: string | null,
  inputHooks: TsInterface_InputHooksObject,
): JSX.Element => {
  let searchResultJSX = (
    <Box
      sx={{ 'padding': '2px', 'marginLeft': '8px', 'marginRight': '8px', 'cursor': 'pointer', '&:hover': { backgroundColor: themeVariables.warning_main } }}
      className="tw-cursor-pointer"
      onClick={() => {
        getClientKey(inputHooks.uc_RootData_ClientKey, inputHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseGetDocument(documentRef(res_GCK.clientKey, option.id))
              .then((res_DGD) => {
                openViewDialog(res_DGD.data, inputHooks)
              })
              .catch((rej_DGD) => {
                console.error(rej_DGD)
              })
          })
          .catch((rej_GCK) => {
            console.error(rej_GCK)
          })
      }}
    >
      {option.name}
    </Box>
  )
  return searchResultJSX
}

const tableSettings: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  search_settings_database: {
    search_type: 'meilisearch',
    search_client_key: '',
    search_index_key: searchIndexKey,
    search_filters: ['status = "active"'],
    search_result_renderer: rJSX_MasterDataSearchResult,
  },
  searchable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

// Create
const createItem = (
  clientKey: string,
  formSubmittedData: TsInterface_FormSubmittedData,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const { jurisdiction_name, key, displayState, displayType, displayName } = normalizeJurisdictionData(
      formSubmittedData.name,
      formSubmittedData.state,
      formSubmittedData.type,
    )

    let updateObject: TsInterface_UnspecifiedObject = {
      ...formSubmittedData,
      key,
      jurisdiction_name,
      name: displayName,
      state: displayState,
      type: displayType,
      status: 'active',
    }

    DatabaseSetMergeDocument(documentRef(clientKey, key), updateObject)
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Update
const updateItem = (clientKey: string, itemKey: string, updateObject: TsInterface_UnspecifiedObject): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), updateObject)
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Delete
const deleteItem = (clientKey: string, itemKey: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), { status: 'deleted' })
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Undelete
const undeleteItem = (clientKey: string, itemKey: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), { status: 'active' })
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

const cleanRowData = (data: Record<string, any>): Record<string, any> => {
  const cleanData: Record<string, any> = {}

  for (const key in data) {
    const value = data[key]

    if (value === undefined || value === null) {
      continue // Skip undefined/null fields
    }

    if (typeof value === 'string' && value.trim() === '') {
      continue // Skip empty strings (optional, remove if Firestore requires them)
    }

    // Skip empty objects (this covers your empty maps issue)
    if (typeof value === 'object' && value !== null && Object.keys(value).length === 0) {
      continue
    }

    cleanData[key] = value
  }

  return cleanData
}

const importItems = (
  clientKey: string,
  spreadsheetData: TsInterface_UnspecifiedObject,
  importAdditionalData: TsInterface_UnspecifiedObject,
  importHooks: TsInterface_ImportHooksObject,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = []

    console.log(`🚀 Starting import for clientKey: ${clientKey}`)
    console.log(`📊 Raw spreadsheet data received:`, JSON.parse(JSON.stringify(spreadsheetData)))

    for (let loopRowKey in spreadsheetData) {
      console.log(`🔹 Processing row ${loopRowKey}`)

      try {
        // -- START: Per-row try/catch to catch any synchronous errors --
        const rawRow = spreadsheetData[loopRowKey]
        console.log(`   ➡️ Raw row data:`, JSON.parse(JSON.stringify(rawRow)))

        const rawName = rawRow.name || ''
        const rawState = rawRow.state || ''
        const rawType = rawRow.type || ''

        const { jurisdiction_name, key, displayState, displayType, displayName } = normalizeJurisdictionData(rawName, rawState, rawType)

        console.log(`   🔑 Normalized key: ${key}`)

        const rowData = {
          jurisdiction_name,
          name: displayName,
          key,
          status: 'active',
          state: displayState,
          type: displayType,

          contact_name: rawRow.contact_name,
          address: rawRow.address,
          phone_number: String(rawRow.phone_number || '').replace(/\D+/g, ''),

          email: rawRow.email,
          website: rawRow.website,
          fire_setback: rawRow.fire_setback,

          applied_date: convertExcelDate(rawRow.applied_date),
          approved_date: convertExcelDate(rawRow.approved_date),
          permit_renewal_date: convertExcelDate(rawRow.permit_renewal_date),

          // These are the multi-selects that need to be parsed into { [key: string]: boolean }
          attachments_requirement: parseMultiSelectField(rawRow.attachments_requirement),
          submission_modes: parseMultiSelectField(rawRow.submission_modes),
          inspection_type: parseMultiSelectField(rawRow.inspection_type),

          payment_modes_accepted: parseMultiSelectField(rawRow.payment_modes_accepted),

          snow_load: rawRow.snow_load,
          wind_load: rawRow.wind_load,
          code_years: rawRow.code_years,
          grounding_requirement_notes: rawRow.grounding_requirement_notes,
          disconnect_requirement_notes: rawRow.disconnect_requirement_notes,
          wire_sizing_notes: rawRow.wire_sizing_notes,
          conduit_notes: rawRow.conduit_notes,
          placard_requirement_notes: rawRow.placard_requirement_notes,
          permit_fees: rawRow.permit_fees,
          estimated_turnaround: rawRow.estimated_turnaround,

          solarapp_enabled: rawRow.solarapp_enabled,
          portal_website_address: rawRow.portal_website_address,
          solar_application_link: rawRow.solar_application_link,
          hu_application_link: rawRow.hu_application_link,

          pe_stamp_requirement: rawRow.pe_stamp_requirement,
          ee_stamp_requirements: parseMultiSelectField(rawRow.ee_stamp_requirements),
          as_built_requirements: rawRow.as_built_requirements,
          hoa_pre_approval_required: rawRow.hoa_pre_approval_required,

          solar_inspection_scheduling_notes: rawRow.solar_inspection_scheduling_notes,
          hu_inspection_scheduling_notes: rawRow.hu_inspection_scheduling_notes,
          permit_holder: rawRow.permit_holder,

          coi_required: rawRow.coi_required,
          registration_required: rawRow.registration_required,
        }

        const cleanedRowData = cleanRowData(rowData)

        console.log(`   ✅ Cleaned row data for ${key}:`, JSON.parse(JSON.stringify(cleanedRowData)))

        updateArray.push({
          type: 'setMerge',
          ref: documentRef(clientKey as string, key),
          data: cleanedRowData,
        })
        // -- END: Per-row try/catch --
      } catch (err) {
        console.error(`❌ Error processing row ${loopRowKey}:`, err)
        // If you want to stop the entire import on first error:
        return reject(err)
        // Otherwise, you could continue to the next row:
        // break or just skip.
      }
    }

    console.log('📦 Final update array (batch payload):', JSON.parse(JSON.stringify(updateArray)))

    DatabaseStagedBatchUpdate(updateArray)
      .then((res_DSBU) => {
        console.log('✅ Batch update successful:', res_DSBU)
        resolve(res_DSBU)
      })
      .catch((rej_DSBU) => {
        console.error('❌ Batch update failed:', rej_DSBU)
        reject(rej_DSBU)
      })
  })
}

// View
// TODO:

///////////////////////////////
// Exports
///////////////////////////////

export const masterData_Jurisdictions = {
  uniqueFieldKey: uniqueFieldKey,
  new_item: {
    text: textOptions.new_item_text,
    form_inputs: formInputs_NewItem,
    form_settings: {},
    function: createItem,
  },
  import_items: {
    text: textOptions.import_items_text,
    mapping: importMappingOptions,
    function: importItems,
  },
  table: {
    columns: tableColumns,
    settings: tableSettings,
    database_ref: collectionRef,
  },
  full_exports: {
    documentRef: documentRef,
    collectionRef: collectionRef,
    activeCollectionRef: activeCollectionRef,
    importAliasesDocumentRef: importAliasesDocumentRef,
    importAliasesCollectionRef: importAliasesCollectionRef,
    importAliasesQuery: importAliasesQuery,
    textOptions: textOptions,
    formInputs_NewItem: formInputs_NewItem,
    formInputs_EditItem: formInputs_EditItem,
    importMappingOptions: importMappingOptions,
    tableColumns: tableColumns,
    tableSettings: tableSettings,
    createItem: createItem,
    updateItem: updateItem,
    deleteItem: deleteItem,
    undeleteItem: undeleteItem,
    importItems: importItems,
  },
}
