///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material/'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveFinancePartners_Query } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_ActiveInstallPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/install_partner'
import { DatabaseRef_ActiveOpportunityMilestones_Query } from 'rfbp_aux/services/database_endpoints/directory/opportunity_milestones'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import {
  DatabaseRef_ActiveSalesOpportunities_v2_Query,
  DatabaseRef_SalesOpportunities_v2_Collection,
  DatabaseRef_SalesOpportunity_v2_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import { DirectMultipleChoiceEdit } from 'rfbp_core/components/direct_edits/direct_multiple_choice_edit'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { IconMenu } from 'rfbp_core/components/icon_menu/icon_menu'
import {
  TableBasic,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  formatCurrency,
  getProp,
  keyFromString,
  objectToArray,
  replaceNewlinesWithHtmlBreaks,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { formInputs_NewStickyNote, formInputs_StickyNote, formSettings_StickyNote } from '../projects/forms/sticky_notes'
import { returnOpportunityNewFormInputs } from './data/import_mapping'
import { OpportunityStickyNotesDialog } from './dialogs/sticky_notes'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesPipelineIndexPage']['key']

const tableSettings_OpportunitiesByMilestone: TsInterface_TableSettings = {
  paginated: false,
  // pagination_rows_per_page_default: 100,
  // pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'asc',
  sort_property_default: 'unique_identifier',
  collapsible_columns: true,
}

const rJSX_Tags = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  project: TsInterface_UnspecifiedObject,
  projectKey: string,
  visiblePacingTags: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  readOrWrite: 'read' | 'write',
): JSX.Element => {
  let labelsJSX = (
    <Box>
      {objectToArray(project['tags'])
        .sort(dynamicSort('text', null))
        .map((loopPacingTag: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>
            {rJSX_Tag(
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              project,
              projectKey,
              visiblePacingTags,
              uc_setUserInterface_FormDialogDisplay,
              loopPacingTag,
              readOrWrite,
            )}
          </Box>
        ))}
      {objectToArray(project['tags']).length == 0 ? <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('No Tags')}</Box> : <></>}
      {readOrWrite === 'write' ? (
        <Box>
          <Icon
            icon="circle-plus"
            className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
            tooltip={rLIB('Add Tag')}
            tooltipPlacement="right"
            onClick={() => {
              openProjectTagDialog(uc_RootData_ClientKey, visiblePacingTags, project, projectKey, uc_setUserInterface_FormDialogDisplay, 'new', null)
            }}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
  return labelsJSX
}

const rJSX_Tag = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  project: TsInterface_UnspecifiedObject,
  projectKey: string,
  visiblePacingTags: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  pacingTag: TsInterface_UnspecifiedObject,
  readOrWrite: 'read' | 'write',
): JSX.Element => {
  let fullTagJSX = <></>
  let labelJSX = <></>
  if (pacingTag != null && pacingTag.text != null && pacingTag.text !== '' && pacingTag.color != null && pacingTag.color !== '') {
    let color = getProp(pacingTag, 'color', themeVariables.gray_500)
    labelJSX = (
      <Box
        className="tw-inline-block tw-px-2 tw-py-1"
        sx={{ backgroundColor: color, borderRadius: '6px', color: themeVariables.white }}
      >
        <Icon
          icon="tag"
          // sx={{ fontSize: '18px' }}
          className="tw-mr-2"
        />
        {pacingTag.text}
      </Box>
    )
  }

  if (readOrWrite === 'write') {
    fullTagJSX = (
      <IconMenu
        icon={labelJSX}
        menuItems={[
          {
            icon: 'pen-to-square',
            text: rLIB('Edit'),
            onClick: () => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // open edit modal
                  openProjectTagDialog(res_GCK.clientKey, visiblePacingTags, project, projectKey, uc_setUserInterface_FormDialogDisplay, 'edit', pacingTag.key)
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            },
          },
          {
            icon: 'trash',
            text: rLIB('Delete'),
            onClick: () => {
              let updateObject = {
                tags: project.tags,
              }
              delete updateObject.tags[pacingTag.key]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // open edit modal
                  DatabaseUpdateDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, projectKey), updateObject)
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            },
          },
        ]}
      />
    )
  } else {
    fullTagJSX = labelJSX
  }

  //   let editIconJSX = (
  //     <Icon
  //     icon="pen-circle"
  //     className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
  //     tooltip={rLIB('Edit')}
  //     tooltipPlacement="right"
  //     sx={{ fontSize: '18px' }}
  //     onClick={() => {
  //       getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //         .then((res_GCK) => {
  //           // open edit modal
  //           openProjectTagDialog(res_GCK.clientKey, visiblePacingTags, project, projectKey, uc_setUserInterface_FormDialogDisplay, 'edit', pacingTag.key)
  //         })
  //         .catch((rej_GCK) => {
  //           console.error(rej_GCK)
  //         })
  //     }}
  //   />
  // )
  // let deleteIconJSX = (
  //   <Icon
  //     icon="trash"
  //     className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_main"
  //     tooltip={rLIB('Delete')}
  //     tooltipPlacement="right"
  //     sx={{ fontSize: '18px' }}
  //     onClick={() => {
  //       console.log('delete')
  //     }}
  //   />
  // )
  return <Box className="tw-mb-1 tw-cursor-pointer">{fullTagJSX}</Box>
  // return (
  //   <Stack
  //     direction="row"
  //     className="tw-mb-1"
  //   >
  //     {labelJSX}
  //     {editIconJSX}
  //     {deleteIconJSX}
  //   </Stack>
  // )
}

const openProjectTagDialog = (
  clientKey: string,
  visiblePacingTags: TsInterface_UnspecifiedObject,
  project: TsInterface_UnspecifiedObject,
  projectKey: string,
  uc_setUserInterface_FormDialogDisplay: any,
  action: 'new' | 'edit',
  tagKey: string | null,
): void => {
  let quickSelectOptions: TsInterface_UnspecifiedObject = {}
  for (let loopPacingLabelKey in visiblePacingTags) {
    let loopPacingLabel = visiblePacingTags[loopPacingLabelKey]
    quickSelectOptions[loopPacingLabelKey] = {
      value: (
        <Box>
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
            sx={{ background: loopPacingLabel.color, borderRadius: '6px', color: themeVariables.white }}
          >
            <Icon
              type="solid"
              icon="tag"
              className="tw-mr-2"
            />
            {loopPacingLabel.text}
          </Box>
        </Box>
      ),
      key: loopPacingLabelKey,
    }
  }
  let formInputs: TsInterface_FormInputs = {}
  if (objectToArray(quickSelectOptions).length > 0) {
    formInputs['quick_select'] = {
      data_type: 'string',
      input_type: 'multiple_choice_radio',
      key: 'quick_select',
      label: <>{rLIB('Quick Select')}</>,
      required: false,
      options: objectToArray(quickSelectOptions),
      submit_on_change: true,
    }
  }
  formInputs['text'] = {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'text',
    label: <>{rLIB('Label')}</>,
    required: true,
  }
  formInputs['color'] = {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'color',
    label: <>{rLIB('Color')}</>,
    required: false,
    options: [
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.error_main, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Red')}
            </Box>
          </Box>
        ),
        key: themeVariables.error_main,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.orange_500, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Orange')}
            </Box>
          </Box>
        ),
        key: themeVariables.orange_500,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.warning_main, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Yellow')}
            </Box>
          </Box>
        ),
        key: themeVariables.warning_main,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.success_main, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Green')}
            </Box>
          </Box>
        ),
        key: themeVariables.success_main,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.info_main, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Blue')}
            </Box>
          </Box>
        ),
        key: themeVariables.info_main,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.purple_500, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Purple')}
            </Box>
          </Box>
        ),
        key: themeVariables.purple_500,
      },
    ],
  }

  let existingTag: TsInterface_UnspecifiedObject = {}
  if (action === 'edit' && tagKey != null && tagKey !== '' && project != null && project.tags != null && project.tags[tagKey] != null) {
    existingTag = project.tags[tagKey]
  }

  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: existingTag,
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            if (action === 'new') {
              tagKey = uuidv4()
            }
            let updateObject = {}
            if (formSubmittedData.quick_select != null) {
              // Split at last instance of '-'
              let quickSelectColor = formSubmittedData.quick_select.split('-').pop()
              // Get string before last instance of '-'
              let quickSelectText = formSubmittedData.quick_select.substring(0, formSubmittedData.quick_select.lastIndexOf('-'))
              updateObject = {
                tags: {
                  [tagKey as string]: {
                    key: tagKey,
                    text: quickSelectText,
                    color: quickSelectColor,
                  },
                },
              }
            } else {
              updateObject = {
                tags: {
                  [tagKey as string]: {
                    key: tagKey,
                    text: formSubmittedData.text,
                    color: getProp(formSubmittedData, 'color', themeVariables.gray_500),
                  },
                },
              }
            }
            DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, projectKey), updateObject)
              .then((res_DSMD) => {
                resolve(true)
              })
              .catch((rej_DSMD) => {
                console.error(rej_DSMD)
                reject(rej_DSMD)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: (
          <>
            {rLIB('Edit Tag')} - {getProp(project, 'id_number', '')}
          </>
        ),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="tag"
          />
        ),
      },
    },
  })
}

const tableColumns_OpportunitiesByMilestone: TsInterface_TableColumns = {
  timestamp_snoozed_until: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let currentTimestamp = new Date().getTime()

        const updateSnoozeTimestamp = (days: number) => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              // Set timestamp to next midnight(s)
              const today = new Date()
              const nextMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate() + days + 1, 0, 0, 0)
              DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, rowData.key as string), {
                timestamp_snoozed_until: nextMidnight,
              })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
        const snoozeMenuItems = [
          {
            icon: 'snooze',
            text: rLIB('Unsnooze'),
            onClick: () => {
              updateSnoozeTimestamp(-1)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 1 day'),
            onClick: () => {
              updateSnoozeTimestamp(0)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 2 days'),
            onClick: () => {
              updateSnoozeTimestamp(1)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 3 days'),
            onClick: () => {
              updateSnoozeTimestamp(2)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 4 days'),
            onClick: () => {
              updateSnoozeTimestamp(3)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 5 days'),
            onClick: () => {
              updateSnoozeTimestamp(4)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 6 days'),
            onClick: () => {
              updateSnoozeTimestamp(5)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 7 days'),
            onClick: () => {
              updateSnoozeTimestamp(6)
            },
          },
        ]
        if (tableAdditionalData.readOrWrite === 'write') {
          if (
            rowData != null &&
            rowData.timestamp_snoozed_until != null &&
            returnTimestampFromUnknownDateFormat(rowData.timestamp_snoozed_until) > currentTimestamp
          ) {
            let daysUntilSnooze = Math.ceil((returnTimestampFromUnknownDateFormat(rowData.timestamp_snoozed_until) - currentTimestamp) / (1000 * 60 * 60 * 24))
            cellJSX = (
              <Stack
                direction="row"
                spacing={0.5}
              >
                <Box sx={{ marginTop: '-2px !important' }}>
                  <Typography sx={{ color: themeVariables.info_main, fontSize: '1.2rem', fontWeight: 'bold' }}>{daysUntilSnooze}</Typography>
                </Box>
                <IconMenu
                  icon={
                    <Icon
                      icon="alarm-snooze"
                      sx={{ fontSize: '1.3rem', color: themeVariables.info_main }}
                      className="tw-cursor-pointer"
                      tooltip={returnFormattedDate(rowData.timestamp_snoozed_until, 'h:mm a D MMM YY')}
                      tooltipPlacement="right"
                    />
                  }
                  menuItems={snoozeMenuItems}
                />
              </Stack>
            )
          } else {
            cellJSX = (
              <Box>
                <IconMenu
                  icon={
                    <Icon
                      icon="alarm-exclamation"
                      sx={{ fontSize: '1.3rem', color: themeVariables.warning_main }}
                      className="tw-cursor-pointer"
                    />
                  }
                  menuItems={snoozeMenuItems}
                />
              </Box>
            )
          }
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: 'timestamp_snoozed_until',
    },
  },
  unique_identifier: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-py-1 tw-px-2 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages['SalesPipelineViewPage'].url(rowData.key as string))
                }
              }}
            >
              {rowData.unique_identifier}
            </Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Unique ID')
      },
      header_sort_by: null,
    },
  },
  tags: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = rJSX_Tags(
          tableHooks.uc_RootData_ClientKey,
          tableHooks.uc_setRootData_ClientKey,
          rowData,
          rowData.key as string,
          tableAdditionalData.us_visibleTags,
          tableHooks.uc_setUserInterface_FormDialogDisplay,
          tableAdditionalData.readOrWrite,
        )

        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Tag')
      },
      header_sort_by: null,
    },
  },
  associated_customer_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box>
            <Box>{rowData.associated_customer_name}</Box>
            {rowData.system_size != null && parseFloat(rowData.system_size as string) > 0 ? (
              <Box className="tw-opacity-30 tw-italic">
                {rowData.system_size} {rLIB('kW')}
              </Box>
            ) : (
              <></>
            )}
            {rowData.financials_contract_amount != null && parseFloat(rowData.financials_contract_amount as string) > 0 ? (
              <Box className="tw-opacity-30 tw-italic">{formatCurrency(rowData.financials_contract_amount as number)}</Box>
            ) : (
              <></>
            )}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Customer')
      },
      header_sort_by: 'associated_customer_name',
    },
  },
  associated_sales_partner_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box>
            <Box>{rowData.associated_sales_partner_name}</Box>
            <Box className="tw-opacity-30 tw-italic">{rowData.associated_sales_rep_name}</Box>
            <Box className="tw-opacity-30 tw-italic">{rowData.associated_region_name}</Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Sales Partner')
      },
      header_sort_by: 'associated_sales_partner_name',
    },
  },
  associated_finance_partner_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box>
            <Box>{rowData.associated_finance_partner_name}</Box>
            <Box className="tw-opacity-30 tw-italic">{rowData.financials_product_sold}</Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Financing')
      },
      header_sort_by: 'associated_finance_partner_name',
    },
  },
  associated_milestone_key: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let selectedMilestoneName = 'Unsorted'
        if (
          rowData != null &&
          rowData.associated_milestone_key != null &&
          tableAdditionalData.us_activeOpportunityMilestones != null &&
          tableAdditionalData.us_activeOpportunityMilestones[rowData.associated_milestone_key as string] != null &&
          tableAdditionalData.us_activeOpportunityMilestones[rowData.associated_milestone_key as string]['name'] != null
        ) {
          selectedMilestoneName = tableAdditionalData.us_activeOpportunityMilestones[rowData.associated_milestone_key as string]['name']
        }
        let cellJSX = (
          <Box>
            <DirectMultipleChoiceEdit
              displayText={selectedMilestoneName}
              fullyClickable={false}
              selectedOption={{ key: getProp(rowData, 'associated_milestone_key', null) as string, value: selectedMilestoneName }}
              textCssClassName=""
              optionType={'static'}
              options={tableAdditionalData.milestoneOptions.sort(dynamicSort('order', 'asc'))}
              onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        associated_milestone_key: selectedOption.key,
                        associated_milestone_name: selectedOption.value,
                      }
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then(() => {
                          resolve(true)
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                })
              }}
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Milestone')
      },
      header_sort_by: 'associated_milestone_key',
    },
  },
  notes: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let stickyNoteContent = <></>
        let oldEditIcon = <></>
        let addIcon = <></>
        if (tableAdditionalData.readOrWrite === 'write') {
          addIcon = (
            <Icon
              icon="circle-plus"
              type="solid"
              className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
              tooltip={rLIB('New Sticky Note')}
              tooltipPlacement="top"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: {
                        date: returnFormattedDate(new Date(), 'YYYY-MM-DD'),
                      },
                      formInputs: formInputs_NewStickyNote,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_StickyNote,
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let newNoteId = uuidv4()
                          let updateObject = {
                            sticky_notes: {
                              [newNoteId]: {
                                timestamp: new Date(
                                  returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                                ),
                                note: formSubmittedData.note,
                                key: newNoteId,
                              },
                            },
                          }
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'warning',
                      formDialogHeaderText: rLIB('Add Sticky Note'),
                      formDialogIcon: (
                        <Icon
                          icon="note"
                          type="solid"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          )
          oldEditIcon = (
            <Icon
              icon="note"
              className="tw-mr-2 tw-text-secondary_main tw-cursor-pointer"
              size="xl"
              tooltip={rLIB('Edit Sticky Note')}
              tooltipPlacement="right"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: formInputs_StickyNote,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_StickyNote,
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, rowData.key as string), formSubmittedData)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'warning',
                      formDialogHeaderText: rLIB('Edit Sticky Note'),
                      formDialogIcon: (
                        <Icon
                          icon="note"
                          className="tw-mr-2"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          )
        } else {
          oldEditIcon = (
            <Icon
              icon="note"
              className="tw-mr-2 tw-text-secondary_main"
              size="xl"
            />
          )
        }
        let viewIconJSX = (
          <Icon
            icon="magnifying-glass"
            className=" tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-secondary_main"
            tooltip={rLIB('View Sticky Notes')}
            tooltipPlacement="top"
            onClick={() => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                tableHooks.uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <OpportunityStickyNotesDialog
                        clientKey={res_GCK.clientKey}
                        opportunityKey={rowData.key as string}
                        readOrWrite={tableAdditionalData.readOrWrite}
                      />
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              })
            }}
          />
        )

        let stickyNoteContentOld = <></>
        let hasOldStickyNote = false
        if (rowData != null && rowData.sticky_note != null && rowData.sticky_note !== '') {
          hasOldStickyNote = true
          stickyNoteContentOld = (
            <Box>
              <Box
                className="tw-inline-block tw-align-top"
                sx={{ width: '32px' }}
              >
                {oldEditIcon}
              </Box>
              <Box
                className="tw-inline-block"
                sx={{ width: 'calc(100% - 32px' }}
              >
                {replaceNewlinesWithHtmlBreaks(rowData.sticky_note as string)}
              </Box>
            </Box>
          )
        }

        const rJSX_EditIcon = (
          readOrWrite: 'read' | 'write',
          note: TsInterface_UnspecifiedObject,
          uc_RootData_ClientKey: any,
          uc_setRootData_ClientKey: any,
          uc_setUserInterface_FormDialogDisplay: any,
          uc_setUserInterface_ErrorDialogDisplay: any,
          projectKey: any,
        ) => {
          let editIconJSX = <></>
          if (readOrWrite === 'write') {
            editIconJSX = (
              <Tooltip title={rLIB('Edit Sticky Note')}>
                <Box
                  className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
                  onClick={() => {
                    uc_setUserInterface_FormDialogDisplay({
                      display: true,
                      form: {
                        form: {
                          formAdditionalData: {},
                          formData: {
                            note: note.note,
                            date: returnFormattedDate(note.timestamp, 'YYYY-MM-DD'),
                          },
                          formInputs: formInputs_NewStickyNote,
                          formOnChange: (
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formData: TsInterface_FormData,
                            formInputs: TsInterface_FormInputs,
                            formSettings: TsInterface_FormSettings,
                          ) => {},
                          formSettings: formSettings_StickyNote,
                          formSubmission: (
                            formSubmittedData: TsInterface_FormSubmittedData,
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formHooks: TsInterface_FormHooksObject,
                          ) => {
                            return new Promise((resolve, reject) => {
                              let updateObject = {
                                sticky_notes: {
                                  [note.key]: {
                                    note: formSubmittedData.note,
                                    timestamp: new Date(
                                      returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                                    ),
                                  },
                                },
                              }
                              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                                DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, projectKey), updateObject)
                                  .then((res_DSMD) => {
                                    resolve(res_DSMD)
                                  })
                                  .catch((rej_DSMD) => {
                                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                    reject(rej_DSMD)
                                  })
                              })
                            })
                          },
                        },
                        dialog: {
                          formDialogHeaderColor: 'warning',
                          formDialogHeaderText: rLIB('Edit Sticky Note'),
                          formDialogIcon: (
                            <Icon
                              icon="note"
                              type="solid"
                            />
                          ),
                        },
                      },
                    })
                  }}
                >
                  <Icon icon="pen-to-square"></Icon>
                </Box>
              </Tooltip>
            )
          }
          return editIconJSX
        }

        const rJSX_DeleteIcon = (
          readOrWrite: 'read' | 'write',
          noteKey: any,
          note: TsInterface_UnspecifiedObject,
          uc_RootData_ClientKey: any,
          uc_setRootData_ClientKey: any,
          uc_setUserInterface_ErrorDialogDisplay: any,
          uc_setUserInterface_ConfirmDialogDisplay: any,
          projectKey: any,
        ) => {
          let deleteIconJSX = <></>
          if (readOrWrite === 'write') {
            deleteIconJSX = (
              <Tooltip title={rLIB('Delete Sticky Note')}>
                <Box className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2">
                  <Icon
                    icon="trash"
                    onClick={() => {
                      uc_setUserInterface_ConfirmDialogDisplay({
                        display: true,
                        confirm: {
                          color: 'error',
                          header: <>{rLIB('Delete Sticky Note')}</>,
                          icon: <Icon icon="trash" />,
                          submit_callback: () => {
                            return new Promise((resolve, reject) => {
                              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  let updateObject = {
                                    sticky_notes: {
                                      [noteKey]: null,
                                    },
                                    sticky_notes_deleted: {
                                      [noteKey]: note,
                                    },
                                  }
                                  DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, projectKey), updateObject)
                                    .then((res_DSMD) => {
                                      resolve(res_DSMD)
                                    })
                                    .catch((rej_DSMD) => {
                                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                      reject(rej_DSMD)
                                    })
                                })
                                .catch((rej_GCK) => {
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                  reject(rej_GCK)
                                })
                            })
                          },
                          submit_text: rLIB('Delete'),
                          text: rLIB('Are you sure that you want to delete this sticky note?'),
                        },
                      })
                    }}
                  />
                </Box>
              </Tooltip>
            )
          }
          return deleteIconJSX
        }

        let stickyNotes = getProp(rowData, 'sticky_notes', {})
        if (objectToArray(stickyNotes).length > 0) {
          stickyNoteContent = (
            <Box sx={{ maxHeight: '60px', overflow: 'hidden' }}>
              {objectToArray(stickyNotes)
                .sort(dynamicSort('timestamp', 'desc'))
                .map((stickyNote: TsInterface_UnspecifiedObject, index: number) => (
                  <Box
                    key={index}
                    className=""
                  >
                    <Icon
                      icon="note"
                      className="tw-mr-1"
                      sx={{ color: themeVariables.secondary_main }}
                    />
                    <Box
                      component="span"
                      className="tw-inline-block tw-mr-1"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {returnFormattedDate(stickyNote.timestamp, 'M/D/YY')}
                      {' - '}
                    </Box>
                    <Box
                      component="span"
                      className="tw-opacity-40"
                    >
                      {stickyNote.note}
                    </Box>
                    {/* {rJSX_EditIcon(
                      tableAdditionalData.readOrWrite,
                      stickyNote,
                      tableHooks.uc_RootData_ClientKey,
                      tableHooks.uc_setRootData_ClientKey,
                      tableHooks.uc_setUserInterface_FormDialogDisplay,
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                      rowData.key as string,
                    )}
                    {rJSX_DeleteIcon(
                      tableAdditionalData.readOrWrite,
                      stickyNote.key,
                      stickyNote,
                      tableHooks.uc_RootData_ClientKey,
                      tableHooks.uc_setRootData_ClientKey,
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                      tableHooks.uc_setUserInterface_ConfirmDialogDisplay,
                      rowData.key as string,
                    )} */}
                  </Box>
                ))}
            </Box>
          )
        } else if (hasOldStickyNote === true) {
          stickyNoteContent = <></>
        } else {
          stickyNoteContent = (
            <Box
              className="tw-italic tw-opacity-50 tw-inline-block"
              component="span"
            >
              {rLIB('No Sticky Notes')}
            </Box>
          )
        }

        cellJSX = (
          <Box sx={{ maxWidth: '400px' }}>
            {stickyNoteContent}
            {stickyNoteContentOld}
            <Box>
              {viewIconJSX}
              {addIcon}
            </Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Project Notes')
      },
      header_sort_by: null,
    },
  },
}

// Table
// Table Columns
const tableColumns_ClosedOpportunities: TsInterface_TableColumns = {
  unique_identifier: tableColumns_OpportunitiesByMilestone.unique_identifier,
  associated_customer_name: tableColumns_OpportunitiesByMilestone.associated_customer_name,
  associated_sales_partner_name: tableColumns_OpportunitiesByMilestone.associated_sales_partner_name,
  associated_finance_partner_name: tableColumns_OpportunitiesByMilestone.associated_finance_partner_name,
  notes: tableColumns_OpportunitiesByMilestone.notes,
}

// Table Settings
const tableSettings_ClosedOpportunities: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_completed',
  use_live_data: false,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_unsortedOpportunities, us_setUnsortedOpportunities] = useState<TsInterface_UnspecifiedObject>({})
  const [us_sortedOpportunities, us_setSortedOpportunities] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeFinancePartners, us_setActiveFinancePartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeInstallPartners, us_setActiveInstallPartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeOpportunityMilestones, us_setActiveOpportunityMilestones] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeRegions, us_setActiveRegions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeSalesOpportunities, us_setActiveSalesOpportunities] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeSalesPartners, us_setActiveSalesPartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredTags, us_setFilteredTags] = useState<string[]>([])
  const [us_snoozeFilter, us_setSnoozeFilter] = useState<'all' | 'snoozed' | 'active'>('all')
  const [us_statusFilter, us_setStatusFilter] = useState<'active' | 'closed_won' | 'closed_lost'>('active')
  const [us_tagFilterAnchorEl, us_setTagFilterAnchorEl] = useState<null | HTMLElement>(null)
  const [us_visibleTags, us_setVisibleTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_maxTaskCount, us_setMaxTaskCount] = useState<number>(0)
  const un_routerNavigation = useNavigate()
  // const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Pipeline', false) as string
  }, [])

  useEffect(() => {
    // Get Max number of projects in any given milestone to set width of progress bar segments
    let currentMaxTaskCount = 0
    let unsortedCount = objectToArray(us_unsortedOpportunities).length
    currentMaxTaskCount = unsortedCount
    for (let loopMilestoneKey in us_activeOpportunityMilestones) {
      let milestoneCount = objectToArray(getProp(us_sortedOpportunities, loopMilestoneKey, {})).length
      if (milestoneCount > currentMaxTaskCount) {
        currentMaxTaskCount = milestoneCount
      }
    }
    us_setMaxTaskCount(currentMaxTaskCount)
  }, [us_activeOpportunityMilestones, us_unsortedOpportunities, us_sortedOpportunities])

  useEffect(() => {
    // Milestone Filter
    let currentTimestamp = new Date().getTime()
    let filteredOpportunities1: TsInterface_UnspecifiedObject = {}
    let filteredOpportunities2: TsInterface_UnspecifiedObject = {}
    let filteredOpportunities3: TsInterface_UnspecifiedObject = {}
    for (let loopOpportunityKey in us_activeSalesOpportunities) {
      let loopOpportunity = us_activeSalesOpportunities[loopOpportunityKey]
      if (
        loopOpportunity['associated_milestone_key'] == null ||
        loopOpportunity['associated_milestone_key'] === '' ||
        (us_activeOpportunityMilestones != null && us_activeOpportunityMilestones[loopOpportunity['associated_milestone_key']] == null)
      ) {
        filteredOpportunities1[loopOpportunityKey] = loopOpportunity
      }
    }
    // Snooze Filter
    if (us_snoozeFilter === 'all') {
      filteredOpportunities2 = filteredOpportunities1
    } else if (us_snoozeFilter === 'snoozed') {
      for (let loopOpportunityKey in filteredOpportunities1) {
        let loopOpportunity = filteredOpportunities1[loopOpportunityKey]
        if (getProp(loopOpportunity, 'timestamp_snoozed_until', null) != null) {
          let snoozeUntilTimestamp = returnTimestampFromUnknownDateFormat(getProp(loopOpportunity, 'timestamp_snoozed_until', null))
          if (snoozeUntilTimestamp > currentTimestamp) {
            filteredOpportunities2[loopOpportunityKey] = loopOpportunity
          }
        }
      }
    } else if (us_snoozeFilter === 'active') {
      for (let loopOpportunityKey in filteredOpportunities1) {
        let loopOpportunity = filteredOpportunities1[loopOpportunityKey]
        if (getProp(loopOpportunity, 'timestamp_snoozed_until', null) != null) {
          let loopOpportunity = filteredOpportunities1[loopOpportunityKey]
          let snoozeUntilTimestamp = returnTimestampFromUnknownDateFormat(getProp(loopOpportunity, 'timestamp_snoozed_until', null))
          if (snoozeUntilTimestamp < currentTimestamp) {
            filteredOpportunities2[loopOpportunityKey] = loopOpportunity
          }
        } else {
          filteredOpportunities2[loopOpportunityKey] = loopOpportunity
        }
      }
    }
    // Tag Filter
    if (us_filteredTags != null && objectToArray(us_filteredTags).length > 0) {
      for (let loopOpportunityKey in filteredOpportunities2) {
        let loopOpportunity = filteredOpportunities2[loopOpportunityKey]
        // Check for any matching tags in the tags object
        if (loopOpportunity['tags'] != null) {
          for (let tagKey in loopOpportunity['tags']) {
            let loopTag = loopOpportunity['tags'][tagKey]
            if (loopTag != null && loopTag.text != null && loopTag.text !== '' && loopTag.color != null && loopTag.color !== '') {
              let combinedLabelKey = loopTag.text + '-' + loopTag.color
              if (us_filteredTags.includes(combinedLabelKey)) {
                filteredOpportunities3[loopOpportunityKey] = loopOpportunity
                break // Found a matching tag, no need to check others
              }
            }
          }
        }
      }
    } else {
      filteredOpportunities3 = filteredOpportunities2
    }
    us_setUnsortedOpportunities(filteredOpportunities3)
  }, [us_activeOpportunityMilestones, us_activeSalesOpportunities, us_filteredTags, us_snoozeFilter])

  useEffect(() => {
    const returnFilteredOpportunities = (milestoneKey: string): TsInterface_UnspecifiedObject => {
      // Milestone Filter
      let currentTimestamp = new Date().getTime()
      let filteredOpportunities1: TsInterface_UnspecifiedObject = {}
      let filteredOpportunities2: TsInterface_UnspecifiedObject = {}
      let filteredOpportunities3: TsInterface_UnspecifiedObject = {}
      for (let loopOpportunityKey in us_activeSalesOpportunities) {
        let loopOpportunity = us_activeSalesOpportunities[loopOpportunityKey]
        if (loopOpportunity['associated_milestone_key'] === milestoneKey) {
          filteredOpportunities1[loopOpportunityKey] = loopOpportunity
        }
      }
      // Snooze Filter
      if (us_snoozeFilter === 'all') {
        filteredOpportunities2 = filteredOpportunities1
      } else if (us_snoozeFilter === 'snoozed') {
        for (let loopOpportunityKey in filteredOpportunities1) {
          let loopOpportunity = filteredOpportunities1[loopOpportunityKey]
          if (getProp(loopOpportunity, 'timestamp_snoozed_until', null) != null) {
            let snoozeUntilTimestamp = returnTimestampFromUnknownDateFormat(getProp(loopOpportunity, 'timestamp_snoozed_until', null))
            if (snoozeUntilTimestamp > currentTimestamp) {
              filteredOpportunities2[loopOpportunityKey] = loopOpportunity
            }
          }
        }
      } else if (us_snoozeFilter === 'active') {
        for (let loopOpportunityKey in filteredOpportunities1) {
          let loopOpportunity = filteredOpportunities1[loopOpportunityKey]
          if (getProp(loopOpportunity, 'timestamp_snoozed_until', null) != null) {
            let loopOpportunity = filteredOpportunities1[loopOpportunityKey]
            let snoozeUntilTimestamp = returnTimestampFromUnknownDateFormat(getProp(loopOpportunity, 'timestamp_snoozed_until', null))
            if (snoozeUntilTimestamp < currentTimestamp) {
              filteredOpportunities2[loopOpportunityKey] = loopOpportunity
            }
          } else {
            filteredOpportunities2[loopOpportunityKey] = loopOpportunity
          }
        }
      }
      // Tag Filter
      if (us_filteredTags != null && objectToArray(us_filteredTags).length > 0) {
        for (let loopOpportunityKey in filteredOpportunities2) {
          let loopOpportunity = filteredOpportunities2[loopOpportunityKey]
          // Check for any matching tags in the tags object
          if (loopOpportunity['tags'] != null) {
            for (let tagKey in loopOpportunity['tags']) {
              let loopTag = loopOpportunity['tags'][tagKey]
              if (loopTag != null && loopTag.text != null && loopTag.text !== '' && loopTag.color != null && loopTag.color !== '') {
                let combinedLabelKey = loopTag.text + '-' + loopTag.color
                if (us_filteredTags.includes(combinedLabelKey)) {
                  filteredOpportunities3[loopOpportunityKey] = loopOpportunity
                  break // Found a matching tag, no need to check others
                }
              }
            }
          }
        }
      } else {
        filteredOpportunities3 = filteredOpportunities2
      }
      return filteredOpportunities3
    }

    let sortedOpportunities: TsInterface_UnspecifiedObject = {}
    for (let loopMilestoneKey in us_activeOpportunityMilestones) {
      let filteredOpportunities = returnFilteredOpportunities(loopMilestoneKey)
      sortedOpportunities[loopMilestoneKey] = filteredOpportunities
    }

    us_setSortedOpportunities(sortedOpportunities)
  }, [us_activeOpportunityMilestones, us_activeSalesOpportunities, us_filteredTags, us_snoozeFilter])

  useEffect(() => {
    // Generate List of Tags
    let pacingTags: TsInterface_UnspecifiedObject = {}
    for (let loopOpportunityKey in us_activeSalesOpportunities) {
      let loopOpportunity = us_activeSalesOpportunities[loopOpportunityKey]
      let loopTags = getProp(loopOpportunity, 'tags', {})
      for (let loopTagKey in loopTags) {
        let loopTag = loopTags[loopTagKey]
        if (loopTag != null && loopTag.text != null && loopTag.text !== '' && loopTag.color != null && loopTag.color !== '') {
          let labelKey = loopTag.text + '-' + loopTag.color
          pacingTags[labelKey] = loopTag
        }
      }
    }
    us_setVisibleTags(pacingTags)
    return () => {}
  }, [us_activeSalesOpportunities])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveSalesOpportunities(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSalesOpportunities_v2_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveSalesPartners(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveFinancePartners(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveFinancePartners_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveInstallPartners(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveInstallPartners_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveRegions(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveOpportunityMilestones(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveOpportunityMilestones_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const createNewOpportunityProper = (clientKey: string, opportunityKey: string, opportunityData: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      // Handle Associations
      if (opportunityData['associated_sales_partner_key'] != null) {
        opportunityData['associated_sales_partner_name'] = us_activeSalesPartners[opportunityData['associated_sales_partner_key']].name
      }
      if (opportunityData['associated_finance_partner_key'] != null) {
        opportunityData['associated_finance_partner_name'] = us_activeFinancePartners[opportunityData['associated_finance_partner_key']].name
      }
      if (opportunityData['associated_install_partner_key'] != null) {
        opportunityData['associated_install_partner_name'] = us_activeInstallPartners[opportunityData['associated_install_partner_key']].name
      }
      if (opportunityData['associated_region_key'] != null) {
        opportunityData['associated_region_name'] = us_activeRegions[opportunityData['associated_region_key']].name
      }
      if (opportunityData['associated_milestone_key'] != null) {
        opportunityData['associated_milestone_name'] = us_activeOpportunityMilestones[opportunityData['associated_milestone_key']].name
      }
      // Save
      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), opportunityData)
        .then((res_DSMD) => {
          resolve({ success: true })
        })
        .catch((rej_DSMD) => {
          reject(rej_DSMD)
        })
    })
  }

  const returnFormattedOptions = (inputData: TsInterface_UnspecifiedObject) => {
    let options: TsInterface_UnspecifiedObject[] = []
    Object.keys(inputData).forEach((key) => {
      options.push({
        key: key,
        value: inputData[key].name,
        order: getProp(inputData[key], 'timestamp_completed', 0), // For milestone ordering
      })
    })
    return options
  }

  const createNewOpportunity = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let formInputs = returnOpportunityNewFormInputs(res_GCK.clientKey)
        // Add options to form
        if (formInputs['associated_sales_partner_key'] != null) {
          formInputs['associated_sales_partner_key'].options = returnFormattedOptions(us_activeSalesPartners)
        }
        if (formInputs['associated_finance_partner_key'] != null) {
          formInputs['associated_finance_partner_key'].options = returnFormattedOptions(us_activeFinancePartners)
        }
        if (formInputs['associated_install_partner_key'] != null) {
          formInputs['associated_install_partner_key'].options = returnFormattedOptions(us_activeInstallPartners)
        }
        if (formInputs['associated_region_key'] != null) {
          formInputs['associated_region_key'].options = returnFormattedOptions(us_activeRegions)
        }
        if (formInputs['associated_milestone_key'] != null) {
          formInputs['associated_milestone_key'].options = returnFormattedOptions(us_activeOpportunityMilestones)
        }
        // Open Dialog
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: formInputs,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  let opportunityKey = keyFromString(formSubmittedData.unique_identifier)
                  let updateObject: TsInterface_UnspecifiedObject = {
                    status: 'active',
                    key: opportunityKey,
                    // timestamp_created: new Date(),
                    ...formSubmittedData,
                  }
                  // Check and see if unique identifier as key already exists
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let promiseArray: TsType_UnknownPromise[] = []
                      let hasMatchingOpportunity: boolean = false
                      promiseArray.push(
                        DatabaseGetDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, opportunityKey)).then((res_DGD) => {
                          if (res_DGD != null && res_DGD.data != null && objectToArray(res_DGD.data).length > 0) {
                            hasMatchingOpportunity = true
                          }
                        }),
                      )
                      // After Check
                      Promise.all(promiseArray).finally(() => {
                        if (hasMatchingOpportunity === true) {
                          formHooks.uc_setUserInterface_ConfirmDialogDisplay({
                            display: true,
                            confirm: {
                              color: 'warning',
                              header: <>{rLIB('Opportunity already exists')}</>,
                              icon: (
                                <Icon
                                  icon="circle-exclamation"
                                  type="solid"
                                />
                              ),
                              submit_text: rLIB('Confirm Update'),
                              text: <>{rLIB('An opportunity with that unique identifier already exists. Do you want to update the existing opportunity?')}</>,
                              submit_callback: () => {
                                return new Promise((resolve2, reject2) => {
                                  createNewOpportunityProper(res_GCK.clientKey, opportunityKey, updateObject)
                                    .then((res_DSMD) => {
                                      resolve2(res_DSMD)
                                      resolve(res_DSMD)
                                    })
                                    .catch((rej_DSMD) => {
                                      reject2(rej_DSMD)
                                      reject(rej_DSMD)
                                    })
                                })
                              },
                            },
                          })
                        } else {
                          updateObject['timestamp_created'] = new Date()
                          createNewOpportunityProper(res_GCK.clientKey, opportunityKey, updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              reject(rej_DSMD)
                            })
                        }
                      })
                    })
                    .catch((rej_GCK) => {
                      reject(rej_GCK)
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: rLIB('Create New Opportunity'),
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="circle-plus"
                />
              ),
            },
          },
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const tableDatabaseEndpoint = (queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject, tableAdditionalData: TsInterface_TableAdditionalData) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: us_statusFilter }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_completed', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)

    return generateDatabaseQuery(
      DatabaseRef_SalesOpportunities_v2_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_NewOpportunityButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          createNewOpportunity()
        }}
        className="tw-mr-2 tw-mb-2"
      >
        {rLIB('New Opportunity', false)}
      </Button>
    )
  }

  const rJSX_ImportButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="info"
        startIcon={<Icon icon="cloud-arrow-up" />}
        className="tw-mr-2 tw-mb-2 "
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesPipelineImportPage.url())
        }}
      >
        {rLIB('Imports')}
      </Button>
    )
  }

  const rJSX_SnoozeFilterButtons = (): JSX.Element => {
    let buttonText: string | JSX.Element = us_snoozeFilter
    if (us_statusFilter === 'active') {
      if (us_snoozeFilter === 'all') {
        buttonText = rLIB('Active Opportunities')
      } else if (us_snoozeFilter === 'active') {
        buttonText = rLIB('Unsnoozed Opportunities')
      } else if (us_snoozeFilter === 'snoozed') {
        buttonText = rLIB('Snoozed Opportunities')
      }
    } else if (us_statusFilter === 'closed_won') {
      buttonText = rLIB('Closed Won')
    } else if (us_statusFilter === 'closed_lost') {
      buttonText = rLIB('Closed Lost')
    }
    return (
      <Box className="tw-inline-block">
        <IconMenu
          icon={
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Icon icon="filter" />}
              className="tw-mr-2 tw-mb-2"
            >
              {buttonText}
            </Button>
          }
          menuItems={[
            {
              icon: 'alarm-clock',
              text: rLIB('Active Opportunities'),
              onClick: () => {
                us_setSnoozeFilter('all')
                us_setStatusFilter('active')
              },
            },
            {
              icon: 'alarm-exclamation',
              text: rLIB('Unsnoozed Opportunities'),
              onClick: () => {
                us_setSnoozeFilter('active')
                us_setStatusFilter('active')
              },
            },
            {
              icon: 'alarm-snooze',
              text: rLIB('Snoozed Opportunities'),
              onClick: () => {
                us_setSnoozeFilter('snoozed')
                us_setStatusFilter('active')
              },
            },
            {
              icon: 'circle-check',
              text: rLIB('Closed Won'),
              onClick: () => {
                us_setStatusFilter('closed_won')
              },
            },
            {
              icon: 'circle-xmark',
              text: rLIB('Closed Lost'),
              onClick: () => {
                us_setStatusFilter('closed_lost')
              },
            },
          ]}
        />
      </Box>
    )
  }

  const rJSX_TagFilterMultiSelect = (): JSX.Element => {
    const open = Boolean(us_tagFilterAnchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      us_setTagFilterAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      us_setTagFilterAnchorEl(null)
    }

    const handleTagToggle = (tagKey: string) => {
      const currentIndex = us_filteredTags.indexOf(tagKey)
      const newChecked = [...us_filteredTags]

      if (currentIndex === -1) {
        newChecked.push(tagKey)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      us_setFilteredTags(newChecked)
    }

    if (us_statusFilter === 'active') {
      return (
        <Box className="tw-inline-block tw-mr-2">
          <Button
            onClick={handleClick}
            startIcon={<Icon icon="tag" />}
            endIcon={<Icon icon={open ? 'chevron-up' : 'chevron-down'} />}
            variant="outlined"
            color="secondary"
            className="tw-mb-2"
          >
            {rLIB('Tags')} ({us_filteredTags.length})
          </Button>
          <Menu
            anchorEl={us_tagFilterAnchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 300,
                minWidth: 200,
              },
            }}
          >
            <MenuItem dense>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={us_filteredTags.length === objectToArray(us_visibleTags).length}
                    indeterminate={us_filteredTags.length > 0 && us_filteredTags.length < objectToArray(us_visibleTags).length}
                    onChange={() => {
                      if (us_filteredTags.length === objectToArray(us_visibleTags).length) {
                        us_setFilteredTags([])
                      } else {
                        us_setFilteredTags(Object.keys(us_visibleTags))
                      }
                    }}
                  />
                }
                label={rLIB('Select All')}
              />
            </MenuItem>
            <Divider />
            {objectToArray(us_visibleTags)
              .sort(dynamicSort('text', 'asc'))
              .map((tag: TsInterface_UnspecifiedObject) => {
                const tagKey = tag.text + '-' + tag.color
                return (
                  <MenuItem
                    key={tagKey}
                    dense
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={us_filteredTags.includes(tagKey)}
                          onChange={() => handleTagToggle(tagKey)}
                        />
                      }
                      label={
                        <Box
                          className="tw-inline-block tw-px-2 tw-py-1"
                          sx={{ backgroundColor: tag.color, borderRadius: '6px', color: themeVariables.white, paddingX: '8px', paddingY: '4px' }}
                        >
                          <Icon
                            icon="tag"
                            className="tw-mr-2"
                            sx={{ marginRight: '4px' }}
                          />
                          {tag.text}
                        </Box>
                      }
                    />
                  </MenuItem>
                )
              })}
          </Menu>
        </Box>
      )
    } else {
      return <></>
    }
  }

  const rJSX_ProjectsForWorkflowMilestoneTableList = (milestoneKey: string, milestoneData: TsInterface_UnspecifiedObject, readOrWrite: 'read' | 'write') => {
    let tableJSX = <></>
    if (milestoneKey != null && objectToArray(milestoneData).length > 0) {
      tableJSX = (
        <Box
          sx={{ border: '1px solid ' + themeVariables.gray_700 }}
          className="tw-rounded-sm"
        >
          <TableBasic
            tableAdditionalData={{
              readOrWrite: 'write',
              us_visibleTags: us_visibleTags,
              milestoneOptions: returnFormattedOptions(us_activeOpportunityMilestones),
              us_activeOpportunityMilestones: us_activeOpportunityMilestones,
            }}
            tableColumns={tableColumns_OpportunitiesByMilestone}
            tableData={objectToArray(milestoneData)}
            tableSettings={tableSettings_OpportunitiesByMilestone}
          />
        </Box>
      )
    } else {
      tableJSX = (
        <Box>
          <Divider />
          <Typography
            variant="body1"
            className="tw-my-1"
          >
            {rLIB('No filtered opportunities for this milestone')}
          </Typography>
        </Box>
      )
    }
    return tableJSX
  }

  const rJSX_SummaryStats = (milestoneData: TsInterface_UnspecifiedObject): JSX.Element => {
    let statsJSX = <></>
    let opportunityCount = objectToArray(milestoneData).length
    let totalSystemSize = 0
    let totalContractAmount = 0
    for (let opportunity of objectToArray(milestoneData)) {
      totalSystemSize += parseFloat(getProp(opportunity, 'system_size', 0))
      totalContractAmount += parseFloat(getProp(opportunity, 'financials_contract_amount', 0))
    }
    // JSX
    statsJSX = (
      <Stack
        direction="row"
        spacing={1}
        className="tw-mb-2"
        sx={{
          opacity: opportunityCount > 0 ? 1 : 0.3,
        }}
      >
        <Box>
          {opportunityCount} {opportunityCount === 1 ? rLIB('Opportunity') : rLIB('Opportunities')}
        </Box>
        <Box>|</Box>
        <Box>
          {totalSystemSize} {rLIB('kW')}
        </Box>
        <Box>|</Box>
        <Box>{formatCurrency(totalContractAmount)}</Box>
      </Stack>
    )
    return statsJSX
  }

  const rJSX_BreakdownGraph = (milestoneTaskCount: number): JSX.Element => {
    return (
      <Box sx={{ width: '100%', height: '12px', overflow: 'hidden' }}>
        <Stack
          direction="row"
          sx={{ width: '100%' }}
        >
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.info_main,
              width: (milestoneTaskCount / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
          {/* <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.error_main,
              width: (projectColorCounts.red / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.warning_main,
              width: (projectColorCounts.yellow / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.success_main,
              width: (projectColorCounts.green / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
           */}
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.gray_700,
              width: ((us_maxTaskCount - milestoneTaskCount) / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
        </Stack>
      </Box>
    )
  }

  const rJSX_UniqueIDsList = (milestoneData: TsInterface_UnspecifiedObject): JSX.Element => {
    return (
      <Box>
        {objectToArray(milestoneData)
          .sort(dynamicSort('unique_identifier', null))
          .map((opportunity: TsInterface_UnspecifiedObject, opportunityIndex: number) => (
            <Box
              className="tw-inline-block tw-mb-1"
              sx={{
                background: themeVariables.info_main,
                padding: '4px 8px',
                borderRadius: '4px',
                marginRight: '4px',
              }}
              key={opportunityIndex}
            >
              {opportunity['unique_identifier']}
            </Box>
          ))}
      </Box>
    )
  }

  const rJSX_Milestone = (milestone: TsInterface_UnspecifiedObject, milestoneData: TsInterface_UnspecifiedObject): JSX.Element => {
    return (
      <Box>
        <Box className="tw-mb-4">
          <Box>{rJSX_BreakdownGraph(objectToArray(milestoneData).length)}</Box>
          <Accordion
            sx={{
              '&.Mui-expanded': {
                marginTop: 0,
              },
            }}
          >
            <AccordionSummary
              sx={{
                'width': '100%',
                // 'padding': 0,
                '& .MuiAccordionSummary-content': {
                  width: '100%',
                  // margin: 0,
                },
              }}
              expandIcon={<Icon icon="angle-down" />}
            >
              <Box sx={{ width: '100%' }}>
                <Typography
                  variant="h5"
                  className="tw-my-1 tw-opacity-40 tw-inline-block"
                >
                  {milestone['name']}
                </Typography>
                {rJSX_SummaryStats(milestoneData)}
                {rJSX_UniqueIDsList(milestoneData)}
              </Box>
            </AccordionSummary>
            <AccordionDetails>{rJSX_ProjectsForWorkflowMilestoneTableList(milestone.key, milestoneData, 'write')}</AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    )
  }

  const rJSX_ClosedOpportunitiesTable = (): JSX.Element => {
    return (
      <Card>
        <TableDatabase
          tableAdditionalData={{
            readOrWrite: 'write',
          }}
          tableColumns={tableColumns_ClosedOpportunities}
          tableDatabaseEndpoint={tableDatabaseEndpoint}
          tableSettings={tableSettings_ClosedOpportunities}
        />
      </Card>
    )
  }

  const rJSX_Milestones = (): JSX.Element => {
    if (us_statusFilter === 'active') {
      return (
        <Box>
          <Box>{rJSX_Milestone({ name: 'Unsorted', key: 'unsorted' }, us_unsortedOpportunities)}</Box>
          {objectToArray(us_activeOpportunityMilestones)
            .sort(dynamicSort('timestamp_completed', 'asc'))
            .map((milestone: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>{rJSX_Milestone(milestone, getProp(us_sortedOpportunities, milestone.key, {}))}</Box>
            ))}
        </Box>
      )
    } else if (us_statusFilter === 'closed_won' && uc_RootData_ClientKey != null) {
      return <Box>{rJSX_ClosedOpportunitiesTable()}</Box>
    } else if (us_statusFilter === 'closed_lost' && uc_RootData_ClientKey != null) {
      return <Box>{rJSX_ClosedOpportunitiesTable()}</Box>
    } else {
      return <></>
    }
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Pipeline')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              {rJSX_NewOpportunityButton()}
              {rJSX_ImportButton()}
              {rJSX_SnoozeFilterButtons()}
              {rJSX_TagFilterMultiSelect()}
            </Box>
            <Box>{rJSX_Milestones()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
