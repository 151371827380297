import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

export const DatabaseRef_Adders_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'adders', 'main')
}

export const DatabaseRef_Adder_Document = (clientKey: string, adderKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'adders', 'main', adderKey)
}

export const DatabaseRef_ActiveAdders_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'sales', 'adders', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_Adder_ImportAliases_Document = (clientKey: string, adderKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'adders', 'import_aliases', adderKey)
}

export const DatabaseRef_Adder_ImportAliases_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'adders', 'import_aliases')
}

export const DatabaseRef_Adder_ImportAliases_Query = (clientKey: string, adderKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: adderKey }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'sales', 'adders', 'import_aliases'), queryOperatorsArray, [], {}, null)
}
