///////////////////////////////
// Imports
///////////////////////////////

import { Box, Divider, Stack, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_FormSubmission,
} from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Functions
///////////////////////////////

const isValidDate = (timestamp: number): boolean => {
  if (isNaN(timestamp)) return false
  const date = new Date(timestamp)
  return date instanceof Date && !isNaN(date.getTime())
}

///////////////////////////////
// Exports
///////////////////////////////

// TODO: extension_reason
// const extensionReasonOptions = [
//   { key: 'Waiting for Internal Team Availability', value: 'Waiting for Internal Team Availability' },
//   { key: 'Waiting on Permitting', value: 'Waiting on Permitting' },
// ]

export const formInputs_DeadlineExtension: TsInterface_FormInputs = {
  HEADER: {
    data_type: 'string',
    key: 'HEADER',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Expected Completion Date
      // TODO: Yellow Days
      let expectedCompletionDate = null
      let showEstimatedCompletionDate = false
      if (formAdditionalData != null && formAdditionalData.TEMP_last_milestone_timestamp != null && formAdditionalData.cutoffDaysAmount != null) {
        expectedCompletionDate =
          parseFloat(getProp(formAdditionalData, 'TEMP_last_milestone_timestamp', 0)) + getProp(formAdditionalData, 'cutoffDaysAmount', 0) * 24 * 60 * 60 * 1000
        showEstimatedCompletionDate = true
      }
      let inputJSX = (
        <Box>
          <Stack
            direction="row"
            spacing={1}
          >
            <Typography
              variant="h6"
              className="tw-text-bold"
            >
              {rLIB('Phase Start Date')}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {returnFormattedDate(formAdditionalData.TEMP_last_milestone_timestamp, 'D MMM YY')}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
          >
            <Typography
              variant="h6"
              className="tw-text-bold"
            >
              {rLIB('Current Date')}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {returnFormattedDate(new Date(), 'D MMM YY')}
            </Typography>
          </Stack>
          {showEstimatedCompletionDate === true ? (
            <Stack
              direction="row"
              spacing={1}
            >
              <Typography
                variant="h6"
                className="tw-text-bold"
              >
                {rLIB('Original Expected Completion Date')}:
              </Typography>
              <Typography
                variant="h6"
                className="tw-opacity-50"
              >
                {returnFormattedDate(expectedCompletionDate, 'D MMM YY')}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
          <Stack
            direction="row"
            spacing={1}
          >
            <Typography
              variant="h6"
              className="tw-text-bold"
            >
              {rLIB('Estimated Phase Completion Date')}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {returnFormattedDate(formAdditionalData.TEMP_estimated_phase_completion_timestamp, 'D MMM YY')}
            </Typography>
          </Stack>

          <Divider className="tw-my-4" />
          <Stack
            direction="row"
            spacing={1}
          >
            <Typography
              variant="h6"
              className="tw-text-bold"
            >
              {rLIB('Expected Days in Phase')}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {getProp(formAdditionalData, 'cutoffDaysAmount', '')} {formAdditionalData.cutoffDaysAmount === 1 ? <>{rLIB('day')}</> : <>{rLIB('days')}</>}
            </Typography>
          </Stack>
          {getProp(formAdditionalData, 'TEMP_days_in_phase', null) != null ? (
            <Stack
              direction="row"
              spacing={1}
            >
              <Typography
                variant="h6"
                className="tw-text-bold"
              >
                {rLIB('Current Days in Phase')}:
              </Typography>
              <Typography
                variant="h6"
                className="tw-opacity-50"
              >
                {getProp(formAdditionalData, 'TEMP_days_in_phase', '')}{' '}
                {getProp(formAdditionalData, 'TEMP_days_in_phase', '') === 1 ? <>{rLIB('day')}</> : <>{rLIB('days')}</>}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
          <Stack
            direction="row"
            spacing={1}
          >
            <Typography
              variant="h6"
              className="tw-text-bold"
            >
              {rLIB('Other Deadline Extensions')}:
            </Typography>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {getProp(formAdditionalData, 'TEMP_phase_extension_days', 0)}{' '}
              {getProp(formAdditionalData, 'TEMP_phase_extension_days', 0) === 1 ? <>{rLIB('day')}</> : <>{rLIB('days')}</>}
            </Typography>
          </Stack>

          {getProp(formAdditionalData, 'TEMP_days_in_phase', null) != null && getProp(formAdditionalData, 'cutoffDaysAmount', null) != null ? (
            <Stack
              direction="row"
              spacing={1}
            >
              <Typography
                variant="h6"
                className="tw-text-bold"
              >
                {rLIB('Expected minus Current')}:
              </Typography>
              <Typography
                variant="h6"
                className="tw-px-2 tw-rounded-md tw-inline-block"
                sx={{
                  color: themeVariables.white,
                  backgroundColor:
                    getProp(formAdditionalData, 'TEMP_days_in_phase', '') -
                      getProp(formAdditionalData, 'TEMP_phase_extension_days', 0) -
                      getProp(formAdditionalData, 'cutoffDaysAmount', '') >=
                    0
                      ? themeVariables.error_main
                      : themeVariables.success_main,
                }}
              >
                {getProp(formAdditionalData, 'TEMP_days_in_phase', '') -
                  getProp(formAdditionalData, 'TEMP_phase_extension_days', 0) -
                  getProp(formAdditionalData, 'cutoffDaysAmount', '')}{' '}
                {getProp(formAdditionalData, 'TEMP_days_in_phase', '') -
                  getProp(formAdditionalData, 'TEMP_phase_extension_days', 0) -
                  getProp(formAdditionalData, 'cutoffDaysAmount', '') ===
                1 ? (
                  <>{rLIB('day')}</>
                ) : (
                  <>{rLIB('days')}</>
                )}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
          <Divider className="tw-my-4" />
        </Box>
      )
      return inputJSX
    },
  },
  extension_days: {
    key: 'extension_days',
    label: rLIB('Extension Duration (days)'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
  },
  extension_days_preview: {
    data_type: 'string',
    key: 'extension_days',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let previewJSX = <></>
      if (formData != null && formData.extension_days != null && formAdditionalData != null && formAdditionalData.TEMP_last_milestone_timestamp != null) {
        // TODO: Add other extension Days
        let extensionDays = parseFloat(formData.extension_days as string)
        let newEstimatedCompletionDate =
          extensionDays * 24 * 60 * 60 * 1000 +
          parseFloat(getProp(formAdditionalData, 'cutoffDaysAmount', 0)) * 24 * 60 * 60 * 1000 +
          parseFloat(getProp(formAdditionalData, 'TEMP_phase_extension_days', 0)) * 24 * 60 * 60 * 1000 +
          parseFloat(formAdditionalData.TEMP_last_milestone_timestamp as string)
        if (isValidDate(newEstimatedCompletionDate)) {
          previewJSX = (
            <Box>
              <Typography>{rLIB('New Estimated Completion Date')}:</Typography>
              <Typography>{returnFormattedDate(newEstimatedCompletionDate, 'D MMM YY')}</Typography>
            </Box>
          )
        }
      }
      return previewJSX
    },
  },
  extension_reason: {
    key: 'extension_reason',
    label: rLIB('Reason'),
    input_type: 'multiple_choice_select',
    required: true,
    data_type: 'string',
    // options: extensionReasonOptions,
    options: [],
  },
  extension_notes: {
    key: 'extension_notes',
    label: rLIB('Notes'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
}

export const formSettings_DeadlineExtension: TsInterface_FormSettings = {}

export const formSubmission_DeadlineExtension: TsType_FormSubmission = (
  formSubmittedData: TsInterface_FormSubmittedData,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
) => {
  return new Promise((resolve, reject) => {
    let projectKey = formAdditionalData.projectKey
    let milestoneKey = formAdditionalData.milestoneTaskKey
    if (projectKey != null && milestoneKey != null) {
      let extensionKey = uuidv4()
      let updateObject = {
        phase_deadline_extensions: {
          [milestoneKey as string]: {
            [extensionKey]: {
              key: extensionKey,
              extension_days: formSubmittedData.extension_days,
              extension_reason: formSubmittedData.extension_reason,
              extension_notes: formSubmittedData.extension_notes,
              timestamp_created: new Date(),
              created_by_key: getProp(formHooks.uc_RootData_ClientUser, 'key', null),
              created_by_name: getProp(formHooks.uc_RootData_ClientUser, 'name', null),
            },
          },
        },
      }
      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey as string), updateObject)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to add deadline extension'),
          details: rLIB('Invalid project or milestone key'),
          code: 'ER-D-DE-FSDE-01',
        },
      })
    }
  })
}

export const formSubmission_DeadlineExtensionEdit: TsType_FormSubmission = (
  formSubmittedData: TsInterface_FormSubmittedData,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
) => {
  return new Promise((resolve, reject) => {
    let projectKey = formAdditionalData.projectKey
    let milestoneKey = formAdditionalData.milestoneTaskKey
    let extensionKey = formAdditionalData.extensionKey
    if (projectKey != null && milestoneKey != null) {
      let updateObject = {
        phase_deadline_extensions: {
          [milestoneKey as string]: {
            [extensionKey as string]: {
              key: extensionKey,
              extension_days: formSubmittedData.extension_days,
              extension_reason: formSubmittedData.extension_reason,
              extension_notes: formSubmittedData.extension_notes,
              timestamp_last_updated: new Date(),
              last_updated_by_key: getProp(formHooks.uc_RootData_ClientUser, 'key', null),
              last_updated_by_name: getProp(formHooks.uc_RootData_ClientUser, 'name', null),
            },
          },
        },
      }
      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey as string), updateObject)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to add deadline extension'),
          details: rLIB('Invalid project or milestone key'),
          code: 'ER-D-DE-FSDE-01',
        },
      })
    }
  })
}

export const deleteDeadlineExtension = (clientKey: string, extensionKey: string, projectKey: string, milestoneKey: string) => {
  console.log('clientKey')
  console.log(clientKey)
  console.log('extensionKey')
  console.log(extensionKey)
  console.log('projectKey')
  console.log(projectKey)
  console.log('milestoneKey')
  console.log(milestoneKey)

  return new Promise((resolve, reject) => {
    let updateObject = {
      phase_deadline_extensions: {
        [milestoneKey as string]: {
          [extensionKey as string]: null,
        },
      },
    }

    DatabaseSetMergeDocument(DatabaseRef_Project_Document(clientKey, projectKey as string), updateObject)
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}
