///////////////////////////////
// Description
///////////////////////////////

///////////////////////////////
// Imports
///////////////////////////////

import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_Batteries_Collection, DatabaseRef_Battery_ImportAliases_Document } from 'rfbp_aux/services/database_endpoints/directory/batteries'
import {
  DatabaseRef_BuildingType_Collection,
  DatabaseRef_BuildingType_ImportAliases_Document,
} from 'rfbp_aux/services/database_endpoints/directory/building_types'
import {
  DatabaseRef_FinancePartner_Collection,
  DatabaseRef_FinancePartner_ImportAliases_Document,
} from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import {
  DatabaseRef_FinancingType_Collection,
  DatabaseRef_FinancingType_ImportAliases_Document,
} from 'rfbp_aux/services/database_endpoints/directory/financing_type'
import { DatabaseRef_Hoas_Collection, DatabaseRef_Hoa_ImportAliases_Document } from 'rfbp_aux/services/database_endpoints/directory/hoas'
import { DatabaseRef_Inverters_Collection, DatabaseRef_Inverter_ImportAliases_Document } from 'rfbp_aux/services/database_endpoints/directory/inverters'
import {
  DatabaseRef_Jurisdictions_Collection,
  DatabaseRef_Jurisdiction_ImportAliases_Document,
} from 'rfbp_aux/services/database_endpoints/directory/jurisdictions'
import { DatabaseRef_Modules_Collection, DatabaseRef_Module_ImportAliases_Document } from 'rfbp_aux/services/database_endpoints/directory/modules'
import { DatabaseRef_MountTypes_Collection, DatabaseRef_MountType_ImportAliases_Document } from 'rfbp_aux/services/database_endpoints/directory/mount_types'
import {
  DatabaseRef_ProductPackages_Collection,
  DatabaseRef_ProductPackage_ImportAliases_Document,
} from 'rfbp_aux/services/database_endpoints/directory/product_packages'
import { DatabaseRef_Racking_Collection, DatabaseRef_Racking_ImportAliases_Document } from 'rfbp_aux/services/database_endpoints/directory/racking'
import {
  DatabaseRef_Regions_Collection,
  DatabaseRef_Region_Document,
  DatabaseRef_Region_ImportAliases_Document,
} from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
  DatabaseRef_SalesPartner_Collection,
  DatabaseRef_SalesPartner_Document,
  DatabaseRef_SalesPartner_ImportAliases_Document,
} from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_Utilities_Collection, DatabaseRef_Utility_ImportAliases_Document } from 'rfbp_aux/services/database_endpoints/directory/utilities'
import {
  DatabaseRef_ProjectAdditionalData_Document,
  DatabaseRef_ProjectImportSession_Projects_Document,
  DatabaseRef_ProjectImportSession_Settings_Document,
  DatabaseRef_ProjectsByIdNumber_Query,
  DatabaseRef_Projects_Collection,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// // Import Functions and Mapping
// const importParserDefault = (allProjectData: TsInterface_UnspecifiedObject, input: string): null | string | number | boolean | Date => {
//   return input
// }

// const importParserLowercase = (allProjectData: TsInterface_UnspecifiedObject, input: string): null | string | number | boolean | Date => {
//   if (input != null) {
//     return input.toLowerCase()
//   } else {
//     return null
//   }
// }

// const dateStringHasTimeComponent = (dateString: string) => {
//   // Use a regular expression to check for the presence of time (hh:mm:ss)
//   const timeRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/
//   // Test if the input string matches the time pattern
//   return timeRegex.test(dateString)
// }

// const importParserDate = (allProjectData: TsInterface_UnspecifiedObject, input: string): null | Date => {
//   let formattedDate: null | Date = null
//   if (input != null && input !== '') {
//     if (dateStringHasTimeComponent(input) === true) {
//       formattedDate = new Date(input)
//     } else {
//       // Correct for UTC
//       formattedDate = returnDateCorrectedForTimezoneOffset(input)
//     }
//   }
//   return formattedDate
// }

// const importParserInt = (allProjectData: TsInterface_UnspecifiedObject, input: string): number | null => {
//   if (!isNaN(parseInt(input))) {
//     return parseInt(input)
//   } else {
//     return null
//   }
// }

// const importParserFloat = (allProjectData: TsInterface_UnspecifiedObject, input: string): number | null => {
//   if (!isNaN(parseFloat(input))) {
//     return parseFloat(input)
//   } else {
//     return null
//   }
// }

///////////////////////////////
// Exports
///////////////////////////////

export const importProjectsToStagingArea = async (clientKey: string, projects: TsInterface_UnspecifiedObject, forceImport: boolean): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    let importSessionKey = uuidv4()
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
    updateArray.push({
      type: 'setMerge',
      ref: DatabaseRef_ProjectImportSession_Settings_Document(clientKey),
      data: {
        current_import_session_key: importSessionKey,
        timestamp_created: new Date(),
        import_session_status: 'started',
      },
    })
    for (let loopProjectKey in projects) {
      let loopProject = projects[loopProjectKey]
      // Generate temp key for project from job code, customer name, and address
      let tempProjectKey = keyFromString(loopProject.id_number + '_' + loopProject.associated_customer_name + '_' + loopProject.location_address)
      updateArray.push({
        type: 'setOverwrite',
        ref: DatabaseRef_ProjectImportSession_Projects_Document(clientKey, tempProjectKey),
        data: {
          data: loopProject,
          associated_customer_name: getProp(loopProject, 'associated_customer_name', null),
          key: tempProjectKey,
          associated_import_session_key: importSessionKey,
          status: 'processing', // processing, success, warning, error
        },
      })
    }
    DatabaseBatchUpdate(updateArray)
      .then((res_DBU) => {
        // Process projects sequentially instead of scheduling them all at once
        const processProjectsSequentially = async () => {
          let sortedProjects = objectToArray(projects).sort(dynamicSort('associated_customer_name', 'asc'))
          let results = []

          for (let loopProjectIndex in sortedProjects) {
            let loopProject = sortedProjects[loopProjectIndex]
            let tempProjectKey = keyFromString(loopProject.id_number + '_' + loopProject.associated_customer_name + '_' + loopProject.location_address)

            // Wait 500ms between processing each project
            if (parseInt(loopProjectIndex) > 0) {
              await new Promise((r) => setTimeout(r, 1000))
            }

            // Process the project and wait for it to complete before moving to the next one
            try {
              const result = await processIndividualStagingImportProject(clientKey, tempProjectKey, loopProject, forceImport)
              results.push(result)
            } catch (error) {
              console.error('Error processing project:', error)
              results.push(error)
            }
          }

          return results
        }

        // Start the sequential processing and resolve when all are done
        processProjectsSequentially()
        resolve({
          success: true,
        })
      })
      .catch((rej_DBU) => {
        reject(rej_DBU)
      })
  })
}

export const importIndividualProject = async (clientKey: string, projectData: TsInterface_UnspecifiedObject, forceImport: boolean) => {
  return new Promise((resolve, reject) => {
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
    let tempProjectKey = keyFromString(projectData.id_number + '_' + projectData.associated_customer_name + '_' + projectData.location_address)
    updateArray.push({
      type: 'setOverwrite',
      ref: DatabaseRef_ProjectImportSession_Projects_Document(clientKey, tempProjectKey),
      data: {
        data: projectData,
        associated_customer_name: getProp(projectData, 'associated_customer_name', null),
        key: tempProjectKey,
        associated_import_session_key: 'single_import',
        status: 'processing', // processing, success, warning, error
      },
    })
    DatabaseBatchUpdate(updateArray)
      .then((res_DBU) => {
        processIndividualStagingImportProject(clientKey, tempProjectKey, projectData, forceImport)
          .then((res_IP) => {
            resolve(res_IP)
          })
          .catch((rej_IP) => {
            reject(rej_IP)
          })
      })
      .catch((rej_DBU) => {
        reject(rej_DBU)
      })
  })
}

// TODO: Use this function for spreadsheet imports, create from opportunity, create from form, create from API
export const processIndividualStagingImportProject = (
  clientKey: string,
  projectTempKey: string,
  projectData: TsInterface_UnspecifiedObject,
  forceImport: boolean,
) => {
  return new Promise((resolve, reject) => {
    // Matching with master data
    let processedData: TsInterface_UnspecifiedObject = {}
    let promiseArray1: Promise<unknown>[] = []
    let promiseArray2: Promise<unknown>[] = []
    let linkingCounts: TsInterface_UnspecifiedObject = {
      green: 0,
      yellow: 0,
      red: 0,
    }
    let updatesToBaseData: TsInterface_UnspecifiedObject = {}
    for (let loopPropKey in projectData) {
      let importMapping = projectImportMappingV2[loopPropKey]
      if (importMapping != null && importMapping.cleaning_function != null) {
        let cleaningFunction = importMapping.cleaning_function
        if (cleaningFunction != null && cleaningFunction != undefined) {
          let cleanedValue = cleaningFunction(projectData[loopPropKey].toString())
          updatesToBaseData[loopPropKey] = cleanedValue
          projectData[loopPropKey] = cleanedValue
        }
      }
      if (importMapping != null && importMapping.linking_function != null) {
        let importProcessingFunction = importMapping.linking_function
        if (importProcessingFunction != null && importProcessingFunction != undefined) {
          promiseArray1.push(
            importProcessingFunction(clientKey, projectData).then((res_IPF: TsInterface_UnspecifiedObject) => {
              let foundMatch = res_IPF.foundMatch
              let lookingForMatch = res_IPF.lookingForMatch
              if (foundMatch === true && lookingForMatch === true) {
                linkingCounts.green++
              } else if (foundMatch === false && lookingForMatch === true) {
                linkingCounts.red++
              } else if (foundMatch === false && lookingForMatch === false) {
                linkingCounts.yellow++
              }
              if (objectToArray(res_IPF.data).length > 0) {
                // Merge processed data with processedData
                processedData = { ...processedData, ...res_IPF.data }
              }
            }),
          )
        }
      }
    }
    // Backfill yellows if the property is required and not found
    for (let loopPropKey in projectImportMappingV2) {
      let importMapping = projectImportMappingV2[loopPropKey]
      if (importMapping != null && importMapping.required_for_import === true) {
        if (projectData[loopPropKey] == null) {
          linkingCounts.red++
        }
      } else if (importMapping != null && importMapping.has_master_data_link === true) {
        if (projectData[loopPropKey] == null) {
          linkingCounts.yellow++
        }
      }
    }
    // Looking for project matches
    let matchInformation: TsInterface_UnspecifiedObject = {
      desired_operation_type: null,
      found_matching_project: false,
      import_project: {
        id_number: getProp(projectData, 'id_number', null),
        associated_customer_name: getProp(projectData, 'associated_customer_name', null),
        location_address: getProp(projectData, 'location_address', null),
        location_city: getProp(projectData, 'location_city', null),
        location_state: getProp(projectData, 'location_state', null),
        location_zip: getProp(projectData, 'location_zip', null),
      },
      matching_database_project: {
        id_number: null,
        associated_customer_name: null,
        location_address: null,
        location_city: null,
        location_state: null,
        location_zip: null,
      },
    }
    if (projectData != null && projectData.id_number != null && projectData.id_number != undefined && projectData.id_number != '') {
      // Project has a job code - look for existing project with same job code
      matchInformation['desired_operation_type'] = 'update'
      let queryRef = generateDatabaseQuery(
        DatabaseRef_Projects_Collection(clientKey),
        [{ prop: 'id_number', comparator: '==', value: projectData.id_number }],
        [],
        {},
        100,
      )
      promiseArray1.push(
        DatabaseGetCollection(queryRef)
          .then((res_DGC) => {
            if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
              let matchingDatabaseProjectData = objectToArray(res_DGC.data)[0]
              matchInformation['found_matching_project'] = true
              // Project found - fill out matching information
              matchInformation['matching_database_project']['key'] = getProp(matchingDatabaseProjectData, 'key', null)
              matchInformation['matching_database_project']['id_number'] = getProp(matchingDatabaseProjectData, 'id_number', null)
              matchInformation['matching_database_project']['associated_customer_name'] = getProp(matchingDatabaseProjectData, 'associated_customer_name', null)
              matchInformation['matching_database_project']['location_address'] = getProp(matchingDatabaseProjectData, 'location_address', null)
              matchInformation['matching_database_project']['location_city'] = getProp(matchingDatabaseProjectData, 'location_city', null)
              matchInformation['matching_database_project']['location_state'] = getProp(matchingDatabaseProjectData, 'location_state', null)
              matchInformation['matching_database_project']['location_zip'] = getProp(matchingDatabaseProjectData, 'location_zip', null)
            }
          })
          .catch((rej_DGC) => {
            // No match found
          }),
      )
    } else {
      // Project does not have a job code - check for existing project with either email or phone match
      matchInformation['desired_operation_type'] = 'create'
      let phoneQueryRef = generateDatabaseQuery(
        DatabaseRef_Projects_Collection(clientKey),
        [{ prop: 'associated_customer_phone', comparator: '==', value: projectData.associated_customer_phone }],
        [],
        {},
        100,
      )
      let emailQueryRef = generateDatabaseQuery(
        DatabaseRef_Projects_Collection(clientKey),
        [{ prop: 'associated_customer_email', comparator: '==', value: projectData.associated_customer_email }],
        [],
        {},
        100,
      )
      promiseArray1.push(
        DatabaseGetCollection(phoneQueryRef)
          .then((res_DGC) => {
            if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
              let matchingDatabaseProjectData = objectToArray(res_DGC.data)[0]
              matchInformation['found_matching_project'] = true
              matchInformation['found_matching_phone'] = true
              // Project found - fill out matching information
              matchInformation['matching_database_project']['key'] = getProp(matchingDatabaseProjectData, 'key', null)
              matchInformation['matching_database_project']['id_number'] = getProp(matchingDatabaseProjectData, 'id_number', null)
              matchInformation['matching_database_project']['associated_customer_name'] = getProp(matchingDatabaseProjectData, 'associated_customer_name', null)
              matchInformation['matching_database_project']['location_address'] = getProp(matchingDatabaseProjectData, 'location_address', null)
              matchInformation['matching_database_project']['location_city'] = getProp(matchingDatabaseProjectData, 'location_city', null)
              matchInformation['matching_database_project']['location_state'] = getProp(matchingDatabaseProjectData, 'location_state', null)
              matchInformation['matching_database_project']['location_zip'] = getProp(matchingDatabaseProjectData, 'location_zip', null)
            }
          })
          .catch((rej_DGC) => {
            // No match found
          }),
      )
      promiseArray1.push(
        DatabaseGetCollection(emailQueryRef)
          .then((res_DGC) => {
            if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
              let matchingDatabaseProjectData = objectToArray(res_DGC.data)[0]
              matchInformation['found_matching_project'] = true
              matchInformation['found_matching_email'] = true
              // Project found - fill out matching information
              matchInformation['matching_database_project']['key'] = getProp(matchingDatabaseProjectData, 'key', null)
              matchInformation['matching_database_project']['id_number'] = getProp(matchingDatabaseProjectData, 'id_number', null)
              matchInformation['matching_database_project']['associated_customer_name'] = getProp(matchingDatabaseProjectData, 'associated_customer_name', null)
              matchInformation['matching_database_project']['location_address'] = getProp(matchingDatabaseProjectData, 'location_address', null)
              matchInformation['matching_database_project']['location_city'] = getProp(matchingDatabaseProjectData, 'location_city', null)
              matchInformation['matching_database_project']['location_state'] = getProp(matchingDatabaseProjectData, 'location_state', null)
              matchInformation['matching_database_project']['location_zip'] = getProp(matchingDatabaseProjectData, 'location_zip', null)
            }
          })
          .catch((rej_DGC) => {
            // No match found
          }),
      )
    }
    Promise.all(promiseArray1).finally(() => {
      // Check for missing region and sales partner keys
      if (processedData['associated_region_key'] == null) {
        linkingCounts.red++
      }
      if (processedData['associated_sales_partner_key'] == null) {
        linkingCounts.red++
      }
      // TODO: proceed with project update or create IF there are no errors
      let projectRootUpdateObject: TsInterface_UnspecifiedObject = {}
      let projectAdditionalDataUpdateObject: TsInterface_UnspecifiedObject = {}
      let importUpdateObject: TsInterface_UnspecifiedObject = {
        status: 'pending_import',
        substatus: 'processing',
        match_information: matchInformation,
        processed_data: processedData,
        link_counts: linkingCounts,
        data: updatesToBaseData,
      }
      // Loop through and create the project root and additional data update object
      for (let loopPropKey in projectImportMappingV2) {
        let importMapping = projectImportMappingV2[loopPropKey]
        // Add data values to the update objects
        if (importMapping.import_location === 'rootProject') {
          if (getProp(updatesToBaseData, loopPropKey, null) != null) {
            projectRootUpdateObject[loopPropKey] = getProp(updatesToBaseData, loopPropKey, null)
          } else if (getProp(projectData, loopPropKey, null) != null) {
            projectRootUpdateObject[loopPropKey] = getProp(projectData, loopPropKey, null)
          }
          // Handle processed data values
          for (let loopProcessedFieldIndex in getProp(importMapping, 'processed_data_fields', [])) {
            let loopProcessedField = getProp(importMapping, 'processed_data_fields', [])[loopProcessedFieldIndex]
            if (loopProcessedField != null && loopProcessedField != undefined && getProp(processedData, loopProcessedField, null) != null) {
              projectRootUpdateObject[loopProcessedField] = getProp(processedData, loopProcessedField, null)
            }
          }
        } else if (importMapping.import_location === 'additionalData') {
          if (getProp(updatesToBaseData, loopPropKey, null) != null) {
            projectAdditionalDataUpdateObject[loopPropKey] = getProp(updatesToBaseData, loopPropKey, null)
          } else if (getProp(projectData, loopPropKey, null) != null) {
            projectAdditionalDataUpdateObject[loopPropKey] = getProp(projectData, loopPropKey, null)
          }
          // Handle processed data values
          for (let loopProcessedFieldIndex in getProp(importMapping, 'processed_data_fields', [])) {
            let loopProcessedField = getProp(importMapping, 'processed_data_fields', [])[loopProcessedFieldIndex]
            if (loopProcessedField != null && loopProcessedField != undefined && getProp(processedData, loopProcessedField, null) != null) {
              projectAdditionalDataUpdateObject[loopPropKey] = getProp(processedData, loopProcessedField, null)
            }
          }
        }
      }
      // Batch Update
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
      importUpdateObject['import_notes'] = {
        red_master_data_links: '',
        yellow_master_data_links: '',
        mismatched_fields: '',
        result: '',
      }
      if (matchInformation['desired_operation_type'] == 'update') {
        // Handle Project Updates
        if (matchInformation['found_matching_project'] == true) {
          // Make sure that the customer name and address match
          let majorFieldsMatch = true
          let mismatchedFields: string[] = []
          if (matchInformation['import_project']['associated_customer_name'] != matchInformation['matching_database_project']['associated_customer_name']) {
            majorFieldsMatch = false
            mismatchedFields.push('Customer Name')
          }
          if (matchInformation['import_project']['location_address'] != matchInformation['matching_database_project']['location_address']) {
            majorFieldsMatch = false
            mismatchedFields.push('Address')
          }
          if (matchInformation['import_project']['location_address'] != matchInformation['matching_database_project']['location_address']) {
            majorFieldsMatch = false
            mismatchedFields.push('City')
          }
          if (matchInformation['import_project']['location_state'] != matchInformation['matching_database_project']['location_state']) {
            majorFieldsMatch = false
            mismatchedFields.push('State')
          }
          if (matchInformation['import_project']['location_zip'] != matchInformation['matching_database_project']['location_zip']) {
            majorFieldsMatch = false
            mismatchedFields.push('Zip')
          }
          // Handle red vs yellow links
          let updateProject = false
          if (linkingCounts.red > 0) {
            // Cannot import until the red links are resolved
            importUpdateObject['status'] = 'error'
            importUpdateObject['substatus'] = 'blocking_error_unlinked_master_data'
            importUpdateObject['import_notes']['red_master_data_links'] = 'Cannot import until the red links are resolved'
            // Server Only
            // if (forceImport == true) {
            //   importUpdateObject['status'] = 'success'
            //   importUpdateObject['substatus'] = 'success'
            //   updateProject = true
            // }
          } else if (linkingCounts.yellow > 0 || majorFieldsMatch == false) {
            // User has to manually confirm to proceed
            importUpdateObject['status'] = 'warning'
            importUpdateObject['substatus'] = 'override_missing_data'
            if (linkingCounts.yellow > 0) {
              importUpdateObject['import_notes']['yellow_master_data_links'] = 'Missing some master data.'
            }
            if (mismatchedFields.length > 0) {
              importUpdateObject['import_notes']['mismatched_fields'] = 'Changes detected on important fields: ' + mismatchedFields.join(', ') + '. '
            }
            if (forceImport == true) {
              importUpdateObject['status'] = 'success'
              importUpdateObject['substatus'] = 'success'
              updateProject = true
            }
          } else {
            // No errors - proceed with import
            importUpdateObject['status'] = 'success'
            importUpdateObject['substatus'] = 'success'
            importUpdateObject['import_notes']['result'] = 'Project updated successfully'
            updateProject = true
          }
          if (updateProject == true) {
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_Project_Document(clientKey, matchInformation['matching_database_project']['key']),
              data: projectRootUpdateObject,
            })
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_ProjectAdditionalData_Document(clientKey, matchInformation['matching_database_project']['key']),
              data: projectAdditionalDataUpdateObject,
            })
          }
        } else {
          // No matching project found
          importUpdateObject['status'] = 'error'
          importUpdateObject['substatus'] = 'blocking_error_no_matching_project'
          importUpdateObject['import_notes']['result'] = 'No matching project found'
        }
      } else if (matchInformation['desired_operation_type'] == 'create') {
        // Handle Project Creation
        if (matchInformation['found_matching_project'] == false || forceImport == true) {
          let projectKey = uuidv4()
          promiseArray2.push(
            generateJobCode(
              clientKey,
              processedData['associated_region_key'],
              processedData['associated_sales_partner_key'],
              projectData['associated_customer_name'],
            )
              .then((res_GJC) => {
                console.log('<><><><>')
                console.log(res_GJC)
                console.log(projectKey)
                if (res_GJC != null && getProp(res_GJC, 'jobCode', null) != null) {
                  projectRootUpdateObject['key'] = projectKey
                  projectRootUpdateObject['status'] = 'unassigned'
                  importUpdateObject['job_code'] = getProp(res_GJC, 'jobCode', null)
                  importUpdateObject['new_project_key'] = projectKey
                  projectRootUpdateObject['id_number'] = getProp(res_GJC, 'jobCode', null)
                  projectRootUpdateObject['timestamp_last_spreadsheet_import'] = new Date()
                  importUpdateObject['status'] = 'success'
                  importUpdateObject['substatus'] = 'success'
                  importUpdateObject['import_notes']['result'] = 'Project Created Successfully'
                  let updateProject = false
                  if (linkingCounts.red > 0) {
                    // Cannot import until the red links are resolved
                    importUpdateObject['status'] = 'error'
                    importUpdateObject['substatus'] = 'blocking_error_unlinked_master_data'
                    importUpdateObject['import_notes']['red_master_data_links'] = 'Cannot import until the red links are resolved'
                    // Server Only
                    // if (forceImport == true) {
                    //   importUpdateObject['status'] = 'success'
                    //   importUpdateObject['substatus'] = 'success'
                    //   updateProject = true
                    // }
                  } else if (linkingCounts.yellow > 0) {
                    // User has to manually confirm to proceed
                    importUpdateObject['status'] = 'warning'
                    importUpdateObject['substatus'] = 'override_missing_data'
                    if (linkingCounts.yellow > 0) {
                      importUpdateObject['import_notes']['yellow_master_data_links'] = 'Missing some master data.'
                    }
                    if (forceImport == true) {
                      importUpdateObject['status'] = 'success'
                      importUpdateObject['substatus'] = 'success'
                      updateProject = true
                    }
                  } else {
                    // No errors - proceed with import
                    importUpdateObject['status'] = 'success'
                    importUpdateObject['substatus'] = 'success'
                    importUpdateObject['import_notes']['result'] = 'Project updated successfully'
                    updateProject = true
                  }
                  if (updateProject == true) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Project_Document(clientKey, projectKey),
                      data: projectRootUpdateObject,
                    })
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_ProjectAdditionalData_Document(clientKey, projectKey),
                      data: projectAdditionalDataUpdateObject,
                    })
                  }
                } else {
                  importUpdateObject['status'] = 'error'
                  importUpdateObject['substatus'] = 'blocking_error_generating_job_code'
                  importUpdateObject['import_notes']['result'] = 'Error generating job code'
                }
              })
              .catch((rej_GJC) => {
                importUpdateObject['status'] = 'error'
                importUpdateObject['substatus'] = 'blocking_error_generating_job_code'
                importUpdateObject['import_notes']['result'] = 'Error generating job code'
              }),
          )
        } else {
          importUpdateObject['status'] = 'warning'
          importUpdateObject['substatus'] = 'override_existing_project'
          importUpdateObject['import_notes']['result'] = 'Customer already exists on another project'
          if (matchInformation['found_matching_phone'] == true && matchInformation['found_matching_email'] == true) {
            importUpdateObject['import_notes']['result'] =
              'Customer already exists on another project - phone and email match (' + matchInformation['matching_database_project']['id_number'] + ')'
          } else if (matchInformation['found_matching_phone'] == true) {
            importUpdateObject['import_notes']['result'] =
              'Customer already exists on another project - phone match (' + matchInformation['matching_database_project']['id_number'] + ')'
          } else if (matchInformation['found_matching_email'] == true) {
            importUpdateObject['import_notes']['result'] =
              'Customer already exists on another project - email match (' + matchInformation['matching_database_project']['id_number'] + ')'
          }
        }
      } else {
        // Missing data required to proceed
        importUpdateObject['status'] = 'error'
        importUpdateObject['substatus'] = 'blocking_error_missing_data'
        importUpdateObject['import_notes']['result'] = 'Missing data required to proceed'
      }
      // Update Database
      Promise.all(promiseArray2).finally(() => {
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_ProjectImportSession_Projects_Document(clientKey, projectTempKey),
          data: importUpdateObject,
        })
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve({
              success: true,
              importUpdateObject,
            })
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      })
    })
  })
}

export const generateJobCode = (
  clientKey: string,
  regionKey: string, // 312
  salesPartnerKey: string, // US
  customerFullName: string, // John Doe
) => {
  return new Promise((resolve, reject) => {
    // Extract the last name more robustly
    let nameParts = customerFullName.trim().split(' ')
    let lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : customerFullName
    // Handle suffixes like Jr, Sr, III, etc.
    const commonSuffixes = ['jr', 'sr', 'ii', 'iii', 'iv', 'v']
    if (nameParts.length > 2) {
      const lastPart = nameParts[nameParts.length - 1].toLowerCase().replace('.', '')
      if (commonSuffixes.includes(lastPart)) {
        // If the last part is a suffix, use the part before it as the last name
        lastName = nameParts[nameParts.length - 2]
      }
    }
    // Get the first 4 letters of the customer's last name (or fewer if last name is shorter)
    let lastNameSubstring = lastName.substring(0, 4).toUpperCase()
    let regionCode: string | null = null
    let salesPartnerCode: string | null = null
    let promiseArray: Promise<unknown>[] = []
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_Region_Document(clientKey, regionKey)).then((res_DGD) => {
        if (res_DGD != null && res_DGD.data != null) {
          let regionData = res_DGD.data
          regionCode = getProp(regionData, 'number_prefix', null)
        }
      }),
    )
    promiseArray.push(
      DatabaseGetDocument(DatabaseRef_SalesPartner_Document(clientKey, salesPartnerKey)).then((res_DGD) => {
        if (res_DGD != null && res_DGD.data != null) {
          let salesPartnerData = res_DGD.data
          salesPartnerCode = getProp(salesPartnerData, 'partner_code', null)
        }
      }),
    )
    Promise.all(promiseArray).finally(() => {
      if (regionCode != null && salesPartnerCode != null) {
        let baseJobCode = regionCode.toString() + salesPartnerCode.toString() + '-' + lastNameSubstring

        // Function to check if a job code exists and try the next one if it does
        const findUniqueJobCode = (currentNumber: number, maxAttempts: number = 100): Promise<{ jobCode: string } | { jobCode: string; error: Error }> => {
          // Reject if we've exceeded the maximum number of attempts
          if (currentNumber > maxAttempts) {
            return Promise.reject({
              error: new Error(`Failed to generate a unique job code after ${maxAttempts} attempts`),
              lastAttemptedJobCode: baseJobCode + currentNumber,
            })
          }

          let jobCode = baseJobCode + currentNumber

          return DatabaseGetCollection(DatabaseRef_ProjectsByIdNumber_Query(clientKey, jobCode))
            .then((res_DGC) => {
              if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                // Job code exists, try the next number
                return findUniqueJobCode(currentNumber + 1, maxAttempts)
              } else {
                // Job code is unique, use it
                return { jobCode: jobCode }
              }
            })
            .catch((error) => {
              console.error('Error checking job code:', error)
              // In case of error, still return a job code
              return { jobCode: jobCode, error: error }
            })
        }

        // Start with number 1 and find a unique job code
        findUniqueJobCode(1)
          .then((result) => resolve(result))
          .catch((error) => reject(error))
      } else {
        reject({ error: 'Missing region or sales partner code' })
      }
    })
  })
}

export const projectImportMappingV2: TsInterface_UnspecifiedObject = {
  // Customer Information
  // TODO: Add processing function to generate job code of link to existing project
  id_number: {
    import_location: 'rootProject',
    key: 'id_number',
    required: true,
    label: rLIB('Job Code', false),
    automatch_properties: ['Job Code'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'id_number', '')
    },
  },
  associated_customer_name: {
    import_location: 'rootProject',
    key: 'associated_customer_name',
    required: true,
    label: rLIB('Customer Name', false),
    automatch_properties: ['Customer Name'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      if (getProp(getProp(rowData, 'data', {}), 'associated_customer_name', null) == null) {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Missing')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX} {getProp(getProp(rowData, 'data', {}), 'associated_customer_name', '')}
        </>
      )
    },
  },
  associated_customer_phone: {
    import_location: 'rootProject',
    key: 'associated_customer_phone',
    required: true,
    label: rLIB('Phone', false),
    automatch_properties: ['Phone'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      if (getProp(getProp(rowData, 'data', {}), 'associated_customer_phone', null) == null) {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Missing')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX} {getProp(getProp(rowData, 'data', {}), 'associated_customer_phone', '')}
        </>
      )
    },
    cleaning_function: (value: string) => {
      // Remove non-numeric characters, if there is a leading 1, remove it, save as a number
      return parseInt(value.replace(/[^0-9]/g, '').replace(/^1/, ''))
    },
  },
  associated_customer_additional_phone: {
    import_location: 'rootProject',
    key: 'associated_customer_additional_phone',
    required: false,
    label: rLIB('Additional Contact Details', false),
    automatch_properties: ['Additional Contact Details'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'associated_customer_additional_phone', '')
    },
  },
  associated_customer_email: {
    import_location: 'rootProject',
    key: 'associated_customer_email',
    required: true,
    label: rLIB('Email', false),
    automatch_properties: ['Email'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      if (getProp(getProp(rowData, 'data', {}), 'associated_customer_email', null) == null) {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Missing')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX} {getProp(getProp(rowData, 'data', {}), 'associated_customer_email', '')}
        </>
      )
    },
    cleaning_function: (value: string) => {
      // Trim and lowercase
      return value.trim().toLowerCase()
    },
  },
  // Address Information
  location_address: {
    import_location: 'rootProject',
    key: 'location_address',
    required: true,
    label: rLIB('Address', false),
    automatch_properties: ['Address', 'Street Address'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      if (getProp(getProp(rowData, 'data', {}), 'location_address', null) == null) {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Missing')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX} {getProp(getProp(rowData, 'data', {}), 'location_address', '')}
        </>
      )
    },
  },
  location_city: {
    import_location: 'rootProject',
    key: 'location_city',
    required: true,
    label: rLIB('City', false),
    automatch_properties: ['City'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      if (getProp(getProp(rowData, 'data', {}), 'location_city', null) == null) {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Missing')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX} {getProp(getProp(rowData, 'data', {}), 'location_city', '')}
        </>
      )
    },
  },
  location_county: {
    import_location: 'rootProject',
    key: 'location_county',
    required: false,
    label: rLIB('County', false),
    automatch_properties: ['County'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'location_county', '')
    },
  },
  location_zip: {
    import_location: 'rootProject',
    key: 'location_zip',
    required: true,
    label: rLIB('Zip', false),
    automatch_properties: ['Zip'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      if (getProp(getProp(rowData, 'data', {}), 'location_zip', null) == null) {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Missing')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX} {getProp(getProp(rowData, 'data', {}), 'location_zip', '')}
        </>
      )
    },
  },
  location_state: {
    import_location: 'rootProject',
    key: 'location_state',
    required: true,
    label: rLIB('State', false),
    automatch_properties: ['State'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      if (getProp(getProp(rowData, 'data', {}), 'location_state', null) == null) {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Missing')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX} {getProp(getProp(rowData, 'data', {}), 'location_state', '')}
        </>
      )
    },
  },
  location_access_instructions: {
    import_location: 'rootProject',
    key: 'location_access_instructions',
    required: false,
    label: rLIB('Gate Code / Access Instructions', false),
    automatch_properties: ['Gate Code / Access Instructions'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'location_access_instructions', '')
    },
  },
  // Associations
  associated_region_name: {
    import_location: 'rootProject',
    key: 'associated_region_name',
    required: true,
    label: rLIB('Region', false),
    automatch_properties: ['Region'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      // let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      // let regionName = getProp(importData, 'associated_region_name', '')
      let associatedRegionKey = getProp(processedData, 'associated_region_key', null)
      if (associatedRegionKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
        // } else if (regionName == null || regionName == '') {
        //   iconJSX = (
        //     <Icon
        //       icon="triangle-exclamation"
        //       tooltip={rLIB('Not Linked')}
        //       tooltipPlacement="top"
        //       className="tw-mr-2"
        //       sx={{ color: themeVariables.warning_main }}
        //     />
        //   )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_region_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_region_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_region_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(DatabaseRef_Regions_Collection(clientKey), [{ prop: 'name', comparator: '==', value: queryValue }], [], {}, 100)
          // Search for existing region name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_region_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no region name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_Region_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_region_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  associated_jurisdiction_name: {
    import_location: 'rootProject',
    key: 'associated_jurisdiction_name',
    required: true,
    label: rLIB('Jurisdiction', false),
    automatch_properties: ['Jurisdiction'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let jurisdictionName = getProp(importData, 'associated_jurisdiction_name', '')
      let associatedJurisdictionKey = getProp(processedData, 'associated_jurisdiction_key', null)
      if (associatedJurisdictionKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (jurisdictionName == null || jurisdictionName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_jurisdiction_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_jurisdiction_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_jurisdiction_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_Jurisdictions_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing jurisdiction name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_jurisdiction_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no jurisdiction name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_Jurisdiction_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_jurisdiction_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  associated_hoa_name: {
    import_location: 'rootProject',
    key: 'associated_hoa_name',
    required: true,
    label: rLIB('HOA', false),
    automatch_properties: ['HOA'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let hoaName = getProp(importData, 'associated_hoa_name', '')
      let associatedHOAKey = getProp(processedData, 'associated_hoa_key', null)
      if (associatedHOAKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (hoaName == null || hoaName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_hoa_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_hoa_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_hoa_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(DatabaseRef_Hoas_Collection(clientKey), [{ prop: 'name', comparator: '==', value: queryValue }], [], {}, 100)
          // Search for existing HOA name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_hoa_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no HOA name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_Hoa_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_hoa_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  associated_utility_company_name: {
    import_location: 'rootProject',
    key: 'associated_utility_company_name',
    required: true,
    label: rLIB('Utility Company', false),
    automatch_properties: ['Utility Company'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let utilityCompanyName = getProp(importData, 'associated_utility_company_name', '')
      let associatedUtilityCompanyKey = getProp(processedData, 'associated_utility_company_key', null)
      if (associatedUtilityCompanyKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (utilityCompanyName == null || utilityCompanyName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_utility_company_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_utility_company_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_utility_company_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_Utilities_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing utility company name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_utility_company_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no utility company name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_Utility_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_utility_company_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  // Sales Information
  associated_sales_partner_name: {
    import_location: 'rootProject',
    key: 'associated_sales_partner_name',
    required: true,
    label: rLIB('Sales Partner', false),
    automatch_properties: ['Sales Partner'],
    required_for_import: true,
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      // let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      // let salesPartnerName = getProp(importData, 'associated_sales_partner_name', '')
      let associatedSalesPartnerKey = getProp(processedData, 'associated_sales_partner_key', null)
      if (associatedSalesPartnerKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
        // } else if (salesPartnerName == null || salesPartnerName == '') {
        //   iconJSX = (
        //     <Icon
        //       icon="triangle-exclamation"
        //       tooltip={rLIB('Not Linked')}
        //       tooltipPlacement="top"
        //       className="tw-mr-2"
        //       sx={{ color: themeVariables.warning_main }}
        //     />
        //   )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_sales_partner_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_sales_partner_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_sales_partner_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_SalesPartner_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing sales partner name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_sales_partner_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no sales partner name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_SalesPartner_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_sales_partner_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  associated_sales_rep_name: {
    import_location: 'rootProject',
    key: 'associated_sales_rep_name',
    required: false,
    label: rLIB('Sales Rep Name', false),
    automatch_properties: ['Sales Rep Name'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'associated_sales_rep_name', '')
    },
  },
  associated_sales_rep_phone: {
    import_location: 'rootProject',
    key: 'associated_sales_rep_phone',
    required: false,
    label: rLIB('Sales Rep Phone', false),
    automatch_properties: ['Sales Rep Phone'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'associated_sales_rep_phone', '')
    },
  },
  associated_sales_rep_email: {
    import_location: 'rootProject',
    key: 'associated_sales_rep_email',
    required: false,
    label: rLIB('Sales Rep Email', false),
    automatch_properties: ['Sales Rep Email'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'associated_sales_rep_email', '')
    },
  },
  // Financials
  financials_financing_type: {
    import_location: 'rootProject',
    key: 'financials_financing_type',
    required: true,
    label: rLIB('Financing Type', false),
    automatch_properties: ['Financing Type'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let financingTypeName = getProp(importData, 'financials_financing_type', '')
      let associatedFinancingTypeKey = getProp(processedData, 'associated_financing_type_key', null)
      if (associatedFinancingTypeKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (financingTypeName == null || financingTypeName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'financials_financing_type', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_financing_type_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'financials_financing_type', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_FinancingType_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing financing type name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_financing_type_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no financing type name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_FinancingType_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_financing_type_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  associated_finance_partner_name: {
    import_location: 'rootProject',
    key: 'associated_finance_partner_name',
    required: true,
    label: rLIB('Finance Partner', false),
    automatch_properties: ['Finance Partner'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let financePartnerName = getProp(importData, 'associated_finance_partner_name', '')
      let associatedFinancePartnerKey = getProp(processedData, 'associated_finance_partner_key', null)
      if (associatedFinancePartnerKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (financePartnerName == null || financePartnerName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_finance_partner_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_finance_partner_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_finance_partner_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_FinancePartner_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing finance partner name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_finance_partner_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no finance partner name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_FinancePartner_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_finance_partner_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  financials_solar_epc_amount: {
    import_location: 'subCollection',
    key: 'financials_solar_epc_amount',
    required: false,
    label: rLIB('Solar EPC Amount', false),
    automatch_properties: ['Solar EPC Amount'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_solar_epc_amount', '')
    },
  },
  financials_loan_amount: {
    import_location: 'subCollection',
    key: 'financials_loan_amount',
    required: false,
    label: rLIB('Loan Amount', false),
    automatch_properties: ['Loan Amount'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_loan_amount', '')
    },
  },
  financials_apr: {
    import_location: 'subCollection',
    key: 'financials_apr',
    required: false,
    label: rLIB('APR', false),
    automatch_properties: ['APR'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_apr', '')
    },
  },
  financials_loan_term: {
    import_location: 'subCollection',
    key: 'financials_loan_term',
    required: false,
    label: rLIB('Loan Term', false),
    automatch_properties: ['Loan Term'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_loan_term', '')
    },
  },
  financials_solar_plus_battery_epc_amount: {
    import_location: 'subCollection',
    key: 'financials_solar_plus_battery_epc_amount',
    required: false,
    label: rLIB('Total Solar + Battery EPC Amount', false),
    automatch_properties: ['Total Solar + Battery EPC Amount'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_solar_plus_battery_epc_amount', '')
    },
  },
  financials_solar_rate: {
    import_location: 'subCollection',
    key: 'financials_solar_rate',
    required: false,
    label: rLIB('Solar Rate', false),
    automatch_properties: ['Solar Rate'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_solar_rate', '')
    },
  },
  financials_escalator: {
    import_location: 'subCollection',
    key: 'financials_escalator',
    required: false,
    label: rLIB('Escalator', false),
    automatch_properties: ['Escalator'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_escalator', '')
    },
  },
  financials_est_y1_solar_monthly_payment: {
    import_location: 'subCollection',
    key: 'financials_est_y1_solar_monthly_payment',
    required: false,
    label: rLIB('Est. Y1 Solar Monthly Payment', false),
    automatch_properties: ['Est. Y1 Solar Monthly Payment'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_est_y1_solar_monthly_payment', '')
    },
  },
  financials_est_y1_battery_monthly_payment: {
    import_location: 'subCollection',
    key: 'financials_est_y1_battery_monthly_payment',
    required: false,
    label: rLIB('Est. Y1 Battery Monthly Payment', false),
    automatch_properties: ['Est. Y1 Battery Monthly Payment'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_est_y1_battery_monthly_payment', '')
    },
  },
  financials_dealer_fee: {
    import_location: 'subCollection',
    key: 'financials_dealer_fee',
    required: false,
    label: rLIB('Dealer Fee', false),
    automatch_properties: ['Dealer Fee'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_dealer_fee', '')
    },
  },
  financials_dealer_fee_percentage: {
    import_location: 'subCollection',
    key: 'financials_dealer_fee_percentage',
    required: false,
    label: rLIB('Dealer Fee (%)', false),
    automatch_properties: ['Dealer Fee (%)'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'financials_dealer_fee_percentage', '')
    },
  },
  // System Information
  associated_product_name: {
    import_location: 'rootProject',
    key: 'associated_product_name',
    required: true,
    label: rLIB('Product Package', false),
    automatch_properties: ['Product Package'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let productName = getProp(importData, 'associated_product_name', '')
      let associatedProductKey = getProp(processedData, 'associated_product_key', null)
      if (associatedProductKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (productName == null || productName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_product_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_product_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_product_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_ProductPackages_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing product name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_product_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no product name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_ProductPackage_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_product_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  system_size_dc: {
    import_location: 'rootProject',
    key: 'system_size_dc',
    required: true,
    label: rLIB('System Size DC', false),
    automatch_properties: ['System Size DC'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_size_dc', '')
    },
  },
  system_size_ac: {
    import_location: 'rootProject',
    key: 'system_size_ac',
    required: true,
    label: rLIB('System Size AC', false),
    automatch_properties: ['System Size AC'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_size_ac', '')
    },
  },
  system_estimated_annual_production: {
    import_location: 'rootProject',
    key: 'system_estimated_annual_production',
    required: true,
    label: rLIB('Est. Y1 Production', false),
    automatch_properties: ['Est. Y1 Production'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_estimated_annual_production', '')
    },
  },
  system_storage_total_kwh: {
    import_location: 'rootProject',
    key: 'system_storage_total_kwh',
    required: false,
    label: rLIB('Total Storage', false),
    automatch_properties: ['Total Storage'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_storage_total_kwh', '')
    },
  },
  system_panel_quantity: {
    import_location: 'rootProject',
    key: 'system_panel_quantity',
    required: true,
    label: rLIB('Module Quantity', false),
    automatch_properties: ['Module Quantity'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_panel_quantity', '')
    },
  },
  associated_system_module_name: {
    import_location: 'rootProject',
    key: 'associated_system_module_name',
    required: true,
    label: rLIB('Module', false),
    automatch_properties: ['Module'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let moduleName = getProp(importData, 'associated_system_module_name', '')
      let associatedModuleKey = getProp(processedData, 'associated_module_key', null)
      if (associatedModuleKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (moduleName == null || moduleName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_system_module_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_module_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_system_module_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(DatabaseRef_Modules_Collection(clientKey), [{ prop: 'name', comparator: '==', value: queryValue }], [], {}, 100)
          // Search for existing module name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_module_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no module name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_Module_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_module_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  system_inverter_quantity: {
    import_location: 'rootProject',
    key: 'system_inverter_quantity',
    required: true,
    label: rLIB('Inverter Quantity', false),
    automatch_properties: ['Inverter Quantity'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_inverter_quantity', '')
    },
  },
  associated_system_inverter_name: {
    import_location: 'rootProject',
    key: 'associated_system_inverter_name',
    required: true,
    label: rLIB('Inverter', false),
    automatch_properties: ['Inverter'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let inverterName = getProp(importData, 'associated_system_inverter_name', '')
      let associatedInverterKey = getProp(processedData, 'associated_inverter_key', null)
      if (associatedInverterKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (inverterName == null || inverterName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_system_inverter_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_inverter_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_system_inverter_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_Inverters_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing inverter name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_inverter_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no inverter name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_Inverter_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_inverter_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  system_racking_quantity: {
    import_location: 'rootProject',
    key: 'system_racking_quantity',
    required: true,
    label: rLIB('Racking Quantity', false),
    automatch_properties: ['Racking Quantity'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_racking_quantity', '')
    },
  },
  associated_system_racking_name: {
    import_location: 'rootProject',
    key: 'associated_system_racking_name',
    required: true,
    label: rLIB('Racking', false),
    automatch_properties: ['Racking'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let rackingName = getProp(importData, 'associated_system_racking_name', '')
      let associatedRackingKey = getProp(processedData, 'associated_racking_key', null)
      if (associatedRackingKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (rackingName == null || rackingName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_system_racking_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_racking_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_system_racking_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(DatabaseRef_Racking_Collection(clientKey), [{ prop: 'name', comparator: '==', value: queryValue }], [], {}, 100)
          // Search for existing racking name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_racking_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no racking name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_Racking_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_racking_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  system_storage_quantity: {
    import_location: 'rootProject',
    key: 'system_storage_quantity',
    required: true,
    label: rLIB('Storage Quantity', false),
    automatch_properties: ['Storage Quantity'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_storage_quantity', '')
    },
  },
  associated_system_storage_name: {
    import_location: 'rootProject',
    key: 'associated_system_storage_name',
    required: true,
    label: rLIB('Storage', false),
    automatch_properties: ['Storage'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let storageName = getProp(importData, 'associated_system_storage_name', '')
      let associatedStorageKey = getProp(processedData, 'associated_storage_key', null)
      if (associatedStorageKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (storageName == null || storageName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_system_storage_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_storage_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_system_storage_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_Batteries_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing storage name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_storage_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no storage name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_Battery_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_storage_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  // Home Information
  home_building_type: {
    import_location: 'subCollection',
    key: 'home_building_type',
    required: false,
    label: rLIB('Home Type', false),
    automatch_properties: ['Home Type'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let buildingTypeName = getProp(importData, 'home_building_type', '')
      let associatedBuildingTypeKey = getProp(processedData, 'associated_building_type_key', null)
      if (associatedBuildingTypeKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (buildingTypeName == null || buildingTypeName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'home_building_type', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_building_type_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'home_building_type', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_BuildingType_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing building type name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_building_type_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no building type name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_BuildingType_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_building_type_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  associated_mount_type_name: {
    import_location: 'subCollection',
    key: 'associated_mount_type_name',
    required: false,
    label: rLIB('Roof/Mount Type', false),
    automatch_properties: ['Roof/Mount Type'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      let iconJSX = <></>
      let importData = getProp(rowData, 'data', {})
      let processedData = getProp(rowData, 'processed_data', {})
      let mountTypeName = getProp(importData, 'associated_mount_type_name', '')
      let associatedMountTypeKey = getProp(processedData, 'associated_mount_type_key', null)
      if (associatedMountTypeKey != null) {
        iconJSX = (
          <Icon
            icon="badge-check"
            tooltip={rLIB('Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.success_main }}
          />
        )
      } else if (mountTypeName == null || mountTypeName == '') {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.warning_main }}
          />
        )
      } else {
        iconJSX = (
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Not Linked')}
            tooltipPlacement="top"
            className="tw-mr-2"
            sx={{ color: themeVariables.error_main }}
          />
        )
      }
      return (
        <>
          {iconJSX}
          {getProp(getProp(rowData, 'data', {}), 'associated_mount_type_name', '')}
        </>
      )
    },
    has_master_data_link: true,
    processed_data_fields: ['associated_mount_type_key'],
    linking_function: (clientKey: string, rowData: TsInterface_UnspecifiedObject) => {
      return new Promise((resolve, reject) => {
        let queryValue = getProp(rowData, 'associated_mount_type_name', null)
        let lookingForMatch = false
        let foundMatch = false
        let promiseArray1: Promise<unknown>[] = []
        let promiseArray2: Promise<unknown>[] = []
        let matchingItem: TsInterface_UnspecifiedObject = {}
        let outputData: TsInterface_UnspecifiedObject = {}
        if (queryValue != null) {
          lookingForMatch = true
          let queryRef = generateDatabaseQuery(
            DatabaseRef_MountTypes_Collection(clientKey),
            [{ prop: 'name', comparator: '==', value: queryValue }],
            [],
            {},
            100,
          )
          // Search for existing mount type name match
          promiseArray1.push(
            DatabaseGetCollection(queryRef)
              .then((res_DGC) => {
                if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                  matchingItem = objectToArray(res_DGC.data)[0]
                  outputData['associated_mount_type_key'] = getProp(matchingItem, 'key', null)
                  foundMatch = true
                }
              })
              .catch((rej_DGC) => {
                // No match found
              }),
          )
        }
        Promise.all(promiseArray1).finally(() => {
          if (foundMatch == false && lookingForMatch === true) {
            // Search for alias match if no mount type name match is found
            try {
              promiseArray2.push(
                DatabaseGetDocument(DatabaseRef_MountType_ImportAliases_Document(clientKey, queryValue)).then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    matchingItem = res_DGD.data
                    outputData['associated_mount_type_key'] = getProp(matchingItem, 'associated_master_data_key', null)
                    foundMatch = true
                  }
                }),
              )
            } catch (error) {
              console.error(error)
            }
          }
          Promise.all(promiseArray2).finally(() => {
            resolve({ success: true, data: outputData, foundMatch: foundMatch, lookingForMatch: lookingForMatch })
          })
        })
      })
    },
  },
  system_max_roof_pitch: {
    import_location: 'rootProject',
    key: 'system_max_roof_pitch',
    required: false,
    label: rLIB('Max Roof Pitch', false),
    automatch_properties: ['Max Roof Pitch'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_max_roof_pitch', '')
    },
  },
  home_number_of_stories: {
    import_location: 'subCollection',
    key: 'home_number_of_stories',
    required: false,
    label: rLIB('Number of Stories', false),
    automatch_properties: ['Number of Stories'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'home_number_of_stories', '')
    },
  },
  system_number_of_arrays: {
    import_location: 'rootProject',
    key: 'system_number_of_arrays',
    required: false,
    label: rLIB('Number of Arrays', false),
    automatch_properties: ['Number of Arrays'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_number_of_arrays', '')
    },
  },
  system_number_of_strings: {
    import_location: 'rootProject',
    key: 'system_number_of_strings',
    required: false,
    label: rLIB('Number of Strings', false),
    automatch_properties: ['Number of Strings'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_number_of_strings', '')
    },
  },
  home_design_notes: {
    import_location: 'subCollection',
    key: 'home_design_notes',
    required: false,
    label: rLIB('Design Notes', false),
    automatch_properties: ['Design Notes'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'home_design_notes', '')
    },
  },
  system_has_attic_run: {
    import_location: 'rootProject',
    key: 'system_has_attic_run',
    required: false,
    label: rLIB('Attic Run', false),
    automatch_properties: ['Attic Run'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_has_attic_run', '')
    },
  },
  home_paint_conduit: {
    import_location: 'subCollection',
    key: 'home_paint_conduit',
    required: false,
    label: rLIB('Paint Conduit', false),
    automatch_properties: ['Paint Conduit'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'home_paint_conduit', '')
    },
  },
  home_minimize_panels_at_front_of_house: {
    import_location: 'subCollection',
    key: 'home_minimize_panels_at_front_of_house',
    required: false,
    label: rLIB('Minimize panels at front of house', false),
    automatch_properties: ['Minimize Panels at Front of House'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'home_minimize_panels_at_front_of_house', '')
    },
  },
  system_usage_offset: {
    import_location: 'rootProject',
    key: 'system_usage_offset',
    required: false,
    label: rLIB('Usage Offset', false),
    automatch_properties: ['Usage Offset'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'system_usage_offset', '')
    },
  },
  home_other_customer_requests: {
    import_location: 'subCollection',
    key: 'home_other_customer_requests',
    required: false,
    label: rLIB('Other Requests', false),
    automatch_properties: ['Other Requests'],
    import_table_cell_render: (rowData: TsInterface_UnspecifiedObject) => {
      return getProp(getProp(rowData, 'data', {}), 'home_other_customer_requests', '')
    },
  },
}

export const downloadProjectImportTemplate = (): void => {
  let fileData: (string | number | boolean | null | undefined)[][] = []
  let headerRow: (string | number | boolean | null | undefined)[] = []
  for (let importMappingOptionKey in projectImportMappingV2) {
    headerRow.push(getProp(projectImportMappingV2[importMappingOptionKey], 'label', ''))
  }
  fileData.push(headerRow)
  downloadCSV('Project Import Template.csv', fileData)
  // TODO: Loop through all of the import mapping options and create a template
  // TODO: Download the template
  // TODO: Save the template to the user's computer
  // TODO: Provide a message to the user
}
