///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Checkbox, Dialog, Divider, FormControl, FormControlLabel, Menu, MenuItem, Select, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_TaskWorkflowsActive_Query, DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  DatabaseRef_ActiveProjectsForSpecificWorkflowAndSalesPartner_Query,
  DatabaseRef_Projects_Collection,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { IconMenu } from 'rfbp_core/components/icon_menu/icon_menu'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_UserPermissions,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  generateDatabaseQuery,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import * as XLSX from 'xlsx'
import { ProjectListProjectSearchTab } from '../projects/v2_list_tabs/tab_project_search'
import { projectExportSpreadsheetColumns } from './services/project_exports'
import { ProjectExportsTab } from './tabs/tab_project_exports'
import { PacingProjectPhasesTab } from './tabs/tab_project_phases'
import { TaskOnHoldTab } from './tabs/tab_task_on_hold'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminProjectPacingPage']['key']

// Project Statuses
const projectStatuses = {
  active: {
    icon: 'circle-play',
    text: rLIB('Active'),
  },
  on_hold: {
    icon: 'circle-pause',
    text: rLIB('On Hold'),
  },
  completed: {
    icon: 'circle-check',
    text: rLIB('Completed'),
  },
  cancelled: {
    icon: 'circle-xmark',
    text: rLIB('Cancelled'),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const downloadXLSX = (fileName: string, dataArray: string[][]): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      // Create a new workbook
      const workbook = XLSX.utils.book_new()

      // Convert data array to worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(dataArray)

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

      // Generate Excel file buffer
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

      // Create a Blob from the buffer
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      // Create URL for the blob
      const url = URL.createObjectURL(blob)

      // Create a link and trigger download
      const link = document.createElement('a')
      link.href = url
      link.download = `${fileName}.xlsx`
      document.body.appendChild(link)
      link.click()

      // Clean up
      document.body.removeChild(link)
      URL.revokeObjectURL(url)

      resolve({ success: true })
    } catch (error) {
      reject(error)
    }
  })
}

const exportProjectCsv = (projects: TsInterface_UnspecifiedObject): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    let sortedProjects = objectToArray(projects).sort(dynamicSort('id_number', 'asc'))
    let spreadsheetData: string[][] = []
    let row: string[] = []
    for (let loopColumnIndex in projectExportSpreadsheetColumns) {
      let loopColumn = projectExportSpreadsheetColumns[loopColumnIndex]
      row.push(loopColumn['label'])
    }
    spreadsheetData.push(row)
    for (let loopProjectIndex in sortedProjects) {
      row = []
      let loopProject = sortedProjects[loopProjectIndex]
      for (let loopColumnIndex in projectExportSpreadsheetColumns) {
        let loopColumn = projectExportSpreadsheetColumns[loopColumnIndex]
        let loopColumnValue = getProp(loopProject, loopColumn['key'], '')
        if (loopColumn['valueDetermination'] != null) {
          loopColumnValue = loopColumn['valueDetermination'](loopProject)
        }
        row.push(loopColumnValue)
      }
      spreadsheetData.push(row)
    }
    downloadXLSX('projects', spreadsheetData).finally(() => {
      resolve({ success: true })
    })
  })
}

const exportProjectJson = (projects: TsInterface_UnspecifiedObject): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    // Process data to convert Date objects to Unix timestamps
    const processedData = JSON.parse(
      JSON.stringify(projects, (key, value) => {
        // Check if the value is a Firebase Timestamp or Date object
        if (
          value &&
          typeof value === 'object' &&
          (value.constructor.name === 'Timestamp' || value instanceof Date || (value.seconds !== undefined && value.nanoseconds !== undefined))
        ) {
          // Convert to Unix timestamp (milliseconds)
          if (value instanceof Date) {
            return value.getTime()
          } else if (value.toDate) {
            // Firebase Timestamp objects have toDate() method
            return value.toDate().getTime()
          } else if (value.seconds !== undefined) {
            // Raw timestamp object with seconds and nanoseconds
            return value.seconds * 1000 + Math.floor(value.nanoseconds / 1000000)
          }
        }
        return value
      }),
    )

    // Create JSON file from processed data
    const jsonData = JSON.stringify(processedData, null, 2)

    // Create a blob and download link
    const blob = new Blob([jsonData], { type: 'application/json' })
    const url = URL.createObjectURL(blob)

    // Create download link and trigger download
    const a = document.createElement('a')
    a.href = url
    a.download = 'projects.json'
    document.body.appendChild(a)
    a.click()

    // Clean up
    setTimeout(() => {
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
      resolve({ success: true })
    }, 0)
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_snoozeFilter, us_setSnoozeFilter] = useState<'all' | 'snoozed' | 'active'>('all')
  const [us_projectDownloadStatus, us_setProjectDownloadStatus] = useState<'active' | 'on_hold' | 'completed' | 'cancelled'>('active')
  const [us_selectedTaskWorkflowKey, us_setSelectedTaskWorkflowKey] = useState<string>('')
  const [us_filtersChanged, us_setFiltersChanged] = useState<boolean>(true)
  const [us_loadingProjects, us_setLoadingProjects] = useState<boolean>(false)
  const [us_searchProjectData, us_setSearchProjectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedFilterTaskWorkflowKey, us_setSelectedFilterTaskWorkflowKey] = useState<string>('ALL')
  const [us_selectedFilterSalesPartnerKey, us_setSelectedFilterSalesPartnerKey] = useState<string>('ALL')
  const [us_selectedFilterRegionKey, us_setSelectedFilterRegionKey] = useState<string>('ALL')
  const [us_taskWorkflowsArray, us_setTaskWorkflowsArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflowsObject, us_setTaskWorkflowsObject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartnersArray, us_setSalesPartnersArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_regionsArray, us_setRegionsArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_filteredProjectPacingTags, us_setFilteredProjectPacingTags] = useState<string[]>([])
  const [us_pacingTagFilterAnchorEl, us_setPacingTagFilterAnchorEl] = useState<null | HTMLElement>(null)
  const [us_visibleProjectPacingTags, us_setVisibleProjectPacingTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeProjects, us_setActiveProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflow, us_setTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_RootData_UserPermissions } = useContext(Context_RootData_UserPermissions)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Project Pipeline', false) as string
  }, [])

  useEffect(() => {
    // Generate List of Pacing Tags
    let pacingTags: TsInterface_UnspecifiedObject = {}
    for (let loopProjectKey in us_activeProjects) {
      let loopProject = us_activeProjects[loopProjectKey]
      let loopPacingTags = getProp(loopProject, 'pacing_tags', {})
      for (let loopPacingTagKey in loopPacingTags) {
        let loopPacingTag = loopPacingTags[loopPacingTagKey]
        if (loopPacingTag != null && loopPacingTag.text != null && loopPacingTag.text !== '' && loopPacingTag.color != null && loopPacingTag.color !== '') {
          let labelKey = loopPacingTag.text + '-' + loopPacingTag.color
          pacingTags[labelKey] = loopPacingTag
        }
      }
    }
    us_setVisibleProjectPacingTags(pacingTags)
    return () => {}
  }, [us_activeProjects])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveProjects(newData)
      ur_forceRerender()
    }
    if (
      us_selectedTaskWorkflowKey != null &&
      us_selectedTaskWorkflowKey !== '' &&
      us_taskWorkflow != null &&
      us_taskWorkflow['associated_sales_partner_key'] != null &&
      us_taskWorkflow['associated_sales_partner_key'] != ''
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ActiveProjectsForSpecificWorkflowAndSalesPartner_Query(
              actualClientKey,
              us_selectedTaskWorkflowKey as string,
              us_taskWorkflow['associated_sales_partner_key'] as string,
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedTaskWorkflowKey, us_taskWorkflow])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskWorkflow(newData)
      ur_forceRerender()
    }
    if (us_selectedTaskWorkflowKey != null && us_selectedTaskWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_TaskWorkflow_Document(actualClientKey, us_selectedTaskWorkflowKey as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [us_selectedTaskWorkflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let pageLocalStorageData = getPageLocalStorage(pageKey)
    if (getProp(pageLocalStorageData, 'selected_task_workflow_key', null) != null) {
      us_setSelectedTaskWorkflowKey(getProp(pageLocalStorageData, 'selected_task_workflow_key', null))
    }
    if (getProp(pageLocalStorageData, 'project_download_status', null) != null) {
      us_setProjectDownloadStatus(getProp(pageLocalStorageData, 'project_download_status', null))
    }
    if (getProp(pageLocalStorageData, 'filter_task_workflow_key', null) != null) {
      us_setSelectedFilterTaskWorkflowKey(getProp(pageLocalStorageData, 'filter_task_workflow_key', null))
    }
    if (getProp(pageLocalStorageData, 'filter_sales_partner_key', null) != null) {
      us_setSelectedFilterSalesPartnerKey(getProp(pageLocalStorageData, 'filter_sales_partner_key', null))
    }
    if (getProp(pageLocalStorageData, 'filter_region_key', null) != null) {
      us_setSelectedFilterRegionKey(getProp(pageLocalStorageData, 'filter_region_key', null))
    }
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            us_setTaskWorkflowsObject(res_DGC.data)
            let workflows: TsInterface_UnspecifiedObject[] = []
            for (let workflowKey in res_DGC.data) {
              let loopWorkflow = res_DGC.data[workflowKey]
              workflows.push({ key: loopWorkflow.key, value: loopWorkflow.name })
            }
            us_setTaskWorkflowsArray(workflows)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey))
          .then((res_DGC) => {
            let salesPartners: TsInterface_UnspecifiedObject[] = []
            for (let salesPartnerKey in res_DGC.data) {
              let loopSalesPartner = res_DGC.data[salesPartnerKey]
              salesPartners.push({ key: loopSalesPartner.key, value: loopSalesPartner.name })
            }
            us_setSalesPartnersArray(salesPartners)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey))
          .then((res_DGC) => {
            let regions: TsInterface_UnspecifiedObject[] = []
            for (let regionKey in res_DGC.data) {
              let loopRegion = res_DGC.data[regionKey]
              regions.push({ key: loopRegion.key, value: loopRegion.name })
            }
            us_setRegionsArray(regions)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const loadProjects = () => {
    us_setFiltersChanged(true)
    us_setLoadingProjects(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
        queryOperatorsArray.push({ prop: 'status', comparator: '==', value: us_projectDownloadStatus })
        if (us_selectedFilterTaskWorkflowKey != null && us_selectedFilterTaskWorkflowKey !== 'ALL') {
          queryOperatorsArray.push({ prop: 'associated_task_workflow_key', comparator: '==', value: us_selectedFilterTaskWorkflowKey })
        }
        if (us_selectedFilterSalesPartnerKey != null && us_selectedFilterSalesPartnerKey !== 'ALL') {
          queryOperatorsArray.push({ prop: 'associated_sales_partner_key', comparator: '==', value: us_selectedFilterSalesPartnerKey })
        }
        if (us_selectedFilterRegionKey != null && us_selectedFilterRegionKey !== 'ALL') {
          queryOperatorsArray.push({ prop: 'associated_region_key', comparator: '==', value: us_selectedFilterRegionKey })
        }

        let queryRef = generateDatabaseQuery(DatabaseRef_Projects_Collection(res_GCK.clientKey), queryOperatorsArray, [], {}, null)
        DatabaseGetCollection(queryRef).then((res_DB) => {
          us_setLoadingProjects(false)
          us_setFiltersChanged(false)
          us_setSearchProjectData(res_DB.data)
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const downloadProjects = (format: 'xlsx' | 'json') => {
    if (format === 'xlsx') {
      exportProjectCsv(us_searchProjectData).finally(() => {})
    } else if (format === 'json') {
      exportProjectJson(us_searchProjectData).finally(() => {})
    }
  }

  // JSX Generation
  const rJSX_WorkflowSelection = (): JSX.Element => {
    return (
      <FormControl className="bp_thin_select_input">
        <Select
          color="primary"
          value={us_selectedTaskWorkflowKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedTaskWorkflowKey(event.target.value)
              us_setFilteredProjectPacingTags([])
              setPageLocalStorage(pageKey, 'selected_task_workflow_key', event.target.value)
            }
          }}
          variant="outlined"
        >
          {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_SnoozeFilterButtons = (): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-mr-2 tw-ml-2">
        <IconMenu
          icon={
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Icon icon="filter" />}
            >
              {us_snoozeFilter}
            </Button>
          }
          menuItems={[
            {
              icon: 'alarm-clock',
              text: rLIB('All Projects'),
              onClick: () => {
                us_setSnoozeFilter('all')
              },
            },
            {
              icon: 'alarm-exclamation',
              text: rLIB('Active Projects'),
              onClick: () => {
                us_setSnoozeFilter('active')
              },
            },
            {
              icon: 'alarm-snooze',
              text: rLIB('Snoozed Projects'),
              onClick: () => {
                us_setSnoozeFilter('snoozed')
              },
            },
          ]}
        />
      </Box>
    )
  }

  const rJSX_ProjectDownloadFilterButtons = (): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-mr-2 tw-ml-2">
        <IconMenu
          icon={
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Icon icon="filter" />}
            >
              {projectStatuses[us_projectDownloadStatus].text}
            </Button>
          }
          menuItems={[
            {
              icon: projectStatuses['active'].icon,
              text: projectStatuses['active'].text,
              onClick: () => {
                us_setProjectDownloadStatus('active')
                us_setFiltersChanged(true)
                setPageLocalStorage(pageKey, 'project_download_status', 'active')
              },
            },
            {
              icon: projectStatuses['on_hold'].icon,
              text: projectStatuses['on_hold'].text,
              onClick: () => {
                us_setProjectDownloadStatus('on_hold')
                us_setFiltersChanged(true)
                setPageLocalStorage(pageKey, 'project_download_status', 'on_hold')
              },
            },
            {
              icon: projectStatuses['completed'].icon,
              text: projectStatuses['completed'].text,
              onClick: () => {
                us_setProjectDownloadStatus('completed')
                us_setFiltersChanged(true)
                setPageLocalStorage(pageKey, 'project_download_status', 'completed')
              },
            },
            {
              icon: projectStatuses['cancelled'].icon,
              text: projectStatuses['cancelled'].text,
              onClick: () => {
                us_setProjectDownloadStatus('cancelled')
                us_setFiltersChanged(true)
                setPageLocalStorage(pageKey, 'project_download_status', 'cancelled')
              },
            },
          ]}
        />
      </Box>
    )
  }

  const rJSX_ProjectExportWorkflowFilter = (): JSX.Element => {
    return (
      <FormControl className="tw-mr-2 bp_thin_select_input">
        <Select
          color="primary"
          value={us_selectedFilterTaskWorkflowKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedFilterTaskWorkflowKey(event.target.value)
              us_setFiltersChanged(true)
              setPageLocalStorage(pageKey, 'filter_task_workflow_key', event.target.value)
            }
          }}
          variant="outlined"
        >
          <MenuItem value={'ALL'}>{rLIB('All Workflows')}</MenuItem>
          {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_ProjectExportSalesPartnerFilter = (): JSX.Element => {
    return (
      <FormControl className="tw-mr-2 bp_thin_select_input">
        <Select
          color="primary"
          value={us_selectedFilterSalesPartnerKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedFilterSalesPartnerKey(event.target.value)
              us_setFiltersChanged(true)
              setPageLocalStorage(pageKey, 'filter_sales_partner_key', event.target.value)
            }
          }}
          variant="outlined"
        >
          <MenuItem value={'ALL'}>{rLIB('All Sales Partners')}</MenuItem>
          {us_salesPartnersArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_ProjectExportRegionFilter = (): JSX.Element => {
    return (
      <FormControl className="tw-mr-2 bp_thin_select_input">
        <Select
          color="primary"
          value={us_selectedFilterRegionKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedFilterRegionKey(event.target.value)
              us_setFiltersChanged(true)
              setPageLocalStorage(pageKey, 'filter_region_key', event.target.value)
            }
          }}
          variant="outlined"
        >
          <MenuItem value={'ALL'}>{rLIB('All Regions')}</MenuItem>
          {us_regionsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={option['key']}
              disabled={option['disabled'] === true}
            >
              {option['value']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_ActualDownloadButton = (): JSX.Element => {
    if (objectToArray(us_searchProjectData).length > 0 && us_filtersChanged === false) {
      return (
        <Box className="tw-inline-block tw-mr-2">
          <IconMenu
            icon={
              <Button
                variant="contained"
                color="info"
                startIcon={<Icon icon="cloud-arrow-down" />}
              >
                {rLIB('Download Projects')}
              </Button>
            }
            menuItems={[
              {
                icon: 'file-spreadsheet',
                text: rLIB('Download XLSX'),
                onClick: () => {
                  downloadProjects('xlsx')
                },
              },
              {
                icon: 'file-code',
                text: rLIB('Download JSON'),
                onClick: () => {
                  downloadProjects('json')
                },
              },
            ]}
          />
        </Box>
      )
    } else {
      return <></>
    }
  }

  const rJSX_ProjectLoadButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        color="info"
        className="tw-mr-2"
        onClick={() => {
          loadProjects()
        }}
        disabled={us_loadingProjects === true || us_filtersChanged === false}
        startIcon={
          us_loadingProjects ? (
            <Icon
              icon="spinner"
              className="bp_spin"
            />
          ) : (
            <Icon icon="magnifying-glass" />
          )
        }
      >
        {rLIB('Load Projects')}
      </Button>
    )
  }

  const rJSX_GlobalProjectSearchResult = (
    option: TsInterface_UnspecifiedObject,
    searchInputValue: string | null,
    inputHooks: TsInterface_InputHooksObject,
    additionalSearchData: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let searchResultJSX = (
      <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
        <Typography>
          {rLIB('Job Code')}: {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}{' '}
        </Typography>
        <Typography>
          {rLIB('Customer')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_customer_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('Sales Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_sales_rep_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('CSS Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_css_rep_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('Status')}: {rJSX_HighlightedSearchString(searchInputValue, option.status)}{' '}
        </Typography>
        <Typography>
          {rLIB('Address')}: {rJSX_HighlightedSearchString(searchInputValue, option.location_address)}{' '}
        </Typography>
        <Button
          tabIndex={0}
          color={'info'}
          onKeyDown={(event) => {
            if (event.key === 'ArrowDown') {
              event.preventDefault() // Prevent default arrow down behavior
              const activeElement = document.activeElement

              console.log(activeElement)

              let currentIndex = 0
              if (activeElement === document.getElementById('search_input')) {
                console.log('HIGHLGIHTING SEARCH 1')
                currentIndex = -1
              } else {
                console.log('HIGHLGIHTING OPTION 1')
                try {
                  currentIndex = inputHooks.us_searchOptions.findIndex((option: any) => inputHooks.optionRefs.current[option.id].current === activeElement)
                } catch (err) {
                  console.error(err)
                }
              }

              console.log(currentIndex)

              const nextIndex = currentIndex + 1 < inputHooks.us_searchOptions.length ? currentIndex + 1 : 0

              console.log(nextIndex)

              // refs.current[us_searchOptions[nextIndex].id].current.focus()
            }
          }}
          onClick={(event) => {
            if (option.id != null) {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              // TODO - handle other user types probably
              onClickAppNavigation(event, inputHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(option.id as string))
            }
          }}
          variant="contained"
          sx={{ marginBottom: '5px' }}
        >
          <Icon icon="magnifying-glass" />
          {rLIB('View Project')}
        </Button>
        <Divider />
      </Box>
    )
    return searchResultJSX
  }

  const rJSX_SearchProjectsButton = (): JSX.Element => {
    return (
      <Button
        variant="outlined"
        color="info"
        startIcon={<Icon icon="magnifying-glass" />}
        onClick={() => {
          if (getProp(uc_RootData_UserPermissions, 'AdminActiveProjectsListPage', false) === true) {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <Dialog
                        className="etw_region_selection_dialog"
                        keepMounted
                        onClose={() => {
                          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                        }}
                        open={true}
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            component="div"
                            className="tw-font-bold tw-mb-2"
                          >
                            {rLIB('Search Projects')}
                          </Typography>
                          <SearchInput
                            clientKey={res_GCK.clientKey}
                            searchIndexKey={'projects'}
                            searchFilters={[]}
                            searchResultRenderer={rJSX_GlobalProjectSearchResult}
                            additionalSearchData={{}}
                            sx={{
                              width: '400px',
                              background: themeVariables.background_json,
                            }}
                          />
                          <Box sx={{ height: '30vh' }}></Box>
                        </Box>
                      </Dialog>
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
                // Set Focus
                setTimeout(() => {
                  console.log(document.getElementById('search_input'))
                  document.getElementById('search_input')?.focus()
                }, 100)
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }
        }}
      >
        {rLIB('Search Projects')}
      </Button>
    )
  }

  const rJSX_Tabs = (): JSX.Element => {
    let pageTabs: TsInterface_TabContentUrlArray = [
      {
        tabUrlKey: 'Project_Phases',
        tabHeader: rLIB('Pacing'),
        tabContent: (
          <PacingProjectPhasesTab
            selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
            taskWorkflows={us_taskWorkflowsObject}
            readOrWrite="write"
            projectViewPageKey={'AdminActiveProjectViewPage'}
            snoozeFilter={us_snoozeFilter}
            filteredProjectPacingTags={us_filteredProjectPacingTags}
          />
        ),
        tabButtons: [
          { fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 },
          { fullJSX: rJSX_SnoozeFilterButtons(), minJSX: rJSX_SnoozeFilterButtons(), sizeCutoff: 0 },
          { fullJSX: rJSX_PacingTagFilterMultiSelect(), minJSX: rJSX_PacingTagFilterMultiSelect(), sizeCutoff: 0 },
        ],
      },
      {
        tabUrlKey: 'On_Hold',
        tabHeader: rLIB('On Hold Projects '),
        tabContent: (
          <TaskOnHoldTab
            selectedTaskWorkflowKey={us_selectedTaskWorkflowKey}
            taskWorkflows={us_taskWorkflowsObject}
            readOrWrite="write"
            projectViewPageKey={'AdminActiveProjectViewPage'}
          />
        ),
        tabButtons: [{ fullJSX: rJSX_WorkflowSelection(), minJSX: rJSX_WorkflowSelection(), sizeCutoff: 0 }],
      },
      {
        tabUrlKey: 'Project_Search',
        tabHeader: rLIB('Project Search'),
        tabContent: <ProjectListProjectSearchTab />,
        tabButtons: [{ fullJSX: rJSX_SearchProjectsButton(), minJSX: rJSX_SearchProjectsButton(), sizeCutoff: 0 }],
      },
      {
        tabUrlKey: 'Exports',
        tabHeader: rLIB('Exports'),
        tabContent: (
          <ProjectExportsTab
            projectData={us_searchProjectData}
            filtersChanged={us_filtersChanged}
          />
        ),
        tabButtons: [
          { fullJSX: rJSX_ProjectDownloadFilterButtons(), minJSX: rJSX_ProjectDownloadFilterButtons(), sizeCutoff: 0 },
          { fullJSX: rJSX_ProjectExportWorkflowFilter(), minJSX: rJSX_ProjectExportWorkflowFilter(), sizeCutoff: 0 },
          { fullJSX: rJSX_ProjectExportSalesPartnerFilter(), minJSX: rJSX_ProjectExportSalesPartnerFilter(), sizeCutoff: 0 },
          { fullJSX: rJSX_ProjectExportRegionFilter(), minJSX: rJSX_ProjectExportRegionFilter(), sizeCutoff: 0 },
          { fullJSX: rJSX_ProjectLoadButton(), minJSX: rJSX_ProjectLoadButton(), sizeCutoff: 0 },
          { fullJSX: rJSX_ActualDownloadButton(), minJSX: rJSX_ActualDownloadButton(), sizeCutoff: 0 },
        ],
      },
    ]
    let tabsJSX = <></>
    if (us_taskWorkflowsArray.length > 0) {
      tabsJSX = (
        <TabsUrl
          tabs={pageTabs}
          tabsSettings={{
            tabQueryParam: 'tab',
            baseUrl: ApplicationPages['AdminProjectPacingPage'].url(),
            overridePageTitle: true,
            basePageTitle: rLIB('Project Pipeline', false) as string,
          }}
        />
      )
    }
    return tabsJSX
  }

  const rJSX_PacingTagFilterMultiSelect = (): JSX.Element => {
    const open = Boolean(us_pacingTagFilterAnchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      us_setPacingTagFilterAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      us_setPacingTagFilterAnchorEl(null)
    }

    const handleTagToggle = (tagKey: string) => {
      const currentIndex = us_filteredProjectPacingTags.indexOf(tagKey)
      const newChecked = [...us_filteredProjectPacingTags]

      if (currentIndex === -1) {
        newChecked.push(tagKey)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      us_setFilteredProjectPacingTags(newChecked)
    }

    return (
      <Box className="tw-inline-block tw-mr-2">
        <Button
          onClick={handleClick}
          startIcon={<Icon icon="tag" />}
          endIcon={<Icon icon={open ? 'chevron-up' : 'chevron-down'} />}
          variant="outlined"
          color="secondary"
        >
          {rLIB('Tags')} ({us_filteredProjectPacingTags.length})
        </Button>
        <Menu
          anchorEl={us_pacingTagFilterAnchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 300,
              minWidth: 200,
            },
          }}
        >
          <MenuItem dense>
            <FormControlLabel
              control={
                <Checkbox
                  checked={us_filteredProjectPacingTags.length === objectToArray(us_visibleProjectPacingTags).length}
                  indeterminate={
                    us_filteredProjectPacingTags.length > 0 && us_filteredProjectPacingTags.length < objectToArray(us_visibleProjectPacingTags).length
                  }
                  onChange={() => {
                    if (us_filteredProjectPacingTags.length === objectToArray(us_visibleProjectPacingTags).length) {
                      us_setFilteredProjectPacingTags([])
                    } else {
                      us_setFilteredProjectPacingTags(Object.keys(us_visibleProjectPacingTags))
                    }
                  }}
                />
              }
              label={rLIB('Select All')}
            />
          </MenuItem>
          <Divider />
          {objectToArray(us_visibleProjectPacingTags)
            .sort(dynamicSort('text', 'asc'))
            .map((tag: TsInterface_UnspecifiedObject) => {
              const tagKey = tag.text + '-' + tag.color
              return (
                <MenuItem
                  key={tagKey}
                  dense
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={us_filteredProjectPacingTags.includes(tagKey)}
                        onChange={() => handleTagToggle(tagKey)}
                      />
                    }
                    label={
                      <Box
                        className="tw-inline-block tw-px-2 tw-py-1"
                        sx={{ backgroundColor: tag.color, borderRadius: '6px', color: themeVariables.white, paddingX: '8px', paddingY: '4px' }}
                      >
                        <Icon
                          icon="tag"
                          className="tw-mr-2"
                          sx={{ marginRight: '4px' }}
                        />
                        {tag.text}
                      </Box>
                    }
                  />
                </MenuItem>
              )
            })}
        </Menu>
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Project Pipeline')}
        pageKey={pageKey}
        content={<Box>{rJSX_Tabs()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
