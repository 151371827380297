///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Chip, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { dynamicSort, generateRandomString, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Options
export const comparatorOptions = {
  '>': {
    key: '>',
    value: rLIB('Greater than'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '>=': {
    key: '>=',
    value: rLIB('Greater than or equal to'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '<': {
    key: '<',
    value: rLIB('Less than'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '<=': {
    key: '<=',
    value: rLIB('Less than or equal to'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '==': {
    key: '==',
    value: rLIB('Equal to'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '!=': {
    key: '!=',
    value: rLIB('Not equal to'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '==null': {
    key: '==null',
    value: rLIB('Is Null'),
    types: { number: true, string: true, boolean: true, timestamp: true },
  },
  '!=null': {
    key: '!=null',
    value: rLIB('Is Not Null'),
    types: { number: true, string: true, boolean: true, timestamp: true },
  },
  '==true': {
    key: '==true',
    value: rLIB('Is True'),
    types: { number: false, string: false, boolean: true, timestamp: false },
  },
  '!=true': {
    key: '!=true',
    value: rLIB('Is Not True'),
    types: { number: false, string: false, boolean: true, timestamp: false },
  },
  '==false': {
    key: '==false',
    value: rLIB('Is False'),
    types: { number: false, string: false, boolean: true, timestamp: false },
  },
  '!=false': {
    key: '!=false',
    value: rLIB('Is Not False'),
    types: { number: false, string: false, boolean: true, timestamp: false },
  },
  'contains_substring_case_insensitive': {
    key: 'contains_substring_case_insensitive',
    value: rLIB('Contains Substring (Case Insensitive)'),
    types: { number: false, string: true, boolean: false, timestamp: false },
  },
  'contains_substring_case_sensitive': {
    key: 'contains_substring_case_sensitive',
    value: rLIB('Contains Substring (Case Sensitive)'),
    types: { number: false, string: true, boolean: false, timestamp: false },
  },
  // "within_tolerance_of": {
  // key: "within_tolerance_of",
  // value: s_WITHIN_TOLERANCE_OF,
  // types: { number: true, string: false, boolean: false, timestamp: false }
  // },
}

const mlNumericComparatorOptions = {
  '>': {
    key: '>',
    value: rLIB('Greater than'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '>=': {
    key: '>=',
    value: rLIB('Greater than or equal to'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '<': {
    key: '<',
    value: rLIB('Less than (or missing)'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  '<=': {
    key: '<=',
    value: rLIB('Less than or equal to (or missing)'),
    types: { number: true, string: false, boolean: false, timestamp: true },
  },
  // ">_or_missing": {
  // key: ">_or_missing",
  // value: rLIB("Greater than")_OR_MISSING,
  // types: { number: true, string: false, boolean: false, timestamp: true }
  // },
  // ">=_or_missing": {
  // key: ">=_or_missing",
  // value: rLIB("Greater than or equal to")_OR_MISSING,
  // types: { number: true, string: false, boolean: false, timestamp: true }
  // },
  // "<_or_missing": {
  // key: "<_or_missing",
  // value: rLIB("Less than (or missing)"),
  // types: { number: true, string: false, boolean: false, timestamp: true }
  // },
  // "<=_or_missing": {
  // key: "<=_or_missing",
  // value: rLIB("Less than or equal to (or missing)"),
  // types: { number: true, string: false, boolean: false, timestamp: true }
  // },
}

export const operatorOptions = {
  '+': {
    key: '+',
    value: rLIB('Plus'),
  },
  '-': {
    key: '-',
    value: rLIB('Minus'),
  },
  '*': {
    key: '*',
    value: rLIB('Multiplied by'),
  },
  '/': {
    key: '/',
    value: rLIB('Divided by'),
  },
  '%': {
    key: '%',
    value: rLIB('Modulus'),
  },
  'nothing': {
    key: 'nothing',
    value: rLIB('Nothing'),
  },
  'error': {
    key: 'error',
    value: rLIB('Flag as Error'),
  },
}

export const logicViewModeOptions = {
  view: {
    key: 'view',
    value: rLIB('View'),
  },
  edit: {
    key: 'edit',
    value: rLIB('Edit'),
  },
  // "trace": {
  // 	key: "trace",
  // 	value: s_TRACE,
  // },
}

export const severityMessageTypes: TsInterface_UnspecifiedObject = {
  error: {
    severity: 1,
    key: 'error',
    name: rLIB('Error'),
    icon: (
      <Box sx={{ color: themeVariables.error_main, marginRight: '8px', display: 'inline-block' }}>
        <Icon icon="triangle-exclamation" />
      </Box>
    ),
  },
  warning: {
    severity: 2,
    key: 'warning',
    name: rLIB('Warning'),
    icon: (
      <Box sx={{ color: themeVariables.warning_main, marginRight: '8px', display: 'inline-block' }}>
        <Icon icon="bell-on" />
      </Box>
    ),
  },
  info: {
    severity: 3,
    key: 'info',
    name: rLIB('Info'),
    icon: (
      <Box sx={{ color: themeVariables.info_main, marginRight: '8px', display: 'inline-block' }}>
        <Icon icon="square-info" />
      </Box>
    ),
  },
  success: {
    severity: 4,
    key: 'success',
    name: rLIB('Success'),
    icon: (
      <Box sx={{ color: themeVariables.success_main, marginRight: '8px', display: 'inline-block' }}>
        <Icon icon="badge-check" />
      </Box>
    ),
  },
}

// and_single_photo
// or_single_photo

// condition_if_else
// condition_switch
// operation_formula
// operation_run_model
// operation_terminate
// condition_ml_category_if_else
// operation_generate_message

///////////////////////////////
// Functions
///////////////////////////////

// all
const createDynamicNestedObject = (arr: string[], obj: TsInterface_UnspecifiedObject) => {
  let result = {}
  let currentObj: TsInterface_UnspecifiedObject = result
  for (let i = 0; i < arr.length; i++) {
    const prop = arr[i]
    currentObj[prop] = {}
    if (i === arr.length - 1) {
      currentObj[prop] = obj
    } else {
      currentObj = currentObj[prop]
    }
  }
  return result
}

function getValueFromPath(pathArray: string[], object: TsInterface_UnspecifiedObject) {
  let value = object
  for (const part of pathArray) {
    if (value && typeof value === 'object' && part in value) {
      value = value[part]
    } else {
      return undefined
    }
  }
  return value
}

// condition_if_else
const addIfElseConditionStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'condition_if_else'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.condition_ifs = [
      {
        condition_type: 'and',
        conditions: [
          {
            variable_1: null,
            variable_2: null,
            comparator: null,
          },
        ],
        logic_step_type: null,
      },
    ]
    logicItem.condition_else = {
      logic_step_type: null,
    }
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteIfElseConditionStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    logicItem.condition_ifs = []
    logicItem.condition_else = {}
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeIfConditionType = (
  conditionalItem: TsInterface_UnspecifiedObject,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.condition_type = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeConditionVariable = (
  conditionLine: TsInterface_UnspecifiedObject,
  variableKey: string,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionLine[variableKey] = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const addElseIf = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_ifs.push({
      condition_type: 'and',
      conditions: [
        {
          variable_1: null,
          variable_2: null,
          comparator: null,
        },
      ],
      logic_step_type: null,
    })
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteElseIf = (
  logicItem: TsInterface_UnspecifiedObject,
  elseIfIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_ifs.splice(elseIfIndex, 1)
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const addConditionLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.conditions.push({
      variable_1: null,
      variable_2: null,
      comparator: null,
    })
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteConditionLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionalLineIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.conditions.splice(conditionalLineIndex, 1)
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

// condition_switch
const addSwitchConditionStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'condition_switch'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.condition_cases = [
      {
        logic_step_type: null,
        case_variable: null,
      },
    ]
    logicItem.condition_default = {
      logic_step_type: null,
    }
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteSwitchConditionStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    logicItem.condition_cases = null
    logicItem.condition_default = null
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeSwitchVariable = (
  logicItem: TsInterface_UnspecifiedObject,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.switch_variable = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeSwitchCaseVariable = (
  switchCase: TsInterface_UnspecifiedObject,
  variableKey: string,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    switchCase[variableKey] = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const addSwitchCase = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_cases.push({
      case_variable: null,
      logic_step_type: null,
    })
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteSwitchCase = (
  logicItem: TsInterface_UnspecifiedObject,
  caseIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_cases.splice(caseIndex, 1)
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

// operation_formula
const addOperationFormulaStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'operation_formula'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.operation_steps = [
      {
        operation_type: null,
        variable_1: null,
        variable_2: null,
      },
    ]
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteOperationFormulaStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    logicItem.operation_steps = null
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const addCalculationStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.operation_steps.push({
      operation_type: null,
      variable_1: null,
      variable_2: null,
    })
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteCalculationStep = (
  logicItem: TsInterface_UnspecifiedObject,
  stepIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.operation_steps.splice(stepIndex, 1)
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeCalculationStepVariable = (
  calculationStep: TsInterface_UnspecifiedObject,
  variableKey: string,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    calculationStep[variableKey] = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

// operation_run_model
const addOperationRunModelStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'operation_run_model'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.model = null
    logicItem.followup_step = {
      logic_step_type: null,
    }
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeModelVariable = (
  logicItem: TsInterface_UnspecifiedObject,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.model = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteOperationRunModelStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    // logicItem.model = null
    logicItem.followup_step = null
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

// operation_terminate
const addOperationTerminateStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'operation_terminate'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteOperationTerminateStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

// condition_ml_category_if_else
const addOperationIfElseMlCategoriesStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'condition_ml_category_if_else'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.condition_ifs = [
      {
        condition_type: 'and_single_photo',
        conditions: [
          {
            model: null,
            category: null,
            quantity: null,
            confidence: null,
          },
        ],
        logic_step_type: null,
      },
    ]
    logicItem.condition_else = {
      logic_step_type: null,
    }
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteOperationIfElseMlCategoriesStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeIfElseMlCategoriesConditionType = (
  conditionalItem: TsInterface_UnspecifiedObject,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.condition_type = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeIfElseMlCategoriesConditionVariable = (
  conditionLine: TsInterface_UnspecifiedObject,
  variableKey: string,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionLine[variableKey] = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const addIfElseMlCategoriesElseIf = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_ifs.push({
      condition_type: 'and_single_photo',
      conditions: [
        {
          model: null,
          category: null,
          quantity: null,
          confidence: null,
        },
      ],
      logic_step_type: null,
    })
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteIfElseMlCategoriesElseIf = (
  logicItem: TsInterface_UnspecifiedObject,
  elseIfIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.condition_ifs.splice(elseIfIndex, 1)
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const addIfElseMlCategoriesConditionLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.conditions.push({
      model: null,
      category: null,
      quantity: null,
      confidence: null,
    })
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteIfElseMlCategoriesConditionLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionalLineIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    conditionalItem.conditions.splice(conditionalLineIndex, 1)
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

// operation_generate_message
const addOperationGenerateMessageStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = 'operation_generate_message'
    logicItem.logic_trace_key = new Date().getTime() + '_' + generateRandomString(6, null)
    logicItem.message = null
    logicItem.message_type = null
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeMessageType = (
  logicItem: TsInterface_UnspecifiedObject,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.message_type = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const changeMessage = (
  logicItem: TsInterface_UnspecifiedObject,
  newValue: string,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.message = newValue
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

const deleteOperationGenerateMessageStep = (
  logicItem: TsInterface_UnspecifiedObject,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    logicItem.logic_step_type = null
    logicItem.message = null
    logicItem.message_type = null
    // @ts-expect-error - TODO: reason for error
    saveFormulaChangeToDatabase(createDynamicNestedObject(nestedLogicPropPathArray, getValueFromPath(nestedLogicPropPathArray, calculationData)))
      .then((res_SFCTD: unknown) => {
        resolve(res_SFCTD)
      })
      .catch((rej_SFCTD: unknown) => {
        reject(rej_SFCTD)
      })
  })
}

///////////////////////////////////////////
// JSX Render
///////////////////////////////////////////

// condition_if_else
const rJSX_IfConditionalContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let containerJSX = <></>
  let addButtonJSX = (
    <Button
      sx={{ marginRight: '8px' }}
      variant="outlined"
      color="info"
      className="tw-opacity-30 hover:tw-opacity-100"
      onClick={() => {
        addElseIf(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {rLIB('Add Else If')}
    </Button>
  )
  if (logicViewMode !== 'edit') {
    addButtonJSX = <></>
  }
  let chipColor: TsType_MuiComponentColors = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem['condition_else'] != null &&
    logicItem['condition_else'].logic_trace_key != null
  ) {
    // @ts-expect-error - TODO: reason for error
    chipColor = 'default'
    if (traceData.calculationPath.pathDebug[logicItem['condition_else'].logic_trace_key] === true) {
      chipColor = 'success'
    } else if (traceData.calculationPath.pathDebug[logicItem['condition_else'].logic_trace_key] === false) {
      chipColor = 'error'
    }
  }
  containerJSX = (
    <Box>
      {logicItem.condition_ifs.map((conditionalItem: TsInterface_UnspecifiedObject, conditionIndex: number) => (
        <Box key={conditionIndex}>
          {rJSX_IfConditional(
            logicItem,
            conditionalItem,
            conditionIndex,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      ))}
      <Box>
        {addButtonJSX}
        <Box
          className=""
          sx={{ height: '16px', borderLeft: '3px solid #ddd', marginLeft: '16px' }}
        ></Box>
      </Box>
      <Box className="">
        <Chip
          color={chipColor}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Else')}
            </Typography>
          }
        />
      </Box>
      <Box sx={{ marginLeft: '16px', marginBottom: '8px' }}>
        <Box
          className="tw-inline-block"
          sx={{ width: '32px', display: 'inline-block' }}
        >
          <Box
            className=""
            sx={{ height: '24px', width: '100%', borderLeft: '3px solid #ddd' }}
          ></Box>
          <Box
            className=""
            sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
          ></Box>
        </Box>
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
        >
          {rJSX_LogicPathRender(
            logicItem.condition_else,
            logicViewMode,
            traceData,
            currentLevelIndex + 1,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      </Box>
    </Box>
  )
  return containerJSX
}

const rJSX_IfConditional = (
  logicItem: TsInterface_UnspecifiedObject,
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let conditionalJSX = <></>
  let ifLabelJSX = <></>
  let deleteButtonJSX = <></>
  if (conditionIndex === 0) {
    let chipColor1: TsType_MuiComponentColors = 'info'
    if (
      logicViewMode === 'trace' &&
      traceData != null &&
      traceData.calculationPath != null &&
      traceData.calculationPath.pathDebug != null &&
      conditionalItem != null &&
      conditionalItem.logic_trace_key != null
    ) {
      // @ts-expect-error - TODO: reason for error
      chipColor1 = 'default'
      if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === true) {
        chipColor1 = 'success'
      } else if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === false) {
        chipColor1 = 'error'
      }
    }
    ifLabelJSX = (
      <Chip
        color={chipColor1}
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('If')}
          </Typography>
        }
      />
    )
    deleteButtonJSX = (
      <Button
        variant="outlined"
        color="error"
        className="tw-opacity-30 hover:tw-opacity-100"
        sx={{ minWidth: '20px', fontSize: '24px', marginTop: '0px' }}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash-xmark" />,
              header: rLIB('Delete Step'),
              text: (
                <>
                  {rLIB('Are you sure that you want to delete this whole step?')} {rLIB('This will delete everything nested underneath it.')}
                </>
              ),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  deleteIfElseConditionStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                    .then((res_DIECS) => {
                      resolve(res_DIECS)
                    })
                    .catch((rej_DIECS) => {
                      reject(rej_DIECS)
                    })
                })
              },
            },
          })
        }}
      >
        <Icon
          icon="trash-xmark"
          type="solid"
        />
      </Button>
    )
  } else {
    let chipColor2: TsType_MuiComponentColors = 'info'
    if (
      logicViewMode === 'trace' &&
      traceData != null &&
      traceData.calculationPath != null &&
      traceData.calculationPath.pathDebug != null &&
      conditionalItem != null &&
      conditionalItem.logic_trace_key != null
    ) {
      // @ts-expect-error - TODO: reason for error
      chipColor2 = 'default'
      if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === true) {
        chipColor2 = 'success'
      } else if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === false) {
        chipColor2 = 'error'
      }
    }
    ifLabelJSX = (
      <Chip
        color={chipColor2}
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Else If')}
          </Typography>
        }
      />
    )
    deleteButtonJSX = (
      <Button
        variant="outlined"
        color="error"
        className="tw-opacity-30 hover:tw-opacity-100"
        sx={{ minWidth: '20px', fontSize: '24px', marginTop: '4px' }}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash-xmark" />,
              header: rLIB('Delete Else If'),
              text: (
                <>
                  {rLIB('Are you sure that you want to delete this else if?')} {rLIB('This will delete everything nested underneath it.')}
                </>
              ),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  deleteElseIf(logicItem, conditionIndex, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                    .then((res_DEI) => {
                      resolve(res_DEI)
                    })
                    .catch((rej_DEI) => {
                      reject(rej_DEI)
                    })
                })
              },
            },
          })
        }}
      >
        <Icon icon="trash-xmark" />
      </Button>
    )
  }
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  let addButtonJSX = (
    <Button
      sx={{ marginBottom: '8px', marginRight: '8px' }}
      variant="outlined"
      color="success"
      className="tw-opacity-30 hover:tw-opacity-100"
      onClick={() => {
        addConditionLine(conditionalItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {rLIB('Add Condition')}
    </Button>
  )
  if (logicViewMode !== 'edit') {
    addButtonJSX = <></>
  }
  conditionalJSX = (
    <Box>
      <Box className="">
        {ifLabelJSX}
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginLeft: '12px', marginRight: '12px' }}
        >
          <Select
            disabled={logicViewMode !== 'edit'}
            autoWidth={true}
            onChange={(event, value) => {
              changeIfConditionType(conditionalItem, event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
            }}
            value={conditionalItem.condition_type || ''}
          >
            <MenuItem
              key={'and'}
              value={'and'}
            >
              {rLIB('All of the following are true')}
            </MenuItem>
            <MenuItem
              key={'or'}
              value={'or'}
            >
              {rLIB('At least one of the following is true')}
            </MenuItem>
          </Select>
        </FormControl>
        {deleteButtonJSX}
      </Box>
      <Box sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px', paddingLeft: '8px' }}>
        <Box sx={{ paddingTop: '8px' }}>
          {conditionalItem.conditions.map((conditionLine: TsInterface_UnspecifiedObject, conditionLineIndex: number) => (
            <Box key={conditionLineIndex}>
              {rJSX_IfConditionalLine(
                conditionalItem,
                conditionLine,
                conditionLineIndex,
                logicViewMode,
                traceData,
                currentLevelIndex,
                saveFormulaChangeToDatabase,
                uc_setUserInterface_ConfirmDialogDisplay,
                calculationData,
                nestedLogicPropPathArray,
              )}
            </Box>
          ))}
          {addButtonJSX}
        </Box>
      </Box>
      <Box
        className=""
        sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px' }}
      >
        <Box
          className="tw-inline-block"
          sx={{ width: '32px', display: 'inline-block' }}
        >
          <Box
            className=""
            sx={{ height: '16px', width: '100%' }}
          ></Box>
          <Box
            className=""
            sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
          ></Box>
        </Box>
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ width: 'calc(100% - 32px)', display: 'inline-block', verticalAlign: 'top' }}
        >
          {rJSX_LogicPathRender(
            conditionalItem,
            logicViewMode,
            traceData,
            currentLevelIndex + 1,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      </Box>
    </Box>
  )
  return conditionalJSX
}

const rJSX_IfConditionalLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionLine: TsInterface_UnspecifiedObject,
  conditionLineIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  // Instantiate Variables
  let conditionalLineJSX = <></>
  // Trace
  let variableTrace1 = <></>
  let variableTrace2 = <></>
  let variable1Background = 'rgba(0,0,0,0)'
  let conditionBackground = 'rgba(0,0,0,0)'
  let variable2Background = 'rgba(0,0,0,0)'
  if (logicViewMode === 'trace') {
    if (
      conditionLine != null &&
      conditionLine.variable_1 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][conditionLine.variable_1] != null
    ) {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][conditionLine.variable_1]}
        </Box>
      )
    } else {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {rLIB('Missing')}
        </Box>
      )
    }
    if (
      conditionLine != null &&
      conditionLine.variable_2 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][conditionLine.variable_2] != null
    ) {
      variableTrace2 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][conditionLine.variable_2]}
        </Box>
      )
    } else {
      variableTrace2 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {rLIB('Missing')}
        </Box>
      )
    }
  }
  // Input background colors
  if (conditionLine.variable_1 == null || calculationData.variables[conditionLine.variable_1] == null) {
    variable1Background = themeVariables.error_wash
  }
  if (conditionLine.comparator == null) {
    conditionBackground = themeVariables.error_wash
  }
  if (conditionLine.variable_2 == null || calculationData.variables[conditionLine.variable_2] == null) {
    variable2Background = themeVariables.error_wash
  }
  // Input and Button JSX
  let variableOneJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_VARIABLE_1 }</InputLabel> */}
      <Select
        sx={{ background: variable1Background }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_VARIABLE_1 }
        onChange={(event, value) => {
          changeConditionVariable(conditionLine, 'variable_1', event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
        value={conditionLine.variable_1 || ''}
      >
        {objectToArray(calculationData.variables)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['status'] === 'inactive'}
            >
              {option['name']}
              {variableTrace1}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
  let conditionJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_CONDITION }</InputLabel> */}
      <Select
        sx={{ background: conditionBackground }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_CONDITION }
        onChange={(event, value) => {
          changeConditionVariable(conditionLine, 'comparator', event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
        value={conditionLine.comparator || ''}
      >
        {objectToArray(comparatorOptions).map((option: TsInterface_UnspecifiedObject) => (
          <MenuItem
            key={option['key']}
            value={option['key']}
            disabled={option['disabled']}
          >
            {option['value']}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  let variableTwoJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_VARIABLE_2 }</InputLabel> */}
      <Select
        sx={{ background: variable2Background }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_VARIABLE_2 }
        onChange={(event, value) => {
          changeConditionVariable(conditionLine, 'variable_2', event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
        value={conditionLine.variable_2 || ''}
      >
        {objectToArray(calculationData.variables)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['status'] === 'inactive'}
            >
              {option['name']}
              {variableTrace2}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Condition'),
            text: rLIB('Are you sure that you want to delete this condition?'),
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteConditionLine(conditionalItem, conditionLineIndex, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                  .then((res_DCL) => {
                    resolve(res_DCL)
                  })
                  .catch((rej_DCL) => {
                    reject(rej_DCL)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  // Modifications to JSX
  if (
    conditionLine.comparator === '==null' ||
    conditionLine.comparator === '!=null' ||
    conditionLine.comparator === '==true' ||
    conditionLine.comparator === '!=true' ||
    conditionLine.comparator === '==false' ||
    conditionLine.comparator === '!=false'
  ) {
    variableTwoJSX = <></>
  }
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  // Full JSX
  conditionalLineJSX = (
    <Box>
      {variableOneJSX}
      {conditionJSX}
      {variableTwoJSX}
      {deleteButtonJSX}
    </Box>
  )
  return conditionalLineJSX
}

// condition_switch
const rJSX_SwitchConditionalContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let containerJSX = <></>
  // Button Components
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginTop: '0px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Step'),
            text: (
              <>
                {rLIB('Are you sure that you want to delete this whole step?')} {rLIB('This will delete everything nested underneath it.')}
              </>
            ),
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteSwitchConditionStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                  .then((res_DSCS) => {
                    resolve(res_DSCS)
                  })
                  .catch((rej_DSCS) => {
                    reject(rej_DSCS)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  let addButtonJSX = (
    <Button
      sx={{ marginRight: '8px' }}
      variant="outlined"
      className="tw-opacity-30 hover:tw-opacity-100"
      color="info"
      onClick={() => {
        addSwitchCase(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {rLIB('Add Switch Case')}
    </Button>
  )
  // Preview Mode
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
    addButtonJSX = <></>
  }
  // Input background colors
  let switchVariable = 'rgba(0,0,0,0)'
  if (logicItem.switch_variable == null || calculationData.variables[logicItem.switch_variable] == null) {
    switchVariable = themeVariables.error_wash
  }
  let chipColor1: TsType_MuiComponentColors = 'info'
  let chipColor2: TsType_MuiComponentColors = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem.logic_trace_key != null
  ) {
    // @ts-expect-error - TODO: reason for error
    chipColor1 = 'default'
    let usedCaseStatement = false
    for (let loopConditionCaseIndex in logicItem.condition_cases) {
      let loopCondition = logicItem.condition_cases[loopConditionCaseIndex]
      if (loopCondition != null && loopCondition.logic_trace_key != null && traceData.calculationPath.pathDebug[loopCondition.logic_trace_key] === true) {
        usedCaseStatement = true
      }
      if (usedCaseStatement === true) {
        chipColor1 = 'success'
      }
    }
  }
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem['condition_default'] != null &&
    logicItem['condition_default'].logic_trace_key != null
  ) {
    // @ts-expect-error - TODO: reason for error
    chipColor2 = 'default'
    if (traceData.calculationPath.pathDebug[logicItem['condition_default'].logic_trace_key] === true) {
      chipColor2 = 'success'
      chipColor1 = 'error'
    }
  }
  let variableTrace = <></>
  if (logicViewMode === 'trace') {
    if (
      logicItem != null &&
      logicItem.switch_variable != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][logicItem.switch_variable] != null
    ) {
      variableTrace = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][logicItem.switch_variable]}
        </Box>
      )
    } else {
      variableTrace = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {rLIB('Missing')}
        </Box>
      )
    }
  }
  // Full JSX
  containerJSX = (
    <Box>
      <Box>
        <Chip
          color={chipColor1}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Switch')}
            </Typography>
          }
        />
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '170px', marginLeft: '12px', marginRight: '12px' }}
        >
          <Select
            sx={{ background: switchVariable }}
            disabled={logicViewMode !== 'edit'}
            autoWidth={true}
            onChange={(event, value) => {
              changeSwitchVariable(logicItem, event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
            }}
            value={logicItem.switch_variable || ''}
          >
            {objectToArray(calculationData.variables)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                  disabled={option['status'] === 'inactive'}
                >
                  {option['name']}
                  {variableTrace}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {deleteButtonJSX}
      </Box>
      <Box>
        {logicItem.condition_cases.map((switchCase: TsInterface_UnspecifiedObject, switchCaseIndex: number) => (
          <Box key={switchCaseIndex}>
            {rJSX_SwitchConditionalCase(
              logicItem,
              switchCase,
              switchCaseIndex,
              logicViewMode,
              traceData,
              currentLevelIndex,
              saveFormulaChangeToDatabase,
              uc_setUserInterface_ConfirmDialogDisplay,
              calculationData,
              availableLogicSteps,
              nestedLogicPropPathArray,
            )}
          </Box>
        ))}
      </Box>
      <Box>
        {addButtonJSX}
        <Box
          className=""
          sx={{ height: '16px', borderLeft: '3px solid #ddd', marginLeft: '16px' }}
        ></Box>
      </Box>
      <Chip
        color={chipColor2}
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Default')}
          </Typography>
        }
      />
      <Box
        className=""
        sx={{ marginLeft: '16px' }}
      >
        <Box
          className="tw-inline-block"
          sx={{ width: '32px', display: 'inline-block' }}
        >
          <Box
            className=""
            sx={{ height: '24px', width: '100%', borderLeft: '3px solid #ddd' }}
          ></Box>
          <Box
            className=""
            sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
          ></Box>
        </Box>
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
        >
          {rJSX_LogicPathRender(
            logicItem.condition_default,
            logicViewMode,
            traceData,
            currentLevelIndex + 1,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      </Box>
    </Box>
  )
  return containerJSX
}

const rJSX_SwitchConditionalCase = (
  logicItem: TsInterface_UnspecifiedObject,
  switchCase: TsInterface_UnspecifiedObject,
  switchCaseIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let caseJSX = <></>
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginTop: '8px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Switch Case'),
            text: (
              <>
                {rLIB('Are you sure that you want to delete this switch case?')} {rLIB('This will delete everything nested underneath it.')}
              </>
            ),
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteSwitchCase(logicItem, switchCaseIndex, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                  .then((res_DSC) => {
                    resolve(res_DSC)
                  })
                  .catch((rej_DSC) => {
                    reject(rej_DSC)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  // Input background colors
  let caseVariableBackground = 'rgba(0,0,0,0)'
  if (switchCase.case_variable == null || calculationData.variables[switchCase.case_variable] == null) {
    caseVariableBackground = themeVariables.error_wash
  }
  let variableTrace = <></>
  if (logicViewMode === 'trace') {
    if (
      switchCase != null &&
      switchCase.case_variable != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][switchCase.case_variable] != null
    ) {
      variableTrace = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][switchCase.case_variable]}
        </Box>
      )
    } else {
      variableTrace = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {rLIB('Missing')}
        </Box>
      )
    }
  }
  // Full JSX
  caseJSX = (
    <Box
      className=""
      sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px' }}
    >
      <Box
        className="tw-inline-block"
        sx={{ width: '32px', display: 'inline-block' }}
      >
        <Box
          className=""
          sx={{ height: '32px', width: '100%' }}
        ></Box>
        <Box
          className=""
          sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
        ></Box>
      </Box>
      <Box
        className="tw-inline-block tw-align-top"
        sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
      >
        <Box>
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '140px', marginRight: '12px', marginTop: '8px' }}
          >
            {/* <InputLabel>{ s_CASE_VARIABLE }</InputLabel> */}
            <Select
              sx={{ background: caseVariableBackground }}
              disabled={logicViewMode !== 'edit'}
              autoWidth={true}
              // label={ s_CASE_VARIABLE }
              onChange={(event, value) => {
                changeSwitchCaseVariable(
                  switchCase,
                  'case_variable',
                  event.target.value,
                  saveFormulaChangeToDatabase,
                  calculationData,
                  nestedLogicPropPathArray,
                )
              }}
              value={switchCase.case_variable || ''}
            >
              {objectToArray(calculationData.variables)
                .sort(dynamicSort('name', null))
                .map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['status'] === 'inactive'}
                  >
                    {option['name']}
                    {variableTrace}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {deleteButtonJSX}
        </Box>
        <Box
          className=""
          sx={{ marginLeft: '16px' }}
        >
          <Box
            className="tw-inline-block"
            sx={{ width: '32px', display: 'inline-block' }}
          >
            <Box
              className=""
              sx={{ height: '24px', width: '100%', borderLeft: '3px solid #ddd' }}
            ></Box>
            <Box
              className=""
              sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
            ></Box>
          </Box>
          <Box
            className="tw-inline-block tw-align-top "
            sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
          >
            {rJSX_LogicPathRender(
              switchCase,
              logicViewMode,
              traceData,
              currentLevelIndex + 1,
              saveFormulaChangeToDatabase,
              uc_setUserInterface_ConfirmDialogDisplay,
              calculationData,
              availableLogicSteps,
              nestedLogicPropPathArray,
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
  return caseJSX
}

// operation_formula
const rJSX_OperationFormulaContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let operationJSX = <></>
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginLeft: '12px', marginTop: '0px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Step'),
            text: (
              <>
                {rLIB('Are you sure that you want to delete this whole step?')} {rLIB('This will delete everything nested underneath it.')}
              </>
            ),
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteOperationFormulaStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                  .then((res_DOFS) => {
                    resolve(res_DOFS)
                  })
                  .catch((rej_DOFS) => {
                    reject(rej_DOFS)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  let addButtonJSX = (
    <Button
      sx={{ marginRight: '8px' }}
      variant="outlined"
      color="info"
      className="tw-opacity-30 hover:tw-opacity-100"
      onClick={() => {
        addCalculationStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {rLIB('Add Calculation Step')}
    </Button>
  )
  if (logicViewMode !== 'edit') {
    addButtonJSX = <></>
    deleteButtonJSX = <></>
  }
  let chipColor: TsType_MuiComponentColors = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem.logic_trace_key != null
  ) {
    // @ts-expect-error - TODO: reason for error
    chipColor = 'default'
    if (traceData.calculationPath.pathDebug[logicItem.logic_trace_key] === true) {
      chipColor = 'success'
    }
  }
  operationJSX = (
    <Box>
      <Box>
        <Chip
          color={chipColor}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Formula')}
            </Typography>
          }
        />
        {deleteButtonJSX}
      </Box>
      <Box
        className=""
        sx={{ height: '16px', borderLeft: '3px solid #ddd', marginLeft: '16px' }}
      ></Box>
      {logicItem.operation_steps.map((step: TsInterface_UnspecifiedObject, stepIndex: number) => (
        <Box
          className=""
          sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px' }}
          key={stepIndex}
        >
          <Box
            className="tw-inline-block"
            sx={{ width: '32px', display: 'inline-block' }}
          >
            <Box
              className=""
              sx={{ height: '16px', width: '100%' }}
            ></Box>
            <Box
              className=""
              sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
            ></Box>
          </Box>
          <Box
            className="tw-inline-block tw-align-top"
            sx={{ width: 'calc(100% - 32px)', display: 'inline-block', verticalAlign: 'top' }}
          >
            {rJSX_CalculationStep(
              logicItem,
              step,
              stepIndex,
              logicViewMode,
              traceData,
              currentLevelIndex,
              saveFormulaChangeToDatabase,
              uc_setUserInterface_ConfirmDialogDisplay,
              calculationData,
              nestedLogicPropPathArray,
            )}
          </Box>
        </Box>
      ))}
      <Box sx={{ marginBottom: '8px' }}>{addButtonJSX}</Box>
    </Box>
  )
  return operationJSX
}

const rJSX_CalculationStep = (
  calculationItem: TsInterface_UnspecifiedObject,
  calculationStep: TsInterface_UnspecifiedObject,
  calculationStepIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let lineJSX = <></>
  let disabledStepDeleteFunction = false
  if (calculationStepIndex < calculationItem.operation_steps.length - 1) {
    disabledStepDeleteFunction = true
  }
  let previousStepOptions: TsInterface_UnspecifiedObject[] = []
  for (let loopStepIndex = 1; loopStepIndex <= calculationStepIndex; loopStepIndex++) {
    previousStepOptions.push({
      key: 'STEP_' + loopStepIndex,
      name: (
        <>
          {rLIB('Result of Step')} {loopStepIndex}
        </>
      ),
    })
  }
  // Trace
  let variableTrace1 = <></>
  let variableTrace2 = <></>
  let variable1Background = 'rgba(0,0,0,0)'
  let operatorBackground = 'rgba(0,0,0,0)'
  let variable2Background = 'rgba(0,0,0,0)'
  if (logicViewMode === 'trace') {
    if (
      calculationStep != null &&
      calculationStep.variable_1 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][calculationStep.variable_1] != null
    ) {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][calculationStep.variable_1]}
        </Box>
      )
    } else {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {rLIB('Missing')}
        </Box>
      )
    }
    if (
      calculationStep != null &&
      calculationStep.variable_2 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][calculationStep.variable_2] != null
    ) {
      variableTrace2 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][calculationStep.variable_2]}
        </Box>
      )
    } else {
      variableTrace2 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {rLIB('Missing')}
        </Box>
      )
    }
  }
  // Input background colors
  if (calculationStep.variable_1 == null || calculationData.variables[calculationStep.variable_1] == null) {
    variable1Background = themeVariables.error_wash
  }
  if (calculationStep.operation_type == null) {
    operatorBackground = themeVariables.error_wash
  }
  if (calculationStep.variable_2 == null || calculationData.variables[calculationStep.variable_2] == null) {
    variable2Background = themeVariables.error_wash
  }
  // Input aand Button JSX
  let variableOneJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_VARIABLE_1 }</InputLabel> */}
      <Box
        onClick={() => {
          if (logicViewMode === 'trace') {
            // TODO
            // console.log("TRACE")
            // console.log(calculationStep.variable_1)
            // console.log(traceData.other_trace_data) // Pass in useful stuff here?
            // console.log( "<><><><>" )
            // console.log( calculationStep )
            // console.log( traceData )
            // console.log( calculationData )
            // TODO - show dialog? can't use custom? maybe tooltip...
          }
        }}
      >
        <Select
          sx={{ background: variable1Background }}
          disabled={logicViewMode !== 'edit'}
          autoWidth={true}
          // label={ s_VARIABLE_1 }
          onChange={(event, value) => {
            changeCalculationStepVariable(
              calculationStep,
              'variable_1',
              event.target.value,
              saveFormulaChangeToDatabase,
              calculationData,
              nestedLogicPropPathArray,
            )
          }}
          value={calculationStep.variable_1 || ''}
        >
          {previousStepOptions.map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['disabled']}
            >
              {option['name']}
            </MenuItem>
          ))}
          {objectToArray(calculationData.variables)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
                disabled={option['status'] === 'inactive'}
              >
                {option['name']}
                {variableTrace1}
              </MenuItem>
            ))}
        </Select>
      </Box>
    </FormControl>
  )
  let operatorJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_OPERATOR }</InputLabel> */}
      <Select
        sx={{ background: operatorBackground }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_OPERATOR }
        onChange={(event, value) => {
          changeCalculationStepVariable(
            calculationStep,
            'operation_type',
            event.target.value,
            saveFormulaChangeToDatabase,
            calculationData,
            nestedLogicPropPathArray,
          )
        }}
        value={calculationStep.operation_type || ''}
      >
        {objectToArray(operatorOptions).map((option: TsInterface_UnspecifiedObject) => (
          <MenuItem
            key={option['key']}
            value={option['key']}
            disabled={option['disabled']}
          >
            {option['value']}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  let variableTwoJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      {/* <InputLabel>{ s_VARIABLE_2 }</InputLabel> */}
      <Select
        sx={{ background: variable2Background }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        // label={ s_VARIABLE_2 }
        onChange={(event, value) => {
          changeCalculationStepVariable(
            calculationStep,
            'variable_2',
            event.target.value,
            saveFormulaChangeToDatabase,
            calculationData,
            nestedLogicPropPathArray,
          )
        }}
        value={calculationStep.variable_2 || ''}
      >
        {previousStepOptions.map((option: TsInterface_UnspecifiedObject) => (
          <MenuItem
            key={option['key']}
            value={option['key']}
            disabled={option['disabled']}
          >
            {option['name']}
          </MenuItem>
        ))}
        {objectToArray(calculationData.variables)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['status'] === 'inactive'}
            >
              {option['name']}
              {variableTrace2}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px' }}
      disabled={disabledStepDeleteFunction}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Calculation Step'),
            text: rLIB('Are you sure that you want to delete this calculation step?'),
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteCalculationStep(calculationItem, calculationStepIndex, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                  .then((res_DCS) => {
                    resolve(res_DCS)
                  })
                  .catch((rej_DCS) => {
                    reject(rej_DCS)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  // Modifications to JSX
  if (calculationStep.operation_type === 'nothing') {
    variableTwoJSX = <></>
  } else if (calculationStep.operation_type === 'error') {
    variableOneJSX = <></>
    variableTwoJSX = <></>
  }
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  // Full JSX
  lineJSX = (
    <Box>
      <Box
        className="tw-inline-block tw-opacity-20"
        sx={{ display: 'inline-block', marginRight: '4px', marginLeft: '4px' }}
      >
        <Typography variant="h6">{calculationStepIndex + 1}</Typography>
      </Box>
      {variableOneJSX}
      {operatorJSX}
      {variableTwoJSX}
      {deleteButtonJSX}
    </Box>
  )
  return lineJSX
}

// operation_run_model
const rJSX_OperationRunModelContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let operationJSX = <></>
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginLeft: '12px', marginTop: '0px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Step'),
            text: (
              <>
                {rLIB('Are you sure that you want to delete this whole step?')} {rLIB('This will delete everything nested underneath it.')}
              </>
            ),
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteOperationRunModelStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                  .then((res_DORMS) => {
                    resolve(res_DORMS)
                  })
                  .catch((rej_DORMS) => {
                    reject(rej_DORMS)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  let chipColor: TsType_MuiComponentColors = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem.logic_trace_key != null
  ) {
    // @ts-expect-error - TODO: reason for error
    chipColor = 'default'
    if (traceData.calculationPath.pathDebug[logicItem.logic_trace_key] === true) {
      chipColor = 'success'
    }
  }
  // Input background colors
  let selectedModel = 'rgba(0,0,0,0)'
  if (logicItem.model == null || calculationData.models[logicItem.model] == null) {
    selectedModel = themeVariables.error_wash
  }
  // Full JSX
  operationJSX = (
    <Box>
      <Box>
        <Chip
          color={chipColor}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Run Model')}
            </Typography>
          }
        />
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '170px', marginLeft: '12px', marginRight: '12px' }}
        >
          <Select
            sx={{ background: selectedModel }}
            disabled={logicViewMode !== 'edit'}
            autoWidth={true}
            onChange={(event, value) => {
              changeModelVariable(logicItem, event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
            }}
            value={logicItem.model || ''}
          >
            {objectToArray(calculationData.models)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                  disabled={option['status'] === 'inactive'}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {deleteButtonJSX}
      </Box>
      {/* RUN MODEL ENDS RUN */}
      {/* <Box className="" sx={{ marginLeft: "16px" }}>
				<Box className="tw-inline-block" sx={{ width: "32px", display: "inline-block" }}>
					<Box className="" sx={{ height:"24px", width: "100%", borderLeft: "3px solid #ddd"}}></Box>
					<Box className="" sx={{ height:"16px", width: "100%", borderTop: "3px solid #ddd" }}></Box>
				</Box>
				<Box className="tw-inline-block tw-align-top" sx={{ width: "calc(100% - 32px)", paddingTop: "8px", display: "inline-block", verticalAlign: "top" }}>
					{ rJSX_LogicPathRender(
						logicItem.followup_step,
						logicViewMode,
						traceData,
						currentLevelIndex + 1,
						saveFormulaChangeToDatabase,
						uc_setUserInterface_ConfirmDialogDisplay,
						calculationData,
						availableLogicSteps,
						nestedLogicPropPathArray
					) }
				</Box>
			</Box> */}
    </Box>
  )
  return operationJSX
}

// operation_terminate
const rJSX_OperationTerminateContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let operationJSX = <></>
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginLeft: '12px', marginTop: '0px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Step'),
            text: <>{rLIB('Are you sure that you want to delete this whole step?')}</>,
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteOperationTerminateStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                  .then((res_DOTS) => {
                    resolve(res_DOTS)
                  })
                  .catch((rej_DOTS) => {
                    reject(rej_DOTS)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  let chipColor: TsType_MuiComponentColors = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem.logic_trace_key != null
  ) {
    // @ts-expect-error - TODO: reason for error
    chipColor = 'default'
    if (traceData.calculationPath.pathDebug[logicItem.logic_trace_key] === true) {
      chipColor = 'success'
    }
  }
  operationJSX = (
    <Box>
      <Box>
        <Chip
          color={chipColor}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Terminate Operation')}
            </Typography>
          }
        />
        {deleteButtonJSX}
      </Box>
    </Box>
  )
  return operationJSX
}

// condition_ml_category_if_else
const rJSX_OperationIfElseMlCategoriesContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let containerJSX = <></>
  let addButtonJSX = (
    <Button
      sx={{ marginRight: '8px' }}
      variant="outlined"
      color="info"
      className="tw-opacity-30 hover:tw-opacity-100"
      onClick={() => {
        addIfElseMlCategoriesElseIf(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {rLIB('Add Else If')}
    </Button>
  )
  if (logicViewMode !== 'edit') {
    addButtonJSX = <></>
  }
  let chipColor: TsType_MuiComponentColors = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem['condition_else'] != null &&
    logicItem['condition_else'].logic_trace_key != null
  ) {
    // @ts-expect-error - TODO: reason for error
    chipColor = 'default'
    if (traceData.calculationPath.pathDebug[logicItem['condition_else'].logic_trace_key] === true) {
      chipColor = 'success'
    } else if (traceData.calculationPath.pathDebug[logicItem['condition_else'].logic_trace_key] === false) {
      chipColor = 'error'
    }
  }
  containerJSX = (
    <Box>
      {logicItem.condition_ifs.map((conditionalItem: TsInterface_UnspecifiedObject, conditionIndex: number) => (
        <Box key={conditionIndex}>
          {rJSX_OperationIfElseMlCategoriesIfConditional(
            logicItem,
            conditionalItem,
            conditionIndex,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      ))}
      <Box>
        {addButtonJSX}
        <Box
          className=""
          sx={{ height: '16px', borderLeft: '3px solid #ddd', marginLeft: '16px' }}
        ></Box>
      </Box>
      <Box className="">
        <Chip
          color={chipColor}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Else')}
            </Typography>
          }
        />
      </Box>
      <Box sx={{ marginLeft: '16px', marginBottom: '8px' }}>
        <Box
          className="tw-inline-block"
          sx={{ width: '32px', display: 'inline-block' }}
        >
          <Box
            className=""
            sx={{ height: '24px', width: '100%', borderLeft: '3px solid #ddd' }}
          ></Box>
          <Box
            className=""
            sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
          ></Box>
        </Box>
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ width: 'calc(100% - 32px)', paddingTop: '8px', display: 'inline-block', verticalAlign: 'top' }}
        >
          {rJSX_LogicPathRender(
            logicItem.condition_else,
            logicViewMode,
            traceData,
            currentLevelIndex + 1,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      </Box>
    </Box>
  )
  return containerJSX
}

const rJSX_OperationIfElseMlCategoriesIfConditional = (
  logicItem: TsInterface_UnspecifiedObject,
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let conditionalJSX = <></>
  let ifLabelJSX = <></>
  let deleteButtonJSX = <></>
  if (conditionIndex === 0) {
    let chipColor1: TsType_MuiComponentColors = 'info'
    if (
      logicViewMode === 'trace' &&
      traceData != null &&
      traceData.calculationPath != null &&
      traceData.calculationPath.pathDebug != null &&
      conditionalItem != null &&
      conditionalItem.logic_trace_key != null
    ) {
      // @ts-expect-error - TODO: reason for error
      chipColor1 = 'default'
      if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === true) {
        chipColor1 = 'success'
      } else if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === false) {
        chipColor1 = 'error'
      }
    }
    ifLabelJSX = (
      <Chip
        color={chipColor1}
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('If')}
          </Typography>
        }
      />
    )
    deleteButtonJSX = (
      <Button
        variant="outlined"
        color="error"
        className="tw-opacity-30 hover:tw-opacity-100"
        sx={{ minWidth: '20px', fontSize: '24px', marginTop: '0px' }}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash-xmark" />,
              header: rLIB('Delete Step'),
              text: (
                <>
                  {rLIB('Are you sure that you want to delete this whole step?')} {rLIB('This will delete everything nested underneath it.')}
                </>
              ),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  deleteOperationIfElseMlCategoriesStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                    .then((res_DOIEMCS) => {
                      resolve(res_DOIEMCS)
                    })
                    .catch((rej_DOIEMCS) => {
                      reject(rej_DOIEMCS)
                    })
                })
              },
            },
          })
        }}
      >
        <Icon
          icon="trash-xmark"
          type="solid"
        />
      </Button>
    )
  } else {
    let chipColor2: TsType_MuiComponentColors = 'info'
    if (
      logicViewMode === 'trace' &&
      traceData != null &&
      traceData.calculationPath != null &&
      traceData.calculationPath.pathDebug != null &&
      conditionalItem != null &&
      conditionalItem.logic_trace_key != null
    ) {
      // @ts-expect-error - TODO: reason for error
      chipColor2 = 'default'
      if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === true) {
        chipColor2 = 'success'
      } else if (traceData.calculationPath.pathDebug[conditionalItem.logic_trace_key] === false) {
        chipColor2 = 'error'
      }
    }
    ifLabelJSX = (
      <Chip
        color={chipColor2}
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Else If')}
          </Typography>
        }
      />
    )
    deleteButtonJSX = (
      <Button
        variant="outlined"
        color="error"
        className="tw-opacity-30 hover:tw-opacity-100"
        sx={{ minWidth: '20px', fontSize: '24px', marginTop: '4px' }}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash-xmark" />,
              header: rLIB('Delete Else If'),
              text: (
                <>
                  {rLIB('Are you sure that you want to delete this else if?')} {rLIB('This will delete everything nested underneath it.')}
                </>
              ),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  deleteIfElseMlCategoriesElseIf(logicItem, conditionIndex, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                    .then((res_DIEMCEI) => {
                      resolve(res_DIEMCEI)
                    })
                    .catch((rej_DIEMCEI) => {
                      reject(rej_DIEMCEI)
                    })
                })
              },
            },
          })
        }}
      >
        <Icon icon="trash-xmark" />
      </Button>
    )
  }
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  let addButtonJSX = (
    <Button
      sx={{ marginBottom: '8px', marginRight: '8px' }}
      variant="outlined"
      color="success"
      className="tw-opacity-30 hover:tw-opacity-100"
      onClick={() => {
        addIfElseMlCategoriesConditionLine(conditionalItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
      }}
    >
      <Icon
        icon="circle-plus"
        className="tw-mr-2"
      />
      {rLIB('Add Condition')}
    </Button>
  )
  if (logicViewMode !== 'edit') {
    addButtonJSX = <></>
  }
  conditionalJSX = (
    <Box>
      <Box className="">
        {ifLabelJSX}
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginLeft: '12px', marginRight: '12px' }}
        >
          <Select
            disabled={logicViewMode !== 'edit'}
            autoWidth={true}
            onChange={(event, value) => {
              changeIfElseMlCategoriesConditionType(conditionalItem, event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
            }}
            value={conditionalItem.condition_type || ''}
          >
            <MenuItem
              key={'and_single_photo'}
              value={'and_single_photo'}
            >
              {rLIB('All of the following are in a single photo')}
            </MenuItem>
            <MenuItem
              key={'or_single_photo'}
              value={'or_single_photo'}
            >
              {rLIB('At least one of the following is in a single photo')}
            </MenuItem>
            {/* TODO - add to this? */}
          </Select>
        </FormControl>
        {deleteButtonJSX}
      </Box>
      <Box sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px', paddingLeft: '8px' }}>
        <Box sx={{ paddingTop: '8px' }}>
          {conditionalItem.conditions.map((conditionLine: TsInterface_UnspecifiedObject, conditionLineIndex: number) => (
            <Box key={conditionLineIndex}>
              {rJSX_OperationIfElseMlCategoriesIfConditionalLine(
                conditionalItem,
                conditionLine,
                conditionLineIndex,
                logicViewMode,
                traceData,
                currentLevelIndex,
                saveFormulaChangeToDatabase,
                uc_setUserInterface_ConfirmDialogDisplay,
                calculationData,
                nestedLogicPropPathArray,
              )}
            </Box>
          ))}
          {addButtonJSX}
        </Box>
      </Box>
      <Box
        className=""
        sx={{ borderLeft: '3px solid #ddd', marginLeft: '16px' }}
      >
        <Box
          className="tw-inline-block"
          sx={{ width: '32px', display: 'inline-block' }}
        >
          <Box
            className=""
            sx={{ height: '16px', width: '100%' }}
          ></Box>
          <Box
            className=""
            sx={{ height: '16px', width: '100%', borderTop: '3px solid #ddd' }}
          ></Box>
        </Box>
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ width: 'calc(100% - 32px)', display: 'inline-block', verticalAlign: 'top' }}
        >
          {rJSX_LogicPathRender(
            conditionalItem,
            logicViewMode,
            traceData,
            currentLevelIndex + 1,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      </Box>
    </Box>
  )
  return conditionalJSX
}

const rJSX_OperationIfElseMlCategoriesIfConditionalLine = (
  conditionalItem: TsInterface_UnspecifiedObject,
  conditionLine: TsInterface_UnspecifiedObject,
  conditionLineIndex: number,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  // Instantiate Variables
  let conditionalLineJSX = <></>
  // Trace
  let variableTrace1 = <></>
  // let variableTrace2 = <></>
  let modelBackground = 'rgba(0,0,0,0)'
  let categoryBackground = 'rgba(0,0,0,0)'
  let quantityBackground = 'rgba(0,0,0,0)'
  let comparatorBackground = 'rgba(0,0,0,0)'
  let confidenceBackground = 'rgba(0,0,0,0)'
  if (logicViewMode === 'trace') {
    if (
      conditionLine != null &&
      conditionLine.variable_1 != null &&
      traceData != null &&
      traceData['variables'] != null &&
      traceData['variables'][conditionLine.variable_1] != null
    ) {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.warning_main, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {traceData['variables'][conditionLine.variable_1]}
        </Box>
      )
    } else {
      variableTrace1 = (
        <Box
          className="tw-inline-block tw-font-bold tw-rounded"
          sx={{ background: themeVariables.error_light, paddingLeft: '8px', paddingRight: '8px', display: 'inline-block', marginLeft: '4px' }}
        >
          {rLIB('Missing')}
        </Box>
      )
    }
    // if(
    // conditionLine != null &&
    // conditionLine.variable_2 != null &&
    // traceData != null &&
    // traceData["variables"] != null &&
    // traceData["variables"][ conditionLine.variable_2 ] != null
    // ){
    // variableTrace2 =
    // <Box className="tw-inline-block tw-font-bold tw-rounded" sx={{ background: themeVariables.warning_main, paddingLeft: "8px", paddingRight: "8px", display: "inline-block", marginLeft: "4px" }}>
    // 	{ traceData["variables"][ conditionLine.variable_2 ] }
    // </Box>
    // } else {
    // variableTrace2 =
    // <Box className="tw-inline-block tw-font-bold tw-rounded" sx={{ background: themeVariables.error_light, paddingLeft: "8px", paddingRight: "8px", display: "inline-block", marginLeft: "4px" }}>
    // 	{ rLIB("Missing") }
    // </Box>
    // }
  }
  // Input background colors
  if (conditionLine.model == null || calculationData.models[conditionLine.model] == null) {
    modelBackground = themeVariables.error_wash
  }
  if (
    conditionLine.category == null ||
    calculationData.models[conditionLine.model] == null ||
    !calculationData.models[conditionLine.model].categories.includes(conditionLine.category)
  ) {
    categoryBackground = themeVariables.error_wash
  }
  if (conditionLine.quantity == null) {
    quantityBackground = themeVariables.error_wash
  }
  if (conditionLine.comparator == null) {
    comparatorBackground = themeVariables.error_wash
  }
  if (conditionLine.confidence == null) {
    confidenceBackground = themeVariables.error_wash
  }
  // Input and Button JSX
  let modelInputJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      <Select
        sx={{ background: modelBackground }}
        disabled={logicViewMode !== 'edit'}
        autoWidth={true}
        onChange={(event, value) => {
          changeIfElseMlCategoriesConditionVariable(
            conditionLine,
            'model',
            event.target.value,
            saveFormulaChangeToDatabase,
            calculationData,
            nestedLogicPropPathArray,
          )
        }}
        value={conditionLine.model || ''}
      >
        {objectToArray(calculationData.models)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['status'] === 'inactive' || option['output_type'] !== 'ml_confidence_categories'}
            >
              {option['name']}
              {variableTrace1}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
  let modelCategoryOptions: string[] = []
  if (
    conditionLine != null &&
    conditionLine.model != null &&
    calculationData != null &&
    calculationData.models != null &&
    calculationData.models[conditionLine.model] != null &&
    calculationData.models[conditionLine.model].categories != null
  ) {
    modelCategoryOptions = calculationData.models[conditionLine.model].categories.sort()
  }
  let categoryInputJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      <Select
        sx={{ background: categoryBackground }}
        disabled={logicViewMode !== 'edit' || conditionLine.model == null || conditionLine.model === ''}
        autoWidth={true}
        onChange={(event, value) => {
          changeIfElseMlCategoriesConditionVariable(
            conditionLine,
            'category',
            event.target.value,
            saveFormulaChangeToDatabase,
            calculationData,
            nestedLogicPropPathArray,
          )
        }}
        value={conditionLine.category || ''}
      >
        {modelCategoryOptions.map((option: string) => (
          <MenuItem
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  let quantityInputJSX = (
    <FormControl
      className="bp_thin_text_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      <TextField
        placeholder={rLIB('Quantity', false) as string}
        sx={{ background: quantityBackground }}
        disabled={logicViewMode !== 'edit'}
        onChange={(event) => {
          changeIfElseMlCategoriesConditionVariable(
            conditionLine,
            'quantity',
            event.target.value,
            saveFormulaChangeToDatabase,
            calculationData,
            nestedLogicPropPathArray,
          )
        }}
        value={conditionLine.quantity || ''}
        variant="outlined"
        type="number"
      />
    </FormControl>
  )
  let comparatorInputJSX = (
    <FormControl
      className="bp_thin_select_input bp_thin_select_multiple_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      <Select
        sx={{ background: comparatorBackground }}
        disabled={logicViewMode !== 'edit' || conditionLine.model == null || conditionLine.model === ''}
        autoWidth={true}
        onChange={(event, value) => {
          changeIfElseMlCategoriesConditionVariable(
            conditionLine,
            'comparator',
            event.target.value,
            saveFormulaChangeToDatabase,
            calculationData,
            nestedLogicPropPathArray,
          )
        }}
        value={conditionLine.comparator || ''}
      >
        {objectToArray(mlNumericComparatorOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
          <MenuItem
            key={index}
            value={option.key}
          >
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  let confidenceInputJSX = (
    <FormControl
      className="bp_thin_text_input"
      sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
    >
      <TextField
        placeholder={rLIB('Confidence (0-1)', false) as string}
        sx={{ background: confidenceBackground }}
        disabled={logicViewMode !== 'edit'}
        onChange={(event) => {
          changeIfElseMlCategoriesConditionVariable(
            conditionLine,
            'confidence',
            event.target.value,
            saveFormulaChangeToDatabase,
            calculationData,
            nestedLogicPropPathArray,
          )
        }}
        value={conditionLine.confidence || ''}
        variant="outlined"
        type="number"
      />
    </FormControl>
  )
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Condition'),
            text: rLIB('Are you sure that you want to delete this condition?'),
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteIfElseMlCategoriesConditionLine(
                  conditionalItem,
                  conditionLineIndex,
                  saveFormulaChangeToDatabase,
                  calculationData,
                  nestedLogicPropPathArray,
                )
                  .then((res_DIEMCCL) => {
                    resolve(res_DIEMCCL)
                  })
                  .catch((rej_DIEMCCL) => {
                    reject(rej_DIEMCCL)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  // Modifications to JSX
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  // Full JSX
  conditionalLineJSX = (
    <Box>
      {modelInputJSX}
      {categoryInputJSX}
      {quantityInputJSX}
      {comparatorInputJSX}
      {confidenceInputJSX}
      {deleteButtonJSX}
    </Box>
  )
  return conditionalLineJSX
}

// operation_generate_message
const rJSX_OperationGenerateMessageContainer = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let operationJSX = <></>
  let deleteButtonJSX = (
    <Button
      variant="outlined"
      color="error"
      className="tw-opacity-30 hover:tw-opacity-100"
      sx={{ minWidth: '20px', fontSize: '24px', marginLeft: '12px', marginTop: '0px' }}
      onClick={() => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            icon: <Icon icon="trash-xmark" />,
            header: rLIB('Delete Step'),
            text: <>{rLIB('Are you sure that you want to delete this whole step?')}</>,
            submit_text: rLIB('Delete'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                deleteOperationGenerateMessageStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
                  .then((res_DOGMS) => {
                    resolve(res_DOGMS)
                  })
                  .catch((rej_DOGMS) => {
                    reject(rej_DOGMS)
                  })
              })
            },
          },
        })
      }}
    >
      <Icon icon="trash-xmark" />
    </Button>
  )
  if (logicViewMode !== 'edit') {
    deleteButtonJSX = <></>
  }
  let chipColor: TsType_MuiComponentColors = 'info'
  if (
    logicViewMode === 'trace' &&
    traceData != null &&
    traceData.calculationPath != null &&
    traceData.calculationPath.pathDebug != null &&
    logicItem != null &&
    logicItem.logic_trace_key != null
  ) {
    // @ts-expect-error - TODO: reason for error
    chipColor = 'default'
    if (traceData.calculationPath.pathDebug[logicItem.logic_trace_key] === true) {
      chipColor = 'success'
    }
  }
  // Input background colors
  let messageTypeInputColor = 'rgba(0,0,0,0)'
  if (logicItem.message_type == null) {
    messageTypeInputColor = themeVariables.error_wash
  }
  let messageInputColor = 'rgba(0,0,0,0)'
  if (logicItem.message == null) {
    messageInputColor = themeVariables.error_wash
  }
  // Full JSX
  operationJSX = (
    <Box>
      <Box className="tw-mb-2">
        <Chip
          color={chipColor}
          variant="filled"
          sx={{ height: '36px' }}
          label={
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Generate Message')}
            </Typography>
          }
        />
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '100px', marginLeft: '12px', marginRight: '12px' }}
        >
          <Select
            sx={{ background: messageTypeInputColor }}
            disabled={logicViewMode !== 'edit'}
            autoWidth={true}
            onChange={(event, value) => {
              changeMessageType(logicItem, event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
            }}
            value={logicItem.message_type || ''}
          >
            {objectToArray(severityMessageTypes).map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
                disabled={option['status'] === 'inactive'}
              >
                {option['icon']}
                {option['name']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          className="bp_thin_text_input"
          sx={{ minWidth: '300px' }}
        >
          <TextField
            sx={{ background: messageInputColor }}
            disabled={logicViewMode !== 'edit'}
            onChange={(event) => {
              changeMessage(logicItem, event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
            }}
            value={logicItem.message || ''}
            variant="outlined"
          />
        </FormControl>
        {deleteButtonJSX}
      </Box>
    </Box>
  )
  return operationJSX
}

// all
const rJSX_LogicPathNewStepButtons = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  // Instantiate Variables
  let buttonsJSX = <></>
  let conditionIfElseButtonJSX = <></>
  let conditionSwitchButtonJSX = <></>
  let operationFormulaButtonJSX = <></>
  let operationRunModelButtonJSX = <></>
  let operationTerminateButtonJSX = <></>
  let operationIfElseMlCategoriesButtonJSX = <></>
  let operationGenerateMessageButtonJSX = <></>
  // Depending on the available logic steps, show the buttons
  if (availableLogicSteps.condition_if_else === true) {
    conditionIfElseButtonJSX = (
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addIfElseConditionStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
      >
        <Icon
          icon="code-branch"
          className="tw-mr-2 fa-flip-vertical"
        />
        {rLIB('Add If/Else Statement')}
      </Button>
    )
  }
  if (availableLogicSteps.condition_switch === true) {
    conditionSwitchButtonJSX = (
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addSwitchConditionStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
      >
        <Icon
          icon="layer-group"
          className="tw-mr-2"
        />
        {rLIB('Add Switch Statement')}
      </Button>
    )
  }
  if (availableLogicSteps.operation_formula === true) {
    operationFormulaButtonJSX = (
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addOperationFormulaStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
      >
        <Icon
          icon="calculator-simple"
          className="tw-mr-2"
        />
        {rLIB('Add Formula Calculation')}
      </Button>
    )
  }
  if (availableLogicSteps.operation_run_model === true) {
    operationRunModelButtonJSX = (
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addOperationRunModelStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
      >
        <Icon
          icon="robot"
          className="tw-mr-2"
        />
        {rLIB('Run Model')}
      </Button>
    )
  }
  if (availableLogicSteps.operation_terminate === true) {
    operationTerminateButtonJSX = (
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addOperationTerminateStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
      >
        <Icon
          icon="octagon"
          className="tw-mr-2"
        />
        {rLIB('Terminate Operation')}
      </Button>
    )
  }
  if (availableLogicSteps.condition_ml_category_if_else === true) {
    operationIfElseMlCategoriesButtonJSX = (
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addOperationIfElseMlCategoriesStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
      >
        <Icon
          icon="magnifying-glass-chart"
          className="tw-mr-2"
        />
        {rLIB('If/Else from ML Categories')}
      </Button>
    )
  }
  if (availableLogicSteps.operation_generate_message === true) {
    operationGenerateMessageButtonJSX = (
      <Button
        sx={{ marginBottom: '8px', marginRight: '8px' }}
        variant="outlined"
        className="tw-opacity-30 hover:tw-opacity-100"
        color="info"
        onClick={() => {
          addOperationGenerateMessageStep(logicItem, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
      >
        <Icon
          icon="message-captions"
          className="tw-mr-2"
        />
        {rLIB('Generate Message')}
      </Button>
    )
  }
  buttonsJSX = (
    <Box>
      {conditionIfElseButtonJSX}
      {operationIfElseMlCategoriesButtonJSX}
      {conditionSwitchButtonJSX}
      {operationFormulaButtonJSX}
      {operationRunModelButtonJSX}
      {operationGenerateMessageButtonJSX}
      {operationTerminateButtonJSX}
    </Box>
  )
  if (logicViewMode !== 'edit') {
    buttonsJSX = (
      <Chip
        color="error"
        variant="filled"
        sx={{ height: '36px' }}
        label={
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Incomplete Path')}
          </Typography>
        }
      />
    )
  }
  return buttonsJSX
}

export const rJSX_LogicPathRender = (
  logicItem: TsInterface_UnspecifiedObject,
  logicViewMode: 'view' | 'edit' | 'trace',
  traceData: TsInterface_UnspecifiedObject,
  currentLevelIndex: number,
  saveFormulaChangeToDatabase: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  calculationData: TsInterface_UnspecifiedObject,
  availableLogicSteps: TsInterface_UnspecifiedObject,
  nestedLogicPropPathArray: string[],
): JSX.Element => {
  let logicItemJSX = <></>
  if (logicItem != null) {
    if (logicItem['logic_step_type'] == null) {
      logicItemJSX = (
        <Box>
          {rJSX_LogicPathNewStepButtons(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'condition_if_else') {
      logicItemJSX = (
        <Box>
          {rJSX_IfConditionalContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'condition_switch') {
      logicItemJSX = (
        <Box>
          {rJSX_SwitchConditionalContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'operation_formula') {
      logicItemJSX = (
        <Box>
          {rJSX_OperationFormulaContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            nestedLogicPropPathArray,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'operation_run_model') {
      logicItemJSX = (
        <Box>
          {rJSX_OperationRunModelContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'operation_terminate') {
      logicItemJSX = (
        <Box>
          {rJSX_OperationTerminateContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            nestedLogicPropPathArray,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'condition_ml_category_if_else') {
      logicItemJSX = (
        <Box>
          {rJSX_OperationIfElseMlCategoriesContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            availableLogicSteps,
            nestedLogicPropPathArray,
          )}
        </Box>
      )
    } else if (logicItem['logic_step_type'] === 'operation_generate_message') {
      logicItemJSX = (
        <Box>
          {rJSX_OperationGenerateMessageContainer(
            logicItem,
            logicViewMode,
            traceData,
            currentLevelIndex,
            saveFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            calculationData,
            nestedLogicPropPathArray,
          )}
        </Box>
      )
    }
  }
  return logicItemJSX
}
