/* eslint-disable react/prop-types */
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { collection, getDoc, getDocs, getFirestore, query, serverTimestamp, where } from 'firebase/firestore'
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_MasterSkusForSupplier_Query } from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import {
  DatabaseRef_Material_Imports_By_Invoice_Number,
  DatabaseRef_Material_Imports_By_Invoice_Number_AsNumber,
  DatabaseRef_Material_Imports_By_Project,
  DatabaseRef_Material_Imports_By_Supplier,
  DatabaseRef_Material_Imports_Document,
  DatabaseRef_Material_Imports_Sessions_Collection,
  DatabaseRef_Material_Imports_Session_Document,
} from 'rfbp_aux/services/database_endpoints/materials/material_imports'

import { DatabaseRef_ActiveSuppliers_Query } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { Icon } from 'rfbp_core/components/icons'
import MaterialImportButtonAndDialog, { performPreImportChecks } from 'rfbp_core/components/imports/material_import_button_and_dialog'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'

import CloseIcon from '@mui/icons-material/Close'
import FilterAltIcon from '@mui/icons-material/FilterAlt' // Add this import if not already present
import { IconButton } from '@mui/material'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseDeleteDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

type Supplier = { key: string; name: string }
const sanitizeRow = (row: any): any => {
  return Object.keys(row).reduce((acc: any, key: string) => {
    if (row[key] !== undefined) acc[key] = row[key]
    return acc
  }, {})
}

export const buildUniqueKey = (rowData: any): string => {
  // Utility to safely convert to string, trim, and replace spaces & slashes
  const cleanup = (val: any): string => {
    const asString = String(val ?? '').trim()
    return asString.replace(/\s+/g, '_').replace(/\//g, '_')
  }

  // Safely convert everything to strings
  const supplierName = String(rowData.associated_supplier_name ?? '')
  const invoiceRef = String(rowData.invoice_reference_number ?? '')
  const projectId = String(rowData.associated_project_id_number ?? '')
  // For SKU or descriptions, we do an extra .toLowerCase() check for 'missing'

  // Step 1: get the "primary" fields
  const primarySkuNumber = String(rowData.sku_number ?? '').trim()
  const primarySkuDesc = String(rowData.sku_description ?? '').trim()
  const tempSkuNumber = String(rowData.temp_sku_number ?? '').trim()
  const tempSkuDesc = String(rowData.temp_sku_description ?? '').trim()

  // Step 2: Fallback logic (SKU number → SKU description → temp_sku_number → temp_sku_description)
  let finalSku: string = ''
  if (primarySkuNumber && primarySkuNumber.toLowerCase() !== 'missing') {
    finalSku = primarySkuNumber
  } else if (primarySkuDesc && primarySkuDesc.toLowerCase() !== 'missing') {
    finalSku = primarySkuDesc
  } else if (tempSkuNumber && tempSkuNumber.toLowerCase() !== 'missing') {
    finalSku = tempSkuNumber
  } else if (tempSkuDesc && tempSkuDesc.toLowerCase() !== 'missing') {
    finalSku = tempSkuDesc
  } else {
    finalSku = 'missing_sku'
  }

  // Step 3: Clean and uppercase each piece
  const supp = cleanup(supplierName).toUpperCase().slice(0, 3)
  const inv = cleanup(invoiceRef).toUpperCase()
  const jobClean = cleanup(projectId).toUpperCase()
  const skuClean = cleanup(finalSku).toUpperCase()

  // We’ll grab the last 4 chars of the job
  const last4 = jobClean.slice(-4)

  // Build the combined key
  const combined = `${supp}_${inv}_${last4}_${skuClean}`

  // If everything is empty (very unlikely), fallback
  return combined || 'fallback_missing_key'
}

const baseFinalImportSubmission = (finalDataToImport: any, importHooks: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const { uc_setUserInterface_ErrorDialogDisplay } = importHooks

    getClientKey(importHooks.uc_RootData_ClientKey, importHooks.uc_setRootData_ClientKey)
      .then(async (res_GCK) => {
        const clientKey = res_GCK.clientKey

        const duplicates: string[] = []
        const insertedDocIDs: string[] = []
        const updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        const originalCount = finalDataToImport.length

        // Add importSessionId if we have one
        const importSessionId = importHooks?.importSessionId || null

        for (const item of finalDataToImport) {
          const row = sanitizeRow(item.row)
          const docId = buildUniqueKey(row)
          const existingDocRef = DatabaseRef_Material_Imports_Document(clientKey, docId)
          try {
            const docSnap = await getDoc(existingDocRef)
            if (docSnap.exists()) {
              duplicates.push(docId) // skip
            } else {
              // HERE: store importSessionId
              updateArray.push({
                type: 'setMerge',
                ref: existingDocRef,
                data: {
                  ...row,
                  status: 'active',
                  key: docId,
                  importSessionId: importSessionId, // <--- ONLY CHANGE
                },
              })
              insertedDocIDs.push(docId)
            }
          } catch (e) {
            console.error('Error checking doc existence for docId:', docId, e)
          }
        }

        if (updateArray.length === 0) {
          const summary = {
            originalCount,
            duplicatesCount: duplicates.length,
            insertedCount: 0,
            insertedDocIDs: [],
          }
          resolve({ success: true, summary })
          return
        }

        DatabaseStagedBatchUpdate(updateArray)
          .then(() => {
            const summary = {
              originalCount,
              duplicatesCount: duplicates.length,
              insertedCount: insertedDocIDs.length,
            }
            resolve({ success: true, summary })
          })
          .catch((rej_DSBU) => {
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: {
                code: 'BATCH-UPDATE',
                message: 'Batch Update Error',
                details: JSON.stringify(rej_DSBU, null, 2),
              },
            })
            reject(rej_DSBU)
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            code: 'CLIENT-KEY',
            message: 'Client Key Error',
            details: JSON.stringify(rej_GCK, null, 2),
          },
        })
        reject(rej_GCK)
      })
  })
}

export const Container: React.FC = (): JSX.Element => {
  // const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const [us_suppliers, us_setSuppliers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_importSessions, setImportSessions] = useState<TsInterface_UnspecifiedObject>({})
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>({ key: '', name: '' })
  // const [us_users, us_setUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogData, setDialogData] = useState<any>({})
  const [dialogOptions, setDialogOptions] = useState<any[]>([])
  const [selectedOptionKey, setSelectedOptionKey] = useState<string>('')
  const [us_importSummaryDialogOpen, us_setImportSummaryDialogOpen] = useState(false)
  const [us_importSummary, us_setImportSummary] = useState<ImportSummary>({})
  const [searchMode, setSearchMode] = useState<'sku' | 'description'>('sku')
  // State for bulk update toggle.
  const [applyBulkUpdateSku, setApplyBulkUpdateSku] = useState<boolean>(false)
  const [applyBulkUpdate, setApplyBulkUpdate] = useState<boolean>(false)
  const [skuDialogOpen, setSkuDialogOpen] = useState(false)
  const [skuDialogData, setSkuDialogData] = useState<any>({})
  const [skuDialogOptions, setSkuDialogOptions] = useState<any[]>([])
  const [skuSelectedOptionKey, setSkuSelectedOptionKey] = useState<string>('')
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [combinedDocs, setCombinedDocs] = useState<any>({})
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openNoDocsDialog, setOpenNoDocsDialog] = useState(false)
  const [selectedSupplierForDelete, setSelectedSupplierForDelete] = useState<Supplier | null>(null)
  const [openSupplierDeleteDialog, setOpenSupplierDeleteDialog] = useState(false)
  const [supplierConfirmationInput, setSupplierConfirmationInput] = useState('')
  const [supplierDeleteConfirmOpen, setSupplierDeleteConfirmOpen] = useState(false)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const [selectedProject, setSelectedProject] = useState<{ key: string; id_number: string } | null>(null)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)

  const [regionsData, setRegionsData] = useState<any>({})

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      setRegionsData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  interface ImportSummary {
    originalCount?: number
    duplicatesCount?: number
    insertedCount?: number
    insertedDocIDs?: string[]
    totalImported?: number
  }

  const ProjectSearchDialog = ({
    clientKey,
    onSelectProject,
  }: {
    clientKey: string
    onSelectProject: (project: { key: string; id_number: string }) => void
  }) => {
    return (
      <Card className="tw-m-4">
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', mb: 2 }}
          >
            {rLIB('Select Project to Filter')}
          </Typography>
          <SearchInput
            clientKey={clientKey}
            searchIndexKey={'projects'}
            searchFilters={[]}
            searchResultRenderer={(option: any, searchInputValue: string | null) => (
              <Box sx={{ ml: 2, mr: 2, mb: 1 }}>
                <Typography variant="body2">
                  {rLIB('Job Code')}: {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}
                </Typography>
                <Typography variant="body2">
                  {rLIB('Customer')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_customer_name)}
                </Typography>
                <Button
                  variant="contained"
                  color="info"
                  sx={{ mt: 1 }}
                  onClick={() => {
                    onSelectProject({ key: option.id, id_number: option.id_number })
                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                  }}
                >
                  {rLIB('Select Project')}
                </Button>
                <Divider sx={{ my: 1 }} />
              </Box>
            )}
            additionalSearchData={{}}
            sx={{ width: '100%' }}
          />
        </Box>
      </Card>
    )
  }

  const openProjectSearchDialog = (onProjectSelected: (project: { key: string; id_number: string }) => void) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK: any) => {
        uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: (
              <Dialog
                open={true}
                onClose={() => {
                  uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                }}
              >
                <ProjectSearchDialog
                  clientKey={res_GCK.clientKey}
                  onSelectProject={onProjectSelected}
                />
              </Dialog>
            ),
            settings: { max_width: 'lg' },
          },
        })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  useEffect(() => {
    document.title = 'Material Imports'
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction | undefined
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      setImportSessions(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Material_Imports_Sessions_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error('Error fetching Suppliers:', rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction | undefined
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSuppliers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSuppliers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error('Error fetching Suppliers:', rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // ------------------
  // Cache and State
  // ------------------
  const skuCache = useRef<{ [supplierKey: string]: any[] }>({})

  const tableDatabaseEndpoint_MaterialImports = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    if (!uc_RootData_ClientKey) {
      throw new Error('Client key is missing')
    }

    const clientKey = uc_RootData_ClientKey as string
    const selectedProject = tableAdditionalData?.selectedProject ?? null

    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []

    if (selectedProject?.key) {
      queryOperatorsArray.push({
        prop: 'associated_project_key',
        comparator: '==', // Double-check this matches your query builder
        value: selectedProject.key,
      })
    }

    let orderByArray: TsInterface_OrderByArray = [{ prop: 'associated_project_key', desc: false }]

    let queryCursorsObject: TsInterface_QueryCursorsObject = {}

    if (queryGenerationData.startAfter != null) {
      queryCursorsObject.startAfter = queryGenerationData.startAfter
    }
    if (queryGenerationData.startAt != null) {
      queryCursorsObject.startAt = queryGenerationData.startAt
    }
    if (queryGenerationData.endAt != null) {
      queryCursorsObject.endAt = queryGenerationData.endAt
    }
    if (queryGenerationData.endBefore != null) {
      queryCursorsObject.endBefore = queryGenerationData.endBefore
    }

    let limit = getProp(queryGenerationData, 'limit', 100)

    return generateDatabaseQuery(
      collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_MissingSKUNumber = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    const clientKey = uc_RootData_ClientKey as string

    // Filter: only docs with sku_number == 'missing'
    const queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'sku_number', comparator: '==', value: 'missing' }]

    // Order by invoice_reference_number, then fallback to key
    const orderByArray: TsInterface_OrderByArray = [
      { prop: 'invoice_reference_number', desc: false },
      { prop: 'key', desc: false },
    ]

    const queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData.startAfter != null) {
      queryCursorsObject.startAfter = queryGenerationData.startAfter
    }
    if (queryGenerationData.startAt != null) {
      queryCursorsObject.startAt = queryGenerationData.startAt
    }
    if (queryGenerationData.endAt != null) {
      queryCursorsObject.endAt = queryGenerationData.endAt
    }
    if (queryGenerationData.endBefore != null) {
      queryCursorsObject.endBefore = queryGenerationData.endBefore
    }

    // match table's page size or 100 as fallback
    const limit = getProp(queryGenerationData, 'limit', 100)

    return generateDatabaseQuery(
      collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_ImportsMissingTasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    if (!uc_RootData_ClientKey) {
      throw new Error('Client key is missing')
    }
    const clientKey = uc_RootData_ClientKey as string

    // 1) Filter: only docs with associated_task_key == 'missing'
    const queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_task_key', comparator: '==', value: 'missing' }]

    // 2) Sort primarily by invoice_reference_number, then by key
    // (or doc ID `__name__` if you prefer)
    const orderByArray: TsInterface_OrderByArray = [
      { prop: 'invoice_reference_number', desc: false },
      { prop: 'key', desc: false },
      // or { prop: '__name__', desc: false },
    ]

    // 3) Handle cursor-based pagination (server-side)
    const queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData.startAfter != null) {
      queryCursorsObject.startAfter = queryGenerationData.startAfter
    }
    if (queryGenerationData.startAt != null) {
      queryCursorsObject.startAt = queryGenerationData.startAt
    }
    if (queryGenerationData.endAt != null) {
      queryCursorsObject.endAt = queryGenerationData.endAt
    }
    if (queryGenerationData.endBefore != null) {
      queryCursorsObject.endBefore = queryGenerationData.endBefore
    }

    const limit = getProp(queryGenerationData, 'limit', 100)

    return generateDatabaseQuery(
      collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_ImportsMissingProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    if (!uc_RootData_ClientKey) {
      throw new Error('Client key is missing')
    }
    const clientKey = uc_RootData_ClientKey as string
    const queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: 'missing' }]

    const orderByArray: TsInterface_OrderByArray = [
      { prop: 'invoice_reference_number', desc: false },
      { prop: 'key', desc: false },
    ]

    const queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData.startAfter != null) {
      queryCursorsObject.startAfter = queryGenerationData.startAfter
    }
    if (queryGenerationData.startAt != null) {
      queryCursorsObject.startAt = queryGenerationData.startAt
    }
    if (queryGenerationData.endAt != null) {
      queryCursorsObject.endAt = queryGenerationData.endAt
    }
    if (queryGenerationData.endBefore != null) {
      queryCursorsObject.endBefore = queryGenerationData.endBefore
    }

    const limit = getProp(queryGenerationData, 'limit', 100)

    return generateDatabaseQuery(
      collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const getSupplierSkus = (supplierKey: string): Promise<any[]> => {
    return getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      if (!res_GCK.clientKey) {
        throw new Error('Missing client key')
      }
      const queryRef = DatabaseRef_MasterSkusForSupplier_Query(res_GCK.clientKey, supplierKey)
      return getDocs(queryRef).then((snapshot) => {
        const skus = snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }))

        return skus
      })
    })
  }

  const setupSkuDialog = (rowData: any, skus: any[]) => {
    const skuOptions = skus.map((sku, index) => {
      const uniqueId = typeof sku.unique_identifier === 'string' ? sku.unique_identifier.trim() : ''
      const key = uniqueId !== '' ? uniqueId : `noid_${index}`
      return {
        key,
        value: uniqueId,
        uniqueIdentifier: uniqueId,
        description: sku.name ? String(sku.name) : '',
      }
    })

    const initialOption = skuOptions.find((opt) => opt.value === rowData.sku_number)
    const initialSkuOptionKey = initialOption ? initialOption.key : null
    setSkuDialogData(rowData)
    setSkuDialogOptions(skuOptions)
    setSkuSelectedOptionKey(initialSkuOptionKey)
    setSkuDialogOpen(true)
  }

  const handleSkuDialogSubmit = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const docId = skuDialogData.docId || skuDialogData.key
        if (res_GCK.clientKey) {
          const selectedOption = skuDialogOptions.find((option: any) => option.key === skuSelectedOptionKey)
          if (!selectedOption) {
            console.error('No valid SKU selected')
            return
          }
          const updatedData = {
            sku_number: selectedOption.value,
            sku_description: selectedOption.description,
            associated_sku_key: selectedOption.key,
          }
          if (applyBulkUpdateSku) {
            const rawSku = skuDialogData.temp_sku_number

            const skuDocsQuery = generateDatabaseQuery(
              collection(getFirestore(), 'clients', res_GCK.clientKey, 'materials', 'imports', 'main'),
              [{ prop: 'temp_sku_number', comparator: '==', value: rawSku }],
              [], // No ordering necessary
              {},
              null,
            )
            DatabaseGetCollection(skuDocsQuery)
              .then((res_docs) => {
                const docs = res_docs.success && res_docs.data ? res_docs.data : {}
                // Filter client-side: only update docs with the same supplier as the current row.
                const matchingDocKeys = Object.keys(docs).filter((docKey) => {
                  const doc = docs[docKey]

                  return doc.associated_supplier_name === skuDialogData.associated_supplier_name
                })

                Promise.all(
                  matchingDocKeys.map((docKey) => {
                    return DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(res_GCK.clientKey, docKey), updatedData)
                  }),
                )
                  .then((res) => {
                    setSkuDialogOpen(false)
                  })
                  .catch((error) => {
                    console.error('Bulk SKU update failed', error)
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                  })
              })
              .catch((error) => {
                console.error('Error fetching documents for bulk SKU update', error)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
              })
          } else {
            // Single update branch:
            if (docId) {
              DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(res_GCK.clientKey, docId), updatedData)
                .then((res) => {
                  // Optionally update local row data.
                  skuDialogData.sku_number = selectedOption.value
                  skuDialogData.sku_description = selectedOption.description
                  skuDialogData.associated_sku_key = selectedOption.key
                  setSkuDialogOpen(false)
                })
                .catch((error) => {
                  console.error('SKU update failed', error)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                })
            } else {
              console.error('Missing client key or docId')
            }
          }
        } else {
          console.error('Missing client key')
        }
      })
      .catch((error) => {
        console.error('Client key error', error)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
      })
  }

  const EditSkuDialog: React.FC = () => {
    const selectedOption = skuDialogOptions.find((option) => option.key === skuSelectedOptionKey) || null

    return (
      <Dialog
        open={skuDialogOpen}
        onClose={() => setSkuDialogOpen(false)}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            variant="h5"
            className="tw-font-bold tw-mb-4"
          >
            {rLIB('Edit SKU')}
          </Typography>
          <Card
            variant="outlined"
            className="tw-mb-4"
          >
            <CardHeader title={rLIB('SKU Details')} />
            <CardContent>
              <Box>
                <Typography variant="body2">
                  <strong>{rLIB('Supplier')}:</strong> {skuDialogData.associated_supplier_name}
                </Typography>
                <Typography variant="body2">
                  <strong>{rLIB('Invoice')}:</strong> {skuDialogData.invoice_reference_number}
                </Typography>
                <Typography variant="body2">
                  <strong>{rLIB('Current SKU Number')}:</strong> {skuDialogData.sku_number}
                </Typography>
                <Typography variant="body2">
                  <strong>{rLIB('Current SKU Description')}:</strong> {skuDialogData.sku_description}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">
                  <strong>{rLIB('New SKU Number')}:</strong> <strong>{selectedOption ? selectedOption.uniqueIdentifier : rLIB('None Selected')}</strong>
                </Typography>
                <Typography variant="body2">
                  <strong>{rLIB('New SKU Description')}:</strong> <strong>{selectedOption ? selectedOption.description : rLIB('None Selected')}</strong>
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Autocomplete
                options={skuDialogOptions}
                getOptionLabel={(option) => (searchMode === 'sku' ? option.uniqueIdentifier || '' : option.description || '')}
                value={skuDialogOptions.find((option) => option.key === skuSelectedOptionKey) || null}
                onChange={(event, newValue) => {
                  setSkuSelectedOptionKey(newValue ? newValue.key : null)
                }}
                onInputChange={(event, value) => {}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={rLIB('Search SKU')}
                    variant="outlined"
                  />
                )}
                fullWidth
                freeSolo={false}
                disableClearable
              />
              <Divider sx={{ my: 2 }} />
              {/* RadioGroup for search mode selection */}
              <RadioGroup
                row
                value={searchMode}
                onChange={(e) => {
                  setSearchMode(e.target.value as 'sku' | 'description')
                }}
              >
                <FormControlLabel
                  value="sku"
                  control={<Radio color="primary" />}
                  label={rLIB('Search by SKU Number')}
                />
                <FormControlLabel
                  value="description"
                  control={<Radio color="primary" />}
                  label={rLIB('Search by SKU Description')}
                />
              </RadioGroup>
            </CardContent>
          </Card>
          {/* Bulk update toggle */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Switch
              checked={applyBulkUpdateSku}
              onChange={(e) => {
                setApplyBulkUpdateSku(e.target.checked)
              }}
              color="primary"
            />
            <Typography
              variant="body2"
              sx={{ ml: 1 }}
            >
              {rLIB('Apply to all rows with matching Raw SKU Number and matching supplier')}
            </Typography>
          </Box>
          <DialogActions>
            <Button
              onClick={() => setSkuDialogOpen(false)}
              color="secondary"
            >
              {rLIB('Cancel')}
            </Button>
            <Button
              onClick={handleSkuDialogSubmit}
              color="primary"
              disabled={!skuSelectedOptionKey}
            >
              {rLIB('Submit')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    )
  }

  // ------------------
  // Updated Table Columns for SKU Lookup
  // ------------------
  const tableColumns_SkuLookup: TsInterface_TableColumns = {
    manage: TableCellManage({
      delete: {
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        label: <>Delete</>, // Removed rLIB since you're moving away from it
        // Only display delete if status is not already "deleted"
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '!=',
          value: 'deleted',
          conditions: [],
        },
        onClick: (rowData: TsInterface_TableDataRow, _tableAdditionalData: TsInterface_UnspecifiedObject, tableHooks: TsInterface_TableHooks) => {
          if (rowData?.key && tableHooks?.uc_RootData_ClientKey) {
            tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                header: 'Delete Import Row', // Removed rLIB
                icon: (
                  <Icon
                    icon="trash"
                    type="solid"
                  />
                ),
                submit_text: 'Delete', // Removed rLIB
                text: 'Are you sure you want to delete this import record?', // Removed rLIB
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    DatabaseDeleteDocument(DatabaseRef_Material_Imports_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string))
                      .then(() => resolve({ success: true }))
                      .catch((error) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                        reject(error)
                      })
                  })
                },
              },
            })
          }
        },
      },
    }),

    sku_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>SKU Number</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Box className="tw-flex tw-items-center">
            <Box className={rowData.sku_number === 'missing' ? 'tw-text-warning_main' : ''}>{rowData.sku_number}</Box>
            {rowData.associated_supplier_key !== 'missing' ? (
              <Icon
                icon="pen-to-square"
                onClick={() => {
                  // Use the supplier key (fallback to name if necessary)
                  const supplierKey = rowData.associated_supplier_key || rowData.associated_supplier_name
                  if (supplierKey && supplierKey !== 'missing') {
                    if (skuCache.current[supplierKey]) {
                      setupSkuDialog(rowData, skuCache.current[supplierKey])
                    } else {
                      getSupplierSkus(supplierKey)
                        .then((skus: any[]) => {
                          skuCache.current[supplierKey] = skus
                          setupSkuDialog(rowData, skus)
                        })
                        .catch((error) => {
                          console.error('Error fetching SKU list:', error)
                        })
                    }
                  } else {
                    console.error('No valid supplier key available')
                  }
                }}
                className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
                tooltip="Edit SKU Number"
                tooltipPlacement="right"
              />
            ) : (
              <Icon
                icon="circle-info"
                className="tw-ml-2 tw-cursor-pointer"
                sx={{ color: themeVariables.warning_main }}
                tooltip="This SKU number cannot be edited because supplier is missing."
                tooltipPlacement="right"
              />
            )}
          </Box>
        ),
      },
    },
    // sku_description: TableCellBasic('sku_description', 'SKU Description', 'sku_description'),
    raw_sku_number: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="body2">{rLIB('Raw SKU Number')}</Typography>
            <Tooltip title={rLIB('This is the SKU number from the import file and did not have a match with an existing SKU Number for the supplier in EOS.')}>
              <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Icon
                  icon="info-circle"
                  sx={{ fontSize: 16 }}
                />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => <Typography variant="body2">{rowData.temp_sku_number}</Typography>,
      },
    },
    raw_sku_description: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="body2">{rLIB('Raw SKU Description')}</Typography>
            <Tooltip title={rLIB('This is the SKU description from the import file.')}>
              <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Icon
                  icon="info-circle"
                  sx={{ fontSize: 16 }}
                />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => {
          // Force the value to a string to avoid TypeScript errors on .length/.slice
          const rawSkuDesc = String(rowData.temp_sku_description ?? '')

          return (
            <Tooltip
              title={rawSkuDesc}
              arrow
            >
              <Typography
                variant="body2"
                sx={{ cursor: 'pointer' }}
                className={rawSkuDesc === 'missing' ? 'tw-text-warning_main' : ''}
              >
                {rawSkuDesc.length > 100 ? rawSkuDesc.slice(0, 100) + '...' : rawSkuDesc}
              </Typography>
            </Tooltip>
          )
        },
      },
    },

    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier', 'associated_supplier_name'),
    invoice_date: TableCellBasic('invoice_date', 'Invoice Date', 'invoice_date'),
    invoice_reference_number: TableCellBasic('invoice_reference_number', 'Invoice Number', 'invoice_reference_number'),
    associated_project_id_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>Project Job Code</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Box className="tw-flex tw-items-center">
            <Box className={rowData.associated_project_key === 'missing' ? 'tw-text-warning_main' : ''}>{rowData.associated_project_id_number}</Box>
            {rowData.associated_project_key === 'missing' && (
              <Tooltip
                title={`No valid project found for Job Code "${rowData.associated_project_id_number}". Please check Project Cleanup.`}
                arrow
              >
                <Box>
                  <Icon
                    icon="circle-info"
                    sx={{ color: themeVariables.warning_main }}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        ),
      },
    },
    associated_task_name: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>Task Name</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Tooltip
            title={rowData.associated_task_name || ''}
            arrow
          >
            <Box
              className={rowData.associated_task_name === 'missing' ? 'tw-text-warning_main' : ''}
              sx={{ cursor: 'pointer' }}
            >
              {rowData.associated_task_name && rowData.associated_task_name.length > 30
                ? rowData.associated_task_name.slice(0, 30) + '...'
                : rowData.associated_task_name}
            </Box>
          </Tooltip>
        ),
      },
    },
    // associated_team_name: {
    //   header: {
    //     header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>Team Name</Box>,
    //   },
    //   cell: {
    //     cell_jsx: (rowData: any) => (
    //       <Box className={rowData.associated_team_name === 'missing' ? 'tw-text-warning_main' : ''}>{rowData.associated_team_name}</Box>
    //     ),
    //   },
    // },

    quantity: TableCellBasic('quantity', 'Quantity', 'quantity'),
    unit_price: TableCellCurrency('unit_price', 'Unit Price', 'unit_price'),
    total_price: TableCellCurrency('total_price', 'Total Price', 'total_price'),
  }

  const handleDialogSubmit = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const docId = dialogData.docId || dialogData.key
        if (res_GCK.clientKey) {
          const selectedOption = dialogOptions.find((option) => option && option.key === selectedOptionKey)
          if (!selectedOption) {
            console.error('No valid task selected')
            return
          }
          const updatedData = {
            associated_task_key: selectedOption.value,
            associated_task_name: selectedOption.display,
            associated_team_name: selectedOption.teamName,
            associated_team_key: selectedOption.teamKey,
          }
          if (applyBulkUpdate) {
            // Bulk update: get all docs for the matching project, then filter by invoice.
            const projectKey = dialogData.associated_project_key
            // Assume we have a reference for the project’s collection:
            const projectDocsQuery = DatabaseRef_Material_Imports_By_Project(res_GCK.clientKey, projectKey)
            DatabaseGetCollection(projectDocsQuery)
              .then((res_docs) => {
                const docs = res_docs.success && res_docs.data ? res_docs.data : {}
                // Filter docs by matching invoice number
                const matchingDocs = Object.keys(docs).filter((docKey) => {
                  const doc = docs[docKey]
                  return doc.invoice_reference_number === dialogData.invoice_reference_number
                })
                Promise.all(
                  matchingDocs.map((docKey) => DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(res_GCK.clientKey, docKey), updatedData)),
                )
                  .then(() => setDialogOpen(false))
                  .catch((error) => {
                    console.error('Bulk update failed', error)
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: error.error,
                    })
                  })
              })
              .catch((error) => {
                console.error('Error fetching documents for bulk update', error)
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: error.error,
                })
              })
          } else {
            // Single update.
            if (docId) {
              DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(res_GCK.clientKey, docId), updatedData)
                .then(() => setDialogOpen(false))
                .catch((error) => {
                  console.error('Update failed', error)
                  uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: error.error,
                  })
                })
            } else {
              console.error('Missing docId for single update')
            }
          }
        } else {
          console.error('Missing client key')
        }
      })
      .catch((error) => {
        console.error('Client key error', error)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
      })
  }

  const EditTaskDialog: React.FC = () => (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
    >
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h5"
          className="tw-font-bold tw-mb-4"
        >
          {rLIB('Edit Task')}
        </Typography>
        <Card
          variant="outlined"
          className="tw-mb-4"
        >
          <CardHeader title={rLIB('Import Summary')} />
          <CardContent>
            {/* Display current row data */}
            <Box>
              <Typography variant="body2">
                <strong>{rLIB('Project')}:</strong> {dialogData.associated_project_id_number}
              </Typography>
              <Typography variant="body2">
                <strong>{rLIB('Supplier')}:</strong> {dialogData.associated_supplier_name}
              </Typography>
              <Typography variant="body2">
                <strong>{rLIB('Invoice')}:</strong> {dialogData.invoice_reference_number}
              </Typography>
              <Typography variant="body2">
                <strong>{rLIB('Invoice Date')}:</strong> {dialogData.invoice_date}
              </Typography>
              <Typography variant="body2">
                <strong>{rLIB('SKU Number')}:</strong> {dialogData.sku_number}
              </Typography>
              <Typography variant="body2">
                <strong>{rLIB('SKU Description')}:</strong> {dialogData.sku_description}
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            {/* Display current task details */}
            <Box>
              <Typography variant="body2">
                <strong>{rLIB('Current Task')}:</strong> <strong>{dialogData.associated_task_name}</strong>
              </Typography>
              <Typography variant="body2">
                <strong>{rLIB('Current Team')}:</strong> <strong>{dialogData.associated_team_name}</strong>
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">
                <strong>{rLIB('New Task')}:</strong>{' '}
                <strong>{selectedOptionKey ? dialogOptions.find((opt: any) => opt.key === selectedOptionKey)?.display : 'None Selected'}</strong>
              </Typography>
              <Typography variant="body2">
                <strong>{rLIB('New Team')}:</strong>{' '}
                <strong>{selectedOptionKey ? dialogOptions.find((opt: any) => opt.key === selectedOptionKey)?.teamName : 'None Selected'}</strong>
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            {/* New Task Selection */}
            <Box>
              <FormControl fullWidth>
                <InputLabel id="edit-task-select-label">{rLIB('Select New Task')}</InputLabel>
                <Select
                  labelId="edit-task-select-label"
                  value={selectedOptionKey}
                  onChange={(e) => setSelectedOptionKey(e.target.value as string)}
                  input={<OutlinedInput label={rLIB('Select New Task')} />}
                >
                  {dialogOptions.map((option: any) => (
                    <MenuItem
                      key={option.key}
                      value={option.key}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{option.display}</Typography>
                        {option.timestamp_completed && <Typography sx={{ ml: 1, color: 'white' }}>({option.timestamp_completed})</Typography>}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* Bulk update toggle */}
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={applyBulkUpdate}
                onChange={(e) => setApplyBulkUpdate(e.target.checked)}
                color="primary"
              />
              <Typography
                variant="body2"
                sx={{ ml: 1 }}
              >
                {rLIB('Apply to all rows with matching project & invoice')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="secondary"
          >
            {rLIB('Cancel')}
          </Button>
          <Button
            onClick={handleDialogSubmit}
            color="primary"
            disabled={!selectedOptionKey}
          >
            {rLIB('Submit')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )

  const tableSettings_MaterialImportSessions: TsInterface_TableSettings = {
    paginated: true,
    pagination_rows_per_page_default: 100,
    pagination_rows_per_page_options: [10, 25, 50, 100],
    show_header: true,
    size: 'small',
    sortable: false,
    sort_direction: 'desc',
    sort_property_default: 'timestamp_created',
  }

  const tableSettings_MaterialImports: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'asc',
    sort_property: 'invoice_reference_number', // or another field you wish to sort by
    use_live_data: false,
    conditional_row_styles: [
      {
        className: 'tw-hidden',
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'deleted',
          conditions: [],
        },
      },
    ],

    collapsible_columns: true,
    no_data_message: rLIB('No Imports Found') as JSX.Element,
    searchable: false,
    sticky_header: true,
    sticky_table_height: 'calc(100vh - 180px)',
    alternate_row_colors: true,
    alternate_row_color_hex: themeVariables.background_hover,
    sticky_first_column: false,
    table_file_download_name: 'Material Imports',
  }

  const tableSettings_SKUCleanUp: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'asc',
    sort_property: 'invoice_reference_number', // or another field you wish to sort by
    use_live_data: false,
    conditional_row_styles: [
      {
        className: 'tw-hidden',
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'deleted',
          conditions: [],
        },
      },
    ],
    collapsible_columns: true,
    no_data_message: rLIB('No Imports Found') as JSX.Element,
    searchable: false,
    sticky_header: true,
    sticky_table_height: 'calc(100vh - 180px)',
    alternate_row_colors: true,
    alternate_row_color_hex: themeVariables.background_hover,
    sticky_first_column: false,
    table_file_download_name: 'Material Imports',
  }

  const tableSettings_ProjectsCleanUp: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'asc',
    sort_property: 'invoice_reference_number', // or another field you wish to sort by
    use_live_data: false,
    conditional_row_styles: [
      {
        className: 'tw-hidden',
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'deleted',
          conditions: [],
        },
      },
    ],
    collapsible_columns: true,
    no_data_message: rLIB('No Imports Found') as JSX.Element,
    searchable: false,
    sticky_header: true,
    sticky_table_height: 'calc(100vh - 180px)',
    alternate_row_colors: true,
    alternate_row_color_hex: themeVariables.background_hover,
    sticky_first_column: false,
    table_file_download_name: 'Material Imports',
  }

  const tableSettings_TaskCleanUp: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'asc',
    sort_property: 'invoice_reference_number', // or another field you wish to sort by
    use_live_data: false,
    conditional_row_styles: [
      {
        className: 'tw-hidden',
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'deleted',
          conditions: [],
        },
      },
    ],
    collapsible_columns: true,
    no_data_message: rLIB('No Imports Found') as JSX.Element,
    searchable: false,
    sticky_header: true,
    sticky_table_height: 'calc(100vh - 180px)',
    alternate_row_colors: true,
    alternate_row_color_hex: themeVariables.background_hover,
    sticky_first_column: false,
    table_file_download_name: 'Material Imports',
  }

  const tableColumns_MaterialImports: TsInterface_TableColumns = {
    manage: TableCellManage({
      delete: {
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        label: <>Delete</>, // Removed rLIB since you're moving away from it
        // Only display delete if status is not already "deleted"
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '!=',
          value: 'deleted',
          conditions: [],
        },
        onClick: (rowData: TsInterface_TableDataRow, _tableAdditionalData: TsInterface_UnspecifiedObject, tableHooks: TsInterface_TableHooks) => {
          if (rowData?.key && tableHooks?.uc_RootData_ClientKey) {
            tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                header: 'Delete Import Row', // Removed rLIB
                icon: (
                  <Icon
                    icon="trash"
                    type="solid"
                  />
                ),
                submit_text: 'Delete', // Removed rLIB
                text: 'Are you sure you want to delete this import record?', // Removed rLIB
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    DatabaseDeleteDocument(DatabaseRef_Material_Imports_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string))
                      .then(() => resolve({ success: true }))
                      .catch((error) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                        reject(error)
                      })
                  })
                },
              },
            })
          }
        },
      },
    }),
    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier', 'associated_supplier_name'),
    invoice_date: TableCellBasic('invoice_date', 'Invoice Date', 'invoice_date'),
    invoice_reference_number: TableCellBasic('invoice_reference_number', 'Invoice Number', 'invoice_reference_number'),
    associated_po_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rLIB('PO Number')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Box sx={{ color: rowData.associated_po_number === 'missing' ? themeVariables.warning_main : 'inherit' }}>{rowData.associated_po_number}</Box>
        ),
      },
    },

    associated_project_id_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>Project Job Code</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Box className="tw-flex tw-items-center">
            <Box className={rowData.associated_project_key === 'missing' ? 'tw-text-yellow-600' : ''}>{rowData.associated_project_id_number}</Box>
            {rowData.associated_project_key === 'missing' && (
              <Icon
                icon="circle-info"
                className="tw-ml-2 tw-cursor-pointer"
                sx={{ color: themeVariables.warning_main }}
                tooltip={`We could not find a valid project for Job Code "${rowData.associated_project_id_number}". Please go to the Project Cleanup tab to fix this.`}
              />
            )}
          </Box>
        ),
      },
    },

    associated_task_name: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>Task Name</Box>,
      },
      cell: {
        cell_jsx: (rowData: any, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => (
          <Tooltip
            title={rowData.associated_task_name || ''}
            arrow
          >
            <Box
              className={rowData.associated_task_name === 'missing' ? 'tw-text-warning_main' : ''}
              sx={{ cursor: 'pointer' }}
            >
              {rowData.associated_task_name?.length > 30 ? rowData.associated_task_name.slice(0, 30) + '...' : rowData.associated_task_name}
            </Box>
          </Tooltip>
        ),
      },
    },

    associated_team_name: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>Team Name</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Box className={rowData.associated_team_name === 'missing' ? 'tw-text-warning_main' : ''}>{rowData.associated_team_name}</Box>
        ),
      },
    },

    sku_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rLIB('SKU Number')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => <Box className={rowData.sku_number === 'missing' ? 'tw-text-warning_main' : ''}>{rowData.sku_number}</Box>,
      },
    },
    sku_description: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rLIB('SKU Description')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Tooltip
            title={rowData.sku_description || ''}
            arrow
          >
            <Box
              sx={{ cursor: 'pointer' }}
              className={rowData.sku_description === 'missing' ? 'tw-text-warning_main' : ''}
            >
              {rowData.sku_description && rowData.sku_description.length > 30 ? rowData.sku_description.slice(0, 30) + '...' : rowData.sku_description}
            </Box>
          </Tooltip>
        ),
      },
    },

    quantity: TableCellBasic('quantity', 'Quantity', 'quantity'),
    unit_price: TableCellCurrency('unit_price', 'Unit Price', 'unit_price'),
    item_amount: {
      ...TableCellCurrency('item_amount', 'Item Amount', 'item_amount'),
      header: {
        header_jsx: () => (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Box component="span">Item Amount</Box>
            <Icon
              icon="circle-info"
              sx={{ ml: 1, display: 'inline-flex' }}
              tooltip="Item Amount is calculated as Quantity multiplied by Unit Price"
            />
          </Box>
        ),
      },
    },

    taxes: TableCellCurrency('taxes', 'Taxes', 'taxes'),
    shipping_costs: TableCellCurrency('shipping_costs', 'Shipping Costs', 'shipping_costs'),
    total_price: TableCellCurrency('total_price', 'Total Price', 'total_price'),
    ship_date: TableCellBasic('ship_date', 'Ship Date', 'ship_date'),
    due_by: TableCellBasic('due_by', 'Due By', 'due_by'),
    ship_mode: TableCellBasic('ship_mode', 'Ship Mode', 'ship_mode'),
  }

  const tableColumns_MaterialImportSessions: TsInterface_TableColumns = {
    manage: TableCellManage({
      // ------------------------------------
      // 1) EXPORT ACTION
      // ------------------------------------
      exportSession: {
        icon: (
          <Icon
            icon="file-arrow-down"
            type="solid"
          />
        ),
        label: <>Export</>,
        onClick: (rowData: TsInterface_TableDataRow, _tableAdditionalData: TsInterface_UnspecifiedObject, tableHooks: TsInterface_TableHooks) => {
          if (!rowData?.key) return
          const sessionId = rowData.key as string

          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              const clientKey = res_GCK.clientKey
              if (!clientKey) {
                console.error('No clientKey found in getClientKey (Export session).')
                return
              }

              const refMain = collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main')
              const qSessionItems = query(refMain, where('importSessionId', '==', sessionId))

              DatabaseGetCollection(qSessionItems)
                .then((res_DGC) => {
                  if (!res_DGC.success) {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: res_DGC.error,
                    })
                    return
                  }

                  const docsObj = res_DGC.data || {}
                  const docIds = Object.keys(docsObj)

                  if (docIds.length === 0) {
                    console.warn(`No line items found for importSessionId: ${sessionId}`)
                    return
                  }

                  const csvRows: string[][] = []

                  // Header Row
                  csvRows.push([
                    'PO Number',
                    'Project ID',
                    'Project Key',
                    'SKU Key',
                    'Supplier Key',
                    'Supplier Name',
                    'Task Key',
                    'Task Name',
                    'Team Key',
                    'Team Name',
                    'Due By',
                    'Import Session ID',
                    'Invoice Date',
                    'Invoice Number',
                    'Item Amount',
                    'Key',
                    'Quantity',
                    'Ship Date',
                    'Ship Mode',
                    'Shipping Costs',
                    'SKU Description',
                    'SKU Number',
                    'Status',
                    'Taxes',
                    'Temp SKU Description',
                    'Temp SKU Number',
                    'Total Price',
                    'Unit Price',
                  ])

                  // Data Rows
                  docIds.forEach((docId) => {
                    const item = docsObj[docId] || {}

                    csvRows.push([
                      item.associated_po_number || '',
                      item.associated_project_id_number || '',
                      item.associated_project_key || '',
                      item.associated_sku_key || '',
                      item.associated_supplier_key || '',
                      item.associated_supplier_name || '',
                      item.associated_task_key || '',
                      item.associated_task_name || '',
                      item.associated_team_key || '',
                      item.associated_team_name || '',
                      item.due_by || '',
                      item.importSessionId || '',
                      item.invoice_date || '',
                      item.invoice_reference_number || '',
                      String(item.item_amount ?? ''),
                      item.key || '',
                      String(item.quantity ?? ''),
                      item.ship_date || '',
                      item.ship_mode || '',
                      String(item.shipping_costs ?? ''),
                      item.sku_description || '',
                      item.sku_number || '',
                      item.status || '',
                      String(item.taxes ?? ''),
                      item.temp_sku_description || '',
                      item.temp_sku_number || '',
                      String(item.total_price ?? ''),
                      String(item.unit_price ?? ''),
                    ])
                  })

                  const fileName = `Session_${sessionId}_Export`
                  downloadCSV(fileName, csvRows)
                    .then(() => console.log(`Export complete for session ${sessionId}`))
                    .catch((errDC) => {
                      console.error('Error during CSV download:', errDC)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                        display: true,
                        error: errDC,
                      })
                    })
                })
                .catch((errQuery) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: errQuery?.error || errQuery,
                  })
                })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: rej_GCK?.error || rej_GCK,
              })
            })
        },
      },

      // ------------------------------------
      // 2) DELETE ACTION (no changes here)
      // ------------------------------------
      delete: {
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        label: <>Delete All Items from this Session</>,
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '!=',
          value: 'deleted',
          conditions: [],
        },
        onClick: (rowData, _tableAdditionalData, tableHooks) => {
          if (!rowData?.key) return
          const sessionId = rowData.key

          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              const clientKey = res_GCK.clientKey
              if (!clientKey) return

              const refMain = collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main')
              const qSessionItems = query(refMain, where('importSessionId', '==', sessionId))

              DatabaseGetCollection(qSessionItems)
                .then((res_DGC) => {
                  if (!res_DGC.success) {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: res_DGC.error,
                    })
                    return
                  }

                  const docsObj = res_DGC.data || {}
                  const docIds = Object.keys(docsObj)

                  if (docIds.length === 0) {
                    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        header: 'Delete Session',
                        icon: (
                          <Icon
                            icon="trash"
                            type="solid"
                          />
                        ),
                        submit_text: 'Delete',
                        text: 'No line items found for this session. Delete session doc anyway?',
                        submit_callback: () =>
                          new Promise((resolve, reject) => {
                            DatabaseDeleteDocument(DatabaseRef_Material_Imports_Session_Document(clientKey, sessionId as any))
                              .then(() => resolve({ success: true }))
                              .catch(reject)
                          }),
                      },
                    })
                  } else {
                    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        header: 'Delete Session & All Items',
                        icon: (
                          <Icon
                            icon="trash"
                            type="solid"
                          />
                        ),
                        submit_text: 'Delete',
                        text: `Found ${docIds.length} line item(s) in this session. Are you sure you want to delete them all?`,
                        submit_callback: () =>
                          new Promise((resolve, reject) => {
                            const batchActions: any = [
                              ...docIds.map((docId) => ({
                                type: 'delete',
                                ref: DatabaseRef_Material_Imports_Document(clientKey, docId),
                              })),
                              {
                                type: 'delete',
                                ref: DatabaseRef_Material_Imports_Session_Document(clientKey, sessionId as any),
                              },
                            ]

                            DatabaseBatchUpdate(batchActions)
                              .then(() => resolve({ success: true }))
                              .catch(reject)
                          }),
                      },
                    })
                  }
                })
                .catch((errQuery) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: errQuery?.error || errQuery,
                  })
                })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: rej_GCK?.error || rej_GCK,
              })
            })
        },
      },
    }),

    timestamp_created: TableCellTimestamp('timestamp_created', 'Imported At', 'timestamp_created', 'YYYY/MM/DD hh:mm A', true),

    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier Name', 'associated_supplier_name'),
    associated_user_name: TableCellBasic('associated_user_name', 'Imported By', 'associated_user_name'),
    total_rows_imported: TableCellBasic('total_rows_imported', 'Rows Imported', 'total_rows_imported'),
    duplicates_count: TableCellBasic('duplicates_count', 'Duplicates Attempted to Import', 'duplicates_count'),
  }

  const tableColumns_MaterialImportsProjectCleanUp: TsInterface_TableColumns = {
    manage: TableCellManage({
      delete: {
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        label: <>Delete</>, // Removed rLIB since you're moving away from it
        // Only display delete if status is not already "deleted"
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '!=',
          value: 'deleted',
          conditions: [],
        },
        onClick: (rowData: TsInterface_TableDataRow, _tableAdditionalData: TsInterface_UnspecifiedObject, tableHooks: TsInterface_TableHooks) => {
          if (rowData?.key && tableHooks?.uc_RootData_ClientKey) {
            tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                header: 'Delete Import Row', // Removed rLIB
                icon: (
                  <Icon
                    icon="trash"
                    type="solid"
                  />
                ),
                submit_text: 'Delete', // Removed rLIB
                text: 'Are you sure you want to delete this import record?', // Removed rLIB
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    DatabaseDeleteDocument(DatabaseRef_Material_Imports_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string))
                      .then(() => resolve({ success: true }))
                      .catch((error) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                        reject(error)
                      })
                  })
                },
              },
            })
          }
        },
      },
    }),
    associated_project_id_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>Project Job Code</Box>,
      },
      cell: {
        cell_jsx: (rowData, tableAdditionalData, tableHooks) => (
          <Box className="tw-flex tw-items-center tw-gap-2">
            <Box>{rowData.associated_project_id_number}</Box>
            <Icon
              icon="pen-to-square"
              className="tw-cursor-pointer tw-text-info_main tw-opacity-80 hover:tw-opacity-100"
              tooltip={rLIB('Assign Project')}
              onClick={() => {
                openProjectSearchDialogForRow(rowData, tableHooks)
              }}
            />
          </Box>
        ),
      },
    },

    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier', 'associated_supplier_name'),
    ordered_by: TableCellBasic('ordered_by', 'Ordered By', 'ordered_by'),
    invoice_date: TableCellBasic('invoice_date', 'Invoice Date', 'invoice_date'),
    invoice_reference_number: TableCellBasic('invoice_reference_number', 'Invoice Number', 'invoice_reference_number'),
    sku_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rLIB('SKU Number')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => <Box className={rowData.sku_number === 'missing' ? 'tw-text-warning_main' : ''}>{rowData.sku_number}</Box>,
      },
    },
    sku_description: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rLIB('SKU Description')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Tooltip
            title={rowData.sku_description || ''}
            arrow
          >
            <Box
              sx={{ cursor: 'pointer' }}
              className={rowData.sku_description === 'missing' ? 'tw-text-warning_main' : ''}
            >
              {rowData.sku_description && rowData.sku_description.length > 30 ? rowData.sku_description.slice(0, 30) + '...' : rowData.sku_description}
            </Box>
          </Tooltip>
        ),
      },
    },
    raw_sku_number: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="body2">{rLIB('Raw SKU Number')}</Typography>
            <Tooltip title={rLIB('This is the SKU number from the import file and did not have a match with an existing SKU Number for the supplier in EOS.')}>
              <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Icon
                  icon="info-circle"
                  sx={{ fontSize: 16 }}
                />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => <Typography variant="body2">{rowData.temp_sku_number}</Typography>,
      },
    },
    raw_sku_description: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="body2">{rLIB('Raw SKU Description')}</Typography>
            <Tooltip title={rLIB('This is the SKU description from the import file.')}>
              <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Icon
                  icon="info-circle"
                  sx={{ fontSize: 16 }}
                />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => {
          // Force the value to a string to avoid TypeScript errors on .length/.slice
          const rawSkuDesc = String(rowData.temp_sku_description ?? '')

          return (
            <Tooltip
              title={rawSkuDesc}
              arrow
            >
              <Typography
                variant="body2"
                sx={{ cursor: 'pointer' }}
                className={rawSkuDesc === 'missing' ? 'tw-text-warning_main' : ''}
              >
                {rawSkuDesc.length > 30 ? rawSkuDesc.slice(0, 30) + '...' : rawSkuDesc}
              </Typography>
            </Tooltip>
          )
        },
      },
    },

    quantity: TableCellBasic('quantity', 'Quantity', 'quantity'),
    unit_price: TableCellCurrency('unit_price', 'Unit Price', 'unit_price'),
    total_price: TableCellCurrency('total_price', 'Total Price', 'total_price'),
  }

  const tableColumns_TaskCleanUp: TsInterface_TableColumns = {
    manage: TableCellManage({
      delete: {
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        label: <>Delete</>, // Removed rLIB since you're moving away from it
        // Only display delete if status is not already "deleted"
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '!=',
          value: 'deleted',
          conditions: [],
        },
        onClick: (rowData: TsInterface_TableDataRow, _tableAdditionalData: TsInterface_UnspecifiedObject, tableHooks: TsInterface_TableHooks) => {
          if (rowData?.key && tableHooks?.uc_RootData_ClientKey) {
            tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                header: 'Delete Import Row', // Removed rLIB
                icon: (
                  <Icon
                    icon="trash"
                    type="solid"
                  />
                ),
                submit_text: 'Delete', // Removed rLIB
                text: 'Are you sure you want to delete this import record?', // Removed rLIB
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    DatabaseDeleteDocument(DatabaseRef_Material_Imports_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string))
                      .then(() => resolve({ success: true }))
                      .catch((error) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                        reject(error)
                      })
                  })
                },
              },
            })
          }
        },
      },
    }),
    associated_task_name: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rLIB('Task Name')}</Box>,
        header_sort_by: 'associated_task_name',
      },
      cell: {
        cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
          return (
            <Box className="tw-flex tw-items-center">
              {/* Display the associated task name */}
              <Box className={rowData.associated_task_name === 'missing' ? 'tw-text-warning_main' : 'tw-text-white'}>
                {rowData.associated_task_name}

                {/* If timestamp_completed is present, display it in white text */}
                {rowData.timestamp_completed && <span className="tw-ml-2 tw-text-sm tw-text-white">{rowData.timestamp_completed}</span>}
              </Box>

              {/* If there's a valid project key, show the edit icon */}
              {rowData.associated_project_key !== 'missing' ? (
                <Icon
                  icon="pen-to-square"
                  onClick={() => {
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        const projectKey = rowData.associated_project_key
                        if (!projectKey) {
                          console.error('No project key found in row data:', rowData)
                          return
                        }

                        const tasksQuery = DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, projectKey as any)

                        DatabaseGetCollection(tasksQuery)
                          .then((res_tasks) => {
                            const tasksMapping = res_tasks.success && res_tasks.data ? res_tasks.data : {}
                            const tasksOptionsRaw = Object.keys(tasksMapping).map((taskKey, index) => {
                              const task = tasksMapping[taskKey]
                              const computedTeamKey = task.associated_team_key || task.team_key || ''
                              if (!computedTeamKey || !task.associated_task_key) return null

                              // Convert Firestore Timestamp to date string (only date, no time)
                              let formattedDate = ''
                              if (task.timestamp_completed && typeof task.timestamp_completed === 'object' && 'seconds' in task.timestamp_completed) {
                                formattedDate = new Date(task.timestamp_completed.seconds * 1000).toLocaleDateString()
                              } else {
                                formattedDate = task.timestamp_completed || ''
                              }

                              return {
                                key: `${taskKey}_${index}`,
                                value: taskKey,
                                display: task.name || task.associated_task_name || taskKey,
                                teamName: task.associated_team_name || task.team_name || '',
                                teamKey: computedTeamKey,
                                timestamp_completed: formattedDate,
                              }
                            })

                            const tasksOptions = tasksOptionsRaw.filter((option) => option !== null)
                            tasksOptions.unshift({
                              key: 'default',
                              value: '',
                              display: 'Select a Task',
                              teamName: '',
                              teamKey: '',
                              timestamp_completed: '',
                            })

                            const initialOption = tasksOptions.find((opt) => opt && opt.value === rowData.associated_task_key)
                            const initialTaskOptionKey = initialOption ? initialOption.key : ''

                            setDialogData(rowData)
                            setDialogOptions(tasksOptions)
                            setSelectedOptionKey(initialTaskOptionKey)
                            // Reset the bulk toggle each time the dialog opens.
                            setApplyBulkUpdate(false)
                            setDialogOpen(true)
                          })
                          .catch((error) => {
                            console.error('Error fetching tasks:', error)
                          })
                      })
                      .catch((error) => {
                        console.error('Error getting client key:', error)
                      })
                  }}
                  className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
                  tooltip={rLIB('Edit')}
                  tooltipPlacement="right"
                />
              ) : (
                <Icon
                  icon="circle-info"
                  className="tw-ml-2 tw-cursor-pointer"
                  sx={{ color: themeVariables.warning_main }}
                  tooltip="This task cannot be assigned because the project is missing. Please go to the Project Cleanup tab first."
                  tooltipPlacement="right"
                />
              )}
            </Box>
          )
        },
      },
    },
    associated_supplier_name: TableCellBasic('associated_supplier_name', 'Supplier', 'associated_supplier_name'),
    ordered_by: TableCellBasic('ordered_by', 'Ordered By', 'ordered_by'),
    invoice_date: TableCellBasic('invoice_date', 'Date', 'invoice_date'),
    invoice_reference_number: TableCellBasic('invoice_reference_number', 'Invoice Ref', 'invoice_reference_number'),
    associated_project_id_number: TableCellBasic('associated_project_id_number', 'Project Job Code', 'associated_project_id_number'),
    sku_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rLIB('SKU Number')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => <Box className={rowData.sku_number === 'missing' ? 'tw-text-warning_main' : ''}>{rowData.sku_number}</Box>,
      },
    },
    sku_description: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>{rLIB('SKU Description')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => (
          <Tooltip
            title={rowData.sku_description || ''}
            arrow
          >
            <Box
              sx={{ cursor: 'pointer' }}
              className={rowData.sku_description === 'missing' ? 'tw-text-warning_main' : ''}
            >
              {rowData.sku_description && rowData.sku_description.length > 30 ? rowData.sku_description.slice(0, 30) + '...' : rowData.sku_description}
            </Box>
          </Tooltip>
        ),
      },
    },

    raw_sku_number: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="body2">{rLIB('Raw SKU Number')}</Typography>
            <Tooltip title={rLIB('This is the SKU number from the import file and did not have a match with an existing SKU Number for the supplier in EOS.')}>
              <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Icon
                  icon="info-circle"
                  sx={{ fontSize: 16 }}
                />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => <Typography variant="body2">{rowData.temp_sku_number}</Typography>,
      },
    },
    raw_sku_description: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="body2">{rLIB('Raw SKU Description')}</Typography>
            <Tooltip title={rLIB('This is the SKU description from the import file.')}>
              <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Icon
                  icon="info-circle"
                  sx={{ fontSize: 16 }}
                />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => {
          // Force the value to a string to avoid TypeScript errors on .length/.slice
          const rawSkuDesc = String(rowData.temp_sku_description ?? '')

          return (
            <Tooltip
              title={rawSkuDesc}
              arrow
            >
              <Typography
                variant="body2"
                sx={{ cursor: 'pointer' }}
                className={rawSkuDesc === 'missing' ? 'tw-text-warning_main' : ''}
              >
                {rawSkuDesc.length > 30 ? rawSkuDesc.slice(0, 30) + '...' : rawSkuDesc}
              </Typography>
            </Tooltip>
          )
        },
      },
    },

    quantity: TableCellBasic('quantity', 'Quantity', 'quantity'),
    unit_price: TableCellCurrency('unit_price', 'Unit Price', 'unit_price'),
    total_price: TableCellCurrency('total_price', 'Total Price', 'total_price'),
  }

  const ProjectChangeDialog = ({ rowData, tableHooks, clientKey }: { rowData: any; tableHooks: any; clientKey: any }) => {
    const [newProject, setNewProject] = useState<any>(null)
    const [applyBulkUpdate, setApplyBulkUpdate] = useState<boolean>(false)
    const [specialAssignment, setSpecialAssignment] = useState<string>('none') // 'none', 'truck_stock', or 'tools'
    const [selectedRegion, setSelectedRegion] = useState<any>(null)

    const handleProjectSelect = (option: any) => {
      setNewProject(option)
      setSpecialAssignment('none')
      setSelectedRegion(null)
    }

    const handleSubmit = () => {
      if (!newProject && specialAssignment === 'none') {
        console.error('No new project selected or special assignment')
        return
      }

      // Require region selection for special assignments
      if ((specialAssignment === 'truck_stock' || specialAssignment === 'tools') && !selectedRegion) {
        console.error('Region selection is required for Truck Stock or Tools assignment')
        return
      }

      let updatedData: any

      if (specialAssignment === 'truck_stock') {
        updatedData = {
          associated_project_id_number: 'Truck Stock',
          associated_project_key: 'truck_stock',
          associated_task_key: 'truck_stock',
          associated_task_name: 'Truck Stock',
          associated_region_key: selectedRegion?.key || '',
          associated_region_name: selectedRegion?.name || '',
        }
      } else if (specialAssignment === 'tools') {
        updatedData = {
          associated_project_id_number: 'Tools',
          associated_project_key: 'tools',
          associated_task_key: 'tools',
          associated_task_name: 'Tools',
          associated_region_key: selectedRegion?.key || '',
          associated_region_name: selectedRegion?.name || '',
        }
      } else {
        updatedData = {
          associated_project_id_number: newProject.id_number,
          associated_project_key: newProject.id,
        }
      }

      if (applyBulkUpdate) {
        const invoiceNumber = rowData.invoice_reference_number

        // Query by invoice number.
        const invoiceDocsQuery = DatabaseRef_Material_Imports_By_Invoice_Number(clientKey, invoiceNumber)
        DatabaseGetCollection(invoiceDocsQuery)
          .then((res_docs) => {
            const docs = res_docs.success && res_docs.data ? res_docs.data : {}
            // Filter client-side: only those with the current row's project id.
            const matchingDocKeys = Object.keys(docs).filter((docKey) => {
              const doc = docs[docKey]

              return doc.associated_project_id_number === rowData.associated_project_id_number
            })

            Promise.all(
              matchingDocKeys.map((docKey) => {
                return DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(clientKey, docKey), updatedData)
              }),
            )
              .then((res) => {
                tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              })
              .catch((error) => {
                console.error('Bulk update failed', error)
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
              })
          })
          .catch((error) => {
            console.error('Error fetching documents for bulk update', error)
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
          })
      } else {
        // Single update.
        const docId = rowData.docId || rowData.key

        if (!docId) {
          console.error('No docId found on row.')
          return
        }
        DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Document(clientKey, docId), updatedData)
          .then((res) => {
            // Update the local row data based on assignment type
            if (specialAssignment === 'truck_stock') {
              rowData.associated_project_id_number = 'Truck Stock'
              rowData.associated_project_key = 'truck_stock'
              rowData.associated_task_key = 'truck_stock'
              rowData.associated_task_name = 'Truck Stock'
              if (selectedRegion) {
                rowData.associated_region_key = selectedRegion.key
                rowData.associated_region_name = selectedRegion.name
              }
            } else if (specialAssignment === 'tools') {
              rowData.associated_project_id_number = 'Tools'
              rowData.associated_project_key = 'tools'
              rowData.associated_task_key = 'tools'
              rowData.associated_task_name = 'Tools'
              if (selectedRegion) {
                rowData.associated_region_key = selectedRegion.key
                rowData.associated_region_name = selectedRegion.name
              }
            } else if (newProject) {
              rowData.associated_project_id_number = newProject.id_number
              rowData.associated_project_key = newProject.id
            }

            tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          })
          .catch((error: any) => {
            console.error('Error updating document with new project', error)
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
          })
      }
    }

    return (
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', mb: 4 }}
        >
          Material Assignment
        </Typography>
        <Card
          variant="outlined"
          sx={{ mb: 4 }}
        >
          <CardHeader title="Material Details" />
          <CardContent>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
              >
                <Typography variant="body2">
                  <strong>Supplier:</strong> {rowData.associated_supplier_name}
                </Typography>
                <Typography variant="body2">
                  <strong>Invoice:</strong> {rowData.invoice_reference_number}
                </Typography>
                <Typography variant="body2">
                  <strong>Date:</strong> {rowData.invoice_date}
                </Typography>
                <Typography variant="body2">
                  <strong>SKU Number:</strong> {rowData.sku_number}
                </Typography>
                <Typography variant="body2">
                  <strong>SKU Description:</strong> {rowData.sku_description}
                </Typography>
                <Typography variant="body2">
                  <strong>Quantity:</strong> {rowData.quantity}
                </Typography>
                <Typography variant="body2">
                  <strong>Total Price:</strong> {rowData.total_price}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pt: { xs: 1, md: 0 } }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, fontWeight: 'bold' }}
                  >
                    Assignment Status
                  </Typography>
                  <Typography variant="body2">
                    <strong>Current Project:</strong> <strong>{rowData.associated_project_id_number}</strong>
                  </Typography>

                  {specialAssignment !== 'none' || newProject ? (
                    <Box
                      sx={{
                        mt: 1,
                        p: 1.5,
                        bgcolor: themeVariables.primary_main,
                        color: 'white',
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: themeVariables.primary_main,
                      }}
                    >
                      <Typography variant="body1">
                        <strong>New Project:</strong>{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {specialAssignment === 'truck_stock'
                            ? 'Truck Stock'
                            : specialAssignment === 'tools'
                              ? 'Tools'
                              : newProject
                                ? newProject.id_number
                                : 'None Selected'}
                        </span>
                      </Typography>

                      {(specialAssignment === 'truck_stock' || specialAssignment === 'tools') && selectedRegion && (
                        <Typography
                          variant="body2"
                          sx={{ mt: 0.5 }}
                        >
                          <strong>Region:</strong> {selectedRegion.name}
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    <Typography variant="body2">
                      <strong>New Assignment:</strong> <em>None Selected</em>
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 4 }}>
          {/* Project Assignment Section */}
          <Card
            variant="outlined"
            sx={{
              mb: 2,
              opacity: specialAssignment !== 'none' ? 0.6 : 1,
              transition: 'opacity 0.2s',
            }}
          >
            <CardHeader
              title="Project Assignment"
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent>
              <Typography
                variant="body2"
                sx={{ mb: 2 }}
              >
                Assign this material to a specific project
              </Typography>

              {!newProject ? (
                <SearchInput
                  clientKey={clientKey}
                  searchIndexKey={'projects'}
                  searchFilters={[]}
                  searchResultRenderer={(option: any, searchInputValue: string | null) => (
                    <Box sx={{ p: 1.5, my: 0.5, border: '1px solid', borderColor: 'divider', borderRadius: 1, bgcolor: 'background.default' }}>
                      <Typography variant="body2">
                        <strong>Job Code:</strong> {option.id_number}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Customer:</strong> {option.associated_customer_name || 'N/A'}
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        sx={{ mt: 1 }}
                        onClick={() => handleProjectSelect(option)}
                      >
                        Select Project
                      </Button>
                    </Box>
                  )}
                  additionalSearchData={{}}
                  sx={{ width: '100%' }}
                />
              ) : (
                <Box sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      width: '100%',
                      p: 2,
                      mb: 1.5,
                      bgcolor: themeVariables.primary_main,
                      color: 'white',
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: themeVariables.primary_main,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ color: 'white' }}
                    >
                      <strong>Selected Project:</strong> <span style={{ fontWeight: 'bold' }}>{newProject.id_number}</span>
                      {newProject.associated_customer_name && (
                        <span style={{ display: 'inline-block', marginLeft: '8px' }}>
                          (<strong>Customer:</strong> {newProject.associated_customer_name})
                        </span>
                      )}
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setNewProject(null)}
                    startIcon={<Icon icon="xmark" />}
                    disabled={specialAssignment !== 'none'}
                    size="small"
                  >
                    Clear Project
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>

          {/* Truck Stock / Tools Section */}
          <Card
            variant="outlined"
            sx={{
              mb: 2,
              opacity: newProject ? 0.6 : 1,
              transition: 'opacity 0.2s',
            }}
          >
            <CardHeader
              title="Material Type Assignment"
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent>
              <Typography
                variant="body2"
                sx={{ mb: 2 }}
              >
                Assign as truck stock or tools material
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {specialAssignment !== 'none' ? (
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        p: 1.5,
                        bgcolor: themeVariables.primary_main,
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: themeVariables.primary_main,
                        mb: 1.5,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: 'white' }}
                      >
                        <strong>Selected Material Type:</strong>{' '}
                        <span style={{ fontWeight: 'bold' }}>{specialAssignment === 'truck_stock' ? 'Truck Stock' : 'Tools'}</span>
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => {
                        setSpecialAssignment('none')
                        setSelectedRegion(null)
                      }}
                      startIcon={<Icon icon="xmark" />}
                    >
                      Clear Selection
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setSpecialAssignment('truck_stock')
                        setNewProject(null)
                      }}
                      disabled={!!newProject}
                      sx={{ flexGrow: 1 }}
                      size="small"
                      startIcon={<Icon icon="truck" />}
                    >
                      Truck Stock
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setSpecialAssignment('tools')
                        setNewProject(null)
                      }}
                      disabled={!!newProject}
                      sx={{ flexGrow: 1 }}
                      size="small"
                      startIcon={<Icon icon="screwdriver-wrench" />}
                    >
                      Tools
                    </Button>
                  </Box>
                )}

                {(specialAssignment === 'truck_stock' || specialAssignment === 'tools') && (
                  <FormControl
                    fullWidth
                    required
                    sx={{ mt: 1 }}
                  >
                    <InputLabel id="region-select-label">Select Region</InputLabel>
                    <Select
                      labelId="region-select-label"
                      value={selectedRegion ? selectedRegion.key : ''}
                      onChange={(e) => {
                        const regionKey = e.target.value
                        const selectedRegionData = regionsData[regionKey]
                        setSelectedRegion(
                          selectedRegionData
                            ? {
                                key: regionKey,
                                name: selectedRegionData.name || selectedRegionData.id_number,
                              }
                            : null,
                        )
                      }}
                      input={<OutlinedInput label="Select Region" />}
                    >
                      {Object.entries(regionsData).map(([key, region]: [string, any]) => (
                        <MenuItem
                          key={key}
                          value={key}
                        >
                          {region.name || region.id_number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
        {false && (
          <Card
            variant="outlined"
            sx={{ mb: 4 }}
          >
            <CardHeader title="Search for New Project" />
            <CardContent>
              <SearchInput
                clientKey={clientKey}
                searchIndexKey={'projects'}
                searchFilters={[]}
                searchResultRenderer={(option: any, searchInputValue: string | null, inputHooks: any, additionalSearchData: any): JSX.Element => (
                  <Box sx={{ ml: 2, mr: 2, mb: 1 }}>
                    <Typography variant="body2">
                      {rLIB('Job Code')}: {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}
                    </Typography>
                    <Typography variant="body2">
                      {rLIB('Customer')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_customer_name)}
                    </Typography>
                    <Button
                      variant="contained"
                      color="info"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        handleProjectSelect(option)
                      }}
                    >
                      {rLIB('Select Project')}
                    </Button>
                    <Divider sx={{ my: 1 }} />
                  </Box>
                )}
                additionalSearchData={{}}
                sx={{ width: '100%', background: themeVariables.background_json }}
              />
            </CardContent>
          </Card>
        )}
        <Card
          variant="outlined"
          sx={{ mb: 4 }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Switch
                checked={applyBulkUpdate}
                onChange={(e) => {
                  setApplyBulkUpdate(e.target.checked)
                }}
                color="primary"
              />
              <Typography
                variant="body2"
                sx={{ ml: 1 }}
              >
                Apply to all rows with matching project & invoice
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={handleSubmit}
              disabled={
                (!newProject && specialAssignment === 'none') || ((specialAssignment === 'truck_stock' || specialAssignment === 'tools') && !selectedRegion)
              }
              sx={{ py: 1.2 }}
            >
              {specialAssignment === 'truck_stock'
                ? 'Assign to Truck Stock'
                : specialAssignment === 'tools'
                  ? 'Assign to Tools'
                  : 'Submit New Project Assignment'}
            </Button>
          </CardContent>
        </Card>
      </Box>
    )
  }

  const openProjectSearchDialogForRow = (rowData: any, tableHooks: any) => {
    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
      .then((res_GCK: any) => {
        tableHooks.uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: (
              <Dialog
                open={true}
                onClose={() => {
                  tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                }}
              >
                <ProjectChangeDialog
                  rowData={rowData}
                  tableHooks={tableHooks}
                  clientKey={res_GCK.clientKey}
                />
              </Dialog>
            ),
            settings: { max_width: 'lg' },
          },
        })
        setTimeout(() => {
          const searchInput = document.getElementById('search_input')

          searchInput?.focus()
        }, 100)
      })
      .catch((rej_GCK: any) => {
        console.error('Error retrieving client key in openProjectSearchDialogForRow:', rej_GCK)
        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const rJSX_materialImports = (): JSX.Element => {
    const tableAdditionalData = {
      selectedProject,
    }
    return (
      <Card className=" ">
        {uc_RootData_ClientKey ? (
          <TableDatabase
            tableAdditionalData={tableAdditionalData}
            tableColumns={tableColumns_MaterialImports}
            tableDatabaseEndpoint={tableDatabaseEndpoint_MaterialImports}
            tableSettings={tableSettings_MaterialImports}
          />
        ) : (
          <Typography>{rLIB('Loading...')}</Typography>
        )}
      </Card>
    )
  }

  const rJSX_taskCleanUp = (): JSX.Element => {
    return (
      <Card className=" ">
        {uc_RootData_ClientKey ? (
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_TaskCleanUp}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ImportsMissingTasks}
            tableSettings={tableSettings_TaskCleanUp}
          />
        ) : (
          <Typography>{rLIB('Loading...')}</Typography>
        )}
      </Card>
    )
  }

  const rJSX_deleteImports = (): JSX.Element => {
    const handleDeleteAllByInvoice = () => {
      if (!invoiceNumber) return

      const numericValue = parseInt(invoiceNumber, 10)
      const isNumericInvoice = !isNaN(numericValue)

      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK: any) => {
          const stringQuery = DatabaseRef_Material_Imports_By_Invoice_Number(res_GCK.clientKey, invoiceNumber)

          const queryPromises: Promise<any>[] = []
          // Always run string query
          queryPromises.push(DatabaseGetCollection(stringQuery))

          // If parseable as a number, run numeric query
          if (isNumericInvoice) {
            const numericQuery = DatabaseRef_Material_Imports_By_Invoice_Number_AsNumber(res_GCK.clientKey, numericValue)
            queryPromises.push(DatabaseGetCollection(numericQuery))
          }

          return Promise.all(queryPromises)
        })
        .then((resultsArray: any[]) => {
          const tempCombinedDocs: Record<string, any> = {}

          resultsArray.forEach((result) => {
            if (result.success) {
              const dataObj = result.data
              Object.keys(dataObj).forEach((docId) => {
                tempCombinedDocs[docId] = dataObj[docId]
              })
            }
          })

          if (Object.keys(tempCombinedDocs).length === 0) {
            // Open a "No Documents Found" dialog instead of confirming deletion
            setOpenNoDocsDialog(true)
            return
          }

          // Store docs in state, open the confirmation dialog
          setCombinedDocs(tempCombinedDocs)
          setOpenConfirm(true)
        })
        .catch((err: any) => {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: err?.error || err,
          })
        })
    }

    const handleOpenSupplierDeleteDialog = () => {
      setOpenSupplierDeleteDialog(true)
    }

    const handleCloseSupplierDeleteDialog = () => {
      setOpenSupplierDeleteDialog(false)
      setSelectedSupplierForDelete(null)
    }

    const handleSupplierSelect = (supplier: Supplier | null) => {
      setSelectedSupplierForDelete(supplier)
      setSupplierConfirmationInput('')
    }

    const handleDeleteAllBySupplier = () => {
      if (!selectedSupplierForDelete) return

      // Check if the confirmation input matches the supplier name
      if (supplierConfirmationInput !== selectedSupplierForDelete.name) {
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            code: 'CONFIRMATION-MISMATCH',
            message: 'Confirmation Error',
            details: 'The supplier name you entered does not match. Please enter the exact supplier name to confirm deletion.',
          },
        })
        return
      }

      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK: any) => {
          const query = DatabaseRef_Material_Imports_By_Supplier(res_GCK.clientKey, selectedSupplierForDelete.name)
          return DatabaseGetCollection(query)
        })
        .then((result: any) => {
          if (!result.success) {
            throw new Error('Failed to query supplier imports')
          }

          const tempCombinedDocs: Record<string, any> = {}

          if (result.success) {
            const dataObj = result.data
            Object.keys(dataObj).forEach((docId) => {
              tempCombinedDocs[docId] = dataObj[docId]
            })
          }

          if (Object.keys(tempCombinedDocs).length === 0) {
            // Open a "No Documents Found" dialog instead of confirming deletion
            setOpenNoDocsDialog(true)
            setOpenSupplierDeleteDialog(false)
            return
          }

          // Store docs in state, close the supplier dialog and open the confirmation dialog
          setCombinedDocs(tempCombinedDocs)
          setOpenSupplierDeleteDialog(false)
          setSupplierDeleteConfirmOpen(true)
        })
        .catch((err: any) => {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: err?.error || err,
          })
        })
    }

    const handleConfirmDelete = () => {
      setOpenConfirm(false)
      setSupplierDeleteConfirmOpen(false)

      const batchUpdates: any[] = []
      Object.keys(combinedDocs).forEach((docId) => {
        const docRef = DatabaseRef_Material_Imports_Document(uc_RootData_ClientKey as any, docId)
        batchUpdates.push({
          type: 'delete',
          ref: docRef,
        })
      })

      // Clear out combinedDocs after building the batch
      setCombinedDocs({})

      DatabaseBatchUpdate(batchUpdates)
        .then(() => {
          // Once deletion is done, clear state
          setInvoiceNumber('')
          setSelectedSupplierForDelete(null)
          setSupplierConfirmationInput('')
        })
        .catch((rej_Batch: any) => {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: rej_Batch.error,
          })
        })
    }

    const handleCancelDelete = () => {
      setOpenConfirm(false)
      setSupplierDeleteConfirmOpen(false)
      setCombinedDocs({})
    }

    const handleCloseNoDocsDialog = () => {
      setOpenNoDocsDialog(false)
    }

    // Format supplier array for Autocomplete
    const supplierOptions = objectToArray(us_suppliers).map((supplier: any) => ({
      key: supplier.key,
      name: supplier.name,
    }))

    return (
      <Box>
        <Typography
          variant="h5"
          sx={{ mb: 3, fontWeight: 'bold' }}
        >
          Delete By Invoice Number
        </Typography>
        <Card sx={{ p: 3, mb: 4, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)' }}>
          <TextField
            label="Invoice Number"
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteAllByInvoice}
            startIcon={<Icon icon="trash" />}
            sx={{ minWidth: '180px' }}
          >
            Delete By Invoice
          </Button>
        </Card>

        <Typography
          variant="h5"
          sx={{ mb: 3, fontWeight: 'bold' }}
        >
          Delete By Supplier
        </Typography>
        <Card sx={{ p: 3, mb: 4, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)' }}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mb: 2 }}
          >
            This will permanently delete ALL import records from a specific supplier. Use with caution.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenSupplierDeleteDialog}
            startIcon={<Icon icon="building" />}
            sx={{ minWidth: '180px' }}
          >
            Select Supplier
          </Button>
        </Card>

        {/* Confirm Deletion by Invoice Dialog */}
        <Dialog
          open={openConfirm}
          onClose={handleCancelDelete}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ bgcolor: '#f44336', color: 'white', fontWeight: 'bold' }}>Confirm Deletion</DialogTitle>
          <DialogContent sx={{ pt: 3 }}>
            <Typography
              variant="body1"
              sx={{ mb: 2 }}
            >
              <b>Found {Object.keys(combinedDocs).length} document(s)</b> matching invoice &quot;{invoiceNumber}&quot;.
            </Typography>
            <Typography
              variant="body1"
              color="error"
            >
              Are you sure you want to delete them? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete}>Cancel</Button>
            <Button
              onClick={handleConfirmDelete}
              color="error"
              variant="contained"
              startIcon={<Icon icon="trash" />}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Supplier Selection Dialog */}
        <Dialog
          open={openSupplierDeleteDialog}
          onClose={handleCloseSupplierDeleteDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ bgcolor: themeVariables.primary_main, color: 'white', fontWeight: 'bold' }}>Delete All Imports By Supplier</DialogTitle>
          <DialogContent sx={{ pt: 3, pb: 2 }}>
            <Typography
              variant="body1"
              sx={{ mb: 3, fontWeight: 'medium' }}
            >
              This will permanently delete ALL import records from the selected supplier. This action cannot be undone.
            </Typography>

            <Autocomplete
              options={supplierOptions}
              getOptionLabel={(option) => option.name}
              value={selectedSupplierForDelete}
              onChange={(_, newValue) => handleSupplierSelect(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Supplier"
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 3 }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.key === value.key}
            />

            {selectedSupplierForDelete && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, mb: 1, color: '#d32f2f', fontWeight: 'bold' }}
                >
                  CONFIRMATION REQUIRED
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mb: 2 }}
                >
                  To confirm deletion, please type the supplier name <b>"{selectedSupplierForDelete.name}"</b> below:
                </Typography>
                <TextField
                  label="Confirm Supplier Name"
                  value={supplierConfirmationInput}
                  onChange={(e) => setSupplierConfirmationInput(e.target.value)}
                  variant="outlined"
                  fullWidth
                  error={supplierConfirmationInput !== '' && supplierConfirmationInput !== selectedSupplierForDelete.name}
                  helperText={
                    supplierConfirmationInput !== '' && supplierConfirmationInput !== selectedSupplierForDelete.name ? "Supplier name doesn't match" : ''
                  }
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSupplierDeleteDialog}>Cancel</Button>
            <Button
              onClick={handleDeleteAllBySupplier}
              color="error"
              variant="contained"
              startIcon={<Icon icon="trash" />}
              disabled={!selectedSupplierForDelete || supplierConfirmationInput !== selectedSupplierForDelete.name}
            >
              Delete All Records
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirm Supplier Deletion Dialog */}
        <Dialog
          open={supplierDeleteConfirmOpen}
          onClose={handleCancelDelete}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={{ bgcolor: '#f44336', color: 'white', fontWeight: 'bold' }}>Confirm Supplier Data Deletion</DialogTitle>
          <DialogContent sx={{ pt: 3 }}>
            <Typography
              variant="body1"
              sx={{ mb: 2 }}
            >
              <b>Found {Object.keys(combinedDocs).length} document(s)</b> for supplier &quot;{selectedSupplierForDelete?.name}&quot;.
            </Typography>
            <Typography
              variant="body1"
              color="error"
              sx={{ fontWeight: 'bold' }}
            >
              Are you absolutely sure you want to delete ALL these records? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete}>Cancel</Button>
            <Button
              onClick={handleConfirmDelete}
              color="error"
              variant="contained"
              startIcon={<Icon icon="trash" />}
            >
              Confirm Deletion
            </Button>
          </DialogActions>
        </Dialog>

        {/* No Documents Found Dialog */}
        <Dialog
          open={openNoDocsDialog}
          onClose={handleCloseNoDocsDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>No Documents Found</DialogTitle>
          <DialogContent sx={{ pt: 2 }}>
            {invoiceNumber ? (
              <Typography variant="body1">No documents found for invoice &quot;{invoiceNumber}&quot;.</Typography>
            ) : selectedSupplierForDelete ? (
              <Typography variant="body1">No documents found for supplier &quot;{selectedSupplierForDelete.name}&quot;.</Typography>
            ) : (
              <Typography variant="body1">No documents found.</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNoDocsDialog}>OK</Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }

  const rJSX_skuNumberCleanUp = (): JSX.Element => {
    return (
      <Card className=" ">
        {uc_RootData_ClientKey ? (
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_SkuLookup}
            tableDatabaseEndpoint={tableDatabaseEndpoint_MissingSKUNumber}
            tableSettings={tableSettings_SKUCleanUp}
          />
        ) : (
          <Typography>{rLIB('Loading...')}</Typography>
        )}
      </Card>
    )
  }

  const rJSX_importSessionManagment = (): JSX.Element => {
    return (
      <Card className=" ">
        {uc_RootData_ClientKey ? (
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_MaterialImportSessions}
            tableData={objectToArray(us_importSessions)}
            tableSettings={tableSettings_MaterialImportSessions}
          />
        ) : (
          <Typography>{rLIB('Loading...')}</Typography>
        )}
      </Card>
    )
  }

  const rJSX_ProjectCleanUp = (): JSX.Element => {
    return (
      <Card className=" ">
        {uc_RootData_ClientKey ? (
          <TableDatabase
            tableAdditionalData={{
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              uc_setUserInterface_ErrorDialogDisplay,
            }}
            tableColumns={tableColumns_MaterialImportsProjectCleanUp}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ImportsMissingProjects}
            tableSettings={tableSettings_ProjectsCleanUp}
          />
        ) : (
          <Typography>{rLIB('Loading...')}</Typography>
        )}
      </Card>
    )
  }

  const ImportSummaryDialog: React.FC = () => (
    <Dialog
      open={us_importSummaryDialogOpen}
      onClose={() => us_setImportSummaryDialogOpen(false)}
    >
      <DialogTitle>{rLIB('Import Summary')}</DialogTitle>
      <DialogContent>
        <Card className="tw-p-4">
          {us_importSummary.originalCount !== undefined && (
            <Box sx={{ mb: 2 }}>
              <Typography
                component="span"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                {rLIB('Rows in file:')}
              </Typography>{' '}
              <Typography
                component="span"
                variant="body1"
              >
                {us_importSummary.originalCount}
              </Typography>
            </Box>
          )}
          {us_importSummary.duplicatesCount !== undefined && (
            <Box sx={{ mb: 2 }}>
              <Typography
                component="span"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                {rLIB('Duplicates Skipped:')}
              </Typography>{' '}
              <Typography
                component="span"
                variant="body1"
              >
                {us_importSummary.duplicatesCount}
              </Typography>
            </Box>
          )}
          {us_importSummary.insertedCount !== undefined && (
            <Box sx={{ mb: 2 }}>
              <Typography
                component="span"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                {rLIB('Rows Added:')}
              </Typography>{' '}
              <Typography
                component="span"
                variant="body1"
              >
                {us_importSummary.insertedCount}
              </Typography>
            </Box>
          )}
          {Array.isArray(us_importSummary.insertedDocIDs) && us_importSummary.insertedDocIDs.length > 0 && (
            <>
              <Box sx={{ mb: 2 }}>
                <Typography
                  component="span"
                  variant="body1"
                  sx={{ fontWeight: 'bold' }}
                >
                  {rLIB('Inserted Doc IDs:')}
                </Typography>
              </Box>
              {us_importSummary.insertedDocIDs.map((docId) => (
                <Box
                  key={docId}
                  sx={{ ml: 2 }}
                >
                  - {docId}
                </Box>
              ))}
            </>
          )}
          {us_importSummary.totalImported !== undefined && (
            <Box sx={{ mb: 2 }}>
              <Typography
                component="span"
                variant="body1"
                sx={{ fontWeight: 'bold' }}
              >
                {rLIB('Total Imported:')}
              </Typography>{' '}
              <Typography
                component="span"
                variant="body1"
              >
                {us_importSummary.totalImported}
              </Typography>
            </Box>
          )}
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => us_setImportSummaryDialogOpen(false)}>{rLIB('Close')}</Button>
      </DialogActions>
    </Dialog>
  )

  const allowedColumnsMapping = {
    invoice_reference_number: {
      key: 'invoice_reference_number',
      required: true,
      label: rLIB('Invoice Number'),
      automatch_properties: ['INVOICE_NUMBER', 'Transaction ID', 'Invoice #', 'Invoice', 'Invoice Number'],
    },
    sku_number: {
      key: 'sku_number',
      required: true,
      label: rLIB('SKU Number'),
      automatch_properties: ['SKU Number', 'Customer PN', 'Scrubbed Mfr Part Number', 'Item', 'DESC_COL'],
    },
    sku_description: {
      key: 'sku_description',
      required: false,
      label: rLIB('SKU Description'),
      automatch_properties: ['SKU Description', 'Product Description', 'Description', 'Mfr Part Number'],
    },
    quantity: {
      key: 'quantity',
      required: true,
      label: rLIB('Quantity'),
      automatch_properties: ['ORDER_QTY_COL', 'SHIP_QTY_COL', 'Quantity', 'This Period Shipped Qty', 'Qty. Sold'],
    },
    unit_price: {
      key: 'unit_price',
      required: false,
      label: rLIB('Unit Price'),
      automatch_properties: ['Net Unit Price', 'Sales Price', 'Unit Price'],
    },
    total_price: {
      key: 'total_price',
      required: false,
      label: rLIB('Total Price'),
      automatch_properties: ['TOTAL_DUE', 'Extended Retail (before discount)', 'This Period Sales $', 'Total Price'],
    },
    invoice_date: {
      key: 'invoice_date',
      required: true,
      label: rLIB('Invoice Date'),
      automatch_properties: ['INVOICE_DATE', 'Date', 'Invoice Print Date', 'Invoice Date'],
    },
    associated_project_id_number: {
      key: 'associated_project_id_number',
      required: false,
      label: rLIB('Project Job Code'),
      automatch_properties: ['PO_NUMBER', 'Job Name', 'Customer PO', 'Job Code'],
    },
    associated_po_number: {
      key: 'associated_po_number',
      required: false,
      label: rLIB('PO Number'),
      automatch_properties: ['PO Number'],
    },
    shipping_costs: {
      key: 'shipping_costs',
      required: false,
      label: rLIB('Shipping Costs'),
      automatch_properties: ['Shipping Costs', 'Shipping', 'SHIP_HANDLING'],
    },
    taxes: {
      key: 'taxes',
      required: false,
      label: rLIB('Taxes'),
      automatch_properties: ['SALES_TAX', 'Taxes', 'TAXES'],
    },
    ship_date: {
      key: 'ship_date',
      required: false,
      label: rLIB('Ship Date'),
      automatch_properties: ['Ship Date'],
    },
    due_by: {
      key: 'due_by',
      required: false,
      label: rLIB('Due By'),
      automatch_properties: ['Due By'],
    },
    ship_mode: {
      key: 'ship_mode',
      required: false,
      label: rLIB('Ship Mode'),
      automatch_properties: ['Ship Mode'],
    },
    item_amount: {
      key: 'item_amount',
      required: false,
      label: rLIB('Item Amount'),
      automatch_properties: ['Item Amount', 'SUBTOTAL'],
    },
    ordered_by: {
      key: 'ordered_by',
      required: false,
      label: rLIB('Ordered By'),
      automatch_properties: ['ORDERED_WITH', 'Purchaser', 'Ordered By'],
    },
  }

  const customImportSubmission = (finalDataToImport: any, importHooks: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      getClientKey(importHooks.uc_RootData_ClientKey, importHooks.uc_setRootData_ClientKey)
        .then((res_GCK: any) => {
          const clientKey = res_GCK.clientKey
          if (!clientKey) {
            reject('No clientKey found in customImportSubmission')
            return
          }

          // 1) Create the session doc
          createMaterialImportSessionDoc(clientKey, finalDataToImport.length, importHooks)
            .then((importSessionId) => {
              // 2) Pass session ID into baseFinalImportSubmission so each new row references it
              const newImportHooks = { ...importHooks, importSessionId }

              baseFinalImportSubmission(finalDataToImport, newImportHooks)
                .then((res: any) => {
                  // We get back something like { success: true, summary: { insertedCount, duplicatesCount, ... } }
                  if (res?.success && res.summary) {
                    const summary = res.summary
                    const insertedCount = summary.insertedCount || 0

                    if (insertedCount === 0) {
                      // 3) If no rows were actually imported, delete the session doc
                      DatabaseDeleteDocument(DatabaseRef_Material_Imports_Session_Document(clientKey, importSessionId))
                        .then(() => {
                          // We can still show the summary dialog if you want
                          us_setImportSummary(summary)
                          us_setImportSummaryDialogOpen(true)
                          resolve(res) // resolves with the summary
                        })
                        .catch((deleteErr) => {
                          reject(deleteErr)
                        })
                    } else {
                      // 4) If some rows were inserted, update session doc’s total_rows_imported
                      DatabaseSetMergeDocument(DatabaseRef_Material_Imports_Session_Document(clientKey, importSessionId), {
                        total_rows_imported: insertedCount,
                        duplicates_count: summary.duplicatesCount || 0, // optional
                      })
                        .then(() => {
                          // Show your existing summary
                          us_setImportSummary(summary)
                          us_setImportSummaryDialogOpen(true)
                          resolve(res)
                        })
                        .catch((updateErr) => {
                          reject(updateErr)
                        })
                    }
                  } else {
                    // If res.success is false or no summary, just pass it along
                    resolve(res)
                  }
                })
                .catch((errBase) => {
                  reject(errBase)
                })
            })
            .catch((errSession) => {
              reject(errSession)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  // A tiny helper to create the import-session doc:
  function createMaterialImportSessionDoc(clientKey: string, rowCount: number, importHooks: any): Promise<string> {
    return new Promise((resolve, reject) => {
      let userName: string = getProp(uc_RootData_ClientUser, 'name', null)
      let userKey: string = getProp(uc_RootData_ClientUser, 'key', null)
      const sessionData = {
        timestamp_created: serverTimestamp(),
        associated_supplier_key: importHooks?.importAdditionalData?.associated_supplier_key ?? '',
        associated_supplier_name: importHooks?.importAdditionalData?.associated_supplier_name ?? '',
        associated_user_key: userKey,
        associated_user_name: userName,
        total_rows_imported: 0, // Will update later once we know how many got inserted
      }

      const sessionCollRef = DatabaseRef_Material_Imports_Sessions_Collection(clientKey)

      DatabaseAddDocument(sessionCollRef, sessionData, true /* addKey */)
        .then((resAdd) => {
          if (!resAdd.success) {
            reject(new Error('Failed to create import session doc: ' + JSON.stringify(resAdd.error)))
            return
          }
          // resAdd.key is the doc ID (which we’ll treat as importSessionId)
          resolve(resAdd.key)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const ImportMaterialsWrapper = ({ shrink, selectedSupplier }: { shrink: boolean; selectedSupplier: any }): JSX.Element => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <MaterialImportButtonAndDialog
          importMappingOptions={allowedColumnsMapping}
          importSubmission={customImportSubmission}
          preImportChecks={performPreImportChecks}
          importButtonText="Import Materials"
          importButtonColor="info"
          importButtonShrink={shrink}
          importDialogHeader="Import for Materials"
          importButtonDisabled={!selectedSupplier.key}
          importAdditionalData={{
            uc_RootData_ClientKey,
            associated_supplier_key: selectedSupplier.key,
            associated_supplier_name: selectedSupplier.name,
          }}
        />
      </Box>
    )
  }

  const rJSX_supplierDropdown = (): JSX.Element => {
    const suppliersArray = objectToArray(us_suppliers)

    const handleChange = (event: SelectChangeEvent<string>) => {
      const supplierKey = event.target.value
      const supplier = suppliersArray.find((s) => s.key === supplierKey)
      const newSupplier = { key: supplierKey, name: supplier ? supplier.name : '' }
      setSelectedSupplier(newSupplier)
    }

    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        className="tw-ml-2"
      >
        <FormControl
          variant="outlined"
          size="small"
          sx={{ minWidth: 200 }}
        >
          <InputLabel>{rLIB('Select Supplier')}</InputLabel>
          <Select
            value={selectedSupplier.key}
            onChange={handleChange}
            input={<OutlinedInput label="Select Supplier" />}
            sx={{ minWidth: 200 }}
          >
            <MenuItem
              value=""
              disabled
            >
              -- Choose a Supplier --
            </MenuItem>
            {suppliersArray.map((supplier) => (
              <MenuItem
                key={supplier.key}
                value={supplier.key}
              >
                {supplier.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const rJSX_projectFilterButton = (): JSX.Element => {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 200, height: 40 }}
        className="tw-ml-2"
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<FilterAltIcon />}
          sx={{
            'flexGrow': 1,
            'textAlign': 'left',
            'justifyContent': 'flex-start',
            'height': '100%',
            'backgroundColor': themeVariables.secondary_main, // Filled background
            'color': themeVariables.white || '#fff', // Text color (fallback to white if no contrast color set)
            '&:hover': {
              backgroundColor: themeVariables.secondary_dark, // Optional hover color if you have it
            },
          }}
          onClick={() =>
            openProjectSearchDialog((selectedProject) => {
              setSelectedProject(selectedProject)
            })
          }
        >
          {selectedProject ? `Project: ${selectedProject.id_number}` : 'Filter by Project'}
        </Button>

        {selectedProject && (
          <IconButton
            size="small"
            sx={{
              'color': themeVariables.secondary_main, // X icon color
              'padding': 0, // Removes extra padding
              '&:hover': {
                color: '#fff', // Turns white on hover
              },
            }}
            onClick={() => {
              setSelectedProject(null)
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    )
  }

  // TABS
  const rJSX_PerformanceManagementTabs = (): JSX.Element => {
    return (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'Record_Deduction',
            tabHeader: rLIB('All Material Import Data'),
            tabContent: rJSX_materialImports(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                    {rJSX_projectFilterButton()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                    {rJSX_projectFilterButton()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },

          {
            tabUrlKey: 'No_Project_Assigned',
            tabHeader: (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Typography component="span">{rLIB('Project Clean Up')}</Typography>
              </Box>
            ),
            tabContent: rJSX_ProjectCleanUp(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },
          {
            tabUrlKey: 'Task_Clean_Up',
            tabHeader: (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Typography component="span">{rLIB('Task Clean Up')}</Typography>
              </Box>
            ),
            tabContent: rJSX_taskCleanUp(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },

          {
            tabUrlKey: 'SKU_Number_Clean_Up',
            tabHeader: (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Typography component="span">{rLIB('SKU Clean Up')}</Typography>
              </Box>
            ),
            tabContent: rJSX_skuNumberCleanUp(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },

          {
            tabUrlKey: 'Import_Session_Management',
            tabHeader: (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Typography component="span">{rLIB('Import Session Management')}</Typography>
              </Box>
            ),
            tabContent: rJSX_importSessionManagment(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },

          {
            tabUrlKey: 'Delete_Imports',
            tabHeader: (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Typography component="span">{rLIB('Delete Imports')}</Typography>
              </Box>
            ),
            tabContent: rJSX_deleteImports(),
            tabButtons: [
              {
                fullJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={false}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                minJSX: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ImportMaterialsWrapper
                      shrink={true}
                      selectedSupplier={selectedSupplier}
                    />
                    {rJSX_supplierDropdown()}
                  </Box>
                ),
                sizeCutoff: 0,
              },
            ],
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.AdminDatabaseMaterialImportsIndexPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: 'Material Imports',
        }}
      />
    )
  }

  // Main page render
  const rJSX_Page = (): JSX.Element => {
    return (
      <Box>
        <AuthenticatedContainer
          pageHeader="Material Imports"
          pageKey={ApplicationPages.AdminDatabaseMaterialImportsIndexPage.key}
          content={
            <>
              {rJSX_PerformanceManagementTabs()}
              <EditTaskDialog />
              <ImportSummaryDialog />
              <EditSkuDialog />
            </>
          }
        />
      </Box>
    )
  }

  return <>{rJSX_Page()}</>
}

export default Container
