///////////////////////////////
// Description
///////////////////////////////

import { Box } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_ActiveRegions_Query,
  DatabaseRef_Regions_Collection,
  DatabaseRef_Region_Document,
  DatabaseRef_Region_ImportAliases_Collection,
  DatabaseRef_Region_ImportAliases_Document,
  DatabaseRef_Region_ImportAliases_Query,
} from 'rfbp_aux/services/database_endpoints/directory/regions'
import { tsI_MultipleChoiceOption } from 'rfbp_core/components/direct_edits/direct_multiple_choice_edit'
import { TsInterface_FormAdditionalData, TsInterface_FormHooksObject, TsInterface_FormInputs, TsInterface_FormSubmittedData } from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { listOfUnitedStates } from 'rfbp_core/components/form/inputs/multiple_choice_select_state_usa_abbreviated'
import { Icon } from 'rfbp_core/components/icons'
import { TsInterface_ImportHooksObject } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { openDeleteDataItemDialog, openMasterDataDialog, openUndeleteDataItemDialog } from './all_master_data'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Unique Field Key
let uniqueFieldKey = 'name'

// Document Ref
const documentRef = DatabaseRef_Region_Document

// Collection Ref
const collectionRef = DatabaseRef_Regions_Collection

// Active Collection Ref
const activeCollectionRef = DatabaseRef_ActiveRegions_Query

// Import Aliases Ref
const importAliasesDocumentRef = DatabaseRef_Region_ImportAliases_Document
const importAliasesCollectionRef = DatabaseRef_Region_ImportAliases_Collection
const importAliasesQuery = DatabaseRef_Region_ImportAliases_Query

// Forum Refs
const forumThreadCollectionDatabaseEndpoint = null
const forumThreadDocumentDatabaseEndpoint = null
const forumMessageCollectionDatabaseEndpoint = null
const forumMessageDocumentDatabaseEndpoint = null
const forumMessageStorageEndpoint = null

// Files Refs
const fileSystemCollectionDatabaseEndpoint = null
const fileSystemDocumentDatabaseEndpoint = null
const fileSystemStorageEndpoint = null

// Search Index Ksy
const searchIndexKey = 'regions'

// View Config
const viewConfig: TsInterface_UnspecifiedObject = {
  // HEADER: {
  //   key: 'HEADER',
  //   type: 'header',
  //   label: rLIB('Header Text'),
  // },
  name: {
    key: 'name',
    label: rLIB('Name'),
    type: 'non_editable_text',
  },
  location_address: {
    key: 'location_address',
    label: rLIB('Address'),
    type: 'editable_text',
  },
  location_city: {
    key: 'location_city',
    label: rLIB('City'),
    type: 'editable_text',
  },
  location_state: {
    key: 'location_state',
    label: rLIB('State'),
    type: 'editable_multiple_choice_static',
    options: objectToArray(listOfUnitedStates) as unknown as tsI_MultipleChoiceOption[],
  },
  location_zip: {
    key: 'location_zip',
    label: rLIB('Zip'),
    type: 'editable_text',
  },
  number_prefix: {
    key: 'number_prefix',
    label: rLIB('Number Prefix'),
    type: 'editable_text',
  },
}

// Open View Dialog
const openViewDialog = (itemData: TsInterface_UnspecifiedObject, inputHooks: TsInterface_InputHooksObject): void => {
  openMasterDataDialog(
    documentRef,
    collectionRef,
    itemData.key,
    viewConfig,
    {
      importAliasesDocumentRef,
      importAliasesCollectionRef,
      importAliasesQuery,
    },
    {
      forumThreadCollectionDatabaseEndpoint,
      forumThreadDocumentDatabaseEndpoint,
      forumMessageCollectionDatabaseEndpoint,
      forumMessageDocumentDatabaseEndpoint,
      forumMessageStorageEndpoint,
    },
    {
      fileSystemCollectionDatabaseEndpoint,
      fileSystemDocumentDatabaseEndpoint,
      fileSystemStorageEndpoint,
    },
    inputHooks.uc_setUserInterface_CustomDialogDisplay,
    masterData_Regions,
  )
}

// Text
const textOptions = {
  item_text: rLIB('Region'),
  new_item_text: rLIB('New Region'),
  edit_item_text: rLIB('Edit Region'),
  import_items_text: rLIB('Import Regions'),
  delete_item_text: rLIB('Delete Region'),
  confirm_delete_text: rLIB('Are you sure you want to delete this region?'),
  undelete_item_text: rLIB('Undelete Region'),
  confirm_undelete_text: rLIB('Are you sure you want to undelete this region?'),
}

// New Form
const formInputs_NewItem: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Name'),
    required: true,
  },
  location_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_address',
    label: rLIB('Street Address'),
    required: true,
  },
  location_city: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_city',
    label: rLIB('City'),
    required: true,
  },
  location_state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa',
    key: 'location_state',
    label: rLIB('State'),
    required: true,
  },
  location_zip: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_zip',
    label: rLIB('Zip'),
    required: true,
  },
  number_prefix: {
    data_type: 'string',
    input_type: 'text_number',
    key: 'number_prefix',
    label: rLIB('Number Prefix'),
    required: true,
  },
}

// Edit Form
const formInputs_EditItem: TsInterface_FormInputs = {
  location_address: formInputs_NewItem.location_address,
  location_city: formInputs_NewItem.location_city,
  location_state: formInputs_NewItem.location_state,
  location_zip: formInputs_NewItem.location_zip,
  number_prefix: formInputs_NewItem.number_prefix,
}

// Import Mapping
const importMappingOptions = {
  name: { key: 'name', required: true, label: rLIB('Name', false), automatch_properties: ['Name'] },
  location_address: { key: 'location_address', required: true, label: rLIB('Street Address', false), automatch_properties: ['Location Address'] },
  location_city: { key: 'location_city', required: true, label: rLIB('City', false), automatch_properties: ['Location City'] },
  location_state: { key: 'location_state', required: true, label: rLIB('State', false), automatch_properties: ['Location State'] },
  location_zip: { key: 'location_zip', required: true, label: rLIB('Zip', false), automatch_properties: ['Location Zip'] },
  number_prefix: { key: 'number_prefix', required: false, label: rLIB('Number Prefix', false), automatch_properties: ['Number Prefix'] },
}

// Table Columns
const tableColumns: TsInterface_TableColumns = {
  manage: TableCellManage({
    view_dialog: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: rLIB('View'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openViewDialog(rowData, tableHooks as any)
      },
    },
    // edit: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="pen-to-square"
    //     />
    //   ),
    //   label: rLIB('Edit'),
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     openEditDataItemDialog(rowData, tableAdditionalData, tableHooks, textOptions, formInputs_EditItem, updateItem)
    //   },
    // },
    // upload_image: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="cloud-arrow-up"
    //     />
    //   ),
    //   label: <>{s_UPLOAD_IMAGE}</>,
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     if (rowData != null && rowData.key != null) {
    //       // File Selection
    //       const readAsDataURL = (file: any) => {
    //         return new Promise((resolve, reject) => {
    //           const fr = new FileReader()
    //           fr.onerror = reject
    //           fr.onload = () => {
    //             resolve(fr.result)
    //           }
    //           fr.readAsDataURL(file)
    //         })
    //       }
    //       const fileOnSelect = (
    //         event: React.ChangeEvent<HTMLInputElement>,
    //         additionalFileUploadParams: TsInterface_UnspecifiedObject,
    //       ): TsType_UnknownPromise => {
    //         return new Promise((resolve, reject) => {
    //           if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
    //             let promiseArray: TsType_UnknownPromise[] = []
    //             let files = event.target.files
    //             let readFiles: TsInterface_UnspecifiedObject = {}
    //             let file = files[0]
    //             if (file != null && typeof file === 'object') {
    //               promiseArray.push(
    //                 readAsDataURL(file)
    //                   .then((res_RADURL) => {
    //                     readFiles[0] = {
    //                       file_name: file.name,
    //                       file: file,
    //                       data_url: res_RADURL,
    //                     }
    //                   })
    //                   .catch((rej_RADURL) => {
    //                     // Nothing
    //                   }),
    //               )
    //             }
    //             Promise.all(promiseArray).finally(() => {
    //               let clientKey = additionalFileUploadParams.clientKey
    //               if (clientKey != null && rowData.key != null) {
    //                 StorageUploadFile(StorageRef_RegionPictures(clientKey, rowData.key as string, readFiles[0].file_name), readFiles[0].file, {
    //                   clientKey: clientKey,
    //                   regionKey: rowData.key,
    //                 })
    //                   .then((res_SUF: any) => {
    //                     // files[ fileIndex ]["storage_url"] = getProp(res_SUF, "url", null)
    //                     let updateObject = {
    //                       region_photo_url: getProp(res_SUF, 'url', null),
    //                     }
    //                     DatabaseSetMergeDocument(DatabaseRef_Region_Document(clientKey, rowData.key as string), updateObject)
    //                       .then((res_DSMD) => {
    //                         tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
    //                         resolve(res_DSMD)
    //                       })
    //                       .catch((rej_DSMD) => {
    //                         reject(rej_DSMD)
    //                       })
    //                   })
    //                   .catch((rej_SUF) => {
    //                     console.error(rej_SUF)
    //                     reject(rej_SUF)
    //                   })
    //               } else {
    //                 reject({
    //                   success: false,
    //                   error: {
    //                     message: s_FAILED_TO_UPLOAD_FILE,
    //                     details: s_MISSING_REQUIRED_KEYS,
    //                     code: 'ER-D-RL-FOS-01',
    //                   },
    //                 })
    //               }
    //             })
    //           } else {
    //             reject({
    //               success: false,
    //               error: {
    //                 message: s_FAILED_TO_UPLOAD_FILE,
    //                 details: s_INVALID_DOCUMENT_SELECTION,
    //                 code: 'ER-D-RL-FOS-02',
    //               },
    //             })
    //           }
    //         })
    //       }
    //       const openRegionPhotoDialog = () => {
    //         getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
    //           .then((res_GCK) => {
    //             let profilePicturePreview = <></>
    //             if (rowData != null && rowData.region_photo_url != null) {
    //               profilePicturePreview = (
    //                 <Box className="tw-text-center">
    //                   <Avatar
    //                     variant="rounded"
    //                     className="tw-mx-auto tw-my-2"
    //                     // @ts-expect-error - TODO: reason for error
    //                     src={rowData.region_photo_url}
    //                     sx={{ width: 300, height: 300 }}
    //                   />
    //                 </Box>
    //               )
    //             } else {
    //               profilePicturePreview = <Typography variant="h6">{s_NO_REGION_PICTURE_UPLOADED}</Typography>
    //             }
    //             let dialogJSX = (
    //               <Box>
    //                 <Dialog
    //                   className="bp_dialog_md_width"
    //                   keepMounted
    //                   onClose={() => {
    //                     tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
    //                   }}
    //                   open={true}
    //                 >
    //                   <AppBar position="static">
    //                     <Toolbar>
    //                       <IconButton
    //                         aria-label="menu"
    //                         color="inherit"
    //                         disabled
    //                         edge="start"
    //                         size="large"
    //                         sx={{ mr: 2, color: '#fff !important' }}
    //                       >
    //                         <Icon icon="compass" />
    //                       </IconButton>
    //                       <Typography
    //                         component={'span'}
    //                         variant={'h6'}
    //                         sx={{ flexGrow: 1 }}
    //                       >
    //                         <Box className="tw-inline-block">{rowData.name}</Box>
    //                       </Typography>
    //                     </Toolbar>
    //                   </AppBar>
    //                   <DialogContent sx={{ padding: '0px' }}>
    //                     <Box className="tw-p-4 tw-text-center">
    //                       {profilePicturePreview}
    //                       <FileUploadButton
    //                         multiple={true}
    //                         accept="image/*"
    //                         onChange={fileOnSelect}
    //                         button={{
    //                           text: s_UPLOAD_PHOTO,
    //                           icon: (
    //                             <Icon
    //                               icon="cloud-arrow-up"
    //                               className="tw-mr-2"
    //                             ></Icon>
    //                           ),
    //                           color: 'info',
    //                           className: '',
    //                           variant: 'contained',
    //                           disabled: false,
    //                         }}
    //                         additionalFileUploadParams={{
    //                           clientKey: res_GCK.clientKey,
    //                           // region: getProp( uc_RootData_GlobalUser, "key", null ),
    //                         }}
    //                       />
    //                     </Box>
    //                   </DialogContent>
    //                 </Dialog>
    //               </Box>
    //             )
    //             // Open Dialog
    //             tableHooks.uc_setUserInterface_CustomDialogDisplay({
    //               display: true,
    //               dialog: {
    //                 dialog_jsx: dialogJSX,
    //                 settings: {
    //                   max_width: 'lg',
    //                 },
    //               },
    //             })
    //           })
    //           .catch((rej_GCK) => {
    //             console.error(rej_GCK)
    //           })
    //       }
    //       // Open Dialog
    //       openRegionPhotoDialog()
    //     }
    //   },
    // },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      label: rLIB('Delete'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openDeleteDataItemDialog(rowData, tableAdditionalData, tableHooks, textOptions, deleteItem)
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="magic-wand-sparkles"
        />
      ),
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      label: rLIB('Undelete'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openUndeleteDataItemDialog(rowData, tableAdditionalData, tableHooks, textOptions, undeleteItem)
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Region'), 'name'),
  location_address: TableCellBasic('location_address', rLIB('Address'), 'location_address'),
  location_city: TableCellBasic('location_city', rLIB('City'), 'location_city'),
  location_state: TableCellBasic('location_state', rLIB('State'), 'location_state'),
  location_zip: TableCellBasic('location_zip', rLIB('Zip'), 'location_zip'),
  number_prefix: TableCellBasic('number_prefix', rLIB('Number Prefix'), 'number_prefix'),
}

// Table Settings
const rJSX_MasterDataSearchResult = (
  option: TsInterface_UnspecifiedObject,
  searchInputValue: string | null,
  inputHooks: TsInterface_InputHooksObject,
): JSX.Element => {
  let searchResultJSX = (
    <Box
      sx={{ 'padding': '2px', 'marginLeft': '8px', 'marginRight': '8px', 'cursor': 'pointer', '&:hover': { backgroundColor: themeVariables.warning_main } }}
      className="tw-cursor-pointer"
      onClick={() => {
        getClientKey(inputHooks.uc_RootData_ClientKey, inputHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseGetDocument(documentRef(res_GCK.clientKey, option.id))
              .then((res_DGD) => {
                openViewDialog(res_DGD.data, inputHooks)
              })
              .catch((rej_DGD) => {
                console.error(rej_DGD)
              })
          })
          .catch((rej_GCK) => {
            console.error(rej_GCK)
          })
      }}
    >
      {option.name}
    </Box>
  )
  return searchResultJSX
}

const tableSettings: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  search_settings_database: {
    search_type: 'meilisearch',
    search_client_key: '',
    search_index_key: searchIndexKey,
    search_filters: ['status = "active"'],
    search_result_renderer: rJSX_MasterDataSearchResult,
  },
  searchable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

// Create
const createItem = (
  clientKey: string,
  formSubmittedData: TsInterface_FormSubmittedData,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    let newItemKey = keyFromString(formSubmittedData[uniqueFieldKey])
    let updateObject: TsInterface_UnspecifiedObject = {
      ...formSubmittedData,
      key: newItemKey,
      status: 'active',
    }
    DatabaseSetMergeDocument(documentRef(clientKey, newItemKey), updateObject)
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Update
const updateItem = (clientKey: string, itemKey: string, updateObject: TsInterface_UnspecifiedObject): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), updateObject)
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Delete
const deleteItem = (clientKey: string, itemKey: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), { status: 'deleted' })
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Undelete
const undeleteItem = (clientKey: string, itemKey: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), { status: 'active' })
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Import
const importItems = (
  clientKey: string,
  spreadsheetData: TsInterface_UnspecifiedObject,
  importAdditionalData: TsInterface_UnspecifiedObject,
  importHooks: TsInterface_ImportHooksObject,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    // Create Update Array
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
    for (let loopRowKey in spreadsheetData) {
      let itemKey = keyFromString(spreadsheetData[loopRowKey][uniqueFieldKey])
      updateArray.push({
        type: 'setMerge',
        ref: documentRef(clientKey as string, itemKey),
        data: {
          ...spreadsheetData[loopRowKey],
          status: 'active',
          key: itemKey,
        },
      })
    }
    // Save to Database
    DatabaseStagedBatchUpdate(updateArray)
      .then((res_DSBU) => {
        resolve(res_DSBU)
      })
      .catch((rej_DSBU) => {
        reject(rej_DSBU)
      })
  })
}
// View
// TODO:

///////////////////////////////
// Exports
///////////////////////////////

export const masterData_Regions = {
  uniqueFieldKey: uniqueFieldKey,
  new_item: {
    text: textOptions.new_item_text,
    form_inputs: formInputs_NewItem,
    form_settings: {},
    function: createItem,
  },
  import_items: {
    text: textOptions.import_items_text,
    mapping: importMappingOptions,
    function: importItems,
  },
  table: {
    columns: tableColumns,
    settings: tableSettings,
    database_ref: collectionRef,
  },
  full_exports: {
    documentRef: documentRef,
    collectionRef: collectionRef,
    activeCollectionRef: activeCollectionRef,
    importAliasesDocumentRef: importAliasesDocumentRef,
    importAliasesCollectionRef: importAliasesCollectionRef,
    importAliasesQuery: importAliasesQuery,
    textOptions: textOptions,
    formInputs_NewItem: formInputs_NewItem,
    formInputs_EditItem: formInputs_EditItem,
    importMappingOptions: importMappingOptions,
    tableColumns: tableColumns,
    tableSettings: tableSettings,
    createItem: createItem,
    updateItem: updateItem,
    deleteItem: deleteItem,
    undeleteItem: undeleteItem,
    importItems: importItems,
  },
}
