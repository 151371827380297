///////////////////////////////
// Imports
///////////////////////////////

import { Box, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material'
import React, { memo, useState } from 'react'
import { Icon } from '../icons'

///////////////////////////////
// Typescript
///////////////////////////////

interface tsI_IconMenuItem {
  icon: string
  text: string | JSX.Element
  onClick: any
}

interface tsI_IconMenu {
  icon: JSX.Element
  menuItems: tsI_IconMenuItem[]
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const IconMenu: React.FC<tsI_IconMenu> = memo(function IconMenu({ icon, menuItems }: tsI_IconMenu) {
  // Hooks
  const [us_anchorEl, us_setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(us_anchorEl)

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Box
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => {
          event.stopPropagation()
          us_setAnchorEl(event.currentTarget as any)
        }}
        color="inherit"
      >
        {icon}
      </Box>
      <Menu
        sx={{ maxWidth: '100%' }}
        id="basic-menu"
        anchorEl={us_anchorEl}
        open={open}
        onClose={(event) => {
          // @ts-ignore
          event.stopPropagation()
          us_setAnchorEl(null)
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          'onClick': (event) => event.stopPropagation(),
        }}
      >
        <MenuList sx={{ maxWidth: '100%' }}>
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              onClick={(event) => {
                event.stopPropagation()
                us_setAnchorEl(null)
                menuItem.onClick(event)
              }}
            >
              <ListItemIcon>
                <Icon icon={menuItem.icon} />
              </ListItemIcon>
              <ListItemText>{menuItem.text}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
})
