//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Basic Data Display

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Typography } from '@mui/material/'
import { TsInterface_DataViewerAdditionalData, TsInterface_DataViewerData, TsInterface_DataViewerLine } from 'rfbp_core/components/data_viewer'
import {
  CleanFormData,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_FormSubmission,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_DataViewerHooksObject } from '../data_viewer_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_DataViewerEditSettings {
  editable?: boolean
  formInputs?: TsInterface_FormInputs
  formSubmission?: TsType_FormSubmission
  formHeader?: string | JSX.Element
  showMissingText?: boolean
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const ViewerLineTimestampData = (
  label: string | JSX.Element,
  propKey: string,
  dateFormat: string,
  editableSettings: TsInterface_DataViewerEditSettings,
): TsInterface_DataViewerLine => {
  let viewerLine = {
    line_jsx: (
      viewerData: TsInterface_DataViewerData,
      viewerAdditionalData: TsInterface_DataViewerAdditionalData,
      viewerHooks: TsInterface_DataViewerHooksObject,
    ) => {
      let propValue = ''
      if (propKey != null && viewerData != null && viewerData[propKey] != null && dateFormat != null) {
        propValue = returnFormattedDate(viewerData[propKey], dateFormat)
      }
      let editIconJSX = <></>
      if (editableSettings != null && editableSettings.editable === true && editableSettings.formInputs != null && editableSettings.formSubmission != null) {
        let formData = CleanFormData(editableSettings.formInputs, { ...viewerData }, {})
        editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              viewerHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: viewerAdditionalData,
                    formData: formData,
                    formInputs: editableSettings.formInputs,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        if (editableSettings.formSubmission != null) {
                          editableSettings
                            .formSubmission(formSubmittedData, formAdditionalData, formHooks)
                            .then((res_FS) => {
                              resolve(res_FS)
                            })
                            .catch((rej_FS) => {
                              reject(rej_FS)
                            })
                        }
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: <>{getProp(editableSettings, 'formHeader', rLIB('Edit'))}</>,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
      }
      let missingTextJSX = <></>
      if (editableSettings != null && editableSettings.showMissingText === true && (propValue == null || propValue === '')) {
        missingTextJSX = (
          <Box
            component="span"
            className="tw-opacity-30 tw-italic"
          >
            {rLIB('Missing')}
          </Box>
        )
      }
      let lineJSX = (
        <Typography className="tw-ml-4">
          <Box component="span">{label}: </Box>
          <Box
            component="span"
            className="tw-opacity-50"
          >
            {propValue}
          </Box>
          {missingTextJSX}
          {editIconJSX}
        </Typography>
      )
      return lineJSX
    },
    line_css: (viewerData: TsInterface_DataViewerData, viewerAdditionalData: TsInterface_DataViewerAdditionalData) => {
      return ''
    },
  }
  return viewerLine
}
