/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Divider, Stack } from '@mui/material'
import { formInputs_NewStickyNote, formSettings_StickyNote } from 'app/pages/projects/forms/sticky_notes'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_SalesOpportunity_v2_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnDateCorrectedForNoonTimezoneOffset,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { returnOpportunityTimestamps } from '../data/import_mapping'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_OpportunityCalendarTab {
  opportunityKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const OpportunityCalendarTab = forwardRef((props: TsInterface_OpportunityCalendarTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_opportunityKey: TsInterface_OpportunityCalendarTab['opportunityKey'] = getProp(props, 'opportunityKey', null)
  let pr_clientKey: TsInterface_OpportunityCalendarTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  // let pr_readOrWrite: TsInterface_OpportunityCalendarTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_rootOpportunity, us_setRootOpportunity] = useState<TsInterface_UnspecifiedObject>({})
  const [us_baseTimestamps, us_setBaseTimestamps] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootOpportunity(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunity_v2_Document(actualClientKey, pr_opportunityKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_opportunityKey, pr_clientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let timestamps = returnOpportunityTimestamps(res_GCK.clientKey)
        us_setBaseTimestamps(timestamps)
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_opportunityKey, pr_clientKey, ur_forceRerender])

  returnOpportunityTimestamps

  // Functions
  const editTimestamp = (timestampKey: string, timestampLabel: string | JSX.Element, timestamp: Date | null) => {
    let formData: TsInterface_FormData = {}
    if (timestamp != null) {
      formData['timestamp'] = returnFormattedDate(timestamp, 'YYYY-MM-DD')
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: formData,
          formInputs: {
            timestamp: {
              data_type: 'string',
              key: 'timestamp',
              label: timestampLabel,
              required: true,
              input_type: 'timestamp_date',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = {
                [timestampKey]: returnDateCorrectedForNoonTimezoneOffset(formSubmittedData['timestamp']),
              }

              console.log(updateObject)

              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'info',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Timestamp')} - {timestampLabel}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="calendar-days"
            />
          ),
        },
      },
    })
  }

  const editStickyNoteTimestamp = (note: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            note: note.note,
            date: returnFormattedDate(note.timestamp, 'YYYY-MM-DD'),
          },
          formInputs: formInputs_NewStickyNote,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_StickyNote,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = {
                sticky_notes: {
                  [note.key]: {
                    note: formSubmittedData.note,
                    timestamp: new Date(returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0)),
                  },
                },
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    reject(rej_DSMD)
                  })
              })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'warning',
          formDialogHeaderText: rLIB('Edit Sticky Note'),
          formDialogIcon: (
            <Icon
              icon="note"
              type="solid"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_MissingTimestamp = (timestampKey: string, timestampLabel: string | JSX.Element): JSX.Element => {
    return (
      <Stack
        className="tw-mb-2"
        direction="row"
        spacing={1}
      >
        <Box
          className="tw-px-2 tw-py-1 tw-rounded-md tw-border tw-text-gray_700 tw-border-gray_700 tw-cursor-pointer"
          onClick={() => {
            console.log('Edit')
            editTimestamp(timestampKey, timestampLabel, null)
          }}
        >
          {rLIB('No Timestamp')}
          <Icon
            icon="circle-xmark"
            className="tw-ml-2"
          />
        </Box>
        <Box
          className="tw-py-1"
          sx={{ border: '1px solid rgba(0,0,0,0)' }}
        >
          {timestampLabel}
        </Box>
      </Stack>
    )
  }

  const rJSX_StickyNoteTimestamp = (stickyNote: TsInterface_UnspecifiedObject, timestampLabel: string | JSX.Element): JSX.Element => {
    return (
      <Stack
        className="tw-mb-2"
        direction="row"
        spacing={1}
      >
        <Box
          className="tw-px-2 tw-py-1 tw-rounded-md tw-bg-secondary_main tw-cursor-pointer"
          onClick={() => {
            editStickyNoteTimestamp(stickyNote)
          }}
        >
          {returnFormattedDate(stickyNote.timestamp, 'D MMM YY')}
          <Icon
            icon="note"
            className="tw-ml-2"
          />
        </Box>
        <Box
          className="tw-py-1"
          sx={{ border: '1px solid rgba(0,0,0,0)' }}
        >
          {timestampLabel}
        </Box>
      </Stack>
    )
  }

  const rJSX_BaseTimestamp = (timestamp: Date, timestampKey: string, timestampLabel: string | JSX.Element): JSX.Element => {
    return (
      <Stack
        className="tw-mb-2"
        direction="row"
        spacing={1}
      >
        <Box
          className="tw-px-2 tw-py-1 tw-rounded-md tw-bg-info_main tw-cursor-pointer"
          onClick={() => {
            editTimestamp(timestampKey, timestampLabel, timestamp)
          }}
        >
          {returnFormattedDate(timestamp, 'D MMM YY')}
          <Icon
            icon="circle-check"
            className="tw-ml-2"
          />
        </Box>
        <Box
          className="tw-py-1"
          sx={{ border: '1px solid rgba(0,0,0,0)' }}
        >
          {timestampLabel}
        </Box>
      </Stack>
    )
  }

  const rJSX_CompletedTimestamps = (): JSX.Element => {
    let combinedTimestamps: TsInterface_UnspecifiedObject = {}
    for (let loopTimestampKey in us_baseTimestamps) {
      if (getProp(us_rootOpportunity, loopTimestampKey, null) != null) {
        combinedTimestamps[loopTimestampKey] = {
          key: loopTimestampKey,
          timestamp: getProp(us_rootOpportunity, loopTimestampKey, null),
          label: us_baseTimestamps[loopTimestampKey].label,
          type: 'base',
        }
      }
    }
    // Add Sticky Notes
    if (getProp(us_rootOpportunity, 'sticky_notes', null) != null) {
      for (let loopStickyNoteKey in getProp(us_rootOpportunity, 'sticky_notes', null)) {
        let loopStickyNote = getProp(us_rootOpportunity, 'sticky_notes', null)[loopStickyNoteKey]
        combinedTimestamps[loopStickyNoteKey] = {
          key: loopStickyNoteKey,
          timestamp: getProp(loopStickyNote, 'timestamp', null),
          label: getProp(loopStickyNote, 'note', null),
          type: 'sticky_note',
          full_sticky_note: loopStickyNote,
        }
      }
    }
    return (
      <Box className="tw-m-auto">
        {objectToArray(combinedTimestamps)
          .sort(dynamicSort('timestamp', 'asc'))
          .map((loopTimestamp: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              {loopTimestamp.type === 'sticky_note'
                ? rJSX_StickyNoteTimestamp(loopTimestamp.full_sticky_note, loopTimestamp.label)
                : rJSX_BaseTimestamp(loopTimestamp.timestamp, loopTimestamp.key, loopTimestamp.label)}
            </Box>
          ))}
      </Box>
    )
  }

  const rJSX_MissingTimestamps = (): JSX.Element => {
    let missingTimestamps: TsInterface_UnspecifiedObject = {}
    for (let loopTimestampKey in us_baseTimestamps) {
      if (getProp(us_rootOpportunity, loopTimestampKey, null) == null) {
        missingTimestamps[loopTimestampKey] = {
          key: loopTimestampKey,
          label: us_baseTimestamps[loopTimestampKey].label,
          type: 'base',
          order: us_baseTimestamps[loopTimestampKey].unsorted_order,
        }
      }
    }

    return (
      <Box className="tw-m-auto">
        {objectToArray(missingTimestamps)
          .sort(dynamicSort('order', 'asc'))
          .map((loopMissingTimestamp: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_MissingTimestamp(loopMissingTimestamp.key, loopMissingTimestamp.label)}</Box>
          ))}
      </Box>
    )
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <Card className="tw-p-4">
          {rJSX_CompletedTimestamps()}
          <Divider className="tw-my-2" />
          {rJSX_MissingTimestamps()}
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
