///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_BatteryFile = (clientKey: string, storageKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/storage/' + storageKey + '/files/' + fileName)
}

export const StorageRef_Battery_MessageThread_File = (clientKey: string, storageKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/storage/' + storageKey + '/threads/' + threadKey + '/files/' + fileName)
}
