/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetCollection } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { importIndividualProject } from '../services/project_imports_v2'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectList_CreateProjectTab {}

///////////////////////////////
// Variables
///////////////////////////////

const formInputs_ProjectCreation: TsInterface_FormInputs = {
  header1: {
    data_type: 'string',
    key: 'header1',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {rLIB('Customer Details')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  associated_customer_name: {
    key: 'associated_customer_name',
    label: rLIB('Customer Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_customer_phone: {
    key: 'associated_customer_phone',
    label: rLIB('Customer Phone'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  associated_customer_email: {
    key: 'associated_customer_email',
    label: rLIB('Customer Email'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  header2: {
    data_type: 'string',
    key: 'header2',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {rLIB('Address')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  location_address: {
    key: 'location_address',
    label: rLIB('Street Address'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  location_city: {
    key: 'location_city',
    label: rLIB('City'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  location_state: {
    key: 'location_state',
    label: rLIB('State'),
    input_type: 'multiple_choice_select_state_usa',
    required: true,
    data_type: 'string',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 3,
      md: 3,
      lg: 3,
    },
  },
  location_zip: {
    key: 'location_zip',
    label: rLIB('Zip Code'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 3,
      md: 3,
      lg: 3,
    },
  },
  header3: {
    data_type: 'string',
    key: 'header3',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {rLIB('Region and Sales Partner')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  associated_region_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_region_key',
    options: [],
    label: <>{rLIB('Region')}</>,
    required: true,
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  associated_sales_partner_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_sales_partner_key',
    options: [],
    label: <>{rLIB('Sales Partner')}</>,
    required: true,
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectListCreateProjectTab = forwardRef((props: TsInterface_ProjectList_CreateProjectTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_projectCreationStatus, us_setProjectCreationStatus] = useState<'new' | 'success'>('new')
  const [us_projectCreationResultingProject, us_setProjectCreationResultingProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadingFormInputs, us_setLoadingFormInputs] = useState<boolean>(false)
  const [us_formData, us_setFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_regionsList, us_setRegionsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartnersList, us_setSalesPartnersList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formInputs_ProjectCreation, us_setFormInputs_ProjectCreation] = useState<TsInterface_FormInputs>(formInputs_ProjectCreation)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    us_setLoadingFormInputs(true)
    let promiseArray: Promise<void>[] = []
    let salesPartnersList: TsInterface_UnspecifiedObject = {}
    let regionsList: TsInterface_UnspecifiedObject = {}
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey)).then((res_DGC) => {
            regionsList = res_DGC.data
          }),
        )
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey)).then((res_DGC) => {
            salesPartnersList = res_DGC.data
          }),
        )
        Promise.all(promiseArray).then(() => {
          let formattedRegionOptions: TsInterface_UnspecifiedObject[] = []
          for (let loopRegionKey in regionsList) {
            formattedRegionOptions.push({
              key: loopRegionKey,
              value: regionsList[loopRegionKey].name,
            })
          }
          formInputs_ProjectCreation.associated_region_key.options = formattedRegionOptions
          let formattedSalesPartnerOptions: TsInterface_UnspecifiedObject[] = []
          for (let loopSalesPartnerKey in salesPartnersList) {
            formattedSalesPartnerOptions.push({
              key: loopSalesPartnerKey,
              value: salesPartnersList[loopSalesPartnerKey].name,
            })
          }
          setTimeout(() => {
            formInputs_ProjectCreation.associated_sales_partner_key.options = formattedSalesPartnerOptions
            us_setRegionsList(regionsList)
            us_setSalesPartnersList(salesPartnersList)
            us_setFormInputs_ProjectCreation(formInputs_ProjectCreation)
            us_setLoadingFormInputs(false)
            ur_forceRerender()
          }, 300)
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_setLoadingFormInputs])

  // Functions

  // JSX Generation
  const rJSX_ProjectCreationContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_projectCreationStatus === 'new') {
      if (!us_loadingFormInputs) {
        contentJSX = (
          <Card className="tw-p-4">
            <Box
              className="tw-m-auto"
              sx={{ maxWidth: '1000px' }}
            >
              <Form
                formAdditionalData={{}}
                formData={us_formData}
                formInputs={us_formInputs_ProjectCreation}
                formOnChange={(
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {
                  us_setFormData(formData)
                }}
                formSettings={{
                  submit_button_text: rLIB('Create Project') as JSX.Element,
                  submit_button_icon: <Icon icon="circle-plus" />,
                }}
                formSubmission={(
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let newProjectData: TsInterface_UnspecifiedObject = {
                      ...formSubmittedData,
                      associated_region_name: us_regionsList[formSubmittedData.associated_region_key].name,
                      associated_sales_partner_name: us_salesPartnersList[formSubmittedData.associated_sales_partner_key].name,
                    }
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        importIndividualProject(res_GCK.clientKey, newProjectData, false)
                          .then((res_IIP: any) => {
                            let filteredImportNotes: string[] = []
                            for (let loopIndex in objectToArray(getProp(getProp(res_IIP, 'importUpdateObject', {}), 'import_notes', {}))) {
                              let loopValue = objectToArray(getProp(getProp(res_IIP, 'importUpdateObject', {}), 'import_notes', {}))[loopIndex]
                              if (loopValue != null && loopValue != undefined && loopValue != '') {
                                filteredImportNotes.push(loopValue)
                              }
                            }
                            if (
                              res_IIP != null &&
                              res_IIP.success == true &&
                              res_IIP.importUpdateObject != null &&
                              res_IIP.importUpdateObject.status == 'success'
                            ) {
                              us_setProjectCreationStatus('success')
                              us_setProjectCreationResultingProject(getProp(res_IIP, 'importUpdateObject', {}))
                              resolve(res_IIP)
                            } else if (
                              res_IIP != null &&
                              res_IIP.success == true &&
                              res_IIP.importUpdateObject != null &&
                              res_IIP.importUpdateObject.status == 'warning'
                            ) {
                              formHooks.uc_setUserInterface_ConfirmDialogDisplay({
                                display: true,
                                confirm: {
                                  color: 'warning',
                                  header: <>{rLIB('Project Creation Warning')}</>,
                                  icon: <Icon icon="exclamation-triangle" />,
                                  submit_callback: () => {
                                    return new Promise((resolve2, reject2) => {
                                      importIndividualProject(res_GCK.clientKey, newProjectData, true)
                                        .then((res_IIP: any) => {
                                          let filteredImportNotes: string[] = []
                                          for (let loopIndex in objectToArray(getProp(getProp(res_IIP, 'importUpdateObject', {}), 'import_notes', {}))) {
                                            let loopValue = objectToArray(getProp(getProp(res_IIP, 'importUpdateObject', {}), 'import_notes', {}))[loopIndex]
                                            if (loopValue != null && loopValue != undefined && loopValue != '') {
                                              filteredImportNotes.push(loopValue)
                                            }
                                          }

                                          if (
                                            res_IIP != null &&
                                            res_IIP.success == true &&
                                            res_IIP.importUpdateObject != null &&
                                            res_IIP.importUpdateObject.status == 'success'
                                          ) {
                                            us_setProjectCreationStatus('success')
                                            us_setProjectCreationResultingProject(getProp(res_IIP, 'importUpdateObject', {}))
                                            resolve2(res_IIP)
                                            resolve(res_IIP)
                                          } else {
                                            formHooks.uc_setUserInterface_ErrorDialogDisplay({
                                              display: true,
                                              error: {
                                                message: rLIB('Error creating project'),
                                                details: filteredImportNotes.join(', '),
                                                code: 'ER-D-TCP-PCC-01',
                                              },
                                            })
                                            reject2(res_IIP)
                                            reject(res_IIP)
                                          }
                                        })
                                        .catch((rej_IIP) => {
                                          reject2(rej_IIP)
                                          reject(rej_IIP)
                                        })
                                    })
                                  },
                                  submit_text: rLIB('Proceed with Project Creation'),
                                  text: (
                                    <>
                                      {filteredImportNotes.join(', ')}
                                      {'. '}
                                      {rLIB('Are you sure that you want to proceed?')}
                                    </>
                                  ),
                                },
                              })
                            } else {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({
                                display: true,
                                error: {
                                  message: rLIB('Error creating project'),
                                  details: filteredImportNotes.join(', '),
                                  code: 'ER-D-TCP-PCC-01',
                                },
                              })
                              reject(res_IIP)
                            }
                          })
                          .catch((rej_IIP) => {
                            reject(rej_IIP)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error('Client Key Error:', rej_GCK)
                        reject(rej_GCK)
                      })
                  })
                }}
              />
            </Box>
          </Card>
        )
      } else {
        contentJSX = (
          <Box className="tw-p-4 tw-text-center">
            <CircularProgress />
          </Box>
        )
      }
    } else if (us_projectCreationStatus === 'success') {
      contentJSX = (
        <Card className="tw-p-4 tw-text-center">
          <Typography variant="h6">
            <Icon
              className="tw-mr-2"
              icon="check-circle"
              sx={{ color: themeVariables.success_main }}
            />
            {rLIB('Project created successfully')}
          </Typography>
          <Typography
            className="tw-my-4"
            variant="h4"
            sx={{ fontWeight: 700, opacity: 0.3 }}
          >
            {us_projectCreationResultingProject.job_code}
          </Typography>
          <Box className="tw-text-center">
            <Button
              variant="contained"
              color="info"
              startIcon={<Icon icon="magnifying-glass" />}
              onClick={(event) => {
                let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(us_projectCreationResultingProject.new_project_key as string)
                window.open(url, '_blank')
              }}
              className="tw-mr-2"
            >
              {rLIB('Open Project')}
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<Icon icon="circle-plus" />}
              onClick={() => {
                us_setProjectCreationStatus('new')
                us_setProjectCreationResultingProject({})
                us_setFormData({})
              }}
            >
              {rLIB('Create Another Project')}
            </Button>
          </Box>
        </Card>
      )
    }
    return contentJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <Box className="tw-m-auto">{rJSX_ProjectCreationContent()}</Box>
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
