///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_InverterFile = (clientKey: string, inverterKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/inverters/' + inverterKey + '/files/' + fileName)
}

export const StorageRef_Inverter_MessageThread_File = (clientKey: string, inverterKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/inverters/' + inverterKey + '/threads/' + threadKey + '/files/' + fileName)
}
