///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Base
export const DatabaseRef_OpportunityMilestones_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'opportunity_milestones', 'main')
}

export const DatabaseRef_OpportunityMilestone_Document = (clientKey: string, opportunityMilestoneKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'opportunity_milestones', 'main', opportunityMilestoneKey)
}

export const DatabaseRef_ActiveOpportunityMilestones_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'opportunity_milestones', 'main'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}

export const DatabaseRef_OpportunityMilestone_ImportAliases_Document = (
  clientKey: string,
  opportunityMilestoneKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'opportunity_milestones', 'main', opportunityMilestoneKey, 'import_aliases')
}

export const DatabaseRef_OpportunityMilestone_ImportAliases_Collection = (
  clientKey: string,
  opportunityMilestoneKey: string,
): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'opportunity_milestones', 'main', opportunityMilestoneKey, 'import_aliases')
}

export const DatabaseRef_OpportunityMilestone_ImportAliases_Query = (clientKey: string, opportunityMilestoneKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: opportunityMilestoneKey }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'opportunity_milestones', 'import_aliases'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}
