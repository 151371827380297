///////////////////////////////
// Imports
///////////////////////////////

import { Box, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

// Mapping
const defaultMapping: TsInterface_UnspecifiedObject = {
  // ==========================
  // Opportunity Details
  // ==========================
  unique_identifier: {
    key: 'unique_identifier',
    required: true,
    label: rLIB('Unique Identifier', false),
    automatch_properties: ['Unique Identifier', 'Unique ID', 'Unique ID Number'],
  },
  // associated_region_key // TODO: IN UI
  associated_milestone_key: {
    key: 'associated_milestone_key',
    required: false,
    label: rLIB('Milestone', false),
    automatch_properties: ['Milestone'],
  },
  // ==========================
  // Customer Details
  // ==========================
  associated_customer_name: {
    key: 'associated_customer_name',
    required: true,
    label: rLIB('Customer Name', false),
    automatch_properties: ['Customer Name'],
  },
  associated_customer_phone: {
    key: 'associated_customer_phone',
    required: true,
    label: rLIB('Customer Phone Number', false),
    automatch_properties: ['Customer Phone Number', 'Phone Number', 'Phone'],
  },
  associated_customer_email: {
    key: 'associated_customer_email',
    required: true,
    label: rLIB('Customer Email Address', false),
    automatch_properties: ['Customer Email Address', 'Email Address', 'Email'],
  },
  location_address: {
    key: 'location_address',
    required: true,
    label: rLIB('Street Address', false),
    automatch_properties: ['Street Address', 'Address'],
  },
  location_city: {
    key: 'location_city',
    required: true,
    label: rLIB('City', false),
    automatch_properties: ['City'],
  },
  location_state: {
    key: 'location_state',
    required: true,
    label: rLIB('State', false),
    automatch_properties: ['State'],
  },
  location_zip: {
    key: 'location_zip',
    required: true,
    label: rLIB('Zip', false),
    automatch_properties: ['Zip'],
  },
  // ==========================
  // Sales Partner
  // ==========================
  // associated_sales_partner_key // TODO: IN UI
  associated_sales_rep_name: {
    key: 'associated_sales_rep_name',
    required: true,
    label: rLIB('Sales Rep Name', false),
    automatch_properties: ['Sales Rep Name', 'Sales Rep'],
  },
  associated_sales_rep_phone: {
    key: 'associated_sales_rep_phone',
    required: false,
    label: rLIB('Sales Rep Phone', false),
    automatch_properties: ['Sales Rep Phone', 'Sales Rep Phone Number'],
  },
  associated_sales_rep_email: {
    key: 'associated_sales_rep_email',
    required: false,
    label: rLIB('Sales Rep Email', false),
    automatch_properties: ['Sales Rep Email', 'Sales Rep Email Address'],
  },
  // ==========================
  // Financing
  // ==========================
  associated_finance_partner_key: {
    key: 'associated_finance_partner_key',
    required: false,
    label: rLIB('Finance Partner', false),
    automatch_properties: ['Finance Partner', 'Finance Partner Name'],
  },
  financials_product_sold: {
    key: 'financials_product_sold',
    required: false,
    label: rLIB('Product Sold', false),
    automatch_properties: ['Product Sold'],
  },
  financials_contract_amount: {
    key: 'financials_contract_amount',
    required: false,
    label: rLIB('Contract Amount', false),
    automatch_properties: ['Contract Amount'],
  },
  financials_loan_dealer_fee_percent: {
    key: 'financials_loan_dealer_fee_percent',
    required: false,
    label: rLIB('Dealer Fee %', false),
    automatch_properties: ['Dealer Fee %', 'Dealer Fee Percent'],
  },
  system_size: {
    key: 'system_size',
    required: false,
    label: rLIB('System Size (KW)', false),
    automatch_properties: ['System Size (KW)', 'System Size', 'System Size kW'],
  },
  financials_price_per_watt: {
    key: 'financials_price_per_watt',
    required: false,
    label: rLIB('Price per Watt', false),
    automatch_properties: ['Price per Watt'],
  },
  notes_adders: {
    key: 'notes_adders',
    required: false,
    label: rLIB('Adders', false),
    automatch_properties: ['Adders'],
  },
  associated_install_partner_key: {
    key: 'associated_install_partner_key',
    required: false,
    label: rLIB('Install Partner', false),
    automatch_properties: ['Install Partner', 'Install Partner Name'],
  },
  // ==========================
  // Timestamps
  // ==========================
  virtual_site_survey_completed: {
    key: 'virtual_site_survey_completed',
    required: false,
    label: rLIB('Virtual Site Survey Completed', false),
    automatch_properties: ['Virtual Site Survey Completed', 'Virtual Site Survey Complete (Y/N)'],
  },
  timestamp_opportunity_created: {
    key: 'timestamp_opportunity_created',
    required: false,
    label: rLIB('Opportunity Created', false),
    automatch_properties: ['Opportunity Created'],
  },
  timestamp_appointment_date: {
    key: 'timestamp_appointment_date',
    required: false,
    label: rLIB('Appointment Date', false),
    automatch_properties: ['Appointment Date'],
  },
  timestamp_customer_signed: {
    key: 'timestamp_customer_signed',
    required: false,
    label: rLIB('Contract Signed', false),
    automatch_properties: ['Contract Signed'],
  },
  timestamp_sales_signoff: {
    key: 'timestamp_sales_signoff',
    required: false,
    label: rLIB('Sales Signoff', false),
    automatch_properties: ['Sales Signoff'],
  },
  timestamp_site_audit_completed: {
    key: 'timestamp_site_audit_completed',
    required: false,
    label: rLIB('Site Audit Completed', false),
    automatch_properties: ['Site Audit Completed'],
  },
  timestamp_design_completed: {
    key: 'timestamp_design_completed',
    required: false,
    label: rLIB('Design Completed', false),
    automatch_properties: ['Design Completed'],
  },
  timestamp_permit_submitted: {
    key: 'timestamp_permit_submitted',
    required: false,
    label: rLIB('Permit Submitted', false),
    automatch_properties: ['Permit Submitted'],
  },
  timestamp_interconnection_submitted: {
    key: 'timestamp_interconnection_submitted',
    required: false,
    label: rLIB('Interconnection Submitted', false),
    automatch_properties: ['Interconnection Submitted'],
  },
  timestamp_permit_approved: {
    key: 'timestamp_permit_approved',
    required: false,
    label: rLIB('Permit Approved', false),
    automatch_properties: ['Permit Approved'],
  },
  timestamp_interconnection_approved: {
    key: 'timestamp_interconnection_approved',
    required: false,
    label: rLIB('Interconnection Approved', false),
    automatch_properties: ['Interconnection Approved'],
  },
  timestamp_install_ready: {
    key: 'timestamp_install_ready',
    required: false,
    label: rLIB('Install Ready', false),
    automatch_properties: ['Install Ready'],
  },
  timestamp_install_complete: {
    key: 'timestamp_install_complete',
    required: false,
    label: rLIB('Install Complete', false),
    automatch_properties: ['Install Complete'],
  },
  timestamp_inspection_scheduled: {
    key: 'timestamp_inspection_scheduled',
    required: false,
    label: rLIB('Inspection Scheduled', false),
    automatch_properties: ['Inspection Scheduled'],
  },
  timestamp_inspection_complete: {
    key: 'timestamp_inspection_complete',
    required: false,
    label: rLIB('Inspection Complete', false),
    automatch_properties: ['Inspection Complete'],
  },
  timestamp_pto_submitted: {
    key: 'timestamp_pto_submitted',
    required: false,
    label: rLIB('PTO Submitted', false),
    automatch_properties: ['PTO Submitted'],
  },
  timestamp_pto_approved: {
    key: 'timestamp_pto_approved',
    required: false,
    label: rLIB('PTO Approved', false),
    automatch_properties: ['PTO Approved'],
  },
}

// Forms
const defaultFormInputs: TsInterface_FormInputs = {
  header0: {
    data_type: 'string',
    key: 'header0',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            // className="tw-mt-4"
          >
            {rLIB('Opportunity Details')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  unique_identifier: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'unique_identifier',
    label: rLIB('Unique Identifier'),
    required: true,
  },
  associated_region_key: {
    data_type: 'string',
    key: 'associated_region_key',
    label: rLIB('Region'),
    required: true,
    input_type: 'multiple_choice_select',
    options: [],
  },
  associated_milestone_key: {
    data_type: 'string',
    key: 'associated_milestone_key',
    label: rLIB('Milestone'),
    required: true,
    input_type: 'multiple_choice_select',
    options: [],
  },
  header1: {
    data_type: 'string',
    key: 'header1',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {rLIB('Customer Details')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  associated_customer_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'associated_customer_name',
    label: rLIB('Customer Name'),
    required: true,
  },
  associated_customer_phone: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'associated_customer_phone',
    label: rLIB('Customer Phone Number'),
    required: true,
  },
  associated_customer_email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'associated_customer_email',
    label: rLIB('Customer Email Address'),
    required: true,
  },
  location_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_address',
    label: rLIB('Street Address'),
    required: true,
  },
  location_city: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_city',
    label: rLIB('City'),
    required: true,
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  location_state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa',
    key: 'location_state',
    label: rLIB('State'),
    required: true,
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 3,
      md: 3,
      lg: 3,
    },
  },
  location_zip: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_zip',
    label: rLIB('Zip'),
    required: true,
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 3,
      md: 3,
      lg: 3,
    },
  },
  header2: {
    data_type: 'string',
    key: 'header2',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {rLIB('Sales Partner')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  associated_sales_partner_key: {
    data_type: 'string',
    key: 'associated_sales_partner_key',
    label: rLIB('Sales Partner'),
    required: true,
    input_type: 'multiple_choice_select',
    options: [],
  },
  associated_sales_rep_name: {
    data_type: 'string',
    key: 'associated_sales_rep_name',
    label: rLIB('Sales Rep Name'),
    required: true,
    input_type: 'text_basic',
  },
  associated_sales_rep_phone: {
    data_type: 'string',
    key: 'associated_sales_rep_phone',
    label: rLIB('Sales Rep Phone'),
    required: false,
    input_type: 'text_basic',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  associated_sales_rep_email: {
    data_type: 'string',
    key: 'associated_sales_rep_email',
    label: rLIB('Sales Rep Email'),
    required: false,
    input_type: 'text_basic',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  header3: {
    data_type: 'string',
    key: 'header3',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {rLIB('Financing')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  associated_finance_partner_key: {
    data_type: 'string',
    key: 'associated_finance_partner_key',
    label: rLIB('Finance Partner'),
    required: false,
    input_type: 'multiple_choice_select',
    options: [],
  },
  financials_product_sold: {
    data_type: 'string',
    key: 'financials_product_sold',
    label: rLIB('Product Sold'),
    required: false,
    input_type: 'text_basic',
  },
  financials_contract_amounts: {
    data_type: 'string',
    key: 'financials_contract_amounts',
    label: rLIB('Contract Amount'),
    required: false,
    input_type: 'text_number',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  financials_loan_dealer_fee_percent: {
    data_type: 'string',
    key: 'financials_loan_dealer_fee_percent',
    label: rLIB('Dealer Fee %'),
    required: false,
    input_type: 'text_number',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  system_size: {
    data_type: 'string',
    key: 'system_size',
    label: rLIB('System Size (KW)'),
    required: false,
    input_type: 'text_number',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  financials_price_per_watt: {
    data_type: 'string',
    key: 'financials_price_per_watt',
    label: rLIB('Price per Watt'),
    required: false,
    input_type: 'text_number',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  notes_adders: {
    data_type: 'string',
    key: 'notes_adders',
    label: rLIB('Adders'),
    required: false,
    input_type: 'text_multiline',
  },
  header4: {
    data_type: 'string',
    key: 'header4',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {rLIB('Timestamps')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  virtual_site_survey_completed: {
    data_type: 'string',
    key: 'virtual_site_survey_completed',
    label: rLIB('Virtual Site Survey Completed'),
    required: false,
    input_type: 'multiple_choice_radio',
    options: [
      { key: 'yes', value: 'yes' },
      { key: 'no', value: 'no' },
    ],
  },
  timestamp_opportunity_created: {
    data_type: 'string',
    key: 'timestamp_opportunity_created',
    label: rLIB('Opportunity Created'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_appointment_date: {
    data_type: 'string',
    key: 'timestamp_appointment_date',
    label: rLIB('Appointment Date'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_customer_signed: {
    data_type: 'string',
    key: 'timestamp_customer_signed',
    label: rLIB('Contract Signed'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_sales_signoff: {
    data_type: 'string',
    key: 'timestamp_sales_signoff',
    label: rLIB('Sales Signoff'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_site_audit_completed: {
    data_type: 'string',
    key: 'timestamp_site_audit_completed',
    label: rLIB('Site Audit Completed'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_design_completed: {
    data_type: 'string',
    key: 'timestamp_design_completed',
    label: rLIB('Design Completed'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_permit_submitted: {
    data_type: 'string',
    key: 'timestamp_permit_submitted',
    label: rLIB('Permit Submitted'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_interconnection_submitted: {
    data_type: 'string',
    key: 'timestamp_interconnection_submitted',
    label: rLIB('Interconnection Submitted'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_permit_approved: {
    data_type: 'string',
    key: 'timestamp_permit_approved',
    label: rLIB('Permit Approved'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_interconnection_approved: {
    data_type: 'string',
    key: 'timestamp_interconnection_approved',
    label: rLIB('Interconnection Approved'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_install_ready: {
    data_type: 'string',
    key: 'timestamp_install_ready',
    label: rLIB('Install Ready'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_install_complete: {
    data_type: 'string',
    key: 'timestamp_install_complete',
    label: rLIB('Install Complete'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_inspection_scheduled: {
    data_type: 'string',
    key: 'timestamp_inspection_scheduled',
    label: rLIB('Inspection Scheduled'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_inspection_complete: {
    data_type: 'string',
    key: 'timestamp_inspection_complete',
    label: rLIB('Inspection Complete'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_pto_submitted: {
    data_type: 'string',
    key: 'timestamp_pto_submitted',
    label: rLIB('PTO Submitted'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
  timestamp_pto_approved: {
    data_type: 'string',
    key: 'timestamp_pto_approved',
    label: rLIB('PTO Approved'),
    required: false,
    input_type: 'timestamp_date',
    use_grid_layout: true,
    grid_layout_columns: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  },
}

const defaultDates = {
  timestamp_opportunity_created: {
    key: 'timestamp_opportunity_created',
    label: rLIB('Opportunity Created'),
    unsorted_order: 1,
  },
  timestamp_appointment_date: {
    key: 'timestamp_appointment_date',
    label: rLIB('Appointment Date'),
    unsorted_order: 2,
  },
  timestamp_customer_signed: {
    key: 'timestamp_customer_signed',
    label: rLIB('Contract Signed'),
    unsorted_order: 3,
  },
  timestamp_sales_signoff: {
    key: 'timestamp_sales_signoff',
    label: rLIB('Sales Signoff'),
    unsorted_order: 4,
  },
  timestamp_site_audit_completed: {
    key: 'timestamp_site_audit_completed',
    label: rLIB('Site Audit Completed'),
    unsorted_order: 5,
  },
  timestamp_design_completed: {
    key: 'timestamp_design_completed',
    label: rLIB('Design Completed'),
    unsorted_order: 6,
  },
  timestamp_permit_submitted: {
    key: 'timestamp_permit_submitted',
    label: rLIB('Permit Submitted'),
    unsorted_order: 7,
  },
  timestamp_interconnection_submitted: {
    key: 'timestamp_interconnection_submitted',
    label: rLIB('Interconnection Submitted'),
    unsorted_order: 8,
  },
  timestamp_permit_approved: {
    key: 'timestamp_permit_approved',
    label: rLIB('Permit Approved'),
    unsorted_order: 9,
  },
  timestamp_interconnection_approved: {
    key: 'timestamp_interconnection_approved',
    label: rLIB('Interconnection Approved'),
    unsorted_order: 10,
  },
  timestamp_install_ready: {
    key: 'timestamp_install_ready',
    label: rLIB('Install Ready'),
    unsorted_order: 11,
  },
  timestamp_install_complete: {
    key: 'timestamp_install_complete',
    label: rLIB('Install Complete'),
    unsorted_order: 12,
  },
  timestamp_inspection_scheduled: {
    key: 'timestamp_inspection_scheduled',
    label: rLIB('Inspection Scheduled'),
    unsorted_order: 13,
  },
  timestamp_inspection_complete: {
    key: 'timestamp_inspection_complete',
    label: rLIB('Inspection Complete'),
    unsorted_order: 14,
  },
  timestamp_pto_submitted: {
    key: 'timestamp_pto_submitted',
    label: rLIB('PTO Submitted'),
    unsorted_order: 15,
  },
  timestamp_pto_approved: {
    key: 'timestamp_pto_approved',
    label: rLIB('PTO Approved'),
    unsorted_order: 16,
  },
}

///////////////////////////////
// Exports
///////////////////////////////

export const returnOpportunityImportDataMapping = (clientKey: string) => {
  switch (clientKey) {
    case 'etw_energy_sales':
      return defaultMapping
    case 'lunar':
      return defaultMapping
    case 'super_green':
      return defaultMapping
    default:
      return defaultMapping
  }
}

export const returnOpportunityNewFormInputs = (clientKey: string): TsInterface_FormInputs => {
  switch (clientKey) {
    case 'etw_energy_sales':
      return defaultFormInputs
    case 'lunar':
      return defaultFormInputs
    case 'super_green':
      return defaultFormInputs
    default:
      return defaultFormInputs
  }
}

export const returnOpportunityTimestamps = (clientKey: string): TsInterface_UnspecifiedObject => {
  switch (clientKey) {
    case 'etw_energy_sales':
      return defaultDates
    case 'lunar':
      return defaultDates
    case 'super_green':
      return defaultDates
    default:
      return defaultDates
  }
}
