/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Avatar, Box, Button, Card, Chip, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { openTaskFormEditDialog } from 'app/models/tasks/task_form_services'
import { returnProjectMilestoneTimelineDates, returnSortedWorkflowPhasesArray } from 'app/pages/pacing/services/project_phases'
import dayjs from 'dayjs'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { firestoreBaseUrl } from 'rfbp_aux/config/config'
import { DatabaseRef_ActiveAccessories_Query } from 'rfbp_aux/services/database_endpoints/directory/accessories'
import { DatabaseRef_ActiveBatteries_Query } from 'rfbp_aux/services/database_endpoints/directory/batteries'
import { DatabaseRef_ActiveBuildingTypes_Query } from 'rfbp_aux/services/database_endpoints/directory/building_types'
import { DatabaseRef_ActiveFinancePartners_Query } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_ActiveFinancingTypes_Query } from 'rfbp_aux/services/database_endpoints/directory/financing_type'
import { DatabaseRef_ActiveHoas_Query } from 'rfbp_aux/services/database_endpoints/directory/hoas'
import { DatabaseRef_ActiveInverters_Query } from 'rfbp_aux/services/database_endpoints/directory/inverters'
import { DatabaseRef_ActiveJurisdictions_Query } from 'rfbp_aux/services/database_endpoints/directory/jurisdictions'
import { DatabaseRef_ActiveModules_Query } from 'rfbp_aux/services/database_endpoints/directory/modules'
import { DatabaseRef_ActiveMountTypes_Query } from 'rfbp_aux/services/database_endpoints/directory/mount_types'
import { DatabaseRef_ActiveProductPackages_Query } from 'rfbp_aux/services/database_endpoints/directory/product_packages'
import { DatabaseRef_ActiveRacking_Query } from 'rfbp_aux/services/database_endpoints/directory/racking'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflow_Document } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_ActiveUtilities_Query } from 'rfbp_aux/services/database_endpoints/directory/utilities'
import { DatabaseRef_ProjectFeedback_Query } from 'rfbp_aux/services/database_endpoints/operations/feedback'
import { DatabaseRef_ProjectAdditionalData_Document, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { stringAvatar, stringToColor } from 'rfbp_core/components/chat'
import { DirectMultipleChoiceEdit, tsI_MultipleChoiceOption } from 'rfbp_core/components/direct_edits/direct_multiple_choice_edit'
import { DirectNumberEdit } from 'rfbp_core/components/direct_edits/direct_number_edit'
import { DirectParagraphEdit } from 'rfbp_core/components/direct_edits/direct_paragraph_edit'
import { DirectTextEdit } from 'rfbp_core/components/direct_edits/direct_text_edit'
import { FeedbackNpsComments } from 'rfbp_core/components/feedback'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { listOfUnitedStates } from 'rfbp_core/components/form/inputs/multiple_choice_select_state_usa_abbreviated'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  formatCurrency,
  getProp,
  objectToArray,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { formInputs_WarehouseDistance } from '../forms/project_customer_details'
import { formInputs_NewStickyNote, formSettings_StickyNote } from '../forms/sticky_notes'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_ProjectDetailsTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
}

///////////////////////////////
// Variables
///////////////////////////////

const tabKey = 'project_details'

///////////////////////////////
// Functions
///////////////////////////////

const checkAndRepairTaskStatuses = (clientKey: string, tasks: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let messedUpTasks: TsInterface_UnspecifiedObject = {}
    let taskRepairUpdateObjects: TsInterface_UnspecifiedObject = {}
    let hasTaskRepairs = false
    // Loop through tasks
    for (let loopTaskKey in tasks) {
      let loopTask = tasks[loopTaskKey]
      // If there are prerequisite tasks
      if (loopTask.prerequisite_tasks == null || objectToArray(loopTask.prerequisite_tasks).length === 0) {
        if (loopTask['ready_to_start'] === false && loopTask['status'] !== 'deleted') {
          taskRepairUpdateObjects[loopTaskKey] = { ready_to_start: true }
        }
      }
      if (loopTask.prerequisite_tasks != null) {
        // Loop through prerequisite tasks
        for (let loopPrereqTaskKey in loopTask.prerequisite_tasks) {
          let loopPrereqTask = tasks[loopPrereqTaskKey]
          if (loopPrereqTask != null && loopPrereqTask.status_complete === true) {
            if (loopTask['ready_to_start'] === false && loopTask['status'] !== 'deleted') {
              messedUpTasks[loopTaskKey] = loopTask
              if (taskRepairUpdateObjects[loopTaskKey] == null) {
                taskRepairUpdateObjects[loopTaskKey] = {
                  ready_to_start: true,
                  prerequisite_tasks_completion: {},
                }
              }
              if (loopPrereqTask['timestamp_completed'] != null) {
                taskRepairUpdateObjects[loopTaskKey]['prerequisite_tasks_completion'][loopPrereqTaskKey] = loopPrereqTask['timestamp_completed']
              }
              hasTaskRepairs = true
            }
            loopTask.prerequisite_tasks[loopPrereqTaskKey] = loopPrereqTaskKey
          }
        }
      }
    }
    if (hasTaskRepairs === true) {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
      for (let loopTaskKey in taskRepairUpdateObjects) {
        taskRepairUpdateObjects[loopTaskKey]['timestamp_last_updated'] = new Date()
        updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: taskRepairUpdateObjects[loopTaskKey] })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      resolve({ success: true })
    }
  })
}

/**
 * Formats a phone number string to (XXX) XXX-XXXX format
 * - If 10 digits, formats as (XXX) XXX-XXXX
 * - If 11 digits with leading 1, strips the 1 and formats as (XXX) XXX-XXXX
 * - Otherwise returns the original string
 *
 * @param phoneNumber The phone number string to format
 * @returns Formatted phone number string
 */
const formatPhoneNumber = (phoneNumber: string): string => {
  // If null, return empty string
  if (phoneNumber == null) {
    return ''
  }
  // If not a string, convert to string
  if (typeof phoneNumber !== 'string') {
    // @ts-ignore
    phoneNumber = phoneNumber.toString()
  }

  // Remove all non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, '')

  // Check if it's 11 digits with leading 1
  if (digitsOnly.length === 11 && digitsOnly.charAt(0) === '1') {
    const areaCode = digitsOnly.substring(1, 4)
    const prefix = digitsOnly.substring(4, 7)
    const lineNumber = digitsOnly.substring(7, 11)
    return `(${areaCode}) ${prefix}-${lineNumber}`
  }

  // Check if it's 10 digits
  if (digitsOnly.length === 10) {
    const areaCode = digitsOnly.substring(0, 3)
    const prefix = digitsOnly.substring(3, 6)
    const lineNumber = digitsOnly.substring(6, 10)
    return `(${areaCode}) ${prefix}-${lineNumber}`
  }

  // If it doesn't match our formatting criteria, return the original
  return phoneNumber
}

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewProjectDetailsTab = forwardRef((props: TsInterface_ProjectView_ProjectDetailsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_ProjectDetailsTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_clientKey: TsInterface_ProjectView_ProjectDetailsTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_readOrWrite: TsInterface_ProjectView_ProjectDetailsTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_additionalProjectData, us_setAdditionalProjectData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_minimizedCustomerDetails, us_setMinimizedCustomerDetails] = useState<boolean>(false)
  const [us_minimizedSalesPartnerDetails, us_setMinimizedSalesPartnerDetails] = useState<boolean>(false)
  const [us_minimizedFinancingDetails, us_setMinimizedFinancingDetails] = useState<boolean>(false)
  const [us_minimizedEstimatedTimeline, us_setMinimizedEstimatedTimeline] = useState<boolean>(false)
  const [us_minimizedCustomerFeedback, us_setMinimizedCustomerFeedback] = useState<boolean>(false)
  const [us_minimizedHomeDetails, us_setMinimizedHomeDetails] = useState<boolean>(false)
  const [us_minimizedLastContact, us_setMinimizedLastContact] = useState<boolean>(false)
  const [us_minimizedProjectTasks, us_setMinimizedProjectTasks] = useState<boolean>(false)
  const [us_minimizedStickyNotes, us_setMinimizedStickyNotes] = useState<boolean>(false)
  const [us_minimizedSystemDetails, us_setMinimizedSystemDetails] = useState<boolean>(false)
  const [us_projectFeedback, us_setProjectFeedback] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_materialImports, us_setMaterialImports] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_rootProjectLoaded, us_setRootProjectLoaded] = useState<boolean>(false)
  const [us_repairedTasks, us_setRepairedTasks] = useState<boolean>(false)
  // const [us_reloadTaskWorkflow] = useState<number>(0)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_sortedMilestonePhaseTasksArray, us_setSortedMilestonePhaseTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_milestoneTimelineDates, us_setMilestoneTimelineDates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflow, us_setTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseWorkflowTasks, us_setPhaseWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Hooks - useEffect
  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage(tabKey)
    if (getProp(pageLocalStorageData, 'us_minimizedCustomerDetails', null) != null) {
      us_setMinimizedCustomerDetails(getProp(pageLocalStorageData, 'us_minimizedCustomerDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedSalesPartnerDetails', null) != null) {
      us_setMinimizedSalesPartnerDetails(getProp(pageLocalStorageData, 'us_minimizedSalesPartnerDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedFinancingDetails', null) != null) {
      us_setMinimizedFinancingDetails(getProp(pageLocalStorageData, 'us_minimizedFinancingDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedSystemDetails', null) != null) {
      us_setMinimizedSystemDetails(getProp(pageLocalStorageData, 'us_minimizedSystemDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedLastContact', null) != null) {
      us_setMinimizedLastContact(getProp(pageLocalStorageData, 'us_minimizedLastContact', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedHomeDetails', null) != null) {
      us_setMinimizedHomeDetails(getProp(pageLocalStorageData, 'us_minimizedHomeDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedProjectTasks', null) != null) {
      us_setMinimizedProjectTasks(getProp(pageLocalStorageData, 'us_minimizedProjectTasks', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedStickyNotes', null) != null) {
      us_setMinimizedStickyNotes(getProp(pageLocalStorageData, 'us_minimizedStickyNotes', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedEstimatedTimeline', null) != null) {
      us_setMinimizedEstimatedTimeline(getProp(pageLocalStorageData, 'us_minimizedEstimatedTimeline', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedCustomerFeedback', null) != null) {
      us_setMinimizedCustomerFeedback(getProp(pageLocalStorageData, 'us_minimizedCustomerFeedback', null))
    }
  }, [])

  // TODO: TEMP
  useEffect(() => {
    if (us_rootProject != null && us_rootProject['sticky_note'] != null) {
      let noteKey = uuidv4()
      let updateObject = {
        sticky_note: null,
        sticky_notes: {
          [noteKey]: {
            timestamp: new Date(),
            note: us_rootProject['sticky_note'],
            key: noteKey,
          },
        },
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        DatabaseSetMergeDocument(DatabaseRef_Project_Document(actualClientKey, pr_projectKey), updateObject)
      })
    }
    return () => {}
  }, [us_rootProject, uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAdditionalProjectData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectAdditionalData_Document(actualClientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectFeedback(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectFeedback_Query(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectFeedback({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  // useEffect(() => {
  //   let unsubscribeLiveData: TsType_VoidFunction
  //   const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //     us_setProjectOpenReminders(newData)
  //     ur_forceRerender()
  //   }
  //   if (pr_projectKey != null) {
  //     getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //       .then((res_GCK) => {
  //         let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
  //         unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectReminders_Query(actualClientKey, pr_projectKey), updateLiveData)
  //       })
  //       .catch((rej_GCK) => {
  //         console.error(rej_GCK)
  //       })
  //   } else {
  //     us_setProjectOpenReminders({})
  //   }
  //   return () => {
  //     if (typeof unsubscribeLiveData === 'function') {
  //       unsubscribeLiveData()
  //     }
  //   }
  // }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
        // us_setProjectTasksLoaded(true)
        if (us_repairedTasks === false) {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
            let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
            checkAndRepairTaskStatuses(actualClientKey, newData)
          })
          us_setRepairedTasks(true)
        }
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
      // us_setProjectTasksLoaded(true)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_repairedTasks, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      // us_setRootProjectLoaded(true)
      // TODO - TEMP
      if (newData != null && newData['task_completion_stats'] != null && newData['task_completion_stats']['unknown'] != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: actualClientKey,
            project_key: pr_projectKey,
          })
          ur_forceRerender()
        })
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(actualClientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  // useEffect(() => {
  //   getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //     .then((res_GCK) => {
  //       let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
  //       DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(actualClientKey, pr_projectKey))
  //         .then((res_DGD) => {
  //           // us_setProjectTaskWorkflow(res_DGD.data)
  //           let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
  //           let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
  //           if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
  //             for (let loopTaskKey in res_DGD.data['tasks']) {
  //               let loopTask = res_DGD.data['tasks'][loopTaskKey]
  //               if (us_reloadTaskWorkflow >= 0) {
  //                 // Nothing - just used for reloads
  //               }
  //               if (loopTask != null && loopTask['associated_owner_type'] != null) {
  //                 taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
  //                 if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
  //                   taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
  //                 }
  //               }
  //             }
  //           }
  //           us_setProjectTaskWorkflowUserRoles(taskWorkflowUserRolesList)
  //           // us_setUsersWithDirectOrScheduledTasksRoles(taskWorkflowUserRolesWithDirectOrScheduledTasksList)
  //         })
  //         .catch((rej_DGC) => {
  //           console.error(rej_DGC)
  //         })
  //     })
  //     .catch((rej_GCK) => {
  //       console.error(rej_GCK)
  //     })
  // }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorkflow, pr_clientKey])

  // useEffect(() => {
  //   let projectUsersFlatObject: TsInterface_UnspecifiedObject = {}
  //   if (us_rootProject != null) {
  //     if (us_rootProject['associated_customer_key'] != null && us_rootProject['associated_customer_name'] != null) {
  //       projectUsersFlatObject[us_rootProject[us_rootProject['associated_customer_key']]] = us_rootProject['associated_customer_name']
  //     }
  //     if (us_rootProject['associated_sales_rep_key'] != null && us_rootProject['associated_sales_rep_name'] != null) {
  //       projectUsersFlatObject[us_rootProject[us_rootProject['associated_sales_rep_key']]] = us_rootProject['associated_sales_rep_name']
  //     }
  //     if (us_rootProject['associated_css_rep_key'] != null && us_rootProject['associated_css_rep_name'] != null) {
  //       projectUsersFlatObject[us_rootProject[us_rootProject['associated_css_rep_key']]] = us_rootProject['associated_css_rep_name']
  //     }
  //     for (let loopRoleKey in us_projectTaskWorkflowUserRoles) {
  //       if (us_rootProject['associated_' + loopRoleKey + '_key'] != null && us_rootProject['associated_' + loopRoleKey + '_name'] != null) {
  //         projectUsersFlatObject[us_rootProject['associated_' + loopRoleKey + '_key']] = us_rootProject['associated_' + loopRoleKey + '_name']
  //       }
  //     }
  //   }
  //   us_setProjectUsersFlatObject(projectUsersFlatObject)
  // }, [us_rootProject, us_projectTaskWorkflowUserRoles])

  // useEffect(() => {
  //   let unsubscribeLiveData: TsType_VoidFunction
  //   const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //     us_setMaterialImports(newData)
  //     ur_forceRerender()
  //   }
  //   if (pr_projectKey != null) {
  //     getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //       .then((res_GCK) => {
  //         unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Material_Imports_By_Project(res_GCK.clientKey, pr_projectKey), updateLiveData)
  //       })
  //       .catch((rej_GCK) => {
  //         console.error(rej_GCK)
  //       })
  //   } else {
  //     us_setMaterialImports({})
  //   }
  //   return () => {
  //     if (typeof unsubscribeLiveData === 'function') {
  //       unsubscribeLiveData()
  //     }
  //   }
  // }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestones'] != null && us_phaseWorkflowTasks != null && us_phaseWorkflowTasks['tasks'] != null) {
      let sortedMilestonePhaseTasksArray = returnSortedWorkflowPhasesArray(us_phaseWorkflowTasks['tasks'], us_taskWorkflow)
      us_setSortedMilestonePhaseTasksArray(sortedMilestonePhaseTasksArray)
    }
  }, [us_taskWorkflow, us_phaseWorkflowTasks])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskWorkflow(newData)
      ur_forceRerender()
    }
    if (us_rootProject != null && us_rootProject.associated_task_workflow_key != null && us_rootProject.associated_task_workflow_key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_TaskWorkflow_Document(actualClientKey, us_rootProject.associated_task_workflow_key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setTaskWorkflow({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootProject, pr_clientKey])

  useEffect(() => {
    if (us_rootProject != null && us_rootProject.associated_task_workflow_key != null && us_rootProject.associated_task_workflow_key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(actualClientKey, us_rootProject.associated_task_workflow_key as string))
            .then((res_DGD) => {
              us_setPhaseWorkflowTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setPhaseWorkflowTasks({})
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootProject, pr_clientKey])

  useEffect(() => {
    if (us_sortedMilestonePhaseTasksArray != null && us_sortedMilestonePhaseTasksArray.length > 0) {
      // Loop through milestones and calculate phase days data
      let milestoneDates = returnProjectMilestoneTimelineDates(us_sortedMilestonePhaseTasksArray, us_rootProject, us_projectTasks, false, {})
      us_setMilestoneTimelineDates(milestoneDates)
    }
  }, [us_sortedMilestonePhaseTasksArray, us_rootProject, us_projectTasks])

  // TODO: TEMP - generate project start date
  useEffect(() => {
    if (
      us_rootProject != null &&
      us_rootProject.associated_task_workflow_key != null &&
      us_rootProject.associated_task_workflow_key !== '' &&
      (us_rootProject.timestamp_workflow_selection == null || us_rootProject.timestamp_workflow_selection[us_rootProject.associated_task_workflow_key] == null)
    ) {
      // Get Oldest Task
      console.log('MISSING START DATE')
      console.log(us_projectTasks)
      let oldestTaskTimestamp = null
      for (let loopTaskKey in us_projectTasks) {
        let loopTask = us_projectTasks[loopTaskKey]
        if (loopTask != null && loopTask.timestamp_completed != null) {
          let loopTaskTimestamp = returnDateFromUnknownDateFormat(loopTask.timestamp_completed)
          if (oldestTaskTimestamp == null || loopTaskTimestamp.getTime() < oldestTaskTimestamp.getTime()) {
            oldestTaskTimestamp = loopTaskTimestamp
          }
        }
      }
      if (oldestTaskTimestamp != null) {
        let updateObject = {
          timestamp_workflow_selection: {
            [us_rootProject.associated_task_workflow_key]: oldestTaskTimestamp,
          },
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
        })
      }
    }
  }, [us_rootProject, us_projectTasks, uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey])

  // Functions
  const getUniqueTeamMembers = (teamNamesMap: Record<string, Record<string, string>>): string[] => {
    const uniqueNamesSet = new Set<string>()
    if (teamNamesMap && typeof teamNamesMap === 'object') {
      Object.values(teamNamesMap).forEach((dailyMap) => {
        if (dailyMap && typeof dailyMap === 'object') {
          Object.values(dailyMap).forEach((memberName) => {
            uniqueNamesSet.add(memberName)
          })
        }
      })
    }
    return Array.from(uniqueNamesSet)
  }

  const returnMilestoneColorsAndIcons = (
    milestoneTimelineData: TsInterface_UnspecifiedObject,
  ): { milestoneColor: string; milestoneStatusIconJSX: JSX.Element } => {
    let milestoneStatusIconJSX: JSX.Element = <></>
    let milestoneTimelineColor = themeVariables.gray_600
    if (milestoneTimelineData != null && milestoneTimelineData.status === 'complete') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-check"
          className="tw-text-info_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.info_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'in_progress' && milestoneTimelineData.end_date_type === 'estimated_delayed') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-error_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.error_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.using_extension_days === true && milestoneTimelineData.status === 'in_progress') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-warning_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.warning_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'in_progress') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-play"
          className="tw-text-success_main tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.success_main
    } else if (milestoneTimelineData != null && milestoneTimelineData.status === 'not_started') {
      milestoneStatusIconJSX = (
        <Icon
          icon="circle-pause"
          className="tw-text-gray_600 tw-mr-2"
        />
      )
      milestoneTimelineColor = themeVariables.gray_600
    }
    return {
      milestoneStatusIconJSX: milestoneStatusIconJSX,
      milestoneColor: milestoneTimelineColor,
    }
  }

  const openDetailsEditDialog = (
    formInputs: TsInterface_FormInputs,
    uc_setUserInterface_FormDialogDisplay: any,
    rootProject: any,
    uc_RootData_ClientKey: any,
    uc_setRootData_ClientKey: any,
    projectKey: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
  ): void => {
    // Copy of Form Data
    let copyOfFormData = { ...rootProject }
    // Correct Timestamp Formats for Form
    for (let loopInputKey in formInputs) {
      if (formInputs[loopInputKey].input_type === 'timestamp_datetime' && copyOfFormData[loopInputKey] != null) {
        copyOfFormData[loopInputKey] = returnFormattedDate(copyOfFormData[loopInputKey], 'YYYY-MM-DD HH:mm:ss')
      }
    }
    // Open Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: copyOfFormData,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Correct Timestamp Formats for Database
                  for (let loopInputKey in formInputs) {
                    if (formInputs[loopInputKey].input_type === 'timestamp_datetime') {
                      formSubmittedData[loopInputKey] = new Date(formSubmittedData[loopInputKey])
                    }
                  }
                  // Save to database
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Edit Project Details'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const removeSystemComponentFields = (deleteConfirmationText: string | JSX.Element, propsToRemove: string[]): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: deleteConfirmationText,
        icon: <Icon icon="trash" />,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateObject: TsInterface_UnspecifiedObject = {}
                for (let loopPropIndex in propsToRemove) {
                  let loopPropKey = propsToRemove[loopPropIndex]
                  updateObject[loopPropKey] = null
                }
                DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    reject(rej_DSMD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this system component?'),
      },
    })
  }

  const removeSystemAccessory = (accessoryKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Remove System Accessory'),
        icon: <Icon icon="trash" />,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
              let updateObject = {
                associated_system_accessories: getProp(us_rootProject, 'associated_system_accessories', {}),
              }
              delete updateObject.associated_system_accessories[accessoryKey]
              console.log(updateObject)
              DatabaseUpdateDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                .then((res_UD) => {
                  resolve(res_UD)
                })
                .catch((rej_UD) => {
                  reject(rej_UD)
                })
            })
          })
        },
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this system accessory?'),
      },
    })
  }

  const addSystemAccessory = (): void => {
    //
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_ActiveAccessories_Query(res_GCK.clientKey))
          .then((res_DGC) => {
            let allSystemAccessories = res_DGC.data
            let allSystemAccessoriesArray: TsInterface_UnspecifiedObject[] = []
            for (let loopSystemAccessoryIndex in allSystemAccessories) {
              allSystemAccessoriesArray.push({
                key: allSystemAccessories[loopSystemAccessoryIndex].key,
                value: allSystemAccessories[loopSystemAccessoryIndex].name,
              })
            }
            // Open Dialog
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: {},
                  formInputs: {
                    quantity: {
                      key: 'quantity',
                      label: rLIB('Quantity'),
                      input_type: 'text_number',
                      required: true,
                      data_type: 'number',
                    },
                    accessory_key: {
                      data_type: 'string',
                      input_type: 'multiple_choice_select',
                      key: 'accessory_key',
                      label: rLIB('Accessory'),
                      required: true,
                      options: allSystemAccessoriesArray,
                    },
                  },
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: formSettings_StickyNote,
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      let updateObject = {
                        associated_system_accessories: {
                          [formSubmittedData.accessory_key]: {
                            quantity: formSubmittedData.quantity,
                            key: formSubmittedData.accessory_key,
                            name: getProp(getProp(allSystemAccessories, formSubmittedData.accessory_key, {}), 'name', null),
                          },
                        },
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'warning',
                  formDialogHeaderText: rLIB('Add System Accessory'),
                  formDialogIcon: (
                    <Icon
                      icon="circle-plus"
                      type="solid"
                    />
                  ),
                },
              },
            })
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  // JSX Generation
  const rJSX_NonEditableDateLineItem = (label: string | JSX.Element, icon: string, object: TsInterface_UnspecifiedObject, propKey: string): JSX.Element => {
    let formattedDateJSX = <></>
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (getProp(object, propKey, null) != null) {
      formattedDateJSX = <>{returnFormattedDate(getProp(object, propKey, null), 'D MMM YYYY')}</>
    } else {
      formattedDateJSX = missingJSX
    }
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {formattedDateJSX}
        </Typography>
      </Box>
    )
  }

  const rJSX_NonEditableTextLineItem = (label: string | JSX.Element, icon: string, object: TsInterface_UnspecifiedObject, propKey: string): JSX.Element => {
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {getProp(object, propKey, missingJSX)}
        </Typography>
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableTextLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectTextEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableParagraphLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectParagraphEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableNumberLineItem = (
    label: string | JSX.Element | null,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    startAdornment: string | JSX.Element | undefined,
    endAdornment: string | JSX.Element | undefined,
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectNumberEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            number={getProp(object, propKey, '')}
            textCssClassName="tw-w-full"
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            onEnter={(value: number) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    if (typeof value === 'string') {
                      value = parseFloat(value)
                    }
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    if (isNaN(value)) {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        {label && (
          <Box
            className="tw-inline-block"
            sx={{ width: '30px' }}
          >
            <Icon
              icon={icon}
              type="solid"
              className="tw-inline-block tw-mr-2 tw-opacity-40"
            />
          </Box>
        )}
        {label && (
          <Typography
            variant="body1"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          >
            {label}:
          </Typography>
        )}
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableCurrencyLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = <></>
    if (getProp(object, propKey, null) != null) {
      editableFieldJSX = (
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {formatCurrency(getProp(object, propKey, ''))}
        </Typography>
      )
    } else {
      editableFieldJSX = missingJSX
    }
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectNumberEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            number={getProp(object, propKey, '')}
            textCssClassName=""
            startAdornment={'$'}
            onEnter={(value: number) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    // @ts-ignore
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    options: tsI_MultipleChoiceOption[],
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, propKey, ''), value: getProp(object, propKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'static'}
            options={options}
            onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: selectedOption.value,
                    }
                    if (selectedOption.value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                          ur_forceRerender()
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                          ur_forceRerender()
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptions = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    keyPropKey: string,
    valuePropKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    getOptions: () => Promise<tsI_MultipleChoiceOption[]>,
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, keyPropKey, null) === '') {
      object[keyPropKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, valuePropKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, keyPropKey, ''), value: getProp(object, valuePropKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'dynamic'}
            loadOptions={getOptions}
            onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
              return new Promise((resolve, reject) => {
                resolve({ success: true })
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [keyPropKey]: selectedOption.key,
                      [valuePropKey]: selectedOption.value,
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs - have to look at each instance of this
  const rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit = (
    label: string | JSX.Element | null,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    keyPropKey: string,
    valuePropKey: string,
    getOptions: () => Promise<tsI_MultipleChoiceOption[]>,
    onEnter: (selectedOption: TsInterface_UnspecifiedObject) => Promise<boolean>,
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, keyPropKey, null) === '') {
      object[keyPropKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, valuePropKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, keyPropKey, ''), value: getProp(object, valuePropKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'dynamic'}
            loadOptions={getOptions}
            onEnter={onEnter}
            searchable={true}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        {label != null ? (
          <Box
            className="tw-inline-block"
            sx={{ width: '30px' }}
          >
            <Icon
              icon={icon}
              type="solid"
              className="tw-inline-block tw-mr-2 tw-opacity-40"
            />
          </Box>
        ) : null}
        {label != null ? (
          <Typography
            variant="body1"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          >
            {label}:
          </Typography>
        ) : null}
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_PhoneLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = <></>
    if (getProp(object, propKey, null) != null) {
      // Format phone number
      editableFieldJSX = <>{formatPhoneNumber(getProp(object, propKey, ''))}</>
    } else {
      editableFieldJSX = missingJSX
    }
    // let editableFieldJSX = (
    //   <Typography
    //     variant="body1"
    //     className="tw-inline-block"
    //   >
    //     {getProp(object, propKey, missingJSX)}
    //   </Typography>
    // )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectNumberEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            number={getProp(object, propKey, null)}
            textCssClassName=" tw-w-full"
            onEnter={(value: number) => {
              return new Promise((resolve, reject) => {
                console.log(value)
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: parseInt(
                        value
                          .toString()
                          .replace(/[^0-9]/g, '')
                          .replace(/^1/, ''),
                      ),
                    }
                    if (value == null) {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    // TODO: Strip leading 1 if it exists
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_EmailLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    required: boolean,
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    if (required) {
      missingJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.warning_main }}
          component="span"
          className="tw-rounded tw-px-1 tw-py-0.5 tw-font-bold tw-text-white"
        >
          <Icon
            icon="exclamation-triangle"
            type="solid"
            className="tw-mr-1"
          />
          {rLIB('Missing')}
        </Box>
      )
    }
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectTextEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value.toLowerCase(),
                    }
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else if (dbLocation === 'additionalProjectData') {
                      DatabaseSetMergeDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  const rJSX_MinimizeSectionIcons = (minimized: boolean, setMinimized: React.Dispatch<React.SetStateAction<boolean>>, sectionKey: string): JSX.Element => {
    return (
      <Box component="span">
        {minimized ? (
          <Icon
            icon="square-caret-down"
            tooltip={rLIB('Expand')}
            className="tw-ml-2 tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimized(false)
              setPageLocalStorage(tabKey, sectionKey, false)
            }}
          />
        ) : (
          <Icon
            icon="square-caret-up"
            tooltip={rLIB('Minimize')}
            className="tw-ml-2 tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimized(true)
              setPageLocalStorage(tabKey, sectionKey, true)
            }}
          />
        )}
      </Box>
    )
  }

  // TODO: Example
  // const rJSX_ExampleTile = (): JSX.Element => {
  //   return (
  //     <Box>
  //       <Typography
  //         variant="h6"
  //         className=""
  //       >
  //         <Box className="tw-inline-block tw-opacity-50">{rLIB('Example')}</Box>
  //         {/* {rJSX_MinimizeSectionIcons(us_minimizedCustomerDetails, us_setMinimizedCustomerDetails, 'us_minimizedCustomerDetails')} */}
  //       </Typography>
  //       {us_minimizedCustomerDetails ? (
  //         <Box
  //           className="tw-p-2 tw-text-center"
  //           sx={{ border: '1px solid ' + themeVariables.background_paper }}
  //         >
  //           <Button
  //             color="inherit"
  //             className="tw-opacity-30"
  //             onClick={() => {
  //               // us_setMinimizedCustomerDetails(false)
  //               // setPageLocalStorage(tabKey, 'us_minimizedCustomerDetails', false)
  //             }}
  //           >
  //             {rLIB('View')}
  //           </Button>
  //         </Box>
  //       ) : (
  //         <Card className="tw-p-2"></Card>
  //       )}
  //     </Box>
  //   )
  // }

  // JSX - Details
  const rJSX_Avatar = (size: number): JSX.Element => {
    let avatarJSX = <></>
    avatarJSX = (
      <Avatar
        // eslint-disable-next-line react/no-children-prop
        children={stringAvatar(getProp(us_rootProject, 'associated_customer_name', ''))['children']}
        sx={{ width: size, height: size, margin: 'auto', bgcolor: stringToColor(getProp(us_rootProject, 'associated_customer_name', '')) }}
      />
    )
    return avatarJSX
  }

  const rJSX_Details_LegacyJurisdiction = (): JSX.Element => {
    let oldJurisdictionJSX = <></>
    if (getProp(us_rootProject, 'location_jurisdiction', null) != null && getProp(us_rootProject, 'associated_jurisdiction_key', null) == null) {
      oldJurisdictionJSX = (
        <Box
          sx={{ background: themeVariables.warning_main, borderRadius: '5px', padding: '5px', marginLeft: '48px' }}
          className="tw-mb-2"
        >
          <Typography
            variant="body1"
            className="tw-inline-block"
          >
            <Icon
              icon="exclamation-triangle"
              type="solid"
              className="tw-inline-block tw-mr-1"
            />
            {rLIB('Old value:')} {getProp(us_rootProject, 'location_jurisdiction', '')}
          </Typography>
        </Box>
      )
    }
    // Return
    return <Box>{oldJurisdictionJSX}</Box>
  }

  const rJSX_HOALineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('HOA'),
          'home',
          us_rootProject,
          'associated_hoa_name',
          'associated_hoa_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveHoas_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        let loopOption = res_DB.data[loopOptionKey]
                        formattedOptions.push({ value: loopOption.name, key: loopOptionKey })
                      }
                      formattedOptions.sort(dynamicSort('value', 'asc'))
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update HOA') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the HOA on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_hoa_key: selectedOption.key,
                              associated_hoa_name: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to update HOA'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
          false,
        )}
      </Box>
    )
  }

  const rJSX_JurisdictionLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Jurisdiction'),
          'gavel',
          us_rootProject,
          'associated_jurisdiction_key',
          'associated_jurisdiction_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveJurisdictions_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        let loopOption = res_DB.data[loopOptionKey]
                        let compositeName = loopOption.name
                        if (loopOption.type != null) {
                          compositeName = loopOption.type.charAt(0).toUpperCase() + loopOption.type.slice(1) + ' of ' + compositeName
                        }
                        if (loopOption.state != null) {
                          compositeName = compositeName + ', ' + loopOption.state
                        }
                        formattedOptions.push({ value: compositeName, key: loopOptionKey })
                      }
                      formattedOptions.sort(dynamicSort('value', 'asc'))
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Jurisdiction') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the jurisdiction on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_jurisdiction_key: selectedOption.key,
                              associated_jurisdiction_name: selectedOption.value,
                              location_jurisdiction: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to update jurisdiction'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_Details_LegacyUtility = (): JSX.Element => {
    let oldUtilityJSX = <></>
    if (getProp(us_rootProject, 'location_utility_company', null) != null && getProp(us_rootProject, 'associated_utility_company_key', null) == null) {
      oldUtilityJSX = (
        <Box
          sx={{ background: themeVariables.warning_main, borderRadius: '5px', padding: '5px', marginLeft: '48px' }}
          className="tw-mb-2"
        >
          <Typography
            variant="body1"
            className="tw-inline-block"
          >
            <Icon
              icon="exclamation-triangle"
              type="solid"
              className="tw-inline-block tw-mr-1"
            />
            {rLIB('Old value:')} {getProp(us_rootProject, 'location_utility_company', '')}
          </Typography>
        </Box>
      )
    }
    // Return
    return <Box>{oldUtilityJSX}</Box>
  }

  const rJSX_UtilityLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Utility Company'),
          'gavel',
          us_rootProject,
          'associated_utility_company_key',
          'associated_utility_company_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveUtilities_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        let loopOption = res_DB.data[loopOptionKey]
                        let compositeName = loopOption.name
                        if (loopOption.type != null) {
                          compositeName = loopOption.type.charAt(0).toUpperCase() + loopOption.type.slice(1) + ' of ' + compositeName
                        }
                        if (loopOption.state != null) {
                          compositeName = compositeName + ', ' + loopOption.state
                        }
                        formattedOptions.push({ value: compositeName, key: loopOptionKey })
                      }
                      formattedOptions.sort(dynamicSort('value', 'asc'))
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Utility') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the utility on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_utility_company_key: selectedOption.key,
                              associated_utility_company_name: selectedOption.value,
                              location_utility_company: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to update utility'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_ProjectStartDate = (): JSX.Element => {
    let projectStartDateJSX = <></>
    if (
      us_rootProject != null &&
      us_rootProject['associated_task_workflow_key'] != null &&
      us_rootProject['timestamp_workflow_selection'] != null &&
      us_rootProject['timestamp_workflow_selection'][us_rootProject['associated_task_workflow_key']] != null
    ) {
      projectStartDateJSX = (
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {returnFormattedDate(us_rootProject['timestamp_workflow_selection'][us_rootProject['associated_task_workflow_key']], 'D MMM YY')}
        </Typography>
      )
    } else {
      projectStartDateJSX = (
        <Box
          component="span"
          className="tw-opacity-30 tw-italic"
        >
          {rLIB('Missing')}
        </Box>
      )
    }
    let dateJSX = (
      <Box className="tw-mb-1">
        {/* <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon="calendar-star"
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box> */}
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {rLIB('Project Start Date')}:
        </Typography>
        {projectStartDateJSX}
      </Box>
    )
    return dateJSX
  }

  const rJSX_DetailsTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Details')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedCustomerDetails, us_setMinimizedCustomerDetails, 'us_minimizedCustomerDetails')}
        </Typography>
        {us_minimizedCustomerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedCustomerDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedCustomerDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box
              className="tw-inline-block tw-align-top"
              sx={{ width: '55px', height: '55px' }}
            >
              {rJSX_Avatar(55)}
            </Box>
            <Box
              className="tw-pl-4 tw-inline-block"
              sx={{ width: 'calc(100% - 55px)' }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 700 }}
              >
                {getProp(us_rootProject, 'associated_customer_name', '')}
              </Typography>
              <Typography
                variant="body1"
                onDoubleClick={() => {
                  // Open Firestore
                  if (uc_RootData_GlobalUser.sys_admin === true) {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        window.open(firestoreBaseUrl + 'clients/' + res_GCK.clientKey + '/operations/projects/main/' + pr_projectKey, '_blank')
                      })
                      .catch((rej_GCK) => {
                        console.log(rej_GCK)
                      })
                  }
                }}
              >
                {getProp(us_rootProject, 'id_number', '')}
              </Typography>
              <Box>{rJSX_ProjectStartDate()}</Box>
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Contact')}
              </Typography>
              {rJSX_PhoneLineItem(rLIB('Phone'), 'phone', us_rootProject, 'associated_customer_phone', 'rootProject', true)}
              {rJSX_DirectlyEditableParagraphLineItem(
                rLIB('Additional Contact Details'),
                'phone',
                us_rootProject,
                'associated_customer_additional_phone',
                'rootProject',
                false,
              )}

              {rJSX_EmailLineItem(rLIB('Email'), 'envelope', us_rootProject, 'associated_customer_email', 'rootProject', true)}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Address')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Address'), 'location-dot', us_rootProject, 'location_address', 'rootProject', true)}
              {rJSX_DirectlyEditableTextLineItem(rLIB('City'), 'location-dot', us_rootProject, 'location_city', 'rootProject', true)}
              {rJSX_DirectlyEditableTextLineItem(rLIB('County'), 'location-dot', us_rootProject, 'location_county', 'rootProject', false)}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Zip'), 'location-dot', us_rootProject, 'location_zip', 'rootProject', undefined, undefined, true)}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('State'),
                'location-dot',
                us_rootProject,
                'location_state',
                'rootProject',
                objectToArray(listOfUnitedStates) as unknown as tsI_MultipleChoiceOption[],
                true,
              )}
              {rJSX_NonEditableTextLineItem(rLIB('Latitude'), 'location-dot', us_rootProject, 'location_latitude')}
              {rJSX_NonEditableTextLineItem(rLIB('Longitude'), 'location-dot', us_rootProject, 'location_longitude')}
              {rJSX_DirectlyEditableParagraphLineItem(
                rLIB('Gate Code / Access Instructions'),
                'pencil',
                us_rootProject,
                'location_access_instructions',
                'rootProject',
                false,
              )}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Region')}
              </Typography>

              {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptions(
                rLIB('Region'),
                'compass',
                us_rootProject,
                'associated_region_key',
                'associated_region_name',
                'rootProject',
                () => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseGetCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey))
                          .then((res_DB) => {
                            let formattedOptions: { key: string; value: string }[] = []
                            for (let loopOptionKey in res_DB.data) {
                              formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                            }
                            resolve(formattedOptions)
                          })
                          .catch((rej_DB) => {
                            reject(rej_DB)
                          })
                      })
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                      })
                  })
                },
                true,
              )}
              {/* {rJSX_DirectlyEditableTextLineItem(rLIB('Jurisdiction'), 'gavel', us_rootProject, 'location_jurisdiction', 'rootProject')} */}
              {rJSX_JurisdictionLineItem()}
              {rJSX_Details_LegacyJurisdiction()}
              {/* {rJSX_DirectlyEditableTextLineItem(rLIB('HOA'), 'home', us_rootProject, 'associated_hoa_name', 'rootProject')} */}
              {rJSX_HOALineItem()}

              {rJSX_UtilityLineItem()}
              {rJSX_Details_LegacyUtility()}
              <Stack
                direction="row"
                className="group"
                sx={{
                  '&:hover .edit-icon-box': {
                    opacity: 1,
                  },
                }}
              >
                {rJSX_NonEditableTextLineItem(rLIB('Distance from warehouse'), 'truck', us_rootProject, 'location_distance_from_warehouse')}
                <Box
                  className="edit-icon-box"
                  sx={{ opacity: 0 }}
                >
                  <Icon
                    icon="pen-circle"
                    className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main tw-cursor-pointer tw-mt-1 tw-align-top"
                    tooltip={rLIB('Edit')}
                    tooltipPlacement="right"
                    onClick={() => {
                      openDetailsEditDialog(
                        formInputs_WarehouseDistance,
                        uc_setUserInterface_FormDialogDisplay,
                        us_rootProject,
                        uc_RootData_ClientKey,
                        uc_setRootData_ClientKey,
                        pr_projectKey,
                        uc_setUserInterface_ErrorDialogDisplay,
                      )
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Sales Partner
  const rJSX_SalesPartnerLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Sales Partner'),
          'building',
          us_rootProject,
          'associated_sales_partner_key',
          'associated_sales_partner_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Sales Partner') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the sales partner on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_sales_partner_key: selectedOption.key,
                              associated_sales_partner_name: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to update sales partner'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_SalesPartnerTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sales Partner')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedSalesPartnerDetails, us_setMinimizedSalesPartnerDetails, 'us_minimizedSalesPartnerDetails')}
        </Typography>
        {us_minimizedSalesPartnerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedSalesPartnerDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedSalesPartnerDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_light }}
              >
                {rLIB('Sales Partner')}
              </Typography>
              {rJSX_SalesPartnerLineItem()}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_light }}
              >
                {rLIB('Sales Rep')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Name'), 'location-dot', us_rootProject, 'associated_sales_rep_name', 'rootProject', false)}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Phone'), 'phone', us_rootProject, 'associated_sales_rep_phone', 'rootProject', false)}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Email'), 'envelope', us_rootProject, 'associated_sales_rep_email', 'rootProject', false)}
              {rJSX_DirectlyEditableTextLineItem(
                rLIB('Sales Rep Team'),
                'people-group',
                us_rootProject,
                'associated_sales_rep_team_name',
                'rootProject',
                false,
              )}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Financing
  const rJSX_FinancePartnerLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Finance Partner'),
          'credit-card',
          us_rootProject,
          'associated_finance_partner_key',
          'associated_finance_partner_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveFinancePartners_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Finance Partner') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the finance partner on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_finance_partner_key: selectedOption.key,
                              associated_finance_partner_name: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to update finance partner'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_FinancingTypeLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Financing Type'),
          'credit-card',
          us_rootProject,
          'associated_financing_type_key',
          'financials_financing_type',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveFinancingTypes_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    financials_financing_type: selectedOption.value,
                    associated_financing_type_key: selectedOption.key,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve1(true)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject1(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  resolve1(true)
                })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_FinancingCashLineItems = (): JSX.Element => {
    let lineItemsJSX = <></>
    if (us_rootProject.financials_financing_type === 'Cash') {
      lineItemsJSX = (
        <Box>
          {rJSX_DirectlyEditableCurrencyLineItem(rLIB('Contract Amount'), 'dollar-sign', us_rootProject, 'financials_contract_amount', 'rootProject', true)}
        </Box>
      )
    }
    return lineItemsJSX
  }

  const rJSX_FinancingLeaseLineItems = (): JSX.Element => {
    let lineItemsJSX = <></>
    if (us_rootProject.financials_financing_type === 'Lease') {
      lineItemsJSX = (
        <Box>
          {rJSX_FinancePartnerLineItem()}
          {rJSX_DirectlyEditableCurrencyLineItem(
            rLIB('Solar EPC Amount'),
            'dollar-sign',
            us_additionalProjectData,
            'financials_solar_epc_amount',
            'additionalProjectData',
            true,
          )}
          {rJSX_DirectlyEditableCurrencyLineItem(
            rLIB('Total Solar + Battery EPC Amount'),
            'dollar-sign',
            us_additionalProjectData,
            'financials_solar_plus_battery_epc_amount',
            'additionalProjectData',
            true,
          )}
          {rJSX_DirectlyEditableNumberLineItem(
            rLIB('Solar Rate'),
            'badge-percent',
            us_additionalProjectData,
            'financials_solar_rate',
            'additionalProjectData',
            '$',
            '/W',
            true,
          )}
          {rJSX_DirectlyEditableNumberLineItem(
            rLIB('Escalator'),
            'badge-percent',
            us_additionalProjectData,
            'financials_escalator',
            'additionalProjectData',
            undefined,
            '%',
            true,
          )}
          {rJSX_DirectlyEditableCurrencyLineItem(
            rLIB('Est. Y1 Solar Monthly Payment'),
            'dollar-sign',
            us_additionalProjectData,
            'financials_est_y1_solar_monthly_payment',
            'additionalProjectData',
            true,
          )}
          {rJSX_DirectlyEditableCurrencyLineItem(
            rLIB('Est. Y1 Battery Monthly Payment'),
            'dollar-sign',
            us_additionalProjectData,
            'financials_est_y1_battery_monthly_payment',
            'additionalProjectData',
            true,
          )}
        </Box>
        //   <Box>{rJSX_DirectlyEditableNumberLineItem(rLIB('Lease Payment'), 'dollar-sign', us_rootProject, 'financials_lease_payment', 'rootProject', undefined, undefined)}</Box>
      )
    }
    return lineItemsJSX
  }

  const rJSX_FinancingLoanLineItems = (): JSX.Element => {
    let lineItemsJSX = <></>
    if (us_rootProject.financials_financing_type === 'Loan') {
      lineItemsJSX = (
        <Box>
          {rJSX_FinancePartnerLineItem()}
          {rJSX_DirectlyEditableCurrencyLineItem(
            rLIB('Solar EPC Amount'),
            'dollar-sign',
            us_additionalProjectData,
            'financials_solar_epc_amount',
            'additionalProjectData',
            true,
          )}
          {rJSX_DirectlyEditableCurrencyLineItem(
            rLIB('Loan Amount'),
            'dollar-sign',
            us_additionalProjectData,
            'financials_loan_amount',
            'additionalProjectData',
            true,
          )}
          {rJSX_DirectlyEditableNumberLineItem(
            rLIB('APR'),
            'badge-percent',
            us_additionalProjectData,
            'financials_apr',
            'additionalProjectData',
            undefined,
            '%',
            true,
          )}
          {rJSX_DirectlyEditableTextLineItem(
            rLIB('Loan Term'),
            'calendar-days',
            us_additionalProjectData,
            'financials_loan_term',
            'additionalProjectData',
            true,
          )}
          {rJSX_DirectlyEditableCurrencyLineItem(
            rLIB('Dealer Fee'),
            'dollar-sign',
            us_additionalProjectData,
            'financials_dealer_fee',
            'additionalProjectData',
            true,
          )}
          {rJSX_DirectlyEditableNumberLineItem(
            rLIB('Dealer Fee (%)'),
            'badge-percent',
            us_additionalProjectData,
            'financials_dealer_fee_percentage',
            'additionalProjectData',
            undefined,
            '%',
            true,
          )}
        </Box>
      )
    }
    return lineItemsJSX
  }

  const rJSX_FinancingTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Financing')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedFinancingDetails, us_setMinimizedFinancingDetails, 'us_minimizedFinancingDetails')}
        </Typography>
        {us_minimizedFinancingDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedFinancingDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedFinancingDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            {/* <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_dark }}
              >
                {rLIB('Finance Partner')}
              </Typography>
              {rJSX_FinancePartnerLineItem()}
            </Box>
            <Divider className="tw-my-2" /> */}
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_dark }}
              >
                {rLIB('Financing Terms')}
              </Typography>

              {rJSX_FinancingTypeLineItem()}
              {/* {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Financing Type'),
                'credit-card',
                us_rootProject,
                'financials_financing_type',
                'rootProject',
                objectToArray([
                  { key: 'Cash', value: 'Cash' },
                  { key: 'Lease', value: 'Lease' },
                  { key: 'Loan', value: 'Loan' },
                ]) as unknown as tsI_MultipleChoiceOption[],
                true,
              )} */}
            </Box>
            {rJSX_FinancingCashLineItems()}
            {rJSX_FinancingLeaseLineItems()}
            {rJSX_FinancingLoanLineItems()}
          </Card>
        )}
      </Box>
    )
  }

  // JSX - System
  const rJSX_ProductPackageLineItem = (): JSX.Element => {
    // {rJSX_DirectlyEditableTextLineItem(rLIB('Product Package'), 'cart-shopping', us_rootProject, 'associated_product_name', 'rootProject', true)}

    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Product Package'),
          'cart-shopping',
          us_rootProject,
          'associated_product_key',
          'associated_product_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveProductPackages_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    associated_product_name: selectedOption.value,
                    associated_product_key: selectedOption.key,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve1(true)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject1(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  resolve1(true)
                })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_LegacyModuleLineItem = (): JSX.Element => {
    let oldDataJSX = <></>
    if (getProp(us_rootProject, 'system_panel_model', null) != null && getProp(us_rootProject, 'associated_system_module_key', null) == null) {
      oldDataJSX = (
        <Box
          sx={{ background: themeVariables.warning_main, borderRadius: '5px', padding: '5px' }}
          className="tw-mb-2"
        >
          <Typography
            variant="body1"
            className="tw-inline-block"
          >
            <Icon
              icon="exclamation-triangle"
              type="solid"
              className="tw-inline-block tw-mr-1"
            />
            {rLIB('Old value:')} {getProp(us_rootProject, 'system_panel_manufacturer', '')} - {getProp(us_rootProject, 'system_panel_model', '')}
          </Typography>
        </Box>
      )
    }
    // Return
    return <Box>{oldDataJSX}</Box>
  }

  const rJSX_ModulesLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          null,
          'circle',
          us_rootProject,
          'associated_system_module_key',
          'associated_system_module_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveModules_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        let loopOption = res_DB.data[loopOptionKey]
                        let compositeName = loopOption.associated_manufacturer_name + ' - ' + loopOption.name
                        formattedOptions.push({ value: compositeName, key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    associated_system_module_key: selectedOption.key,
                    associated_system_module_name: selectedOption.value,
                    system_panel_manufacturer: selectedOption.value,
                    system_panel_model: selectedOption.value,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(true)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  resolve(true)
                })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_LegacyInvertersLineItem = (): JSX.Element => {
    let oldDataJSX = <></>
    if (getProp(us_rootProject, 'system_inverter_model', null) != null && getProp(us_rootProject, 'associated_system_inverter_key', null) == null) {
      oldDataJSX = (
        <Box
          sx={{ background: themeVariables.warning_main, borderRadius: '5px', padding: '5px' }}
          className="tw-mb-2"
        >
          <Typography
            variant="body1"
            className="tw-inline-block"
          >
            <Icon
              icon="exclamation-triangle"
              type="solid"
              className="tw-inline-block tw-mr-1"
            />
            {rLIB('Old value:')} {getProp(us_rootProject, 'system_inverter_manufacturer', '')} - {getProp(us_rootProject, 'system_inverter_model', '')}
          </Typography>
        </Box>
      )
    }
    // Return
    return <Box>{oldDataJSX}</Box>
  }

  const rJSX_InvertersLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          null,
          'circle',
          us_rootProject,
          'associated_system_inverter_key',
          'associated_system_inverter_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveInverters_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        let loopOption = res_DB.data[loopOptionKey]
                        let compositeName = loopOption.associated_manufacturer_name + ' - ' + loopOption.name
                        formattedOptions.push({ value: compositeName, key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    associated_system_inverter_key: selectedOption.key,
                    associated_system_inverter_name: selectedOption.value,
                    system_inverter_manufacturer: selectedOption.value,
                    system_inverter_model: selectedOption.value,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(true)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  resolve(true)
                })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_RackingLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          null,
          'circle',
          us_rootProject,
          'associated_system_racking_key',
          'associated_system_racking_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveRacking_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        let loopOption = res_DB.data[loopOptionKey]
                        let compositeName = loopOption.associated_manufacturer_name + ' - ' + loopOption.name
                        formattedOptions.push({ value: compositeName, key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    associated_system_racking_key: selectedOption.key,
                    associated_system_racking_name: selectedOption.value,
                    system_racking_manufacturer: selectedOption.value,
                    system_racking_model: selectedOption.value,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(true)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  resolve(true)
                })
            })
          },
          true,
        )}
      </Box>
    )
  }

  const rJSX_LegacyBatteriesLineItem = (): JSX.Element => {
    let oldDataJSX = <></>
    if (getProp(us_rootProject, 'system_storage_model', null) != null && getProp(us_rootProject, 'associated_system_storage_key', null) == null) {
      oldDataJSX = (
        <Box
          sx={{ background: themeVariables.warning_main, borderRadius: '5px', padding: '5px' }}
          className="tw-mb-2"
        >
          <Typography
            variant="body1"
            className="tw-inline-block"
          >
            <Icon
              icon="exclamation-triangle"
              type="solid"
              className="tw-inline-block tw-mr-1"
            />
            {rLIB('Old value:')} {getProp(us_rootProject, 'system_storage_manufacturer', '')} - {getProp(us_rootProject, 'system_storage_model', '')}
          </Typography>
        </Box>
      )
    }
    // Return
    return <Box>{oldDataJSX}</Box>
  }

  const rJSX_BatteriesLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          null,
          'circle',
          us_rootProject,
          'associated_system_storage_key',
          'associated_system_storage_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveBatteries_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        let loopOption = res_DB.data[loopOptionKey]
                        let compositeName = loopOption.associated_manufacturer_name + ' - ' + loopOption.name
                        formattedOptions.push({ value: compositeName, key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    associated_system_storage_key: selectedOption.key,
                    associated_system_storage_name: selectedOption.value,
                    system_storage_manufacturer: selectedOption.value,
                    system_storage_model: selectedOption.value,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(true)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  resolve(true)
                })
            })
          },
          false,
        )}
      </Box>
    )
  }

  const rJSX_AccessoryLineHeader = (): JSX.Element => {
    return (
      <Stack
        direction="row"
        className="group"
        sx={{
          '&:hover .edit-icon-box': {
            opacity: 1,
          },
        }}
      >
        <Typography variant="body1">
          <Box
            component="span"
            className="tw-inline-block"
            sx={{ width: '30px' }}
          >
            <Icon icon="meter-bolt" />
          </Box>
          {rLIB('Accessories')}:
        </Typography>
        <Box
          className="edit-icon-box"
          sx={{ opacity: 0 }}
        >
          <Icon
            icon="circle-plus"
            className="tw-mt-1.5 tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_light tw-cursor-pointer tw-align-top"
            tooltip={rLIB('Add Accessory')}
            tooltipPlacement="right"
            onClick={() => {
              addSystemAccessory()
            }}
          />
        </Box>
      </Stack>
    )
  }

  const rJSX_SystemComponents = (): JSX.Element => {
    return (
      <Box className="tw-pl-4">
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ opacity: 0.4, padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Stack
                    direction="row"
                    className="group"
                    sx={{
                      '&:hover .edit-icon-box': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography variant="body1">
                      <Box
                        component="span"
                        className="tw-inline-block"
                        sx={{ width: '30px' }}
                      >
                        <Icon icon="solar-panel" />
                      </Box>
                      {rLIB('Modules')}:
                    </Typography>
                    <Box
                      className="edit-icon-box"
                      sx={{ opacity: 0 }}
                    >
                      <Icon
                        icon="circle-x"
                        className="tw-mt-1.5 tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_light tw-cursor-pointer tw-align-top"
                        tooltip={rLIB('Remove Module')}
                        tooltipPlacement="right"
                        onClick={() => {
                          removeSystemComponentFields(<>{rLIB('Remove Module')}</>, [
                            'associated_system_module_key',
                            'associated_system_module_name',
                            'system_panel_quantity',
                            'system_panel_manufacturer',
                            'system_panel_model',
                          ])
                        }}
                      />
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Box sx={{ marginLeft: '-16px' }}>
                    {rJSX_DirectlyEditableNumberLineItem(
                      null,
                      'solar-panel',
                      us_rootProject,
                      'system_panel_quantity',
                      'rootProject',
                      undefined,
                      undefined,
                      true,
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Box sx={{ marginLeft: '-16px' }}>{rJSX_ModulesLineItem()}</Box>
                  <Box>{rJSX_LegacyModuleLineItem()}</Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ opacity: 0.4, padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Stack
                    direction="row"
                    className="group"
                    sx={{
                      '&:hover .edit-icon-box': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography variant="body1">
                      <Box
                        component="span"
                        className="tw-inline-block"
                        sx={{ width: '30px' }}
                      >
                        <Icon icon="transformer-bolt" />
                      </Box>
                      {rLIB('Inverters')}:
                    </Typography>
                    <Box
                      className="edit-icon-box"
                      sx={{ opacity: 0 }}
                    >
                      <Icon
                        icon="circle-x"
                        className="tw-mt-1.5 tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_light tw-cursor-pointer tw-align-top"
                        tooltip={rLIB('Remove Inverter')}
                        tooltipPlacement="right"
                        onClick={() => {
                          removeSystemComponentFields(<>{rLIB('Remove Inverter')}</>, [
                            'associated_system_inverter_key',
                            'associated_system_inverter_name',
                            'system_inverter_quantity',
                            'system_inverter_manufacturer',
                            'system_inverter_model',
                          ])
                        }}
                      />
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Box sx={{ marginLeft: '-16px' }}>
                    {rJSX_DirectlyEditableNumberLineItem(
                      null,
                      'transformer-bolt',
                      us_rootProject,
                      'system_inverter_quantity',
                      'rootProject',
                      undefined,
                      undefined,
                      true,
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Box sx={{ marginLeft: '-16px' }}>{rJSX_InvertersLineItem()}</Box>
                  <Box>{rJSX_LegacyInvertersLineItem()}</Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ opacity: 0.4, padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Stack
                    direction="row"
                    className="group"
                    sx={{
                      '&:hover .edit-icon-box': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography variant="body1">
                      <Box
                        component="span"
                        className="tw-inline-block"
                        sx={{ width: '30px' }}
                      >
                        <Icon icon="ruler-triangle" />
                      </Box>
                      {rLIB('Racking')}:
                    </Typography>
                    <Box
                      className="edit-icon-box"
                      sx={{ opacity: 0 }}
                    >
                      <Icon
                        icon="circle-x"
                        className="tw-mt-1.5 tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_light tw-cursor-pointer tw-align-top"
                        tooltip={rLIB('Remove Racking')}
                        tooltipPlacement="right"
                        onClick={() => {
                          removeSystemComponentFields(<>{rLIB('Remove Racking')}</>, [
                            'associated_system_racking_key',
                            'associated_system_racking_name',
                            'system_racking_quantity',
                            'system_racking_manufacturer',
                            'system_racking_model',
                          ])
                        }}
                      />
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Box sx={{ marginLeft: '-16px' }}>
                    {rJSX_DirectlyEditableNumberLineItem(
                      null,
                      'battery-three-quarters',
                      us_rootProject,
                      'system_racking_quantity',
                      'rootProject',
                      undefined,
                      undefined,
                      true,
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Box sx={{ marginLeft: '-16px' }}>{rJSX_RackingLineItem()}</Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ opacity: 0.4, padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Stack
                    direction="row"
                    className="group"
                    sx={{
                      '&:hover .edit-icon-box': {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography variant="body1">
                      <Box
                        component="span"
                        className="tw-inline-block"
                        sx={{ width: '30px' }}
                      >
                        <Icon icon="battery-three-quarters" />
                      </Box>
                      {rLIB('Batteries')}:
                    </Typography>
                    <Box
                      className="edit-icon-box"
                      sx={{ opacity: 0 }}
                    >
                      <Icon
                        icon="circle-x"
                        className="tw-mt-1.5 tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_light tw-cursor-pointer tw-align-top"
                        tooltip={rLIB('Remove Battery')}
                        tooltipPlacement="right"
                        onClick={() => {
                          removeSystemComponentFields(<>{rLIB('Remove Battery')}</>, [
                            'associated_system_storage_key',
                            'associated_system_storage_name',
                            'system_storage_quantity',
                            'system_storage_manufacturer',
                            'system_storage_model',
                          ])
                        }}
                      />
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Box sx={{ marginLeft: '-16px' }}>
                    {rJSX_DirectlyEditableNumberLineItem(
                      null,
                      'battery-three-quarters',
                      us_rootProject,
                      'system_storage_quantity',
                      'rootProject',
                      undefined,
                      undefined,
                      false,
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                  <Box sx={{ marginLeft: '-16px' }}>{rJSX_BatteriesLineItem()}</Box>
                  <Box>{rJSX_LegacyBatteriesLineItem()}</Box>
                </TableCell>
              </TableRow>
              {objectToArray(us_rootProject.associated_system_accessories).length == 0 && (
                <TableRow>
                  <TableCell sx={{ opacity: 0.4, padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>{rJSX_AccessoryLineHeader()}</TableCell>
                  <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}></TableCell>
                  <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}></TableCell>
                </TableRow>
              )}
              {objectToArray(us_rootProject.associated_system_accessories)
                .sort(dynamicSort('name', 'asc'))
                .map((accessory: TsInterface_UnspecifiedObject, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ opacity: 0.4, padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                      {index === 0 ? rJSX_AccessoryLineHeader() : <></>}
                    </TableCell>
                    <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                      <Typography variant="body1">{accessory.quantity}</Typography>
                    </TableCell>
                    <TableCell sx={{ padding: '0px', borderBottom: '0px solid rgba(0,0,0,0)' }}>
                      <Stack
                        direction="row"
                        className="group"
                        sx={{
                          '&:hover .edit-icon-box': {
                            opacity: 1,
                          },
                        }}
                      >
                        <Typography variant="body1">{accessory.name}</Typography>
                        <Box
                          className="edit-icon-box"
                          sx={{ opacity: 0 }}
                        >
                          <Icon
                            icon="circle-x"
                            className="tw-mt-1.5 tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_light tw-cursor-pointer tw-align-top"
                            tooltip={rLIB('Remove Accessory')}
                            tooltipPlacement="right"
                            onClick={() => {
                              removeSystemAccessory(accessory.key)
                            }}
                          />
                        </Box>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  const rJSX_SystemTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('System')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedSystemDetails, us_setMinimizedSystemDetails, 'us_minimizedSystemDetails')}
        </Typography>
        {us_minimizedSystemDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedSystemDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedSystemDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Type')}
              </Typography>
              {/* {rJSX_DirectlyEditableTextLineItem(rLIB('Product Package'), 'cart-shopping', us_rootProject, 'associated_product_name', 'rootProject', true)} */}
              {rJSX_ProductPackageLineItem()}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Stats')}
              </Typography>
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('System Size DC'),
                'solar-panel',
                us_rootProject,
                'system_size_dc',
                'rootProject',
                undefined,
                'kW',
                true,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('System Size AC'),
                'solar-panel',
                us_rootProject,
                'system_size_ac',
                'rootProject',
                undefined,
                'kW',
                true,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Est. Y1 Production'),
                'solar-panel',
                us_rootProject,
                'system_estimated_annual_production',
                'rootProject',
                undefined,
                'kWH',
                true,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Total Storage'),
                'solar-panel',
                us_rootProject,
                'system_storage_total_kwh',
                'rootProject',
                undefined,
                'kWH',
                false,
              )}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Components')}
              </Typography>
              {rJSX_SystemComponents()}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Design
  const rJSX_HomeDetailsTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Design')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedHomeDetails, us_setMinimizedHomeDetails, 'us_minimizedHomeDetails')}
        </Typography>
        {us_minimizedHomeDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedHomeDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedHomeDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.error_main }}
              >
                {rLIB('Home')}
              </Typography>
              {/* {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Home Type'),
                'home',
                us_additionalProjectData,
                'home_building_type',
                'additionalProjectData',
                objectToArray(homeBuildingTypeOptions) as unknown as tsI_MultipleChoiceOption[],
                false,
              )} */}
              {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptions(
                rLIB('Home Type'),
                'home',
                us_additionalProjectData,
                'associated_building_type_key',
                'home_building_type',
                'additionalProjectData',
                () => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseGetCollection(DatabaseRef_ActiveBuildingTypes_Query(res_GCK.clientKey))
                          .then((res_DB) => {
                            let formattedOptions: { key: string; value: string }[] = []
                            for (let loopOptionKey in res_DB.data) {
                              formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                            }
                            resolve(formattedOptions)
                          })
                          .catch((rej_DB) => {
                            reject(rej_DB)
                          })
                      })
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                      })
                  })
                },
                false,
              )}
              {/* {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Roof Type'),
                'home',
                us_additionalProjectData,
                'home_roof_type',
                'additionalProjectData',
                objectToArray(roofTypeOptions) as unknown as tsI_MultipleChoiceOption[],
              )} */}
              {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptions(
                rLIB('Roof/Mount Type'),
                'home',
                us_additionalProjectData,
                'associated_mount_type_key',
                'associated_mount_type_name',
                'additionalProjectData',
                () => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseGetCollection(DatabaseRef_ActiveMountTypes_Query(res_GCK.clientKey))
                          .then((res_DB) => {
                            let formattedOptions: { key: string; value: string }[] = []
                            for (let loopOptionKey in res_DB.data) {
                              formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                            }
                            resolve(formattedOptions)
                          })
                          .catch((rej_DB) => {
                            reject(rej_DB)
                          })
                      })
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                      })
                  })
                },
                false,
              )}

              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Max Roof Pitch'),
                'home',
                us_rootProject,
                'system_max_roof_pitch',
                'rootProject',
                undefined,
                undefined,
                false,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Number of Stories'),
                'home',
                us_additionalProjectData,
                'home_number_of_stories',
                'additionalProjectData',
                undefined,
                undefined,
                false,
              )}
            </Box>
            <Divider className="tw-my-2" />
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, color: themeVariables.error_main }}
            >
              {rLIB('Layout')}
            </Typography>
            {rJSX_DirectlyEditableNumberLineItem(
              rLIB('Number of Arrays'),
              'solar-panel',
              us_rootProject,
              'system_number_of_arrays',
              'rootProject',
              undefined,
              undefined,
              false,
            )}
            {rJSX_DirectlyEditableNumberLineItem(
              rLIB('Number of Strings'),
              'solar-panel',
              us_rootProject,
              'system_number_of_strings',
              'rootProject',
              undefined,
              undefined,
              false,
            )}
            {rJSX_DirectlyEditableParagraphLineItem(
              rLIB('Design Notes'),
              'pencil',
              us_additionalProjectData,
              'home_design_notes',
              'additionalProjectData',
              false,
            )}
            <Divider className="tw-my-2" />
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, color: themeVariables.error_main }}
            >
              {rLIB('Customer Requests')}
            </Typography>
            <Box>
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Attic Run'),
                'home',
                us_rootProject,
                'system_has_attic_run',
                'rootProject',
                objectToArray([
                  { key: 'yes', value: 'yes' },
                  { key: 'no', value: 'no' },
                ]) as unknown as tsI_MultipleChoiceOption[],
                false,
              )}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Paint Conduit'),
                'brush',
                us_additionalProjectData,
                'home_paint_conduit',
                'additionalProjectData',
                objectToArray([
                  { key: 'yes', value: 'yes' },
                  { key: 'no', value: 'no' },
                ]) as unknown as tsI_MultipleChoiceOption[],
                false,
              )}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Minimize panels at front of house'),
                'home',
                us_additionalProjectData,
                'home_minimize_panels_at_front_of_house',
                'additionalProjectData',
                objectToArray([
                  { key: 'yes', value: 'yes' },
                  { key: 'no', value: 'no' },
                ]) as unknown as tsI_MultipleChoiceOption[],
                false,
              )}
            </Box>
            {rJSX_DirectlyEditableNumberLineItem(
              rLIB('Usage Offset'),
              'badge-percent',
              us_rootProject,
              'system_usage_offset',
              'rootProject',
              undefined,
              '%',
              false,
            )}
            {rJSX_DirectlyEditableParagraphLineItem(
              rLIB('Other Requests'),
              'pencil',
              us_additionalProjectData,
              'home_other_customer_requests',
              'additionalProjectData',
              false,
            )}

            {/* <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.error_main }}
              >
                {rLIB('Entities')}
              </Typography>
            </Box> */}
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Customer Feedback
  const rJSX_CustomerFeedbackTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Feedback')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedCustomerFeedback, us_setMinimizedCustomerFeedback, 'us_minimizedCustomerFeedback')}
        </Typography>
        {us_minimizedCustomerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedCustomerFeedback(false)
                setPageLocalStorage(tabKey, 'us_minimizedCustomerFeedback', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Box>{rJSX_FeedbackDetails()}</Box>
        )}
      </Box>
    )
  }

  const rJSX_FeedbackDetails = (): JSX.Element => {
    let feedbackDetailsJSX = <></>
    if (us_projectFeedback != null && objectToArray(us_projectFeedback).length > 0) {
      feedbackDetailsJSX = (
        <Box>
          {/* <FeedbackNpsGraph
            feedbackData={ projectFeedback }
            feedbackSettings={ feedbackGraphSettings }
          /> */}
          <FeedbackNpsComments
            feedbackData={us_projectFeedback}
            feedbackSettings={{
              sort_order: 'timestamp_created',
              mapping: {
                rating: 'feedback_rating',
                name: 'associated_user_name',
                text: 'feedback_notes',
                date: 'timestamp_created',
                chips: {
                  feedback_topic: {
                    key: 'feedback_topic',
                    // color: "inherit"
                  },
                },
              },
              display_buttons: {
                associated_project_key: false,
              },
            }}
          />
        </Box>
      )
    } else {
      feedbackDetailsJSX = (
        <Card className="tw-p-2">
          <Box className="tw-text-center">
            <Typography
              variant="subtitle1"
              className="tw-opacity-30 tw-italic"
            >
              {rLIB('No feedback for project')}
            </Typography>
          </Box>
        </Card>
      )
    }
    return feedbackDetailsJSX
  }

  // JSX - Last Contact
  const rJSX_LastContactTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Last Contact')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedLastContact, us_setMinimizedLastContact, 'us_minimizedLastContact')}
        </Typography>
        {us_minimizedLastContact ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedLastContact(false)
                setPageLocalStorage(tabKey, 'us_minimizedLastContact', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box className="">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.warning_main }}
              >
                {rLIB('Last Contact')}
              </Typography>
              {rJSX_NonEditableDateLineItem(rLIB('Last Contact Date'), 'calendar-days', us_rootProject, 'timestamp_last_contact_log')}
              {rJSX_NonEditableTextLineItem(rLIB('Last Contactor'), 'user', us_rootProject, 'last_contact_log_person')}
              {rJSX_NonEditableTextLineItem(rLIB('Last Contact Method'), 'mobile-screen', us_rootProject, 'last_contact_log_type')}
              {rJSX_NonEditableTextLineItem(rLIB('Notes'), 'comment', us_rootProject, 'last_contact_log_notes')}
            </Box>

            {/* <ReactTimeAgo date={returnDateFromUnknownDateFormat(rootProject.timestamp_last_contact_log)} /> */}
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Timeline
  const rJSX_InPhaseDays = (actualDays: number, estimatedDays: number, extensionDays: number, status: string): JSX.Element => {
    let phaseDaysJSX: JSX.Element = <></>
    if (actualDays == null) {
      actualDays = 0
    }
    if (estimatedDays == null) {
      estimatedDays = 0
    }
    if (extensionDays == null) {
      extensionDays = 0
    }
    let color = themeVariables.gray_600
    if (actualDays < estimatedDays) {
      color = themeVariables.success_main
    } else if (actualDays < estimatedDays + extensionDays) {
      color = themeVariables.warning_main
    } else {
      color = themeVariables.error_main
    }
    let textColor = themeVariables.white
    let boxType = 'contained'
    if (status === 'complete') {
      boxType = 'contained'
    } else if (status === 'in_progress') {
      boxType = 'outlined'
    } else if (status === 'not_started') {
      boxType = 'outlined'
      color = themeVariables.gray_600
      textColor = themeVariables.gray_600
    }
    if (boxType === 'contained') {
      if (extensionDays > 0) {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md "
            sx={{ backgroundColor: color, color: textColor }}
          >
            {actualDays} /{' '}
            <Box
              component="span"
              className="tw-italic tw-line-through tw-opacity-50"
            >
              {estimatedDays}
            </Box>{' '}
            {estimatedDays + extensionDays} {estimatedDays + extensionDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      } else {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md "
            sx={{ backgroundColor: color, color: textColor }}
          >
            {actualDays} / {estimatedDays} {estimatedDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      }
    } else {
      if (extensionDays > 0) {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md"
            sx={{ border: `1px solid ${color}`, color: textColor }}
          >
            {actualDays} /{' '}
            <Box
              component="span"
              className="tw-italic tw-line-through tw-opacity-50"
            >
              {estimatedDays}
            </Box>{' '}
            {estimatedDays + extensionDays} {estimatedDays + extensionDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      } else {
        phaseDaysJSX = (
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md"
            sx={{ border: `1px solid ${color}`, color: textColor }}
          >
            {actualDays} / {estimatedDays} {estimatedDays === 1 ? rLIB('day') : rLIB('days')}
          </Box>
        )
      }
    }

    // phaseDaysJSX = <Box className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-gray_700">{estimatedDays}</Box>

    return phaseDaysJSX
  }

  const rJSX_PhaseDate = (phaseDate: number, phaseDateType: string, phaseDateOriginal: number | null, typeOverride: string | null): JSX.Element => {
    let phaseStartDateJSX: JSX.Element = <></>
    if (typeOverride === 'sticky_note') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-secondary_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="note"
            className="tw-ml-2"
            tooltip={rLIB('Sticky Note')}
          />
        </Box>
      )
    } else if (phaseDateType === 'actual') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-check"
            className="tw-ml-2"
            tooltip={rLIB('Actual Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'estimated') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-ml-2 tw-text-info_main"
            tooltip={rLIB('Estimated Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'estimated_delayed') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-info_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-ml-2 tw-text-info_main"
            tooltip={rLIB('Estimated Date')}
          />
        </Box>
      )
    } else if (phaseDateType === 'corrected_actual') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-bg-warning_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {phaseDateOriginal != null ? (
            <>
              <Box className="tw-inline-block tw-mr-2">{returnFormattedDate(phaseDateOriginal, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          {phaseDate != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1 tw-line-through tw-italic tw-opacity-50">{returnFormattedDate(phaseDate, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          <Icon
            icon="circle-info"
            className=" tw-ml-2"
            tooltip={rLIB('Milestone Completed Out of Order')}
          />
        </Box>
      )
    } else if (phaseDateType === 'range') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-gray_600"
          sx={{ minWidth: '110px', textAlign: 'center', color: themeVariables.gray_600 }}
        >
          {phaseDate != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1">{returnFormattedDate(phaseDate, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          {phaseDate != null && phaseDateOriginal != null ? <>{'- '}</> : <></>}
          {phaseDateOriginal != null ? (
            <>
              <Box className="tw-inline-block tw-mr-1">{returnFormattedDate(phaseDateOriginal, 'D MMM YY')}</Box>
            </>
          ) : (
            <></>
          )}
          <Icon
            icon="circle-info"
            className=" tw-ml-2"
            tooltip={rLIB('Estimated Date Range')}
          />
        </Box>
      )
    } else if (phaseDateType === 'corrected_estimated') {
      phaseStartDateJSX = (
        <Box
          className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-md tw-border tw-border-warning_main"
          sx={{ minWidth: '110px', textAlign: 'center' }}
        >
          {returnFormattedDate(phaseDate, 'D MMM YY')}
          <Icon
            icon="circle-info"
            className="tw-text-warning_main tw-ml-2"
            tooltip={rLIB('Milestone Completed Out of Order')}
          />
        </Box>
      )
    }
    return phaseStartDateJSX
  }

  const rJSX_TimelineTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Timeline')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedEstimatedTimeline, us_setMinimizedEstimatedTimeline, 'us_minimizedEstimatedTimeline')}
        </Typography>
        {us_minimizedEstimatedTimeline ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedEstimatedTimeline(false)
                setPageLocalStorage(tabKey, 'us_minimizedEstimatedTimeline', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Box className="">
            {us_sortedMilestonePhaseTasksArray.map((milestoneTask: TsInterface_UnspecifiedObject, index: number) => (
              <Card
                key={index}
                className="tw-mb-1 tw-p-2"
                sx={{ borderLeft: `8px solid ${returnMilestoneColorsAndIcons(us_milestoneTimelineDates[milestoneTask.key]).milestoneColor}` }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  className="tw-mb-0"
                >
                  <Typography
                    variant="body1"
                    className="tw-mb-1"
                    sx={{ fontWeight: 900, fontSize: '18px' }}
                  >
                    {returnMilestoneColorsAndIcons(us_milestoneTimelineDates[milestoneTask.key]).milestoneStatusIconJSX}
                    {milestoneTask.name}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  className="tw-mb-0"
                >
                  {rJSX_PhaseDate(
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date_type,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).start_date_original,
                    null,
                  )}
                  <Typography className="tw-mt-1">{rLIB('to')}</Typography>
                  {rJSX_PhaseDate(
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date_type,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).end_date_original,
                    null,
                  )}
                  {rJSX_InPhaseDays(
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).actual_days,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).expected_days,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).extension_days,
                    getProp(us_milestoneTimelineDates, milestoneTask.key, {}).status,
                  )}
                </Stack>
              </Card>
            ))}
          </Box>
        )}
      </Box>
    )
  }

  // JSX - Sticky Notes
  const rJSX_EditStickyNoteIcon = (
    readOrWrite: 'read' | 'write',
    note: TsInterface_UnspecifiedObject,
    uc_RootData_ClientKey: any,
    uc_setRootData_ClientKey: any,
    uc_setUserInterface_FormDialogDisplay: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    projectKey: any,
  ) => {
    let editIconJSX = <></>
    if (readOrWrite === 'write') {
      editIconJSX = (
        <Tooltip title={rLIB('Edit Sticky Note')}>
          <Box
            className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            onClick={() => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {
                      note: note.note,
                      date: returnFormattedDate(note.timestamp, 'YYYY-MM-DD'),
                    },
                    formInputs: formInputs_NewStickyNote,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: formSettings_StickyNote,
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject = {
                          sticky_notes: {
                            [note.key]: {
                              note: formSubmittedData.note,
                              timestamp: new Date(
                                returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                              ),
                            },
                          },
                        }
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'warning',
                    formDialogHeaderText: rLIB('Edit Sticky Note'),
                    formDialogIcon: (
                      <Icon
                        icon="note"
                        type="solid"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon icon="pen-to-square"></Icon>
          </Box>
        </Tooltip>
      )
    }
    return editIconJSX
  }

  const rJSX_DeleteStickyNoteIcon = (
    readOrWrite: 'read' | 'write',
    noteKey: any,
    note: TsInterface_UnspecifiedObject,
    uc_RootData_ClientKey: any,
    uc_setRootData_ClientKey: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    uc_setUserInterface_ConfirmDialogDisplay: any,
    projectKey: any,
  ) => {
    let deleteIconJSX = <></>
    if (readOrWrite === 'write') {
      deleteIconJSX = (
        <Tooltip title={rLIB('Delete Sticky Note')}>
          <Box className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2">
            <Icon
              icon="trash"
              onClick={() => {
                uc_setUserInterface_ConfirmDialogDisplay({
                  display: true,
                  confirm: {
                    color: 'error',
                    header: <>{rLIB('Delete Sticky Note')}</>,
                    icon: <Icon icon="trash" />,
                    submit_callback: () => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              sticky_notes: {
                                [noteKey]: null,
                              },
                              sticky_notes_deleted: {
                                [noteKey]: note,
                              },
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                    submit_text: rLIB('Delete'),
                    text: rLIB('Are you sure that you want to delete this sticky note?'),
                  },
                })
              }}
            />
          </Box>
        </Tooltip>
      )
    }
    return deleteIconJSX
  }

  const rJSX_StickyNotesTile = (): JSX.Element => {
    let stickyNoteContent = <></>
    let newNoteButtonJSX = <></>
    if (pr_readOrWrite === 'write') {
      newNoteButtonJSX = (
        <Icon
          icon="circle-plus"
          type="solid"
          className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
          tooltip={rLIB('New Sticky Note')}
          tooltipPlacement="top"
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: {
                    date: returnFormattedDate(new Date(), 'YYYY-MM-DD'),
                  },
                  formInputs: formInputs_NewStickyNote,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: formSettings_StickyNote,
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      let newNoteId = uuidv4()
                      let updateObject = {
                        sticky_notes: {
                          [newNoteId]: {
                            timestamp: new Date(
                              returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                            ),
                            note: formSubmittedData.note,
                            key: newNoteId,
                          },
                        },
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'warning',
                  formDialogHeaderText: rLIB('Add Sticky Note'),
                  formDialogIcon: (
                    <Icon
                      icon="note"
                      type="solid"
                    />
                  ),
                },
              },
            })
          }}
        />
      )
    }
    let stickyNotes = getProp(us_rootProject, 'sticky_notes', {})
    if (objectToArray(stickyNotes).length > 0) {
      stickyNoteContent = (
        <Box>
          {objectToArray(stickyNotes)
            .sort(dynamicSort('timestamp', 'desc'))
            .map((stickyNote: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Icon
                  icon="note"
                  className="tw-mr-1"
                  sx={{ color: themeVariables.secondary_main }}
                />
                <Box
                  component="span"
                  className="tw-inline-block tw-mr-1"
                  sx={{ fontWeight: 'bold' }}
                >
                  {returnFormattedDate(stickyNote.timestamp, 'M/D/YY')}
                  {' - '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-40"
                >
                  {stickyNote.note}
                </Box>
                {rJSX_EditStickyNoteIcon(
                  pr_readOrWrite,
                  stickyNote,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_setUserInterface_FormDialogDisplay,
                  uc_setUserInterface_ErrorDialogDisplay,
                  pr_projectKey,
                )}
                {rJSX_DeleteStickyNoteIcon(
                  pr_readOrWrite,
                  stickyNote.key,
                  stickyNote,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_setUserInterface_ErrorDialogDisplay,
                  uc_setUserInterface_ConfirmDialogDisplay,
                  pr_projectKey,
                )}
              </Box>
            ))}
        </Box>
      )
    } else {
      stickyNoteContent = <Box className="tw-italic tw-opacity-50 tw-inline-block">{rLIB('No Sticky Notes')}</Box>
    }
    // Return JSX
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sticky Notes')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedStickyNotes, us_setMinimizedStickyNotes, 'us_minimizedStickyNotes')}
          {us_minimizedStickyNotes ? <></> : newNoteButtonJSX}
        </Typography>
        {us_minimizedStickyNotes ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedStickyNotes(false)
                setPageLocalStorage(tabKey, 'us_minimizedStickyNotes', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">{stickyNoteContent}</Card>
        )}
      </Box>
    )
  }

  // JSX - Field Tasks
  const rJSX_ProjectTasksGrid = (): JSX.Element => {
    const taskArray = us_projectTasks
      ? Object.values(us_projectTasks).map((task) => ({
          ...task,
          team_members: task.task_completion_scheduled_team_names ? getUniqueTeamMembers(task.task_completion_scheduled_team_names) : [],
          last_scheduled_date: Array.isArray(task.task_completion_scheduled_dates)
            ? task.task_completion_scheduled_dates[task.task_completion_scheduled_dates.length - 1]
              ? dayjs(new Date(task.task_completion_scheduled_dates[task.task_completion_scheduled_dates.length - 1])).format('DD MMM YYYY')
              : 'N/A'
            : 'N/A',
          completed_date: task.timestamp_completed ? dayjs(task.timestamp_completed.seconds * 1000).format('DD MMM YYYY') : 'N/A',
        }))
      : []
    const filteredTasks = taskArray.filter((task) => task.associated_team_name)
    const sortedTasks = filteredTasks.sort((a, b) => {
      const dateA = a.last_scheduled_date === 'N/A' ? 0 : dayjs(a.last_scheduled_date, 'DD MMM YYYY').valueOf()
      const dateB = b.last_scheduled_date === 'N/A' ? 0 : dayjs(b.last_scheduled_date, 'DD MMM YYYY').valueOf()
      return dateA - dateB
    })

    return (
      <Box>
        <Typography variant="h6">
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Field Tasks')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedProjectTasks, us_setMinimizedProjectTasks, 'us_minimizedProjectTasks')}
        </Typography>
        {us_minimizedProjectTasks ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedProjectTasks(false)
                setPageLocalStorage(tabKey, 'us_minimizedProjectTasks', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Box>
            {filteredTasks.length === 0 ? (
              <Card className="tw-p-2 tw-text-center">
                <Typography>
                  <Icon
                    icon="circle-info"
                    className="tw-mr-2 tw-text-info_main"
                  />
                  <Box
                    component="span"
                    className="tw-italic tw-opacity-30"
                  >
                    {rLIB('No completed field tasks with team members assigned.')}
                  </Box>
                </Typography>
              </Card>
            ) : (
              <Box>
                {sortedTasks.map((task, index) => (
                  <Card
                    key={index}
                    className="tw-p-2 tw-mb-1 tw-rounded-md"
                    sx={{
                      boxShadow: 'none',
                      width: '100%',
                      maxWidth: '800px',
                    }}
                  >
                    <Stack
                      direction="row"
                      className="group"
                      sx={{
                        '&:hover .view-icon-box': {
                          opacity: 1,
                        },
                      }}
                    >
                      <Typography
                        variant="h6"
                        className="tw-font-semibold"
                      >
                        {task.name || 'N/A'}
                      </Typography>
                      {pr_readOrWrite === 'write' ? (
                        <Box
                          className="view-icon-box"
                          sx={{ opacity: 0 }}
                        >
                          <Icon
                            icon="magnifying-glass"
                            className="tw-inline-block tw-mt-2 tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-info_main tw-cursor-pointer tw-align-top"
                            tooltip={rLIB('View')}
                            tooltipPlacement="right"
                            onClick={() => {
                              console.log(task)
                              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  openTaskFormEditDialog(
                                    res_GCK.clientKey,
                                    task.key as string,
                                    null,
                                    uc_setUserInterface_CustomDialogDisplay,
                                    uc_setUserInterface_ConfirmDialogDisplay,
                                    uc_setUserInterface_ErrorDialogDisplay,
                                    getProp(uc_RootData_ClientUser, 'key', null),
                                    getProp(uc_RootData_ClientUser, 'name', null),
                                    true,
                                  )
                                })
                                .catch((rej_GCK) => {
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                })
                            }}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Stack>

                    <Grid2 container>
                      <Grid2
                        xs={12}
                        sm={4}
                      >
                        <Typography
                          variant="subtitle2"
                          className="tw-font-semibold"
                        >
                          {rLIB('Team Members')}
                        </Typography>
                        <Box className="tw-flex tw-flex-wrap tw-gap-1">
                          {task.team_members.length > 0 ? (
                            task.team_members.map((member: string, idx: number) => (
                              <Chip
                                key={idx}
                                label={member}
                                size="small"
                                className="tw-mr-1 tw-mb-1"
                                sx={{
                                  height: '24px',
                                  fontWeight: 500,
                                }}
                              />
                            ))
                          ) : (
                            <Typography
                              variant="body2"
                              className="tw-opacity-50"
                            >
                              {rLIB('No team members assigned.')}
                            </Typography>
                          )}
                        </Box>
                      </Grid2>
                      <Grid2
                        xs={12}
                        sm={8}
                      >
                        <Box>
                          <Grid2
                            container
                            spacing={2}
                            alignItems="center"
                          >
                            <Grid2
                              xs={12}
                              sm={6}
                            >
                              <Typography
                                variant="subtitle2"
                                className="tw-font-semibold"
                              >
                                {rLIB('Status')}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="tw-capitalize"
                                sx={{
                                  color:
                                    task.status === 'completed'
                                      ? themeVariables.success_main
                                      : task.status === 'assigned'
                                        ? themeVariables.warning_main
                                        : 'inherit',
                                }}
                              >
                                {task.status || 'N/A'}
                              </Typography>
                            </Grid2>
                            <Grid2
                              xs={12}
                              sm={6}
                            >
                              <Typography
                                variant="subtitle2"
                                className="tw-font-semibold"
                              >
                                {rLIB('Team Name')}
                              </Typography>
                              <Typography variant="body2">{task.associated_team_name || 'N/A'}</Typography>
                            </Grid2>

                            <Grid2
                              xs={12}
                              sm={6}
                            >
                              <Typography
                                variant="subtitle2"
                                className={`tw-font-semibold ${task.last_scheduled_date === 'N/A' ? 'tw-opacity-50' : ''}`}
                                sx={{ color: task.last_scheduled_date === 'N/A' ? 'gray' : 'inherit' }}
                              >
                                {rLIB('Scheduled Date')}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: task.last_scheduled_date === 'N/A' ? 'gray' : 'inherit' }}
                              >
                                {task.last_scheduled_date}
                              </Typography>
                            </Grid2>
                            <Grid2
                              xs={12}
                              sm={6}
                            >
                              <Typography
                                variant="subtitle2"
                                className={`tw-font-semibold ${task.completed_date === 'N/A' ? 'tw-opacity-50' : ''}`}
                                sx={{ color: task.completed_date === 'N/A' ? 'gray' : 'inherit' }}
                              >
                                {rLIB('Completed Date')}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: task.completed_date === 'N/A' ? 'gray' : 'inherit' }}
                              >
                                {task.completed_date}
                              </Typography>
                            </Grid2>
                          </Grid2>
                        </Box>
                      </Grid2>
                    </Grid2>
                  </Card>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    )
  }

  // JSX - Full Page
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Grid2
          container
          spacing={2}
        >
          <Grid2
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
          >
            <Box>{rJSX_DetailsTile()}</Box>
            <Box className="tw-mt-2">{rJSX_SalesPartnerTile()}</Box>
            <Box className="tw-mt-2">{rJSX_LastContactTile()}</Box>
            <Box>{rJSX_CustomerFeedbackTile()}</Box>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
          >
            <Box>{rJSX_StickyNotesTile()}</Box>
            <Box className="tw-mt-2">{rJSX_ProjectTasksGrid()}</Box>
            <Box className="tw-mt-2">{rJSX_TimelineTile()}</Box>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
          >
            <Box>{rJSX_FinancingTile()}</Box>
            <Box className="tw-mt-2">{rJSX_SystemTile()}</Box>
            <Box className="tw-mt-2">{rJSX_HomeDetailsTile()}</Box>
          </Grid2>
        </Grid2>
        {/* <Masonry
          columns={{ xs: 1, sm: 2, md: 3 }}
          spacing={2}
        ></Masonry> */}
      </Box>
    )

    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
