/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Typography } from '@mui/material'
import { tableColumns_ActiveProjects, tableSettings_OnHoldProjects } from 'app/models/projects/project_table'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_OnHoldProjectsForSpecificWorkflowAndSalesPartner_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { TableBasic } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_Pacing_TaskOnHoldTab {
  selectedTaskWorkflowKey: string | null
  taskWorkflows: TsInterface_UnspecifiedObject
  readOrWrite: 'read' | 'write'
  projectViewPageKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const TaskOnHoldTab = forwardRef((props: TsInterface_Pacing_TaskOnHoldTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_selectedTaskWorkflowKey: TsInterface_Pacing_TaskOnHoldTab['selectedTaskWorkflowKey'] = getProp(props, 'selectedTaskWorkflowKey', null)
  let pr_readOrWrite: TsInterface_Pacing_TaskOnHoldTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')
  let pr_taskWorkflows: TsInterface_UnspecifiedObject = getProp(props, 'taskWorkflows', {})
  let pr_projectViewPageKey: TsInterface_Pacing_TaskOnHoldTab['projectViewPageKey'] = getProp(props, 'projectViewPageKey', null)

  // Hooks - useContext, useState, useReducer, other
  // const [us_phaseActiveProjects, us_setPhaseActiveProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_onHoldProjects, us_setOnHoldProjects] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setOnHoldProjects(newData)
      ur_forceRerender()
    }
    if (
      pr_selectedTaskWorkflowKey != null &&
      pr_selectedTaskWorkflowKey !== '' &&
      pr_taskWorkflows != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey] != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey]['associated_sales_partner_key'] != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey]['associated_sales_partner_key'] != ''
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // let actualClientKey = pr_clientKeyOverride != null && pr_clientKeyOverride !== '' ? pr_clientKeyOverride : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_OnHoldProjectsForSpecificWorkflowAndSalesPartner_Query(
              res_GCK.clientKey,
              pr_selectedTaskWorkflowKey as string,
              pr_taskWorkflows[pr_selectedTaskWorkflowKey as string]['associated_sales_partner_key'] as string,
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_selectedTaskWorkflowKey, pr_taskWorkflows])

  // Functions

  // JSX Generation
  const rJSX_TabContent = (): JSX.Element => {
    let contentJSX = <></>
    if (pr_selectedTaskWorkflowKey == null || pr_selectedTaskWorkflowKey === '') {
      contentJSX = (
        <Box className="tw-p-4 tw-text-center tw-opacity-50">
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {rLIB('Select a Workflow')}
          </Typography>
        </Box>
      )
    } else {
      contentJSX = (
        <Box>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={tableColumns_ActiveProjects}
              tableData={objectToArray(us_onHoldProjects)}
              tableSettings={tableSettings_OnHoldProjects}
            />
          </Card>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <Box className="tw-m-auto">{rJSX_TabContent()}</Box>
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
