///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, MenuItem, Select } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { IconMenu } from 'rfbp_core/components/icon_menu/icon_menu'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { TableCellBasic, TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { getMasterDataList } from './data/all_master_data'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['MasterDataIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_tableFilterStatus, us_setTableFilterStatus] = useState<'active' | 'deleted'>('active')
  const [us_masterDataList, us_setMasterDataList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedMasterDataItemKey, us_setSelectedMasterDataItemKey] = useState<string | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Master Data', false) as string
  }, [])

  useEffect(() => {
    let pageLocalStorageData = getPageLocalStorage(pageKey)
    if (getProp(pageLocalStorageData, 'selected_master_data_item_key', null) != null) {
      us_setSelectedMasterDataItemKey(getProp(pageLocalStorageData, 'selected_master_data_item_key', null))
    }
  }, [])

  useEffect(() => {
    us_setMasterDataList(getMasterDataList(getProp(uc_RootData_ClientPermissions, 'client_type', null), getProp(uc_RootData_ClientUser, 'user_role', null)))
  }, [uc_RootData_ClientUser, uc_RootData_ClientPermissions])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_MasterData = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: us_tableFilterStatus }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)

    return generateDatabaseQuery(
      tableAdditionalData.tableDatabaseRef(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_ImportAliases = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)

    return generateDatabaseQuery(
      tableAdditionalData.tableDatabaseRef(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // const exportMasterData = (clientKey: string, collectionRef: any, mapping: TsInterface_UnspecifiedObject) => {
  //   // Get Data
  //   DatabaseGetCollection(collectionRef(clientKey))
  //     .then((res_DGC) => {
  //       // Loop through and create CSV from mapping object and make each data item a row
  //       let csvData: any[][] = []
  //       // Create CSV Header
  //       let csvHeader: any[] = []
  //       for (let loopMappingKey in mapping) {
  //         let loopMappingValue = mapping[loopMappingKey]
  //         csvHeader.push(loopMappingValue.label)
  //       }
  //       csvData.push(csvHeader)
  //       // Create CSV Data
  //       for (let loopDataItemKey in res_DGC.data) {
  //         let loopDataItem = res_DGC.data[loopDataItemKey]
  //         let row: any[] = []
  //         for (let loopMappingKey in mapping) {
  //           row.push(getProp(loopDataItem, loopMappingKey, null))
  //         }
  //         csvData.push(row)
  //       }
  //       // Export CSV
  //       downloadCSV('master_data_export.csv', csvData)
  //     })
  //     .catch((rej_DGC) => {
  //       console.error('Error getting collection:', rej_DGC)
  //     })
  // }

  const isMultiSelectObject = (value: any): boolean => {
    if (typeof value !== 'object' || value === null) return false
    // Check if all values in the object are boolean (true/false)
    return Object.values(value).every((v) => typeof v === 'boolean')
  }

  const exportMasterData = (clientKey: string, collectionRef: any, mapping: TsInterface_UnspecifiedObject) => {
    DatabaseGetCollection(collectionRef(clientKey))
      .then((res_DGC) => {
        let csvData: any[][] = []
        let csvHeader: any[] = []

        // Create CSV Header
        for (let loopMappingKey in mapping) {
          let loopMappingValue = mapping[loopMappingKey]
          csvHeader.push(loopMappingValue.label)
        }
        csvData.push(csvHeader)

        // Create CSV Data
        for (let loopDataItemKey in res_DGC.data) {
          let loopDataItem = res_DGC.data[loopDataItemKey]
          let row: any[] = []

          for (let loopMappingKey in mapping) {
            let value = getProp(loopDataItem, loopMappingKey, null)

            // Auto-detect and convert multi-select fields (objects where all values are boolean)
            if (isMultiSelectObject(value)) {
              value = Object.keys(value)
                .filter((key) => value[key] === true)
                .join(', ')
            }

            row.push(value)
          }
          csvData.push(row)
        }

        downloadCSV('master_data_export.csv', csvData)
      })
      .catch((rej_DGC) => {
        console.error('Error getting collection:', rej_DGC)
      })
  }

  const exportMasterDataJson = (clientKey: string, collectionRef: any, mapping: TsInterface_UnspecifiedObject) => {
    // Get Data
    DatabaseGetCollection(collectionRef(clientKey))
      .then((res_DGC) => {
        // Process data to convert Date objects to Unix timestamps
        const processedData = JSON.parse(
          JSON.stringify(res_DGC.data, (key, value) => {
            // Check if the value is a Firebase Timestamp or Date object
            if (
              value &&
              typeof value === 'object' &&
              (value.constructor.name === 'Timestamp' || value instanceof Date || (value.seconds !== undefined && value.nanoseconds !== undefined))
            ) {
              // Convert to Unix timestamp (milliseconds)
              if (value instanceof Date) {
                return value.getTime()
              } else if (value.toDate) {
                // Firebase Timestamp objects have toDate() method
                return value.toDate().getTime()
              } else if (value.seconds !== undefined) {
                // Raw timestamp object with seconds and nanoseconds
                return value.seconds * 1000 + Math.floor(value.nanoseconds / 1000000)
              }
            }
            return value
          }),
        )

        // Create JSON file from processed data
        const jsonData = JSON.stringify(processedData, null, 2)

        // Create a blob and download link
        const blob = new Blob([jsonData], { type: 'application/json' })
        const url = URL.createObjectURL(blob)

        // Create download link and trigger download
        const a = document.createElement('a')
        a.href = url
        a.download = 'master_data_export.json'
        document.body.appendChild(a)
        a.click()

        // Clean up
        setTimeout(() => {
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        }, 0)
      })
      .catch((rej_DGC) => {
        console.error('Error getting collection:', rej_DGC)
      })
  }

  // JSX Generation
  const rJSX_MasterDataSelection = (): JSX.Element => {
    return (
      <FormControl className="bp_thin_select_input tw-mr-2 ">
        <Select
          color="primary"
          value={us_selectedMasterDataItemKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedMasterDataItemKey(event.target.value)
              setPageLocalStorage(pageKey, 'selected_master_data_item_key', event.target.value)
            }
          }}
          variant="outlined"
        >
          {objectToArray(us_masterDataList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_CreateMasterDataItemButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (
      us_masterDataList != null &&
      us_selectedMasterDataItemKey != null &&
      us_masterDataList[us_selectedMasterDataItemKey] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item']['text'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item']['form_inputs'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item']['form_settings'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item']['function'] != null
    ) {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          className="tw-mr-2 "
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: {},
                  formInputs: us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item'].form_inputs,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item'].form_settings,
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item']
                            .function(res_GCK.clientKey, formSubmittedData, formAdditionalData, formHooks)
                            .then((res_CI: unknown) => {
                              resolve(res_CI)
                            })
                            .catch((rej_CI: unknown) => {
                              reject(rej_CI)
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: getProp(rej_CI, 'error', {}) })
                            })
                        })
                        .catch((rej_GCK) => {
                          reject(rej_GCK)
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item'].text,
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="circle-plus"
                    />
                  ),
                },
              },
            })
          }}
          disableElevation
          startIcon={
            <Icon
              icon="circle-plus"
              type="solid"
            />
          }
        >
          {us_masterDataList[us_selectedMasterDataItemKey]['data']['new_item'].text}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_ImportMasterDataButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (
      us_masterDataList != null &&
      us_selectedMasterDataItemKey != null &&
      us_masterDataList[us_selectedMasterDataItemKey] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['text'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['mapping'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['function'] != null
    ) {
      buttonJSX = (
        <Box className=" tw-inline-block tw-align-top">
          <BasicImportButtonAndDialog
            importAdditionalData={{}}
            importButtonColor={'info'}
            importButtonShrink={false}
            importButtonText={us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['text']}
            importDialogHeader={us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['text']}
            importMappingOptions={us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['mapping']}
            importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
              return new Promise((resolve, reject) => {
                try {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']
                        .function(res_GCK.clientKey, spreadsheetData, importAdditionalData, importHooks)
                        .then((res_II: unknown) => {
                          resolve(res_II)
                        })
                        .catch((rej_II: unknown) => {
                          reject(rej_II)
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: getProp(rej_II, 'error', {}) })
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error('Client Key Error:', rej_GCK)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                } catch (error) {
                  console.error('Error during import submission:', error)
                  reject(error)
                }
              })
            }}
          />
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_ToggleDeletedFilterButton = (): JSX.Element => {
    if (us_tableFilterStatus === 'active') {
      return (
        <Button
          color="secondary"
          variant="outlined"
          className=""
          startIcon={
            <Icon
              icon="filter"
              type="solid"
            />
          }
          onClick={() => {
            us_setTableFilterStatus('deleted')
          }}
        >
          {rLIB('Active')}
        </Button>
      )
    } else {
      return (
        <Button
          color="secondary"
          variant="outlined"
          className=""
          startIcon={
            <Icon
              icon="filter"
              type="solid"
            />
          }
          onClick={() => {
            us_setTableFilterStatus('active')
          }}
        >
          {rLIB('Deleted')}
        </Button>
      )
    }
  }

  const rJSX_ExportButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (
      us_masterDataList != null &&
      us_selectedMasterDataItemKey != null &&
      us_masterDataList[us_selectedMasterDataItemKey] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['full_exports'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['full_exports']['activeCollectionRef'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['mapping'] != null
    ) {
      buttonJSX = (
        <Box className=" tw-inline-block tw-align-top">
          <IconMenu
            icon={
              <Button
                className=" tw-mr-2 bp_icon_only_button"
                color="info"
                variant="outlined"
                startIcon={
                  <Icon
                    icon="cloud-arrow-down"
                    type="solid"
                  />
                }
              />
            }
            menuItems={[
              {
                icon: 'file-spreadsheet',
                text: rLIB('Download CSV'),
                onClick: () => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      exportMasterData(
                        res_GCK.clientKey,
                        us_masterDataList[us_selectedMasterDataItemKey]['data']['full_exports']['activeCollectionRef'],
                        us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['mapping'],
                      )
                    })
                    .catch((rej_GCK) => {
                      console.error('Client Key Error:', rej_GCK)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                },
              },
              {
                icon: 'file-code',
                text: rLIB('Download JSON'),
                onClick: () => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                    exportMasterDataJson(
                      res_GCK.clientKey,
                      us_masterDataList[us_selectedMasterDataItemKey]['data']['full_exports']['activeCollectionRef'],
                      us_masterDataList[us_selectedMasterDataItemKey]['data']['import_items']['mapping'],
                    )
                  })
                },
              },
            ]}
          />
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_MasterDataItemTable = (): JSX.Element => {
    let tableJSX = <></>
    if (
      uc_RootData_ClientKey != null &&
      us_masterDataList != null &&
      us_selectedMasterDataItemKey != null &&
      us_masterDataList[us_selectedMasterDataItemKey] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['table'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['columns'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['settings'] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['database_ref'] != null
    ) {
      if (us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['settings'].search_settings_database == null) {
        us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['settings'].search_settings_database = { search_type: 'meilisearch' }
      }
      us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['settings'].search_settings_database.search_client_key = uc_RootData_ClientKey
      if (us_tableFilterStatus === 'deleted') {
        us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['settings'].search_settings_database.search_filters = ['status = "deleted"']
      } else {
        us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['settings'].search_settings_database.search_filters = ['status = "active"']
      }
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{
              tableDatabaseRef: us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['database_ref'],
            }}
            tableColumns={us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['columns']}
            tableDatabaseEndpoint={tableDatabaseEndpoint_MasterData}
            tableSettings={us_masterDataList[us_selectedMasterDataItemKey]['data']['table']['settings']}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_ImportAliasesTable = (): JSX.Element => {
    let tableJSX = <></>
    if (
      uc_RootData_ClientKey != null &&
      us_masterDataList != null &&
      us_selectedMasterDataItemKey != null &&
      us_masterDataList[us_selectedMasterDataItemKey] != null &&
      us_masterDataList[us_selectedMasterDataItemKey]['data'] != null
    ) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{
              tableDatabaseRef: us_masterDataList[us_selectedMasterDataItemKey]['data']['full_exports']['importAliasesCollectionRef'],
            }}
            tableColumns={{
              name: TableCellBasic('name', rLIB('Alias'), 'name'),
              associated_master_data_name: TableCellBasic('associated_master_data_name', rLIB('Master Data Item'), 'associated_master_data_name'),
            }}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ImportAliases}
            tableSettings={{
              rows_per_page: 100,
              show_header: true,
              size: 'small',
              sort_direction: 'asc',
              sort_property: 'name',
              use_live_data: false,
            }}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_PageContent = (): JSX.Element => {
    if (objectToArray(us_masterDataList).length > 0) {
      return (
        <Box>
          <TabsUrl
            tabsSettings={{
              baseUrl: ApplicationPages.MasterDataIndexPage.url(),
              tabQueryParam: 'tab',
              overridePageTitle: true,
              basePageTitle: rLIB('Master Data', false) as string,
            }}
            tabs={[
              {
                tabUrlKey: 'Master_Data',
                tabOnChange: () => {},
                tabHeader: rLIB('Master Data'),
                tabContent: <Box>{rJSX_MasterDataItemTable()}</Box>,
                tabButtons: [
                  { fullJSX: rJSX_MasterDataSelection(), minJSX: rJSX_MasterDataSelection(), sizeCutoff: 0 },
                  { fullJSX: rJSX_CreateMasterDataItemButton(), minJSX: rJSX_CreateMasterDataItemButton(), sizeCutoff: 0 },
                  { fullJSX: rJSX_ImportMasterDataButton(), minJSX: rJSX_ImportMasterDataButton(), sizeCutoff: 0 },
                  { fullJSX: rJSX_ExportButton(), minJSX: rJSX_ExportButton(), sizeCutoff: 0 },
                  { fullJSX: rJSX_ToggleDeletedFilterButton(), minJSX: rJSX_ToggleDeletedFilterButton(), sizeCutoff: 0 },
                ],
              },
              {
                tabUrlKey: 'Import_Aliases',
                tabOnChange: () => {},
                tabHeader: rLIB('Import Aliases'),
                tabContent: <Box>{rJSX_ImportAliasesTable()}</Box>,
                tabButtons: [{ fullJSX: rJSX_MasterDataSelection(), minJSX: rJSX_MasterDataSelection(), sizeCutoff: 0 }],
              },
            ]}
          />
        </Box>
      )
    } else {
      return <Box className="tw-text-center tw-italic tw-opacity-50">{rLIB('Account not set up for master data. Please contact support.')}</Box>
    }
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Master Data')}
        pageKey={pageKey}
        content={<Box>{rJSX_PageContent()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
