///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material/'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesOpportunity_v2_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ConfirmDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseUpdateDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { importIndividualProject } from '../projects/services/project_imports_v2'
import { OpportunityCalendarTab } from './tabs/tab_calendar'
import { OpportunityDocumentsTab } from './tabs/tab_documents'
import { OpportunityDetailsTab } from './tabs/tab_opportunity_details'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesPipelineViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_opportunityData, us_setOpportunityData] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const un_routerNavigation = useNavigate()

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Opportunity', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setOpportunityData(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.log(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, itemKey, uc_setRootData_ClientKey])

  // Functions
  const updateOpportunityWithProjectKey = (clientKey: string, projectKey: string) => {
    let updateObject: TsInterface_UnspecifiedObject = {
      associated_project_key: projectKey,
    }
    DatabaseUpdateDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, itemKey), updateObject)
      .then((res_UD) => {
        console.log(res_UD)
      })
      .catch((rej_UD) => {
        console.log(rej_UD)
      })
  }

  const createProjectFromOpportunity = () => {
    let newProjectData: TsInterface_UnspecifiedObject = {
      ...us_opportunityData,
      // associated_region_name: us_regionsList[formSubmittedData.associated_region_key].name,
      // associated_sales_partner_name: us_salesPartnersList[formSubmittedData.associated_sales_partner_key].name,
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        importIndividualProject(res_GCK.clientKey, newProjectData, false)
          .then((res_IIP: any) => {
            let filteredImportNotes: string[] = []
            for (let loopIndex in objectToArray(getProp(getProp(res_IIP, 'importUpdateObject', {}), 'import_notes', {}))) {
              let loopValue = objectToArray(getProp(getProp(res_IIP, 'importUpdateObject', {}), 'import_notes', {}))[loopIndex]
              if (loopValue != null && loopValue != undefined && loopValue != '') {
                filteredImportNotes.push(loopValue)
              }
            }
            if (res_IIP != null && res_IIP.success == true && res_IIP.importUpdateObject != null && res_IIP.importUpdateObject.status == 'success') {
              updateOpportunityWithProjectKey(res_GCK.clientKey, getProp(getProp(res_IIP, 'importUpdateObject', {}), 'new_project_key', null))
            } else if (res_IIP != null && res_IIP.success == true && res_IIP.importUpdateObject != null && res_IIP.importUpdateObject.status == 'warning') {
              uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'warning',
                  header: <>{rLIB('Project Creation Warning')}</>,
                  icon: <Icon icon="exclamation-triangle" />,
                  submit_callback: () => {
                    return new Promise((resolve2, reject2) => {
                      importIndividualProject(res_GCK.clientKey, newProjectData, true)
                        .then((res_IIP: any) => {
                          let filteredImportNotes: string[] = []
                          for (let loopIndex in objectToArray(getProp(getProp(res_IIP, 'importUpdateObject', {}), 'import_notes', {}))) {
                            let loopValue = objectToArray(getProp(getProp(res_IIP, 'importUpdateObject', {}), 'import_notes', {}))[loopIndex]
                            if (loopValue != null && loopValue != undefined && loopValue != '') {
                              filteredImportNotes.push(loopValue)
                            }
                          }

                          if (
                            res_IIP != null &&
                            res_IIP.success == true &&
                            res_IIP.importUpdateObject != null &&
                            res_IIP.importUpdateObject.status == 'success'
                          ) {
                            updateOpportunityWithProjectKey(res_GCK.clientKey, getProp(getProp(res_IIP, 'importUpdateObject', {}), 'new_project_key', null))
                            resolve2(res_IIP)
                          } else {
                            uc_setUserInterface_ErrorDialogDisplay({
                              display: true,
                              error: {
                                message: rLIB('Error creating project'),
                                details: filteredImportNotes.join(', '),
                                code: 'ER-D-TCP-PCC-01',
                              },
                            })
                            reject2(res_IIP)
                          }
                        })
                        .catch((rej_IIP) => {
                          reject2(rej_IIP)
                        })
                    })
                  },
                  submit_text: rLIB('Proceed with Project Creation'),
                  text: (
                    <>
                      {filteredImportNotes.join(', ')}
                      {'. '}
                      {rLIB('Are you sure that you want to proceed?')}
                    </>
                  ),
                },
              })
            } else {
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: rLIB('Error creating project'),
                  details: filteredImportNotes.join(', '),
                  code: 'ER-D-TCP-PCC-01',
                },
              })
            }
          })
          .catch((rej_IIP) => {})
      })
      .catch((rej_GCK) => {
        console.error('Client Key Error:', rej_GCK)
      })
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    return (
      <Button
        color="inherit"
        variant="outlined"
        startIcon={<Icon icon="chevron-left"></Icon>}
        className="tw-mb-2 tw-mr-2"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesPipelineIndexPage.url())
        }}
      >
        {rLIB('Back')}
      </Button>
    )
  }

  const rJSX_CreateProjectButton = (): JSX.Element => {
    if (uc_RootData_ClientKey === 'etw_energy') {
      if (us_opportunityData != null && us_opportunityData.key != null) {
        if (us_opportunityData.associated_project_key != null) {
          return (
            <Button
              color="info"
              variant="contained"
              startIcon={<Icon icon="magnifying-glass"></Icon>}
              className="tw-mb-2 tw-mr-2"
              disabled={uc_RootData_ClientKey !== 'etw_energy'}
              onClick={(event) => {
                // TODO
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(us_opportunityData.associated_project_key))
              }}
            >
              {rLIB('View Project')}
            </Button>
          )
        } else {
          return (
            <Button
              color="success"
              variant="contained"
              startIcon={<Icon icon="circle-plus"></Icon>}
              className="tw-mb-2 tw-mr-2"
              onClick={(event) => {
                createProjectFromOpportunity()
              }}
              disabled={uc_RootData_ClientKey !== 'etw_energy'}
            >
              {rLIB('Create Project')}
            </Button>
          )
        }
      } else {
        return <></>
      }
    } else {
      return <></>
    }
  }

  const returnProjectTabs = (): TsInterface_TabContentUrlArray => {
    let tabs: TsInterface_TabContentUrlArray = []
    // DETAILS TAB
    tabs.push({
      tabUrlKey: 'details',
      tabHeader: rLIB('Details'),
      tabOnChange: () => {},
      tabContent: (
        <Box>
          <OpportunityDetailsTab
            opportunityKey={itemKey}
            readOrWrite={'write'}
          />
        </Box>
      ),
    })
    // CALENDAR TAB
    tabs.push({
      tabUrlKey: 'calendar',
      tabHeader: rLIB('Calendar'),
      tabOnChange: () => {},
      tabContent: (
        <Box>
          <OpportunityCalendarTab
            opportunityKey={itemKey}
            readOrWrite={'write'}
          />
        </Box>
      ),
    })
    // DOCUMENTS TAB
    tabs.push({
      tabUrlKey: 'documents',
      tabHeader: rLIB('Documents'),
      tabOnChange: () => {},
      tabContent: (
        <Box>
          <OpportunityDocumentsTab
            opportunityKey={itemKey}
            readOrWrite={'write'}
          />
        </Box>
      ),
    })
    return tabs
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Opportunity')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              {rJSX_BackButton()}
              {rJSX_CreateProjectButton()}
            </Box>
            <TabsUrl
              tabs={returnProjectTabs()}
              tabsSettings={{
                baseUrl: ApplicationPages.SalesPipelineViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: getProp(us_opportunityData, 'key', ''),
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
