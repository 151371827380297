/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box } from '@mui/material'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import {
  DatabaseRef_ProjectNoteThreadNotes_Collection,
  DatabaseRef_ProjectNoteThreads_Collection,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_NotesTab } from '../project_view/tab_notes'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_NotesTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
}

type TsType_NoteTableFilterOptions = 'all' | 'deleted' | 'not_deleted' | 'starred'

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewNotesTab = forwardRef((props: TsInterface_ProjectView_NotesTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_NotesTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_clientKey: TsInterface_ProjectView_NotesTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_readOrWrite: TsInterface_ProjectView_NotesTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_newNoteEditorVisibility, us_setNewNoteEditorVisibility] = useState<boolean>(false)
  const [us_noteReplyRichContentEditorValue, us_setNoteReplyRichContentEditorValue] = useState<string>('')
  const [us_noteRichContentEditorValue, us_setNoteRichContentEditorValue] = useState<string>('')
  const [us_noteSortOrder, us_setNoteSetOrder] = useState<string>('timestamp_last_updated_desc')
  const [us_noteSubjectValue, us_setNoteSubjectValue] = useState<string>('')
  const [us_noteTableFilter, us_setNoteTableFilter] = useState<TsType_NoteTableFilterOptions>('not_deleted')
  const [us_projectNoteThreads, us_setProjectNoteThreads] = useState<TsInterface_UnspecifiedObject>({})
  const [us_replyEditorVisibility, us_setReplyEditorVisibility] = useState<boolean>(false)
  const [us_selectedNoteThreadKey, us_setSelectedNoteThreadKey] = useState<null | string>(null)
  const [us_selectedThreadNotes, us_setSelectedThreadNotes] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedThreadNotes(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null && us_selectedNoteThreadKey != null && us_selectedNoteThreadKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ProjectNoteThreadNotes_Collection(actualClientKey, pr_projectKey, us_selectedNoteThreadKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedThreadNotes({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedNoteThreadKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectNoteThreads(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectNoteThreads_Collection(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectNoteThreads({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  // Functions

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        {rJSX_NotesTab(
          pr_readOrWrite,
          uc_RootData_ClientKey,
          uc_RootData_ClientUser,
          us_newNoteEditorVisibility,
          us_noteReplyRichContentEditorValue,
          us_noteRichContentEditorValue,
          us_noteSortOrder,
          us_noteSubjectValue,
          us_noteTableFilter,
          pr_projectKey,
          us_projectNoteThreads,
          us_replyEditorVisibility,
          us_selectedNoteThreadKey,
          us_selectedThreadNotes,
          us_setNewNoteEditorVisibility,
          us_setNoteReplyRichContentEditorValue,
          us_setNoteRichContentEditorValue,
          us_setNoteSetOrder,
          us_setNoteSubjectValue,
          us_setNoteTableFilter,
          us_setReplyEditorVisibility,
          uc_setRootData_ClientKey,
          us_setSelectedNoteThreadKey,
          uc_setUserInterface_ConfirmDialogDisplay,
          uc_setUserInterface_ErrorDialogDisplay,
          uc_setUserInterface_FormDialogDisplay,
        )}
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
