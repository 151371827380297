/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box } from '@mui/material'
import { ProjectPacingTimeline } from 'app/pages/pacing/components/project_pacing_timeline'
import { forwardRef } from 'react'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_CalendarTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
  includeDelays: boolean
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewCalendarTab = forwardRef((props: TsInterface_ProjectView_CalendarTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_CalendarTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_clientKey: TsInterface_ProjectView_CalendarTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_includeDelays: TsInterface_ProjectView_CalendarTab['includeDelays'] = getProp(props, 'includeDelays', null)
  // let pr_readOrWrite: TsInterface_ProjectView_CalendarTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  // const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_formattedTasks, us_setFormattedTasks] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_projectTasksLoaded, us_setProjectTasksLoaded] = useState<boolean>(false)
  // const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  // useEffect(() => {
  //   let unsubscribeLiveData: TsType_VoidFunction
  //   const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //     if (newData != null) {
  //       us_setProjectTasks(newData)
  //       us_setProjectTasksLoaded(true)
  //     }
  //     ur_forceRerender()
  //   }
  //   if (pr_projectKey != null) {
  //     getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //       .then((res_GCK) => {
  //         unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
  //       })
  //       .catch((rej_GCK) => {
  //         console.error(rej_GCK)
  //       })
  //   } else {
  //     us_setProjectTasks({})
  //     us_setProjectTasksLoaded(true)
  //   }
  //   return () => {
  //     if (typeof unsubscribeLiveData === 'function') {
  //       unsubscribeLiveData()
  //     }
  //   }
  // }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  // useEffect(() => {
  //   let formattedTasks: TsInterface_UnspecifiedObject = {}
  //   for (let loopTaskKey in us_projectTasks) {
  //     let loopTask = us_projectTasks[loopTaskKey]
  //     let taskDates: string[] = []
  //     // Task Scheduled Dates
  //     if (loopTask.task_completion_scheduled_dates != null && loopTask.task_completion_scheduled_dates.length > 0) {
  //       taskDates = taskDates.concat(loopTask.task_completion_scheduled_dates)
  //     }
  //     // Task
  //     if (taskDates.length === 0) {
  //       if (loopTask.timestamp_completed != null) {
  //         taskDates.push(returnFormattedDateKey(returnDateFromUnknownDateFormat(loopTask.timestamp_completed)))
  //       }
  //     }
  //     if (taskDates.length > 0) {
  //       let calculatedTaskData = returnTaskCalculatedTimesAndStatus(loopTask)
  //       let taskColor = themeVariables.info_main
  //       if (
  //         calculatedTaskData.elapsedDays != null &&
  //         loopTask.status_green_days_cutoff != null &&
  //         !isNaN(loopTask.status_green_days_cutoff as number) != null &&
  //         ((calculatedTaskData.elapsedDays <= loopTask.status_green_days_cutoff) as unknown as number)
  //       ) {
  //         taskColor = themeVariables.success_main
  //       } else if (
  //         calculatedTaskData.elapsedDays != null &&
  //         loopTask.status_yellow_days_cutoff != null &&
  //         !isNaN(loopTask.status_yellow_days_cutoff as number) != null &&
  //         ((calculatedTaskData.elapsedDays <= loopTask.status_yellow_days_cutoff) as unknown as number)
  //       ) {
  //         taskColor = themeVariables.warning_main
  //       } else if (calculatedTaskData.elapsedDays != null) {
  //         taskColor = themeVariables.error_main
  //       } else {
  //         taskColor = themeVariables.info_main
  //       }
  //       formattedTasks[loopTaskKey] = {
  //         key: loopTaskKey,
  //         name: loopTask.name,
  //         dates: taskDates,
  //         color: taskColor,
  //         elapsed_days: calculatedTaskData.elapsedDays,
  //       }
  //     }
  //   }
  //   us_setFormattedTasks(formattedTasks)
  // }, [us_projectTasks])

  // Functions

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <ProjectPacingTimeline
          projectKey={pr_projectKey}
          clientKeyOverride={pr_clientKey}
          includeDelays={pr_includeDelays}
        />
        {/* <ProjectTaskCalendar events={us_formattedTasks} /> */}
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
