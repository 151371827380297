///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_ABPProposal = (clientKey: string, userKey: string, formName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/abp_disclosure_form/' + userKey + '/proposals/' + formName)
}

export const DatabaseRef_ABPPresets_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'abp_presets', 'main')
}

export const DatabaseRef_ABPPresets_Document = (clientKey: string, presetKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_presets', 'main', presetKey)
}

export const DatabaseRef_ABPRecGroupValues_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'abp_rec_group_values', 'main')
}

export const DatabaseRef_ABPRecGroupValues_Document = (clientKey: string, recGroupKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_rec_group_values', 'main', recGroupKey)
}

export const DatabaseRef_ValidCompanyNameURLs_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'abp_valid_company_urls', 'main')
}

export const DatabaseRef_ValidCompanyNameURLs_Document = (clientKey: string, newFormURL: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_valid_company_urls', 'main', newFormURL)
}

export const DatabaseRef_ABPProposal_Document = (clientKey: string, proposalKey: string, formKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_proposals', 'main', proposalKey, 'main', formKey)
}

export const DatabaseRef_ABPDegradation_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_degradation', 'main', 'degradation')
}

export const DatabaseRef_ABPApplicationFee_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_application_fee', 'main', 'application_fee')
}

export const DatabaseRef_ABPForm_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'abp_forms', 'main')
}

export const DatabaseRef_ABPForm_Document = (clientKey: string, formKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_forms', 'main', formKey)
}

export const DatabaseRef_ABPGreenhouseURLIsProd_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'abp_greenhouse_url', 'main', 'greenhouse_url')
}
