///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to Initialize Firebase Instance

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_Accessories_Collection } from 'rfbp_aux/services/database_endpoints/directory/accessories'
import { DatabaseRef_Batteries_Collection } from 'rfbp_aux/services/database_endpoints/directory/batteries'
import { DatabaseRef_BuildingType_Collection } from 'rfbp_aux/services/database_endpoints/directory/building_types'
import { DatabaseRef_DesignPartners_Collection } from 'rfbp_aux/services/database_endpoints/directory/design_partners'
import { DatabaseRef_FinancePartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_FinancingType_Collection } from 'rfbp_aux/services/database_endpoints/directory/financing_type'
import { DatabaseRef_Hoas_Collection } from 'rfbp_aux/services/database_endpoints/directory/hoas'
import { DatabaseRef_InstallPartners_Collection } from 'rfbp_aux/services/database_endpoints/directory/install_partner'
import { DatabaseRef_Inverters_Collection } from 'rfbp_aux/services/database_endpoints/directory/inverters'
import { DatabaseRef_Jurisdictions_Collection } from 'rfbp_aux/services/database_endpoints/directory/jurisdictions'
import { DatabaseRef_LeadSources_Collection } from 'rfbp_aux/services/database_endpoints/directory/lead_sources'
import { DatabaseRef_Modules_Collection } from 'rfbp_aux/services/database_endpoints/directory/modules'
import { DatabaseRef_MountTypes_Collection } from 'rfbp_aux/services/database_endpoints/directory/mount_types'
import { DatabaseRef_OpportunityMilestones_Collection } from 'rfbp_aux/services/database_endpoints/directory/opportunity_milestones'
import { DatabaseRef_ProductPackages_Collection } from 'rfbp_aux/services/database_endpoints/directory/product_packages'
import { DatabaseRef_ProjectMilestones_Collection } from 'rfbp_aux/services/database_endpoints/directory/project_milestones'
import { DatabaseRef_Racking_Collection } from 'rfbp_aux/services/database_endpoints/directory/racking'
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_Trailers_Collection } from 'rfbp_aux/services/database_endpoints/directory/trailers'
import { DatabaseRef_Utilities_Collection } from 'rfbp_aux/services/database_endpoints/directory/utilities'
import { DatabaseRef_Rentals_Collection, DatabaseRef_Vehicles_Collection } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { DatabaseRef_MasterSKUs_Collection } from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import { DatabaseRef_Projects_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_Adders_Collection } from 'rfbp_aux/services/database_endpoints/sales/adders'
import { DatabaseRef_Discounts_Collection } from 'rfbp_aux/services/database_endpoints/sales/discounts'
import { DatabaseRef_Incentives_Collection } from 'rfbp_aux/services/database_endpoints/sales/incentives'
import { DatabaseRef_SOW_Collection } from 'rfbp_aux/services/database_endpoints/sales/sow'
import { DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_SearchIndexObject {
  key: string
  type: 'client' | 'global'
  indexKey: string
  primaryKey: 'id'
  indexedAttributes: {
    id: string
    [propKey: string]: string
  }
  searchableAttributes: string[]
  displayedAttributes: string[]
  filterableAttributes: string[]
  databaseDataEndpointOrderByProp: string
  databaseDataEndpoint: any
  statusToSetIfNull?: string
  inactiveBoolToStatus?: string
  searchIndexSettings?: TsInterface_UnspecifiedObject
}

interface TsInterface_SearchIndexList {
  [indexKey: string]: TsInterface_SearchIndexObject
}

///////////////////////////////
// Variables
///////////////////////////////

export const searchConfig = {
  host: 'https://search.etw.app/',
  apiKey: '57482d40cc30917943f5e319772a30d2385fbe6c5229aa66ba099791b157eb5f',
}

export const searchIndexList: TsInterface_SearchIndexList = {
  client_users: {
    type: 'client',
    key: 'client_users',
    indexKey: 'users',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      email: 'email',
      phone: 'phone',
      user_role: 'user_role',
      status: 'status',
    },
    filterableAttributes: ['user_role', 'status'],
    searchableAttributes: ['name', 'email', 'phone'],
    displayedAttributes: ['id', 'name', 'email', 'phone', 'user_role', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Users_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_projects: {
    type: 'client',
    key: 'client_projects',
    indexKey: 'projects',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      id_number: 'id_number',
      associated_customer_name: 'associated_customer_name',
      associated_sales_rep_name: 'associated_sales_rep_name',
      associated_css_rep_name: 'associated_css_rep_name',
      status: 'status',
      associated_customer_key: 'associated_customer_key',
      associated_sales_rep_key: 'associated_sales_rep_key',
      associated_css_rep_key: 'associated_css_rep_key',
      associated_sales_rep_email: 'associated_sales_rep_email',
      associated_sales_partner_key: 'associated_sales_partner_key',
      associated_region_key: 'associated_region_key',
      location_address: 'location_address',
    },
    filterableAttributes: [
      'status',
      'associated_sales_rep_key',
      'associated_css_rep_key',
      'associated_sales_rep_email',
      'associated_sales_partner_key',
      'associated_region_key',
    ],
    searchableAttributes: ['id_number', 'associated_customer_name', 'location_address'],
    displayedAttributes: [
      'id',
      'id_number',
      'associated_customer_name',
      'associated_sales_rep_name',
      'associated_css_rep_name',
      'status',
      'associated_customer_key',
      'associated_sales_rep_key',
      'associated_css_rep_key',
      'associated_sales_rep_email',
      'associated_sales_partner_key',
      'associated_region_key',
      'location_address',
    ],
    databaseDataEndpointOrderByProp: 'key',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'key', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Projects_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_utilities: {
    type: 'client',
    key: 'client_utilities',
    indexKey: 'utilities',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Utilities_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_jurisdictions: {
    type: 'client',
    key: 'client_jurisdictions',
    indexKey: 'jurisdictions',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Jurisdictions_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_vehicles: {
    type: 'client',
    key: 'client_vehicles',
    indexKey: 'vehicles',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
      associated_garage_key: 'associated_garage_key',
      associated_garage_name: 'associated_garage_name',
      associated_user_key: 'associated_user_key',
      associated_user_name: 'associated_user_name',
      field_status: 'field_status',
      license_plate: 'license_plate',
      vin: 'vin',
    },
    filterableAttributes: ['status', 'field_status', 'associated_garage_key'],
    searchableAttributes: ['name', 'associated_user_name', 'license_plate', 'vin'],
    displayedAttributes: [
      'id',
      'name',
      'status',
      'associated_garage_key',
      'associated_garage_name',
      'associated_user_key',
      'associated_user_name',
      'field_status',
      'license_plate',
      'vin',
    ],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Vehicles_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_trailers: {
    type: 'client',
    key: 'client_trailers',
    indexKey: 'trailers',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
      associated_garage_key: 'associated_garage_key',
      associated_garage_name: 'associated_garage_name',
      associated_user_key: 'associated_user_key',
      associated_user_name: 'associated_user_name',
      field_status: 'field_status',
      license_plate: 'license_plate',
      vin: 'vin',
    },
    filterableAttributes: ['status', 'field_status', 'associated_garage_key'],
    searchableAttributes: ['name', 'associated_user_name', 'license_plate', 'vin'],
    displayedAttributes: [
      'id',
      'name',
      'status',
      'associated_garage_key',
      'associated_garage_name',
      'associated_user_key',
      'associated_user_name',
      'field_status',
      'license_plate',
      'vin',
    ],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Trailers_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_materials: {
    type: 'client',
    key: 'client_materials',
    indexKey: 'materials',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      key: 'key',
      unique_identifier: 'unique_identifier',
      name: 'name',
      associated_category_name: 'associated_category_name',
      associated_classification_name: 'associated_classification_name',
      associated_manufacturer_unique_identifier: 'associated_manufacturer_unique_identifier',
      associated_supplier_key: 'associated_supplier_key',
      pick_estimated_price_per_package: 'pick_estimated_price_per_package',
      pick_packaging_type: 'pick_packaging_type',
      pick_unit_quantity_per_package: 'pick_unit_quantity_per_package',
      pick_base_unit_of_measurement: 'pick_base_unit_of_measurement',
      pick_max_per_cart: 'pick_max_per_cart',
      photo_url: 'photo_url',
      status: 'status',
      timestamp_last_updated: 'timestamp_last_updated',
    },
    filterableAttributes: ['associated_supplier_key', 'status', 'associated_category_name', 'associated_classification_name'],
    searchableAttributes: ['unique_identifier', 'name', 'associated_category_name', 'associated_manufacturer_unique_identifier'],
    displayedAttributes: [
      'id',
      'key',
      'unique_identifier',
      'name',
      'associated_category_name',
      'associated_classification_name',
      'associated_manufacturer_unique_identifier',
      'associated_supplier_key',
      'pick_estimated_price_per_package',
      'pick_packaging_type',
      'pick_unit_quantity_per_package',
      'pick_base_unit_of_measurement',
      'pick_max_per_cart',
      'photo_url',
      'status',
      'timestamp_last_updated',
    ],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'key', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_MasterSKUs_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_materials_by_sku: {
    type: 'client',
    key: 'client_materials_by_sku',
    indexKey: 'materials_by_sku',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      key: 'key',
      unique_identifier: 'unique_identifier',
      name: 'name',
      associated_category_name: 'associated_category_name',
      associated_classification_name: 'associated_classification_name',
      associated_manufacturer_unique_identifier: 'associated_manufacturer_unique_identifier',
      associated_supplier_key: 'associated_supplier_key',
      pick_estimated_price_per_package: 'pick_estimated_price_per_package',
      pick_packaging_type: 'pick_packaging_type',
      pick_unit_quantity_per_package: 'pick_unit_quantity_per_package',
      pick_base_unit_of_measurement: 'pick_base_unit_of_measurement',
      pick_max_per_cart: 'pick_max_per_cart',
      photo_url: 'photo_url',
      status: 'status',
      timestamp_last_updated: 'timestamp_last_updated',
    },
    filterableAttributes: ['associated_supplier_key', 'status', 'associated_category_name', 'associated_classification_name'],
    searchableAttributes: [
      'unique_identifier',
      // "name",
      // "associated_category_name"
    ],
    displayedAttributes: [
      'id',
      'key',
      'unique_identifier',
      'name',
      'associated_category_name',
      'associated_classification_name',
      'associated_manufacturer_unique_identifier',
      'associated_supplier_key',
      'pick_estimated_price_per_package',
      'pick_packaging_type',
      'pick_unit_quantity_per_package',
      'pick_base_unit_of_measurement',
      'pick_max_per_cart',
      'photo_url',
      'status',
      'timestamp_last_updated',
    ],
    searchIndexSettings: {
      rankingRules: ['exactness', 'words', 'typo', 'proximity', 'attribute', 'sort'],
      typoTolerance: {
        minWordSizeForTypos: {
          oneTypo: 10,
          twoTypos: 20,
        },
      },
    },
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'key', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_MasterSKUs_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  // NEW Indexes
  client_accessories: {
    type: 'client',
    key: 'client_accessories',
    indexKey: 'accessories',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
      associated_manufacturer_name: 'associated_manufacturer_name',
    },
    filterableAttributes: ['status', 'associated_manufacturer_name'],
    searchableAttributes: ['name', 'associated_manufacturer_name'],
    displayedAttributes: ['id', 'name', 'status', 'associated_manufacturer_name'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Accessories_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_batteries: {
    type: 'client',
    key: 'client_batteries',
    indexKey: 'batteries',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
      associated_manufacturer_name: 'associated_manufacturer_name',
    },
    filterableAttributes: ['status', 'associated_manufacturer_name'],
    searchableAttributes: ['name', 'associated_manufacturer_name'],
    displayedAttributes: ['id', 'name', 'status', 'associated_manufacturer_name'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }] // databaseDataEndpointOrderByProp
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Batteries_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_design_partners: {
    type: 'client',
    key: 'client_design_partners',
    indexKey: 'design_partners',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_DesignPartners_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_finance_partners: {
    type: 'client',
    key: 'client_finance_partners',
    indexKey: 'finance_partners',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_FinancePartner_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_financing_types: {
    type: 'client',
    key: 'client_financing_types',
    indexKey: 'financing_types',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_FinancingType_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_hoas: {
    type: 'client',
    key: 'client_hoas',
    indexKey: 'hoas',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Hoas_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_install_partners: {
    type: 'client',
    key: 'client_install_partners',
    indexKey: 'install_partners',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_InstallPartners_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_inverters: {
    type: 'client',
    key: 'client_inverters',
    indexKey: 'inverters',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
      associated_manufacturer_name: 'associated_manufacturer_name',
    },
    filterableAttributes: ['status', 'associated_manufacturer_name'],
    searchableAttributes: ['name', 'associated_manufacturer_name'],
    displayedAttributes: ['id', 'name', 'status', 'associated_manufacturer_name'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Inverters_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_lead_sources: {
    type: 'client',
    key: 'client_lead_sources',
    indexKey: 'lead_sources',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_LeadSources_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_modules: {
    type: 'client',
    key: 'client_modules',
    indexKey: 'modules',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
      associated_manufacturer_name: 'associated_manufacturer_name',
    },
    filterableAttributes: ['status', 'associated_manufacturer_name'],
    searchableAttributes: ['name', 'associated_manufacturer_name'],
    displayedAttributes: ['id', 'name', 'status', 'associated_manufacturer_name'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Modules_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_mount_types: {
    type: 'client',
    key: 'client_mount_types',
    indexKey: 'mount_types',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_MountTypes_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_opportunity_milestones: {
    type: 'client',
    key: 'client_opportunity_milestones',
    indexKey: 'opportunity_milestones',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(
        DatabaseRef_OpportunityMilestones_Collection(clientKey as string),
        queryOperatorsArray,
        orderByArray,
        queryCursorsObject,
        limit,
      )
    },
  },
  client_product_packages: {
    type: 'client',
    key: 'client_product_packages',
    indexKey: 'product_packages',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_ProductPackages_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_project_milestones: {
    type: 'client',
    key: 'client_project_milestones',
    indexKey: 'project_milestones',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_ProjectMilestones_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_racking: {
    type: 'client',
    key: 'client_racking',
    indexKey: 'racking',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
      associated_manufacturer_name: 'associated_manufacturer_name',
    },
    filterableAttributes: ['status', 'associated_manufacturer_name'],
    searchableAttributes: ['name', 'associated_manufacturer_name'],
    displayedAttributes: ['id', 'name', 'status', 'associated_manufacturer_name'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Racking_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_regions: {
    type: 'client',
    key: 'client_regions',
    indexKey: 'regions',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Regions_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_rentals: {
    type: 'client',
    key: 'client_rentals',
    indexKey: 'rentals',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Rentals_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_sales_partners: {
    type: 'client',
    key: 'client_sales_partners',
    indexKey: 'sales_partners',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_SalesPartner_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_adders: {
    type: 'client',
    key: 'client_adders',
    indexKey: 'adders',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Adders_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_discounts: {
    type: 'client',
    key: 'client_discounts',
    indexKey: 'discounts',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Discounts_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_incentives: {
    type: 'client',
    key: 'client_incentives',
    indexKey: 'incentives',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_Incentives_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_sow: {
    type: 'client',
    key: 'client_sow',
    indexKey: 'sow',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_SOW_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
  client_building_types: {
    type: 'client',
    key: 'client_building_types',
    indexKey: 'building_types',
    primaryKey: 'id',
    indexedAttributes: {
      id: 'key',
      name: 'name',
      status: 'status',
    },
    filterableAttributes: ['status'],
    searchableAttributes: ['name'],
    displayedAttributes: ['id', 'name', 'status'],
    databaseDataEndpointOrderByProp: 'name',
    databaseDataEndpoint: (
      clientKey: string,
      startAfter: string | null,
      startAt: string | null,
      endAt: string | null,
      endBefore: string | null,
      limit: number,
    ) => {
      let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
      let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
      let queryCursorsObject: TsInterface_QueryCursorsObject = {}
      if (startAfter != null) {
        queryCursorsObject['startAfter'] = startAfter
      }
      if (startAt != null) {
        queryCursorsObject['startAt'] = startAt
      }
      if (endAt != null) {
        queryCursorsObject['endAt'] = endAt
      }
      if (endBefore != null) {
        queryCursorsObject['endBefore'] = endBefore
      }
      return generateDatabaseQuery(DatabaseRef_BuildingType_Collection(clientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////
