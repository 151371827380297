///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, MenuItem, Select, Typography } from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveFinancePartners_Query } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_ActiveInstallPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/install_partner'
import { DatabaseRef_ActiveOpportunityMilestones_Query } from 'rfbp_aux/services/database_endpoints/directory/opportunity_milestones'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_SalesOpportunity_v2_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseStagedBatchUpdate, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { returnOpportunityImportDataMapping } from './data/import_mapping'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesPipelineImportPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  const [us_activeInstallPartners, us_setActiveInstallPartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedSalesPartnerKey, us_setSelectedSalesPartnerKey] = useState<string>('')
  const [us_selectedRegionKey, us_setSelectedRegionKey] = useState<string>('')
  const [us_importTemplate, us_setImportTemplate] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeSalesPartners, us_setActiveSalesPartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeRegions, us_setActiveRegions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeFinancePartners, us_setActiveFinancePartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeOpportunityMilestones, us_setActiveOpportunityMilestones] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Opportunity Import', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveSalesPartners(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveRegions(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveFinancePartners(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveFinancePartners_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setImportTemplate(returnOpportunityImportDataMapping(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveOpportunityMilestones(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveOpportunityMilestones_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveInstallPartners(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveInstallPartners_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const importOpportunities = (clientKey: string, spreadsheetData: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
      for (let loopIndex in spreadsheetData) {
        let loopImportRow = spreadsheetData[loopIndex]
        if (loopImportRow != null && loopImportRow['unique_identifier'] != null && loopImportRow['unique_identifier'] != '') {
          let opportunityKey = keyFromString(loopImportRow.unique_identifier)
          let updateObject: TsInterface_UnspecifiedObject = {
            status: 'active',
            key: opportunityKey,
            unique_identifier: loopImportRow.unique_identifier,
            timestamp_last_imported: new Date(),
            associated_sales_partner_key: us_selectedSalesPartnerKey,
            associated_sales_partner_name: us_activeSalesPartners[us_selectedSalesPartnerKey].name,
            associated_region_key: us_selectedRegionKey,
            associated_region_name: us_activeRegions[us_selectedRegionKey].name,
          }
          for (let loopPropKey in loopImportRow) {
            let loopPropValue = loopImportRow[loopPropKey]
            switch (loopPropKey) {
              case 'unique_identifier':
                // Already handled
                break
              case 'associated_sales_partner_key':
                // Already handled
                break
              case 'associated_region_key':
                // Already handled
                break
              case 'associated_finance_partner_key':
                if (loopPropValue != null && loopPropValue != '') {
                  // Find the finance partner key
                  let financePartnerKey = Object.keys(us_activeFinancePartners).find((key) => us_activeFinancePartners[key].name === loopPropValue)
                  if (financePartnerKey != null) {
                    updateObject['associated_finance_partner_key'] = financePartnerKey
                    updateObject['associated_finance_partner_name'] = us_activeFinancePartners[financePartnerKey].name
                  }
                }
                break
              case 'associated_install_partner_key':
                if (loopPropValue != null && loopPropValue != '') {
                  // Find the install partner key
                  let installPartnerKey = Object.keys(us_activeInstallPartners).find((key) => us_activeInstallPartners[key].name === loopPropValue)
                  if (installPartnerKey != null) {
                    updateObject['associated_install_partner_key'] = installPartnerKey
                    updateObject['associated_install_partner_name'] = us_activeInstallPartners[installPartnerKey].name
                  }
                }
                break
              case 'associated_milestone_key':
                if (loopPropValue != null && loopPropValue != '') {
                  // Find the milestone key
                  let milestoneKey = Object.keys(us_activeOpportunityMilestones).find((key) => us_activeOpportunityMilestones[key].name === loopPropValue)
                  if (milestoneKey != null) {
                    updateObject['associated_milestone_key'] = milestoneKey
                    updateObject['associated_milestone_name'] = us_activeOpportunityMilestones[milestoneKey].name
                  }
                }
                break
              default:
                console.log('timestamp')
                console.log(loopPropKey)
                console.log(loopPropValue)
                if (loopPropKey.startsWith('timestamp_')) {
                  try {
                    if (loopPropValue) {
                      // Check if the value is purely numeric (Excel/spreadsheet date)
                      // This regex ensures we only match numbers (with optional decimal point)
                      if (/^[0-9]+(\.[0-9]+)?$/.test(String(loopPropValue).trim())) {
                        const numericValue = parseFloat(loopPropValue)
                        // Convert Excel/spreadsheet serial date to JavaScript Date
                        // Excel dates: days since Dec 30, 1899 (Windows) or Jan 1, 1904 (Mac)
                        // We'll assume Windows format as it's more common
                        const excelEpoch = new Date(1899, 11, 30)
                        const msPerDay = 24 * 60 * 60 * 1000
                        const jsDate = new Date(excelEpoch.getTime() + numericValue * msPerDay)

                        // Create dayjs object from the converted date
                        const date = dayjs(jsDate)
                        if (date.isValid() && date.year() > 1970) {
                          updateObject[loopPropKey] = date.toDate()
                          continue // Skip the rest of the date parsing logic
                        }
                      }

                      // First try to parse as a valid date
                      const date = dayjs(loopPropValue)
                      if (date.isValid() && date.year() > 1970) {
                        // Basic sanity check
                        // More comprehensive regex for time formats:
                        // - HH:mm or H:mm (24-hour)
                        // - hh:mm or h:mm with AM/PM/am/pm/a.m./p.m.
                        const hasTimeComponent = /\d{1,2}:\d{2}(?:\s*(?:AM|PM|am|pm|a\.m\.|p\.m\.))?/i.test(loopPropValue)

                        // If no time component, set to noon, otherwise use original time
                        const finalDate = hasTimeComponent ? date : date.hour(12).minute(0).second(0)

                        updateObject[loopPropKey] = finalDate.toDate()
                      } else {
                        // Try parsing with different formats if the initial parse failed or gave an invalid year
                        const alternateFormats = ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY']
                        let validDate = null

                        for (const format of alternateFormats) {
                          const attemptDate = dayjs(loopPropValue, format)
                          if (attemptDate.isValid() && attemptDate.year() > 1970) {
                            validDate = attemptDate
                            break
                          }
                        }

                        if (validDate) {
                          // Set to noon for these date-only formats
                          updateObject[loopPropKey] = validDate.hour(12).minute(0).second(0).toDate()
                        } else {
                          console.warn(`Invalid date format for ${loopPropKey}: ${loopPropValue}`)
                        }
                      }
                    }
                  } catch (error) {
                    console.error(`Error processing date for ${loopPropKey}:`, error)
                    console.warn(`Skipping invalid date: ${loopPropValue}`)
                  }
                } else {
                  console.log('2')
                  if (loopPropValue != null && loopPropValue != '') {
                    updateObject[loopPropKey] = loopImportRow[loopPropKey]
                  }
                }
                break
            }
          }
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey),
            data: updateObject,
          })
        }
      }

      console.log(updateArray)

      // Batch Update
      DatabaseStagedBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
          onClickAppNavigation({}, un_routerNavigation, ApplicationPages.SalesPipelineIndexPage.url())
        })
        .catch((rej_DBU) => {
          console.error(rej_DBU)
          reject(rej_DBU)
        })
    })
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    return (
      <Button
        color="inherit"
        variant="outlined"
        startIcon={<Icon icon="chevron-left"></Icon>}
        className="tw-mb-2 tw-mr-2"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesPipelineIndexPage.url())
        }}
      >
        {rLIB('Back to tasks')}
      </Button>
    )
  }

  const rJSX_SalesPartnerSelection = (): JSX.Element => {
    return (
      <FormControl className="bp_thin_select_input tw-mr-2 tw-mb-2">
        <Select
          color="primary"
          value={us_selectedSalesPartnerKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedSalesPartnerKey(event.target.value)
            }
          }}
          variant="outlined"
        >
          {objectToArray(us_activeSalesPartners)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_RegionSelection = (): JSX.Element => {
    return (
      <FormControl className="bp_thin_select_input tw-mr-2 tw-mb-2">
        <Select
          color="primary"
          value={us_selectedRegionKey}
          onChange={(event: any) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedRegionKey(event.target.value)
            }
          }}
          variant="outlined"
        >
          {objectToArray(us_activeRegions)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
                disabled={option['disabled'] === true}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }

  const rJSX_DownloadTemplateButton = (): JSX.Element => {
    return (
      <Button
        color="info"
        variant="outlined"
        startIcon={<Icon icon="download"></Icon>}
        className="tw-mb-2 tw-mr-2"
        onClick={() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
            let template: TsInterface_UnspecifiedObject = returnOpportunityImportDataMapping(res_GCK.clientKey)
            // Turn into CSV
            let csvData = []
            let rowData = []
            for (let loopPropKey in template) {
              let propData = template[loopPropKey]
              rowData.push(propData.label)
            }
            csvData.push(rowData)
            downloadCSV('Opportunity Import Template', csvData)
          })
        }}
      >
        {rLIB('Download Template')}
      </Button>
    )
  }

  const rJSX_ImportButton = () => {
    return (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={false}
        importButtonDisabled={us_selectedSalesPartnerKey === '' || us_selectedRegionKey === ''}
        importButtonText={rLIB('Import Opportunities')}
        importDialogHeader={rLIB('Import Opportunities')}
        importMappingOptions={us_importTemplate}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  importOpportunities(res_GCK.clientKey, spreadsheetData)
                    .then((res_IO) => {
                      resolve(res_IO)
                    })
                    .catch((rej_IO) => {
                      reject(rej_IO)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Opportunity Import')}
        pageKey={pageKey}
        content={
          <Box>
            {rJSX_BackButton()}
            {rJSX_DownloadTemplateButton()}
            <Card className="tw-p-4">
              <Grid2 container>
                <Grid2
                  xs={12}
                  sm={6}
                  md={4}
                  className="tw-text-center"
                >
                  <Typography variant="h6">{rLIB('Step 1:')}</Typography>
                  <Typography
                    variant="body1"
                    className="tw-opacity-30 tw-mb-4"
                  >
                    {rLIB('Select Sales Partner')}
                  </Typography>
                  {rJSX_SalesPartnerSelection()}
                </Grid2>
                <Grid2
                  xs={12}
                  sm={6}
                  md={4}
                  className="tw-text-center"
                >
                  <Typography variant="h6">{rLIB('Step 2:')}</Typography>
                  <Typography
                    variant="body1"
                    className="tw-opacity-30 tw-mb-4"
                  >
                    {rLIB('Select Region')}
                  </Typography>
                  {rJSX_RegionSelection()}
                </Grid2>
                <Grid2
                  xs={12}
                  sm={12}
                  md={4}
                  className="tw-text-center"
                >
                  <Typography variant="h6">{rLIB('Step 3:')}</Typography>
                  <Typography
                    variant="body1"
                    className="tw-opacity-30 tw-mb-4"
                  >
                    {rLIB('Select Import File')}
                  </Typography>
                  <Box>{rJSX_ImportButton()}</Box>
                </Grid2>
              </Grid2>
            </Card>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
