///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_ModulesFile = (clientKey: string, moduleKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/modules/' + moduleKey + '/files/' + fileName)
}

export const StorageRef_Module_MessageThread_File = (clientKey: string, moduleKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/modules/' + moduleKey + '/threads/' + threadKey + '/files/' + fileName)
}
