import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

export const DatabaseRef_Incentives_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'main')
}

export const DatabaseRef_Incentive_Document = (clientKey: string, incentiveKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'main', incentiveKey)
}

export const DatabaseRef_ActiveIncentives_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_Incentive_ImportAliases_Document = (clientKey: string, incentiveKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'import_aliases', incentiveKey)
}

export const DatabaseRef_Incentive_ImportAliases_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'import_aliases')
}

export const DatabaseRef_Incentive_ImportAliases_Query = (clientKey: string, incentiveKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: incentiveKey }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'import_aliases'), queryOperatorsArray, [], {}, null)
}
