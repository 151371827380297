/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Checkbox, Divider, FormControl, LinearProgress, Stack, TextField, Typography } from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-scroll'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { rJSX_StagingWarning } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_AlertDialog, Context_UserInterface_ErrorDialog, TsInterface_AlertDialogObject } from 'rfbp_core/services/context'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { checkIfAuthenticated } from 'rfbp_core/services/user_authentication'
// @ts-expect-error - TODO: reason for error
import logoSrc from '../../images/logos/logo_temp.png'
// @ts-expect-error - TODO: reason for error
import bg2Src from '../../images/unauthenticated/bg2.png'
// @ts-expect-error - TODO: reason for error
import user6Src from '../../images/unauthenticated/cody.jpg'
// @ts-expect-error - TODO: reason for error
import user2Src from '../../images/unauthenticated/david.jpg'
// @ts-expect-error - TODO: reason for error
import user5Src from '../../images/unauthenticated/john.jpg'
// @ts-expect-error - TODO: reason for error
import user1Src from '../../images/unauthenticated/raphael.jpg'
// @ts-expect-error - TODO: reason for error
import user4Src from '../../images/unauthenticated/santos.png'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_LANDING_PAGE_ABOUT_1: JSX.Element =			<Trans>More sustainable future for you and your family</Trans>
// const s_LANDING_PAGE_ABOUT_2: JSX.Element =			<Trans>Going solar is more than just a smart financial decision. It's an investment in a better future for our planet and our children. By choosing to install solar panels on your home, you're taking a powerful step towards energy independence and resilience. You're also doing your part to combat climate change and reduce our reliance on dirty, non-renewable energy sources. At ETW, we're proud to be at the forefront of the solar energy revolution, and we're excited to help you make the switch to clean, renewable energy.</Trans>
const s_ALL_RIGHTS_RESERVED: JSX.Element = <Trans>All right reserved</Trans>
const s_BACK: JSX.Element = <Trans>Back</Trans>
const s_CONTACT_FORM_SUBMITTED: JSX.Element = <Trans>Contact form submitted</Trans>
const s_CONTACT_US: JSX.Element = <Trans>Contact Us</Trans>
const s_COPYRIGHT: JSX.Element = <Trans>Copyright</Trans>
const s_ETW_ENERGY_LLC: JSX.Element = <Trans>ETW Energy, LLC</Trans>
const s_HOME: JSX.Element = <Trans>Home</Trans>
const s_I_AUTHORIZE_CONTECT_: JSX.Element = (
  <Trans>
    I authorize ETW Energy, LLC ("ETW") to call me and send pre-recorded messages and text messages to me about ETW products and services at the telephone
    number I entered above, even if I am on a national or state “Do Not Call” list. Message and data rates may apply. Maximum 10 texts per month. Consent for
    calls & texts is optional. You can opt out anytime. You also agree to our Terms of Service.
  </Trans>
)
const s_LANDING_PAGE_ABOUT_3: JSX.Element = <Trans>Looking to help better the world?</Trans>
const s_LANDING_PAGE_ABOUT_4: JSX.Element = (
  <Trans>
    We believe that our success is driven by the quality of our team, and we are committed to attracting, developing, and retaining top talent. We are looking
    for individuals who are passionate about their work, thrive in a fast-paced environment, and are dedicated to making a difference. Here are a few roles that
    we currently have open:
  </Trans>
)
const s_LANDING_PAGE_INTRO_1: JSX.Element = <Trans>ACCELERATING THE TRANSITION TO RENEWABLE ENERGY</Trans>
const s_LANDING_PAGE_INTRO_2: JSX.Element = <Trans>We're on a mission to</Trans>
const s_LANDING_PAGE_INTRO_3: JSX.Element = <Trans>electrify homes & help families save money while doing good for the planet</Trans>
const s_LANDING_PAGE_INTRO_4: JSX.Element = (
  <Trans>
    Our team is committed to delivering high-quality, reliable solar solutions that will help you save money on your energy bills while reducing your carbon
    footprint.
  </Trans>
)
const s_LANDING_PAGE_INTRO_5: JSX.Element = <Trans>We are looking for exceptional individuals who share our passion for changing the world.</Trans>
const s_LANDING_PAGE_TEAM_1: JSX.Element = <Trans>Meet some members of our team</Trans>
const s_LOGIN: JSX.Element = <Trans>Login</Trans>
const s_MEET_OUR_TEAM: JSX.Element = <Trans>Meet our team</Trans>
const s_OPEN_POSITIONS: JSX.Element = <Trans>Open Positions</Trans>
const s_OUR_TEAM: JSX.Element = <Trans>Our Team</Trans>
const s_PRIVACY_POLICY: JSX.Element = <Trans>Privacy Policy</Trans>
const s_SEE_OUR_OPEN_POSITIONS: JSX.Element = <Trans>See our open positions</Trans>
const s_TERMS_OF_SERVICE: JSX.Element = <Trans>Terms of Service</Trans>
const s_WELL_BE_IN_TOUCH_SOON: JSX.Element = <Trans>We'll be in touch soon!</Trans>
const se_CONTACT_REASON: string = 'What would you like to connect with us about?'
const se_EMAIL_ADDRESS: string = 'Email Address'
const se_ETW_ENERGY: string = 'ETW Energy'
const se_FIRST_NAME: string = 'First Name'
const se_LAST_NAME: string = 'Last Name'
const se_PHONE_NUMBER: string = 'Phone Number'
// { sort-end } - displayed text

// let openPositions = [
// 	{ text: "Solar Installers" }
// ]

// CSS - for full page scroll
let pageCSS: string = `
		@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&display=swap');
		body {
			background: #000000;
		}
		.etw_landing_font {
			font-family: "Inter", "Manrope", "Roboto","Helvetica","Arial",sans-serif;
		}
		.etw_landing_smooth_container {
			scroll-snap-type: y mandatory;
		}
		.etw_landing_smooth_container .etw_landing_smooth_section {
			scroll-behavior: smooth;
			scroll-snap-align: start;
		}
		.etw_contact_form_input_container .MuiInputBase-root {
			border-radius: 30px;
			background: #1F253A;
		}
	`

let teamMembers = [
  {
    name: 'Raphael Kurian',
    bio1: `Raphael is the CEO of ETW Energy. He's led several large teams over multiple continents but has found his calling in Solar. `,
    bio2: `Prior to ETW, Raphael was a Senior Vice President at Sunrun, where he ran the AEE and SnapNrack subsidiaries along with overseeing procurement, supply chain and the operations of over 50 warehouse locations across the country. He has also held senior roles at McKinsey & Company and Dell Inc.`,
    bio3: `Raphael has a bachelor's degree in Computer Science, and an MBA from Cornell University. He lives in Utah with his wife and two daughters.`,
    photo: user1Src,
  },
  {
    name: 'David Barham',
    bio1: `David is the COO at ETW and oversees operations and customer success.`,
    bio2: `Prior to ETW, David was the Sr. Director, Supply Chain at Sunrun, where he oversaw materials planning, forecasting, distribution, logistics, and facility operations across a network of 50 warehouses and 4 distribution centers. Prior to joining Sunrun, David was the Vice President of Operations for Teesnap, a SaaS startup specializing in the golf & hospitality industry. His responsibilities included sales, product implementation, customer service, revenue, finance, and marketing.`,
    bio3: `David has a bachelor's degree in business administration and management, lives in Utah with his wife and two daughters aged 8 and 11.`,
    photo: user2Src,
  },
  {
    name: 'Arnulfo Santos',
    bio1: `Arnulfo is the GM over the San Antonio location and oversees ETW's day to day operations, including expansion into new markets. His teams just call him “Santos”.`,
    bio2: `Santos is a licensed electrician and grew up through the ranks in Texas oilfields and C&I installations. Throughout his career, he has built exceptional teams by setting a high bar and role modeling high quality workmanship. He prides himself on being able to deal with the current situation in a grounded manner, no matter how shocking it may be.`,
    bio3: `In his spare time, he likes to work out, spend time with his family and does not miss an opportunity at electrical humor.`,
    photo: user4Src,
    // photo: null,
  },
  // {
  //   name: 'Christine Lim',
  //   bio1: `Christine leads ETW's Customer Success team and serves as the subject matter expert on new initiatives.`,
  //   bio2: `Christine was previously at Sunrun where she served multiple roles in Project Coordination and Customer Success. Over her tenure, she oversaw over 600 installs and developed a deep appreciation for working with jurisdictions, utilities and HOAs. She is a keen customer advocate and will do what it takes to go above and beyond for ETW’s customers.`,
  //   bio3: `Christine has a bachelor's degree in Hospitality Management and is a born-and-raised Colorado native, speaks English and Korean and loves traveling. She's currently planning her next getaway to Germany to experience Oktoberfest.`,
  //   photo: user3Src,
  // },
  {
    name: 'John Morrell',
    bio1: `John is one of the Directors of Engineering at ETW and is responsible for building whatever technology will help the ETW team be more efficient and responsive to our customer's needs.`,
    bio2: `Before joining ETW, John spent 8 years building software products for companies operating in industries ranging from transportation to healthcare. Before that he worked as an operations analyst at Qualtrics where he ran the global customer satisfaction program.`,
    bio3: `John has a bachelor's degree in biochemistry and lives in Utah with wife and 2 sons with another boy on the way. While not at work you're likely to find him hiking, biking, kayaking or snowboarding somewhere in the mountains. `,
    photo: user5Src,
  },
  {
    name: 'Cody Colson',
    bio1: `Cody is one of ETW's Engineering Directors, specializing in mobile app development. He has a decade of prior experience in software engineering.`,
    bio2: `He holds a bachelor's degree in Information Systems and calls Utah home, where he lives with his wife, their golden retriever, and a baby boy on the way.`,
    bio3: `When he's not working, Cody enjoys a mix of reading, running, golfing, playing basketball, and snowboarding.`,
    photo: user6Src,
  },
]

///////////////////////////////
// Functions
///////////////////////////////

const getBaseURL = () => {
  // Get the current URL of the webpage
  const currentURL = window.location.href

  // Find the index of the end of the domain
  const domainEndIndex = currentURL.indexOf('/', 8) // Search after "https://" or "http://"

  // Extract the base URL
  const baseURL = currentURL.substring(0, domainEndIndex)

  return baseURL
}

// @ts-expect-error - TODO: reason for error
function Carousel({ slides }) {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ maxWidth: 800, margin: 'auto', overflow: 'hidden' }}>
        {/* <Grid2 container spacing={2}> */}
        {/* <Grid2 xs={12} sm={4} md={3}>
						<Box className="tw-text-center">
							<Typography variant="h6" gutterBottom>
								{slides[activeStep].title}
							</Typography>
						</Box>
						<Box
							className="tw-text-center tw-m-auto"
							sx={{
								width: {
									xs: "60%",
									sm: "100%",
								},
							}}>
							<img
								className="tw-w-full tw-h-auto"
								src={slides[activeStep].image}
								alt={slides[activeStep].title}
							/>
						</Box>
					</Grid2> */}
        {/* <Grid2
						xs={12}
						sm={8}
						md={9}
						sx={{
							padding: theme => theme.spacing(2),
							marginTop: {
								xs: "0px",
								sm: "32px",
							},
						}}
						className="tw-text-left"
					> */}
        {/* <Box className="tw-text-center"> */}
        <Box className="tw-text-left">
          <Typography
            variant="h6"
            gutterBottom
          >
            {slides[activeStep].title}
          </Typography>
        </Box>
        <Box className="tw-text-left">
          {slides[activeStep].text.map((paragraph: string[], index: number) => (
            <Typography
              variant="body1"
              sx={{ opacity: '64%' }}
              paragraph
              key={index}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
        {/* </Grid2>
				</Grid2> */}
        <Box>
          <Stack
            direction="row"
            spacing={2}
            className="tw-justify-evenly"
          >
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <Icon
                icon="arrow-left"
                className="tw-mr-2"
              />
              {s_BACK}
            </Button>
            <Box
              sx={{ textAlign: 'center', mt: 2 }}
              className="tw-inline-block"
            >
              {slides.map((slide: any, index: number) => (
                <Box
                  className="tw-inline-block tw-cursor-pointer"
                  key={index}
                  onClick={() => setActiveStep(index)}
                  sx={{
                    width: 14,
                    height: 14,
                    borderRadius: '50%',
                    bgcolor: activeStep === index ? 'primary.main' : 'grey.800',
                    mx: 0.5,
                  }}
                />
              ))}
            </Box>
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === slides.length - 1}
            >
              {rLIB('Next')}
              <Icon
                icon="arrow-right"
                className="tw-ml-2"
              />
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_agreedToContact, us_setAgreedToContact] = useState<boolean>(false)
  const [us_contactForm_Email, us_setContactForm_Email] = useState<string>('')
  const [us_contactForm_FirstName, us_setContactForm_FirstName] = useState<string>('')
  const [us_contactForm_LastName, us_setContactForm_LastName] = useState<string>('')
  const [us_contactForm_Notes, us_setContactForm_Notes] = useState<string>('')
  const [us_contactForm_Phone, us_setContactForm_Phone] = useState<string>('')
  const [us_submittingContactForm, us_setSubmittingContactForm] = useState<boolean>(false)
  const [us_userIsAuthenticated, us_setUserIsAuthenticated] = useState<boolean>(false)
  const un_routerNavigation = useNavigate()
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_ETW_ENERGY
  }, [])

  useEffect(() => {
    let baseUrl = getBaseURL()
    // if (baseUrl.includes('type3.app') || baseUrl.includes('localhost')) {
    if (baseUrl.includes('type3.app')) {
      // Redirect to login page
      un_routerNavigation(ApplicationPages.UnauthenticatedLoginPage.url())
    }
  }, [un_routerNavigation])

  useEffect(() => {
    checkIfAuthenticated()
      .then((resCIA) => {
        // @ts-expect-error - TODO: reason for error
        if (resCIA != null && resCIA.authenticated === true) {
          us_setUserIsAuthenticated(true)
        } else {
          us_setUserIsAuthenticated(false)
        }
      })
      .catch((rej_CIA) => {
        console.error(rej_CIA)
      })
    return () => {}
  }, [un_routerNavigation])

  // Functions
  const submitContactRequestForm = (firstName: string, lastName: string, email: string, phone: string, notes: string) => {
    us_setSubmittingContactForm(true)
    cloudFunctionUnauthenticatedRequests({
      function: 'contactRequestSubmission',
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      notes: notes,
    })
      .then((res_CFUR) => {
        us_setSubmittingContactForm(false)
        us_setContactForm_FirstName('')
        us_setContactForm_LastName('')
        us_setContactForm_Email('')
        us_setContactForm_Phone('')
        us_setContactForm_Notes('')
        const AlertDialogObject: TsInterface_AlertDialogObject = {
          color: 'success',
          header: s_CONTACT_FORM_SUBMITTED,
          icon: <Icon icon="circle-check" />,
          text: s_WELL_BE_IN_TOUCH_SOON,
        }
        uc_setUserInterface_AlertDialogDisplay({
          display: true,
          alert: AlertDialogObject,
        })
      })
      .catch((rej_CFUR) => {
        us_setSubmittingContactForm(false)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFUR.error })
      })
  }

  // JSX Generation
  const rJSX_Header = (): JSX.Element => {
    let loginText = s_LOGIN
    let loginLink = ApplicationPages.UnauthenticatedLoginPage.url()
    if (us_userIsAuthenticated === true) {
      loginText = s_HOME
      loginLink = ApplicationPages.HomePage.url()
    }
    let headerJSX = (
      <Stack
        direction="row"
        className="tw-justify-between"
      >
        <Box
          className="tw-m-2"
          component="img"
          sx={{ width: 126, height: 36 }}
          alt="logo"
          src={logoSrc}
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.UnauthenticatedLandingPage.url())
          }}
        />
        <Box>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
          >
            <Button
              className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case"
              variant="contained"
            >
              {s_CONTACT_US}
            </Button>
          </Link>
          <Button
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, loginLink)
            }}
            className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case tw-mr-1"
            variant="outlined"
          >
            {loginText}
          </Button>
        </Box>
      </Stack>
    )
    return headerJSX
  }

  const rJSX_Intro = (): JSX.Element => {
    let introJSX = (
      <Box>
        <Box
          className="tw-px-2 tw-py-1 tw-rounded-full tw-inline-block"
          sx={{
            border: '1px solid ' + themeVariables.white,
            background: 'rgba(0,0,0,0.4)',
          }}
        >
          <Typography
            sx={{ fontSize: '12px' }}
            className="tw-uppercase etw_landing_font"
          >
            {s_LANDING_PAGE_INTRO_1}
          </Typography>
        </Box>
        <Typography
          className="tw-mt-10 etw_landing_font tw-leading-normal"
          variant="h4"
          sx={{ maxWidth: '600px' }}
        >
          <Box className="tw-font-thin">{s_LANDING_PAGE_INTRO_2}</Box>
          <Box className="tw-font-bold">{s_LANDING_PAGE_INTRO_3}</Box>
        </Typography>
        <Typography
          className="tw-mt-6 etw_landing_font tw-leading-7"
          variant="body1"
          sx={{ maxWidth: '550px', opacity: '64%' }}
        >
          {s_LANDING_PAGE_INTRO_4}
        </Typography>
        <Typography
          className="tw-mt-2 etw_landing_font tw-leading-7"
          variant="body1"
          sx={{ maxWidth: '550px', opacity: '64%' }}
        >
          {s_LANDING_PAGE_INTRO_5}
        </Typography>
        <Box className="tw-mt-6">
          <Link
            to="about"
            spy={true}
            smooth={true}
            duration={500}
          >
            <Box
              className="tw-rounded-full tw-text-center tw-pt-2 tw-cursor-pointer tw-inline-block"
              sx={{
                background: themeVariables.primary_main,
                height: '40px',
                width: '40px',
              }}
            >
              <Icon icon="arrow-right" />
            </Box>
            <Box
              className="tw-ml-2 tw-mt-2 tw-uppercase tw-cursor-pointer tw-inline-block"
              sx={{ color: themeVariables.primary_main }}
            >
              {s_SEE_OUR_OPEN_POSITIONS}
            </Box>
          </Link>
        </Box>
      </Box>
    )
    return introJSX
  }

  const rJSX_About = (): JSX.Element => {
    let aboutJSX = (
      <Box className="tw-text-center tw-w-full">
        <Typography
          className="tw-mt-2 etw_landing_font tw-font-bold"
          variant="subtitle1"
          sx={{ color: themeVariables.primary_main }}
        >
          {s_OPEN_POSITIONS}
        </Typography>
        <Typography
          className="tw-mt-2 tw-font-bold etw_landing_font tw-leading-normal"
          variant="h5"
        >
          {s_LANDING_PAGE_ABOUT_3}
        </Typography>
        <Typography
          className="tw-mt-6 etw_landing_font tw-leading-7"
          variant="body1"
          sx={{ opacity: '64%' }}
        >
          {s_LANDING_PAGE_ABOUT_4}
        </Typography>
        <Box className="tw-text-center tw-mt-6">
          <Grid2
            container
            spacing={2}
          >
            {/* <Grid2 xs={12} md={6} lg={6}> */}
            <Grid2
              xs={12}
              md={12}
              lg={12}
            >
              <Typography
                className="tw-mt-2 etw_landing_font tw-font-bold"
                variant="subtitle1"
                sx={{ color: themeVariables.primary_main }}
              >
                Peoria, IL
              </Typography>
              <a href="https://www.indeed.com/job/installer-60dbcccd35942b60">
                <Button
                  color="inherit"
                  className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case"
                  variant="outlined"
                >
                  Solar Installers
                </Button>
              </a>
              <a href="https://www.indeed.com/job/lead-installer-657260400b8f5fb1">
                <Button
                  color="inherit"
                  className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case"
                  variant="outlined"
                >
                  Lead Installers
                </Button>
              </a>
              <a href="https://www.indeed.com/job/electrical-foreman-f6fa91820f6847ec">
                <Button
                  color="inherit"
                  className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case"
                  variant="outlined"
                >
                  Electrical Foremen
                </Button>
              </a>
            </Grid2>
            {/* <Grid2 xs={12} md={6} lg={6}>
							<Typography className="tw-mt-2 etw_landing_font tw-font-bold" variant="subtitle1" sx={{ color: themeVariables.primary_main }}>Colorado Springs, CO</Typography>
							<a href="https://www.indeed.com/job/installer-1e5e581b0ba1724e">
								<Button color="inherit" className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case" variant="outlined">Solar Installers</Button>
							</a>
							<a href="https://www.indeed.com/job/lead-installer-1384792683a7ddd0">
								<Button color="inherit" className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case" variant="outlined">Lead Installers</Button>
							</a>
							<a href="https://www.indeed.com/job/electrical-foreman-264650ac3e36c3a4">
								<Button color="inherit" className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case" variant="outlined">Electrical Foremen</Button>
							</a>
						</Grid2> */}
          </Grid2>
        </Box>
        <Box className="tw-text-center tw-mt-6">
          <Link
            to="team"
            spy={true}
            smooth={true}
            duration={500}
          >
            <Box
              className="tw-rounded-full tw-text-center tw-pt-2 tw-cursor-pointer tw-inline-block"
              sx={{
                background: themeVariables.primary_main,
                height: '40px',
                width: '40px',
              }}
            >
              <Icon icon="arrow-right" />
            </Box>
            <Box
              className="tw-ml-2 tw-mt-2 tw-uppercase tw-cursor-pointer tw-inline-block"
              sx={{ color: themeVariables.primary_main }}
            >
              {s_MEET_OUR_TEAM}
            </Box>
          </Link>
        </Box>
      </Box>
    )
    return aboutJSX
  }

  const rJSX_Team = (): JSX.Element => {
    let teamJSX = (
      <Box className="tw-text-center tw-w-full">
        <Typography
          className="tw-mt-2 etw_landing_font tw-font-bold"
          variant="subtitle1"
          sx={{ color: themeVariables.primary_main }}
        >
          {s_OUR_TEAM}
        </Typography>
        <Typography
          className="tw-mt-2 tw-mb-8 tw-font-bold etw_landing_font tw-leading-normal"
          variant="h5"
        >
          {s_LANDING_PAGE_TEAM_1}
        </Typography>
        <Box>
          <Carousel
            slides={[
              {
                image: teamMembers[0]['photo'],
                title: teamMembers[0]['name'],
                text: [teamMembers[0]['bio1'], teamMembers[0]['bio2'], teamMembers[0]['bio3']],
              },
              {
                image: teamMembers[1]['photo'],
                title: teamMembers[1]['name'],
                text: [teamMembers[1]['bio1'], teamMembers[1]['bio2'], teamMembers[1]['bio3']],
              },
              {
                image: teamMembers[2]['photo'],
                title: teamMembers[2]['name'],
                text: [teamMembers[2]['bio1'], teamMembers[2]['bio2'], teamMembers[2]['bio3']],
              },
              {
                image: teamMembers[3]['photo'],
                title: teamMembers[3]['name'],
                text: [teamMembers[3]['bio1'], teamMembers[3]['bio2'], teamMembers[3]['bio3']],
              },
              // {
              //   image: teamMembers[5]['photo'],
              //   title: teamMembers[5]['name'],
              //   text: [teamMembers[5]['bio1'], teamMembers[5]['bio2'], teamMembers[5]['bio3']],
              // },
              {
                image: teamMembers[4]['photo'],
                title: teamMembers[4]['name'],
                text: [teamMembers[4]['bio1'], teamMembers[4]['bio2'], teamMembers[4]['bio3']],
              },
            ]}
          />
        </Box>
      </Box>
    )
    return teamJSX
  }

  const rJSX_Footer = (): JSX.Element => {
    let footerJSX = <></>
    footerJSX = (
      <Box sx={{ marginTop: 'auto' }}>
        <Divider />
        <Stack
          direction="row"
          className="tw-justify-between"
        >
          <Box className="tw-text-center">
            <Button
              size="small"
              color="inherit"
              className="tw-opacity-30 tw-ml-1 tw-cursor-pointer"
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.PrivacyPolicyPage.url())
              }}
            >
              {s_PRIVACY_POLICY}
            </Button>
            <Button
              size="small"
              color="inherit"
              className="tw-opacity-30 tw-ml-1 tw-cursor-pointer"
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.TermsOfServicePage.url())
              }}
            >
              {s_TERMS_OF_SERVICE}
            </Button>
          </Box>
          <Box
            className="tw-mt-1 tw-mr-1 tw-opacity-30 tw-text-center"
            sx={{ fontSize: '13px' }}
          >
            {s_COPYRIGHT} © {new Date().getFullYear()} {s_ETW_ENERGY_LLC}. {s_ALL_RIGHTS_RESERVED}.
          </Box>
        </Stack>
      </Box>
    )
    return footerJSX
  }

  const rJSX_ContactUs = (): JSX.Element => {
    let contactJSX = (
      <Box
        className="tw-text-center tw-w-full tw-pt-10"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
        }}
      >
        <Box className="tw-p-4 tw-mt-12">
          <Typography
            className="tw-mt-10 tw-mb-2 tw-font-bold etw_landing_font tw-leading-normal"
            variant="h5"
          >
            {s_CONTACT_US}
          </Typography>
          <Box
            className="tw-px-12 tw-py-4 tw-rounded-xl tw-m-auto"
            sx={{
              background: '#131A30',
              maxWidth: '800px',
            }}
          >
            <Grid2
              container
              spacing={0}
            >
              <Grid2
                xs={12}
                sm={6}
                className="tw-px-3"
              >
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    className="etw_contact_form_input_container"
                    color="primary"
                    value={us_contactForm_FirstName}
                    placeholder={se_FIRST_NAME}
                    margin="normal"
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        us_setContactForm_FirstName(event.target.value)
                      }
                    }}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                className="tw-px-3"
              >
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    className="etw_contact_form_input_container"
                    color="primary"
                    value={us_contactForm_LastName}
                    placeholder={se_LAST_NAME}
                    margin="normal"
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        us_setContactForm_LastName(event.target.value)
                      }
                    }}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                className="tw-px-3"
              >
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    className="etw_contact_form_input_container"
                    color="primary"
                    value={us_contactForm_Email}
                    placeholder={se_EMAIL_ADDRESS}
                    margin="normal"
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        us_setContactForm_Email(event.target.value)
                      }
                    }}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                className="tw-px-3"
              >
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    className="etw_contact_form_input_container"
                    color="primary"
                    value={us_contactForm_Phone}
                    placeholder={se_PHONE_NUMBER}
                    margin="normal"
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        us_setContactForm_Phone(event.target.value)
                      }
                    }}
                    variant="outlined"
                    size="small"
                    // InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid2>
              <Grid2
                xs={12}
                sm={12}
                className="tw-px-3"
              >
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    className="etw_contact_form_input_container"
                    color="primary"
                    value={us_contactForm_Notes}
                    placeholder={se_CONTACT_REASON}
                    margin="normal"
                    multiline
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        us_setContactForm_Notes(event.target.value)
                      }
                    }}
                    variant="outlined"
                    size="small"
                    minRows={4}
                    // InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid2>
              <Grid2
                xs={12}
                sm={12}
                className="tw-text-center"
              >
                {/* #575C6A */}
                <Box
                  className="tw-mt-0 tw-inline-block tw-align-top"
                  sx={{ fontSize: '14px', width: '42px' }}
                >
                  <Checkbox
                    color="warning"
                    checked={us_agreedToContact === true}
                    onChange={(event) => {
                      us_setAgreedToContact(event.target.checked)
                    }}
                  />
                </Box>
                <Box
                  className="tw-mt-2 tw-opacity-40 tw-inline-block tw-text-left"
                  sx={{ fontSize: '14px', width: 'calc(100% - 42px)' }}
                >
                  {s_I_AUTHORIZE_CONTECT_}
                </Box>
              </Grid2>
              <Grid2
                xs={12}
                sm={12}
                className="tw-text-center"
              >
                <Button
                  className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case tw-mt-6"
                  variant="contained"
                  sx={{ width: '280px' }}
                  disabled={
                    us_contactForm_FirstName === '' ||
                    us_contactForm_LastName === '' ||
                    us_contactForm_Email === '' ||
                    us_contactForm_Phone === '' ||
                    us_submittingContactForm === true ||
                    us_agreedToContact === false
                  }
                  onClick={() => {
                    submitContactRequestForm(
                      us_contactForm_FirstName,
                      us_contactForm_LastName,
                      us_contactForm_Email,
                      us_contactForm_Phone,
                      us_contactForm_Notes,
                    )
                  }}
                >
                  {s_CONTACT_US}
                </Button>
              </Grid2>
            </Grid2>
          </Box>
        </Box>
        {rJSX_Footer()}
      </Box>
    )
    return contactJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = <></>
    let baseUrl = getBaseURL()
    if (baseUrl.includes('type3.app')) {
      // if (baseUrl.includes('type3.app') || baseUrl.includes('localhost')) {
      pageJSX = (
        <Box>
          <LinearProgress />
        </Box>
      )
    } else {
      pageJSX = (
        <Box className="etw_landing_smooth_container">
          {rJSX_StagingWarning()}
          <Box
            id="intro"
            className="etw_landing_smooth_section tw-w-full tw-h-full"
            sx={{
              backgroundImage: {
                xs: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(' + bg2Src + ')',
                md: 'url(' + bg2Src + ')',
              },
              backgroundColor: '#000',
              backgroundRepeat: 'no-repeat',
              backgroundSize: {
                xs: 'cover',
                md: 'cover',
              },
              backgroundPositionX: {
                xs: '80%',
                md: 'center',
              },
              minHeight: '100vh',
            }}
          >
            <Box
              className="tw-m-auto"
              sx={{ maxWidth: '1000px' }}
            >
              {rJSX_Header()}
            </Box>
            <Divider />
            <Box sx={{ height: { xs: '40px', md: '80px', lg: '120px' } }}></Box>
            <Box
              className="tw-p-4 tw-m-auto"
              sx={{ maxWidth: '1000px' }}
            >
              {rJSX_Intro()}
            </Box>
          </Box>
          <Box
            id="about"
            className="etw_landing_smooth_section tw-w-full tw-h-full"
            sx={{
              background: themeVariables.black,
              width: '100%',
              minHeight: '80vh',
            }}
          >
            <Box
              className="tw-p-4 tw-m-auto"
              sx={{ maxWidth: '800px' }}
            >
              {rJSX_About()}
            </Box>
          </Box>
          <Box
            id="team"
            className="etw_landing_smooth_section tw-w-full tw-h-full"
            sx={{
              background: themeVariables.black,
              width: '100%',
              minHeight: '70vh',
            }}
          >
            <Box
              className="tw-p-4 tw-m-auto"
              sx={{ maxWidth: '800px' }}
            >
              {rJSX_Team()}
            </Box>
          </Box>
          <Box
            id="contact"
            className="etw_landing_smooth_section tw-w-full tw-h-full"
            sx={{
              background: themeVariables.black,
              width: '100%',
              // minHeight: "60vh"
            }}
          >
            <Box>{rJSX_ContactUs()}</Box>
          </Box>
          <style>{pageCSS}</style>
        </Box>
      )
    }
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

// height: 100vh;
// width: 100%;
// display: flex;
// justify-content: center;
// align-items: center;
// scroll-snap-align: center;
// position: relative;
