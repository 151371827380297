import { Link } from 'react-router-dom'
import { TsInterface_TableAdditionalData, TsInterface_TableColumn, TsInterface_TableDataRow, TsInterface_TableHooks, TsType_TableSortBy } from '../table_types'

export const TableCellURL = (propKey: string, propName: string | JSX.Element, sortBy: TsType_TableSortBy): TsInterface_TableColumn => {
  let urlCell = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const urlValue = rowData[propKey] ? String(rowData[propKey]) : '' // Convert to string and handle null or undefined

        return urlValue ? (
          <Link
            // @ts-ignore
            href={urlValue.startsWith('http') ? urlValue : `http://${urlValue}`} // Ensure it has http:// or https://
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
            color="primary"
          >
            {urlValue}
          </Link>
        ) : (
          <span>No URL</span> // Display fallback text if URL is not available
        )
      },
    },
  }
  return urlCell
}
