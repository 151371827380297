/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { formInputs_NewStickyNote, formInputs_StickyNote, formSettings_StickyNote } from 'app/pages/projects/forms/sticky_notes'
import { ProjectStickyNotesDialog } from 'app/pages/projects/v2_dialogs/dialog_sticky_notes'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_TaskWorkflowDelayReasons_Collection,
  DatabaseRef_TaskWorkflowProd_Document,
  DatabaseRef_TaskWorkflow_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  DatabaseRef_ActiveProjectsForSpecificWorkflowAndSalesPartner_Query,
  DatabaseRef_ActiveProjectTimestampsForSpecificWorkflowAndSalesPartner_Query,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { IconMenu } from 'rfbp_core/components/icon_menu/icon_menu'
import {
  TableBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
} from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  objectToArray,
  replaceNewlinesWithHtmlBreaks,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import {
  deleteDeadlineExtension,
  formInputs_DeadlineExtension,
  formSettings_DeadlineExtension,
  formSubmission_DeadlineExtension,
  formSubmission_DeadlineExtensionEdit,
} from '../forms/deadline_extensions'
import { returnProjectsSortedByMilestonePhase, returnSortedWorkflowPhasesArray } from '../services/project_phases'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_Pacing_ProjectPhasesTab {
  selectedTaskWorkflowKey: string | null
  taskWorkflows: TsInterface_UnspecifiedObject
  readOrWrite: 'read' | 'write'
  projectViewPageKey: string
  clientKeyOverride?: string
  useSalesPartnerInstallPartnerProjectView?: boolean
  snoozeFilter?: string
  filteredProjectPacingTags?: string[]
}

///////////////////////////////
// Variables
///////////////////////////////

const rJSX_PacingLabels = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  project: TsInterface_UnspecifiedObject,
  projectKey: string,
  visiblePacingTags: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  readOrWrite: 'read' | 'write',
): JSX.Element => {
  let labelsJSX = (
    <Box>
      {objectToArray(project['pacing_tags'])
        .sort(dynamicSort('text', null))
        .map((loopPacingTag: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>
            {rJSX_PacingLabel(
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              project,
              projectKey,
              visiblePacingTags,
              uc_setUserInterface_FormDialogDisplay,
              loopPacingTag,
              readOrWrite,
            )}
          </Box>
        ))}
      {objectToArray(project['pacing_tags']).length == 0 ? <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('No Tags')}</Box> : <></>}
      {readOrWrite === 'write' ? (
        <Box>
          <Icon
            icon="circle-plus"
            className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
            tooltip={rLIB('Add Tag')}
            tooltipPlacement="right"
            onClick={() => {
              openProjectTagDialog(uc_RootData_ClientKey, visiblePacingTags, project, projectKey, uc_setUserInterface_FormDialogDisplay, 'new', null)
            }}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
  return labelsJSX
}

const rJSX_PacingLabel = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  project: TsInterface_UnspecifiedObject,
  projectKey: string,
  visiblePacingTags: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  pacingTag: TsInterface_UnspecifiedObject,
  readOrWrite: 'read' | 'write',
): JSX.Element => {
  let fullTagJSX = <></>
  let labelJSX = <></>
  if (pacingTag != null && pacingTag.text != null && pacingTag.text !== '' && pacingTag.color != null && pacingTag.color !== '') {
    let color = getProp(pacingTag, 'color', themeVariables.gray_500)
    labelJSX = (
      <Box
        className="tw-inline-block tw-px-2 tw-py-1"
        sx={{ backgroundColor: color, borderRadius: '6px', color: themeVariables.white }}
      >
        <Icon
          icon="tag"
          className="tw-mr-2"
        />
        {pacingTag.text}
      </Box>
    )
  }
  if (readOrWrite === 'write') {
    fullTagJSX = (
      <IconMenu
        icon={labelJSX}
        menuItems={[
          {
            icon: 'pen-to-square',
            text: rLIB('Edit'),
            onClick: () => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // open edit modal
                  openProjectTagDialog(res_GCK.clientKey, visiblePacingTags, project, projectKey, uc_setUserInterface_FormDialogDisplay, 'edit', pacingTag.key)
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            },
          },
          {
            icon: 'trash',
            text: rLIB('Delete'),
            onClick: () => {
              let updateObject = {
                pacing_tags: project.pacing_tags,
              }
              delete updateObject.pacing_tags[pacingTag.key]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // open edit modal
                  DatabaseUpdateDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                })
            },
          },
        ]}
      />
    )
  } else {
    fullTagJSX = labelJSX
  }
  return <Box className="tw-mb-1 tw-cursor-pointer">{fullTagJSX}</Box>
}

const openProjectTagDialog = (
  clientKey: string,
  visiblePacingTags: TsInterface_UnspecifiedObject,
  project: TsInterface_UnspecifiedObject,
  projectKey: string,
  uc_setUserInterface_FormDialogDisplay: any,
  action: 'new' | 'edit',
  tagKey: string | null,
): void => {
  let quickSelectOptions: TsInterface_UnspecifiedObject = {}
  for (let loopPacingLabelKey in visiblePacingTags) {
    let loopPacingLabel = visiblePacingTags[loopPacingLabelKey]
    quickSelectOptions[loopPacingLabelKey] = {
      value: (
        <Box>
          <Box
            className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
            sx={{ background: loopPacingLabel.color, borderRadius: '6px', color: themeVariables.white }}
          >
            <Icon
              type="solid"
              icon="tag"
              className="tw-mr-2"
            />
            {loopPacingLabel.text}
          </Box>
        </Box>
      ),
      key: loopPacingLabelKey,
    }
  }
  let formInputs: TsInterface_FormInputs = {}
  if (objectToArray(quickSelectOptions).length > 0) {
    formInputs['quick_select'] = {
      data_type: 'string',
      input_type: 'multiple_choice_radio',
      key: 'quick_select',
      label: <>{rLIB('Quick Select')}</>,
      required: false,
      options: objectToArray(quickSelectOptions),
      submit_on_change: true,
    }
  }
  formInputs['text'] = {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'text',
    label: <>{rLIB('Label')}</>,
    required: true,
  }
  formInputs['color'] = {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'color',
    label: <>{rLIB('Color')}</>,
    required: false,
    options: [
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.error_main, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Red')}
            </Box>
          </Box>
        ),
        key: themeVariables.error_main,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.orange_500, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Orange')}
            </Box>
          </Box>
        ),
        key: themeVariables.orange_500,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.warning_main, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Yellow')}
            </Box>
          </Box>
        ),
        key: themeVariables.warning_main,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.success_main, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Green')}
            </Box>
          </Box>
        ),
        key: themeVariables.success_main,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.info_main, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Blue')}
            </Box>
          </Box>
        ),
        key: themeVariables.info_main,
      },
      {
        value: (
          <Box>
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-mr-2"
              sx={{ background: themeVariables.purple_500, borderRadius: '6px', color: themeVariables.white }}
            >
              <Icon
                type="solid"
                icon="tag"
                className="tw-mr-2"
              />
              {rLIB('Purple')}
            </Box>
          </Box>
        ),
        key: themeVariables.purple_500,
      },
    ],
  }

  let existingTag: TsInterface_UnspecifiedObject = {}
  if (action === 'edit' && tagKey != null && tagKey !== '' && project != null && project.pacing_tags != null && project.pacing_tags[tagKey] != null) {
    existingTag = project.pacing_tags[tagKey]
  }

  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: existingTag,
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            if (action === 'new') {
              tagKey = uuidv4()
            }
            let updateObject = {}
            if (formSubmittedData.quick_select != null) {
              // Split at last instance of '-'
              let quickSelectColor = formSubmittedData.quick_select.split('-').pop()
              // Get string before last instance of '-'
              let quickSelectText = formSubmittedData.quick_select.substring(0, formSubmittedData.quick_select.lastIndexOf('-'))
              updateObject = {
                pacing_tags: {
                  [tagKey as string]: {
                    key: tagKey,
                    text: quickSelectText,
                    color: quickSelectColor,
                  },
                },
              }
            } else {
              updateObject = {
                pacing_tags: {
                  [tagKey as string]: {
                    key: tagKey,
                    text: formSubmittedData.text,
                    color: getProp(formSubmittedData, 'color', themeVariables.gray_500),
                  },
                },
              }
            }
            DatabaseSetMergeDocument(DatabaseRef_Project_Document(clientKey, projectKey), updateObject)
              .then((res_DSMD) => {
                resolve(true)
              })
              .catch((rej_DSMD) => {
                console.error(rej_DSMD)
                reject(rej_DSMD)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: (
          <>
            {rLIB('Edit Pacing Tag')} - {getProp(project, 'id_number', '')}
          </>
        ),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="tag"
          />
        ),
      },
    },
  })
}

const tableColumns_ProjectsByMilestone: TsInterface_TableColumns = {
  // associated_customer_risk_status: customerRiskStatusCell,
  timestamp_snoozed_until: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let currentTimestamp = new Date().getTime()

        const updateSnoozeTimestamp = (days: number) => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              // Set timestamp to next midnight(s)
              const today = new Date()
              const nextMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate() + days + 1, 0, 0, 0)
              DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, rowData.key as string), {
                timestamp_snoozed_until: nextMidnight,
              })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
        const snoozeMenuItems = [
          {
            icon: 'snooze',
            text: rLIB('Unsnooze'),
            onClick: () => {
              updateSnoozeTimestamp(-1)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 1 day'),
            onClick: () => {
              updateSnoozeTimestamp(0)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 2 days'),
            onClick: () => {
              updateSnoozeTimestamp(1)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 3 days'),
            onClick: () => {
              updateSnoozeTimestamp(2)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 4 days'),
            onClick: () => {
              updateSnoozeTimestamp(3)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 5 days'),
            onClick: () => {
              updateSnoozeTimestamp(4)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 6 days'),
            onClick: () => {
              updateSnoozeTimestamp(5)
            },
          },
          {
            icon: 'snooze',
            text: rLIB('Snooze for 7 days'),
            onClick: () => {
              updateSnoozeTimestamp(6)
            },
          },
        ]
        if (tableAdditionalData.readOrWrite === 'write') {
          if (
            rowData != null &&
            rowData.timestamp_snoozed_until != null &&
            returnTimestampFromUnknownDateFormat(rowData.timestamp_snoozed_until) > currentTimestamp
          ) {
            let daysUntilSnooze = Math.ceil((returnTimestampFromUnknownDateFormat(rowData.timestamp_snoozed_until) - currentTimestamp) / (1000 * 60 * 60 * 24))
            cellJSX = (
              <Stack
                direction="row"
                spacing={0.5}
              >
                <Box sx={{ marginTop: '-2px !important' }}>
                  <Typography sx={{ color: themeVariables.info_main, fontSize: '1.2rem', fontWeight: 'bold' }}>{daysUntilSnooze}</Typography>
                </Box>
                <IconMenu
                  icon={
                    <Icon
                      icon="alarm-snooze"
                      sx={{ fontSize: '1.3rem', color: themeVariables.info_main }}
                      className="tw-cursor-pointer"
                      tooltip={returnFormattedDate(rowData.timestamp_snoozed_until, 'h:mm a D MMM YY')}
                      tooltipPlacement="right"
                    />
                  }
                  menuItems={snoozeMenuItems}
                />
              </Stack>
            )
          } else {
            cellJSX = (
              <Box>
                <IconMenu
                  icon={
                    <Icon
                      icon="alarm-exclamation"
                      sx={{ fontSize: '1.3rem', color: themeVariables.warning_main }}
                      className="tw-cursor-pointer"
                    />
                  }
                  menuItems={snoozeMenuItems}
                />
              </Box>
            )
          }
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: 'timestamp_snoozed_until',
    },
  },
  id_number: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let projectLinkColor = themeVariables.background_default
        if (rowData != null && rowData.ALL_PACING_DATA != null && getProp(rowData.ALL_PACING_DATA, 'project_current_color', null)) {
          projectLinkColor = getProp(rowData.ALL_PACING_DATA, 'project_current_color', null)
        }
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ background: projectLinkColor }}
              onClick={(event) => {
                if (rowData.key != null) {
                  if (tableAdditionalData.pr_useSalesPartnerInstallPartnerProjectView === true) {
                    onClickAppNavigation(
                      event,
                      tableHooks.un_routerNavigation,
                      ApplicationPages['SalesInstallPartnerProjectViewPage'].url(tableAdditionalData.pr_clientKeyOverride, rowData.key as string),
                    )
                  } else {
                    // TODO - handle other user types probably
                    onClickAppNavigation(
                      event,
                      tableHooks.un_routerNavigation,
                      ApplicationPages[tableAdditionalData.projectViewPageKey].url(rowData.key as string),
                    )
                  }
                }
              }}
            >
              {rowData.id_number}
            </Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Job Code')
      },
      header_sort_by: null,
    },
  },
  pacing_tags: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = rJSX_PacingLabels(
          tableHooks.uc_RootData_ClientKey,
          tableHooks.uc_setRootData_ClientKey,
          rowData,
          rowData.key as string,
          tableAdditionalData.us_visibleProjectPacingTags,
          tableHooks.uc_setUserInterface_FormDialogDisplay,
          tableAdditionalData.readOrWrite,
        )

        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Tag')
      },
      header_sort_by: null,
    },
  },
  associated_customer_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box>
            <Box>{rowData.associated_customer_name}</Box>
            <Box className="tw-opacity-30">{rowData.location_jurisdiction}</Box>
            <Box className="tw-opacity-30">{rowData.associated_finance_partner_name}</Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Customer / AHJ')
      },
      header_sort_by: 'associated_customer_name',
    },
  },
  TEMP_project_start_date: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box>{returnFormattedDate(rowData.TEMP_project_start_date, 'D MMM YY')}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Project Start Date')
      },
      header_sort_by: 'TEMP_project_start_date',
    },
  },
  TEMP_project_phase_pace_target_delta: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let greenText = <></>
        let redText = <></>
        let greenDateSx = {}
        let redDateSx = {}
        if (
          rowData != null &&
          rowData.TEMP_project_phase_pace_target_delta != null &&
          getProp(rowData, 'TEMP_project_phase_pace_target_delta', null) != null &&
          getProp(rowData, 'TEMP_project_phase_pace_target_delta', null) > 0
        ) {
          // RED
          redDateSx = {
            border: `2px solid ${themeVariables.error_main}`,
            background: themeVariables.error_main,
          }
          greenDateSx = {
            border: `2px solid ${themeVariables.success_main}`,
            // background: themeVariables.success_main,
          }
          redText = (
            <Box className="tw-text-error_main tw-mt-1.5">
              {Math.abs(rowData.TEMP_project_phase_pace_target_delta as number)}{' '}
              {Math.abs(rowData.TEMP_project_phase_pace_target_delta as number) === 1 ? rLIB('day') : rLIB('days')} {rLIB('after deadline')}
            </Box>
          )
          greenText = (
            <Box className="tw-text-error_main tw-mt-1.5">
              {Math.abs(rowData.TEMP_project_phase_pace_target_green_only_delta as number)}{' '}
              {Math.abs(rowData.TEMP_project_phase_pace_target_green_only_delta as number) === 1 ? rLIB('day') : rLIB('days')} {rLIB('after deadline')}
            </Box>
          )
        } else if (
          rowData != null &&
          rowData.TEMP_project_phase_pace_target_green_only_delta != null &&
          getProp(rowData, 'TEMP_project_phase_pace_target_green_only_delta', null) != null &&
          getProp(rowData, 'TEMP_project_phase_pace_target_green_only_delta', null) > 0
        ) {
          // YELLOW
          redDateSx = {
            border: `2px solid ${themeVariables.error_main}`,
            // background: themeVariables.error_main,
          }
          greenDateSx = {
            border: `2px solid ${themeVariables.success_main}`,
            // background: themeVariables.success_main,
          }
          redText = (
            <Box className="tw-text-warning_main tw-mt-1.5">
              {Math.abs(rowData.TEMP_project_phase_pace_target_delta as number)}{' '}
              {Math.abs(rowData.TEMP_project_phase_pace_target_delta as number) === 1 ? rLIB('day') : rLIB('days')} {rLIB('to go')}
            </Box>
          )
          greenText = (
            <Box className="tw-text-warning_main tw-mt-1.5">
              {Math.abs(rowData.TEMP_project_phase_pace_target_green_only_delta as number)}{' '}
              {Math.abs(rowData.TEMP_project_phase_pace_target_green_only_delta as number) === 1 ? rLIB('day') : rLIB('days')} {rLIB('after deadline')}
            </Box>
          )
        } else {
          // GREEN
          redDateSx = {
            border: `2px solid ${themeVariables.error_main}`,
            // background: themeVariables.error_main,
          }
          greenDateSx = {
            border: `2px solid ${themeVariables.success_main}`,
            background: themeVariables.success_main,
          }
          redText = (
            <Box className="tw-text-success_main tw-mt-1.5">
              {Math.abs(rowData.TEMP_project_phase_pace_target_delta as number)}{' '}
              {Math.abs(rowData.TEMP_project_phase_pace_target_delta as number) === 1 ? rLIB('day') : rLIB('days')} {rLIB('to go')}
            </Box>
          )
          greenText = (
            <Box className="tw-text-success_main tw-mt-1.5">
              {Math.abs(rowData.TEMP_project_phase_pace_target_green_only_delta as number)}{' '}
              {Math.abs(rowData.TEMP_project_phase_pace_target_green_only_delta as number) === 1 ? rLIB('day') : rLIB('days')} {rLIB('to go')}
            </Box>
          )
        }
        // Cell JSX
        cellJSX = (
          <Box>
            <Box>
              <Stack
                direction="row"
                className="tw-capitalize tw-mb-2"
              >
                <Box className="tw-mr-2 tw-pt-1.5">{rLIB('Green Until')}:</Box>
                <Box
                  className="tw-rounded tw-px-1 tw-py-1 tw-mr-2"
                  sx={greenDateSx}
                >
                  {returnFormattedDate(new Date().getTime() - (rowData.TEMP_project_phase_pace_target_green_only_delta as number) * 86400000, 'D MMM YY')}
                </Box>
                {greenText}
              </Stack>
              <Stack
                direction="row"
                className="tw-capitalize tw-mb-2"
              >
                <Box className="tw-mr-2 tw-pt-1.5">{rLIB('Red After')}:</Box>
                <Box
                  className="tw-rounded tw-px-1 tw-py-1 tw-mr-2"
                  sx={redDateSx}
                >
                  {returnFormattedDate(new Date().getTime() - (rowData.TEMP_project_phase_pace_target_delta as number) * 86400000, 'D MMM YY')}
                </Box>
                {redText}
              </Stack>
            </Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Phase Target Completion')
      },
      header_sort_by: 'TEMP_project_phase_pace_target_delta',
    },
  },

  // TEMP_last_milestone_timestamp: {
  //   cell: {
  //     cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
  //       return ''
  //     },
  //     cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
  //       let cellJSX = <></>
  //       let cutoffDaysAmount = getProp(rowData, 'TEMP_phase_expected_days', 0)
  //       let extensionDaysAmount = getProp(rowData, 'TEMP_phase_extension_days', 0)
  //       let sumOfCutoffAndExtensions = cutoffDaysAmount + extensionDaysAmount
  //       let daysColor = themeVariables.info_main
  //       if (cutoffDaysAmount != null) {
  //         if (rowData.TEMP_days_in_phase != null && rowData.TEMP_days_in_phase <= cutoffDaysAmount) {
  //           daysColor = themeVariables.success_main
  //         } else if (rowData.TEMP_days_in_phase != null && rowData.TEMP_days_in_phase <= sumOfCutoffAndExtensions) {
  //           daysColor = themeVariables.warning_main
  //         } else {
  //           daysColor = themeVariables.error_main
  //         }
  //       }
  //       cellJSX = (
  //         <>
  //           <Box>
  //             {rowData.TEMP_last_milestone_timestamp != null ? (
  //               <>
  //                 <Box className="tw-capitalize tw-px-1 tw-py-1">{returnFormattedDate(rowData.TEMP_last_milestone_timestamp, 'D MMM YY')}</Box>
  //               </>
  //             ) : (
  //               <></>
  //             )}
  //           </Box>
  //           <Box>
  //             {rowData.TEMP_last_milestone_timestamp != null ? (
  //               <>
  //                 <Box
  //                   sx={{ background: daysColor }}
  //                   className="tw-px-2 tw-py-1 tw-rounded-md tw-inline-block"
  //                 >
  //                   {rowData.TEMP_days_in_phase} {rowData.TEMP_days_in_phase === 1 ? <>{rLIB('day')}</> : <>{rLIB('days')}</>}
  //                 </Box>
  //               </>
  //             ) : (
  //               <></>
  //             )}
  //           </Box>
  //         </>
  //       )
  //       return cellJSX
  //     },
  //   },
  //   header: {
  //     header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
  //       return ''
  //     },
  //     header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
  //       return rLIB('Phase Start Date')
  //     },
  //     header_sort_by: 'TEMP_last_milestone_timestamp',
  //   },
  // },

  // TEMP_estimated_phase_completion_timestamp: {
  //   cell: {
  //     cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
  //       return ''
  //     },
  //     cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
  //       let cellJSX = <></>
  //       let daysLeftJSX = <></>
  //       let cutoffDaysAmount = getProp(rowData, 'TEMP_phase_expected_days', 0)
  //       let extensionDaysAmount = getProp(rowData, 'TEMP_phase_extension_days', 0)
  //       let sumOfCutoffAndExtensions = cutoffDaysAmount + extensionDaysAmount
  //       if (cutoffDaysAmount != null) {
  //         let daysLeft = getProp(rowData, 'TEMP_days_in_phase', 0) - sumOfCutoffAndExtensions
  //         if (daysLeft > 0) {
  //           daysLeftJSX = (
  //             <Box className="tw-text-error_main">
  //               {daysLeft} {daysLeft === 1 ? rLIB('day') : rLIB('days')} {rLIB('after deadline')}
  //             </Box>
  //           )
  //         } else {
  //           daysLeftJSX = (
  //             <Box className="tw-text-success_main">
  //               {Math.abs(daysLeft)} {Math.abs(daysLeft) === 1 ? rLIB('day') : rLIB('days')} {rLIB('to go')}
  //             </Box>
  //           )
  //         }
  //       }
  //       cellJSX = (
  //         <>
  //           {rowData.TEMP_estimated_phase_completion_timestamp != null ? (
  //             <Box>
  //               <Box className="tw-capitalize">{returnFormattedDate(rowData.TEMP_estimated_phase_completion_timestamp, 'D MMM YY')}</Box>
  //               {daysLeftJSX}
  //             </Box>
  //           ) : (
  //             <></>
  //           )}
  //         </>
  //       )
  //       return cellJSX
  //     },
  //   },
  //   header: {
  //     header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
  //       return ''
  //     },
  //     header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
  //       return rLIB('Est. Completion')
  //     },
  //     header_sort_by: 'TEMP_estimated_phase_completion_timestamp',
  //   },
  // },

  deadline_extensions: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let newIcon = <></>
        if (tableAdditionalData.readOrWrite === 'write') {
          newIcon = (
            <Icon
              icon="circle-plus"
              tooltip={rLIB('Add Deadline Extension')}
              tooltipPlacement="right"
              className="tw-mr-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
              onClick={() => {
                // Generate List of Delay Reasons
                let delayReasons: TsInterface_UnspecifiedObject[] = []
                for (let loopReasonKey in getProp(getProp(tableAdditionalData, 'us_taskWorkflow', {}), 'phase_delay_reasons', {})) {
                  if (
                    tableAdditionalData != null &&
                    tableAdditionalData['us_taskWorkflow'] != null &&
                    tableAdditionalData['us_taskWorkflow']['phase_delay_reasons'] != null &&
                    tableAdditionalData['us_taskWorkflow']['phase_delay_reasons'][loopReasonKey] === true &&
                    tableAdditionalData['us_phaseDelayReasons'] != null &&
                    tableAdditionalData['us_phaseDelayReasons'][loopReasonKey] != null &&
                    tableAdditionalData['us_phaseDelayReasons'][loopReasonKey]['name']
                  ) {
                    delayReasons.push({
                      key: tableAdditionalData['us_phaseDelayReasons'][loopReasonKey]['name'],
                      value: tableAdditionalData['us_phaseDelayReasons'][loopReasonKey]['name'],
                    })
                  }
                }
                formInputs_DeadlineExtension['extension_reason']['options'] = delayReasons.sort(dynamicSort('value', 'asc'))
                let cutoffDaysAmount = null
                if (
                  rowData != null &&
                  tableAdditionalData != null &&
                  tableAdditionalData['milestoneTaskKey'] != null &&
                  tableAdditionalData['us_taskWorkflow'] != null &&
                  tableAdditionalData['us_taskWorkflow']['filter_milestone_days'] != null &&
                  tableAdditionalData['us_taskWorkflow']['filter_milestone_days'][tableAdditionalData['milestoneTaskKey']] != null
                ) {
                  cutoffDaysAmount = tableAdditionalData['us_taskWorkflow']['filter_milestone_days'][tableAdditionalData['milestoneTaskKey']]
                }
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {
                        projectKey: rowData.key,
                        milestoneTaskKey: tableAdditionalData['milestoneTaskKey'],
                        cutoffDaysAmount: cutoffDaysAmount,
                        TEMP_last_milestone_timestamp: rowData.TEMP_last_milestone_timestamp,
                        TEMP_days_in_phase: rowData.TEMP_days_in_phase,
                        TEMP_estimated_phase_completion_timestamp: rowData.TEMP_estimated_phase_completion_timestamp,
                        TEMP_phase_expected_days: rowData.TEMP_phase_expected_days,
                        TEMP_phase_extension_days: rowData.TEMP_phase_extension_days,
                      },
                      formData: rowData,
                      formInputs: formInputs_DeadlineExtension,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_DeadlineExtension,
                      formSubmission: formSubmission_DeadlineExtension,
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: rLIB('Add Deadline Extension'),
                      formDialogIcon: (
                        <Icon
                          icon="circle-plus"
                          className="tw-mr-2"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          )
        }
        let extensionContents = <></>

        let projectPacingData = getProp(rowData, 'ALL_PACING_DATA', {})

        if (
          projectPacingData != null &&
          ((projectPacingData['current_deadline_extensions'] != null && objectToArray(projectPacingData['current_deadline_extensions']).length > 0) ||
            (projectPacingData['past_deadline_extensions'] != null && objectToArray(projectPacingData['past_deadline_extensions']).length > 0))
        ) {
          let previousMilestoneExtensions = objectToArray(getProp(projectPacingData, 'past_deadline_extensions', {}))
          let currentMilestoneExtensions = objectToArray(getProp(projectPacingData, 'current_deadline_extensions', {}))
          const returnDelayType = (extension: TsInterface_UnspecifiedObject) => {
            let delayType = 'default'
            if (
              extension != null &&
              extension['extension_reason'] != null &&
              tableAdditionalData != null &&
              tableAdditionalData.us_phaseDelayReasonTypes != null &&
              tableAdditionalData.us_phaseDelayReasonTypes[extension['extension_reason']] != null
            ) {
              delayType = tableAdditionalData.us_phaseDelayReasonTypes[extension['extension_reason']]
            }
            return delayType
          }

          const rJSX_ExtensionContent = (extension: TsInterface_UnspecifiedObject, type: 'current' | 'past') => {
            let extensionContents = (
              <Stack
                direction="row"
                spacing={0}
                className="tw-mb-1"
                sx={{
                  opacity: type === 'past' ? 0.3 : 1,
                }}
              >
                <Box className="tw-cursor-default">
                  <Tooltip
                    title={
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="body1">{rLIB('Created By')}:</Typography>
                          <Typography
                            sx={{ color: themeVariables.warning_main }}
                            variant="body1"
                          >
                            {extension['created_by_name']}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="body1">{rLIB('Created On')}:</Typography>
                          <Typography
                            sx={{ color: themeVariables.warning_main }}
                            variant="body1"
                          >
                            {returnFormattedDate(extension['timestamp_created'], 'D MMM YY h:mm a')}
                          </Typography>
                        </Stack>

                        {extension['last_updated_by_name'] != null && (
                          <Stack
                            direction="row"
                            spacing={1}
                          >
                            <Typography variant="body1">{rLIB('Last Updated By')}:</Typography>
                            <Typography
                              sx={{ color: themeVariables.warning_main }}
                              variant="body1"
                            >
                              {extension['last_updated_by_name']}
                            </Typography>
                          </Stack>
                        )}
                        {extension['timestamp_last_updated'] != null && (
                          <Stack
                            direction="row"
                            spacing={1}
                          >
                            <Typography variant="body1">{rLIB('Last Updated On')}:</Typography>
                            <Typography
                              sx={{ color: themeVariables.warning_main }}
                              variant="body1"
                            >
                              {returnFormattedDate(extension['timestamp_last_updated'], 'D MMM YY h:mm a')}
                            </Typography>
                          </Stack>
                        )}

                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="body1">{rLIB('# of Days')}:</Typography>
                          <Typography
                            sx={{ color: themeVariables.warning_main }}
                            variant="body1"
                          >
                            {extension['extension_days']}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="body1">{rLIB('Reason')}:</Typography>
                          <Typography
                            sx={{ color: themeVariables.warning_main }}
                            variant="body1"
                          >
                            {extension['extension_reason']}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="body1">{rLIB('Notes')}:</Typography>
                          <Typography
                            sx={{ color: themeVariables.warning_main }}
                            variant="body1"
                          >
                            {extension['extension_notes']}
                          </Typography>
                        </Stack>
                      </Box>
                    }
                    placement="top"
                  >
                    <Box>
                      <IconMenu
                        icon={
                          <Box
                            className="tw-inline-block"
                            sx={{
                              background: returnDelayType(extension) === 'excused' ? themeVariables.teal_700 : themeVariables.warning_main,
                              padding: '4px 8px',
                              borderRadius: '4px',
                              marginRight: '4px',
                            }}
                          >
                            {extension['extension_days']} {extension['extension_days'] === 1 ? <>{rLIB('day')}</> : <>{rLIB('days')}</>}
                          </Box>
                        }
                        menuItems={[
                          {
                            icon: 'pen-to-square',
                            text: rLIB('Edit'),
                            onClick: () => {
                              let cutoffDaysAmount = null
                              if (
                                rowData != null &&
                                tableAdditionalData != null &&
                                extension != null &&
                                extension.associated_milestone_key != null &&
                                tableAdditionalData['us_taskWorkflow'] != null &&
                                tableAdditionalData['us_taskWorkflow']['filter_milestone_days'] != null &&
                                tableAdditionalData['us_taskWorkflow']['filter_milestone_days'][extension.associated_milestone_key] != null
                              ) {
                                cutoffDaysAmount = tableAdditionalData['us_taskWorkflow']['filter_milestone_days'][extension.associated_milestone_key]
                              }
                              // Generate List of Delay Reasons
                              let delayReasons: TsInterface_UnspecifiedObject[] = []
                              for (let loopReasonKey in getProp(getProp(tableAdditionalData, 'us_taskWorkflow', {}), 'phase_delay_reasons', {})) {
                                if (
                                  tableAdditionalData != null &&
                                  tableAdditionalData['us_taskWorkflow'] != null &&
                                  tableAdditionalData['us_taskWorkflow']['phase_delay_reasons'] != null &&
                                  tableAdditionalData['us_taskWorkflow']['phase_delay_reasons'][loopReasonKey] === true &&
                                  tableAdditionalData['us_phaseDelayReasons'] != null &&
                                  tableAdditionalData['us_phaseDelayReasons'][loopReasonKey] != null &&
                                  tableAdditionalData['us_phaseDelayReasons'][loopReasonKey]['name']
                                ) {
                                  delayReasons.push({
                                    key: tableAdditionalData['us_phaseDelayReasons'][loopReasonKey]['name'],
                                    value: tableAdditionalData['us_phaseDelayReasons'][loopReasonKey]['name'],
                                  })
                                }
                              }
                              formInputs_DeadlineExtension['extension_reason']['options'] = delayReasons.sort(dynamicSort('value', 'asc'))
                              tableHooks.uc_setUserInterface_FormDialogDisplay({
                                display: true,
                                form: {
                                  form: {
                                    formAdditionalData: {
                                      projectKey: rowData.key,
                                      milestoneTaskKey: extension.associated_milestone_key,
                                      cutoffDaysAmount: cutoffDaysAmount,
                                      TEMP_last_milestone_timestamp: rowData.TEMP_last_milestone_timestamp,
                                      TEMP_days_in_phase: rowData.TEMP_days_in_phase,
                                      TEMP_estimated_phase_completion_timestamp: rowData.TEMP_estimated_phase_completion_timestamp,
                                      TEMP_phase_expected_days: rowData.TEMP_phase_expected_days,
                                      TEMP_phase_extension_days: rowData.TEMP_phase_extension_days,
                                      extensionKey: extension.key,
                                    },
                                    formData: { ...extension },
                                    formInputs: formInputs_DeadlineExtension,
                                    formOnChange: (
                                      formAdditionalData: TsInterface_FormAdditionalData,
                                      formData: TsInterface_FormData,
                                      formInputs: TsInterface_FormInputs,
                                      formSettings: TsInterface_FormSettings,
                                    ) => {},
                                    formSettings: formSettings_DeadlineExtension,
                                    formSubmission: formSubmission_DeadlineExtensionEdit,
                                  },
                                  dialog: {
                                    formDialogHeaderColor: 'success',
                                    formDialogHeaderText: rLIB('Add Deadline Extension'),
                                    formDialogIcon: (
                                      <Icon
                                        icon="circle-plus"
                                        className="tw-mr-2"
                                      />
                                    ),
                                  },
                                },
                              })
                            },
                          },
                          {
                            icon: 'trash',
                            text: rLIB('Delete'),
                            onClick: () => {
                              tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                                display: true,
                                confirm: {
                                  color: 'error',
                                  icon: <Icon icon="trash-xmark" />,
                                  header: rLIB('Delete Deadline Extension'),
                                  text: rLIB('Are you sure that you want to delete this deadline extension?'),
                                  submit_text: rLIB('Delete'),
                                  submit_callback: () => {
                                    return new Promise((resolve, reject) => {
                                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                        .then((res_GCK) => {
                                          deleteDeadlineExtension(
                                            res_GCK.clientKey,
                                            extension.key,
                                            rowData.key as string,
                                            extension.associated_milestone_key as string,
                                          )
                                            .then((res_DDE) => {
                                              resolve(res_DDE)
                                            })
                                            .catch((rej_DDE) => {
                                              reject(rej_DDE)
                                            })
                                        })
                                        .catch((rej_GCK) => {
                                          reject(rej_GCK)
                                        })
                                    })
                                  },
                                },
                              })
                            },
                          },
                        ]}
                      />
                    </Box>
                  </Tooltip>
                </Box>
                <Box className="tw-mt-1">{extension['extension_reason'].split(' ')[0]}</Box>
              </Stack>
            )
            return extensionContents
          }

          extensionContents = (
            <Box>
              {previousMilestoneExtensions.sort(dynamicSort('timestamp_created', 'asc')).map((extension: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_ExtensionContent(extension, 'past')}</Box>
              ))}
              {previousMilestoneExtensions.length > 0 && currentMilestoneExtensions.length > 0 && <Divider className="tw-my-2" />}
              {currentMilestoneExtensions.sort(dynamicSort('timestamp_created', 'asc')).map((extension: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_ExtensionContent(extension, 'current')}</Box>
              ))}
              <Stack
                direction="row"
                spacing={1}
              >
                {tableAdditionalData.readOrWrite === 'write' && (
                  <>
                    <Box className="tw-opacity-30 tw-italic">{rLIB('New deadline extension')}</Box>
                    <Box>{newIcon}</Box>
                  </>
                )}
              </Stack>
            </Box>
          )
        } else {
          extensionContents = (
            <Stack
              direction="row"
              spacing={1}
            >
              <Box className="tw-opacity-30 tw-italic">{rLIB('No deadline extension')}</Box>
              <Box>{newIcon}</Box>
            </Stack>
          )
        }
        cellJSX = (
          <>
            <Box>{extensionContents}</Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Deadline Extensions')
      },
      header_sort_by: null,
    },
  },
  notes: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let stickyNoteContent = <></>
        let oldEditIcon = <></>
        let addIcon = <></>
        if (tableAdditionalData.readOrWrite === 'write') {
          addIcon = (
            <Icon
              icon="circle-plus"
              type="solid"
              className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
              tooltip={rLIB('New Sticky Note')}
              tooltipPlacement="top"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: {
                        date: returnFormattedDate(new Date(), 'YYYY-MM-DD'),
                      },
                      formInputs: formInputs_NewStickyNote,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_StickyNote,
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let newNoteId = uuidv4()
                          let updateObject = {
                            sticky_notes: {
                              [newNoteId]: {
                                timestamp: new Date(
                                  returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                                ),
                                note: formSubmittedData.note,
                                key: newNoteId,
                              },
                            },
                          }
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'warning',
                      formDialogHeaderText: rLIB('Add Sticky Note'),
                      formDialogIcon: (
                        <Icon
                          icon="note"
                          type="solid"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          )
          oldEditIcon = (
            <Icon
              icon="note"
              className="tw-mr-2 tw-text-secondary_main tw-cursor-pointer"
              size="xl"
              tooltip={rLIB('Edit Sticky Note')}
              tooltipPlacement="right"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: formInputs_StickyNote,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_StickyNote,
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, rowData.key as string), formSubmittedData)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'warning',
                      formDialogHeaderText: rLIB('Edit Sticky Note'),
                      formDialogIcon: (
                        <Icon
                          icon="note"
                          className="tw-mr-2"
                        />
                      ),
                    },
                  },
                })
              }}
            />
          )
        } else {
          oldEditIcon = (
            <Icon
              icon="note"
              className="tw-mr-2 tw-text-secondary_main"
              size="xl"
            />
          )
        }
        let viewIconJSX = (
          <Icon
            icon="magnifying-glass"
            className=" tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-secondary_main"
            tooltip={rLIB('View Sticky Notes')}
            tooltipPlacement="top"
            onClick={() => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                tableHooks.uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <ProjectStickyNotesDialog
                        clientKey={res_GCK.clientKey}
                        projectKey={rowData.key as string}
                        readOrWrite={tableAdditionalData.readOrWrite}
                      />
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              })
            }}
          />
        )

        let stickyNoteContentOld = <></>
        let hasOldStickyNote = false
        if (rowData != null && rowData.sticky_note != null && rowData.sticky_note !== '') {
          hasOldStickyNote = true
          stickyNoteContentOld = (
            <Box>
              <Box
                className="tw-inline-block tw-align-top"
                sx={{ width: '32px' }}
              >
                {oldEditIcon}
              </Box>
              <Box
                className="tw-inline-block"
                sx={{ width: 'calc(100% - 32px' }}
              >
                {replaceNewlinesWithHtmlBreaks(rowData.sticky_note as string)}
              </Box>
            </Box>
          )
        }

        // const rJSX_EditIcon = (
        //   readOrWrite: 'read' | 'write',
        //   note: TsInterface_UnspecifiedObject,
        //   uc_RootData_ClientKey: any,
        //   uc_setRootData_ClientKey: any,
        //   uc_setUserInterface_FormDialogDisplay: any,
        //   uc_setUserInterface_ErrorDialogDisplay: any,
        //   projectKey: any,
        // ) => {
        //   let editIconJSX = <></>
        //   if (readOrWrite === 'write') {
        //     editIconJSX = (
        //       <Tooltip title={rLIB('Edit Sticky Note')}>
        //         <Box
        //           className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        //           onClick={() => {
        //             uc_setUserInterface_FormDialogDisplay({
        //               display: true,
        //               form: {
        //                 form: {
        //                   formAdditionalData: {},
        //                   formData: {
        //                     note: note.note,
        //                     date: returnFormattedDate(note.timestamp, 'YYYY-MM-DD'),
        //                   },
        //                   formInputs: formInputs_NewStickyNote,
        //                   formOnChange: (
        //                     formAdditionalData: TsInterface_FormAdditionalData,
        //                     formData: TsInterface_FormData,
        //                     formInputs: TsInterface_FormInputs,
        //                     formSettings: TsInterface_FormSettings,
        //                   ) => {},
        //                   formSettings: formSettings_StickyNote,
        //                   formSubmission: (
        //                     formSubmittedData: TsInterface_FormSubmittedData,
        //                     formAdditionalData: TsInterface_FormAdditionalData,
        //                     formHooks: TsInterface_FormHooksObject,
        //                   ) => {
        //                     return new Promise((resolve, reject) => {
        //                       let updateObject = {
        //                         sticky_notes: {
        //                           [note.key]: {
        //                             note: formSubmittedData.note,
        //                             timestamp: new Date(
        //                               returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
        //                             ),
        //                           },
        //                         },
        //                       }
        //                       getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
        //                         DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
        //                           .then((res_DSMD) => {
        //                             resolve(res_DSMD)
        //                           })
        //                           .catch((rej_DSMD) => {
        //                             uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
        //                             reject(rej_DSMD)
        //                           })
        //                       })
        //                     })
        //                   },
        //                 },
        //                 dialog: {
        //                   formDialogHeaderColor: 'warning',
        //                   formDialogHeaderText: rLIB('Edit Sticky Note'),
        //                   formDialogIcon: (
        //                     <Icon
        //                       icon="note"
        //                       type="solid"
        //                     />
        //                   ),
        //                 },
        //               },
        //             })
        //           }}
        //         >
        //           <Icon icon="pen-to-square"></Icon>
        //         </Box>
        //       </Tooltip>
        //     )
        //   }
        //   return editIconJSX
        // }

        // const rJSX_DeleteIcon = (
        //   readOrWrite: 'read' | 'write',
        //   noteKey: any,
        //   note: TsInterface_UnspecifiedObject,
        //   uc_RootData_ClientKey: any,
        //   uc_setRootData_ClientKey: any,
        //   uc_setUserInterface_ErrorDialogDisplay: any,
        //   uc_setUserInterface_ConfirmDialogDisplay: any,
        //   projectKey: any,
        // ) => {
        //   let deleteIconJSX = <></>
        //   if (readOrWrite === 'write') {
        //     deleteIconJSX = (
        //       <Tooltip title={rLIB('Delete Sticky Note')}>
        //         <Box className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2">
        //           <Icon
        //             icon="trash"
        //             onClick={() => {
        //               uc_setUserInterface_ConfirmDialogDisplay({
        //                 display: true,
        //                 confirm: {
        //                   color: 'error',
        //                   header: <>{rLIB('Delete Sticky Note')}</>,
        //                   icon: <Icon icon="trash" />,
        //                   submit_callback: () => {
        //                     return new Promise((resolve, reject) => {
        //                       getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        //                         .then((res_GCK) => {
        //                           let updateObject = {
        //                             sticky_notes: {
        //                               [noteKey]: null,
        //                             },
        //                             sticky_notes_deleted: {
        //                               [noteKey]: note,
        //                             },
        //                           }
        //                           DatabaseSetMergeDocument(DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), updateObject)
        //                             .then((res_DSMD) => {
        //                               resolve(res_DSMD)
        //                             })
        //                             .catch((rej_DSMD) => {
        //                               uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
        //                               reject(rej_DSMD)
        //                             })
        //                         })
        //                         .catch((rej_GCK) => {
        //                           uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        //                           reject(rej_GCK)
        //                         })
        //                     })
        //                   },
        //                   submit_text: rLIB('Delete'),
        //                   text: rLIB('Are you sure that you want to delete this sticky note?'),
        //                 },
        //               })
        //             }}
        //           />
        //         </Box>
        //       </Tooltip>
        //     )
        //   }
        //   return deleteIconJSX
        // }

        let stickyNotes = getProp(rowData, 'sticky_notes', {})
        if (objectToArray(stickyNotes).length > 0) {
          stickyNoteContent = (
            <Box sx={{ maxHeight: '60px', overflow: 'hidden' }}>
              {objectToArray(stickyNotes)
                .sort(dynamicSort('timestamp', 'desc'))
                .map((stickyNote: TsInterface_UnspecifiedObject, index: number) => (
                  <Box
                    key={index}
                    className=""
                  >
                    <Icon
                      icon="note"
                      className="tw-mr-1"
                      sx={{ color: themeVariables.secondary_main }}
                    />
                    <Box
                      component="span"
                      className="tw-inline-block tw-mr-1"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {returnFormattedDate(stickyNote.timestamp, 'M/D/YY')}
                      {' - '}
                    </Box>
                    <Box
                      component="span"
                      className="tw-opacity-40"
                    >
                      {stickyNote.note}
                    </Box>
                    {/* {rJSX_EditIcon(
                      tableAdditionalData.readOrWrite,
                      stickyNote,
                      tableHooks.uc_RootData_ClientKey,
                      tableHooks.uc_setRootData_ClientKey,
                      tableHooks.uc_setUserInterface_FormDialogDisplay,
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                      rowData.key as string,
                    )}
                    {rJSX_DeleteIcon(
                      tableAdditionalData.readOrWrite,
                      stickyNote.key,
                      stickyNote,
                      tableHooks.uc_RootData_ClientKey,
                      tableHooks.uc_setRootData_ClientKey,
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                      tableHooks.uc_setUserInterface_ConfirmDialogDisplay,
                      rowData.key as string,
                    )} */}
                  </Box>
                ))}
            </Box>
          )
        } else if (hasOldStickyNote === true) {
          stickyNoteContent = <></>
        } else {
          stickyNoteContent = <Box className="tw-italic tw-opacity-50 tw-inline-block">{rLIB('No Sticky Notes')}</Box>
        }

        cellJSX = (
          <Box sx={{ maxWidth: '400px' }}>
            {stickyNoteContent}
            {stickyNoteContentOld}
            <Box>
              {viewIconJSX}
              {addIcon}
            </Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Project Notes')
      },
      header_sort_by: null,
    },
  },
}

const tableSettings_ProjectsByMilestone: TsInterface_TableSettings = {
  paginated: false,
  // pagination_rows_per_page_default: 100,
  // pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'desc',
  sort_property_default: 'TEMP_project_phase_pace_target_delta',
  collapsible_columns: true,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const PacingProjectPhasesTab = forwardRef((props: TsInterface_Pacing_ProjectPhasesTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_selectedTaskWorkflowKey: TsInterface_Pacing_ProjectPhasesTab['selectedTaskWorkflowKey'] = getProp(props, 'selectedTaskWorkflowKey', null)
  let pr_taskWorkflows: TsInterface_Pacing_ProjectPhasesTab['taskWorkflows'] = getProp(props, 'taskWorkflows', null)
  let pr_readOrWrite: TsInterface_Pacing_ProjectPhasesTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')
  let pr_projectUrl: TsInterface_Pacing_ProjectPhasesTab['projectViewPageKey'] = getProp(props, 'projectViewPageKey', null)
  let pr_clientKeyOverride: TsInterface_Pacing_ProjectPhasesTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_useSalesPartnerInstallPartnerProjectView: TsInterface_Pacing_ProjectPhasesTab['useSalesPartnerInstallPartnerProjectView'] = getProp(
    props,
    'useSalesPartnerInstallPartnerProjectView',
    null,
  )
  let pr_snoozeFilter: TsInterface_Pacing_ProjectPhasesTab['snoozeFilter'] = getProp(props, 'snoozeFilter', null)
  let pr_filteredProjectPacingTags: TsInterface_Pacing_ProjectPhasesTab['filteredProjectPacingTags'] = getProp(props, 'filteredProjectPacingTags', [])

  // Hooks - useContext, useState, useReducer, other
  const [us_maxTaskCount, us_setMaxTaskCount] = useState<number>(0)
  const [us_visibleProjectPacingTags, us_setVisibleProjectPacingTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseActiveProjectTimestampsData, us_setPhaseActiveProjectTimestampsData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseDelayReasons, us_setPhaseDelayReasons] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseDelayReasonTypes, us_setPhaseDelayReasonTypes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeProjects, us_setActiveProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredProjects, us_setFilteredProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseWorkflowTasks, us_setPhaseWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectsSortedByMilestone, us_setProjectsSortedByMilestone] = useState<TsInterface_UnspecifiedObject>({})
  const [us_sortedMilestonePhaseTasksArray, us_setSortedMilestonePhaseTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_sortedMilestonePhaseTasksObject, us_setSortedMilestonePhaseTasksObject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskWorkflow, us_setTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskWorkflow(newData)
      ur_forceRerender()
    }
    if (pr_selectedTaskWorkflowKey != null && pr_selectedTaskWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKeyOverride != null && pr_clientKeyOverride !== '' ? pr_clientKeyOverride : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_TaskWorkflow_Document(actualClientKey, pr_selectedTaskWorkflowKey as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_selectedTaskWorkflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_clientKeyOverride])

  useEffect(() => {
    // Generate List of Pacing Tags
    let pacingTags: TsInterface_UnspecifiedObject = {}
    for (let loopProjectKey in us_activeProjects) {
      let loopProject = us_activeProjects[loopProjectKey]
      let loopPacingTags = getProp(loopProject, 'pacing_tags', {})
      for (let loopPacingTagKey in loopPacingTags) {
        let loopPacingTag = loopPacingTags[loopPacingTagKey]
        if (loopPacingTag != null && loopPacingTag.text != null && loopPacingTag.text !== '' && loopPacingTag.color != null && loopPacingTag.color !== '') {
          let labelKey = loopPacingTag.text + '-' + loopPacingTag.color
          pacingTags[labelKey] = loopPacingTag
        }
      }
    }
    us_setVisibleProjectPacingTags(pacingTags)
    return () => {}
  }, [us_activeProjects])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveProjects(newData)
      ur_forceRerender()
    }
    if (
      pr_selectedTaskWorkflowKey != null &&
      pr_selectedTaskWorkflowKey !== '' &&
      us_taskWorkflow != null &&
      us_taskWorkflow['associated_sales_partner_key'] != null &&
      us_taskWorkflow['associated_sales_partner_key'] != ''
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKeyOverride != null && pr_clientKeyOverride !== '' ? pr_clientKeyOverride : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ActiveProjectsForSpecificWorkflowAndSalesPartner_Query(
              actualClientKey,
              pr_selectedTaskWorkflowKey as string,
              us_taskWorkflow['associated_sales_partner_key'] as string,
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_selectedTaskWorkflowKey, pr_clientKeyOverride, us_taskWorkflow])

  useEffect(() => {
    let filteredProjects: TsInterface_UnspecifiedObject = {}
    let currentTimestamp = new Date().getTime()
    // Filter By Snooze Filter
    for (let loopProjectKey in us_activeProjects) {
      let loopProject = us_activeProjects[loopProjectKey]
      if (pr_snoozeFilter === 'all') {
        filteredProjects[loopProjectKey] = loopProject
      } else if (pr_snoozeFilter === 'snoozed') {
        if (getProp(loopProject, 'timestamp_snoozed_until', null) != null) {
          let snoozeUntilTimestamp = returnTimestampFromUnknownDateFormat(getProp(loopProject, 'timestamp_snoozed_until', null))
          if (snoozeUntilTimestamp > currentTimestamp) {
            filteredProjects[loopProjectKey] = loopProject
          }
        }
      } else if (pr_snoozeFilter === 'active') {
        if (getProp(loopProject, 'timestamp_snoozed_until', null) != null) {
          let snoozeUntilTimestamp = returnTimestampFromUnknownDateFormat(getProp(loopProject, 'timestamp_snoozed_until', null))
          if (snoozeUntilTimestamp < currentTimestamp) {
            filteredProjects[loopProjectKey] = loopProject
          }
        } else {
          filteredProjects[loopProjectKey] = loopProject
        }
      }
    }
    // Filter By Pacing Tags
    let secondaryFilterProjects: TsInterface_UnspecifiedObject = {}
    if (pr_filteredProjectPacingTags != null && pr_filteredProjectPacingTags.length > 0) {
      for (let loopProjectKey in us_activeProjects) {
        let loopProject = us_activeProjects[loopProjectKey]
        // Check for any matching tags in the pacing_tags object
        if (loopProject['pacing_tags'] != null) {
          for (let tagKey in loopProject['pacing_tags']) {
            let loopTag = loopProject['pacing_tags'][tagKey]
            if (loopTag != null && loopTag.text != null && loopTag.text !== '' && loopTag.color != null && loopTag.color !== '') {
              let combinedLabelKey = loopTag.text + '-' + loopTag.color
              if (pr_filteredProjectPacingTags.includes(combinedLabelKey)) {
                secondaryFilterProjects[loopProjectKey] = loopProject
                break // Found a matching tag, no need to check others
              }
            }
          }
        }
      }
    } else {
      secondaryFilterProjects = filteredProjects
    }
    us_setFilteredProjects(secondaryFilterProjects)
    return () => {}
  }, [us_activeProjects, pr_snoozeFilter, pr_filteredProjectPacingTags])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPhaseActiveProjectTimestampsData(newData)
      ur_forceRerender()
    }
    if (
      pr_selectedTaskWorkflowKey != null &&
      pr_selectedTaskWorkflowKey !== '' &&
      us_taskWorkflow != null &&
      us_taskWorkflow['associated_sales_partner_key'] != null &&
      us_taskWorkflow['associated_sales_partner_key'] != ''
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKeyOverride != null && pr_clientKeyOverride !== '' ? pr_clientKeyOverride : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ActiveProjectTimestampsForSpecificWorkflowAndSalesPartner_Query(
              actualClientKey,
              pr_selectedTaskWorkflowKey as string,
              us_taskWorkflow['associated_sales_partner_key'] as string,
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_selectedTaskWorkflowKey, pr_clientKeyOverride, us_taskWorkflow])

  useEffect(() => {
    if (pr_selectedTaskWorkflowKey != null && pr_selectedTaskWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKeyOverride != null && pr_clientKeyOverride !== '' ? pr_clientKeyOverride : res_GCK.clientKey
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(actualClientKey, pr_selectedTaskWorkflowKey as string))
            .then((res_DGD) => {
              us_setPhaseWorkflowTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_selectedTaskWorkflowKey, pr_clientKeyOverride])

  useEffect(() => {
    if (
      pr_selectedTaskWorkflowKey != null &&
      pr_selectedTaskWorkflowKey !== '' &&
      pr_taskWorkflows != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey] != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey]['filter_milestones'] != null &&
      us_phaseWorkflowTasks != null &&
      us_phaseWorkflowTasks['tasks'] != null
    ) {
      let sortedMilestonePhaseTasksArray = returnSortedWorkflowPhasesArray(us_phaseWorkflowTasks['tasks'], pr_taskWorkflows[pr_selectedTaskWorkflowKey])
      us_setSortedMilestonePhaseTasksArray(sortedMilestonePhaseTasksArray)
      let sortedMilestoneTaskObject: TsInterface_UnspecifiedObject = {}
      for (let loopMilestoneTaskIndex in sortedMilestonePhaseTasksArray) {
        let loopMilestoneTask = sortedMilestonePhaseTasksArray[loopMilestoneTaskIndex]
        sortedMilestoneTaskObject[loopMilestoneTask.key] = getProp(loopMilestoneTask, 'phase_tasks', {})
      }
      us_setSortedMilestonePhaseTasksObject(sortedMilestoneTaskObject)
    }
  }, [pr_selectedTaskWorkflowKey, pr_taskWorkflows, us_phaseWorkflowTasks])

  useEffect(() => {
    if (
      pr_selectedTaskWorkflowKey != null &&
      pr_selectedTaskWorkflowKey !== '' &&
      us_filteredProjects != null &&
      us_sortedMilestonePhaseTasksArray != null &&
      us_sortedMilestonePhaseTasksArray.length > 0
    ) {
      us_setProjectsSortedByMilestone(
        returnProjectsSortedByMilestonePhase(
          us_sortedMilestonePhaseTasksArray,
          us_filteredProjects,
          us_phaseActiveProjectTimestampsData,
          us_taskWorkflow,
          us_phaseDelayReasonTypes,
        ),
      )
    }
  }, [
    pr_selectedTaskWorkflowKey,
    us_filteredProjects,
    us_sortedMilestonePhaseTasksArray,
    us_phaseActiveProjectTimestampsData,
    us_taskWorkflow,
    us_phaseDelayReasonTypes,
  ])

  useEffect(() => {
    // Get Max number of projects in any given milestone to set width of progress bar segments
    let currentMaxTaskCount = 0
    for (let loopMilestoneKey in us_projectsSortedByMilestone) {
      let loopMilestone = us_projectsSortedByMilestone[loopMilestoneKey]
      if (loopMilestone != null) {
        let loopMilestoneTaskCount = objectToArray(loopMilestone).length
        if (loopMilestoneTaskCount > currentMaxTaskCount) {
          currentMaxTaskCount = loopMilestoneTaskCount
        }
      }
    }
    us_setMaxTaskCount(currentMaxTaskCount)
  }, [us_projectsSortedByMilestone])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPhaseDelayReasons(newData)

      let phaseDelayReasonTypes: TsInterface_UnspecifiedObject = {}
      for (let loopReasonKey in newData) {
        if (newData[loopReasonKey] != null && newData[loopReasonKey]['name'] != null && newData[loopReasonKey]['delay_reason_type'] != null) {
          phaseDelayReasonTypes[newData[loopReasonKey]['name']] = newData[loopReasonKey]['delay_reason_type']
        }
      }
      us_setPhaseDelayReasonTypes(phaseDelayReasonTypes)

      ur_forceRerender()
    }
    if (pr_readOrWrite === 'write') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKeyOverride != null && pr_clientKeyOverride !== '' ? pr_clientKeyOverride : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowDelayReasons_Collection(actualClientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_clientKeyOverride, pr_readOrWrite])

  // Functions
  const getMilestoneProjectColorCounts = (milestoneTaskKey: string): TsInterface_UnspecifiedObject => {
    let projectColorCounts: TsInterface_UnspecifiedObject = {
      red: 0,
      yellow: 0,
      green: 0,
      teal: 0,
      blue: 0,
      total: objectToArray(getProp(us_projectsSortedByMilestone, milestoneTaskKey, {})).length,
    }
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestone_days'] != null && us_taskWorkflow['filter_milestone_days'][milestoneTaskKey] != null) {
      for (let loopProjectKey in getProp(us_projectsSortedByMilestone, milestoneTaskKey, {})) {
        let loopProject = getProp(us_projectsSortedByMilestone, milestoneTaskKey, {})[loopProjectKey]
        if (loopProject != null && loopProject['ALL_PACING_DATA'] != null && loopProject['ALL_PACING_DATA']['project_current_color_key'] != null) {
          projectColorCounts[loopProject['ALL_PACING_DATA']['project_current_color_key']] += 1
        }
      }
    } else {
      projectColorCounts['blue'] = objectToArray(getProp(us_projectsSortedByMilestone, milestoneTaskKey, {})).length
    }
    return projectColorCounts
  }

  // JSX Generation
  const rJSX_SummaryStats = (milestoneTaskKey: string): JSX.Element => {
    let statsJSX = <></>
    let projectColorCounts = getMilestoneProjectColorCounts(milestoneTaskKey)
    let projectCountJSX = <></>
    let noCutoffDaysSetupJSX = <></>
    let cutoffDays = null
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestone_days'] != null && us_taskWorkflow['filter_milestone_days'][milestoneTaskKey] != null) {
      cutoffDays = us_taskWorkflow['filter_milestone_days'][milestoneTaskKey]
    }
    if (cutoffDays != null) {
      // Nothing
    } else {
      noCutoffDaysSetupJSX = (
        <Box sx={{ color: themeVariables.info_main }}>
          <Icon
            icon="circle-info"
            className="tw-mr-2"
          />
          {rLIB('Phase days not set up on workflow')}
        </Box>
      )
    }
    if (projectColorCounts.total > 0) {
      projectCountJSX = (
        <Box>
          {projectColorCounts.total} {projectColorCounts.total === 1 ? rLIB('Project') : rLIB('Projects')}
        </Box>
      )
    } else {
      projectCountJSX = (
        <Box className="tw-opacity-30">
          {projectColorCounts.total} {projectColorCounts.total === 1 ? rLIB('Project') : rLIB('Projects')}
        </Box>
      )
    }
    let cutoffDaysJSX = <></>
    if (cutoffDays != null) {
      cutoffDaysJSX = (
        <Box>
          {rLIB('Cycle Time Goal')}: {cutoffDays} {cutoffDays === 1 ? rLIB('day') : rLIB('days')}
        </Box>
      )
    }
    // JSX
    statsJSX = (
      <Stack
        direction="row"
        spacing={1}
        className="tw-mb-2"
      >
        {projectCountJSX}
        <Box>|</Box>
        {cutoffDaysJSX}
        {noCutoffDaysSetupJSX}
      </Stack>
    )
    return statsJSX
  }

  const rJSX_ProjectJobCodesList = (milestoneTaskKey: string): JSX.Element => {
    let cutoffDays: null | number = null
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestone_days'] != null && us_taskWorkflow['filter_milestone_days'][milestoneTaskKey] != null) {
      cutoffDays = us_taskWorkflow['filter_milestone_days'][milestoneTaskKey]
    }

    let projectJobCodes = []
    if (us_projectsSortedByMilestone != null && us_projectsSortedByMilestone[milestoneTaskKey] != null) {
      projectJobCodes = objectToArray(us_projectsSortedByMilestone[milestoneTaskKey])
    }

    const returnColor = (cutoffDays: null | number, project: TsInterface_UnspecifiedObject): string => {
      if (project != null && project['ALL_PACING_DATA'] != null && project['ALL_PACING_DATA']['project_current_color'] != null) {
        return project['ALL_PACING_DATA']['project_current_color']
      } else {
        return themeVariables.info_main
      }
      // // Determine if any of the phase_deadline_extensions are excused
      // let isExcused = false
      // if (project['phase_deadline_extensions'] != null && project['phase_deadline_extensions'][milestoneTaskKey] != null) {
      //   for (let loopExtensionKey in project['phase_deadline_extensions'][milestoneTaskKey]) {
      //     let loopExtension = project['phase_deadline_extensions'][milestoneTaskKey][loopExtensionKey]
      //     if (loopExtension != null && loopExtension['extension_reason'] != null && us_phaseDelayReasonTypes[loopExtension['extension_reason']] != null) {
      //       if (us_phaseDelayReasonTypes[loopExtension['extension_reason']] === 'excused') {
      //         isExcused = true
      //       }
      //     }
      //   }
      // }
      // if (cutoffDays != null) {
      //   if (project['TEMP_days_in_phase'] <= cutoffDays) {
      //     return themeVariables.success_main
      //   } else if (project['TEMP_days_in_phase'] <= cutoffDays + project['TEMP_phase_extension_days']) {
      //     if (isExcused) {
      //       return themeVariables.teal_700
      //     } else {
      //       return themeVariables.warning_main
      //     }
      //   } else {
      //     return themeVariables.error_main
      //   }
      // } else {
      //   return themeVariables.info_main
      // }
    }

    return (
      <Box>
        {projectJobCodes.sort(dynamicSort('id_number', null)).map((project: TsInterface_UnspecifiedObject, projectIndex: number) => (
          <Box
            className="tw-inline-block tw-mb-1"
            sx={{
              background: returnColor(cutoffDays, project),
              padding: '4px 8px',
              borderRadius: '4px',
              marginRight: '4px',
            }}
            key={projectIndex}
          >
            {project['id_number']}
          </Box>
        ))}
      </Box>
    )
  }

  const rJSX_BreakdownGraph = (milestoneTaskKey: string): JSX.Element => {
    let projectColorCounts = getMilestoneProjectColorCounts(milestoneTaskKey)
    return (
      <Box sx={{ width: '100%', height: '12px', overflow: 'hidden' }}>
        <Stack
          direction="row"
          sx={{ width: '100%' }}
        >
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.info_main,
              width: (projectColorCounts.blue / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.success_main,
              width: (projectColorCounts.green / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.teal_700,
              width: (projectColorCounts.teal / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.warning_main,
              width: (projectColorCounts.yellow / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.error_main,
              width: (projectColorCounts.red / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
          <Box
            className="tw-text-center tw-py-2"
            sx={{
              background: themeVariables.gray_700,
              width: ((us_maxTaskCount - projectColorCounts.total) / us_maxTaskCount) * 100 + '%',
              height: '40px',
            }}
          ></Box>
        </Stack>
      </Box>
    )
  }

  const rJSX_ProjectsForWorkflowMilestoneTableList = (
    milestoneTaskKey: string,
    us_projectsSortedByMilestone: TsInterface_UnspecifiedObject | null,
    readOrWrite: 'read' | 'write',
  ) => {
    let tableJSX = <></>
    if (
      milestoneTaskKey != null &&
      us_projectsSortedByMilestone != null &&
      us_projectsSortedByMilestone[milestoneTaskKey] != null &&
      objectToArray(us_projectsSortedByMilestone[milestoneTaskKey]).length > 0
    ) {
      tableJSX = (
        <Box
          sx={{ border: '1px solid ' + themeVariables.gray_700 }}
          className="tw-rounded-sm"
        >
          <TableBasic
            tableAdditionalData={{
              projectViewPageKey: pr_projectUrl,
              readOrWrite: readOrWrite,
              us_taskWorkflow: us_taskWorkflow,
              milestoneTaskKey: milestoneTaskKey,
              us_sortedMilestonePhaseTasksObject: us_sortedMilestonePhaseTasksObject,
              us_phaseDelayReasons: us_phaseDelayReasons,
              pr_useSalesPartnerInstallPartnerProjectView: pr_useSalesPartnerInstallPartnerProjectView,
              pr_clientKeyOverride: pr_clientKeyOverride,
              us_visibleProjectPacingTags: us_visibleProjectPacingTags,
              us_phaseDelayReasonTypes: us_phaseDelayReasonTypes,
            }}
            tableColumns={tableColumns_ProjectsByMilestone}
            tableData={objectToArray(us_projectsSortedByMilestone[milestoneTaskKey])}
            tableSettings={tableSettings_ProjectsByMilestone}
          />
        </Box>
      )
    } else {
      tableJSX = (
        <Box>
          <Typography
            variant="body1"
            className="tw-my-1"
          >
            {rLIB('No projects for this milestone')}
          </Typography>
        </Box>
      )
    }
    return tableJSX
  }

  const rJSX_MilestoneName = (milestoneTaskKey: string, milestoneName: string): JSX.Element => {
    if (us_taskWorkflow != null && us_taskWorkflow['filter_milestone_names'] != null && us_taskWorkflow['filter_milestone_names'][milestoneTaskKey] != null) {
      return <>{us_taskWorkflow['filter_milestone_names'][milestoneTaskKey]}</>
    }
    return <>{milestoneName}</>
  }

  const rJSX_PhasesTabContent = (readOrWrite: 'read' | 'write'): JSX.Element => {
    let contentJSX = <></>
    if (pr_selectedTaskWorkflowKey == null || pr_selectedTaskWorkflowKey === '') {
      contentJSX = (
        <Box className="tw-p-4 tw-text-center tw-opacity-50">
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {rLIB('Select a Workflow')}
          </Typography>
        </Box>
      )
    } else if (
      pr_selectedTaskWorkflowKey != null &&
      pr_taskWorkflows != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey] != null &&
      pr_taskWorkflows[pr_selectedTaskWorkflowKey]['filter_milestones'] != null &&
      us_sortedMilestonePhaseTasksArray != null &&
      us_sortedMilestonePhaseTasksArray.length > 0
    ) {
      contentJSX = (
        <Box>
          {us_sortedMilestonePhaseTasksArray.map((milestone: TsInterface_UnspecifiedObject, milestoneIndex: number) => (
            <Box
              className="tw-mb-4"
              key={milestoneIndex}
            >
              <Box>{rJSX_BreakdownGraph(milestone.key)}</Box>
              <Accordion
                sx={{
                  '&.Mui-expanded': {
                    marginTop: 0,
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    'width': '100%',
                    // 'padding': 0,
                    '& .MuiAccordionSummary-content': {
                      width: '100%',
                      // margin: 0,
                    },
                  }}
                  expandIcon={<Icon icon="angle-down" />}
                >
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      variant="h5"
                      className="tw-my-1 tw-opacity-40 tw-inline-block"
                    >
                      {rJSX_MilestoneName(milestone.key, milestone['name'])}
                    </Typography>
                    {rJSX_SummaryStats(milestone.key)}
                    {rJSX_ProjectJobCodesList(milestone.key)}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>{rJSX_ProjectsForWorkflowMilestoneTableList(milestone.key, us_projectsSortedByMilestone, readOrWrite)}</AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Box>
      )
    } else if (pr_selectedTaskWorkflowKey != null && pr_taskWorkflows != null && pr_taskWorkflows[pr_selectedTaskWorkflowKey] != null) {
      contentJSX = (
        <Box className="tw-p-4 tw-text-center tw-opacity-50">
          <Typography
            variant="h6"
            className="tw-my-1"
          >
            {rLIB('Milestone tasks not set on workflow')}
          </Typography>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_ProjectPhasesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = <Box>{rJSX_PhasesTabContent(pr_readOrWrite)}</Box>
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <Box>{rJSX_ProjectPhasesTab()}</Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
