///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_TaskForms = (clientKey: string, fileName: string, taskFormKey: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/task_forms/' + taskFormKey + '/' + fileName)
}
