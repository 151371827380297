/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Typography } from '@mui/material'
import { tableColumns_ActiveProjects, tableSettings_SearchProjects2 } from 'app/models/projects/project_table'
import { forwardRef } from 'react'
import { TableBasic } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_Pacing_ProjectExportsTab {
  projectData: TsInterface_UnspecifiedObject
  filtersChanged: boolean
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectExportsTab = forwardRef((props: TsInterface_Pacing_ProjectExportsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectData: TsInterface_Pacing_ProjectExportsTab['projectData'] = getProp(props, 'projectData', {})
  let pr_filtersChanged: TsInterface_Pacing_ProjectExportsTab['filtersChanged'] = getProp(props, 'filtersChanged', false)

  // Hooks - useContext, useState, useReducer, other

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const rJSX_TabContent = (): JSX.Element => {
    let contentJSX = <></>
    if (pr_filtersChanged === true) {
      contentJSX = (
        <Card className="">
          <Box className="tw-p-4 tw-text-center">
            <Typography variant="h5">{rLIB('Click load projects to refresh the table')}</Typography>
          </Box>
        </Card>
      )
    } else {
      contentJSX = (
        <Card className="">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_ActiveProjects}
            tableData={objectToArray(pr_projectData)}
            tableSettings={tableSettings_SearchProjects2}
          />
        </Card>
      )
    }
    return contentJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <Box className="tw-m-auto">{rJSX_TabContent()}</Box>
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
