//////////////////////////////////////////
//   SkuImportButtonAndDialog.tsx
//////////////////////////////////////////

import { Timestamp } from 'firebase/firestore'
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'

import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'

import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_MasterSkusForSupplier_Query } from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import { DatabaseRef_Order_Document } from 'rfbp_aux/services/database_endpoints/materials/orders'
import { DatabaseRef_SkuLineItem_Document } from 'rfbp_aux/services/database_endpoints/materials/sku_line_items'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_LoadingBar,
  Context_UserInterface_PromptDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { FileUploadButton } from '../file_upload'
import { Icon } from '../icons/icon'
import { TableBasic, TsInterface_TableColumns, TsInterface_TableDataRow, TsInterface_TableSettings } from '../table'

// Example interface from your snippet
interface TsInterface_UnspecifiedObject {
  [key: string]: any
}

// If you have a local type for a Firestore unsubscribe function:
type TsType_VoidFunction = () => void

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// 1) Local updateOrderItem => includes references to rootOrder
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
function updateOrderItem(
  skuItem: TsInterface_UnspecifiedObject,
  quantity: number,
  showError: boolean,
  rootOrder: TsInterface_UnspecifiedObject | null,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
): Promise<unknown> {
  return new Promise((resolve, reject) => {
    if (rootOrder != null && rootOrder['key'] != null && skuItem != null && skuItem['key'] != null && skuItem['name'] != null) {
      const orderKey = rootOrder['key']
      let lineItemSkuKey = orderKey + '_' + skuItem['key']

      // Prepare line item update object
      let updateObject: any = {
        associated_order_key: orderKey,
        associated_order_po_number: getProp(rootOrder, 'po_number', null),
        associated_project_id_number: getProp(rootOrder, 'associated_project_id_number', null),
        associated_project_key: getProp(rootOrder, 'associated_project_key', null),
        associated_sku_category_name: getProp(skuItem, 'associated_category_name', null),
        associated_sku_classification_name: getProp(skuItem, 'associated_classification_name', null),
        associated_sku_manufacturer_unique_identifier: getProp(skuItem, 'associated_manufacturer_unique_identifier', null),
        associated_sku_estimated_photo_url: getProp(skuItem, 'photo_url', null),
        associated_sku_key: skuItem['key'],
        associated_sku_name: skuItem['name'],
        associated_sku_pick_base_unit_of_measurement: getProp(skuItem, 'pick_base_unit_of_measurement', null),
        associated_sku_pick_estimated_price_per_package: getProp(skuItem, 'pick_estimated_price_per_package', null),
        associated_sku_pick_max_per_cart: getProp(skuItem, 'pick_max_per_cart', null),
        associated_sku_pick_packaging_type: getProp(skuItem, 'pick_packaging_type', null),
        associated_sku_pick_requested_quantity: quantity,
        associated_sku_pick_unit_quantity_per_package: getProp(skuItem, 'pick_unit_quantity_per_package', null),
        associated_sku_unique_identifier: getProp(skuItem, 'unique_identifier', null),
        associated_supplier_key: getProp(rootOrder, 'associated_supplier_key', null),
        associated_supplier_name: getProp(rootOrder, 'associated_supplier_name', null),
        associated_task_key: getProp(rootOrder, 'associated_task_key', null),
        associated_task_name: getProp(rootOrder, 'associated_task_name', null),
        associated_team_key: getProp(rootOrder, 'associated_team_key', null),
        associated_team_member_keys: getProp(rootOrder, 'associated_team_member_keys', null),
        associated_team_member_names: getProp(rootOrder, 'associated_team_member_names', null),
        associated_team_name: getProp(rootOrder, 'associated_team_name', null),
        associated_user_key: getProp(rootOrder, 'associated_user_key', null),
        associated_user_name: getProp(rootOrder, 'associated_user_name', null),
        key: lineItemSkuKey,
        timestamp_scheduled_pickup_date_key: getProp(rootOrder, 'timestamp_scheduled_pickup_date_key', null),
      }

      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // Update SKU line item
          DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, lineItemSkuKey), updateObject)
            .then((res_DSMD) => {
              // If initial email was sent, update order doc
              if (rootOrder.initial_email_sent_to_supplier === true) {
                const orderUpdateObject = {
                  edited_order_submitted: false,
                  last_updated: Timestamp.now(),
                }

                DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, orderKey), orderUpdateObject)
                  .then(() => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_OrderUpdate) => {
                    console.error('Failed to update order document:', rej_OrderUpdate)
                    reject(rej_OrderUpdate)
                  })
              } else {
                // If no initial email, just resolve after SKU update
                resolve(res_DSMD)
              }
            })
            .catch((rej_DSMD) => {
              if (showError === true) {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              }
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          if (showError === true) {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          }
          reject(rej_GCK)
        })
    } else {
      let error = {
        message: 'Failed to update Order Item',
        details: 'Missing Required Data',
        code: 'ER-D-OV-UOI-01',
      }
      if (showError === true) {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
      }
      reject({ success: false, error: error })
    }
  })
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// 2) SkuImportMappingAndConfirmationDialog
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

interface SkuImportMappingAndConfirmationDialogProps {
  masterSkus: TsInterface_UnspecifiedObject[]
  rawData: TsInterface_UnspecifiedObject
  rawHeaders: string[]
  onClose: () => void

  // The order from which we’re updating line items
  rootOrder: TsInterface_UnspecifiedObject | null

  // Context functions needed by updateOrderItem
  uc_RootData_ClientKey: any
  uc_setRootData_ClientKey: any
  uc_setUserInterface_ErrorDialogDisplay: any
}

// -- ADDED: "include" property to track if user wants that row
interface TsInterface_SkuImportRow {
  rawData: TsInterface_UnspecifiedObject
  matchedSku: TsInterface_UnspecifiedObject | null
  quantity: number
  productCategory?: string
  manufacturerPart?: string
  productName?: string
  include?: boolean
}

const SkuImportMappingAndConfirmationDialog: React.FC<SkuImportMappingAndConfirmationDialogProps> = ({
  masterSkus,
  rawData,
  rawHeaders,
  onClose,
  rootOrder,
  uc_RootData_ClientKey,
  uc_setRootData_ClientKey,
  uc_setUserInterface_ErrorDialogDisplay,
}) => {
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)

  // The object with fields to map. We wrap it in useMemo so ESLint won't complain that it changes every render.
  const importMappingOptions: any = useMemo(() => {
    return {
      product_category: {
        key: 'product_category',
        label: 'PRODUCT CATEGORY',
        required: false,
        automatch_properties: ['PRODUCT CATEGORY'],
      },
      count: {
        key: 'count',
        label: 'COUNT',
        required: true,
        automatch_properties: ['COUNT'],
      },
      product_name: {
        key: 'product_name',
        label: 'PRODUCT NAME',
        required: false,
        automatch_properties: ['PRODUCT NAME'],
      },
      manufacturer_part: {
        key: 'manufacturer_part',
        label: 'MANUFACTURER PART#',
        required: false,
        automatch_properties: ['MANUFACTURER PART#'],
      },
    }
  }, [])

  // For controlling which spreadsheet column maps to which field
  const [us_importMapping, us_setImportMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_invertedImportMapping, us_setInvertedImportMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_disableImportButton, us_setDisableImportButton] = useState<boolean>(true)
  const [us_automappingAttemptComplete, us_setAutomappingAttemptComplete] = useState<boolean>(false)

  // Summaries
  const [us_skuRows, us_setSkuRows] = useState<TsInterface_SkuImportRow[]>([])

  // ~~~~~~~~~~
  // Attempt auto-mapping once
  // ~~~~~~~~~~
  useEffect(() => {
    if (!us_automappingAttemptComplete) {
      let mappingData: TsInterface_UnspecifiedObject = {}
      rawHeaders.forEach((loopHeader: string) => {
        // check each field in importMappingOptions
        for (let loopMappingKey in importMappingOptions) {
          let loopMappingData = importMappingOptions[loopMappingKey]
          if (loopMappingData.automatch_properties.includes(loopHeader)) {
            mappingData[loopHeader] = loopMappingKey
          }
        }
      })
      us_setImportMapping(mappingData)
      us_setAutomappingAttemptComplete(true)
    }
  }, [rawHeaders, us_automappingAttemptComplete, importMappingOptions])

  // ~~~~~~~~~~
  // Rebuild inverted mapping whenever user changes us_importMapping
  // ~~~~~~~~~~
  useEffect(() => {
    let inverted: TsInterface_UnspecifiedObject = {}
    for (let rawHeader in us_importMapping) {
      const mappedFieldKey = us_importMapping[rawHeader]
      if (mappedFieldKey != null) {
        inverted[mappedFieldKey] = rawHeader
      }
    }
    us_setInvertedImportMapping(inverted)
  }, [us_importMapping])

  // ~~~~~~~~~~
  // Check required fields => disable import if missing
  // ~~~~~~~~~~
  useEffect(() => {
    let disable = false
    for (let fieldKey in importMappingOptions) {
      if (importMappingOptions[fieldKey].required) {
        if (!us_invertedImportMapping[fieldKey]) {
          disable = true
          break
        }
      }
    }
    us_setDisableImportButton(disable)
  }, [us_invertedImportMapping, importMappingOptions])

  // ~~~~~~~~~~
  // Build “skuRows” each time mapping changes
  // ~~~~~~~~~~
  useEffect(() => {
    let newRows: TsInterface_SkuImportRow[] = []

    for (let rowKey in rawData) {
      let rowObj = rawData[rowKey]

      // Extract fields (ignoring product_category)
      let productName = us_invertedImportMapping.product_name ? rowObj[us_invertedImportMapping.product_name] || '' : ''
      let manufacturerPart = us_invertedImportMapping.manufacturer_part ? rowObj[us_invertedImportMapping.manufacturer_part] || '' : ''

      let quantity = 0
      if (us_invertedImportMapping.count) {
        let possibleQty = rowObj[us_invertedImportMapping.count]
        quantity = parseInt(possibleQty, 10)
        if (isNaN(quantity)) {
          quantity = 0
        }
      }

      // Find matching SKU
      let foundSku: TsInterface_UnspecifiedObject | null = null

      // 1) Attempt match by manufacturerPart => unique_identifier
      if (manufacturerPart) {
        const mfrTrim = manufacturerPart.toLowerCase().trim()
        foundSku = masterSkus.find((sku) => (sku.unique_identifier || '').toString().toLowerCase().trim() === mfrTrim) || null
      }

      // 2) Fallback: match by productName => name
      if (!foundSku && productName) {
        const nameTrim = productName.toLowerCase().trim()
        foundSku = masterSkus.find((sku) => (sku.name || '').toString().toLowerCase().trim() === nameTrim) || null
      }

      // Use SKU category from master data, leave blank if no match
      let category = foundSku ? foundSku.associated_category_name || '' : ''

      // Auto-select matched rows, disable unmatched rows
      newRows.push({
        rawData: rowObj,
        matchedSku: foundSku,
        quantity,
        productCategory: category, // Use master SKU category
        productName,
        manufacturerPart,
        include: !!foundSku, // Auto-select if SKU is matched, otherwise disable
      })
    }

    us_setSkuRows(newRows)
  }, [us_invertedImportMapping, rawData, masterSkus])

  // ~~~~~~~~~~
  // Confirm Import => for each matched row => updateOrderItem
  // ~~~~~~~~~~
  const handleConfirmImport = () => {
    let promises: Promise<unknown>[] = []
    // ADDED: only import if row.include === true
    us_skuRows.forEach((row) => {
      if (row.include && row.matchedSku && row.quantity > 0) {
        promises.push(
          updateOrderItem(
            row.matchedSku,
            row.quantity,
            true, // showError
            rootOrder,
            uc_setUserInterface_ErrorDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
          ),
        )
      }
    })

    Promise.all(promises)
      .then(() => {
        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
      })
      .catch((err) => {
        console.error('Error importing SKUs:', err)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: err })
      })
  }

  // ~~~~~~~~~~
  // Table config for raw data preview
  // ~~~~~~~~~~
  const tableSettings_ImportPreview: TsInterface_TableSettings = {
    paginated: true,
    pagination_rows_per_page_default: 50,
    pagination_rows_per_page_options: [10, 25, 50, 100],
    show_header: true,
    size: 'small',
    sortable: false,
  }

  const [us_tableColumns_ImportPreview, us_setTableColumns_ImportPreview] = useState<TsInterface_TableColumns>({})

  useEffect(() => {
    const columns: TsInterface_TableColumns = {}
    rawHeaders.forEach((header) => {
      columns[header] = {
        header: {
          header_css: () => '',
          header_jsx: () => (
            <Box>
              <Select
                className="bp_thin_select_input"
                color="primary"
                value={us_importMapping[header] || ''}
                onChange={(evt) => {
                  if (evt.target.value === '') {
                    // remove mapping
                    us_setImportMapping((prev) => ({
                      ...prev,
                      [header]: null,
                    }))
                  } else {
                    us_setImportMapping((prev) => ({
                      ...prev,
                      [header]: evt.target.value,
                    }))
                  }
                }}
                variant="outlined"
              >
                <MenuItem value="">
                  <em style={{ color: themeVariables.error_light }}>Remove Mapping</em>
                </MenuItem>
                {objectToArray(importMappingOptions).map((option: TsInterface_UnspecifiedObject, idx: number) => (
                  <MenuItem
                    key={idx}
                    value={option.key}
                    disabled={us_invertedImportMapping[option.key] != null && us_invertedImportMapping[option.key] !== header}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <Divider className="tw-my-2" />
              <Box sx={{ fontWeight: 'bold', opacity: 0.3 }}>{header}</Box>
            </Box>
          ),
          header_sort_by: null,
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow) => <Box>{rowData[header]}</Box>,
          cell_css: () => '',
        },
      }
    })
    us_setTableColumns_ImportPreview(columns)
  }, [rawHeaders, us_importMapping, us_invertedImportMapping, importMappingOptions])

  // ~~~~~~~~~~
  // Summary table: found vs not found, quantity, etc.
  // ~~~~~~~~~~

  // ADDED: We'll have an 'include' column with a checkbox
  const summaryColumns: TsInterface_TableColumns = {
    include: {
      header: {
        header_css: () => '',
        header_jsx: () => <Box>Include?</Box>,
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => {
          const rowIndex: any = rowData.__row_index
          const isMatched = !!rowData.matchedSku
          const isIncluded = rowData.include !== false

          return (
            <input
              type="checkbox"
              checked={isIncluded}
              disabled={!isMatched} // Disable if no SKU match
              onChange={(evt) => {
                if (!isMatched) return // Prevent toggle if disabled
                const newVal = evt.target.checked
                us_setSkuRows((prev) => {
                  let updated = [...prev]
                  if (rowIndex != null && updated[rowIndex]) {
                    updated[rowIndex].include = newVal
                  }
                  return updated
                })
              }}
            />
          )
        },
        cell_css: (rowData: TsInterface_TableDataRow) => (!rowData.matchedSku ? 'tw-opacity-40' : ''),
      },
    },

    productCategory: {
      header: {
        header_css: () => '',
        header_jsx: () => <Box>PRODUCT CATEGORY</Box>,
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => <Box>{rowData.productCategory}</Box>,
        cell_css: (rowData: TsInterface_TableDataRow) => (!rowData.include ? 'tw-opacity-40' : ''),
      },
    },

    productName: {
      header: {
        header_css: () => '',
        header_jsx: () => <Box>PRODUCT NAME</Box>,
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => <Box>{rowData.productName}</Box>,
        cell_css: (rowData: TsInterface_TableDataRow) => (!rowData.include ? 'tw-opacity-40' : ''),
      },
    },
    manufacturerPart: {
      header: {
        header_css: () => '',
        header_jsx: () => <Box>MANUFACTURER PART#</Box>,
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => <Box>{rowData.manufacturerPart}</Box>,
        cell_css: (rowData: TsInterface_TableDataRow) => (!rowData.include ? 'tw-opacity-40' : ''),
      },
    },
    quantity: {
      header: {
        header_css: () => '',
        header_jsx: () => <Box>COUNT</Box>,
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => <Box>{rowData.quantity}</Box>,
        cell_css: (rowData: TsInterface_TableDataRow) => (!rowData.include ? 'tw-opacity-40' : ''),
      },
    },
    found: {
      header: {
        header_css: () => '',
        header_jsx: () => <Box>FOUND SKU?</Box>,
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) =>
          rowData.matchedSku ? (
            <Icon
              icon="check"
              sx={{ color: themeVariables.success_main }}
            />
          ) : (
            <Icon
              icon="times"
              sx={{ color: themeVariables.error_main }}
            />
          ),
        cell_css: (rowData: TsInterface_TableDataRow) => (!rowData.include ? 'tw-opacity-40' : ''),
      },
    },
  }

  const summaryTableData = us_skuRows.map((row, index) => ({
    // We'll store row index so we can identify it in the checkbox
    __row_index: index,
    productCategory: row.productCategory || '',
    productName: row.productName || '',
    manufacturerPart: row.manufacturerPart || '',
    quantity: row.quantity,
    matchedSku: row.matchedSku,
    include: row.include,
  }))

  // ~~~~~~~~~~
  // Render
  // ~~~~~~~~~~
  // Confirm Import Button moved to the footer
  return (
    <Dialog
      className="bp_dialog_xl_width"
      keepMounted
      onClose={onClose}
      open={true}
    >
      <AppBar
        position="static"
        color="info"
      >
        <Toolbar>
          <IconButton
            aria-label="menu"
            color="inherit"
            disabled
            edge="start"
            size="large"
            sx={{ mr: 2, color: '#fff !important' }}
          >
            <Icon icon="upload" />
          </IconButton>
          <Typography
            component="span"
            variant="h6"
            sx={{ flexGrow: 1 }}
          >
            <Box className="tw-inline-block">Import SKUs</Box>
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ padding: '8px' }}>
        {/* Fields to Map */}
        <Card sx={{ margin: '8px' }}>
          <Box
            className="tw-text-center"
            sx={{ padding: '8px' }}
          >
            Fields to Map
          </Box>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {objectToArray(importMappingOptions).map((option: TsInterface_UnspecifiedObject, index: number) => {
                  const mappedHeader = us_invertedImportMapping[option.key]
                  const isMapped = Boolean(mappedHeader)
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ width: '24px' }}>
                        {isMapped ? (
                          <Icon
                            icon="badge-check"
                            sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                          />
                        ) : option.required ? (
                          <Icon
                            icon="triangle-exclamation"
                            sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                          />
                        ) : (
                          <Icon
                            icon="circle-info"
                            sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                          />
                        )}
                      </TableCell>
                      <TableCell>{option.label}</TableCell>
                      <TableCell>{mappedHeader && <>{mappedHeader}</>}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        {/* Raw Data Preview */}
        <Card sx={{ margin: '8px' }}>
          <Box
            className="tw-text-center"
            sx={{ padding: '8px' }}
          >
            Mapping
          </Box>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={us_tableColumns_ImportPreview}
            tableData={objectToArray(rawData)}
            tableSettings={tableSettings_ImportPreview}
          />
        </Card>

        {/* Summary */}
        <Card sx={{ margin: '8px' }}>
          <Box
            className="tw-text-center"
            sx={{ padding: '8px' }}
          >
            Import Summary
          </Box>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={summaryColumns}
            tableData={objectToArray(summaryTableData)}
            tableSettings={tableSettings_ImportPreview}
          />
        </Card>
      </DialogContent>

      {/* Confirm Import Button moved to the footer */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<Icon icon="cloud-arrow-up" />}
          disabled={us_disableImportButton}
          onClick={handleConfirmImport}
        >
          Confirm Import
        </Button>
      </Box>
    </Dialog>
  )
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// 3) Main Button + “onChange” => SkuImportButtonAndDialog
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
interface SkuImportButtonAndDialogProps {
  importButtonText?: JSX.Element
  importButtonColor?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  importButtonShrink: boolean
  supplierKey: string
  rootOrder?: TsInterface_UnspecifiedObject // from your snippet
}

export const SkuImportButtonAndDialog: React.FC<SkuImportButtonAndDialogProps> = (props) => {
  const {
    importButtonText = <span>Import SKUs</span>, // must be JSX
    importButtonColor = 'info',
    importButtonShrink,
    supplierKey,
    rootOrder = null,
  } = props

  // Context
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_LoadingBarDisplay } = useContext(Context_UserInterface_LoadingBar)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Store the loaded master SKUs as an object
  const [us_masterSkus, us_setMasterSkus] = useState<TsInterface_UnspecifiedObject>({})

  // ------------------------------------------------------------
  //  useEffect to load or subscribe to Master SKUs for supplier
  // ------------------------------------------------------------
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction | undefined

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setMasterSkus(newData)
      }
      ur_forceRerender()
    }

    if (supplierKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_MasterSkusForSupplier_Query(res_GCK.clientKey, supplierKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error('[SkuImportButtonAndDialog] Error fetching clientKey:', rej_GCK)
        })
    } else {
      us_setMasterSkus({})
    }

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, supplierKey])

  // ------------------------------------------------------------
  //  parse spreadsheet => open custom dialog
  // ------------------------------------------------------------
  // In your SkuImportButtonAndDialog component:
  const openSpreadsheetCustomDialog = (
    event: React.ChangeEvent<HTMLInputElement>,
    additionalFileUploadParams: TsInterface_UnspecifiedObject,
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (event?.target?.files?.[0]) {
          const file = event.target.files[0]

          const reader = new FileReader()
          reader.onload = (readEvent) => {
            if (readEvent?.target?.result && typeof readEvent.target.result === 'string') {
              const binaryString = readEvent.target.result
              const workbook = XLSX.read(binaryString, { type: 'binary' })

              // We'll assume the first sheet
              const sheetName = workbook.SheetNames[0]
              const sheet = workbook.Sheets[sheetName]

              // originalParsedData => array of arrays, where each index is a row in the sheet
              const originalParsedData: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1 })

              // If there's less than 2 rows, we can't skip the first row reliably
              if (!originalParsedData || originalParsedData.length < 2) {
                let error: any = {
                  message: 'Failed to import data',
                  details: 'Not enough rows (need at least 2)',
                }
                console.error('[SkuImportButtonAndDialog] Not enough rows to skip the first row.')
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
                reject(error)
                return
              }

              // Row 0 is ["BILL OF MATERIALS"] => we ignore it
              // Row 1 is your real header: ["PRODUCT CATEGORY","COUNT","PRODUCT NAME","MANUFACTURER PART#"]
              const rawHeaders = originalParsedData[1]

              // The data rows are from row 2 onwards
              // (i.e. originalParsedData[2], originalParsedData[3], etc.)
              const dataRows = originalParsedData.slice(2)

              // Convert the data rows into an object keyed by row index
              let spreadsheetObjectData: TsInterface_UnspecifiedObject = {}
              for (let rowIndex = 0; rowIndex < dataRows.length; rowIndex++) {
                const rowArray = dataRows[rowIndex]
                // We'll call the key the string of rowIndex, or rowIndex+2, etc.
                const rowKey = (rowIndex + 2).toString()

                spreadsheetObjectData[rowKey] = {}
                for (let colIdx = 0; colIdx < rowArray.length; colIdx++) {
                  const headerName = rawHeaders[colIdx]
                  spreadsheetObjectData[rowKey][headerName] = rowArray[colIdx]
                }
              }

              // Show the custom dialog

              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <SkuImportMappingAndConfirmationDialog
                      masterSkus={objectToArray(us_masterSkus)}
                      rawData={spreadsheetObjectData}
                      rawHeaders={rawHeaders}
                      onClose={() => {
                        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                      }}
                      rootOrder={rootOrder}
                      uc_RootData_ClientKey={uc_RootData_ClientKey}
                      uc_setRootData_ClientKey={uc_setRootData_ClientKey}
                      uc_setUserInterface_ErrorDialogDisplay={uc_setUserInterface_ErrorDialogDisplay}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })

              resolve()
            } else {
              let error: any = {
                message: 'Failed to import data',
                details: 'Unknown error reading the file',
              }
              console.error('[SkuImportButtonAndDialog] File readEvent was invalid or not a string.')
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
              reject(error)
            }
          }
          reader.readAsBinaryString(file)
        } else {
          let error = {
            message: 'Failed to import data',
            details: 'No file selected',
          }
          console.error('[SkuImportButtonAndDialog] No file was selected by user.')
          reject(error)
        }
      } catch (err) {
        console.error('[SkuImportButtonAndDialog] Caught error in openSpreadsheetCustomDialog:', err)
        reject(err)
      }
    })
  }

  // ------------------------------------------------------------
  // Render the FileUploadButton
  // ------------------------------------------------------------
  const rJSX_ImportButton = (): JSX.Element => {
    if (!importButtonShrink) {
      // Full-size button with text

      return (
        <FileUploadButton
          multiple={false}
          button={{
            text: importButtonText, // must be JSX
            icon: (
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
                sx={{ fontSize: '20px' }}
              />
            ),
            className: 'tw-mr-2',
            color: importButtonColor,
            variant: 'contained',
            disabled: false,
          }}
          accept=".xlsx, .csv"
          onChange={openSpreadsheetCustomDialog}
          additionalFileUploadParams={{}}
        />
      )
    } else {
      // Icon-only button

      return (
        <FileUploadButton
          multiple={false}
          button={{
            text: <></>,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                sx={{ fontSize: '20px' }}
              />
            ),
            className: 'bp_icon_only_button tw-mr-2',
            color: importButtonColor,
            variant: 'contained',
            disabled: false,
          }}
          accept=".xlsx, .csv"
          onChange={openSpreadsheetCustomDialog}
          additionalFileUploadParams={{}}
        />
      )
    }
  }

  return <>{rJSX_ImportButton()}</>
}
