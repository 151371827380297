import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

export const DatabaseRef_InstallPartners_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'install_partners', 'main')
}

export const DatabaseRef_InstallPartner_Document = (clientKey: string, installPartnerKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'install_partners', 'main', installPartnerKey)
}

export const DatabaseRef_ActiveInstallPartners_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'install_partners', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_InstallPartner_ImportAliases_Document = (clientKey: string, installPartnerKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'install_partners', 'import_aliases', installPartnerKey)
}

export const DatabaseRef_InstallPartner_ImportAliases_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'install_partners', 'import_aliases')
}

export const DatabaseRef_InstallPartner_ImportAliases_Query = (clientKey: string, installPartnerKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: installPartnerKey }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'install_partners', 'import_aliases'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}
