/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box } from '@mui/material'
import { forwardRef } from 'react'
import {
  DatabaseRef_SaleOpportunityFiles_Collection,
  DatabaseRef_SaleOpportunityFile_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import { StorageRef_SalesOpportunityFile } from 'rfbp_aux/services/storage_endpoints/sales_opportunities'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_OpportunityNotesTab {
  opportunityKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const OpportunityDocumentsTab = forwardRef((props: TsInterface_OpportunityNotesTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_opportunityKey: TsInterface_OpportunityNotesTab['opportunityKey'] = getProp(props, 'opportunityKey', null)
  // let pr_clientKey: TsInterface_OpportunityNotesTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  // let pr_readOrWrite: TsInterface_OpportunityNotesTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <FileSystemBasic
          fileSystemCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_SaleOpportunityFiles_Collection(clientKey, pr_opportunityKey)
          }}
          fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
            return DatabaseRef_SaleOpportunityFile_Document(clientKey, pr_opportunityKey, fileKey)
          }}
          fileSystemStorageEndpoint={(clientKey, fileName) => {
            return StorageRef_SalesOpportunityFile(clientKey, pr_opportunityKey, fileName)
          }}
          fileSystemHardCodedFolders={{}}
          fileSystemSettings={{
            allow_file_archiving: true,
            allow_file_movement: true,
            allow_file_unarchiving: true,
            allow_file_uploads: true,
            allow_folder_creation: true,
            allow_folder_deletion: true,
            allow_folder_movement: true,
            allow_folder_rename: true,
            archive_filter_visible: true,
          }}
        />
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
