///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////
import CancelIcon from '@mui/icons-material/Cancel'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import PaidIcon from '@mui/icons-material/Paid'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Timestamp } from 'firebase/firestore'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_MasterSkusBySKUNumber_Query } from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import {
  DatabaseRef_Material_Imports_By_Invoice_Number,
  DatabaseRef_Material_Imports_By_Invoice_Number_AsNumber,
  DatabaseRef_Material_Imports_Collection,
  DatabaseRef_Processed_Invoices_By_Date_Range,
  DatabaseRef_Processed_Invoices_By_Invoice_Number,
  DatabaseRef_Processed_Invoices_Collection,
  DatabaseRef_Processed_Invoice_Document,
} from 'rfbp_aux/services/database_endpoints/materials/material_imports'
import { DatabaseRef_SkuLineItemsByProject_Query } from 'rfbp_aux/services/database_endpoints/materials/sku_line_items'
import { DatabaseRef_ActiveSuppliers_Query } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

const today = new Date()
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0)
const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 0, 0, 0, 0)

///////////////////////////////
// Functions
///////////////////////////////

const formatDateString = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}` // Keep as ISO format for internal use
}

const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}/${month}/${day}` // Format for invoice_date in database
}

const extractField = (doc: any, field: string): any => {
  if (!doc) return undefined
  if (doc.data && typeof doc.data === 'object') {
    return doc.data[field] || doc[field]
  }
  return doc[field]
}

const unwrapResponse = (res: any): any[] => {
  if (res && res.success && res.data && typeof res.data === 'object' && Object.keys(res.data).length > 0) {
    return Object.values(res.data)
  }
  return Array.isArray(res) ? res : [res]
}

const getItemPrice = (item: any): number => {
  if (extractField(item, 'item_amount') && parseFloat(extractField(item, 'item_amount')) !== 0) {
    return parseFloat(extractField(item, 'item_amount'))
  } else {
    const unitPrice = parseFloat(extractField(item, 'unit_price')) || 0
    const quantity = parseFloat(extractField(item, 'quantity')) || 0
    return unitPrice * quantity
  }
}

const parseDate = (dateString: string): Date => {
  if (dateString.includes('-')) {
    const [year, month, day] = dateString.split('-').map(Number)
    return new Date(year, month - 1, day)
  } else if (dateString.includes('/')) {
    const [year, month, day] = dateString.split('/').map(Number)
    return new Date(year, month - 1, day)
  }
  return new Date(dateString)
}

const normalizeSkuForMatching = (sku: string): string => {
  return sku
    .replace(/[/-\s]/g, '') // Removed the unnecessary escape
    .toLowerCase()
    .trim()
}

const getStatusDisplay = (status: string, actualQty: any, requestedQty: number): JSX.Element => {
  switch (status) {
    case 'received':
      return (
        <Box sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
          <CheckCircleIcon sx={{ mr: 1, fontSize: 16 }} />
          Received
        </Box>
      )
    case 'partial':
      return (
        <Box sx={{ color: 'orange', display: 'flex', alignItems: 'center' }}>
          <ChangeCircleIcon sx={{ mr: 1, fontSize: 16 }} />
          Partial ({actualQty || 0}/{requestedQty})
        </Box>
      )
    case 'not_received':
      return (
        <Box sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
          <CancelIcon sx={{ mr: 1, fontSize: 16 }} />
          Not Received
        </Box>
      )
    default:
      return (
        <Box sx={{ color: 'gray', display: 'flex', alignItems: 'center' }}>
          <HelpOutlineIcon sx={{ mr: 1, fontSize: 16 }} />
          Unknown
        </Box>
      )
  }
}

///////////////////////////////
// Container
///////////////////////////////
export const Container: React.FC = (): JSX.Element => {
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_invoiceMaterialImports, us_setInvoiceMaterialImports] = useState<any>({})
  const [us_invoiceLineItems, us_setInvoiceLineItems] = useState<any>({})
  const [us_invoiceMasterSkus, us_setInvoiceMasterSkus] = useState<any>({})
  const [us_processedInvoices, us_setProcessedInvoices] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_paymentDialogOpen, us_setPaymentDialogOpen] = useState<boolean>(false)
  const [us_notesDialogOpen, us_setNotesDialogOpen] = useState<boolean>(false)
  const [us_currentInvoiceToProcess, us_setCurrentInvoiceToProcess] = useState<any>(null)
  const [us_paymentNotes, us_setPaymentNotes] = useState<string>('')
  const [us_paymentStatus, us_setPaymentStatus] = useState<'paid' | 'rejected'>('paid')
  const [us_snackbarOpen, us_setSnackbarOpen] = useState<boolean>(false)
  const [us_snackbarMessage, us_setSnackbarMessage] = useState<string>('')
  const [us_invoiceStatusMap, us_setInvoiceStatusMap] = useState<Record<string, TsInterface_UnspecifiedObject>>({})
  const [us_lookupResult, us_setLookupResult] = useState<any[]>([])
  const [us_suppliers, us_setSuppliers] = useState<any[]>([])
  const [us_supplierFilter, us_setSupplierFilter] = useState<string>('all')
  const [us_weekStartDateKey, us_setWeekStartDateKey] = useState<string>(formatDateString(firstDayOfMonth))
  const [us_weekEndDateKey, us_setWeekEndDateKey] = useState<string>(formatDateString(lastDayOfMonth))
  const [us_invoiceLookup, us_setInvoiceLookup] = useState<string>('')
  const [us_lineItemsResult, us_setLineItemsResult] = useState<any[]>([])
  const [us_isLoading, us_setIsLoading] = useState<boolean>(false)
  const [us_errorMessage, us_setErrorMessage] = useState<string>('')
  const [us_lookupInvoiceStatusInfo, us_setLookupInvoiceStatusInfo] = useState<TsInterface_UnspecifiedObject | null>(null)

  // On mount, set doc title
  useEffect(() => {
    document.title = rLIB('Invoice Reconciliation', false) as string
  }, [])

  // Get all suppliers once on component mount
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK: any) => {
        const clientKey = res_GCK.clientKey
        return DatabaseGetCollection(DatabaseRef_ActiveSuppliers_Query(clientKey))
      })
      .then((result: any) => {
        const suppliersArray = unwrapResponse(result)
        suppliersArray.sort((a: any, b: any) => {
          const nameA = String(a.name || '').toLowerCase()
          const nameB = String(b.name || '').toLowerCase()
          return nameA.localeCompare(nameB)
        })
        us_setSuppliers(suppliersArray)
      })
      .catch((err: any) => {
        console.error('Error fetching suppliers:', err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK: any) => {
        const clientKey = res_GCK.clientKey
        const startDate = new Date(us_weekStartDateKey)
        const endDate = new Date(us_weekEndDateKey)
        return DatabaseGetCollection(DatabaseRef_Material_Imports_Collection(clientKey)).then((materialData: any) => {
          const allImports = unwrapResponse(materialData)
          const filteredImports = allImports.filter((imp: any) => {
            const importDate = extractField(imp, 'invoice_date')
            if (!importDate) return false

            try {
              const dateObj = new Date(importDate)
              return dateObj >= startDate && dateObj <= endDate
            } catch (e) {
              return false
            }
          })

          const importsByProject: any = {}
          filteredImports.forEach((imp: any) => {
            const projectKey = extractField(imp, 'associated_project_key') || 'unknown'
            if (!importsByProject[projectKey]) {
              importsByProject[projectKey] = []
            }
            importsByProject[projectKey].push(imp)
          })

          us_setInvoiceMaterialImports(importsByProject)
          ur_forceRerender()
        })
      })
      .catch((err: any) => {
        console.error('Error fetching material imports:', err)
        us_setInvoiceMaterialImports({})
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [us_weekStartDateKey, us_weekEndDateKey, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    const projectKeys = Object.keys(us_invoiceMaterialImports)
    if (projectKeys.length === 0) {
      us_setInvoiceLineItems({})
      return
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK: any) => {
        const clientKey = res_GCK.clientKey
        const lineItemPromises = projectKeys.map((projectKey) => {
          return DatabaseGetCollection(DatabaseRef_SkuLineItemsByProject_Query(clientKey, projectKey)).then((lineItemsData: any) => ({
            projectKey,
            lineItems: unwrapResponse(lineItemsData),
          }))
        })

        Promise.all(lineItemPromises)
          .then((allLineItems: any[]) => {
            const invoiceLineItemsByProject: any = allLineItems.reduce((acc: any, obj: any) => {
              acc[obj.projectKey] = obj.lineItems
              return acc
            }, {})
            us_setInvoiceLineItems(invoiceLineItemsByProject)
            ur_forceRerender()
          })
          .catch((err: any) => {
            console.error('Error fetching SKU line items (Invoices):', err)
          })
      })
      .catch((err: any) => {
        console.error('Error fetching client key (Invoices - line items):', err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [us_invoiceMaterialImports])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK: any) => {
        const clientKey = res_GCK.clientKey
        const startDate = new Date(us_weekStartDateKey)
        const endDate = new Date(us_weekEndDateKey)
        return DatabaseGetCollection(DatabaseRef_Processed_Invoices_Collection(clientKey)).then((allInvoices) => {
          return DatabaseGetCollection(DatabaseRef_Processed_Invoices_By_Date_Range(clientKey, startDate, endDate))
        })
      })
      .then((result) => {
        const allProcessedInvoices = unwrapResponse(result) as TsInterface_UnspecifiedObject[]
        us_setProcessedInvoices(allProcessedInvoices)

        const statusMap: Record<string, TsInterface_UnspecifiedObject> = {}
        allProcessedInvoices.forEach((invoice) => {
          statusMap[String(invoice.invoice_number)] = invoice
        })
        us_setInvoiceStatusMap(statusMap)
      })
      .catch((err) => {
        console.error('Error fetching processed invoices:', err)
        us_setProcessedInvoices([])
        us_setInvoiceStatusMap({})
      })
  }, [us_weekStartDateKey, us_weekEndDateKey, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK: any) => {
      const clientKey = res_GCK.clientKey
      const allImports: any[] = []
      Object.entries<any[]>(us_invoiceMaterialImports).forEach(([projectKey, entries]) => {
        entries.forEach((entry: any) => {
          entry.__projectKey = projectKey
          allImports.push(entry)
        })
      })
      const allLineItems: any[] = []
      Object.entries<any[]>(us_invoiceLineItems).forEach(([projectKey, items]) => {
        items.forEach((it: any) => {
          it.__projectKey = projectKey
          allLineItems.push(it)
        })
      })
      const allSkusSet = new Set<string>()
      allImports.forEach((imp: any) => {
        const skuNum = String(extractField(imp, 'sku_number') || '')
          .toLowerCase()
          .trim()
        if (skuNum) allSkusSet.add(skuNum)
      })
      allLineItems.forEach((li: any) => {
        const liSku = String(extractField(li, 'associated_sku_unique_identifier') || '')
          .toLowerCase()
          .trim()
        if (liSku) allSkusSet.add(liSku)
      })

      const uniqueSkus = Array.from(allSkusSet).filter((sku) => sku && sku !== 'unknown')
      if (uniqueSkus.length === 0) {
        us_setInvoiceMasterSkus({})
        return
      }
      const queryPromises: any[] = []
      uniqueSkus.forEach((sku) => {
        const p = DatabaseGetCollection(DatabaseRef_MasterSkusBySKUNumber_Query(clientKey, sku))
          .then((res: any) => {
            const docs = unwrapResponse(res) || []
            if (docs.length > 0) {
              return { sku, doc: docs[0] }
            } else {
              return { sku, doc: null }
            }
          })
          .catch((err: any) => {
            console.error('Error fetching master SKU for', sku, err)
            return { sku, doc: null }
          })
        queryPromises.push(p)
      })

      Promise.all(queryPromises)
        .then((results: any[]) => {
          const newMasterSkuDict: any = {}
          results.forEach((r: any) => {
            if (r && r.sku) {
              newMasterSkuDict[r.sku] = r.doc
            }
          })
          us_setInvoiceMasterSkus(newMasterSkuDict)
        })
        .catch((err: any) => {
          console.error('Error in Promise.all for master SKUs:', err)
          us_setInvoiceMasterSkus({})
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [us_invoiceMaterialImports, us_invoiceLineItems])

  const handleChangeStartDate = (newDateParam: any): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      const newDate = new Date(newDateParam)
      if (isNaN(newDate.getTime())) {
        resolve({ success: false })
        return
      }
      const formattedDate = newDate.toISOString().slice(0, 10)
      us_setWeekStartDateKey(formattedDate)
      resolve({ success: true })
    })
  }

  const handleChangeEndDate = (newDateParam: any): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      const newDate = new Date(newDateParam)
      if (isNaN(newDate.getTime())) {
        resolve({ success: false })
        return
      }
      const formattedDate = newDate.toISOString().slice(0, 10)
      us_setWeekEndDateKey(formattedDate)
      resolve({ success: true })
    })
  }

  const handleOpenPaymentDialog = (invoiceNumber: string | number, invoiceData: any[]) => {
    if (us_invoiceStatusMap[String(invoiceNumber)]) {
      us_setSnackbarMessage(`Invoice ${invoiceNumber} already ${us_invoiceStatusMap[String(invoiceNumber)].status}`)
      us_setSnackbarOpen(true)
      return
    }
    us_setCurrentInvoiceToProcess({
      invoiceNumber,
      invoiceData,
    })
    us_setPaymentNotes('')
    us_setPaymentDialogOpen(true)
  }

  const handleClosePaymentDialog = () => {
    us_setPaymentDialogOpen(false)
    us_setCurrentInvoiceToProcess(null)
  }

  const handleOpenNotesDialog = (invoice: TsInterface_UnspecifiedObject) => {
    us_setCurrentInvoiceToProcess({
      invoiceNumber: invoice.invoice_number,
      key: invoice.key,
      supplier_name: invoice.supplier_name,
      invoice_date: invoice.invoice_date,
      supplier_key: invoice.supplier_key,
      total_amount: invoice.total_amount,
      status: invoice.status,
      timestamp_created: invoice.timestamp_created,
    })
    us_setPaymentNotes(invoice.notes || '')
    us_setPaymentStatus(invoice.status)
    us_setNotesDialogOpen(true)
  }

  const handleCloseNotesDialog = () => {
    us_setNotesDialogOpen(false)
    us_setCurrentInvoiceToProcess(null)
  }

  const handleUpdateNotes = () => {
    if (!us_currentInvoiceToProcess) return

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK: any) => {
        const clientKey = res_GCK.clientKey
        const partialUpdate: Partial<TsInterface_UnspecifiedObject> = {
          notes: us_paymentNotes ?? '',
        }
        return DatabaseSetMergeDocument(DatabaseRef_Processed_Invoice_Document(clientKey, us_currentInvoiceToProcess.key), partialUpdate).then(() => {
          const updatedInvoices = us_processedInvoices.map((invoice) => {
            if (invoice.key === us_currentInvoiceToProcess.key) {
              return { ...invoice, notes: us_paymentNotes ?? '' }
            }
            return invoice
          })
          us_setProcessedInvoices(updatedInvoices)
          const newStatusMap = { ...us_invoiceStatusMap }
          if (newStatusMap[String(us_currentInvoiceToProcess.invoiceNumber)]) {
            newStatusMap[String(us_currentInvoiceToProcess.invoiceNumber)].notes = us_paymentNotes ?? ''
          }
          us_setInvoiceStatusMap(newStatusMap)

          // If this is the currently looked-up invoice, update its status info too
          if (String(us_currentInvoiceToProcess.invoiceNumber) === String(us_invoiceLookup) && us_lookupInvoiceStatusInfo) {
            const updatedStatusInfo = { ...us_lookupInvoiceStatusInfo, notes: us_paymentNotes ?? '' }
            us_setLookupInvoiceStatusInfo(updatedStatusInfo)
          }

          us_setSnackbarMessage(`Notes updated for invoice ${us_currentInvoiceToProcess.invoiceNumber}`)
          us_setSnackbarOpen(true)
          handleCloseNotesDialog()
        })
      })
      .catch((error) => {
        console.error('Error updating notes:', error)
        us_setSnackbarMessage(`Error: ${error.message || 'Failed to update notes'}`)
        us_setSnackbarOpen(true)
      })
  }

  const handleProcessInvoice = () => {
    if (!us_currentInvoiceToProcess) return

    const safeDocId = (str: string) => str.replace(/\//g, '_')

    const { key: existingDocKey, invoiceNumber, invoiceData, supplier_name, invoice_date, supplier_key, total_amount } = us_currentInvoiceToProcess

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK: any) => {
        const clientKey = res_GCK.clientKey
        if (existingDocKey) {
          const partialUpdate: Partial<TsInterface_UnspecifiedObject> = {
            status: us_paymentStatus,
            notes: us_paymentNotes ?? '',
          }
          return DatabaseSetMergeDocument(DatabaseRef_Processed_Invoice_Document(clientKey, existingDocKey), partialUpdate).then(() => {
            return {
              key: existingDocKey,
              invoice_number: invoiceNumber,
              supplier_key: supplier_key ?? 'unknown',
              supplier_name: supplier_name ?? 'Unknown Supplier',
              invoice_date: invoice_date ?? formatDateToYYYYMMDD(new Date()),
              total_amount: total_amount ?? 0,
              status: us_paymentStatus,
              notes: us_paymentNotes ?? '',
              timestamp_created: us_currentInvoiceToProcess.timestamp_created ?? Timestamp.now(),
            } as TsInterface_UnspecifiedObject
          })
        }
        const firstItem = invoiceData?.[0] || {}
        const timestamp = Date.now()
        const safeInvoiceNumber = safeDocId(String(invoiceNumber))
        const invoiceKey = `INV_${safeInvoiceNumber}_${timestamp}`
        const computedTotal = Array.isArray(invoiceData) ? invoiceData.reduce((sum: number, item: any) => sum + getItemPrice(item), 0) : 0
        const newInvoice: TsInterface_UnspecifiedObject = {
          key: invoiceKey,
          invoice_number: invoiceNumber,
          supplier_key: extractField(firstItem, 'associated_supplier_key') || 'unknown',
          supplier_name: extractField(firstItem, 'associated_supplier_name') || 'Unknown Supplier',
          invoice_date: extractField(firstItem, 'invoice_date') || formatDateToYYYYMMDD(new Date()),
          total_amount: computedTotal,
          status: us_paymentStatus,
          notes: us_paymentNotes ?? '',
          timestamp_created: Timestamp.now(),
        }
        return DatabaseSetMergeDocument(DatabaseRef_Processed_Invoice_Document(clientKey, invoiceKey), newInvoice).then(() => newInvoice)
      })
      .then((processedInvoice) => {
        const existingIndex = us_processedInvoices.findIndex((inv) => inv.key === processedInvoice.key)
        let newProcessedInvoices = [...us_processedInvoices]

        if (existingIndex >= 0) {
          newProcessedInvoices[existingIndex] = processedInvoice
        } else {
          newProcessedInvoices.push(processedInvoice)
        }
        us_setProcessedInvoices(newProcessedInvoices)
        const newStatusMap = { ...us_invoiceStatusMap }
        newStatusMap[String(processedInvoice.invoice_number)] = processedInvoice
        us_setInvoiceStatusMap(newStatusMap)

        // If this is from the invoice lookup tab, also update the lookup-specific status info
        if (String(processedInvoice.invoice_number) === String(us_invoiceLookup)) {
          us_setLookupInvoiceStatusInfo(processedInvoice)
        }

        us_setSnackbarMessage(`Invoice ${processedInvoice.invoice_number} marked as ${processedInvoice.status}`)
        us_setSnackbarOpen(true)
        handleClosePaymentDialog()
        ur_forceRerender()
      })
      .catch((error) => {
        console.error('Error processing invoice:', error)
        us_setSnackbarMessage(`Error: ${error.message || 'Failed to process invoice'}`)
        us_setSnackbarOpen(true)
      })
  }

  const getInvoiceStatusChip = (invoiceNumber: string | number): JSX.Element | null => {
    const statusInfo = us_invoiceStatusMap[String(invoiceNumber)]
    if (!statusInfo) return null

    if (statusInfo.status === 'paid') {
      return (
        <Chip
          icon={<PaidIcon />}
          label="Paid"
          color="success"
          size="small"
          sx={{ mr: 1 }}
        />
      )
    } else {
      return (
        <Chip
          icon={<MoneyOffIcon />}
          label="Rejected"
          color="error"
          size="small"
          sx={{ mr: 1 }}
        />
      )
    }
  }

  const rJSX_DatePicker = (): JSX.Element => {
    const generateMonthOptions = () => {
      const options = []
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const previousYear = currentYear - 1
      for (let year = currentYear; year >= previousYear; year--) {
        for (let month = 11; month >= 0; month--) {
          if (year === currentYear && month > currentDate.getMonth()) continue
          const monthName = new Date(year, month, 1).toLocaleString('default', { month: 'long' })
          options.push({
            value: `${year}-${String(month + 1).padStart(2, '0')}`,
            label: `${monthName} ${year}`,
          })
        }
      }
      return options
    }

    const monthOptions = generateMonthOptions()
    const startDate = parseDate(us_weekStartDateKey)
    const currentMonth = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}`
    const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const [year, month] = event.target.value.split('-').map(Number)
      const firstDayOfMonth = new Date(year, month - 1, 1)
      handleChangeStartDate(firstDayOfMonth)
      const lastDayOfMonth = new Date(year, month, 0)
      handleChangeEndDate(lastDayOfMonth)
    }

    return (
      <Box className="tw-inline-flex tw-align-top tw-mr-2 tw-gap-2">
        <TextField
          select
          label={'Select Month'}
          value={currentMonth}
          onChange={handleMonthChange}
          variant="outlined"
          size="small"
          sx={{ minWidth: '200px', background: themeVariables.background_paper }}
        >
          {monthOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    )
  }

  const rJSX_SupplierFilter = (): JSX.Element => {
    return (
      <Box className="tw-inline-flex tw-align-top tw-mr-2">
        <TextField
          select
          label="Supplier Filter"
          value={us_supplierFilter}
          onChange={(e) => us_setSupplierFilter(e.target.value)}
          size="small"
          sx={{
            'minWidth': 200,
            '& .MuiInputBase-input': { color: 'white' },
            '& .MuiInputLabel-root': { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: 'white' },
            },
            '& .MuiSvgIcon-root': { color: 'white' },
          }}
        >
          <MenuItem value="all">All Suppliers</MenuItem>
          {us_suppliers.map((supplier) => (
            <MenuItem
              key={supplier.key}
              value={supplier.name}
            >
              {supplier.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    )
  }

  const rJSX_invoicesTab = (): JSX.Element => {
    const allImports: any[] = []
    Object.entries<any[]>(us_invoiceMaterialImports).forEach(([projectKey, entries]) => {
      entries.forEach((entry: any) => {
        entry.__projectKey = projectKey
        allImports.push(entry)
      })
    })
    const importsWithInvoiceRef = allImports.filter((imp: any) => {
      const invoiceRef = extractField(imp, 'invoice_reference_number')
      return invoiceRef && invoiceRef !== 'unknown'
    })

    const filteredImports = importsWithInvoiceRef.filter((imp: any) => {
      if (us_supplierFilter === 'all') return true
      const supplierName = extractField(imp, 'associated_supplier_name')
      return supplierName === us_supplierFilter
    })

    const invoiceMap = new Map<string, any[]>()
    filteredImports.forEach((imp: any) => {
      const invoiceRef = String(extractField(imp, 'invoice_reference_number'))
      let arr = invoiceMap.get(invoiceRef)
      if (!arr) {
        arr = []
        invoiceMap.set(invoiceRef, arr)
      }
      arr.push(imp)
    })
    const allInvoices: any[] = Array.from(invoiceMap.keys()).map((invNum) => {
      return {
        invoiceNumber: invNum,
        invoiceEntries: invoiceMap.get(invNum) || [],
      }
    })
    allInvoices.sort((a, b) => {
      const aFirstItem = a.invoiceEntries[0] || {}
      const bFirstItem = b.invoiceEntries[0] || {}
      const aDate = extractField(aFirstItem, 'invoice_date') || ''
      const bDate = extractField(bFirstItem, 'invoice_date') || ''
      return new Date(bDate).getTime() - new Date(aDate).getTime() // Sort by date in descending order
    })
    const findMatchingLineItem = (imp: any): any => {
      const projectKey = imp.__projectKey
      const importSku = String(extractField(imp, 'sku_number') || '')
        .toLowerCase()
        .trim()
      const normalizedImportSku = normalizeSkuForMatching(importSku)
      const lineItems = us_invoiceLineItems[projectKey] || []
      const exactMatch = lineItems.find((li: any) => {
        const liSku = String(extractField(li, 'associated_sku_unique_identifier') || '')
          .toLowerCase()
          .trim()
        const liProject = extractField(li, 'associated_project_key')
        return liSku === importSku && liProject === projectKey
      })
      if (exactMatch) return exactMatch
      return lineItems.find((li: any) => {
        const liProject = extractField(li, 'associated_project_key')
        if (liProject !== projectKey) return false
        const liSku = String(extractField(li, 'associated_sku_unique_identifier') || '')
          .toLowerCase()
          .trim()
        if (normalizeSkuForMatching(liSku) === normalizedImportSku) return true
        const liMfgSku = String(extractField(li, 'associated_sku_manufacturer_unique_identifier') || '')
          .toLowerCase()
          .trim()
        if (normalizeSkuForMatching(liMfgSku) === normalizedImportSku) return true

        return false
      })
    }

    const getExpectedUnitPrice = (imp: any, matchingLineItem: any): number => {
      const skuNumLower = String(extractField(imp, 'sku_number') || '')
        .toLowerCase()
        .trim()

      let lineItemPrice = 0
      if (matchingLineItem) {
        lineItemPrice = parseFloat(extractField(matchingLineItem, 'associated_sku_pick_estimated_price_per_package')) || 0
      }
      if (lineItemPrice > 0) {
        return lineItemPrice
      } else {
        let masterDoc = us_invoiceMasterSkus[skuNumLower]
        if (!masterDoc) {
          const normalizedKey = normalizeSkuForMatching(skuNumLower)
          if (us_invoiceMasterSkus[normalizedKey]) {
            masterDoc = us_invoiceMasterSkus[normalizedKey]
          }
        }
        if (masterDoc && masterDoc.pick_estimated_price_per_package) {
          return parseFloat(masterDoc.pick_estimated_price_per_package) || 0
        }
        if (matchingLineItem) {
          const alternativePriceFields = ['estimated_price_per_package', 'price_per_package', 'unit_price', 'price']
          for (const field of alternativePriceFields) {
            const altPrice = parseFloat(extractField(matchingLineItem, field)) || 0
            if (altPrice > 0) {
              return altPrice
            }
          }
          const mfgSku = String(extractField(matchingLineItem, 'associated_sku_manufacturer_unique_identifier') || '')
            .toLowerCase()
            .trim()
          if (mfgSku && mfgSku !== skuNumLower) {
            const mfgSkuDoc = us_invoiceMasterSkus[mfgSku]
            if (mfgSkuDoc && mfgSkuDoc.pick_estimated_price_per_package) {
              return parseFloat(mfgSkuDoc.pick_estimated_price_per_package) || 0
            }
          }
        }

        return 0
      }
    }

    const getPaymentRecommendation = (invoiceEntries: any[], invoiceTotalDifference: number = 0): JSX.Element => {
      const allReceived = invoiceEntries.every((item) => {
        const matchedLineItem = findMatchingLineItem(item)
        const status = matchedLineItem ? extractField(matchedLineItem, 'item_received_status') : null
        return status === 'received'
      })
      const anyMissingMatches = invoiceEntries.some((item) => !findMatchingLineItem(item))
      const anyQuantityDiscrepancies = invoiceEntries.some((item) => {
        const matchedLineItem = findMatchingLineItem(item)
        if (!matchedLineItem) return false
        const orderedQty = parseFloat(extractField(item, 'quantity')) || 0
        const receivedQty = parseFloat(extractField(matchedLineItem, 'actual_quantity_received')) || 0
        return receivedQty !== orderedQty && orderedQty > 0
      })
      const hasPriceDifference = invoiceTotalDifference !== 0
      if (anyMissingMatches) {
        return <Box sx={{ color: 'orange', fontWeight: 'bold' }}>Review Required - Some items have no matching line items</Box>
      } else if (!allReceived) {
        return <Box sx={{ color: 'red', fontWeight: 'bold' }}>Pending - Not all items have been received</Box>
      } else if (anyQuantityDiscrepancies) {
        return <Box sx={{ color: 'orange', fontWeight: 'bold' }}>Review Required - Quantity discrepancies between ordered and received</Box>
      } else if (hasPriceDifference) {
        return <Box sx={{ color: 'orange', fontWeight: 'bold' }}>Review Required - Price discrepancy of ${Math.abs(invoiceTotalDifference).toFixed(2)}</Box>
      } else {
        return <Box sx={{ color: 'green', fontWeight: 'bold' }}>Ready to Pay - All items received</Box>
      }
    }

    const renderInvoicesUI = () => {
      return allInvoices.map(({ invoiceNumber, invoiceEntries }, idx) => {
        const firstItem = invoiceEntries[0] || {}
        const supplier = extractField(firstItem, 'associated_supplier_name') || 'Unknown Supplier'
        const invoiceDate = extractField(firstItem, 'invoice_date') || 'Unknown Date'
        const isProcessed = Boolean(us_invoiceStatusMap[String(invoiceNumber)])
        const processedStatus = isProcessed ? us_invoiceStatusMap[String(invoiceNumber)].status : null
        const invoiceTotal = invoiceEntries.reduce((sum: number, item: any) => sum + getItemPrice(item), 0)
        let invoiceTotalImport = 0
        let invoiceTotalExpected = 0
        const tableRows = invoiceEntries.map((imp: any, rowIdx: number) => {
          const projectId = extractField(imp, 'associated_project_id_number') || 'Unknown'
          const skuNum = String(extractField(imp, 'sku_number') || '')
          const desc = extractField(imp, 'sku_description') || 'N/A'
          const qtyImported = parseFloat(extractField(imp, 'quantity')) || 0
          const importUnitPrice = parseFloat(extractField(imp, 'unit_price')) || 0
          const importPrice = getItemPrice(imp)
          invoiceTotalImport += importPrice
          const poNumber = extractField(imp, 'associated_po_number') || 'N/A'
          const matchingLineItem = findMatchingLineItem(imp)
          const matchFound = Boolean(matchingLineItem)
          let lineItemPo = poNumber
          let receivedStatus = 'no_line_item'
          let actualQtyReceived = 0

          if (matchFound) {
            lineItemPo = extractField(matchingLineItem, 'associated_order_po_number') || poNumber
            receivedStatus = extractField(matchingLineItem, 'item_received_status') || 'not_received'
            actualQtyReceived = extractField(matchingLineItem, 'actual_quantity_received') || 0
            if (receivedStatus === 'received' && !actualQtyReceived) {
              actualQtyReceived = qtyImported
            }
          }

          const expectedUnitPrice = getExpectedUnitPrice(imp, matchingLineItem)
          const qtyForCalculation = actualQtyReceived || 0
          const expectedTotal = expectedUnitPrice * qtyForCalculation
          invoiceTotalExpected += expectedTotal
          const priceDifference = importPrice - expectedTotal
          const priceVarianceColor =
            priceDifference > 0
              ? themeVariables.error_light // Over expected
              : priceDifference < 0
                ? themeVariables.success_light // Under
                : 'white' // Exactly equal

          return (
            <TableRow key={rowIdx}>
              <TableCell sx={{ color: 'white' }}>{projectId}</TableCell>
              <TableCell sx={{ color: 'white' }}>
                {!matchFound ? (
                  <Tooltip title="No matching SKU found between import and order">
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'orange' }}>
                      <HelpOutlineIcon sx={{ mr: 1, fontSize: 16 }} />
                      No match
                    </Box>
                  </Tooltip>
                ) : (
                  lineItemPo
                )}
              </TableCell>
              <TableCell sx={{ color: 'white' }}>{skuNum}</TableCell>
              <TableCell sx={{ color: 'white' }}>{desc}</TableCell>

              <TableCell sx={{ color: 'white', borderLeft: `1px solid ${themeVariables.gray_700}`, pl: 2 }}>{qtyImported}</TableCell>
              <TableCell sx={{ color: 'white' }}>${importUnitPrice.toFixed(2)}</TableCell>
              <TableCell sx={{ color: 'white', borderRight: `1px solid ${themeVariables.gray_700}`, pr: 2 }}>${importPrice.toFixed(2)}</TableCell>

              <TableCell sx={{ color: 'white', borderLeft: `1px solid ${themeVariables.gray_700}`, pl: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {actualQtyReceived || 0}
                  {actualQtyReceived !== qtyImported && qtyImported > 0 && (
                    <Tooltip title={`Ordered quantity (${qtyImported}) differs from received quantity (${actualQtyReceived || 0})`}>
                      <Box
                        component="span"
                        sx={{ ml: 1, color: 'orange' }}
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </TableCell>
              <TableCell sx={{ color: 'white' }}>${expectedUnitPrice.toFixed(2)}</TableCell>
              <TableCell sx={{ color: 'white', borderRight: `1px solid ${themeVariables.gray_700}`, pr: 2 }}>${expectedTotal.toFixed(2)}</TableCell>

              <TableCell sx={{ color: priceVarianceColor }}>
                {priceDifference > 0 ? '+$' : '$'}
                {Math.abs(priceDifference).toFixed(2)}
              </TableCell>
              <TableCell>{getStatusDisplay(receivedStatus, actualQtyReceived, qtyImported)}</TableCell>
            </TableRow>
          )
        })

        return (
          <Accordion
            key={`invoice_${invoiceNumber}_${idx}`}
            sx={{
              'backgroundColor': themeVariables.background_paper,
              'mb': 3,
              'borderRadius': '8px',
              'boxShadow': '0 4px 6px rgba(0,0,0,0.1)',
              '&::before': { display: 'none' },
            }}
          >
            <AccordionSummary
              expandIcon={
                <Icon
                  icon="chevron-down"
                  tooltip="Expand/Collapse"
                />
              }
              sx={{
                'backgroundColor': 'transparent',
                'borderRadius': '8px 8px 0 0',
                'px': 3,
                'py': 1,
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(180deg)' },
              }}
            >
              <Grid
                container
                alignItems="center"
              >
                <Grid
                  item
                  xs={4}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {getInvoiceStatusChip(invoiceNumber)}
                    <Typography
                      variant="h6"
                      color="white"
                    >
                      Invoice {invoiceNumber}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={5}
                >
                  <Typography
                    variant="body1"
                    color="white"
                  >
                    {supplier} • {invoiceDate}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  textAlign="right"
                >
                  <Typography
                    variant="subtitle1"
                    color="white"
                    sx={{ fontWeight: 'bold' }}
                  >
                    ${invoiceTotal.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 3, py: 2 }}>
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: 'transparent', boxShadow: 'none', mb: 3 }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        sx={{ color: 'white', fontWeight: 'bold', borderBottom: 'none' }}
                      ></TableCell>
                      <TableCell
                        colSpan={3}
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          borderLeft: `1px solid ${themeVariables.gray_700}`,
                          borderRight: `1px solid ${themeVariables.gray_700}`,
                          backgroundColor: themeVariables.gray_800,
                        }}
                      >
                        Invoice Data
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          borderLeft: `1px solid ${themeVariables.gray_700}`,
                          borderRight: `1px solid ${themeVariables.gray_700}`,
                          backgroundColor: themeVariables.gray_800,
                        }}
                      >
                        Order Data
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center', backgroundColor: themeVariables.gray_800 }}
                      >
                        Analysis
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Project ID</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PO Number</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>SKU</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold', borderLeft: `1px solid ${themeVariables.gray_700}`, pl: 2 }}>Qty</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Unit Price</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold', borderRight: `1px solid ${themeVariables.gray_700}`, pr: 2 }}>Total</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold', borderLeft: `1px solid ${themeVariables.gray_700}`, pl: 2 }}>Qty</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Unit Price</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold', borderRight: `1px solid ${themeVariables.gray_700}`, pr: 2 }}>Total</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Diff. ($)</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{tableRows}</TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={4} />
                      <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'right', borderLeft: `1px solid ${themeVariables.gray_700}` }}>
                        Total:
                      </TableCell>
                      <TableCell />
                      <TableCell sx={{ color: 'white', fontWeight: 'bold', borderRight: `1px solid ${themeVariables.gray_700}` }}>
                        ${invoiceTotalImport.toFixed(2)}
                      </TableCell>
                      <TableCell />
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}></TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold', borderRight: `1px solid ${themeVariables.gray_700}` }}>
                        ${invoiceTotalExpected.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            invoiceTotalImport - invoiceTotalExpected > 0
                              ? themeVariables.error_light
                              : invoiceTotalImport - invoiceTotalExpected < 0
                                ? themeVariables.success_light
                                : 'white',
                          fontWeight: 'bold',
                        }}
                      >
                        {invoiceTotalImport - invoiceTotalExpected > 0 ? '+$' : '$'}
                        {Math.abs(invoiceTotalImport - invoiceTotalExpected).toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        {isProcessed && (
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {processedStatus === 'paid' ? (
                              <Chip
                                icon={<PaidIcon />}
                                label="Invoice Paid"
                                color="success"
                                size="small"
                              />
                            ) : (
                              <Chip
                                icon={<MoneyOffIcon />}
                                label="Invoice Rejected"
                                color="error"
                                size="small"
                              />
                            )}
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 2 }}>
                <Box>
                  {isProcessed ? (
                    <Card sx={{ backgroundColor: 'transparent', borderRadius: '8px', border: `1px solid ${themeVariables.gray_700}`, p: 1.5 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {processedStatus === 'paid' ? (
                          <PaidIcon sx={{ mr: 1, color: themeVariables.success_main }} />
                        ) : (
                          <MoneyOffIcon sx={{ mr: 1, color: themeVariables.error_main }} />
                        )}
                        <Typography
                          variant="subtitle1"
                          color="white"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {processedStatus === 'paid' ? 'Paid' : 'Rejected'}: {us_invoiceStatusMap[String(invoiceNumber)].notes || 'No notes provided'}
                        </Typography>
                      </Box>
                    </Card>
                  ) : (
                    <Card sx={{ backgroundColor: 'transparent', borderRadius: '8px', border: `1px solid ${themeVariables.gray_700}`, p: 1.5 }}>
                      <Typography
                        variant="subtitle1"
                        color="white"
                        sx={{ fontWeight: 'bold' }}
                      >
                        Recommendation: {getPaymentRecommendation(invoiceEntries, invoiceTotalImport - invoiceTotalExpected)}
                      </Typography>
                    </Card>
                  )}
                </Box>

                {!isProcessed && (
                  <Box sx={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      startIcon={<PaidIcon />}
                      sx={{ mr: 2, backgroundColor: themeVariables.success_main }}
                      onClick={() => {
                        us_setPaymentStatus('paid')
                        handleOpenPaymentDialog(invoiceNumber, invoiceEntries)
                      }}
                    >
                      Mark as Paid
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<MoneyOffIcon />}
                      sx={{ backgroundColor: themeVariables.error_main }}
                      onClick={() => {
                        us_setPaymentStatus('rejected')
                        handleOpenPaymentDialog(invoiceNumber, invoiceEntries)
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      })
    }

    return (
      <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <CardContent>
          {allInvoices.length === 0 ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography
                variant="body1"
                sx={{ color: 'white' }}
              >
                No invoices found within the selected date range.
              </Typography>
            </Box>
          ) : (
            renderInvoicesUI()
          )}
        </CardContent>
      </Card>
    )
  }

  const rJSX_invoiceLookupTab = (): JSX.Element => {
    const handleLookupChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      us_setInvoiceLookup(evt.target.value)
    }

    // Function to check if an invoice has been processed (paid/rejected)
    const checkInvoiceStatus = (clientKey: string, invoiceNumber: string | number) => {
      // CRITICAL: Always convert invoice number to string for consistency with database
      const invoiceNumberAsString = String(invoiceNumber)

      return DatabaseGetCollection(DatabaseRef_Processed_Invoices_By_Invoice_Number(clientKey, invoiceNumberAsString))
        .then((result) => {
          const processedInvoices = unwrapResponse(result)

          if (processedInvoices && Array.isArray(processedInvoices) && processedInvoices.length > 0) {
            // We found a processed invoice, but verify it has a valid status
            const invoice = processedInvoices[0]

            if (invoice && invoice.status && typeof invoice.status === 'string') {
              us_setLookupInvoiceStatusInfo(invoice)
              return invoice
            } else {
              us_setLookupInvoiceStatusInfo(null)
              return null
            }
          } else {
            // No processed invoice found - try a fallback approach

            // For debugging, check all processed invoices
            return DatabaseGetCollection(DatabaseRef_Processed_Invoices_Collection(clientKey))
              .then((allProcessedResult) => {
                const allProcessed = unwrapResponse(allProcessedResult)

                // Find any matching invoice by string comparison
                const matchingInvoice = allProcessed.find((inv: any) => String(inv.invoice_number) === invoiceNumberAsString)

                if (matchingInvoice && matchingInvoice.status && typeof matchingInvoice.status === 'string') {
                  us_setLookupInvoiceStatusInfo(matchingInvoice)
                  return matchingInvoice
                } else {
                  us_setLookupInvoiceStatusInfo(null)
                  return null
                }
              })
              .catch((err) => {
                console.error('Error in fallback invoice check:', err)
                us_setLookupInvoiceStatusInfo(null)
                return null
              })
          }
        })
        .catch((error) => {
          console.error('Error checking invoice status:', error)
          us_setLookupInvoiceStatusInfo(null)
          return null
        })
    }

    const handleSearch = () => {
      if (!us_invoiceLookup.trim()) {
        us_setErrorMessage('Please enter an invoice number')
        return
      }

      // Reset all state to ensure we start fresh
      us_setIsLoading(true)
      us_setErrorMessage('')
      us_setLookupResult([])
      us_setLineItemsResult([])

      // IMPORTANT: Make sure we explicitly clear any existing invoice status info
      us_setLookupInvoiceStatusInfo(null)

      // Always keep the invoice number as a string first, then convert only for specific database calls if needed
      const invoiceNumberAsString = String(us_invoiceLookup.trim())
      const invoiceNumberValue = isNaN(Number(invoiceNumberAsString)) ? invoiceNumberAsString : Number(invoiceNumberAsString)

      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK: any) => {
          const clientKey = res_GCK.clientKey

          // First, explicitly check if this invoice has already been processed
          return checkInvoiceStatus(clientKey, invoiceNumberValue).then((processedInvoice) => {
            // Then fetch the invoice line items
            const queryRef =
              typeof invoiceNumberValue === 'number'
                ? DatabaseRef_Material_Imports_By_Invoice_Number_AsNumber(clientKey, invoiceNumberValue)
                : DatabaseRef_Material_Imports_By_Invoice_Number(clientKey, invoiceNumberValue.toString())

            return DatabaseGetCollection(queryRef)
          })
        })
        .then((materialData: any) => {
          const importItems = unwrapResponse(materialData)
          us_setLookupResult(importItems)

          if (importItems.length === 0) {
            us_setErrorMessage(`No data found for invoice ${us_invoiceLookup}`)
            us_setIsLoading(false)
            return
          }

          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK: any) => {
            const clientKey = res_GCK.clientKey
            const projectKeys = Array.from(new Set(importItems.map((item: any) => extractField(item, 'associated_project_key')).filter(Boolean)))
            const lineItemPromises = projectKeys.map((projectKey: string) => {
              return DatabaseGetCollection(DatabaseRef_SkuLineItemsByProject_Query(clientKey, projectKey)).then((lineItemsData) =>
                unwrapResponse(lineItemsData),
              )
            })

            Promise.all(lineItemPromises)
              .then((allLineItemResults) => {
                const allLineItems = allLineItemResults.flat()
                us_setLineItemsResult(allLineItems)
                us_setIsLoading(false)
              })
              .catch((error) => {
                console.error('Error fetching line items for invoice lookup:', error)
                us_setErrorMessage('Error fetching line item data')
                us_setIsLoading(false)
              })
          })
        })
        .catch((error) => {
          console.error('Error in invoice lookup:', error)
          us_setErrorMessage('Error looking up invoice data')
          us_setIsLoading(false)
        })
    }

    const findMatchingLineItem = (imp: any): any => {
      const projectKey = extractField(imp, 'associated_project_key')
      const importSku = String(extractField(imp, 'sku_number') || '')
        .toLowerCase()
        .trim()

      const normalizedImportSku = normalizeSkuForMatching(importSku)
      const exactMatch = us_lineItemsResult.find((li: any) => {
        const liSku = String(extractField(li, 'associated_sku_unique_identifier') || '')
          .toLowerCase()
          .trim()
        const liProject = extractField(li, 'associated_project_key')
        return liSku === importSku && liProject === projectKey
      })

      if (exactMatch) return exactMatch
      return us_lineItemsResult.find((li: any) => {
        const liProject = extractField(li, 'associated_project_key')
        if (liProject !== projectKey) return false
        const liSku = String(extractField(li, 'associated_sku_unique_identifier') || '')
          .toLowerCase()
          .trim()
        if (normalizeSkuForMatching(liSku) === normalizedImportSku) return true

        const liMfgSku = String(extractField(li, 'associated_sku_manufacturer_unique_identifier') || '')
          .toLowerCase()
          .trim()
        if (normalizeSkuForMatching(liMfgSku) === normalizedImportSku) return true

        return false
      })
    }
    const getExpectedPrice = (imp: any): number => {
      const skuNum = String(extractField(imp, 'sku_number') || '')
        .toLowerCase()
        .trim()

      // const normalizedSkuNum = normalizeSkuForMatching(skuNum)

      const matchingLineItem = findMatchingLineItem(imp)
      if (matchingLineItem) {
        const expectedPrice = parseFloat(extractField(matchingLineItem, 'associated_sku_pick_estimated_price_per_package')) || 0
        if (expectedPrice > 0) return expectedPrice
      }

      let masterDoc = us_invoiceMasterSkus[skuNum]

      if (!masterDoc) {
        const normalizedKey = normalizeSkuForMatching(skuNum)

        if (us_invoiceMasterSkus[normalizedKey]) {
          masterDoc = us_invoiceMasterSkus[normalizedKey]
        }
      }

      if (masterDoc && masterDoc.pick_estimated_price_per_package) {
        const masterPrice = parseFloat(masterDoc.pick_estimated_price_per_package) || 0
        if (masterPrice > 0) return masterPrice
      }

      if (matchingLineItem) {
        const alternativePriceFields = ['estimated_price_per_package', 'price_per_package', 'unit_price', 'price']

        for (const field of alternativePriceFields) {
          const altPrice = parseFloat(extractField(matchingLineItem, field)) || 0
          if (altPrice > 0) {
            return altPrice
          }
        }

        const mfgSku = String(extractField(matchingLineItem, 'associated_sku_manufacturer_unique_identifier') || '')
          .toLowerCase()
          .trim()
        if (mfgSku && mfgSku !== skuNum) {
          const mfgSkuDoc = us_invoiceMasterSkus[mfgSku]
          if (mfgSkuDoc && mfgSkuDoc.pick_estimated_price_per_package) {
            return parseFloat(mfgSkuDoc.pick_estimated_price_per_package) || 0
          }

          if (!mfgSkuDoc) {
            const normalizedMfgSku = normalizeSkuForMatching(mfgSku)

            if (us_invoiceMasterSkus[normalizedMfgSku]) {
              const matchedDoc = us_invoiceMasterSkus[normalizedMfgSku]
              if (matchedDoc && matchedDoc.pick_estimated_price_per_package) {
                return parseFloat(matchedDoc.pick_estimated_price_per_package) || 0
              }
            }
          }
        }
      }

      return 0
    }
    const getStatusDisplay = (status: string, actualQty: any, requestedQty: number): JSX.Element => {
      switch (status) {
        case 'received':
          return (
            <Box sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
              <CheckCircleIcon sx={{ mr: 1, fontSize: 16 }} />
              Received
            </Box>
          )
        case 'partial':
          return (
            <Box sx={{ color: 'orange', display: 'flex', alignItems: 'center' }}>
              <ChangeCircleIcon sx={{ mr: 1, fontSize: 16 }} />
              Partial ({actualQty || 0}/{requestedQty})
            </Box>
          )
        case 'not_received':
          return (
            <Box sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
              <CancelIcon sx={{ mr: 1, fontSize: 16 }} />
              Not Received
            </Box>
          )
        default:
          return (
            <Box sx={{ color: 'gray', display: 'flex', alignItems: 'center' }}>
              <HelpOutlineIcon sx={{ mr: 1, fontSize: 16 }} />
              Unknown
            </Box>
          )
      }
    }

    const getPaymentRecommendation = (): JSX.Element => {
      const allReceived = us_lookupResult.every((item) => {
        const matchedLineItem = findMatchingLineItem(item)
        const status = matchedLineItem ? extractField(matchedLineItem, 'item_received_status') : null
        return status === 'received'
      })

      const anyMissingMatches = us_lookupResult.some((item) => !findMatchingLineItem(item))

      const anyQuantityDiscrepancies = us_lookupResult.some((item) => {
        const matchedLineItem = findMatchingLineItem(item)
        if (!matchedLineItem) return false

        const orderedQty = parseFloat(extractField(item, 'quantity')) || 0
        const receivedQty = parseFloat(extractField(matchedLineItem, 'actual_quantity_received')) || 0

        return receivedQty !== orderedQty && orderedQty > 0
      })

      if (anyMissingMatches) {
        return <Box sx={{ color: 'orange', fontWeight: 'bold' }}>Review Required - Some items have no matching line items</Box>
      } else if (!allReceived) {
        return <Box sx={{ color: 'red', fontWeight: 'bold' }}>Pending - Not all items have been received</Box>
      } else if (anyQuantityDiscrepancies) {
        return <Box sx={{ color: 'orange', fontWeight: 'bold' }}>Review Required - Quantity discrepancies between ordered and received</Box>
      } else {
        return <Box sx={{ color: 'green', fontWeight: 'bold' }}>Ready to Pay - All items received</Box>
      }
    }

    const renderInvoiceDetails = () => {
      if (us_lookupResult.length === 0) return null

      const firstItem = us_lookupResult[0]
      const supplierName = extractField(firstItem, 'associated_supplier_name') || 'Unknown Supplier'
      const invoiceDate = extractField(firstItem, 'invoice_date') || 'Unknown Date'

      const totalAmount = us_lookupResult.reduce((sum, item) => {
        return sum + (parseFloat(extractField(item, 'total_price')) || 0)
      }, 0)

      // Check if the invoice has been processed - MUST have both a status info object AND a valid status field
      const isProcessed = Boolean(us_lookupInvoiceStatusInfo && us_lookupInvoiceStatusInfo.status)

      // Only get the status and notes if the invoice is actually processed
      const processedStatus = isProcessed ? us_lookupInvoiceStatusInfo?.status : null
      const processedNotes = isProcessed ? us_lookupInvoiceStatusInfo?.notes || '' : ''

      return (
        <Box sx={{ mt: 4 }}>
          <Card sx={{ p: 2, mb: 3, backgroundColor: 'transparent', border: `1px solid ${themeVariables.gray_700}` }}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
              >
                <Typography
                  variant="h6"
                  color="white"
                >
                  Invoice {us_invoiceLookup}
                </Typography>
                <Typography
                  variant="body2"
                  color="white"
                >
                  {supplierName} • {invoiceDate}
                </Typography>
                {isProcessed && processedStatus && (
                  <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                    <Chip
                      icon={processedStatus === 'paid' ? <PaidIcon /> : <MoneyOffIcon />}
                      label={processedStatus === 'paid' ? 'Paid' : 'Rejected'}
                      color={processedStatus === 'paid' ? 'success' : 'error'}
                      size="small"
                      sx={{ mr: 1 }}
                    />

                    {processedNotes && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="body2"
                          color="white"
                          sx={{ ml: 1 }}
                        >
                          Note: {processedNotes}
                        </Typography>
                        <IconButton
                          size="small"
                          sx={{ color: 'white', ml: 1 }}
                          onClick={() => {
                            us_setCurrentInvoiceToProcess({
                              key: us_lookupInvoiceStatusInfo?.key,
                              invoiceNumber: us_invoiceLookup,
                              invoiceData: us_lookupResult,
                              supplier_name: us_lookupInvoiceStatusInfo?.supplier_name,
                              invoice_date: us_lookupInvoiceStatusInfo?.invoice_date,
                              supplier_key: us_lookupInvoiceStatusInfo?.supplier_key,
                              total_amount: us_lookupInvoiceStatusInfo?.total_amount,
                              timestamp_created: us_lookupInvoiceStatusInfo?.timestamp_created,
                            })
                            us_setPaymentStatus(processedStatus)
                            us_setPaymentNotes(processedNotes)
                            us_setPaymentDialogOpen(true)
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ textAlign: { xs: 'left', md: 'right' } }}
              >
                <Typography
                  variant="h6"
                  color="white"
                >
                  Total: ${totalAmount.toFixed(2)}
                </Typography>
                {isProcessed ? null : getPaymentRecommendation()}
                {!isProcessed && (
                  <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      startIcon={<PaidIcon />}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        // Prepare invoice data with all necessary properties
                        const firstItem = us_lookupResult[0] || {}
                        const supplierName = extractField(firstItem, 'associated_supplier_name') || 'Unknown Supplier'
                        const invoiceDate = extractField(firstItem, 'invoice_date') || formatDateToYYYYMMDD(new Date())
                        const supplierKey = extractField(firstItem, 'associated_supplier_key') || 'unknown'
                        const totalAmount = us_lookupResult.reduce((sum, item) => {
                          return sum + (parseFloat(extractField(item, 'total_price')) || 0)
                        }, 0)

                        us_setCurrentInvoiceToProcess({
                          invoiceNumber: us_invoiceLookup,
                          invoiceData: us_lookupResult,
                          supplier_name: supplierName,
                          invoice_date: invoiceDate,
                          supplier_key: supplierKey,
                          total_amount: totalAmount,
                        })
                        us_setPaymentStatus('paid')
                        us_setPaymentNotes('')
                        us_setPaymentDialogOpen(true)
                      }}
                    >
                      Mark as Paid
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      startIcon={<MoneyOffIcon />}
                      onClick={() => {
                        // Prepare invoice data with all necessary properties
                        const firstItem = us_lookupResult[0] || {}
                        const supplierName = extractField(firstItem, 'associated_supplier_name') || 'Unknown Supplier'
                        const invoiceDate = extractField(firstItem, 'invoice_date') || formatDateToYYYYMMDD(new Date())
                        const supplierKey = extractField(firstItem, 'associated_supplier_key') || 'unknown'
                        const totalAmount = us_lookupResult.reduce((sum, item) => {
                          return sum + (parseFloat(extractField(item, 'total_price')) || 0)
                        }, 0)

                        us_setCurrentInvoiceToProcess({
                          invoiceNumber: us_invoiceLookup,
                          invoiceData: us_lookupResult,
                          supplier_name: supplierName,
                          invoice_date: invoiceDate,
                          supplier_key: supplierKey,
                          total_amount: totalAmount,
                        })
                        us_setPaymentStatus('rejected')
                        us_setPaymentNotes('')
                        us_setPaymentDialogOpen(true)
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Card>

          <TableContainer
            component={Paper}
            sx={{ backgroundColor: 'transparent', boxShadow: 'none', mb: 3 }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ color: 'white', fontWeight: 'bold', borderBottom: 'none' }}
                  ></TableCell>
                  <TableCell
                    colSpan={3}
                    sx={{
                      color: 'white',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      borderLeft: `1px solid ${themeVariables.gray_700}`,
                      borderRight: `1px solid ${themeVariables.gray_700}`,
                      backgroundColor: themeVariables.gray_800,
                    }}
                  >
                    Invoice Data
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    sx={{
                      color: 'white',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      borderLeft: `1px solid ${themeVariables.gray_700}`,
                      borderRight: `1px solid ${themeVariables.gray_700}`,
                      backgroundColor: themeVariables.gray_800,
                    }}
                  >
                    Order Data
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center', backgroundColor: themeVariables.gray_800 }}
                  >
                    Analysis
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Project</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>PO Number</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>SKU</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', borderLeft: `1px solid ${themeVariables.gray_700}`, pl: 2 }}>Qty</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Unit Price</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', borderRight: `1px solid ${themeVariables.gray_700}`, pr: 2 }}>Total</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', borderLeft: `1px solid ${themeVariables.gray_700}`, pl: 2 }}>Qty</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Unit Price</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', borderRight: `1px solid ${themeVariables.gray_700}`, pr: 2 }}>Total</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Diff. ($)</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {us_lookupResult.map((item, index) => {
                  const skuNum = extractField(item, 'sku_number') || 'N/A'
                  const desc = extractField(item, 'sku_description') || 'N/A'
                  const qty = extractField(item, 'quantity') || 0
                  const unitPrice = extractField(item, 'unit_price') || 0
                  const total = extractField(item, 'total_price') || 0
                  const projectId = extractField(item, 'associated_project_id_number') || 'N/A'
                  const poNumber = extractField(item, 'associated_po_number') || 'N/A'

                  const matchingLineItem = findMatchingLineItem(item)
                  const itemStatus = matchingLineItem ? extractField(matchingLineItem, 'item_received_status') || 'not_received' : 'not_found'
                  const actualQty = matchingLineItem ? extractField(matchingLineItem, 'actual_quantity_received') : null

                  const expectedUnitPrice = getExpectedPrice(item)

                  const qtyForCalculation = actualQty || 0

                  const expectedTotal = expectedUnitPrice * qtyForCalculation
                  const priceDifference = parseFloat(total) - expectedTotal

                  const priceVarianceColor = priceDifference > 0 ? themeVariables.error_light : priceDifference < 0 ? themeVariables.success_light : 'white'

                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ color: 'white' }}>{projectId}</TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        {matchingLineItem ? extractField(matchingLineItem, 'associated_order_po_number') || poNumber : poNumber}
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>{skuNum}</TableCell>
                      <TableCell sx={{ color: 'white' }}>{desc}</TableCell>

                      <TableCell sx={{ color: 'white', borderLeft: `1px solid ${themeVariables.gray_700}`, pl: 2 }}>{qty}</TableCell>
                      <TableCell sx={{ color: 'white' }}>${parseFloat(unitPrice).toFixed(2)}</TableCell>
                      <TableCell sx={{ color: 'white', borderRight: `1px solid ${themeVariables.gray_700}`, pr: 2 }}>${parseFloat(total).toFixed(2)}</TableCell>

                      <TableCell sx={{ color: 'white', borderLeft: `1px solid ${themeVariables.gray_700}`, pl: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {actualQty || 0}
                          {actualQty !== qty && qty > 0 && (
                            <Tooltip title={`Ordered quantity (${qty}) differs from received quantity (${actualQty || 0})`}>
                              <Box
                                component="span"
                                sx={{ ml: 1, color: 'orange' }}
                              >
                                <HelpOutlineIcon fontSize="small" />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>${expectedUnitPrice.toFixed(2)}</TableCell>
                      <TableCell sx={{ color: 'white', borderRight: `1px solid ${themeVariables.gray_700}`, pr: 2 }}>${expectedTotal.toFixed(2)}</TableCell>

                      <TableCell sx={{ color: priceVarianceColor }}>
                        {priceDifference > 0 ? '+$' : '$'}
                        {Math.abs(priceDifference).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {matchingLineItem ? (
                          getStatusDisplay(itemStatus, actualQty, qty)
                        ) : (
                          <Box sx={{ color: 'gray', display: 'flex', alignItems: 'center' }}>
                            <HelpOutlineIcon sx={{ mr: 1, fontSize: 16 }} />
                            No Matching Line Item
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={4} />
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', textAlign: 'right', borderLeft: `1px solid ${themeVariables.gray_700}` }}>
                    Total:
                  </TableCell>
                  <TableCell />
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', borderRight: `1px solid ${themeVariables.gray_700}` }}>
                    ${us_lookupResult.reduce((sum, item) => sum + (parseFloat(extractField(item, 'total_price')) || 0), 0).toFixed(2)}
                  </TableCell>
                  <TableCell />
                  <TableCell sx={{ color: 'white', fontWeight: 'bold' }}></TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', borderRight: `1px solid ${themeVariables.gray_700}` }}>
                    $
                    {us_lookupResult
                      .reduce((sum, item) => {
                        const matchingLineItem = findMatchingLineItem(item)
                        const expectedUnitPrice = getExpectedPrice(item)
                        const actualQty = matchingLineItem ? extractField(matchingLineItem, 'actual_quantity_received') || 0 : 0
                        return sum + expectedUnitPrice * actualQty
                      }, 0)
                      .toFixed(2)}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      color: (() => {
                        const diff = us_lookupResult.reduce((sum, item) => {
                          const total = parseFloat(extractField(item, 'total_price')) || 0
                          const matchingLineItem = findMatchingLineItem(item)
                          const expectedUnitPrice = getExpectedPrice(item)
                          const actualQty = matchingLineItem ? extractField(matchingLineItem, 'actual_quantity_received') || 0 : 0
                          const expectedTotal = expectedUnitPrice * actualQty
                          return sum + (total - expectedTotal)
                        }, 0)

                        return diff > 0 ? themeVariables.error_light : diff < 0 ? themeVariables.success_light : 'white'
                      })(),
                    }}
                  >
                    {(() => {
                      const diff = us_lookupResult.reduce((sum, item) => {
                        const total = parseFloat(extractField(item, 'total_price')) || 0
                        const matchingLineItem = findMatchingLineItem(item)
                        const expectedUnitPrice = getExpectedPrice(item)
                        const actualQty = matchingLineItem ? extractField(matchingLineItem, 'actual_quantity_received') || 0 : 0
                        const expectedTotal = expectedUnitPrice * actualQty
                        return sum + (total - expectedTotal)
                      }, 0)

                      return `${diff > 0 ? '+$' : '$'}${Math.abs(diff).toFixed(2)}`
                    })()}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      )
    }

    return (
      <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h6"
              color="white"
              gutterBottom
            >
              Invoice Lookup
            </Typography>
            <Typography
              variant="body2"
              color="white"
              gutterBottom
            >
              * Start and end date filters do not apply to this lookup.
            </Typography>

            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="Invoice Number"
                  variant="outlined"
                  fullWidth
                  value={us_invoiceLookup}
                  onChange={handleLookupChange}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleSearch()
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white',
                      },
                      '&:hover fieldset': {
                        borderColor: 'white',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
              >
                <Button
                  onClick={handleSearch}
                  disabled={us_isLoading}
                  variant="contained"
                  color="primary"
                  sx={{ backgroundColor: themeVariables.primary_main }}
                >
                  {us_isLoading ? 'Searching...' : 'Search Invoice'}
                </Button>
              </Grid>
            </Grid>

            {us_errorMessage && (
              <Typography
                color="error"
                sx={{ mt: 2 }}
              >
                {us_errorMessage}
              </Typography>
            )}
          </Box>

          {us_isLoading ? (
            <Box sx={{ textAlign: 'center', py: 4 }}>
              <Typography color="white">Loading invoice data...</Typography>
            </Box>
          ) : us_errorMessage ? (
            <Box sx={{ textAlign: 'center', py: 4, mt: 2 }}>
              <Typography
                color="white"
                variant="h6"
              >
                {us_errorMessage}
              </Typography>
            </Box>
          ) : (
            renderInvoiceDetails()
          )}
        </CardContent>
      </Card>
    )
  }

  /*************************************************
   * TABS
   *************************************************/

  const rJSX_processedInvoicesTab = (): JSX.Element => {
    return (
      <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <CardContent>
          <Box>
            <Typography
              variant="h6"
              color="white"
              gutterBottom
            >
              Processed Invoices
            </Typography>

            {us_processedInvoices.length === 0 ? (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography color="white">No processed invoices found in the selected date range.</Typography>
              </Box>
            ) : (
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: 'transparent', mt: 2 }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Invoice</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Supplier</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Amount</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Notes</TableCell>
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {us_processedInvoices.map((invoice) => (
                      <TableRow key={invoice.key}>
                        <TableCell sx={{ color: 'white' }}>{invoice.invoice_number}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{invoice.supplier_name}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{invoice.invoice_date}</TableCell>
                        <TableCell sx={{ color: 'white' }}>${invoice.total_amount ? invoice.total_amount.toFixed(2) : '0.00'}</TableCell>

                        <TableCell>
                          {invoice.status === 'paid' ? (
                            <Chip
                              icon={<PaidIcon />}
                              label="Paid"
                              color="success"
                              size="small"
                            />
                          ) : (
                            <Chip
                              icon={<MoneyOffIcon />}
                              label="Rejected"
                              color="error"
                              size="small"
                            />
                          )}
                        </TableCell>

                        <TableCell sx={{ color: 'white', alignItems: 'center' }}>
                          {invoice.notes || '-'}
                          <IconButton
                            size="small"
                            sx={{ color: 'white', ml: 1 }}
                            onClick={() => handleOpenNotesDialog(invoice)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Update Status">
                            <IconButton
                              onClick={() => {
                                us_setCurrentInvoiceToProcess({
                                  invoiceNumber: invoice.invoice_number,

                                  key: invoice.key,
                                  supplier_name: invoice.supplier_name,
                                  invoice_date: invoice.invoice_date,
                                  supplier_key: invoice.supplier_key,
                                  total_amount: invoice.total_amount,
                                  timestamp_created: invoice.timestamp_created,
                                })
                                us_setPaymentStatus(invoice.status === 'paid' ? 'rejected' : 'paid')
                                us_setPaymentNotes(invoice.notes || '')
                                us_setPaymentDialogOpen(true)
                              }}
                            >
                              <Icon
                                icon="pen-to-square"
                                sx={{ color: themeVariables.primary_main }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </CardContent>
      </Card>
    )
  }

  const rJSX_PaymentDialog = (): JSX.Element => {
    if (!us_currentInvoiceToProcess) return <></>

    return (
      <Dialog
        open={us_paymentDialogOpen}
        onClose={handleClosePaymentDialog}
        PaperProps={{
          sx: {
            backgroundColor: themeVariables.background_paper,
            color: 'white',
            minWidth: '400px',
          },
        }}
      >
        <DialogTitle sx={{ borderBottom: `1px solid ${themeVariables.gray_700}` }}>
          {us_paymentStatus === 'paid' ? 'Mark Invoice as Paid' : 'Reject Invoice'}
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText sx={{ color: 'white', mb: 2 }}>
            {us_paymentStatus === 'paid'
              ? `Are you sure you want to mark Invoice ${us_currentInvoiceToProcess.invoiceNumber} as paid?`
              : `Are you sure you want to reject Invoice ${us_currentInvoiceToProcess.invoiceNumber}?`}
          </DialogContentText>

          <TextField
            label="Notes"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={us_paymentNotes}
            onChange={(e) => us_setPaymentNotes(e.target.value)}
            margin="normal"
            InputProps={{ style: { color: 'white' } }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={handleClosePaymentDialog}
            startIcon={<CloseIcon />}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleProcessInvoice}
            variant="contained"
            color={us_paymentStatus === 'paid' ? 'success' : 'error'}
            startIcon={us_paymentStatus === 'paid' ? <PaidIcon /> : <MoneyOffIcon />}
            sx={{ ml: 1 }}
          >
            {us_paymentStatus === 'paid' ? 'Mark as Paid' : 'Reject Invoice'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const rJSX_NotesDialog = (): JSX.Element => {
    if (!us_currentInvoiceToProcess) return <></>

    return (
      <Dialog
        open={us_notesDialogOpen}
        onClose={handleCloseNotesDialog}
        PaperProps={{
          sx: {
            backgroundColor: themeVariables.background_paper,
            color: 'white',
            minWidth: '400px',
          },
        }}
      >
        <DialogTitle sx={{ borderBottom: `1px solid ${themeVariables.gray_700}` }}>Edit Invoice Notes</DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText sx={{ color: 'white', mb: 2 }}>Update notes for Invoice {us_currentInvoiceToProcess.invoiceNumber}</DialogContentText>

          <TextField
            label="Notes"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={us_paymentNotes}
            onChange={(e) => us_setPaymentNotes(e.target.value)}
            margin="normal"
            InputProps={{ style: { color: 'white' } }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={handleCloseNotesDialog}
            startIcon={<CloseIcon />}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateNotes}
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            sx={{ ml: 1, backgroundColor: themeVariables.primary_main }}
          >
            Update Notes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const rJSX_Snackbar = (): JSX.Element => {
    return (
      <Snackbar
        open={us_snackbarOpen}
        autoHideDuration={5000}
        onClose={() => us_setSnackbarOpen(false)}
        message={us_snackbarMessage}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={() => us_setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )
  }

  const rJSX_MaterialAnalysisTabs = (): JSX.Element => {
    return (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'invoices',
            tabHeader: 'Invoices',
            tabButtons: [
              { fullJSX: rJSX_DatePicker(), minJSX: rJSX_DatePicker(), sizeCutoff: 0 },
              { fullJSX: rJSX_SupplierFilter(), minJSX: rJSX_SupplierFilter(), sizeCutoff: 0 },
            ],
            tabContent: rJSX_invoicesTab(),
          },
          {
            tabUrlKey: 'invoice_lookup',
            tabHeader: 'Invoice Lookup',
            tabButtons: [{ fullJSX: rJSX_DatePicker(), minJSX: rJSX_DatePicker(), sizeCutoff: 0 }],
            tabContent: rJSX_invoiceLookupTab(),
          },
          {
            tabUrlKey: 'processed_invoices',
            tabHeader: 'Processed Invoices',
            tabButtons: [{ fullJSX: rJSX_DatePicker(), minJSX: rJSX_DatePicker(), sizeCutoff: 0 }],
            tabContent: rJSX_processedInvoicesTab(),
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.AdminDatabaseMaterialAnalysisIndexPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: 'Invoice Reconciliation',
        }}
      />
    )
  }

  const rJSX_Page = (): JSX.Element => {
    return (
      <Box>
        <AuthenticatedContainer
          pageHeader="Invoice Reconciliation"
          pageKey={ApplicationPages.AdminDatabaseMaterialAnalysisIndexPage.key}
          content={<>{rJSX_MaterialAnalysisTabs()}</>}
        />
        {rJSX_PaymentDialog()}
        {rJSX_NotesDialog()}
        {rJSX_Snackbar()}
      </Box>
    )
  }

  return <>{rJSX_Page()}</>
}
