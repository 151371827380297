import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { useContext, useEffect, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import {
  DatabaseRef_ABPApplicationFee_Document,
  DatabaseRef_ABPDegradation_Document,
  DatabaseRef_ABPForm_Collection,
  DatabaseRef_ABPForm_Document,
  DatabaseRef_ABPGreenhouseURLIsProd_Document,
  DatabaseRef_ABPPresets_Collection,
  DatabaseRef_ABPRecGroupValues_Collection,
  DatabaseRef_ABPRecGroupValues_Document,
} from './database'
import { TsInterface_ABPSettings } from './interfaces/i_abp_disclosure_forms'
import { tableColumns_ABPDisclosurePresets, tableColumns_ABPForms, tableSettings_ABPDisclosurePresets } from './tables/abp_form_table'

const pageKey: string = ApplicationPages['AdminABPDisclosureIndexPage']['key']

export const Container = (): JSX.Element => {
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // update the form data, including updating the statuses of all forms that are not 'Completed' or 'Withdrawn'
  useEffect(() => {
    const updateFormData = async () => {
      const client = 'etw_energy'
      const res = await DatabaseGetCollection(DatabaseRef_ABPForm_Collection(client))

      // Create array of promises for forms that need updating
      const statusUpdatePromises = Object.values(res.data)
        .filter((form) => form.form_status !== 'Completed' && form.form_status !== 'Withdrawn' && form.form_id)
        .map((form) =>
          cloudFunctionUnauthenticatedRequests({
            function: 'getABPDisclosureFormStatus',
            form_id: form.form_id,
          })
            .then((res: any) => {
              if (res.data.success) {
                // Handle successful response
                if (res.data.data[0].form_status !== form.form_status) {
                  form.form_status = res.data.data[0].form_status
                  DatabaseSetMergeDocument(DatabaseRef_ABPForm_Document(client, form.key), { ...form, form_status: res.data.data[0].form_status })
                }
              }
            })
            .catch((err) => {
              // Handle errors but don't fail the promise
              console.error('Error updating form status:', form.form_id, err)
              if (err.response?.data) {
                return { error: err.response.data.error_message, form_id: form.form_id }
              }
              return { error: 'Unknown error', form_id: form.form_id }
            }),
        )

      // Wait for all promises to resolve
      const results = await Promise.all(statusUpdatePromises)
    }

    updateFormData()
  }, [])

  const tableDatabaseEndpoint_ABPDisclosureFormPresets = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'form_type', comparator: '==', value: 'active' }]
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    // if (queryGenerationData['startAfter'] != null) {
    //   queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    // }
    // if (queryGenerationData['startAt'] != null) {
    //   queryCursorsObject['startAt'] = queryGenerationData.startAt
    // }
    // if (queryGenerationData['endAt'] != null) {
    //   queryCursorsObject['endAt'] = queryGenerationData.endAt
    // }
    // if (queryGenerationData['endBefore'] != null) {
    //   queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    // }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_ABPPresets_Collection(uc_RootData_ClientKey as string),
      //   queryOperatorsArray,
      //   orderByArray,
      //   queryCursorsObject,
      [],
      [],
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_ABPDisclosureForms = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'form_type', comparator: '==', value: 'active' }]
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    // if (queryGenerationData['startAfter'] != null) {
    //   queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    // }
    // if (queryGenerationData['startAt'] != null) {
    //   queryCursorsObject['startAt'] = queryGenerationData.startAt
    // }
    // if (queryGenerationData['endAt'] != null) {
    //   queryCursorsObject['endAt'] = queryGenerationData.endAt
    // }
    // if (queryGenerationData['endBefore'] != null) {
    //   queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    // }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_ABPForm_Collection(uc_RootData_ClientKey as string),
      //   queryOperatorsArray,
      //   orderByArray,
      //   queryCursorsObject,
      [],
      [],
      queryCursorsObject,
      limit,
    )
  }

  const rJSX_ABPDisclosureTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_ABPDisclosurePresets}
              tableDatabaseEndpoint={tableDatabaseEndpoint_ABPDisclosureFormPresets}
              tableSettings={tableSettings_ABPDisclosurePresets}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_ABPFormTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_ABPForms}
              tableDatabaseEndpoint={tableDatabaseEndpoint_ABPDisclosureForms}
              tableSettings={tableSettings_ABPDisclosurePresets}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_ABPSettings = (): JSX.Element => {
    const [us_isEditMode, us_setIsEditMode] = useState(false)
    const [us_deleteConfirmOpen, us_setDeleteConfirmOpen] = useState(false)
    const [us_batteryToDelete, us_setBatteryToDelete] = useState<number | null>(null)
    const [us_addBatteryOpen, us_setAddBatteryOpen] = useState(false)
    const [us_newBattery, us_setNewBattery] = useState({ type: '', value: 0 })
    const [us_ABPSettings, us_setABPSettings] = useState<TsInterface_ABPSettings>()

    // This useeffect gets all of the adjustable parameters in the table from the database upon page load
    // TODO: eventually Raphael wants to add support for different batteries
    useEffect(() => {
      const getABPData = async () => {
        const client = 'etw_energy'
        const recGroupData = await DatabaseGetCollection(DatabaseRef_ABPRecGroupValues_Collection(client))
        const recGroups = recGroupData.data

        const degradationData = await DatabaseGetDocument(DatabaseRef_ABPDegradation_Document(client))
        const degradation = degradationData.data.degradation

        const appFeeData = await DatabaseGetDocument(DatabaseRef_ABPApplicationFee_Document(client))
        const appFee = appFeeData.data.application_fee

        const ABPData: TsInterface_ABPSettings = { SREC_groups: recGroups, degradation: degradation, application_fee: appFee }

        return ABPData
      }

      const fetchData = async () => {
        if (uc_RootData_ClientKey !== null) {
          const abpData = await getABPData()

          us_setABPSettings(abpData)
        }
      }

      fetchData()
    }, [uc_RootData_ClientKey])

    useEffect(() => {
      const updateABPData = async () => {
        const client = 'etw_energy'
        // update the rec group values
        DatabaseSetMergeDocument(DatabaseRef_ABPRecGroupValues_Document(client, 'A'), {
          '<=10': us_ABPSettings?.SREC_groups.A['<=10'],
          '10-25': us_ABPSettings?.SREC_groups.A['10-25'],
          '>25': us_ABPSettings?.SREC_groups.A['>25'],
        })
        DatabaseSetMergeDocument(DatabaseRef_ABPRecGroupValues_Document(client, 'B'), {
          '<=10': us_ABPSettings?.SREC_groups.B['<=10'],
          '10-25': us_ABPSettings?.SREC_groups.B['10-25'],
          '>25': us_ABPSettings?.SREC_groups.B['>25'],
        })

        // update the degradation
        DatabaseSetMergeDocument(DatabaseRef_ABPDegradation_Document(client), {
          degradation: us_ABPSettings?.degradation,
        })

        // update the application fee
        DatabaseSetMergeDocument(DatabaseRef_ABPApplicationFee_Document(client), {
          application_fee: us_ABPSettings?.application_fee,
        })
      }

      if (us_ABPSettings && !us_isEditMode) {
        updateABPData()
      }
    }, [us_isEditMode, us_ABPSettings])

    const handleAddBatteryClick = () => {
      us_setNewBattery({ type: '', value: 0 })
      us_setAddBatteryOpen(true)
    }

    // const handleAddBatteryConfirm = () => {
    //   if (us_newBattery.type.trim()) {
    //     us_setABPSettings({
    //       ...us_ABPSettings,
    //       batteryOptions: [...us_ABPSettings.batteryOptions, { ...us_newBattery }],
    //     })
    //     us_setAddBatteryOpen(false)
    //   }
    // }

    const handleAddBatteryCancel = () => {
      us_setAddBatteryOpen(false)
    }

    const handleDeleteClick = (index: number) => {
      us_setBatteryToDelete(index)
      us_setDeleteConfirmOpen(true)
    }

    // const handleDeleteConfirm = () => {
    //   if (us_batteryToDelete !== null) {
    //     us_setABPSettings({
    //       ...us_ABPSettings,
    //       batteryOptions: us_ABPSettings.batteryOptions.filter((_, index) => index !== us_batteryToDelete),
    //     })
    //   }
    //   us_setDeleteConfirmOpen(false)
    //   us_setBatteryToDelete(null)
    // }

    const handleDeleteCancel = () => {
      us_setDeleteConfirmOpen(false)
      us_setBatteryToDelete(null)
    }

    if (uc_RootData_ClientKey != null) {
      return (
        <Box>
          <Card sx={{ borderRadius: 4 }}>
            <Box sx={{ p: 5 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => us_setIsEditMode(!us_isEditMode)}
                  startIcon={us_isEditMode ? <SaveIcon /> : <EditIcon />}
                >
                  {us_isEditMode ? 'Save Changes' : 'Edit Settings'}
                </Button>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          color: 'white',
                          borderBottom: '2px solid',
                          borderColor: 'primary.main',
                          py: 2,
                        }}
                      >
                        Group
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          color: 'white',
                          borderBottom: '2px solid',
                          borderColor: 'primary.main',
                          py: 2,
                        }}
                      >
                        Size
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          color: 'white',
                          borderBottom: '2px solid',
                          borderColor: 'primary.main',
                          py: 2,
                        }}
                      >
                        SREC Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {us_ABPSettings && (
                      <>
                        <TableRow>
                          <TableCell align="center">A</TableCell>
                          <TableCell align="center">0-10</TableCell>
                          <TableCell align="center">
                            {us_isEditMode ? (
                              <TextField
                                size="small"
                                type="number"
                                value={us_ABPSettings.SREC_groups.A['<=10']}
                                onChange={(e) => {
                                  const newValue = parseFloat(e.target.value)
                                  us_setABPSettings({
                                    ...us_ABPSettings,
                                    SREC_groups: {
                                      ...us_ABPSettings.SREC_groups,
                                      A: { ...us_ABPSettings.SREC_groups.A, '<=10': newValue },
                                    },
                                  })
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                              />
                            ) : (
                              `$${us_ABPSettings.SREC_groups.A['<=10']}`
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">A</TableCell>
                          <TableCell align="center">10-25</TableCell>
                          <TableCell align="center">
                            {us_isEditMode ? (
                              <TextField
                                size="small"
                                type="number"
                                value={us_ABPSettings.SREC_groups.A['10-25']}
                                onChange={(e) => {
                                  const newValue = parseFloat(e.target.value)
                                  us_setABPSettings({
                                    ...us_ABPSettings,
                                    SREC_groups: {
                                      ...us_ABPSettings.SREC_groups,
                                      A: { ...us_ABPSettings.SREC_groups.A, '10-25': newValue },
                                    },
                                  })
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                              />
                            ) : (
                              `$${us_ABPSettings.SREC_groups.A['10-25']}`
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">A</TableCell>
                          <TableCell align="center">25+</TableCell>
                          <TableCell align="center">
                            {us_isEditMode ? (
                              <TextField
                                size="small"
                                type="number"
                                value={us_ABPSettings.SREC_groups.A['>25']}
                                onChange={(e) => {
                                  const newValue = parseFloat(e.target.value)
                                  us_setABPSettings({
                                    ...us_ABPSettings,
                                    SREC_groups: {
                                      ...us_ABPSettings.SREC_groups,
                                      A: { ...us_ABPSettings.SREC_groups.A, '>25': newValue },
                                    },
                                  })
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                              />
                            ) : (
                              `$${us_ABPSettings.SREC_groups.A['>25']}`
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {us_ABPSettings && (
                      <>
                        <TableRow>
                          <TableCell align="center">B</TableCell>
                          <TableCell align="center">0-10</TableCell>
                          <TableCell align="center">
                            {us_isEditMode ? (
                              <TextField
                                size="small"
                                type="number"
                                value={us_ABPSettings.SREC_groups.B['<=10']}
                                onChange={(e) => {
                                  const newValue = parseFloat(e.target.value)
                                  us_setABPSettings({
                                    ...us_ABPSettings,
                                    SREC_groups: {
                                      ...us_ABPSettings.SREC_groups,
                                      B: { ...us_ABPSettings.SREC_groups.B, '<=10': newValue },
                                    },
                                  })
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                              />
                            ) : (
                              `$${us_ABPSettings.SREC_groups.B['<=10']}`
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">B</TableCell>
                          <TableCell align="center">10-25</TableCell>
                          <TableCell align="center">
                            {us_isEditMode ? (
                              <TextField
                                size="small"
                                type="number"
                                value={us_ABPSettings.SREC_groups.B['10-25']}
                                onChange={(e) => {
                                  const newValue = parseFloat(e.target.value)
                                  us_setABPSettings({
                                    ...us_ABPSettings,
                                    SREC_groups: {
                                      ...us_ABPSettings.SREC_groups,
                                      B: { ...us_ABPSettings.SREC_groups.B, '10-25': newValue },
                                    },
                                  })
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                              />
                            ) : (
                              `$${us_ABPSettings.SREC_groups.B['10-25']}`
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center">B</TableCell>
                          <TableCell align="center">25+</TableCell>
                          <TableCell align="center">
                            {us_isEditMode ? (
                              <TextField
                                size="small"
                                type="number"
                                value={us_ABPSettings.SREC_groups.B['>25']}
                                onChange={(e) => {
                                  const newValue = parseFloat(e.target.value)
                                  us_setABPSettings({
                                    ...us_ABPSettings,
                                    SREC_groups: {
                                      ...us_ABPSettings.SREC_groups,
                                      B: { ...us_ABPSettings.SREC_groups.B, '>25': newValue },
                                    },
                                  })
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                              />
                            ) : (
                              `$${us_ABPSettings.SREC_groups.B['>25']}`
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box sx={{ mt: 4 }}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      align="center"
                    >
                      Additional Settings
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {us_ABPSettings && (
                        <TextField
                          fullWidth
                          label="Degradation (%)"
                          type="number"
                          value={us_ABPSettings.degradation}
                          onChange={(e) => us_setABPSettings({ ...us_ABPSettings, degradation: parseFloat(e.target.value) })}
                          disabled={!us_isEditMode}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      )}
                      {us_ABPSettings && (
                        <TextField
                          fullWidth
                          label="Application Fee"
                          type="number"
                          value={us_ABPSettings.application_fee}
                          onChange={(e) => us_setABPSettings({ ...us_ABPSettings, application_fee: parseFloat(e.target.value) })}
                          disabled={!us_isEditMode}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      align="center"
                    >
                      Battery Options
                    </Typography>
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                        {/* {us_isEditMode && (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={handleAddBatteryClick}
                          >
                            Add Battery Option
                          </Button>
                        )} */}
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2, gap: 2 }}>
                        <Typography sx={{ minWidth: 50 }}>{'Tesla'}</Typography>
                        <TextField
                          type="number"
                          label="Value"
                          value={13.5}
                          onChange={(e) => {
                            // const newBatteryOptions = [...us_ABPSettings.batteryOptions]
                            // newBatteryOptions[index].value = parseFloat(e.target.value)
                            // us_setABPSettings({ ...us_ABPSettings })
                          }}
                          // disabled={!us_isEditMode}
                          disabled
                          size="small"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>

          {/* <Dialog
            open={us_deleteConfirmOpen}
            onClose={handleDeleteCancel}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>Are you sure you want to delete this battery option? This action cannot be undone.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCancel}>Cancel</Button>
              <Button
                onClick={handleDeleteConfirm}
                color="error"
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog> */}

          {/* <Dialog
            open={us_addBatteryOpen}
            onClose={handleAddBatteryCancel}
          >
            <DialogTitle>Add New Battery Option</DialogTitle>
            <DialogContent>
              <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  fullWidth
                  label="Battery Type"
                  value={us_newBattery.type}
                  onChange={(e) => us_setNewBattery({ ...us_newBattery, type: e.target.value })}
                  autoFocus
                />
                <TextField
                  fullWidth
                  type="number"
                  label="Value"
                  value={us_newBattery.value}
                  onChange={(e) => us_setNewBattery({ ...us_newBattery, value: parseFloat(e.target.value) || 0 })}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddBatteryCancel}>Cancel</Button>
              <Button
                onClick={handleAddBatteryConfirm}
                variant="contained"
                disabled={!us_newBattery.type.trim()}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog> */}
        </Box>
      )
    } else {
      return <></>
    }
  }

  const rJSX_NewPurchasePresetButton = (): JSX.Element => {
    let newButton = <></>
    newButton = (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        className="tw-mr-2"
        onClick={() => {
          window.open(ApplicationPages.AdminABPDisclosureIndexPage.url('etw_admin_purchase'), '_blank')
        }}
      >
        {rLIB('Create New Purchase Preset')}
      </Button>
    )
    return newButton
  }
  const rJSX_NewLeasePresetButton = (): JSX.Element => {
    let newButton = <></>
    newButton = (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        className="tw-mr-2"
        onClick={() => {
          window.open(ApplicationPages.AdminABPDisclosureIndexPage.url('etw_admin_lease'), '_blank')
        }}
      >
        {rLIB('Create New Lease Preset')}
      </Button>
    )
    return newButton
  }

  const rJSX_GreenhouseURLSwitch = (): JSX.Element => {
    const [isProduction, setIsProduction] = useState(true)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [pendingState, setPendingState] = useState(true)

    // Add useEffect to fetch initial value from database
    useEffect(() => {
      const fetchInitialValue = async () => {
        try {
          if (uc_RootData_ClientKey) {
            const doc = await DatabaseGetDocument(DatabaseRef_ABPGreenhouseURLIsProd_Document(uc_RootData_ClientKey))
            if (doc.data) {
              setIsProduction(doc.data.is_prod ?? true)
              setPendingState(doc.data.is_prod ?? true)
            }
          }
        } catch (error) {
          console.error('Error fetching Greenhouse URL environment setting:', error)
          // Default to production environment if there's an error
          setIsProduction(true)
          setPendingState(true)
        }
      }
      fetchInitialValue()
    }, [uc_RootData_ClientKey])

    const handleSwitchChange = (newState: boolean) => {
      setPendingState(newState)
      setConfirmDialogOpen(true)
    }

    const handleConfirm = async () => {
      setIsProduction(pendingState)
      setConfirmDialogOpen(false)

      // Update the database with the new value
      if (uc_RootData_ClientKey) {
        await DatabaseSetMergeDocument(DatabaseRef_ABPGreenhouseURLIsProd_Document(uc_RootData_ClientKey), { is_prod: pendingState })
      }
    }

    const handleCancel = () => {
      setPendingState(isProduction)
      setConfirmDialogOpen(false)
    }

    let newSwitch = <></>
    newSwitch = (
      <Box className="tw-flex-col tw-align-center">
        {rLIB('Use Greenhouse Prod URL')}
        <Switch
          checked={isProduction}
          onChange={(e) => handleSwitchChange(e.target.checked)}
        />
        <Dialog
          open={confirmDialogOpen}
          onClose={handleCancel}
        >
          <DialogTitle>Confirm Environment Change</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {pendingState
                ? 'You are switching to the Greenhouse production environment. This is not a testing environment and should only be used for real applications. Switching this will switch it for all users of the app, including those using the form creation pages. Are you sure you want to continue?'
                : 'You are switching to the Greenhouse staging environment. This is a testing environment and any forms created here will not be processed. Switching this will switch it for all users of the app, including those using the form creation pages. Are you sure you want to continue?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
    return newSwitch
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('ABP Disclosure')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminABPDisclosureIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('ABP Disclosure', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rLIB('ABP Form Presets'),
                  tabUrlKey: 'abp_presets',
                  tabButtons: [
                    { fullJSX: rJSX_NewPurchasePresetButton(), minJSX: rJSX_NewPurchasePresetButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewLeasePresetButton(), minJSX: rJSX_NewLeasePresetButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_GreenhouseURLSwitch(), minJSX: rJSX_GreenhouseURLSwitch(), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_ABPDisclosureTable()}</Box>,
                },
                {
                  tabHeader: rLIB('ABP Forms'),
                  tabUrlKey: 'abp_forms',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_ABPFormTable()}</Box>,
                },
                {
                  tabHeader: rLIB('ABP Settings'),
                  tabUrlKey: 'abp_settings',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_ABPSettings()}</Box>,
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  return rJSX_Page()
}

export default Container
