/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, useMediaQuery } from '@mui/material'
import { returnImageTags } from 'app/models/projects/project_image_tags'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_TaskFormProdPages_Document } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import {
  DatabaseRef_ProjectAdditionalImages_Collection,
  DatabaseRef_ProjectTaskFormData_Collection,
  DatabaseRef_ProjectTaskWorkflow_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { StorageRef_ProjectImageThumbnailFile, StorageRef_ProjectImageThumbnailsFolder } from 'rfbp_aux/services/storage_endpoints/projects'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_Snackbar,
} from 'rfbp_core/services/context'
import { DatabaseGetDocument, DatabaseGetLiveCollection, StorageGetDownloadUrl, StorageListFiles } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_PhotosTab } from '../project_view/tab_photos'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_PhotosTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
}

///////////////////////////////
// Variables
///////////////////////////////

const taskTagPrefix = 'TASK: '

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewPhotosTab = forwardRef((props: TsInterface_ProjectView_PhotosTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_PhotosTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_clientKey: TsInterface_ProjectView_PhotosTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_readOrWrite: TsInterface_ProjectView_PhotosTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [us_allTaskFormData, us_setAllTaskFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_availableImageTags, us_setAvailableImageTags] = useState<string[]>([])
  const [us_downloadingPhotos, us_setDownloadingPhotos] = useState<boolean>(false)
  const [us_filteredPhotosList, us_setFilteredPhotosList] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_flatImageTagCounts, us_setFlatImageTagCounts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_photosViewLevel, us_setPhotosViewLevel] = useState<string>('root') // root | task | page | subfolder
  const [us_photosViewType, us_setPhotosViewType] = useState<string>('tag') // folder | tag
  const [us_projectRootPhotosFolders, us_setProjectRootPhotosFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflow, us_setProjectTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_runningImageAnalysis, us_setRunningImageAnalysis] = useState<boolean>(false)
  const [us_screenSize, us_setScreenSize] = useState<string>('md')
  const [us_selectedAllImageFilterTag, us_setSelectedAllImagesFilterTag] = useState<boolean>(false)
  const [us_selectedImageFilterTags, us_setSelectedImageFilterTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolder, us_setSelectedPhotosTaskFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderData, us_setSelectedPhotosTaskFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderForm, us_setSelectedPhotosTaskFolderForm] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageFolder, us_setSelectedPhotosTaskPageFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageSubfolder, us_setSelectedPhotosTaskPageSubfolder] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  const [us_flatImageUploadData, us_setFlatImageUploadData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskKeysWithImages, us_setTaskKeysWithImages] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalImageData, us_setAdditionalImageData] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_projectImageThumbnails, us_setProjectImageThumbnails] = useState<TsInterface_UnspecifiedObject>({})
  const [us_reloadTaskWorkflow] = useState<number>(0)
  const umq_isExtraSmallScreen = useMediaQuery('(max-width: 400px)')
  const umq_isLargeScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)')
  const umq_isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)')
  const umq_isSmallScreen = useMediaQuery('(min-width: 401px) and (max-width: 600px)')
  const [us_loadedTaskFormPages, us_setLoadedTaskFormPages] = useState<boolean>(false)

  // Hooks - useEffect
  useEffect(() => {
    if (umq_isExtraSmallScreen === true) {
      us_setScreenSize('xs')
    } else if (umq_isSmallScreen === true) {
      us_setScreenSize('sm')
    } else if (umq_isMediumScreen === true) {
      us_setScreenSize('md')
    } else if (umq_isLargeScreen === true) {
      us_setScreenSize('lg')
    } else {
      us_setScreenSize('xl')
    }
    ur_forceRerender()
    return () => {}
  }, [umq_isExtraSmallScreen, umq_isSmallScreen, umq_isMediumScreen, umq_isLargeScreen, ur_forceRerender])

  useEffect(() => {
    if (
      us_selectedPhotosTaskFolder != null &&
      us_selectedPhotosTaskFolder.key != null &&
      us_projectTasks != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key] != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key'] != null &&
      us_loadedTaskFormPages === false
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          DatabaseGetDocument(
            DatabaseRef_TaskFormProdPages_Document(actualClientKey, us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key']),
          )
            .then((res_DGD) => {
              us_setSelectedPhotosTaskFolderForm(res_DGD.data)
              us_setLoadedTaskFormPages(true)
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedPhotosTaskFolderForm({})
    }
    return () => {}
  }, [
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    us_selectedPhotosTaskFolder,
    pr_projectKey,
    ur_forceRerender,
    us_projectTasks,
    us_loadedTaskFormPages,
    pr_clientKey,
  ])

  // TODO: Permissions
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllTaskFormData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectTaskFormData_Collection(actualClientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, ur_forceRerender, pr_clientKey])

  useEffect(() => {
    if (!uc_RootData_ClientKey || !pr_projectKey) {
      return
    }

    let unsubscribeAdditionalImages: TsType_VoidFunction

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        const additionalImagesRef = DatabaseRef_ProjectAdditionalImages_Collection(actualClientKey, pr_projectKey)

        unsubscribeAdditionalImages = DatabaseGetLiveCollection(additionalImagesRef, (newData) => {
          const additionalImages = objectToArray(newData)
          us_setAdditionalImageData(additionalImages)
        })
      })
      .catch((error) => console.error('Failed to load additional images:', error))

    return () => {
      if (typeof unsubscribeAdditionalImages === 'function') {
        unsubscribeAdditionalImages()
      }
    }
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, pr_clientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // TODO - merge with
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        let combinedTags = objectToArray({ ...returnImageTags(actualClientKey) })
        for (let loopTaskKey in us_taskKeysWithImages) {
          if (us_projectTasks != null && us_projectTasks[loopTaskKey] != null && us_projectTasks[loopTaskKey]['name'] != null) {
            combinedTags.push(taskTagPrefix + us_projectTasks[loopTaskKey]['name'])
          }
        }
        us_setAvailableImageTags(combinedTags)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_taskKeysWithImages, us_projectTasks, pr_clientKey])

  useEffect(() => {
    // Convert objects to arrays if necessary
    const flatImageUploadDataArray = Array.isArray(us_flatImageUploadData) ? us_flatImageUploadData : Object.values(us_flatImageUploadData)
    const projectImageThumbnailsArray = Array.isArray(us_projectImageThumbnails) ? us_projectImageThumbnails : Object.values(us_projectImageThumbnails)

    // Combine main image data and additional images without duplication
    const combinedImagesMap = new Map() // Use Map to store unique images

    ;[...flatImageUploadDataArray, ...us_additionalImageData].forEach((image) => {
      if (!combinedImagesMap.has(image.key)) {
        combinedImagesMap.set(image.key, image) // Add unique image to Map by its unique key
      }
    })

    // Convert map back to array for processing
    const combinedImages = Array.from(combinedImagesMap.values())
    let filteredImages: TsInterface_UnspecifiedObject[] = []

    combinedImages.forEach((loopImage) => {
      // Apply thumbnail if it exists
      projectImageThumbnailsArray.forEach((loopThumbnail) => {
        let fileNameWithoutExtension = loopImage.name.split('.').slice(0, -1).join('.')
        if (loopThumbnail.name.startsWith(fileNameWithoutExtension)) {
          loopImage.thumbnail_url = loopThumbnail.thumbnail_url
        }
      })

      // Filter based on tags and selection criteria
      if (us_selectedAllImageFilterTag) {
        filteredImages.push(loopImage)
      } else {
        let includePhotoInDisplay = objectToArray(us_selectedImageFilterTags).length > 0

        for (let loopTagKey in us_selectedImageFilterTags) {
          if (us_selectedImageFilterTags[loopTagKey] && (!loopImage.tags || !loopImage.tags.includes(loopTagKey))) {
            includePhotoInDisplay = false
            break
          }
        }

        if (includePhotoInDisplay) {
          filteredImages.push(loopImage)
        }
      }
    })

    us_setFilteredPhotosList(filteredImages)
    return () => {}
  }, [
    us_setFilteredPhotosList,
    us_flatImageUploadData,
    us_additionalImageData,
    us_selectedAllImageFilterTag,
    us_selectedImageFilterTags,
    us_projectImageThumbnails,
  ])

  useEffect(() => {
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          StorageListFiles(StorageRef_ProjectImageThumbnailsFolder(actualClientKey, pr_projectKey))
            .then((res_SLF) => {
              let thumbnailFiles: TsInterface_UnspecifiedObject = {}
              let promiseArray: TsType_UnknownPromise[] = []
              if (res_SLF != null && res_SLF.data != null && res_SLF.data.files != null) {
                for (let loopFileKey in res_SLF.data.files) {
                  thumbnailFiles[loopFileKey] = res_SLF.data.files[loopFileKey]
                  promiseArray.push(
                    StorageGetDownloadUrl(StorageRef_ProjectImageThumbnailFile(actualClientKey, pr_projectKey, loopFileKey))
                      .then((res_SGDU) => {
                        thumbnailFiles[loopFileKey]['thumbnail_url'] = getProp(res_SGDU, 'url', null)
                      })
                      .catch((rej_SGDU) => {
                        console.error(rej_SGDU)
                      }),
                  )
                }
              }
              Promise.all(promiseArray).finally(() => {
                us_setProjectImageThumbnails(thumbnailFiles)
              })
            })
            .catch((rej_SLF) => {
              console.error(rej_SLF)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(actualClientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setProjectTaskWorkflow(res_DGD.data)
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorkflow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorkflow, pr_clientKey])

  useEffect(() => {
    let tagCounts: TsInterface_UnspecifiedObject = { all_photos: 0 }
    let calculatedProjectRootPhotosFolders: TsInterface_UnspecifiedObject = {}
    let tasksWithImages: TsInterface_UnspecifiedObject = {}
    let taskFormImages: TsInterface_UnspecifiedObject[] = []

    // Iterate through task form images
    if (us_allTaskFormData != null) {
      for (let loopTaskKey in us_allTaskFormData) {
        let loopTaskData = us_allTaskFormData[loopTaskKey]
        for (let loopPageKey in loopTaskData) {
          let loopPageData = loopTaskData[loopPageKey]
          if (loopPageData?.folders) {
            for (let loopFolderKey in loopPageData.folders) {
              let loopFolder = loopPageData.folders[loopFolderKey]
              for (let loopFileUploadIndex in loopFolder) {
                let loopFileUpload = loopFolder[loopFileUploadIndex]
                if (loopFileUpload?.upload_type === 'image') {
                  tasksWithImages[loopTaskKey] = true
                  if (!calculatedProjectRootPhotosFolders[loopTaskKey]) {
                    calculatedProjectRootPhotosFolders[loopTaskKey] = { key: loopTaskKey, path: null }
                  }
                  loopFileUpload['TEMP_associated_task_key'] = loopTaskKey
                  loopFileUpload['TEMP_associated_page_key'] = loopPageKey
                  loopFileUpload['TEMP_associated_folder_key'] = loopFolderKey
                  loopFileUpload['TEMP_subfolder_name'] = loopFolder.name
                  loopFileUpload['TEMP_upload_index'] = loopFileUploadIndex
                  taskFormImages.push(loopFileUpload)
                  tagCounts['all_photos']++

                  if (!loopFileUpload.tags) loopFileUpload.tags = []
                  if (loopTaskKey && us_projectTasks?.[loopTaskKey]?.name && !loopFileUpload.tags.includes(taskTagPrefix + us_projectTasks[loopTaskKey].name)) {
                    loopFileUpload.tags.push(taskTagPrefix + us_projectTasks[loopTaskKey].name)
                  }

                  let includePhotoInCounts = true
                  for (let loopTagKey in us_selectedImageFilterTags) {
                    if (us_selectedImageFilterTags[loopTagKey] && !loopFileUpload.tags.includes(loopTagKey)) {
                      includePhotoInCounts = false
                    }
                  }
                  if (includePhotoInCounts) {
                    loopFileUpload.tags.forEach((tag: any) => {
                      if (!tagCounts[tag]) tagCounts[tag] = 0
                      tagCounts[tag]++
                    })
                  }
                }
              }
            }
          }
        }
      }
    }

    us_additionalImageData.forEach((image) => {
      taskFormImages.push(image)
      tagCounts['all_photos']++

      let includePhotoInCounts = true
      for (let loopTagKey in us_selectedImageFilterTags) {
        if (us_selectedImageFilterTags[loopTagKey] && (!image.tags || !image.tags.includes(loopTagKey))) {
          includePhotoInCounts = false
        }
      }
      if (includePhotoInCounts) {
        image.tags?.forEach((tag: any) => {
          if (!tagCounts[tag]) tagCounts[tag] = 0
          tagCounts[tag]++
        })
      }
    })

    us_setTaskKeysWithImages(tasksWithImages)
    us_setFlatImageUploadData(taskFormImages)
    us_setFlatImageTagCounts(tagCounts)
    us_setProjectRootPhotosFolders(calculatedProjectRootPhotosFolders)
    ur_forceRerender()
  }, [
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    us_allTaskFormData,
    ur_forceRerender,
    us_selectedImageFilterTags,
    us_projectTasks,
    us_additionalImageData,
  ])

  // Functions

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        {rJSX_PhotosTab(
          pr_readOrWrite,
          uc_RootData_ClientKey,
          us_allTaskFormData,
          us_availableImageTags,
          us_downloadingPhotos,
          us_filteredPhotosList,
          us_flatImageTagCounts,
          ur_forceRerender,
          us_photosViewLevel,
          us_photosViewType,
          pr_projectKey,
          us_projectRootPhotosFolders,
          us_projectTaskWorkflow,
          us_projectTasks,
          us_selectedAllImageFilterTag,
          us_selectedImageFilterTags,
          us_selectedPhotosTaskFolder,
          us_selectedPhotosTaskFolderData,
          us_selectedPhotosTaskFolderForm,
          us_selectedPhotosTaskPageFolder,
          us_selectedPhotosTaskPageSubfolder,
          us_setDownloadingPhotos,
          us_setPhotosViewLevel,
          us_setPhotosViewType,
          uc_setRootData_ClientKey,
          us_setSelectedAllImagesFilterTag,
          us_setSelectedImageFilterTags,
          us_setSelectedPhotosTaskFolder,
          us_setSelectedPhotosTaskFolderData,
          us_setSelectedPhotosTaskPageFolder,
          us_setSelectedPhotosTaskPageSubfolder,
          uc_setUserInterface_CustomDialogDisplay,
          uc_setUserInterface_ErrorDialogDisplay,
          uc_setUserInterface_FormDialogDisplay,
          uc_setUserInterface_AlertDialogDisplay,
          us_runningImageAnalysis,
          us_setRunningImageAnalysis,
          uc_setUserInterface_SnackbarDisplay,
          us_screenSize,
          selectedTags,
          setSelectedTags,
          uc_RootData_ClientUser,
        )}
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
