///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_MasterSKUs_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'materials', 'master_sku_items', 'main')
}

export const DatabaseRef_MasterSKU_Document = (clientKey: string, supplierKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'master_sku_items', 'main', supplierKey)
}

export const DatabaseRef_MasterSkusForSupplier_Query = (clientKey: string, supplierKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_supplier_key', comparator: '==', value: supplierKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'master_sku_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_MasterSkusBySKUNumber_Query = (clientKey: string, skuNumber: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_manufacturer_unique_identifier', comparator: '==', value: skuNumber }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'master_sku_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_MasterSkusBySKUName_Query = (clientKey: string, skuName: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'name', comparator: '==', value: skuName }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'master_sku_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_MasterSkuItem_ManufacturerIDAndSupplier_Query = (
  clientKey: string,
  manufacturerUniqueIdentifier: string,
  supplierKey: string,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_manufacturer_unique_identifier', comparator: '==', value: manufacturerUniqueIdentifier },
    { prop: 'associated_supplier_key', comparator: '==', value: supplierKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'master_sku_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}
