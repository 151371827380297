///////////////////////////////
// Imports
///////////////////////////////

import { Box, Tooltip } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_MasterSKU_Document } from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import { DatabaseRef_SupplierCategories_Document } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { formInputs_EditSku } from '../forms/form_edit_skus'
import { formInputs_SkuImageUpload } from '../forms/form_sku_image_upload'

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_MaterialSupplierSKUs: TsInterface_TableColumns = {
  manage: TableCellManage({
    edit: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Edit')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null && getProp(rowData, 'associated_supplier_key', null) != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              // Load Classifications and Categories
              let promiseArray: TsType_UnknownPromise[] = []
              let categoriesData: TsInterface_UnspecifiedObject = {}
              promiseArray.push(
                DatabaseGetDocument(DatabaseRef_SupplierCategories_Document(res_GCK.clientKey, getProp(rowData, 'associated_supplier_key', null)))
                  .then((res_DGD) => {
                    // categoriesData = res_DGD.data
                    for (let loopCategoryKey in getProp(res_DGD.data, 'associated_categories', {})) {
                      let loopCategory = getProp(res_DGD.data, 'associated_categories.' + loopCategoryKey, null)
                      if (loopCategory != null) {
                        categoriesData[loopCategoryKey] = { key: loopCategory, value: loopCategory }
                      }
                    }
                  })
                  .catch((rej_DGD) => {
                    console.error(rej_DGD)
                  }),
              )
              // After Data Loaded
              Promise.all(promiseArray).finally(() => {
                // Add Options to Form
                formInputs_EditSku['associated_category_name']['options'] = objectToArray(categoriesData)
                // Open Form Dialog
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {
                        clientKey: tableHooks.uc_RootData_ClientKey,
                        skuKey: rowData.key,
                        skuData: rowData,
                      },
                      formData: rowData,
                      formInputs: formInputs_EditSku,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let updateObject: TsInterface_UnspecifiedObject = {}
                          if (getProp(formSubmittedData, 'name', null) != null) {
                            updateObject['name'] = getProp(formSubmittedData, 'name', null)
                          }
                          if (getProp(formSubmittedData, 'pick_packaging_type', null) != null) {
                            updateObject['pick_packaging_type'] = getProp(formSubmittedData, 'pick_packaging_type', null)
                          }
                          if (getProp(formSubmittedData, 'associated_manufacturer_unique_identifier', null) != null) {
                            updateObject['associated_manufacturer_unique_identifier'] = getProp(
                              formSubmittedData,
                              'associated_manufacturer_unique_identifier',
                              null,
                            )
                          }
                          if (getProp(formSubmittedData, 'pick_unit_quantity_per_package', null) != null) {
                            updateObject['pick_unit_quantity_per_package'] = getProp(formSubmittedData, 'pick_unit_quantity_per_package', null)
                          }
                          if (getProp(formSubmittedData, 'pick_base_unit_of_measurement', null) != null) {
                            updateObject['pick_base_unit_of_measurement'] = getProp(formSubmittedData, 'pick_base_unit_of_measurement', null)
                          }
                          if (getProp(formSubmittedData, 'pick_estimated_price_per_package', null) != null) {
                            updateObject['pick_estimated_price_per_package'] = getProp(formSubmittedData, 'pick_estimated_price_per_package', null)
                          }
                          if (getProp(formSubmittedData, 'associated_category_name', null) != null) {
                            updateObject['associated_category_name'] = getProp(formSubmittedData, 'associated_category_name', null)
                          }
                          if (getProp(formSubmittedData, 'associated_classification_name', null) != null) {
                            updateObject['associated_classification_name'] = getProp(formSubmittedData, 'associated_classification_name', null)
                          }
                          DatabaseSetMergeDocument(
                            DatabaseRef_MasterSKU_Document(formAdditionalData.clientKey as string, formAdditionalData.skuKey as string),
                            updateObject,
                          )
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              reject(rej_DSMD)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'info',
                      formDialogHeaderText: (
                        <>
                          {rLIB('Edit')} {rowData.unique_identifier}
                        </>
                      ),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: rej_GCK.error,
              })
            })
        }
      },
    },
    activate: {
      icon: (
        <Icon
          type="solid"
          icon="wand-magic-sparkles"
        />
      ),
      label: <>{rLIB('Activate')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '!=',
            value: 'active',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          let updateObject = {
            status: 'active',
          }
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              DatabaseSetMergeDocument(DatabaseRef_MasterSKU_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                .then((res_DSMD) => {
                  // Nothing
                })
                .catch((rej_DSMD) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: rej_DSMD.error,
                  })
                })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: rej_GCK.error,
              })
            })
        }
      },
    },
    deactivate: {
      icon: (
        <Icon
          type="solid"
          icon="trash-xmark"
        />
      ),
      label: <>{rLIB('Deactivate')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'active',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          let updateObject = {
            status: 'inactive',
          }
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              DatabaseSetMergeDocument(DatabaseRef_MasterSKU_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                .then((res_DSMD) => {
                  // Nothing
                })
                .catch((rej_DSMD) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: rej_DSMD.error,
                  })
                })
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: rej_GCK.error,
              })
            })
        }
      },
    },
  }),
  TEMP_status: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // Icon JSX
        if (rowData != null && rowData.status === 'active') {
          cellJSX = (
            <Icon
              icon="play"
              sx={{ fontSize: '16px', color: themeVariables.success_main }}
              tooltip={rLIB('Active SKU')}
              tooltipPlacement="right"
            />
          )
        } else {
          cellJSX = (
            <Icon
              icon="pause"
              sx={{ fontSize: '16px', color: themeVariables.error_main }}
              tooltip={rLIB('Inactive SKU')}
              tooltipPlacement="right"
            />
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_image: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // Functions
        let openPhotoManagementDialog = () => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      clientKey: res_GCK.clientKey,
                      skuKey: rowData.key,
                      skuData: rowData,
                    },
                    formData: rowData,
                    formInputs: formInputs_SkuImageUpload,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {
                      submit_button_hide: true,
                    },
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        resolve({ success: true })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: rLIB('Upload SKU Photo'),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="cloud-arrow-up"
                      />
                    ),
                  },
                },
              })
            })
            .catch((rej_GCK) => {
              console.error(rej_GCK)
            })
        }
        // let autoGetImageFromUrl = () => {
        // // TODO - takes manual adjustment for different suppliers
        // // https://www.crawfordelectricsupply.com/product/detail/22702
        // if( rowData.unique_identifier != null ){
        // 	let url = "https://www.crawfordelectricsupply.com/product/detail/" + rowData.unique_identifier
        // 	getImageUrlFromDiv( url, "primaryMediaContainer" ).then( ( res_GIUFD ) => {
        // 		getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
        // 			console.log( res_GIUFD )
        // 			copyImageUrlToStorage( res_GCK.clientKey, res_GIUFD, rowData )
        // 		}).catch(( rej_GCK ) => {
        // 			console.error( rej_GCK )
        // 		})
        // 	}).catch( ( rej_GIUFD ) => {
        // 		console.error( rej_GIUFD )
        // 	})
        // }
        // }
        // Icon JSX
        if (rowData != null && rowData.photo_url != null) {
          cellJSX = (
            <Box
              onClick={() => {
                openPhotoManagementDialog()
              }}
            >
              <Icon
                icon="image"
                tooltip={rLIB('View Image')}
                tooltipPlacement="right"
                sx={{ fontSize: '16px', color: themeVariables.info_main }}
              />
            </Box>
          )
        } else {
          cellJSX = (
            <Icon
              icon="image-slash"
              sx={{ fontSize: '16px', color: themeVariables.gray_700 }}
              tooltip={rLIB('No image for SKU')}
              tooltipPlacement="right"
              onClick={() => {
                openPhotoManagementDialog()
              }}
            />
          )
          // cellJSX =
          // <Icon
          // 	icon="robot"
          // 	sx={{ fontSize: "16px", color: themeVariables.error_main }}
          // 	tooltip={ rLIB("No image for SKU") }
          // 	tooltipPlacement='right'
          // 	onClick={ () => {
          // 		autoGetImageFromUrl()
          // 	}}
          // />
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  unique_identifier: TableCellBasic('unique_identifier', rLIB('Unique Identifier'), 'unique_identifier'),
  // unique_identifier: {
  // header: {
  // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return rLIB("Unique Identifier") },
  // 	header_sort_by: "unique_identifier"
  // },
  // cell: {
  // 	cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
  // 		let cellJSX = <></>
  // 		cellJSX = <>{ rowData.unique_identifier } <a className="tw-text-info_main" href={ "https://www.crawfordelectricsupply.com/product/detail/" + rowData.unique_identifier } target="_blank" >LINK</a></>
  // 		return cellJSX
  // 	},
  // 	cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
  // 		let cellCSS = ""
  // 		return cellCSS
  // 	}
  // },
  // },
  status: TableCellBasic('status', rLIB('Status'), 'status'),
  // name: TableCellBasic('name', rLIB('Product Name'), 'name'),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Product Name')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.name != null) {
          const truncatedName: JSX.Element =
            getProp(rowData, 'name', null).length > 40 ? (
              <Box>
                <Tooltip title={getProp(rowData, 'name', null)}>
                  <Box>{`${getProp(rowData, 'name', null).substring(0, 40)}...`}</Box>
                </Tooltip>
              </Box>
            ) : (
              <>{getProp(rowData, 'name', null)}</>
            )
          cellJSX = <>{truncatedName}</>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  pick_packaging_type: TableCellBasic('pick_packaging_type', rLIB('Package'), 'pick_packaging_type'),
  pick_unit_quantity_per_package: TableCellBasic('pick_unit_quantity_per_package', rLIB('Quantity per Package'), 'pick_unit_quantity_per_package'),
  pick_base_unit_of_measurement: TableCellBasic('pick_base_unit_of_measurement', rLIB('UOM'), 'pick_base_unit_of_measurement'),
  pick_estimated_price_per_package: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Estimated Price')
      },
      header_sort_by: 'pick_estimated_price_per_package',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.pick_estimated_price_per_package != null) {
          cellJSX = <>{formatCurrency(parseFloat(rowData.pick_estimated_price_per_package as string))}</>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  associated_category_name: TableCellBasic('associated_category_name', rLIB('Category'), 'associated_category_name'),
  associated_classification_name: TableCellBasic('associated_classification_name', rLIB('Classification'), 'associated_classification_name'),
  associated_manufacturer_unique_identifier: TableCellBasic(
    'associated_manufacturer_unique_identifier',
    rLIB('Manufacturer Part Number'),
    'associated_manufacturer_unique_identifier',
  ),
  // key: TableCellBasic('key', rLIB('Key'), 'key'),
}

export const tableSettings_MaterialSupplierSKUs: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
}
