/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Dialog, DialogContent, Stack } from '@mui/material'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_ProjectImportSession_Projects_Query,
  DatabaseRef_ProjectImportSession_Settings_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { processIndividualStagingImportProject, projectImportMappingV2 } from '../services/project_imports_v2'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectList_ImportsNewTab {
  projectFilter: string
}

///////////////////////////////
// Variables
///////////////////////////////

const tableSettings_CurrentImportSessionProjects: TsInterface_TableSettings = {
  paginated: false,
  // pagination_rows_per_page_default: 100,
  // pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'asc',
  sort_property_default: 'associated_customer_name',
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectListImportsNewTab = forwardRef((props: TsInterface_ProjectList_ImportsNewTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectFilter: TsInterface_ProjectList_ImportsNewTab['projectFilter'] = getProp(props, 'projectFilter', 'all')

  // Hooks - useContext, useState, useReducer, other
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_tableColumns_CurrentImportSessionProjects, us_setTableColumns_CurrentImportSessionProjects] = useState<TsInterface_TableColumns>({})
  const [us_currentImportSession, us_setCurrentImportSession] = useState<TsInterface_UnspecifiedObject>({})
  const [us_currentImportSessionProjects, us_setCurrentImportSessionProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_visibleImportSessionProjects, us_setVisibleImportSessionProjects] = useState<TsInterface_UnspecifiedObject[]>([])
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCurrentImportSession(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectImportSession_Settings_Document(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCurrentImportSessionProjects(newData)
      ur_forceRerender()
    }
    if (us_currentImportSession != null && us_currentImportSession.current_import_session_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ProjectImportSession_Projects_Query(res_GCK.clientKey, us_currentImportSession.current_import_session_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_currentImportSession])

  useEffect(() => {
    if (pr_projectFilter === 'all') {
      us_setVisibleImportSessionProjects(objectToArray(us_currentImportSessionProjects))
    } else if (pr_projectFilter === 'successful') {
      us_setVisibleImportSessionProjects(
        objectToArray(us_currentImportSessionProjects).filter((project: TsInterface_UnspecifiedObject) => {
          return project.status === 'success'
        }),
      )
    } else if (pr_projectFilter === 'manual_review') {
      us_setVisibleImportSessionProjects(
        objectToArray(us_currentImportSessionProjects).filter((project: TsInterface_UnspecifiedObject) => {
          return project.status === 'warning'
        }),
      )
    } else if (pr_projectFilter === 'failed') {
      us_setVisibleImportSessionProjects(
        objectToArray(us_currentImportSessionProjects).filter((project: TsInterface_UnspecifiedObject) => {
          return project.status === 'error'
        }),
      )
    }
  }, [pr_projectFilter, us_currentImportSessionProjects])

  useEffect(() => {
    let tableColumns: TsInterface_TableColumns = {
      manage: TableCellManage({
        force_import_on_pending_status: {
          icon: (
            <Icon
              type="solid"
              icon="cloud-arrow-up"
            />
          ),
          conditional_display: {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '==',
            value: 'pending_import',
            conditions: [],
          },
          label: <>{rLIB('Try Import Again')}</>,
          onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            if (rowData.key != null) {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  processIndividualStagingImportProject(res_GCK.clientKey, rowData.key as string, getProp(rowData, 'data', {}), true)
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }
          },
        },
        force_import_with_job_code_generation: {
          icon: (
            <Icon
              type="solid"
              icon="cloud-arrow-up"
            />
          ),
          conditional_display: {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'substatus',
            comparator: '==',
            value: 'blocking_error_generating_job_code',
            conditions: [],
          },
          label: <>{rLIB('Force Import (attempt job code generation again)')}</>,
          onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            if (rowData.key != null) {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  processIndividualStagingImportProject(res_GCK.clientKey, rowData.key as string, getProp(rowData, 'data', {}), true)
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }
          },
        },
        force_import_with_missing_data: {
          icon: (
            <Icon
              type="solid"
              icon="cloud-arrow-up"
            />
          ),
          conditional_display: {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'substatus',
            comparator: '==',
            value: 'override_missing_data',
            conditions: [],
          },
          label: <>{rLIB('Force Import (with Missing Data)')}</>,
          onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            if (rowData.key != null) {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  processIndividualStagingImportProject(res_GCK.clientKey, rowData.key as string, getProp(rowData, 'data', {}), true)
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }
          },
        },
        force_import_duplicate_project: {
          icon: (
            <Icon
              type="solid"
              icon="cloud-arrow-up"
            />
          ),
          conditional_display: {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'substatus',
            comparator: '==',
            value: 'override_existing_project',
            conditions: [],
          },
          label: <>{rLIB('Force Import (Potentially Duplicate Project)')}</>,
          onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            if (rowData.key != null) {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  processIndividualStagingImportProject(res_GCK.clientKey, rowData.key as string, getProp(rowData, 'data', {}), true)
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }
          },
        },
        attempt_relink: {
          icon: (
            <Icon
              type="solid"
              icon="cloud-arrow-up"
            />
          ),
          conditional_display: {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'substatus',
            comparator: '==',
            value: 'blocking_error_unlinked_master_data',
            conditions: [],
          },
          label: <>{rLIB('Attempt Relink')}</>,
          onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            if (rowData.key != null) {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  processIndividualStagingImportProject(res_GCK.clientKey, rowData.key as string, getProp(rowData, 'data', {}), false)
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            }
          },
        },
        view_json: {
          icon: (
            <Icon
              type="solid"
              icon="code"
            />
          ),
          label: <>{rLIB('View Raw Data')}</>,
          onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            tableHooks.uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: (
                  <Dialog
                    className="bp_dialog_lg_width"
                    keepMounted
                    onClose={() => {
                      tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    }}
                    open={true}
                  >
                    <DialogContent sx={{ padding: '0px' }}>
                      <Json data={rowData} />
                    </DialogContent>
                  </Dialog>
                ),
                settings: {
                  max_width: 'lg',
                },
              },
            })
          },
        },
      }),
      status: {
        cell: {
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = <></>
            if (rowData.status == 'processing') {
              cellJSX = (
                <Stack direction="column">
                  <Box sx={{ color: themeVariables.gray_600 }}>
                    <Icon
                      icon="spinner"
                      className="bp_spin tw-mr-1"
                      tooltip={rLIB('Processing')}
                      tooltipPlacement="right"
                    />
                    {rLIB('Processing')}
                  </Box>
                  <Box sx={{ marginLeft: '18px' }}>
                    {objectToArray(getProp(rowData, 'import_notes', {}))
                      .sort()
                      .map((note: string, index: number) => {
                        return <Box key={index}>{note}</Box>
                      })}
                  </Box>
                </Stack>
              )
            } else if (rowData.status == 'pending_import') {
              cellJSX = (
                <Stack direction="column">
                  <Box sx={{ color: themeVariables.info_main }}>
                    <Icon
                      className="tw-mr-1"
                      tooltip={rLIB('Pending Import')}
                      tooltipPlacement="right"
                      icon="circle-play"
                    />
                    {rLIB('Pending Import')}
                  </Box>
                  <Box sx={{ marginLeft: '18px' }}>
                    {objectToArray(getProp(rowData, 'import_notes', {}))
                      .sort()
                      .map((note: string, index: number) => {
                        return <Box key={index}>{note}</Box>
                      })}
                  </Box>
                </Stack>
              )
            } else if (rowData.status == 'success') {
              cellJSX = (
                <Stack direction="column">
                  <Box sx={{ color: themeVariables.success_main }}>
                    <Icon
                      className="tw-mr-1"
                      icon="circle-check"
                      tooltip={rLIB('Successful Import')}
                      tooltipPlacement="right"
                    />
                    {rLIB('Successful Import')}
                  </Box>
                  <Box sx={{ marginLeft: '18px' }}>
                    {objectToArray(getProp(rowData, 'import_notes', {}))
                      .sort()
                      .map((note: string, index: number) => {
                        return <Box key={index}>{note}</Box>
                      })}
                  </Box>
                </Stack>
              )
            } else if (rowData.status == 'error') {
              cellJSX = (
                <Stack direction="column">
                  <Box sx={{ color: themeVariables.error_main }}>
                    <Icon
                      className="tw-mr-1"
                      icon="circle-exclamation"
                      tooltip={rLIB('Failed to Import')}
                      tooltipPlacement="right"
                    />
                    {rLIB('Failed to Import')}
                  </Box>
                  <Box sx={{ marginLeft: '18px' }}>
                    {objectToArray(getProp(rowData, 'import_notes', {}))
                      .sort()
                      .map((note: string, index: number) => {
                        return <Box key={index}>{note}</Box>
                      })}
                  </Box>
                </Stack>
              )
            } else if (rowData.status == 'warning') {
              cellJSX = (
                <Stack direction="column">
                  <Box sx={{ color: themeVariables.warning_main }}>
                    <Icon
                      className="tw-mr-1"
                      icon="circle-pause"
                      tooltip={rLIB('Needs Manual Review')}
                      tooltipPlacement="right"
                    />
                    {rLIB('Needs Manual Review')}
                  </Box>
                  <Box sx={{ marginLeft: '18px' }}>
                    {objectToArray(getProp(rowData, 'import_notes', {}))
                      .sort()
                      .map((note: string, index: number) => {
                        return <Box key={index}>{note}</Box>
                      })}
                  </Box>
                </Stack>
              )
            }
            return cellJSX
          },
        },
        header: {
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return <>{rLIB('Import Status')}</>
          },
          header_sort_by: 'status',
          // header_sort_by: 'data.' + loopFieldKey,
        },
      },
      action: {
        cell: {
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = <></>
            let desiredOperationType = getProp(rowData, 'match_information.desired_operation_type', null)
            // let foundMatchingProject = getProp(rowData, 'match_information.found_matching_project', null)
            if (desiredOperationType == 'create') {
              // if (foundMatchingProject === true) {
              //   // TODO: On click to show probable matches
              //   cellJSX = (
              //     <Box>
              //       <Icon
              //         sx={{ color: themeVariables.error_main }}
              //         icon="circle-exclamation"
              //         className="tw-mr-1"
              //         tooltipPlacement="top"
              //         tooltip={rLIB('A Project with a matching customer phone or email address was found')}
              //       />
              //       {rLIB('New Project')}
              //     </Box>
              //   )
              // } else if (foundMatchingProject === false) {
              //   cellJSX = (
              //     <Box>
              //       <Icon
              //         sx={{ color: themeVariables.success_main }}
              //         icon="circle-check"
              //         className="tw-mr-1"
              //         tooltipPlacement="top"
              //         tooltip={rLIB('New Project')}
              //       />
              //       {rLIB('New Project')}
              //     </Box>
              //   )
              // }
              cellJSX = <Box>{rLIB('New Project')}</Box>
            } else if (desiredOperationType == 'update') {
              // if (foundMatchingProject === true) {
              //   cellJSX = (
              //     <Box>
              //       <Icon
              //         sx={{ color: themeVariables.success_main }}
              //         icon="circle-check"
              //         className="tw-mr-1"
              //         tooltipPlacement="top"
              //         tooltip={rLIB('Update Project')}
              //       />
              //       {rLIB('Update Project')}
              //     </Box>
              //   )
              // } else if (foundMatchingProject === false) {
              //   cellJSX = (
              //     <Box>
              //       <Icon
              //         sx={{ color: themeVariables.error_main }}
              //         icon="circle-exclamation"
              //         className="tw-mr-1"
              //         tooltipPlacement="top"
              //         tooltip={rLIB('No projects with that job code found')}
              //       />
              //       {rLIB('Update Project')}
              //     </Box>
              //   )
              // }
              cellJSX = <Box>{rLIB('Update Project')}</Box>
            }
            return cellJSX
          },
        },
        header: {
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return <>{rLIB('Import Action')}</>
          },
          header_sort_by: null,
          // header_sort_by: 'data.' + loopFieldKey,
        },
      },
      links: {
        cell: {
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = <></>
            let redLinks = getProp(rowData, 'link_counts.red', 0)
            let yellowLinks = getProp(rowData, 'link_counts.yellow', 0)
            let greenLinks = getProp(rowData, 'link_counts.green', 0)
            let redJSX = <></>
            let yellowJSX = <></>
            let greenJSX = <></>
            if (redLinks > 0) {
              redJSX = (
                <Box sx={{ color: themeVariables.error_main }}>
                  {redLinks}
                  <Icon
                    icon="link"
                    className="tw-ml-1"
                  />
                </Box>
              )
            }
            if (yellowLinks > 0) {
              yellowJSX = (
                <Box sx={{ color: themeVariables.warning_main }}>
                  {yellowLinks}
                  <Icon
                    icon="link"
                    className="tw-ml-1"
                  />
                </Box>
              )
            }
            if (greenLinks > 0) {
              greenJSX = (
                <Box sx={{ color: themeVariables.success_main }}>
                  {greenLinks}
                  <Icon
                    icon="link"
                    className="tw-ml-1"
                  />
                </Box>
              )
            }
            cellJSX = (
              <Stack
                direction="row"
                spacing={1}
              >
                {redJSX} {yellowJSX} {greenJSX}
              </Stack>
            )
            return cellJSX
          },
        },
        header: {
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return <>{rLIB('Master Data Links')}</>
          },
          header_sort_by: null,
          // header_sort_by: 'data.' + loopFieldKey,
        },
      },
    }
    for (let loopFieldKey in projectImportMappingV2) {
      let loopField = projectImportMappingV2[loopFieldKey]
      tableColumns[loopFieldKey] = {
        cell: {
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = loopField.import_table_cell_render(rowData)
            return cellJSX
          },
        },
        header: {
          header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return ''
          },
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return <>{loopField.label}</>
          },
          header_sort_by: null,
          // header_sort_by: 'data.' + loopFieldKey,
        },
      }
    }
    us_setTableColumns_CurrentImportSessionProjects(tableColumns)
  }, [])

  // Functions

  // JSX Generation

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        <Card>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={us_tableColumns_CurrentImportSessionProjects}
            tableData={us_visibleImportSessionProjects}
            tableSettings={tableSettings_CurrentImportSessionProjects}
          />
        </Card>
        {/* <Json
          data={us_currentImportSessionProjects}
          alphabetized={true}
        /> */}
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
})
