/* eslint-disable react/prop-types */
import { AppBar, Box, Button, Card, Dialog, DialogContent, Divider, IconButton, MenuItem, Select, Toolbar, Tooltip, Typography } from '@mui/material'
import { getDocs } from 'firebase/firestore'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_MasterSkusForSupplier_Query } from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import { DatabaseRef_ProjectsByIdNumber_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_LoadingBar,
  Context_UserInterface_PromptDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import * as XLSX from 'xlsx'
import { Icon } from '../icons/icon'
import { TableBasic, TsInterface_TableColumns, TsInterface_TableDataRow, TsInterface_TableSettings } from '../table'

export interface TsInterface_AdvancedImportHooksObject {
  uc_RootData_ClientKey: any
  uc_RootData_ClientPermissions: any
  uc_RootData_ClientUser: any
  uc_RootData_GlobalUser: any
  uc_setRootData_ClientKey: any
  uc_setUserInterface_AlertDialogDisplay: any
  uc_setUserInterface_ConfirmDialogDisplay: any
  uc_setUserInterface_CustomDialogDisplay: any
  uc_setUserInterface_ErrorDialogDisplay: any
  uc_setUserInterface_FormDialogDisplay: any
  uc_setUserInterface_LoadingBarDisplay: any
  uc_setUserInterface_PromptDialogDisplay: any
  un_routerNavigation: any
  ur_forceRerender: any
  localClientKey?: any
  importAdditionalData?: TsInterface_UnspecifiedObject
}

export type TsType_PreImportChecks = (
  mappedData: TsInterface_UnspecifiedObject,
  rawData: TsInterface_UnspecifiedObject,
  importHooks: TsInterface_AdvancedImportHooksObject,
) => Promise<{
  success: boolean
  processedData: Array<{ docId: string; row: any }>
  summary: TsInterface_UnspecifiedObject
}>

export type TsType_ImportSubmission = (
  finalDataToImport: Array<{ docId: string; row: any }>,
  importHooks: TsInterface_AdvancedImportHooksObject,
) => TsType_UnknownPromise

export interface TsInterface_MaterialImportButtonAndDialog {
  importMappingOptions: {
    [key: string]: {
      automatch_properties: string[]
      key: string
      label: string | JSX.Element
      required: boolean
      possible_values?: string[]
    }
  }
  importSubmission: TsType_ImportSubmission
  preImportChecks: TsType_PreImportChecks
  importButtonText: string | JSX.Element
  importButtonColor: TsType_MuiComponentColors
  importButtonDisabled?: boolean
  importButtonShrink: boolean
  importDialogHeader: string | JSX.Element
  importAdditionalData?: TsInterface_UnspecifiedObject
}

interface TsInterface_MaterialImportDialog {
  importMappingOptions: TsInterface_MaterialImportButtonAndDialog['importMappingOptions']
  importDialogHeader: JSX.Element
  importHooks: TsInterface_AdvancedImportHooksObject
  importRawData: TsInterface_UnspecifiedObject
  importRawHeaders: string[]
  importSubmission: TsType_ImportSubmission
  preImportChecks: TsType_PreImportChecks
  importAdditionalData: TsInterface_UnspecifiedObject
}

const tableSettings_ImportPreview: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 10,
  pagination_rows_per_page_options: [10, 25, 50, 10],
  show_header: true,
  size: 'small',
  sortable: false,
}

const parseDateString = (dateString: string): Date | null =>
  !dateString
    ? null
    : (() => {
        const d = new Date(dateString)
        return isNaN(d.getTime()) ? null : d
      })()

const convertExcelDate = (excelDateValue: any): string => {
  let serialNumber: number
  if (typeof excelDateValue === 'number') {
    serialNumber = excelDateValue
  } else {
    serialNumber = parseFloat(excelDateValue)
  }
  if (isNaN(serialNumber)) {
    return String(excelDateValue)
  }
  const jsDate = new Date((serialNumber - 25569) * 86400 * 1000)
  const year = jsDate.getFullYear()
  const month = String(jsDate.getMonth() + 1).padStart(2, '0')
  const day = String(jsDate.getDate()).padStart(2, '0')
  return `${year}/${month}/${day}`
}

export const buildUniqueKey = (rowData: any): string => {
  const cleanup = (val: any): string => {
    const asString = String(val ?? '').trim()
    return asString.replace(/\s+/g, '_').replace(/\//g, '_')
  }

  const supplierName = String(rowData.associated_supplier_name ?? '')
  const invoiceRef = String(rowData.invoice_reference_number ?? '')
  const projectId = String(rowData.associated_project_id_number ?? '')
  const primarySkuNumber = String(rowData.sku_number ?? '').trim()
  const primarySkuDesc = String(rowData.sku_description ?? '').trim()
  const tempSkuNumber = String(rowData.temp_sku_number ?? '').trim()
  const tempSkuDesc = String(rowData.temp_sku_description ?? '').trim()

  let finalSku: string = ''
  if (primarySkuNumber && primarySkuNumber.toLowerCase() !== 'missing') {
    finalSku = primarySkuNumber
  } else if (primarySkuDesc && primarySkuDesc.toLowerCase() !== 'missing') {
    finalSku = primarySkuDesc
  } else if (tempSkuNumber && tempSkuNumber.toLowerCase() !== 'missing') {
    finalSku = tempSkuNumber
  } else if (tempSkuDesc && tempSkuDesc.toLowerCase() !== 'missing') {
    finalSku = tempSkuDesc
  } else {
    finalSku = 'missing_sku'
  }
  const supp = cleanup(supplierName).toUpperCase().slice(0, 3)
  const inv = cleanup(invoiceRef).toUpperCase()
  const jobClean = cleanup(projectId).toUpperCase()
  const skuClean = cleanup(finalSku).toUpperCase()
  const last4 = jobClean.slice(-4)
  const combined = `${supp}_${inv}_${last4}_${skuClean}`
  return combined || 'fallback_missing_key'
}

function findClosestScheduledDate(task: any, targetDate: Date): string | null {
  if (!Array.isArray(task.task_completion_scheduled_dates)) return null
  let minDiff = Number.MAX_SAFE_INTEGER
  let chosenDate: string | null = null
  for (const scheduled of task.task_completion_scheduled_dates) {
    const scheduledDate = parseDateString(scheduled)
    if (!scheduledDate) continue
    const diff = Math.abs(scheduledDate.getTime() - targetDate.getTime())
    if (diff < minDiff) {
      minDiff = diff
      chosenDate = scheduled
    }
  }
  return chosenDate
}

// Helper to normalize strings: remove all non-alphanumeric characters and convert to lowercase.
// Also safely handles non-string values by casting them to strings
const normalizeString = (value: any): string => {
  if (value === null || value === undefined) return ''

  // Cast to string if not already a string
  const strValue = typeof value === 'string' ? value : String(value)
  return strValue.toLowerCase().replace(/[^a-z0-9]/g, '')
}

export const performPreImportChecks: TsType_PreImportChecks = async (
  mappedData: { [rowKey: string]: any },
  rawData: { [rowKey: string]: any },
  importHooks: any,
) => {
  const finalRows: Array<{ docId: string; row: any }> = []
  let skippedQtyZero = 0,
    skippedNoProject = 0,
    skippedNoTask = 0,
    tasksFound = 0,
    teamsFound = 0,
    missingSkuCount = 0

  const clientKey = importHooks.localClientKey || importHooks.uc_RootData_ClientKey
  if (!clientKey) {
    return { success: false, processedData: [], summary: { error: 'No clientKey found' } }
  }
  const allRowKeys = Object.keys(mappedData)
  const cleanup = (value: any): string => (value != null ? String(value).replace(/\s+/g, '_').replace(/\//g, '_') : '')
  let masterSkus: any[] = []
  const supplierKey = importHooks.importAdditionalData?.associated_supplier_key || ''
  if (supplierKey) {
    try {
      const masterSkuSnap = await getDocs(DatabaseRef_MasterSkusForSupplier_Query(clientKey, supplierKey))
      masterSkus = masterSkuSnap.empty ? [] : masterSkuSnap.docs.map((doc) => doc.data())
    } catch (err) {
      console.error('Error fetching master SKUs for supplier', supplierKey, err)
    }
  }

  const combinedRows: {
    [compositeKey: string]: {
      rowData: any
      localDate: Date | null
    }
  } = {}

  for (const rowKey of allRowKeys) {
    const rowData = mappedData[rowKey] || {}
    const rawRowData = rawData[rowKey] || {}
    rowData.invoice_reference_number = rowData.invoice_reference_number ?? ''
    rowData.sku_number = rowData.sku_number ?? ''
    rowData.sku_description = rowData.sku_description ?? ''
    rowData.quantity = rowData.quantity ?? 0
    rowData.unit_price = rowData.unit_price ?? 0
    rowData.total_price = rowData.total_price ?? 0
    rowData.invoice_date = rowData.invoice_date ?? ''
    rowData.associated_project_id_number = rowData.associated_project_id_number ?? ''
    rowData.associated_po_number =
      typeof rowData.associated_po_number === 'string' && rowData.associated_po_number.trim() !== '' ? rowData.associated_po_number.trim() : 'missing'
    rowData.shipping_costs = rowData.shipping_costs ?? 0
    rowData.taxes = rowData.taxes ?? 0
    rowData.ship_date = rowData.ship_date ?? null
    rowData.due_by = rowData.due_by ?? null
    rowData.ship_mode = rowData.ship_mode ?? null
    rowData.item_amount = rowData.item_amount ?? 0

    const qty = parseFloat(rowData.quantity)
    if (!rowData.quantity || isNaN(qty) || qty <= 0) {
      skippedQtyZero++
      continue
    }

    //For Crawford
    let candidateProjId = ''
    if (supplierKey === 'LXYEUvHezEaq82TeYUio') {
      // First try to use RELEASE_NUMBER if available
      if (typeof rawRowData.RELEASE_NUMBER === 'string' && rawRowData.RELEASE_NUMBER.trim() !== '') {
        candidateProjId = rawRowData.RELEASE_NUMBER.trim()
      }
      // If RELEASE_NUMBER is not available or empty, use PO_NUMBER
      if (!candidateProjId && typeof rawRowData.PO_NUMBER === 'string' && rawRowData.PO_NUMBER.trim() !== '') {
        candidateProjId = rawRowData.PO_NUMBER.trim()
      }
    } else {
      // For other suppliers, use associated_project_id_number
      if (typeof rowData.associated_project_id_number === 'string' && rowData.associated_project_id_number.trim() !== '') {
        candidateProjId = rowData.associated_project_id_number.trim()
      }
    }
    // Convert to uppercase for consistency
    candidateProjId = candidateProjId || ''
    candidateProjId = candidateProjId.toUpperCase()
    if (!candidateProjId) {
      candidateProjId = 'MISSING'
      skippedNoProject++
    }
    rowData.associated_project_id_number = candidateProjId

    let targetDateLocal: Date | null = null
    if (rowData.invoice_date) {
      const dateRaw = String(rowData.invoice_date).trim()
      if (dateRaw.includes('/')) {
        targetDateLocal = parseDateString(dateRaw)
      } else {
        const serial = parseFloat(dateRaw)
        if (!isNaN(serial)) {
          targetDateLocal = new Date((serial - 25569) * 86400 * 1000)
        }
      }
    }
    rowData.invoice_date = rowData.invoice_date ? convertExcelDate(rowData.invoice_date) : ''

    if (rowData.item_amount === 0) {
      const unitPrice = parseFloat(rowData.unit_price) || 0
      rowData.item_amount = qty * unitPrice
    }
    if (rowData.taxes != null) {
      const taxInput = parseFloat(rowData.taxes)
      if (!isNaN(taxInput) && taxInput < 0.25) {
        rowData.taxes = (parseFloat(rowData.item_amount) || 0) * taxInput
      }
    }
    if (!rowData.total_price) {
      const itemAmt = parseFloat(rowData.item_amount) || 0
      const taxesDollar = parseFloat(rowData.taxes) || 0
      const shippingVal = parseFloat(rowData.shipping_costs) || 0
      rowData.total_price = itemAmt + taxesDollar + shippingVal
    }

    const originalSkuNumber = String(rowData.sku_number ?? '').trim()
    const originalSkuDescription = String(rowData.sku_description ?? '').trim()
    if (originalSkuNumber || originalSkuDescription) {
      rowData.temp_sku_number = originalSkuNumber
      rowData.temp_sku_description = originalSkuDescription
    }

    let matchedMaster: any = null
    if (originalSkuNumber) {
      const normalizedImportSku = normalizeString(originalSkuNumber)
      matchedMaster = masterSkus.find((master: any) => {
        // Ensure masterSku is always a string
        const masterSku = String(master.unique_identifier || '')
        return normalizeString(masterSku) === normalizedImportSku
      })
    }
    if (!matchedMaster && originalSkuDescription) {
      const normalizedImportDesc = normalizeString(originalSkuDescription)
      matchedMaster = masterSkus.find((master: any) => {
        // Ensure masterName is always a string
        const masterName = String(master.name || '')
        return normalizeString(masterName) === normalizedImportDesc
      })
    }
    if (matchedMaster) {
      rowData.sku_number = matchedMaster.unique_identifier || originalSkuNumber || 'missing'
      rowData.sku_description = matchedMaster.name || originalSkuDescription || 'missing'
      rowData.associated_sku_key = matchedMaster.key || 'missing'
    } else {
      rowData.sku_number = 'missing'
      rowData.sku_description = 'missing'
      rowData.associated_sku_key = 'missing'
      missingSkuCount++
    }

    const primarySku = rowData.sku_number
    const secondarySku = rowData.temp_sku_number
    const tertiarySku = rowData.temp_sku_description

    let finalSkuValue = ''
    if (primarySku && primarySku !== 'missing') {
      finalSkuValue = primarySku
    } else if (secondarySku && secondarySku !== 'missing') {
      finalSkuValue = secondarySku
    } else if (tertiarySku && tertiarySku !== 'missing') {
      finalSkuValue = tertiarySku
    } else {
      finalSkuValue = `NO_SKU_${rowKey}`
    }

    const invoiceClean = cleanup(rowData.invoice_reference_number).toUpperCase()
    const projClean = cleanup(candidateProjId).toUpperCase()
    const skuClean = cleanup(finalSkuValue).toUpperCase()
    const compositeKey = `${invoiceClean}_${projClean}_${skuClean}`

    if (!combinedRows[compositeKey]) {
      combinedRows[compositeKey] = {
        rowData,
        localDate: targetDateLocal,
      }
    } else {
      const existing = combinedRows[compositeKey]
      const existingRow = existing.rowData

      existingRow.quantity = (Number(existingRow.quantity) || 0) + (Number(rowData.quantity) || 0)
      existingRow.taxes = (Number(existingRow.taxes) || 0) + (Number(rowData.taxes) || 0)
      existingRow.shipping_costs = (Number(existingRow.shipping_costs) || 0) + (Number(rowData.shipping_costs) || 0)

      const newItemAmount = (Number(existingRow.item_amount) || 0) + (Number(rowData.item_amount) || 0)
      existingRow.item_amount = newItemAmount
      existingRow.total_price = newItemAmount + (existingRow.taxes || 0) + (existingRow.shipping_costs || 0)
    }
  }

  const uniqueProjectIds: any = new Set()
  Object.values(combinedRows).forEach((entry) => {
    const rowData = entry.rowData
    if (rowData.associated_project_id_number && rowData.associated_project_id_number !== 'MISSING') {
      uniqueProjectIds.add(rowData.associated_project_id_number)
    }
  })

  const projectMapping: { [candidateProjId: string]: string } = {}
  for (const projId of uniqueProjectIds) {
    try {
      const projSnap = await getDocs(DatabaseRef_ProjectsByIdNumber_Query(clientKey, projId))
      if (!projSnap.empty) {
        projectMapping[projId] = projSnap.docs[0].id
      } else {
        projectMapping[projId] = 'missing'
        skippedNoProject++
      }
    } catch (err) {
      console.error(`Error querying project for ${projId}:`, err)
      projectMapping[projId] = 'missing'
      skippedNoProject++
    }
  }

  Object.values(combinedRows).forEach((entry) => {
    const rowData = entry.rowData
    const cpid = rowData.associated_project_id_number
    rowData.associated_project_key = cpid === 'MISSING' ? 'missing' : projectMapping[cpid] || 'missing'
  })

  const projectKeysUsed: any = new Set()
  Object.values(combinedRows).forEach((entry) => {
    const rowData = entry.rowData
    if (rowData.associated_project_key && rowData.associated_project_key !== 'missing') {
      projectKeysUsed.add(rowData.associated_project_key)
    }
  })

  const tasksByProjectKey: { [pKey: string]: Array<{ id: string; data: any }> } = {}
  for (const projectKey of projectKeysUsed) {
    try {
      const tasksSnap = await getDocs(DatabaseRef_AllProjectTasks_Query(clientKey, projectKey))
      if (!tasksSnap.empty) {
        tasksByProjectKey[projectKey] = tasksSnap.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      } else {
        tasksByProjectKey[projectKey] = []
      }
    } catch (err) {
      console.error(`Error querying tasks for project ${projectKey}:`, err)
      tasksByProjectKey[projectKey] = []
    }
  }

  Object.values(combinedRows).forEach((entry) => {
    const rowData = entry.rowData
    const localDate = entry.localDate

    if (rowData.associated_project_key === 'missing' || !localDate || !tasksByProjectKey[rowData.associated_project_key]) {
      rowData.associated_task_key = 'missing'
      rowData.associated_task_name = 'missing'
      rowData.associated_team_key = 'missing'
      rowData.associated_team_name = 'missing'
      return
    }

    const tasksArray = tasksByProjectKey[rowData.associated_project_key] || []
    if (!tasksArray.length) {
      rowData.associated_task_key = 'missing'
      rowData.associated_task_name = 'missing'
      rowData.associated_team_key = 'missing'
      rowData.associated_team_name = 'missing'
      skippedNoTask++
      return
    }

    let smallestDiff = Number.MAX_SAFE_INTEGER
    let ambiguous = false
    let foundTaskKey: string | null = null
    let foundTaskDoc: any = null

    for (const t of tasksArray) {
      const tdata = t.data
      const maybeClosest = findClosestScheduledDate(tdata, localDate)
      if (!maybeClosest) continue

      const scheduledDate = parseDateString(maybeClosest)
      if (!scheduledDate || scheduledDate.getTime() < localDate.getTime()) continue

      const diff = scheduledDate.getTime() - localDate.getTime()
      if (diff < smallestDiff) {
        smallestDiff = diff
        foundTaskKey = t.id
        foundTaskDoc = tdata
        ambiguous = false
      } else if (diff === smallestDiff) {
        ambiguous = true
      }
    }

    if (ambiguous || !foundTaskKey) {
      rowData.associated_task_key = 'missing'
      rowData.associated_task_name = 'missing'
      rowData.associated_team_key = 'missing'
      rowData.associated_team_name = 'missing'
      skippedNoTask++
    } else {
      rowData.associated_task_key = foundTaskKey
      rowData.associated_task_name = foundTaskDoc?.name || ''
      rowData.associated_team_key = foundTaskDoc?.associated_team_key || ''
      rowData.associated_team_name = foundTaskDoc?.associated_team_name || ''
      tasksFound++
      teamsFound++
    }
  })

  Object.values(combinedRows).forEach((entry) => {
    const rowData = entry.rowData
    let docId = buildUniqueKey(rowData)
    docId = docId.replace(/\//g, '_')
    finalRows.push({ docId: `preCheck_${docId}`, row: rowData })
  })

  const summary = {
    totalRows: allRowKeys.length,
    finalRows: finalRows.length,
    skippedQtyZero,
    skippedNoProject,
    skippedNoTask,
    missingSkuCount,
    tasksFound,
    teamsFound,
  }

  return { success: true, processedData: finalRows, summary }
}

const MaterialImportDialog: React.FC<TsInterface_MaterialImportDialog> = ({
  importMappingOptions,
  importDialogHeader,
  importHooks,
  importRawData,
  importRawHeaders,
  importSubmission,
  preImportChecks,
  importAdditionalData,
}): JSX.Element => {
  const { uc_RootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_importMapping, us_setImportMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_invertedMapping, us_setInvertedMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_mappedData, us_setMappedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tableColumns, us_setTableColumns] = useState<TsInterface_TableColumns>({})
  const [us_automappingAttemptComplete, us_setAutomappingAttemptComplete] = useState<boolean>(false)
  const [us_hasRunPreImportChecks, us_setHasRunPreImportChecks] = useState<boolean>(false)
  const [us_preImportInProgress, us_setPreImportInProgress] = useState<boolean>(false)
  const [us_preImportSummary, us_setPreImportSummary] = useState<TsInterface_UnspecifiedObject>({})
  const [us_preImportProcessedData, us_setPreImportProcessedData] = useState<Array<{ docId: string; row: any }>>([])
  const [us_isImporting, us_setIsImporting] = useState<boolean>(false)

  useEffect(() => {
    if (!us_automappingAttemptComplete) {
      const initialMapping: TsInterface_UnspecifiedObject = {}
      for (const rawHeader of importRawHeaders) {
        for (const fieldKey in importMappingOptions) {
          if (importMappingOptions[fieldKey].automatch_properties.includes(rawHeader)) {
            initialMapping[rawHeader] = fieldKey
            break
          }
        }
      }
      us_setImportMapping(initialMapping)
      us_setAutomappingAttemptComplete(true)
      ur_forceRerender()
    }
  }, [importMappingOptions, importRawHeaders, us_automappingAttemptComplete, ur_forceRerender])

  useEffect(() => {
    const inverted: TsInterface_UnspecifiedObject = {}
    for (const rawHeader in us_importMapping) {
      if (us_importMapping[rawHeader]) {
        inverted[us_importMapping[rawHeader]] = rawHeader
      }
    }
    us_setInvertedMapping(inverted)
  }, [us_importMapping])

  useEffect(() => {
    const newMapped: TsInterface_UnspecifiedObject = {}
    for (const rowKey in importRawData) {
      const row = importRawData[rowKey]
      const mappedRow: TsInterface_UnspecifiedObject = {}
      for (const rawHeader in us_importMapping) {
        const mappedField = us_importMapping[rawHeader]
        if (mappedField) {
          mappedRow[mappedField] = row[rawHeader]
        }
      }
      newMapped[rowKey] = mappedRow
    }
    us_setMappedData(newMapped)
  }, [importRawData, us_importMapping])

  const renderMappingSummary = () => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5, mb: 2 }}>
        {Object.keys(importMappingOptions).map((fieldKey) => {
          const field = importMappingOptions[fieldKey]
          const isMapped = Boolean(us_invertedMapping[fieldKey])
          return (
            <Box
              key={fieldKey}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                p: 1,
                borderRadius: '16px',
                backgroundColor: isMapped ? 'rgba(76,175,80,0.1)' : 'rgba(244,67,54,0.1)',
                border: `1px solid ${isMapped ? 'green' : 'red'}`,
              }}
            >
              <Icon
                icon={isMapped ? 'check' : 'xmark'}
                sx={{ color: isMapped ? 'green' : 'red', fontSize: 16 }}
              />
              <Typography variant="body2">{field.label}</Typography>
            </Box>
          )
        })}
      </Box>
    )
  }
  useEffect(() => {
    const sortedHeaders = [...importRawHeaders].sort((a, b) => {
      const aMapped = us_importMapping[a]
      const bMapped = us_importMapping[b]
      if (aMapped && !bMapped) return -1
      if (!aMapped && bMapped) return 1
      return 0
    })
    const newCols: TsInterface_TableColumns = {}
    sortedHeaders.forEach((rawHeader) => {
      newCols[rawHeader] = {
        header: {
          header_jsx: () => (
            <Box>
              <Box>
                <Select
                  className="bp_thin_select_input"
                  color="primary"
                  value={us_importMapping[rawHeader] || ''}
                  onChange={(event: any) => {
                    const newVal = event.target.value
                    us_setImportMapping((prev) => ({
                      ...prev,
                      [rawHeader]: newVal === '' ? null : newVal,
                    }))
                  }}
                  variant="outlined"
                >
                  <MenuItem
                    value=""
                    sx={{ color: themeVariables.error_light }}
                  >
                    <em>Remove Mapping</em>
                  </MenuItem>
                  {Object.keys(importMappingOptions).map((fieldKey) => {
                    const field = importMappingOptions[fieldKey]
                    const isDisabled = us_invertedMapping[fieldKey] && us_invertedMapping[fieldKey] !== rawHeader
                    return (
                      <MenuItem
                        key={fieldKey}
                        value={fieldKey}
                        disabled={isDisabled}
                      >
                        {field.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
              <Divider className="tw-my-2" />
              <Box sx={{ fontWeight: 'bold', opacity: us_importMapping[rawHeader] ? 1 : 0.5 }}>{rawHeader}</Box>
            </Box>
          ),
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow) => <Box sx={{ opacity: us_importMapping[rawHeader] ? 1 : 0.5 }}>{rowData[rawHeader]}</Box>,
        },
      }
    })
    us_setTableColumns(newCols)
  }, [importMappingOptions, importRawHeaders, us_importMapping, us_invertedMapping])

  function allRequiredFieldsMapped(): boolean {
    for (const fieldKey in importMappingOptions) {
      if (importMappingOptions[fieldKey].required) {
        if (!us_invertedMapping[fieldKey]) return false
      }
    }
    return true
  }

  const handlePreImportChecks = () => {
    us_setPreImportInProgress(true)
    const newHooks = { ...importHooks, localClientKey: uc_RootData_ClientKey }
    preImportChecks(us_mappedData, importRawData, newHooks)
      .then((res) => {
        us_setPreImportInProgress(false)
        if (res.success) {
          us_setPreImportProcessedData(res.processedData)
          us_setPreImportSummary(res.summary)
          us_setHasRunPreImportChecks(true)
        } else {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              code: 'PRE-IMPORT-CHECKS',
              message: 'Pre-Import Checks failed',
              details: JSON.stringify(res, null, 2),
            },
          })
        }
      })
      .catch((err) => {
        us_setPreImportInProgress(false)
        console.error('PreImportChecks error:', err)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            code: 'PRE-IMPORT-CHECKS-EXCEPTION',
            message: 'Pre-Import Checks threw an exception',
            details: JSON.stringify(err, null, 2),
          },
        })
      })
  }

  const handleConfirmImport = () => {
    if (!us_hasRunPreImportChecks) return
    us_setIsImporting(true)
    const processedDataWithSupplier = Array.isArray(us_preImportProcessedData)
      ? us_preImportProcessedData.map((item: any) => ({
          ...item,
          row: {
            ...item.row,
            associated_supplier_key: importAdditionalData.associated_supplier_key,
            associated_supplier_name: importAdditionalData.associated_supplier_name,
          },
        }))
      : us_preImportProcessedData
    importSubmission(processedDataWithSupplier, { ...importHooks, localClientKey: uc_RootData_ClientKey })
      .then(() => {
        us_setIsImporting(false)
        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        ur_forceRerender()
      })
      .catch((err) => {
        us_setIsImporting(false)
        console.error('importSubmission error:', err)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            code: 'FINAL-IMPORT-ERROR',
            message: 'Import Submission failed',
            details: JSON.stringify(err, null, 2),
          },
        })
      })
  }

  const rJSX_PreImportSummary = () => {
    if (!us_hasRunPreImportChecks) return null

    const { totalRows = 0, finalRows = 0, skippedQtyZero = 0, skippedNoProject = 0, missingSkuCount = 0 } = us_preImportSummary

    const rowsCombined = Math.max(0, totalRows - skippedQtyZero - finalRows)

    return (
      <Card sx={{ m: '8px', p: '8px' }}>
        <Typography
          variant="h6"
          gutterBottom
        >
          {rLIB('Pre-Import Summary')}
        </Typography>
        <Box sx={{ ml: '16px', mb: '8px' }}>
          <Box>
            •{' '}
            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              {rLIB('Total Rows:')}
            </Typography>{' '}
            {totalRows}
          </Box>

          <Box>
            •{' '}
            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              {rLIB('Skipped (Qty=0):')}
            </Typography>{' '}
            {skippedQtyZero}
          </Box>

          <Box>
            •{' '}
            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              {rLIB('Missing Project:')}
            </Typography>{' '}
            {skippedNoProject}
          </Box>

          <Box>
            •{' '}
            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              {rLIB('Missing SKU:')}
            </Typography>{' '}
            {missingSkuCount}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Box
              component="span"
              sx={{ mr: 0.5 }}
            >
              •
            </Box>

            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 'bold', display: 'inline-flex', alignItems: 'center' }}
            >
              {rLIB('Rows Combined:')}
            </Typography>
            <Box
              component="span"
              sx={{ ml: 1, mr: 1 }}
            >
              {rowsCombined}
            </Box>
            <Tooltip
              title="Rows are combined when they share the same Project ID, Invoice Number, and SKU Number."
              arrow
            >
              <IconButton
                size="small"
                sx={{ color: 'text.secondary' }}
                aria-label="rows combined info"
              >
                <Icon icon="info-circle" />
              </IconButton>
            </Tooltip>
          </Box>

          <Box>
            •{' '}
            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              {rLIB('Final Rows:')}
            </Typography>{' '}
            {finalRows}
          </Box>
        </Box>
      </Card>
    )
  }

  const rJSX_BottomActions = () => {
    const canRunPreImport = allRequiredFieldsMapped()
    const importButtonDisabled = !us_hasRunPreImportChecks || us_isImporting
    return (
      <>
        {!us_hasRunPreImportChecks && (
          <Button
            variant="contained"
            color="info"
            disabled={!canRunPreImport || us_preImportInProgress}
            onClick={handlePreImportChecks}
            sx={{ marginRight: '8px' }}
          >
            {us_preImportInProgress ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="gears" />
            )}
            <Box sx={{ marginLeft: '4px' }}>{rLIB('Run Pre-Import Checks')}</Box>
          </Button>
        )}
        {us_hasRunPreImportChecks && (
          <Button
            variant="contained"
            color="success"
            onClick={handleConfirmImport}
            disabled={importButtonDisabled}
          >
            {us_isImporting ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="cloud-arrow-up" />
            )}
            <Box sx={{ marginLeft: '4px' }}>Confirm Import</Box>
          </Button>
        )}
      </>
    )
  }

  return (
    <Dialog
      className="bp_dialog_xl_width"
      keepMounted
      onClose={() => uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)}
      open={true}
    >
      <AppBar
        position="static"
        color="info"
      >
        <Toolbar>
          <IconButton
            aria-label="menu"
            color="inherit"
            disabled
            edge="start"
            size="large"
            sx={{ mr: 2, color: '#fff !important' }}
          >
            <Icon icon="upload" />
          </IconButton>
          <Typography
            component="span"
            variant="h6"
            sx={{ flexGrow: 1 }}
          >
            <Box className="tw-inline-block">{importDialogHeader}</Box>
          </Typography>
          {rJSX_BottomActions()}
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ padding: '8px' }}>
        {renderMappingSummary()}
        {rJSX_PreImportSummary()}

        <Card sx={{ margin: '8px' }}>
          {us_hasRunPreImportChecks ? (
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={finalColumns}
              tableData={us_preImportProcessedData.map((item) => item.row)}
              tableSettings={tableSettings_ImportPreview}
            />
          ) : (
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns}
              tableData={objectToArray(importRawData)}
              tableSettings={tableSettings_ImportPreview}
            />
          )}
        </Card>
      </DialogContent>
    </Dialog>
  )
}
export const finalColumns: TsInterface_TableColumns = {
  invoice_reference_number: {
    header: {
      header_jsx: () => <Box>Invoice Number</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.invoice_reference_number}</Box>,
    },
  },
  invoice_date: {
    header: {
      header_jsx: () => <Box>Invoice Date</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.invoice_date}</Box>,
    },
  },
  associated_project_id_number: {
    header: {
      header_jsx: () => <Box>Project Job Code</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.associated_project_id_number}</Box>,
    },
  },
  associated_po_number: {
    header: {
      header_jsx: () => <Box>PO Number</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.associated_po_number}</Box>,
    },
  },
  sku_number: {
    header: {
      header_jsx: () => <Box>SKU Number</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.sku_number}</Box>,
    },
  },
  sku_description: {
    header: {
      header_jsx: () => <Box>SKU Description</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.sku_description}</Box>,
    },
  },

  temp_sku_number: {
    header: {
      header_jsx: () => <Box> Raw SKU Number</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.temp_sku_number}</Box>,
    },
  },
  temp_sku_description: {
    header: {
      header_jsx: () => <Box> Raw SKU Description</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.temp_sku_description}</Box>,
    },
  },
  quantity: {
    header: {
      header_jsx: () => <Box>Qty</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.quantity}</Box>,
    },
  },
  unit_price: {
    header: {
      header_jsx: () => <Box>Unit Price</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.unit_price}</Box>,
    },
  },
  item_amount: {
    header: {
      header_jsx: () => <Box>Item Amount</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.item_amount}</Box>,
    },
  },
  shipping_costs: {
    header: {
      header_jsx: () => <Box>Shipping</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.shipping_costs}</Box>,
    },
  },
  taxes: {
    header: {
      header_jsx: () => <Box>Taxes</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.taxes}</Box>,
    },
  },
  total_price: {
    header: {
      header_jsx: () => <Box>Total Price</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.total_price}</Box>,
    },
  },
  // If you want to show the team, task, etc.
  associated_task_name: {
    header: {
      header_jsx: () => <Box>Task Name</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.associated_task_name}</Box>,
    },
  },
  associated_team_name: {
    header: {
      header_jsx: () => <Box>Team Name</Box>,
    },
    cell: {
      cell_jsx: (rowData) => <Box>{rowData.associated_team_name}</Box>,
    },
  },
}

const MaterialImportButtonAndDialog: React.FC<TsInterface_MaterialImportButtonAndDialog> = (props) => {
  const pr_importMappingOptions = getProp(props, 'importMappingOptions', {})
  const pr_preImportChecks = getProp(props, 'preImportChecks', performPreImportChecks)
  const pr_importSubmission = getProp(props, 'importSubmission', () => Promise.resolve({ success: true }))
  const pr_importAdditionalData = getProp(props, 'importAdditionalData', {})
  const pr_importButtonText = getProp(props, 'importButtonText', 'Material Import')
  const pr_importButtonColor = getProp(props, 'importButtonColor', 'info')
  const pr_importButtonDisabled = getProp(props, 'importButtonDisabled', false)
  const pr_importButtonShrink = getProp(props, 'importButtonShrink', false)
  const pr_importDialogHeader = getProp(props, 'importDialogHeader', 'Material Import')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_LoadingBarDisplay } = useContext(Context_UserInterface_LoadingBar)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  const importHooks: TsInterface_AdvancedImportHooksObject = {
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    uc_RootData_ClientPermissions,
    uc_RootData_ClientUser,
    uc_RootData_GlobalUser,
    uc_setUserInterface_AlertDialogDisplay,
    uc_setUserInterface_ConfirmDialogDisplay,
    uc_setUserInterface_CustomDialogDisplay,
    uc_setUserInterface_ErrorDialogDisplay,
    uc_setUserInterface_FormDialogDisplay,
    uc_setUserInterface_LoadingBarDisplay,
    uc_setUserInterface_PromptDialogDisplay,
    un_routerNavigation,
    ur_forceRerender,
    importAdditionalData: pr_importAdditionalData,
  }

  const openSpreadsheetCustomDialog = (event: React.ChangeEvent<HTMLInputElement>): TsType_UnknownPromise =>
    new Promise((resolve, reject) => {
      if (event?.target?.files?.length) {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (readEvent) => {
          if (typeof readEvent?.target?.result === 'string') {
            try {
              const binaryString = readEvent.target.result
              const workbook = XLSX.read(binaryString, { type: 'binary' })
              const sheetName = workbook.SheetNames[0]
              const sheet = workbook.Sheets[sheetName]
              const originalParsedData: any[] = XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                blankrows: false, // skip empty rows
                defval: null,
              })

              // Always assume headers are in row 1 (index 0) and data starts on row 2 (index 1)
              const headerRowIndex = 0
              const dataRowIndex = 1

              const headersArray: string[] = originalParsedData[headerRowIndex] || []
              const dataRows: any[] = originalParsedData.slice(dataRowIndex)

              const spreadsheetObjectData: TsInterface_UnspecifiedObject = {}
              dataRows.forEach((row, idx) => {
                const rowKey = (idx + 1).toString()
                spreadsheetObjectData[rowKey] = {}
                headersArray.forEach((colProp, colIdx) => {
                  const colValue = row[colIdx]
                  if (colValue != null && colValue !== '') {
                    spreadsheetObjectData[rowKey][colProp] = colValue
                  }
                })
              })

              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <MaterialImportDialog
                      importMappingOptions={pr_importMappingOptions}
                      importDialogHeader={pr_importDialogHeader}
                      importHooks={importHooks}
                      importRawData={spreadsheetObjectData}
                      importRawHeaders={headersArray}
                      importSubmission={pr_importSubmission}
                      preImportChecks={pr_preImportChecks}
                      importAdditionalData={pr_importAdditionalData}
                    />
                  ),
                  settings: { max_width: 'lg' },
                },
              })

              resolve({ success: true })
            } catch (err) {
              console.error('Parsing error:', err)
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  code: 'SPREADSHEET-PARSE',
                  message: 'Failed to parse spreadsheet',
                  details: JSON.stringify(err, null, 2),
                },
              })
              reject({ success: false, error: err })
            }
          } else {
            const error = {
              code: 'SPREADSHEET-READ',
              message: 'Failed to read file',
              details: 'Unknown error occurred',
            }
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
            reject({ success: false, error })
          }
        }
        reader.readAsBinaryString(file)
      } else {
        const error = {
          code: 'NO-FILE-SELECTED',
          message: 'No file selected',
          details: 'Please select a valid spreadsheet',
        }
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
        reject({ success: false, error })
      }
    })

  const rJSX_ImportButton = () => {
    if (!pr_importButtonShrink) {
      return (
        <FileUploadButton
          multiple={false}
          button={{
            text: pr_importButtonText,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                className=""
                sx={{ fontSize: '20px' }}
              />
            ),
            color: pr_importButtonColor,
            className: '',
            variant: 'contained',
            disabled: pr_importButtonDisabled,
          }}
          accept=".xlsx, .csv"
          onChange={openSpreadsheetCustomDialog}
          additionalFileUploadParams={pr_importAdditionalData || {}}
        />
      )
    } else {
      return (
        <FileUploadButton
          multiple={false}
          button={{
            text: <></>,
            icon: (
              <Icon
                icon="cloud-arrow-up"
                sx={{ fontSize: '20px' }}
              />
            ),
            color: pr_importButtonColor,
            className: 'bp_icon_only_button ',
            variant: 'contained',
            disabled: pr_importButtonDisabled,
          }}
          accept=".xlsx, .csv"
          onChange={openSpreadsheetCustomDialog}
          additionalFileUploadParams={pr_importAdditionalData || {}}
        />
      )
    }
  }

  return <>{rJSX_ImportButton()}</>
}

export default MaterialImportButtonAndDialog
