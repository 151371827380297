///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Material_Imports_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main')
}

export const DatabaseRef_Material_Imports_Document = (clientKey: string, importKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main', importKey)
}

export const DatabaseRef_Imports_Missing_Tasks = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_task_key', comparator: '==', value: 'missing' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Imports_Missing_PO_Number = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_po_number', comparator: '==', value: 'missing' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Imports_Missing_SKU_Number = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'sku_number', comparator: '==', value: 'missing' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Material_Imports_By_Project = (clientKey: string, projectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Imports_Missing_Projects = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: 'missing' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Material_Imports_By_Invoice_Number = (clientKey: string, invoiceNumber: string): Query<unknown> => {
  const queryOperatorsArray: any[] = [{ prop: 'invoice_reference_number', comparator: '==', value: invoiceNumber }]
  const orderByArray: any[] = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

// Same thing but for an invoiceNumber that is numeric
export const DatabaseRef_Material_Imports_By_Invoice_Number_AsNumber = (clientKey: string, invoiceNumber: number): Query<unknown> => {
  const queryOperatorsArray: any[] = [{ prop: 'invoice_reference_number', comparator: '==', value: invoiceNumber }]
  const orderByArray: any[] = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Material_Imports_Sessions_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'materials', 'material_import_sessions', 'main')
}

export const DatabaseRef_Material_Imports_Session_Document = (clientKey: string, importKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'material_import_sessions', 'main', importKey)
}

///////////////////////////////
// Invoice Management Endpoints
///////////////////////////////

export const DatabaseRef_Processed_Invoices_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'materials', 'processed_invoices', 'main')
}

export const DatabaseRef_Processed_Invoice_Document = (clientKey: string, invoiceKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'processed_invoices', 'main', invoiceKey)
}

export const DatabaseRef_Processed_Invoices_By_Status = (clientKey: string, status: 'paid' | 'rejected'): Query<unknown> => {
  const queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: status }]
  const orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'processed_invoices', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_Processed_Invoices_By_Date_Range = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  // Convert dates to strings in YYYY/MM/DD format for invoice_date comparison
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };
  
  const startDateStr = formatDate(startDate);
  const endDateStr = formatDate(endDate);
  
  const queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'invoice_date', comparator: '>=', value: startDateStr },
    { prop: 'invoice_date', comparator: '<=', value: endDateStr },
  ]

  const orderByArray: TsInterface_OrderByArray = [{ prop: 'invoice_date', desc: true }]

  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'processed_invoices', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_Processed_Invoices_By_Invoice_Number = (clientKey: string, invoiceNumber: string | number): Query<unknown> => {
  const queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'invoice_number', comparator: '==', value: invoiceNumber }]
  const orderByArray: TsInterface_OrderByArray = []

  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'processed_invoices', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

// Query imports by supplier name
export const DatabaseRef_Material_Imports_By_Supplier = (clientKey: string, supplierName: string): Query<unknown> => {
  const queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_supplier_name', comparator: '==', value: supplierName }]
  const orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'imports', 'main'), 
    queryOperatorsArray, 
    orderByArray, 
    {}, 
    null
  )
}
