///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material/'
import { tableColumns_AllSalesProjects, tableSettings_AllSalesProjects } from 'app/models/projects/project_table'
import { returnTaskRows } from 'app/models/tasks'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesHierarchyUser_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_hierarchies'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflowsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  DatabaseRef_ActiveProjectsForSpecificWorkflow_Query,
  DatabaseRef_ActiveProjectTimestampsForSpecificWorkflow_Query,
  DatabaseRef_Projects_Collection,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnTimestampFromUnknownDateFormat } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { rJSX_ProjectPhasesTabContent } from '../projects/components/milestone_dashboard'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesProjectsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ACCOUNT_NOT_SET_UP_TO_VIEW_PROJECTS: JSX.Element = <Trans>Account not set up to view projects</Trans>
const s_ACTIVE: JSX.Element = <Trans>Active</Trans>
const s_ALL_USERS: JSX.Element = <Trans>All Users</Trans>
const s_CANCELLED: JSX.Element = <Trans>Cancelled</Trans>
const s_COMPLETED: JSX.Element = <Trans>Completed</Trans>
const s_CONTACT_SUPPORT_TO_GET_ACCOUNT_ACCESS_CONFIGURED: JSX.Element = <Trans>Contact support to get account access configured</Trans>
const s_JUST_USERS_PROJECTS: JSX.Element = <Trans>Just User's Projects</Trans>
const s_ON_HOLD: JSX.Element = <Trans>On hold</Trans>
const s_PROJECT_STATUS: JSX.Element = <Trans>Project Status</Trans>
const s_QUERY_DEPTH: JSX.Element = <Trans>Query Depth</Trans>
const s_TABLE_STATUS_FILTER: JSX.Element = <Trans>Table Status Filter</Trans>
const s_USER_AND_ALL_REPORTS: JSX.Element = <Trans>User and All Reports</Trans>
const s_USER_AND_DIRECT_REPORTS: JSX.Element = <Trans>User and Direct Reports</Trans>
const s_USER_FILTER: JSX.Element = <Trans>User Filter</Trans>
const se_PROJECTS: string = 'Projects'
// { sort-end } - displayed text

// Table Status Filter Options
let projectStatusOptions: TsInterface_UnspecifiedObject = {
  active: { key: 'active', value: s_ACTIVE, disabled: false },
  on_hold: { key: 'on_hold', value: s_ON_HOLD, disabled: false },
  cancelled: { key: 'cancelled', value: s_CANCELLED, disabled: false },
  completed: { key: 'completed', value: s_COMPLETED, disabled: false },
}

const formInputs_TableStatusFilter: TsInterface_FormInputs = {
  status: {
    key: 'status',
    label: s_PROJECT_STATUS,
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(projectStatusOptions),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_filterByWorkflow, us_setFilterByWorkflow] = useState<boolean>(true)
  const [us_filteredUserEmail, us_setFilteredUserEmail] = useState<string>('ALL_USERS')
  const [us_phaseActiveProjects, us_setPhaseActiveProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseWorkflowKey, us_setPhaseWorkflowKey] = useState<string | null>('')
  const [us_phaseWorkflowTasks, us_setPhaseWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectsSortedByMilestone, us_setProjectsSortedByMilestone] = useState<TsInterface_UnspecifiedObject>({})
  const [us_queryDepth, us_setQueryDepth] = useState<string>('just_user')
  const [us_salesHierarchyUsers, us_setSalesHierarchyUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_sortedMilestonePhaseTasksArray, us_setSortedMilestonePhaseTasksArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_tableFilterStatus, us_setTableFilterStatus] = useState<string>('active')
  const [us_taskWorkflowsArray, us_setTaskWorkflowsArray] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_taskWorkflowsObject, us_setTaskWorkflowsObject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_phaseActiveProjectTimestampsData, us_setPhaseActiveProjectTimestampsData] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_PROJECTS
  }, [])

  useEffect(() => {
    us_setFilterByWorkflow(true)
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (us_phaseWorkflowKey != null && us_phaseWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_ActiveProjectTimestampsForSpecificWorkflow_Query(res_GCK.clientKey, us_phaseWorkflowKey))
            .then((res_DGD) => {
              us_setPhaseActiveProjectTimestampsData(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_phaseWorkflowKey])

  useEffect(() => {
    if (
      uc_RootData_ClientUser != null &&
      uc_RootData_ClientUser.associated_sales_partner_key != null &&
      uc_RootData_ClientUser.associated_sales_partner_key !== ''
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_SalesHierarchyUser_Collection(res_GCK.clientKey, uc_RootData_ClientUser.associated_sales_partner_key as string))
            .then((res_DGC) => {
              let salesHierarchyUsersObject: TsInterface_UnspecifiedObject = {}
              for (let loopUserKey in res_DGC.data) {
                let user = res_DGC.data[loopUserKey]
                if (user.email != null) {
                  salesHierarchyUsersObject[user.email] = user
                }
              }
              us_setSalesHierarchyUsers(salesHierarchyUsersObject)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
              us_setSalesHierarchyUsers({})
              ur_forceRerender()
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          us_setSalesHierarchyUsers({})
          ur_forceRerender()
        })
    }
  }, [uc_RootData_ClientUser, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            let allTaskWorkflowsObject: TsInterface_UnspecifiedObject = res_DGC.data
            let workflowsObject: TsInterface_UnspecifiedObject = {}
            let workflowsArray: TsInterface_UnspecifiedObject[] = []
            for (let loopTaskWorkflowKey in allTaskWorkflowsObject) {
              let loopTaskWorkflow = allTaskWorkflowsObject[loopTaskWorkflowKey]
              if (
                loopTaskWorkflow &&
                loopTaskWorkflow.key != null &&
                loopTaskWorkflow.name != null &&
                loopTaskWorkflow.associated_sales_partner_key != null &&
                uc_RootData_ClientUser != null &&
                uc_RootData_ClientUser.associated_sales_partner_key != null &&
                uc_RootData_ClientUser.associated_sales_partner_key === loopTaskWorkflow.associated_sales_partner_key
              ) {
                workflowsObject[loopTaskWorkflow.key] = loopTaskWorkflow
                workflowsArray.push({ key: loopTaskWorkflow.key, value: loopTaskWorkflow.name })
              }
            }
            us_setTaskWorkflowsObject(workflowsObject)
            us_setTaskWorkflowsArray(workflowsArray)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_RootData_ClientUser, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (
      us_phaseWorkflowKey != null &&
      us_phaseWorkflowKey !== '' &&
      us_taskWorkflowsObject != null &&
      us_taskWorkflowsObject[us_phaseWorkflowKey] != null &&
      us_taskWorkflowsObject[us_phaseWorkflowKey]['filter_milestones'] != null &&
      us_phaseWorkflowTasks != null &&
      us_phaseWorkflowTasks['tasks'] != null
    ) {
      // Order Tasks
      let sortedMilestoneTasks: TsInterface_UnspecifiedObject[] = []
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_phaseWorkflowTasks['tasks']))
      let sortedTaskRows = returnTaskRows(us_phaseWorkflowTasks['tasks'], {}, tasksThatReferenceThemselves, ['Active Projects List useEffect'])
      // Loop through grouped rows
      let loopIndex = 0
      for (let loopRowKey in sortedTaskRows) {
        let loopRow = sortedTaskRows[loopRowKey]
        if (loopRow != null && loopRow['tasks'] != null) {
          for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
            let loopTask = loopRow['tasks'][loopTaskIndex]
            if (loopTask != null && loopTask.key != null && us_taskWorkflowsObject[us_phaseWorkflowKey]['filter_milestones'][loopTask.key] === true) {
              sortedMilestoneTasks.push({
                name: getProp(loopTask, 'name', null),
                key: getProp(loopTask, 'key', null),
                index: loopIndex,
              })
              loopIndex++
            }
          }
        }
      }
      us_setSortedMilestonePhaseTasksArray(sortedMilestoneTasks)
    }
  }, [us_filterByWorkflow, us_phaseWorkflowKey, us_taskWorkflowsObject, us_phaseWorkflowTasks])

  useEffect(() => {
    if (us_phaseWorkflowKey != null && us_phaseWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, us_phaseWorkflowKey))
            .then((res_DGD) => {
              us_setPhaseWorkflowTasks(res_DGD.data)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_phaseWorkflowKey])

  useEffect(() => {
    if (
      us_phaseWorkflowKey != null &&
      us_phaseWorkflowKey !== '' &&
      us_phaseActiveProjects != null &&
      us_sortedMilestonePhaseTasksArray != null &&
      us_sortedMilestonePhaseTasksArray.length > 0
    ) {
      let projectsSortedByMilestone: TsInterface_UnspecifiedObject = {}
      // Loop through milestones and create starting project milestone object
      for (let loopMilestoneIndex in us_sortedMilestonePhaseTasksArray) {
        let loopMilestone = us_sortedMilestonePhaseTasksArray[loopMilestoneIndex]
        projectsSortedByMilestone[loopMilestone.key] = {}
      }
      let firstMilestoneTaskKey = us_sortedMilestonePhaseTasksArray[0].key
      // Loop through projects and determine where they fall in the milestone array
      for (let loopProjectKey in us_phaseActiveProjects) {
        let loopProject = us_phaseActiveProjects[loopProjectKey]
        let projectLeastFarMilestoneIndex = us_sortedMilestonePhaseTasksArray.length
        let projectLeastFarMilestoneKey = null
        if (loopProject != null && loopProject.task_statuses != null) {
          let foundMilestoneLocation = false
          for (let loopMilestoneTaskIndex in us_sortedMilestonePhaseTasksArray) {
            let loopMilestoneTask = getProp(us_sortedMilestonePhaseTasksArray, loopMilestoneTaskIndex, {})
            let loopMilestoneTaskKey = getProp(loopMilestoneTask, 'key', '')
            if (loopProject != null && loopProject['task_statuses'] != null && loopProject['task_statuses'][loopMilestoneTaskKey] !== true) {
              if (parseInt(loopMilestoneTaskIndex) <= projectLeastFarMilestoneIndex) {
                projectLeastFarMilestoneIndex = parseInt(loopMilestoneTaskIndex)
                projectLeastFarMilestoneKey = loopMilestoneTaskKey
                foundMilestoneLocation = true
              }
            }
          }
          if (foundMilestoneLocation === true && projectLeastFarMilestoneKey != null) {
            projectsSortedByMilestone[projectLeastFarMilestoneKey][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
          } else {
            projectsSortedByMilestone[firstMilestoneTaskKey][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
          }
        } else {
          projectsSortedByMilestone[firstMilestoneTaskKey][loopProjectKey] = us_phaseActiveProjects[loopProjectKey]
        }
      }
      // Determine Days spent in phase
      let currentDate = new Date()
      for (let loopMilestoneKey in projectsSortedByMilestone) {
        let loopMilestone = projectsSortedByMilestone[loopMilestoneKey]
        for (let loopProjectKey in loopMilestone) {
          let loopProjectTimestamps = getProp(us_phaseActiveProjectTimestampsData, loopProjectKey, {})
          let loopProjectDaysInPhase: number | null = null
          let lastMilestoneTimestamp: number | null = null
          // Get the index of the milestone
          let milestoneIndex = us_sortedMilestonePhaseTasksArray.findIndex((milestone) => milestone.key === loopMilestoneKey)
          if (loopProjectTimestamps != null && objectToArray(loopProjectTimestamps).length > 0) {
            if (milestoneIndex > 1) {
              // Get the index of the previous milestone
              let previousMilestoneIndex = milestoneIndex - 1
              let previousMilestoneKey = us_sortedMilestonePhaseTasksArray[previousMilestoneIndex].key
              // Use the last updated date of the previous milestone
              if (
                loopProjectTimestamps != null &&
                loopProjectTimestamps['task_completion_timestamps'] != null &&
                loopProjectTimestamps['key'] != null &&
                loopProjectTimestamps['task_completion_timestamps'][loopProjectTimestamps['key'] + '_' + previousMilestoneKey] != null
              ) {
                let previousMilestoneDate = returnTimestampFromUnknownDateFormat(
                  loopProjectTimestamps['task_completion_timestamps'][loopProjectTimestamps['key'] + '_' + previousMilestoneKey],
                )
                if (previousMilestoneDate != null) {
                  loopProjectDaysInPhase = Math.floor((currentDate.getTime() - previousMilestoneDate) / 86400000)
                  lastMilestoneTimestamp = previousMilestoneDate
                }
              }
            }
            if (milestoneIndex === -1 || milestoneIndex === 0 || loopProjectDaysInPhase == null) {
              // Use Oldest Task Date
              let oldestTaskDate = null
              if (loopProjectTimestamps != null && loopProjectTimestamps['task_completion_timestamps'] != null) {
                for (let loopTaskKey in loopProjectTimestamps['task_completion_timestamps']) {
                  if (
                    oldestTaskDate == null ||
                    (loopProjectTimestamps['task_completion_timestamps'][loopTaskKey] != null &&
                      returnTimestampFromUnknownDateFormat(loopProjectTimestamps['task_completion_timestamps'][loopTaskKey]) < oldestTaskDate)
                  ) {
                    oldestTaskDate = returnTimestampFromUnknownDateFormat(loopProjectTimestamps['task_completion_timestamps'][loopTaskKey])
                  }
                }
              }
              // Number of days between oldest task date and now
              if (oldestTaskDate != null) {
                loopProjectDaysInPhase = Math.floor((currentDate.getTime() - oldestTaskDate) / 86400000)
                lastMilestoneTimestamp = oldestTaskDate
              }
            }
          }
          if (
            projectsSortedByMilestone != null &&
            projectsSortedByMilestone[loopMilestoneKey] != null &&
            projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]
          ) {
            projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_days_in_phase'] = loopProjectDaysInPhase
            projectsSortedByMilestone[loopMilestoneKey][loopProjectKey]['TEMP_last_milestone_timestamp'] = lastMilestoneTimestamp
          }
        }
      }
      us_setProjectsSortedByMilestone(projectsSortedByMilestone)
    }
  }, [us_phaseWorkflowKey, us_phaseActiveProjects, us_sortedMilestonePhaseTasksArray, us_phaseActiveProjectTimestampsData])

  useEffect(() => {
    if (us_phaseWorkflowKey != null && us_phaseWorkflowKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_ActiveProjectsForSpecificWorkflow_Query(res_GCK.clientKey, us_phaseWorkflowKey))
            .then((res_DGD) => {
              let filteredSalesPartnerProjectsObject: TsInterface_UnspecifiedObject = {}
              for (let loopProjectKey in res_DGD.data) {
                let loopProject = res_DGD.data[loopProjectKey]
                if (loopProject.associated_sales_partner_key === uc_RootData_ClientUser.associated_sales_partner_key) {
                  filteredSalesPartnerProjectsObject[loopProjectKey] = loopProject
                }
              }
              us_setPhaseActiveProjects(filteredSalesPartnerProjectsObject)
              ur_forceRerender()
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_RootData_ClientUser.associated_sales_partner_key, uc_setRootData_ClientKey, ur_forceRerender, us_phaseWorkflowKey])

  // Functions
  // TODO: change query to have on hold and unassigned projects as well? Or a filter at the top to select the status to show?
  const tableDatabaseEndpoint_AllSalesProjects = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    if (us_filteredUserEmail != null && us_filteredUserEmail !== 'ALL_USERS') {
      if (us_queryDepth === 'just_user') {
        queryOperatorsArray = [
          { prop: 'associated_sales_rep_email', comparator: '==', value: us_filteredUserEmail as string },
          { prop: 'status', comparator: '==', value: us_tableFilterStatus },
        ]
      } else if (us_queryDepth === 'user_and_direct_reports') {
        queryOperatorsArray = [
          { prop: 'associated_sales_access_direct_managers', comparator: 'array-contains', value: us_filteredUserEmail as string },
          { prop: 'status', comparator: '==', value: us_tableFilterStatus },
        ]
      } else if (us_queryDepth === 'user_and_all_reports') {
        queryOperatorsArray = [
          { prop: 'associated_sales_access_all_managers', comparator: 'array-contains', value: us_filteredUserEmail as string },
          { prop: 'status', comparator: '==', value: us_tableFilterStatus },
        ]
      }
    } else {
      queryOperatorsArray = [
        { prop: 'associated_sales_partner_key', comparator: '==', value: uc_RootData_ClientUser.associated_sales_partner_key as string },
        { prop: 'status', comparator: '==', value: us_tableFilterStatus },
      ]
    }

    // Just User
    // User and Direct Reports
    // User and All Reports

    let orderByArray: TsInterface_OrderByArray = [{ prop: 'associated_customer_name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Projects_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // JSX Generation
  const rJSX_TableStatusFilterButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        color="secondary"
        className="tw-ml-2"
        startIcon={
          <Icon
            icon="filters"
            type="solid"
          />
        }
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: { status: us_tableFilterStatus },
                formInputs: formInputs_TableStatusFilter,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    us_setTableFilterStatus(formSubmittedData.status)
                    resolve({ success: true })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: s_TABLE_STATUS_FILTER,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="filters"
                  />
                ),
              },
            },
          })
        }}
      >
        {getProp(projectStatusOptions[us_tableFilterStatus], 'value', '')} {rLIB('Projects')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ProjectsTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null && uc_RootData_ClientUser != null && uc_RootData_ClientUser.key !== '') {
      if (uc_RootData_ClientUser.associated_sales_partner_key != null) {
        if (tableSettings_AllSalesProjects.search_settings_database == null) {
          tableSettings_AllSalesProjects.search_settings_database = { search_type: 'meilisearch' }
        }
        tableSettings_AllSalesProjects.search_settings_database.search_client_key = uc_RootData_ClientKey
        let searchFilters = ["status = 'active'", "associated_sales_partner_key = '" + uc_RootData_ClientUser.associated_sales_partner_key + "'"]
        tableSettings_AllSalesProjects.search_settings_database.search_filters = searchFilters
        tableJSX = (
          <Box>
            <Card className="">
              <TableDatabase
                tableAdditionalData={{}}
                tableColumns={tableColumns_AllSalesProjects}
                tableDatabaseEndpoint={tableDatabaseEndpoint_AllSalesProjects}
                tableSettings={tableSettings_AllSalesProjects}
              />
            </Card>
          </Box>
        )
      } else {
        tableJSX = (
          <Card className="tw-p-4 tw-text-center">
            <Typography variant="h5">{s_ACCOUNT_NOT_SET_UP_TO_VIEW_PROJECTS}</Typography>
            <Typography
              variant="body1"
              className="tw-mt-2 tw-opacity-30"
            >
              {s_CONTACT_SUPPORT_TO_GET_ACCOUNT_ACCESS_CONFIGURED}
            </Typography>
          </Card>
        )
      }
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_FilterUserDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl
          className="tw-ml-2 bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px' }}
        >
          <InputLabel id={'userFilter'}>{s_USER_FILTER}</InputLabel>
          <Select
            // className="bp_thin_select_input"
            autoWidth={true}
            id={'userFilter'}
            labelId={'userFilter'}
            value={us_filteredUserEmail}
            label={<Box>{s_USER_FILTER}</Box>}
            onChange={(event: any) => {
              console.log(event)
              if (event != null && event.target != null && event.target.value != null) {
                console.log(event.target.value)
                us_setFilteredUserEmail(event.target.value)
              }
            }}
          >
            <MenuItem value={'ALL_USERS'}>{s_ALL_USERS}</MenuItem>
            {objectToArray(us_salesHierarchyUsers)
              .sort(dynamicSort('name', 'asc'))
              .map((user: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={user.email}
                >
                  {user.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_QueryDepthDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    if (us_filteredUserEmail !== 'ALL_USERS') {
      dropdownJSX = (
        <Box className="tw-inline-block tw-align-top">
          <FormControl
            className="tw-ml-2 bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px' }}
          >
            <InputLabel id={'userFilter'}>{s_QUERY_DEPTH}</InputLabel>
            <Select
              // className="bp_thin_select_input"
              autoWidth={true}
              id={'userFilter'}
              labelId={'userFilter'}
              value={us_queryDepth}
              label={<Box>{s_USER_FILTER}</Box>}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  us_setQueryDepth(event.target.value)
                }
              }}
            >
              <MenuItem value={'just_user'}>{s_JUST_USERS_PROJECTS}</MenuItem>
              <MenuItem value={'user_and_direct_reports'}>{s_USER_AND_DIRECT_REPORTS}</MenuItem>
              <MenuItem value={'user_and_all_reports'}>{s_USER_AND_ALL_REPORTS}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )
    }
    return dropdownJSX
  }

  const rJSX_ProjectPhasesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Box>
          <Card className="tw-text-left tw-p-4 tw-mb-2">
            <Box>
              <Stack
                direction="row"
                spacing={0}
              >
                <Typography
                  variant="h6"
                  className="tw-my-1 tw-mr-2"
                >
                  {rLIB('Workflow')}
                </Typography>
                <FormControl className="bp_thin_select_input">
                  <Select
                    color="primary"
                    value={us_phaseWorkflowKey}
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        us_setPhaseWorkflowKey(event.target.value)
                      }
                    }}
                    variant="outlined"
                  >
                    {us_taskWorkflowsArray.sort(dynamicSort('value', null)).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                        disabled={option['disabled'] === true}
                      >
                        {option['value']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Box>
          </Card>
          {rJSX_ProjectPhasesTabContent(
            us_phaseWorkflowKey,
            us_taskWorkflowsObject,
            us_sortedMilestonePhaseTasksArray,
            us_projectsSortedByMilestone,
            un_routerNavigation,
            'SalesProjectViewPage',
            'read',
            uc_setUserInterface_FormDialogDisplay,
            uc_setUserInterface_ErrorDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
          )}
        </Box>
      )
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  // const rJSX_ShowingProjectNotesButton = (minimize: boolean) => {
  // let buttonJSX = <></>
  // buttonJSX = (
  //   <Button
  //     variant="outlined"
  //     color="secondary"
  //     className="te-mr-2"
  //     startIcon={<Icon icon="notes" />}
  //     onClick={() => {
  //       us_setShowingProjectNotes(!us_showingProjectNotes)
  //     }}
  //   >
  //     {us_showingProjectNotes ? rLIB('Showing Project Notes') : rLIB('Hiding Project Notes')}
  //   </Button>
  // )
  // return buttonJSX
  // }

  const rJSX_PageTabs = (): JSX.Element => {
    let tabsJSX = <></>
    if (uc_RootData_ClientKey != null && uc_RootData_ClientUser != null && uc_RootData_ClientUser.key !== '') {
      if (uc_RootData_ClientUser.associated_sales_partner_key != null) {
        tabsJSX = (
          <TabsUrl
            tabsSettings={{
              baseUrl: ApplicationPages.SalesProjectsListPage.url(),
              tabQueryParam: 'tab',
              overridePageTitle: true,
              basePageTitle: rLIB('Projects', false) as string,
            }}
            tabs={[
              {
                tabHeader: rLIB('Projects'),
                tabUrlKey: 'projects',
                tabButtons: [
                  { fullJSX: rJSX_TableStatusFilterButton(), minJSX: rJSX_TableStatusFilterButton(), sizeCutoff: 0 },
                  { fullJSX: rJSX_FilterUserDropdown(), minJSX: rJSX_FilterUserDropdown(), sizeCutoff: 0 },
                  { fullJSX: rJSX_QueryDepthDropdown(), minJSX: rJSX_QueryDepthDropdown(), sizeCutoff: 0 },
                ],
                tabContent: <Box className="tw-h-full">{rJSX_ProjectsTable()}</Box>,
              },
              {
                tabHeader: rLIB('Project Phases'),
                tabUrlKey: 'phases',
                tabButtons: [
                  // { fullJSX: rJSX_ShowingProjectNotesButton(false), minJSX: rJSX_ShowingProjectNotesButton(true), sizeCutoff: 0 }
                ],
                tabContent: <Box className="tw-h-full">{rJSX_ProjectPhasesTab()}</Box>,
              },
            ]}
          />
        )
      } else {
        tabsJSX = (
          <Card className="tw-p-4 tw-text-center">
            <Typography variant="h5">{s_ACCOUNT_NOT_SET_UP_TO_VIEW_PROJECTS}</Typography>
            <Typography
              variant="body1"
              className="tw-mt-2 tw-opacity-30"
            >
              {s_CONTACT_SUPPORT_TO_GET_ACCOUNT_ACCESS_CONFIGURED}
            </Typography>
          </Card>
        )
      }
    } else {
      tabsJSX = <></>
    }
    return tabsJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Projects')}
        pageKey={pageKey}
        // content={<Box>{rJSX_ProjectsTable()}</Box>}
        content={<Box>{rJSX_PageTabs()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
