import Masonry from '@mui/lab/Masonry'
import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip as RTooltip } from 'recharts'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ActiveBatteries_Query } from 'rfbp_aux/services/database_endpoints/directory/batteries'
import { DatabaseRef_ActiveFinancePartners_Query } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_ActiveInverters_Query } from 'rfbp_aux/services/database_endpoints/directory/inverters'
import { DatabaseRef_ActiveModules_Query } from 'rfbp_aux/services/database_endpoints/directory/modules'
import { DatabaseRef_ActiveRacking_Query } from 'rfbp_aux/services/database_endpoints/directory/racking'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_ActiveAdders_Query } from 'rfbp_aux/services/database_endpoints/sales/adders'
import { DatabaseRef_ActiveDiscounts_Query } from 'rfbp_aux/services/database_endpoints/sales/discounts'
import { DatabaseRef_ActiveIncentives_Query } from 'rfbp_aux/services/database_endpoints/sales/incentives'
import {
  DatabaseRef_ProposalFiles_Collection,
  DatabaseRef_ProposalFile_Document,
  DatabaseRef_ProposalPhotos_Collection,
  DatabaseRef_ProposalPhoto_Document,
  DatabaseRef_Proposal_Document,
} from 'rfbp_aux/services/database_endpoints/sales/proposals'
import { DatabaseRef_ActiveSOW_Query } from 'rfbp_aux/services/database_endpoints/sales/sow'
import { StorageRef_ProposalFile, StorageRef_ProposalPhoto } from 'rfbp_aux/services/storage_endpoints/proposals'
import { FileSystemBasic, TsInterface_FileSystemSettings } from 'rfbp_core/components/file_system/file_system_basic'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { BillingAnalysis, ExportCreditsData, GenerationData, IntervalData } from './interfaces'
// @ts-expect-error - reason for error
import weekdayExportCredits from './export_credits/PG&E Export Credits - 2024 Interconnection - Weekdays (1).csv'
// @ts-expect-error - reason for error
import weekendExportCredits from './export_credits/PG&E Export Credits - 2024 Interconnection - Weekends (2).csv'

interface OpportunityProposalDialogProps {
  proposalKey: string
  opportunity: TsInterface_UnspecifiedObject
}

interface AdderItem {
  name: string
  amount: number
}

interface SOWItem {
  name: string
  amount: number
}

interface IncentiveItem {
  name: string
  amount: number
}

interface DiscountItem {
  name: string
  amount: number
}

//usage and production graph
const BATTERY_SPECS = {
  capacity: 20, // kWh
  reserveCapacity: 5, // percentage
  maxDischargeRate: 9.6, // kW per hour
  efficiency: 100, // percentage
}
const UTILITY_RATES = {
  // per kWh
  peak_summer: 0.61578,
  peak_winter: 0.38426,
  offPeak_summer: 0.39722,
  offPeak_winter: 0.34831,
  partial_peak_summer: 0.4539,
  partial_peak_winter: 0.36217,
}

const formInputs_ProposalDetails: TsInterface_FormInputs = {
  associated_product_name: {
    key: 'associated_product_name',
    label: rLIB('Product Name'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Solar', value: 'Solar' },
      { key: 'Solar + Storage', value: 'Solar + Storage' },
      { key: 'EV Charger', value: 'EV Charger' },
      { key: 'Standalone Storage', value: 'Standalone Storage' },
    ],
    required: true,
    data_type: 'string',
  },
  associated_proposal_origin_name: {
    key: 'associated_proposal_origin_name',
    label: rLIB('Proposal Origin'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Aurora', value: 'Aurora' },
      { key: 'OpenSolar', value: 'OpenSolar' },
      { key: 'Solo', value: 'Solo' },
      { key: 'Merdeka', value: 'Merdeka' },
      { key: 'Energy Toolbase', value: 'Energy Toolbase' },
      { key: 'Google Solar', value: 'Google Solar' },
    ],
    required: true,
    data_type: 'string',
  },
  associated_production_model_name: {
    key: 'associated_production_model_name',
    label: rLIB('Production Model'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'PVWatts v6', value: 'PVWatts v6' },
      { key: 'PVWatts v7', value: 'PVWatts v7' },
      { key: 'PVWatts v8', value: 'PVWatts v8' },
      { key: 'Google Solar', value: 'Google Solar' },
    ],
    required: true,
    data_type: 'string',
  },
  system_size_dc: {
    key: 'system_size_dc',
    label: rLIB('System Size KWdc'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 3,
    max: 100,
    min_error_message: rLIB('System size must be greater than or equal to 3 kWdc'),
    max_error_message: rLIB('System size must be less than or equal to 100 kWdc'),
  },
  system_size_ac: {
    key: 'system_size_ac',
    label: rLIB('System Size KWac'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 2,
    max: 100,
    min_error_message: rLIB('System size must be greater than or equal to 2 kWac'),
    max_error_message: rLIB('System size must be less than or equal to 100 kWac'),
  },
  selected_system_size: {
    key: 'selected_system_size',
    label: rLIB('System Size KW'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 2,
    max: 100,
    min_error_message: rLIB('System size must be greater than or equal to 2 kWac'),
    max_error_message: rLIB('System size must be less than or equal to 100 kWac'),
  },
  system_estimated_annual_production: {
    key: 'system_estimated_annual_production',
    label: rLIB('Estimated Annual Production'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    min: 3000,
    max: 150000,
    min_error_message: rLIB('Estimated annual production must be greater than or equal to 3000 kWh'),
    max_error_message: rLIB('Estimated annual production must be less than or equal to 150000 kWh'),
  },
  system_usage_offset: {
    key: 'system_usage_offset',
    label: rLIB('Usage Offset (%)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 10,
    max: 200,
    min_error_message: rLIB('Usage offset must be greater than or equal to 10%'),
    max_error_message: rLIB('Usage offset must be less than or equal to 200%'),
  },
  system_estimated_year_one_savings: {
    key: 'system_estimated_year_one_savings',
    label: rLIB('Est. Y1 Savings ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: -2000,
    max: 5000,
    min_error_message: rLIB('Estimated year one savings must be greater than or equal to $-2,000'),
    max_error_message: rLIB('Estimated year one savings must be less than or equal to $5,000'),
  },
  system_mount_type: {
    key: 'system_mount_type',
    label: rLIB('Mount Type'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Roof Mount', value: 'Roof Mount' },
      { key: 'Ground Mount', value: 'Ground Mount' },
    ],
    required: true,
    data_type: 'string',
  },
  associated_financing_type: {
    key: 'associated_financing_type',
    label: rLIB('Financing Type'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Cash', value: 'Cash' },
      { key: 'Loan', value: 'Loan' },
      { key: 'Leasing', value: 'Leasing' },
      { key: 'PPA', value: 'PPA' },
    ],
    required: false,
    data_type: 'string',
  },
  associated_sales_rep_name: {
    key: 'associated_sales_rep_name',
    label: rLIB('Sales Rep Name'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  financials_cash_deposit: {
    key: 'financials_cash_deposit',
    label: rLIB('Cash Deposit ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_cash_base_price: {
    key: 'financials_cash_base_price',
    label: rLIB('Base Price ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lunar_battery_cost: {
    key: 'financials_lunar_battery_cost',
    label: rLIB('Lunar Battery Cost ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_cash_price_after_incentives: {
    key: 'financials_cash_price_after_incentives',
    label: rLIB('Price After Incentives ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_federal_incentive: {
    key: 'financials_federal_incentive',
    label: rLIB('Federal Incentive ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_srec_incentive: {
    key: 'financials_srec_incentive',
    label: rLIB('State Incentive ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lease_downpayment: {
    key: 'financials_lease_downpayment',
    label: rLIB('Downpayment ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lease_monthly_payment: {
    key: 'financials_lease_monthly_payment',
    label: rLIB('Monthly Payment ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lease_solar_rate: {
    key: 'financials_lease_solar_rate',
    label: rLIB('Solar Rate ($/kWh)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lease_escalator_rate: {
    key: 'financials_lease_escalator_rate',
    label: rLIB('Escalator (%)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 0,
    max: 4,
    min_error_message: rLIB('Escalator rate must be greater than or equal to 0%'),
    max_error_message: rLIB('Escalator rate must be less than or equal to 4%'),
  },
  financials_lease_epc_amount: {
    key: 'financials_lease_epc_amount',
    label: rLIB('EPC Amount ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_loan_apr: {
    key: 'financials_loan_apr',
    label: rLIB('APR (%)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 0,
    max: 100,
  },
  financials_loan_dealer_fee_percent: {
    key: 'financials_loan_dealer_fee_percent',
    label: rLIB('Dealer Fee (%)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  credit_check_status: {
    key: 'credit_check_status',
    label: rLIB('Credit Check Status'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Not Started', value: 'Not Started' },
      { key: 'Not Required', value: 'Not Required' },
      { key: 'Approved', value: 'Approved' },
      { key: 'Failed', value: 'Failed' },
    ],
    required: false,
    data_type: 'string',
  },
}

const formInputs_ProposalAssociations: TsInterface_FormInputs = {
  system_panel_model: {
    key: 'system_panel_model',
    label: rLIB('Panel Model'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  system_storage_model: {
    key: 'system_storage_model',
    label: rLIB('Storage Model'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  system_inverter_model: {
    key: 'system_inverter_model',
    label: rLIB('Inverter Model'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  system_racking_model: {
    key: 'system_racking_model',
    label: rLIB('Racking Model'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  associated_finance_partner_key: {
    key: 'associated_finance_partner_key',
    label: rLIB('Finance Partner'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  associated_sales_partner_key: {
    key: 'associated_sales_partner_key',
    label: rLIB('Sales Partner'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
}

const ArrayCard: React.FC<{ array: any; onEdit: () => void }> = ({ array, onEdit }) => {
  return (
    <Box className="tw-mb-2">
      <Typography
        variant="subtitle1"
        sx={{ color: themeVariables.primary_main }}
      >
        {array.name || 'Unnamed Array'}
        <IconButton
          onClick={onEdit}
          size="small"
          sx={{ color: themeVariables.gray_600 }}
        >
          <Icon icon="edit" />
        </IconButton>
      </Typography>
      <Box className="tw-pl-4">
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Panels: ${array.panel_quantity || 'Not set'}`}</Typography>
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Azimuth: ${array.azimuth !== null ? `${array.azimuth}°` : 'Not set'}`}</Typography>
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Pitch: ${array.pitch !== null ? `${array.pitch}°` : 'Not set'}`}</Typography>
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Orientation: ${array.orientation || 'Not set'}`}</Typography>
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Story: ${array.story || 'Not set'}`}</Typography>
      </Box>
    </Box>
  )
}

const ArrayDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (array: any) => void
  array: any
  isNew: boolean
}> = ({ open, onClose, onSave, array, isNew }) => {
  const [editedArray, setEditedArray] = useState(array)

  useEffect(() => {
    setEditedArray(array)
  }, [array])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    let newValue = value

    if (name === 'azimuth') {
      newValue = Math.min(Math.max(Number(value), 0), 360)
    } else if (name === 'pitch') {
      newValue = Math.min(Math.max(Number(value), 0), 90)
    } else if (name === 'story') {
      newValue = Math.min(Math.max(Number(value), 0), 2)
    }

    setEditedArray({ ...editedArray, [name as string]: newValue })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{isNew ? 'Add New Array' : 'Edit Array'}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          name="name"
          label="Array Name"
          value={editedArray.name || ''}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="panel_quantity"
          label="Panel Quantity"
          type="number"
          value={editedArray.panel_quantity || ''}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
        <TextField
          fullWidth
          margin="normal"
          name="azimuth"
          label="Azimuth (0-360°)"
          type="number"
          value={editedArray.azimuth || ''}
          onChange={handleChange}
          inputProps={{ min: 0, max: 360 }}
        />
        <TextField
          fullWidth
          margin="normal"
          name="pitch"
          label="Pitch (0-90°)"
          type="number"
          value={editedArray.pitch || ''}
          onChange={handleChange}
          inputProps={{ min: 0, max: 90 }}
        />
        <Select
          fullWidth
          margin="dense"
          name="orientation"
          label="Orientation"
          value={editedArray.orientation || ''}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          <MenuItem value="landscape">Landscape</MenuItem>
          <MenuItem value="portrait">Portrait</MenuItem>
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="story"
          label="Story"
          type="number"
          value={editedArray.story || ''}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(editedArray)}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const AdderDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (adder: AdderItem) => void
  adder: AdderItem
  adderOptions: { key: string; value: string }[]
}> = ({ open, onClose, onSave, adder, adderOptions }) => {
  const [editedAdder, setEditedAdder] = useState(adder)

  useEffect(() => {
    setEditedAdder(adder)
  }, [adder])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    setEditedAdder({ ...editedAdder, [name as string]: value })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{rLIB('Add Adder')}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          margin="dense"
          name="name"
          label={rLIB('Adder')}
          value={editedAdder.name}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          {adderOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label={rLIB('Amount ($)')}
          type="number"
          value={editedAdder.amount}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rLIB('Cancel')}</Button>
        <Button onClick={() => onSave(editedAdder)}>{rLIB('Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const SOWDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (sow: SOWItem) => void
  sow: SOWItem
  sowOptions: { key: string; value: string }[]
}> = ({ open, onClose, onSave, sow, sowOptions }) => {
  const [editedSOW, setEditedSOW] = useState(sow)

  useEffect(() => {
    setEditedSOW(sow)
  }, [sow])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    setEditedSOW({ ...editedSOW, [name as string]: value })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{rLIB('Add SOW')}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          margin="dense"
          name="name"
          label={rLIB('SOW')}
          value={editedSOW.name}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          {sowOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label={rLIB('Amount ($)')}
          type="number"
          value={editedSOW.amount}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rLIB('Cancel')}</Button>
        <Button onClick={() => onSave(editedSOW)}>{rLIB('Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const IncentiveDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (incentive: IncentiveItem) => void
  incentive: IncentiveItem
  incentiveOptions: { key: string; value: string }[]
}> = ({ open, onClose, onSave, incentive, incentiveOptions }) => {
  const [editedIncentive, setEditedIncentive] = useState(incentive)

  useEffect(() => {
    setEditedIncentive(incentive)
  }, [incentive])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    setEditedIncentive({ ...editedIncentive, [name as string]: value })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{rLIB('Add Incentive')}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          margin="dense"
          name="name"
          label={rLIB('Incentive')}
          value={editedIncentive.name}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          {incentiveOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label={rLIB('Amount ($)')}
          type="number"
          value={editedIncentive.amount}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rLIB('Cancel')}</Button>
        <Button onClick={() => onSave(editedIncentive)}>{rLIB('Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const DiscountDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (discount: DiscountItem) => void
  discount: DiscountItem
  discountOptions: { key: string; value: string }[]
}> = ({ open, onClose, onSave, discount, discountOptions }) => {
  const [editedDiscount, setEditedDiscount] = useState(discount)

  useEffect(() => {
    setEditedDiscount(discount)
  }, [discount])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    setEditedDiscount({ ...editedDiscount, [name as string]: value })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{rLIB('Add Discount')}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          margin="dense"
          name="name"
          label={rLIB('Discount')}
          value={editedDiscount.name}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          {discountOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label={rLIB('Amount ($)')}
          type="number"
          value={editedDiscount.amount}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rLIB('Cancel')}</Button>
        <Button onClick={() => onSave(editedDiscount)}>{rLIB('Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export const OpportunityProposalDialog: React.FC<OpportunityProposalDialogProps> = ({ proposalKey, opportunity }) => {
  // Hooks
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_proposalData, us_setProposalData] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const [us_arrayDialogOpen, us_setArrayDialogOpen] = useState(false)
  const [us_currentArray, us_setCurrentArray] = useState<TsInterface_UnspecifiedObject>({})
  const [us_isNewArray, us_setIsNewArray] = useState(true)
  const [us_adderDialogOpen, us_setAdderDialogOpen] = useState(false)
  const [us_currentAdder, us_setCurrentAdder] = useState<AdderItem>({ name: '', amount: 0 })
  const [us_adderOptions, us_setAdderOptions] = useState<{ key: string; value: string }[]>([])
  const [us_sowDialogOpen, us_setSOWDialogOpen] = useState(false)
  const [us_currentSOW, us_setCurrentSOW] = useState<SOWItem>({ name: '', amount: 0 })
  const [us_sowOptions, us_setSOWOptions] = useState<{ key: string; value: string }[]>([])
  const [us_incentiveDialogOpen, us_setIncentiveDialogOpen] = useState(false)
  const [us_currentIncentive, us_setCurrentIncentive] = useState<IncentiveItem>({ name: '', amount: 0 })
  const [us_incentiveOptions, us_setIncentiveOptions] = useState<{ key: string; value: string }[]>([])
  const [us_discountDialogOpen, us_setDiscountDialogOpen] = useState(false)
  const [us_currentDiscount, us_setCurrentDiscount] = useState<DiscountItem>({ name: '', amount: 0 })
  const [us_discountOptions, us_setDiscountOptions] = useState<{ key: string; value: string }[]>([])

  //usage and production
  const [us_usageData, us_setUsageData] = useState<IntervalData[]>([])
  const [us_generationData, us_setGenerationData] = useState<GenerationData[]>([])
  const [us_exportCreditsWeekDayData, us_setExportCreditsWeekDayData] = useState<ExportCreditsData[]>([])
  const [us_exportCreditsWeekendData, us_setExportCreditsWeekendData] = useState<ExportCreditsData[]>([])
  const [us_billingAnalysis, us_setBillingAnalysis] = useState<BillingAnalysis | null>(null)
  const [us_solarOnlyBill, us_setSolarOnlyBill] = useState<BillingAnalysis | null>(null)
  const [us_solarPlusBatteryBill_v1, us_setSolarPlusBatteryBill_v1] = useState<BillingAnalysis | null>(null)
  const [us_solarPlusBatteryBill_v2, us_setSolarPlusBatteryBill_v2] = useState<BillingAnalysis | null>(null)
  const [us_solarPlusBatteryBill_v2_consumptionProfile, us_setSolarPlusBatteryBill_v2_consumptionProfile] = useState<{
    [month: number]: { [hour: number]: any }
  } | null>(null)
  const [us_solarOnlyBill_consumptionProfile, us_setSolarOnlyBill_consumptionProfile] = useState<{
    [month: number]: { [hour: number]: any }
  } | null>(null)
  const [us_selectedMonth, us_setSelectedMonth] = useState<number | null>(0)
  const [us_selectedHour, us_setSelectedHour] = useState<number | null>(0)
  const [us_selectedConsumptionProfile, us_setSelectedConsumptionProfile] = useState<string>('')
  const [us_data, us_setData] = useState<any[]>([])

  //useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProposalData(newData)
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.log(rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, proposalKey, uc_setRootData_ClientKey])

  useEffect(() => {
    const parseCSVData = async () => {
      try {
        const fetchCSV = async (path: string) => {
          const response = await fetch(path)
          return await response.text()
        }

        const weekdayCSV = await fetchCSV(weekdayExportCredits)
        const weekendCSV = await fetchCSV(weekendExportCredits)

        // Parse CSV data (assuming comma-separated values)
        const parseExportCreditsCSV = async (csvText: string): Promise<ExportCreditsData[]> => {
          return new Promise((resolve, reject) => {
            try {
              const lines = csvText.split('\n')
              // Skip first two rows and use third row (index 2) as headers
              const headers = lines[2].toLowerCase().split(',')
              const monthColumns = headers
                .map((header, index) => ({
                  index,
                  header: header.trim(),
                }))
                .filter((col) => col.header.startsWith('total - '))

              if (monthColumns.length === 0) {
                throw new Error('CSV must contain columns starting with "total - "')
              }

              const data: ExportCreditsData[] = []

              // Process each row (starting from row 4, which is index 3)
              lines.slice(3).forEach((line) => {
                if (!line.trim()) return // Skip empty lines

                const columns = line.split(',')
                const timeStr = columns[0].trim()

                // Parse time string (assuming 12-hour format)
                const [time, period] = timeStr.split(' ')
                const [hours, minutes] = time.split(':').map((n) => parseInt(n))

                // Convert to 24-hour format
                let hour = hours
                if (period.toLowerCase() === 'pm' && hours !== 12) {
                  hour += 12
                } else if (period.toLowerCase() === 'am' && hours === 12) {
                  hour = 0
                }

                // Get credits for each month
                monthColumns.forEach((col) => {
                  const credits = parseFloat(columns[col.index])
                  if (!isNaN(credits)) {
                    data.push({
                      time: hour,
                      month: col.header.split(' ')[2].trim(),
                      credits: credits,
                    })
                  }
                })
              })

              resolve(data)
            } catch (error) {
              reject(error)
            }
          })
        }

        const weekdayData = await parseExportCreditsCSV(weekdayCSV)
        const weekendData = await parseExportCreditsCSV(weekendCSV)

        // Update states with parsed data
        us_setExportCreditsWeekDayData(weekdayData)
        us_setExportCreditsWeekendData(weekendData)
      } catch (error) {
        console.error('Error loading export credits data:', error)
      }
    }

    parseCSVData()
  }, [])

  //functions

  const calculateProductionRatio = (): string | null => {
    const y1Production = parseFloat(us_proposalData.system_estimated_annual_production)
    const systemSizeDC = parseFloat(us_proposalData.system_size_dc)

    if (!isNaN(y1Production) && !isNaN(systemSizeDC) && systemSizeDC > 0) {
      const ratio = y1Production / systemSizeDC // Convert kW to W
      return ratio.toFixed(2)
    }
    return null
  }

  const handleAddArray = () => {
    us_setCurrentArray({
      name: null,
      panel_quantity: null,
      azimuth: null,
      pitch: null,
      orientation: null,
      story: null,
    })
    us_setIsNewArray(true)
    us_setArrayDialogOpen(true)
  }

  const handleEditArray = (array: TsInterface_UnspecifiedObject) => {
    us_setCurrentArray({ ...array })
    us_setIsNewArray(false)
    us_setArrayDialogOpen(true)
  }

  const handleSaveArray = (editedArray: any) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let updatedArrays: any[]
        if (us_isNewArray) {
          updatedArrays = [...(us_proposalData.system_panel_arrays || []), editedArray]
        } else {
          updatedArrays = us_proposalData.system_panel_arrays.map((a: any) => (a.name === editedArray.name ? editedArray : a))
        }
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { system_panel_arrays: updatedArrays })
          .then(() => {
            us_setProposalData({ ...us_proposalData, system_panel_arrays: updatedArrays })
            us_setArrayDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating arrays:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleAddAdder = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_ActiveAdders_Query(res_GCK.clientKey))
        .then((res_DGC) => {
          const options = Object.entries(res_DGC.data).map(([key, value]) => ({
            key,
            value: value.name,
          }))
          us_setAdderOptions(options)
          us_setCurrentAdder({ name: '', amount: 0 })
          us_setAdderDialogOpen(true)
        })
        .catch((error) => {
          console.error('Error fetching adders:', error)
        })
    })
  }

  const handleSaveAdder = (editedAdder: AdderItem) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const adderWithNumberAmount = { ...editedAdder, amount: Number(editedAdder.amount) }
        const updatedAdders = [...(us_proposalData.adders || []), adderWithNumberAmount]
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { adders: updatedAdders })
          .then(() => {
            us_setProposalData({ ...us_proposalData, adders: updatedAdders })
            us_setAdderDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating adders:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleAddSOW = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_ActiveSOW_Query(res_GCK.clientKey))
        .then((res_DGC) => {
          const options = Object.entries(res_DGC.data).map(([key, value]) => ({
            key,
            value: value.name,
          }))
          us_setSOWOptions(options)
          us_setCurrentSOW({ name: '', amount: 0 })
          us_setSOWDialogOpen(true)
        })
        .catch((error) => {
          console.error('Error fetching sow:', error)
        })
    })
  }

  const handleSaveSOW = (editedSOW: SOWItem) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const updatedSOW = [...(us_proposalData.sow || []), editedSOW]
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { sow: updatedSOW })
          .then(() => {
            us_setProposalData({ ...us_proposalData, sow: updatedSOW })
            us_setSOWDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating sow:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleAddIncentive = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_ActiveIncentives_Query(res_GCK.clientKey))
        .then((res_DGC) => {
          const options = Object.entries(res_DGC.data).map(([key, value]) => ({
            key,
            value: value.name,
          }))
          us_setIncentiveOptions(options)
          us_setCurrentIncentive({ name: '', amount: 0 })
          us_setIncentiveDialogOpen(true)
        })
        .catch((error) => {
          console.error('Error fetching sow:', error)
        })
    })
  }

  const handleSaveIncentive = (editedIncentive: IncentiveItem) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const updatedIncentives = [...(us_proposalData.incentives || []), editedIncentive]
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { incentives: updatedIncentives })
          .then(() => {
            us_setProposalData({ ...us_proposalData, incentives: updatedIncentives })
            us_setIncentiveDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating sow:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleAddDiscount = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_ActiveDiscounts_Query(res_GCK.clientKey))
        .then((res_DGC) => {
          const options = Object.entries(res_DGC.data).map(([key, value]) => ({
            key,
            value: value.name,
          }))
          us_setDiscountOptions(options)
          us_setCurrentDiscount({ name: '', amount: 0 })
          us_setDiscountDialogOpen(true)
        })
        .catch((error) => {
          console.error('Error fetching sow:', error)
        })
    })
  }

  const handleSaveDiscount = (editedDiscount: DiscountItem) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const discountWithNumberAmount = { ...editedDiscount, amount: Number(editedDiscount.amount) }
        const updatedDiscounts = [...(us_proposalData.discounts || []), discountWithNumberAmount]
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { discounts: updatedDiscounts })
          .then(() => {
            us_setProposalData({ ...us_proposalData, discounts: updatedDiscounts })
            us_setDiscountDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating sow:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  //JSX
  const rJSX_DetailLineItem = (
    icon: string,
    label: JSX.Element | string,
    displayKey: string,
    formKey: string,
    editIcon: (propKey: string) => JSX.Element,
    displayValue?: string,
    highlight: boolean = false,
  ): JSX.Element => {
    const value = displayValue || getProp(us_proposalData, displayKey, null)
    const editIconJSX = editIcon(formKey)
    return (
      <Box sx={{ backgroundColor: highlight && !value ? 'rgba(255, 255, 0, 0.3)' : 'transparent' }}>
        <Icon
          className="tw-mr-2"
          icon={icon}
          sx={{ color: themeVariables.primary_main }}
        />
        <Typography
          className="tw-inline-block tw-mr-2"
          sx={{ color: themeVariables.primary_main }}
        >
          {label}:
        </Typography>
        {value !== null ? (
          <Typography className="tw-inline-block">{value}</Typography>
        ) : (
          <Typography
            className="tw-inline-block"
            sx={{ opacity: 0.5 }}
          >
            {rLIB('Missing')}
          </Typography>
        )}
        {editIconJSX}
      </Box>
    )
  }

  const rJSX_NotEditableLineItemIcon = (propKey: string): JSX.Element => {
    return <></>
  }

  const rJSX_SimpleEditLineItemIcon = (propKey: string): JSX.Element => {
    return (
      <Tooltip
        placement="right"
        title={rLIB('Edit')}
      >
        <Box className="tw-inline-block">
          <Icon
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            icon={'pen-to-square'}
            onClick={() => {
              let formInputs: TsInterface_FormInputs = {}

              if (formInputs_ProposalDetails[propKey] != null) {
                formInputs[propKey] = formInputs_ProposalDetails[propKey]
              }

              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: { ...us_proposalData },
                    formInputs: formInputs,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                          let updateObject = formSubmittedData
                          DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), updateObject)
                            .then((res_DAD) => {
                              resolve(res_DAD)
                            })
                            .catch((rej_DAD) => {
                              reject(rej_DAD)
                            })
                        })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: rLIB('Edit Opportunity'),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          ></Icon>
        </Box>
      </Tooltip>
    )
  }

  const rJSX_AssociationLineItemEditIcon = (propKey: string): JSX.Element => {
    return (
      <Tooltip
        placement="right"
        title={rLIB('Edit')}
      >
        <Box className="tw-inline-block">
          <Icon
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            icon={'pen-to-square'}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                //get association list
                let associationOptions: TsInterface_UnspecifiedObject = {}
                let promiseArray: Promise<unknown>[] = []

                switch (propKey) {
                  case 'system_panel_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveModules_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_storage_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveBatteries_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_inverter_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveInverters_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_racking_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveRacking_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'associated_finance_partner_key':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveFinancePartners_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'associated_sales_partner_key':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                }
                Promise.all(promiseArray).finally(() => {
                  //format options
                  let formattedOptions: { key: string; value: string }[] = []
                  for (let loopOptionKey in associationOptions) {
                    formattedOptions.push({ value: associationOptions[loopOptionKey]['name'], key: loopOptionKey })
                  }
                  //generate form inputs
                  let formInputs: TsInterface_FormInputs = {}

                  if (formInputs_ProposalAssociations[propKey] != null) {
                    formInputs[propKey] = formInputs_ProposalAssociations[propKey]
                    formInputs[propKey].options = formattedOptions
                  }

                  const currentValue = us_proposalData[propKey]
                  const currentKey = formattedOptions.find((option) => option.value === currentValue)?.key || ''

                  const formData = { ...us_proposalData, [propKey]: currentKey }
                  //open form dialog
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: formData,
                        formInputs: formInputs,
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                let namePropKey = propKey.replace('_key', '_name')
                                let matchingOption = getProp(associationOptions, formSubmittedData[propKey], {})
                                let nameValue = getProp(matchingOption, 'name', null)

                                updateObject[namePropKey] = nameValue

                                DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), updateObject)
                                  .then((res_DAD) => {
                                    resolve(res_DAD)
                                  })
                                  .catch((rej_DAD) => {
                                    reject(rej_DAD)
                                  })
                              })
                              .catch((rej_GCK) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                reject(rej_GCK)
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: rLIB('Edit Opportunity'),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                })
              })
            }}
          ></Icon>
        </Box>
      </Tooltip>
    )
  }

  const rJSX_ModelAndQuantityEditIcon = (propKey: string, quantityKey: string): JSX.Element => {
    return (
      <Tooltip
        placement="right"
        title={rLIB('Edit')}
      >
        <Box className="tw-inline-block">
          <Icon
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            icon={'pen-to-square'}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                //get association list
                let associationOptions: TsInterface_UnspecifiedObject = {}
                let promiseArray: Promise<unknown>[] = []

                switch (propKey) {
                  case 'system_panel_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveModules_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_storage_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveBatteries_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_inverter_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveInverters_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_racking_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveRacking_Query(res_GCK.clientKey)).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                }
                Promise.all(promiseArray).finally(() => {
                  //format options
                  let formattedOptions: { key: string; value: string }[] = []
                  for (let loopOptionKey in associationOptions) {
                    formattedOptions.push({ value: associationOptions[loopOptionKey]['name'], key: loopOptionKey })
                  }
                  //generate form inputs
                  let formInputs: TsInterface_FormInputs = {}

                  if (formInputs_ProposalAssociations[propKey] != null) {
                    formInputs[propKey] = formInputs_ProposalAssociations[propKey]
                    formInputs[propKey].options = formattedOptions
                  }

                  formInputs[quantityKey] = {
                    key: quantityKey,
                    label: rLIB('Quantity'),
                    input_type: 'text_number',
                    required: true,
                    data_type: 'number',
                    min: 0,
                  }

                  const currentValue = us_proposalData[propKey]
                  const currentKey = formattedOptions.find((option) => option.value === currentValue)?.key || ''

                  const formData = { ...us_proposalData, [propKey]: currentKey }
                  //open form dialog
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: formData,
                        formInputs: formInputs,
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                let namePropKey = propKey.replace('_key', '_name')
                                let matchingOption = getProp(associationOptions, formSubmittedData[propKey], {})
                                let nameValue = getProp(matchingOption, 'name', null)

                                updateObject[namePropKey] = nameValue

                                DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), updateObject)
                                  .then((res_DAD) => {
                                    resolve(res_DAD)
                                  })
                                  .catch((rej_DAD) => {
                                    reject(rej_DAD)
                                  })
                              })
                              .catch((rej_GCK) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                reject(rej_GCK)
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: rLIB('Edit Opportunity'),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                })
              })
            }}
          ></Icon>
        </Box>
      </Tooltip>
    )
  }

  const rJSX_ProposalSystemTabContent = (): JSX.Element => {
    const calculatedProductionRatio = calculateProductionRatio()
    if (calculatedProductionRatio !== null) {
      us_proposalData.calculated_production_ratio = `${calculatedProductionRatio} kWh/kWdc`
    } else {
      us_proposalData.calculated_production_ratio = null
    }

    const isLunarBattery = opportunity.product_type === 'lunar_battery'

    return (
      <Box sx={{ padding: '16px' }}>
        <Grid2
          container
          spacing={2}
        >
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Details')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'file-signature',
                rLIB('Proposal Origin'),
                'associated_proposal_origin_name',
                'associated_proposal_origin_name',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('solar-panel', rLIB('Product'), 'associated_product_name', 'associated_product_name', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'head-side-brain',
                rLIB('Production Model'),
                'associated_production_model_name',
                'associated_production_model_name',
                rJSX_SimpleEditLineItemIcon,
              )}
              {/* {rJSX_DetailLineItem('solar-panel', rLIB('System Size (kWdc)'), 'system_size_dc', 'system_size_dc', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('solar-panel', rLIB('System Size (kWac)'), 'system_size_ac', 'system_size_ac', rJSX_SimpleEditLineItemIcon)} */}
              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('System Size (kW)'),
                'selected_system_size',
                'selected_system_size',
                rJSX_SimpleEditLineItemIcon,
                '',
                isLunarBattery,
              )}
              {rJSX_DetailLineItem(
                'clock-one',
                rLIB('Y1 Production (kWh)'),
                'system_estimated_annual_production',
                'system_estimated_annual_production',
                rJSX_SimpleEditLineItemIcon,
                '',
                isLunarBattery,
              )}
              {rJSX_DetailLineItem('divide', rLIB('Production Ratio'), 'calculated_production_ratio', '', rJSX_NotEditableLineItemIcon)}
              {rJSX_DetailLineItem('badge-percent', rLIB('Usage Offset (%)'), 'system_usage_offset', 'system_usage_offset', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'badge-dollar',
                rLIB('Est. Y1 Savings ($)'),
                'system_estimated_year_one_savings',
                'system_estimated_year_one_savings',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'house-signal',
                rLIB('Mount Type'),
                'system_mount_type',
                'system_mount_type',
                rJSX_SimpleEditLineItemIcon,
                '',
                isLunarBattery,
              )}
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Equipment')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('Panel'),
                'system_panel_model',
                'system_panel_model',
                () => rJSX_ModelAndQuantityEditIcon('system_panel_model', 'panel_quantity'),
                us_proposalData.system_panel_model && us_proposalData.panel_quantity
                  ? `${us_proposalData.panel_quantity} x ${us_proposalData.system_panel_model}`
                  : undefined,
                isLunarBattery,
              )}
              {rJSX_DetailLineItem(
                'battery',
                rLIB('Storage'),
                'system_storage_model',
                'system_storage_model',
                () => rJSX_ModelAndQuantityEditIcon('system_storage_model', 'system_storage_quantity'),
                us_proposalData.system_storage_model && us_proposalData.system_storage_quantity
                  ? `${us_proposalData.system_storage_quantity} x ${us_proposalData.system_storage_model}`
                  : undefined,
                isLunarBattery,
              )}
              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('Inverter'),
                'system_inverter_model',
                'system_inverter_model',
                () => rJSX_ModelAndQuantityEditIcon('system_inverter_model', 'system_inverter_quantity'),
                us_proposalData.system_inverter_model && us_proposalData.system_inverter_quantity
                  ? `${us_proposalData.system_inverter_quantity} x ${us_proposalData.system_inverter_model}`
                  : undefined,
              )}
              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('Racking'),
                'system_racking_model',
                'system_racking_model',
                () => rJSX_ModelAndQuantityEditIcon('system_racking_model', 'system_racking_quantity'),
                us_proposalData.system_racking_model && us_proposalData.system_racking_quantity
                  ? `${us_proposalData.system_racking_quantity} x ${us_proposalData.system_racking_model}`
                  : undefined,
              )}
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Arrays')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.system_panel_arrays && us_proposalData.system_panel_arrays.length > 0 ? (
                us_proposalData.system_panel_arrays.map((array: any, index: number) => (
                  <ArrayCard
                    key={index}
                    array={array}
                    onEdit={() => handleEditArray(array)}
                  />
                ))
              ) : (
                <Typography>{rLIB('No arrays defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddArray}
                className="tw-mt-2"
              >
                {rLIB('Add Array')}
              </Button>
            </Card>
          </Grid2>
        </Grid2>
        <ArrayDialog
          open={us_arrayDialogOpen}
          onClose={() => us_setArrayDialogOpen(false)}
          onSave={handleSaveArray}
          array={us_currentArray}
          isNew={us_isNewArray}
        />
      </Box>
    )
  }

  const rJSX_PricingTabContent = (): JSX.Element => {
    const isLunarBattery = opportunity.product_type === 'lunar_battery'

    // Add this function to calculate price after incentives
    const calculatePriceAfterIncentivesAndAdders = (): number | null => {
      const basePrice = parseFloat(us_proposalData.financials_cash_base_price) || 0
      const federalIncentive = parseFloat(us_proposalData.financials_federal_incentive) || 0
      const stateIncentive = parseFloat(us_proposalData.financials_srec_incentive) || 0

      // Add any additional incentives from the incentives array
      let additionalIncentives = 0
      if (us_proposalData.incentives && Array.isArray(us_proposalData.incentives)) {
        additionalIncentives = us_proposalData.incentives.reduce((sum, incentive) => {
          return sum + (parseFloat(incentive.amount) || 0)
        }, 0)
      }

      //adders
      let totalAdders = 0
      if (us_proposalData.adders && Array.isArray(us_proposalData.adders)) {
        totalAdders = us_proposalData.adders.reduce((sum: number, adder: AdderItem) => {
          return sum + (adder.amount || 0)
        }, 0)
      }

      //discounts
      let totalDiscounts = 0
      if (us_proposalData.discounts && Array.isArray(us_proposalData.discounts)) {
        totalDiscounts = us_proposalData.discounts.reduce((sum: number, discount: DiscountItem) => {
          return sum + (discount.amount || 0)
        }, 0)
      }

      const totalIncentives = federalIncentive + stateIncentive + additionalIncentives
      if (isLunarBattery) {
        const lunarBatteryCost = parseFloat(us_proposalData.financials_lunar_battery_cost) || 0
        const priceAfterIncentives = basePrice + lunarBatteryCost + totalAdders - totalIncentives - totalDiscounts
        return priceAfterIncentives > 0 ? priceAfterIncentives : 0
      } else {
        const priceAfterIncentives = basePrice + totalAdders - totalIncentives - totalDiscounts
        return priceAfterIncentives > 0 ? priceAfterIncentives : 0
      }
    }

    // Calculate and update the price after incentives whenever relevant data changes
    useEffect(() => {
      const priceAfterIncentives = calculatePriceAfterIncentivesAndAdders()
      if (priceAfterIncentives !== null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), {
            financials_cash_price_after_incentives: priceAfterIncentives,
          })
        })
      }
    }, [us_proposalData.financials_cash_base_price, us_proposalData.financials_federal_incentive, us_proposalData.financials_srec_incentive])

    const financingType = us_proposalData.associated_financing_type

    return (
      <Box sx={{ padding: '16px' }}>
        <Grid2
          container
          spacing={2}
        >
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Details')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'money-bill-transfer',
                rLIB('Financing Type'),
                'associated_financing_type',
                'associated_financing_type',
                rJSX_SimpleEditLineItemIcon,
                '',
                isLunarBattery,
              )}
              {rJSX_DetailLineItem(
                'building',
                rLIB('Finance Partner'),
                'associated_finance_partner_name',
                'associated_finance_partner_key',
                rJSX_AssociationLineItemEditIcon,
              )}
              {rJSX_DetailLineItem(
                'building',
                rLIB('Sales Partner'),
                'associated_sales_partner_name',
                'associated_sales_partner_name',
                rJSX_AssociationLineItemEditIcon,
              )}
              {rJSX_DetailLineItem('user', rLIB('Sales Rep'), 'associated_sales_rep_name', 'associated_sales_rep_name', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'business-time',
                rLIB('Redline'),
                'associated_sales_partner_redline',
                'associated_sales_partner_redline',
                rJSX_NotEditableLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'circle-dollar',
                rLIB('Sales Rep Commission'),
                'associated_sales_rep_commission',
                'associated_sales_rep_commission',
                rJSX_NotEditableLineItemIcon,
              )}
              {rJSX_DetailLineItem('check', rLIB('Credit Check Status'), 'credit_check_status', 'credit_check_status', rJSX_SimpleEditLineItemIcon)}
            </Card>
          </Grid2>
          {/* {financingType === 'Cash' && (
            <Grid2
              xs={12}
              sm={6}
              md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {rLIB('Cash')}
              </Typography>
              <Card className="tw-p-2">
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Cash Deposit ($)'),
                  'financials_cash_deposit',
                  'financials_cash_deposit',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'square-dollar',
                  rLIB('Base Price ($)'),
                  'financials_cash_base_price',
                  'financials_cash_base_price',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-wave',
                  rLIB('Price Before Incentives ($)'),
                  'financials_cash_price_before_incentives',
                  'financials_cash_price_before_incentives',
                  rJSX_NotEditableLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-wave',
                  rLIB('Price After Incentives ($)'),
                  'financials_cash_price_after_incentives',
                  'financials_cash_price_after_incentives',
                  rJSX_NotEditableLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-wave',
                  rLIB('Price Per Watt ($/W)'),
                  'financials_cash_price_per_watt',
                  'financials_cash_price_per_watt',
                  rJSX_NotEditableLineItemIcon,
                )}
              </Card>
            </Grid2>
          )} */}
          {financingType === 'Loan' && (
            <Grid2
              xs={12}
              sm={6}
              md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {rLIB('Loan')}
              </Typography>
              <Card className="tw-p-2">
                {rJSX_DetailLineItem('money-bill-transfer', rLIB('APR (%)'), 'financials_loan_apr', 'financials_loan_apr', rJSX_SimpleEditLineItemIcon)}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Dealer Fee (%)'),
                  'financials_loan_dealer_fee_percent',
                  'financials_loan_dealer_fee_percent',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Dealer Fee ($)'),
                  'financials_loan_dealer_fee',
                  'financials_loan_dealer_fee',
                  rJSX_NotEditableLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Total Loan Amount ($)'),
                  'financials_loan_total_loan_amount',
                  'financials_loan_total_loan_amount',
                  rJSX_NotEditableLineItemIcon,
                )}
              </Card>
            </Grid2>
          )}
          {(financingType === 'Leasing' || financingType === 'PPA') && (
            <Grid2
              xs={12}
              sm={6}
              md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {financingType === 'Leasing' ? rLIB('Lease') : rLIB('PPA')}
              </Typography>
              <Card className="tw-p-2">
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Downpayment ($)'),
                  'financials_lease_down_payment',
                  'financials_lease_down_payment',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Monthly Payment ($)'),
                  'financials_lease_monthly_payment',
                  'financials_lease_monthly_payment',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Solar Rate ($/kWh)'),
                  'financials_lease_solar_rate',
                  'financials_lease_solar_rate',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Escalator (%)'),
                  'financials_lease_escalator_rate',
                  'financials_lease_escalator_rate',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('EPC Amount ($)'),
                  'financials_lease_epc_amount',
                  'financials_lease_epc_amount',
                  rJSX_SimpleEditLineItemIcon,
                )}
              </Card>
            </Grid2>
          )}
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Adders')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.adders && us_proposalData.adders.length > 0 ? (
                us_proposalData.adders.map((adder: AdderItem, index: number) => (
                  <Box
                    key={index}
                    className="tw-mb-2"
                  >
                    <Typography sx={{ color: themeVariables.primary_main }}>
                      {adder.name}: ${adder.amount}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>{rLIB('No adders defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddAdder}
                className="tw-mt-2"
              >
                {rLIB('Add Adder')}
              </Button>
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('SOW')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.sow && us_proposalData.sow.length > 0 ? (
                us_proposalData.sow.map((sow: SOWItem, index: number) => (
                  <Box
                    key={index}
                    className="tw-mb-2"
                  >
                    <Typography sx={{ color: themeVariables.primary_main }}>
                      {sow.name}: ${sow.amount}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>{rLIB('No SOW defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddSOW}
                className="tw-mt-2"
              >
                {rLIB('Add SOW')}
              </Button>
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Incentives')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.incentives && us_proposalData.incentives.length > 0 ? (
                us_proposalData.incentives.map((incentive: IncentiveItem, index: number) => (
                  <Box
                    key={index}
                    className="tw-mb-2"
                  >
                    <Typography sx={{ color: themeVariables.primary_main }}>
                      {incentive.name}: ${incentive.amount}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>{rLIB('No Incentive defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddIncentive}
                className="tw-mt-2"
              >
                {rLIB('Add Incentive')}
              </Button>
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Discounts')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.discounts && us_proposalData.discounts.length > 0 ? (
                us_proposalData.discounts.map((discount: DiscountItem, index: number) => (
                  <Box
                    key={index}
                    className="tw-mb-2"
                  >
                    <Typography sx={{ color: themeVariables.primary_main }}>
                      {discount.name}: ${discount.amount}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>{rLIB('No Discount defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddDiscount}
                className="tw-mt-2"
              >
                {rLIB('Add Discount')}
              </Button>
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Pricing')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'dollar-sign',
                rLIB('Base Price ($)'),
                'financials_cash_base_price',
                'financials_cash_base_price',
                rJSX_SimpleEditLineItemIcon,
                '',
                isLunarBattery,
              )}
              {isLunarBattery &&
                rJSX_DetailLineItem(
                  'battery',
                  rLIB('Lunar Battery Cost ($)'),
                  'financials_lunar_battery_cost',
                  'financials_lunar_battery_cost',
                  rJSX_SimpleEditLineItemIcon,
                  '',
                  isLunarBattery,
                )}

              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('Federal Tax Credit'),
                'financials_federal_incentive',
                'financials_federal_incentive',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('State Tax Credit'),
                'financials_srec_incentive',
                'financials_srec_incentive',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'dollar-sign',
                rLIB('Price After Incentives ($)'),
                'financials_cash_price_after_incentives',
                'financials_cash_price_after_incentives',
                rJSX_NotEditableLineItemIcon,
              )}
            </Card>
          </Grid2>
        </Grid2>
        <AdderDialog
          open={us_adderDialogOpen}
          onClose={() => us_setAdderDialogOpen(false)}
          onSave={handleSaveAdder}
          adder={us_currentAdder}
          adderOptions={us_adderOptions}
        />
        <SOWDialog
          open={us_sowDialogOpen}
          onClose={() => us_setSOWDialogOpen(false)}
          onSave={handleSaveSOW}
          sow={us_currentSOW}
          sowOptions={us_sowOptions}
        />
        <IncentiveDialog
          open={us_incentiveDialogOpen}
          onClose={() => us_setIncentiveDialogOpen(false)}
          onSave={handleSaveIncentive}
          incentive={us_currentIncentive}
          incentiveOptions={us_incentiveOptions}
        />
        <DiscountDialog
          open={us_discountDialogOpen}
          onClose={() => us_setDiscountDialogOpen(false)}
          onSave={handleSaveDiscount}
          discount={us_currentDiscount}
          discountOptions={us_discountOptions}
        />
      </Box>
    )
  }

  const rJSX_DocumentsTabContent = (): JSX.Element => {
    let fileSystemSettings: TsInterface_FileSystemSettings = {
      allow_file_archiving: false,
      allow_file_movement: false,
      allow_file_unarchiving: false,
      allow_file_uploads: false,
      allow_folder_creation: false,
      allow_folder_deletion: false,
      allow_folder_movement: false,
      allow_folder_rename: false,
      allow_link_creation: false,
      archive_filter_visible: false,
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin') {
      fileSystemSettings = {
        allow_file_archiving: true,
        allow_file_movement: true,
        allow_file_unarchiving: true,
        allow_file_uploads: true,
        allow_folder_creation: true,
        allow_folder_deletion: true,
        allow_folder_movement: true,
        allow_folder_rename: true,
        allow_link_creation: true,
        archive_filter_visible: true,
      }
    }
    return (
      <Box sx={{ padding: '16px' }}>
        <FileSystemBasic
          fileSystemCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_ProposalFiles_Collection(clientKey, proposalKey)
          }}
          fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
            return DatabaseRef_ProposalFile_Document(clientKey, proposalKey, fileKey)
          }}
          fileSystemStorageEndpoint={(clientKey, fileName) => {
            return StorageRef_ProposalFile(clientKey, proposalKey, fileName)
          }}
          fileSystemHardCodedFolders={{}}
          fileSystemSettings={fileSystemSettings}
        />
      </Box>
    )
  }

  const rJSX_PhotosTabContent = (): JSX.Element => {
    let fileSystemSettings: TsInterface_FileSystemSettings = {
      allow_file_archiving: false,
      allow_file_movement: false,
      allow_file_unarchiving: false,
      allow_file_uploads: false,
      allow_folder_creation: false,
      allow_folder_deletion: false,
      allow_folder_movement: false,
      allow_folder_rename: false,
      allow_link_creation: false,
      archive_filter_visible: false,
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin') {
      fileSystemSettings = {
        allow_file_archiving: true,
        allow_file_movement: true,
        allow_file_unarchiving: true,
        allow_file_uploads: true,
        allow_folder_creation: true,
        allow_folder_deletion: true,
        allow_folder_movement: true,
        allow_folder_rename: true,
        allow_link_creation: true,
        archive_filter_visible: true,
      }
    }
    return (
      <Box>
        <FileSystemBasic
          fileSystemCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_ProposalPhotos_Collection(clientKey, proposalKey)
          }}
          fileSystemDocumentDatabaseEndpoint={(clientKey, photoKey) => {
            return DatabaseRef_ProposalPhoto_Document(clientKey, proposalKey, photoKey)
          }}
          fileSystemStorageEndpoint={(clientKey, fileName) => {
            return StorageRef_ProposalPhoto(clientKey, proposalKey, fileName)
          }}
          fileSystemHardCodedFolders={{}}
          fileSystemSettings={fileSystemSettings}
        />
      </Box>
    )
  }

  const parseExportCreditsCSV = async (file: File): Promise<ExportCreditsData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        try {
          const text = event.target?.result as string
          const lines = text.split('\n')

          // Skip first two rows and use third row (index 2) as headers
          const headers = lines[2].toLowerCase().split(',')
          const monthColumns = headers
            .map((header, index) => ({
              index,
              header: header.trim(),
            }))
            .filter((col) => col.header.startsWith('total - '))

          if (monthColumns.length === 0) {
            throw new Error('CSV must contain columns starting with "total - "')
          }

          const data: ExportCreditsData[] = []

          // Process each row (starting from row 4, which is index 3)
          lines.slice(3).forEach((line, rowIndex) => {
            if (!line.trim()) return // Skip empty lines

            const columns = line.split(',')
            const timeStr = columns[0].trim()

            // Parse time string (assuming 12-hour format)
            const [time, period] = timeStr.split(' ')
            const [hours, minutes] = time.split(':').map((n) => parseInt(n))

            // Convert to 24-hour format
            let hour = hours
            if (period.toLowerCase() === 'pm' && hours !== 12) {
              hour += 12
            } else if (period.toLowerCase() === 'am' && hours === 12) {
              hour = 0
            }

            // Get credits for each month
            monthColumns.forEach((col) => {
              const credits = parseFloat(columns[col.index])
              if (!isNaN(credits)) {
                data.push({
                  time: hour,
                  month: col.header.split(' ')[2].trim(),
                  credits: credits,
                })
              }
            })
          })

          resolve(data)
        } catch (error) {
          reject(error)
        }
      }

      reader.onerror = () => reject(new Error('Failed to read file'))
      reader.readAsText(file)
    })
  }

  // Function to parse CSV and extract relevant data
  const parseUsageCSV = async (file: File): Promise<IntervalData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      // Helper function to parse date strings in the specific format
      const parseDate = (dateStr: string): Date => {
        // Handle both date formats (MM/DD/YYYY HH:mm and MM/DD/YY HH:mm)
        const [datePart, timePart] = dateStr.trim().split(' ')
        const [month, day, year] = datePart.split('/')
        const [hours, minutes] = timePart.split(':')

        // Convert 2-digit year to 4-digit year if necessary
        const fullYear = year.length === 2 ? '20' + year : year

        return new Date(
          parseInt(fullYear),
          parseInt(month) - 1, // Months are 0-based in JavaScript
          parseInt(day),
          parseInt(hours),
          parseInt(minutes),
        )
      }

      // Helper function to aggregate 15-min intervals into hourly data
      const aggregateToHourly = (data: IntervalData[]): IntervalData[] => {
        const hourlyMap = new Map<string, IntervalData>()

        data.forEach((interval) => {
          // Create key for the hour (removing minutes)
          const hourKey = new Date(interval.intervalStart).setMinutes(0, 0, 0).toString()

          if (!hourlyMap.has(hourKey)) {
            hourlyMap.set(hourKey, {
              intervalStart: new Date(parseInt(hourKey)),
              intervalEnd: new Date(new Date(parseInt(hourKey)).setHours(new Date(parseInt(hourKey)).getHours() + 1)),
              intervalKWh: 0,
            })
          }

          // Add the kWh value to the hour's total
          const hourData = hourlyMap.get(hourKey)!
          hourData.intervalKWh += interval.intervalKWh
        })

        return Array.from(hourlyMap.values()).sort((a, b) => a.intervalStart.getTime() - b.intervalStart.getTime())
      }

      const parseCSVLine = (line: string): string[] => {
        const values: string[] = []
        let currentValue = ''
        let insideQuotes = false

        for (let i = 0; i < line.length; i++) {
          const char = line[i]

          if (char === '"') {
            insideQuotes = !insideQuotes
          } else if (char === ',' && !insideQuotes) {
            values.push(currentValue.trim())
            currentValue = ''
          } else {
            currentValue += char
          }
        }

        // Push the last value
        values.push(currentValue.trim())

        // Clean up any quotes from the values
        return values.map((val) => val.replace(/^"|"$/g, ''))
      }

      const findHeaderRow = (lines: string[]): number => {
        const requiredColumns = ['interval_start', 'interval_end', 'interval_kwh']
        for (let i = 0; i < lines.length; i++) {
          const columns = parseCSVLine(lines[i]).map((col) => col.toLowerCase())
          if (requiredColumns.every((required) => columns.some((col) => col.includes(required)))) {
            return i
          }
        }
        throw new Error('Could not find header row with required columns')
      }

      reader.onload = (event) => {
        try {
          const text = event.target?.result as string
          const lines = text.split('\n')
          // Get header row and find column indices
          const headerRowIndex = findHeaderRow(lines)
          const headers = parseCSVLine(lines[headerRowIndex]).map((h) => h.toLowerCase())
          const startIndex = headers.findIndex((h) => h.includes('interval_start'))
          const endIndex = headers.findIndex((h) => h.includes('interval_end'))
          const kWhIndex = headers.findIndex((h) => h.includes('interval_kwh'))

          // Validate required columns exist
          if (startIndex === -1 || endIndex === -1 || kWhIndex === -1) {
            throw new Error('CSV must contain interval_start, interval_end, and interval_kwh columns')
          }

          // Parse all data first
          const rawData: IntervalData[] = lines
            .slice(1) // Skip header row
            .filter((line) => line.trim()) // Remove empty lines
            .map((line) => {
              const columns = parseCSVLine(line)
              try {
                return {
                  intervalStart: parseDate(columns[startIndex]),
                  intervalEnd: parseDate(columns[endIndex]),
                  intervalKWh: parseFloat(columns[kWhIndex]),
                }
              } catch (error) {
                console.error('Error parsing row:', line, error)
                throw new Error(`Failed to parse row: ${line}`)
              }
            })

          // Detect interval length by checking the first two intervals
          if (rawData.length >= 2) {
            const firstInterval = rawData[0].intervalEnd.getTime() - rawData[0].intervalStart.getTime()
            const intervalInMinutes = firstInterval / (1000 * 60)

            // If intervals are 15 minutes, aggregate to hourly
            if (intervalInMinutes === 15) {
              resolve(aggregateToHourly(rawData))
            } else if (intervalInMinutes === 30) {
              resolve(aggregateToHourly(rawData))
            } else if (intervalInMinutes === 60) {
              resolve(rawData)
            } else {
              console.warn(`Unexpected interval length: ${intervalInMinutes} minutes`)
              resolve(rawData)
            }
          } else {
            resolve(rawData)
          }
        } catch (error) {
          reject(error)
        }
      }
      reader.onerror = () => reject(new Error('Failed to read file'))
      reader.readAsText(file)
    })
  }

  const parseGenerationCSV = async (file: File): Promise<GenerationData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      const parseCSVLine = (line: string): string[] => {
        const values: string[] = []
        let currentValue = ''
        let insideQuotes = false

        for (let i = 0; i < line.length; i++) {
          const char = line[i]

          if (char === '"') {
            insideQuotes = !insideQuotes
          } else if (char === ',' && !insideQuotes) {
            values.push(currentValue.trim())
            currentValue = ''
          } else {
            currentValue += char
          }
        }

        values.push(currentValue.trim())
        return values.map((val) => val.replace(/^"|"$/g, ''))
      }

      const findHeaderRow = (lines: string[]): number => {
        const requiredColumns = ['month', 'day', 'hour', 'dc array output (w)', 'ac system output (w)']

        // Check each line until we find one containing all required columns
        for (let i = 0; i < lines.length; i++) {
          const columns = parseCSVLine(lines[i]).map((col) => col.toLowerCase())
          if (requiredColumns.every((required) => columns.some((col) => col.includes(required)))) {
            return i
          }
        }
        throw new Error('Could not find header row with required columns')
      }

      reader.onload = (event) => {
        try {
          const text = event.target?.result as string
          const lines = text.split('\n').filter((line) => line.trim()) // Remove empty lines

          // Find the header row
          const headerRowIndex = findHeaderRow(lines)
          const headers = parseCSVLine(lines[headerRowIndex]).map((h) => h.toLowerCase())

          // Get column indices
          const monthIndex = headers.findIndex((h) => h.includes('month'))
          const dayIndex = headers.findIndex((h) => h.includes('day'))
          const hourIndex = headers.findIndex((h) => h.includes('hour'))
          const dcOutputIndex = headers.findIndex((h) => h.includes('dc array output (w)'))
          const acOutputIndex = headers.findIndex((h) => h.includes('ac system output (w)'))

          // Validate required columns exist
          if (monthIndex === -1 || dayIndex === -1 || hourIndex === -1 || dcOutputIndex === -1 || acOutputIndex === -1) {
            throw new Error('CSV must contain month, day, hour, dc output, and ac output columns')
          }

          // Parse data rows (everything after the header row)
          const data: GenerationData[] = lines.slice(headerRowIndex + 1).map((line) => {
            const columns = parseCSVLine(line)
            try {
              return {
                month: parseInt(columns[monthIndex]),
                day: parseInt(columns[dayIndex]),
                hour: parseInt(columns[hourIndex]),
                dcOutput: parseFloat(columns[dcOutputIndex]),
                acOutput: parseFloat(columns[acOutputIndex]),
              }
            } catch (error) {
              console.error('Error parsing row:', line, error)
              throw new Error(`Failed to parse row: ${line}`)
            }
          })

          resolve(data)
        } catch (error) {
          reject(error)
        }
      }
      reader.onerror = () => reject(new Error('Failed to read file'))
      reader.readAsText(file)
    })
  }

  const calculateBill = (data: IntervalData[], utilityRates: any): BillingAnalysis => {
    // Initialize billing analysis
    const analysis: BillingAnalysis = {
      totalCost: 0,
      totalUsage: 0,
      gridUsage: 0,
      solarUsage: 0,
      batteryUsage: 0,
      exportedToGrid: 0,
      storedInBattery: 0,
      peakSummer: { usage: 0, cost: 0 },
      peakWinter: { usage: 0, cost: 0 },
      partialPeakSummer: { usage: 0, cost: 0 },
      partialPeakWinter: { usage: 0, cost: 0 },
      offPeakSummer: { usage: 0, cost: 0 },
      offPeakWinter: { usage: 0, cost: 0 },
      numberOfIntervals: 0,
      dailyCharges: 0,
      semiAnnualCharges: 0,
    }

    const uniqueDays = new Set<string>()
    const semiAnnualCharge = 55.17

    // Helper function to determine if a date is in summer (May-October)
    const isSummerMonth = (date: Date): boolean => {
      const month = date.getMonth() // 0-11
      return month >= 5 && month <= 8 // June (5) through September (8)
    }

    // Helper function to determine rate period based on hour
    const getRatePeriod = (hour: number): 'peak' | 'partial_peak' | 'off_peak' => {
      if (hour >= 16 && hour < 21) {
        // 4 PM - 9 PM
        return 'peak'
      } else if ((hour >= 15 && hour < 16) || hour >= 21 || hour == 0) {
        // 3 PM - 4 PM or 9 PM - 12 AM
        return 'partial_peak'
      } else {
        return 'off_peak'
      }
    }

    // Process each interval
    data.forEach((interval) => {
      const startHour = interval.intervalStart.getHours()
      const isSummer = isSummerMonth(interval.intervalStart)
      const ratePeriod = getRatePeriod(startHour)
      const usage = interval.intervalKWh

      // Track unique days for daily charge
      const dateKey = interval.intervalStart.toISOString().split('T')[0]
      uniqueDays.add(dateKey)

      // Check for semi-annual charges (April 1st and October 1st)
      const month = interval.intervalStart.getMonth()
      const day = interval.intervalStart.getDate()
      const hour = interval.intervalStart.getHours()
      if ((month === 3 || month === 9) && day === 1 && hour === 0) {
        // 3 = April, 9 = October
        analysis.semiAnnualCharges += semiAnnualCharge
      }

      // Add to total usage
      analysis.totalUsage += usage
      analysis.gridUsage += usage
      analysis.numberOfIntervals += 1

      // Determine rate and add to appropriate period
      if (ratePeriod === 'peak') {
        if (isSummer) {
          analysis.peakSummer.usage += usage
          analysis.peakSummer.cost += usage * utilityRates.peak_summer
        } else {
          analysis.peakWinter.usage += usage
          analysis.peakWinter.cost += usage * utilityRates.peak_winter
        }
      } else if (ratePeriod === 'partial_peak') {
        if (isSummer) {
          analysis.partialPeakSummer.usage += usage
          analysis.partialPeakSummer.cost += usage * utilityRates.partial_peak_summer
        } else {
          analysis.partialPeakWinter.usage += usage
          analysis.partialPeakWinter.cost += usage * utilityRates.partial_peak_winter
        }
      } else {
        // off_peak
        if (isSummer) {
          analysis.offPeakSummer.usage += usage
          analysis.offPeakSummer.cost += usage * utilityRates.offPeak_summer
        } else {
          analysis.offPeakWinter.usage += usage
          analysis.offPeakWinter.cost += usage * utilityRates.offPeak_winter
        }
      }
    })

    // Calculate daily charges
    const dailyCharge = 0.49281
    analysis.dailyCharges = uniqueDays.size * dailyCharge

    // Calculate total cost
    analysis.totalCost =
      analysis.peakSummer.cost +
      analysis.peakWinter.cost +
      analysis.partialPeakSummer.cost +
      analysis.partialPeakWinter.cost +
      analysis.offPeakSummer.cost +
      analysis.offPeakWinter.cost +
      analysis.dailyCharges +
      analysis.semiAnnualCharges

    return analysis
  }

  const calculateCreditRate = (exportCreditsData: ExportCreditsData[], month: number, hour: number): number => {
    // need to know month
    // need to know hour
    let index = 0
    index = 12 * hour + month
    return exportCreditsData[index].credits
  }

  const calculateSolarOnlyBill = (
    data: IntervalData[],
    utilityRates: any,
    generationData: GenerationData[],
    exportCreditsWeekDayData: ExportCreditsData[],
    exportCreditsWeekendData: ExportCreditsData[],
  ): { analysis: BillingAnalysis; consumptionProfile: { [month: number]: { [hour: number]: any } } } => {
    // Initialize billing analysis
    const analysis: BillingAnalysis = {
      totalCost: 0,
      totalUsage: 0,
      gridUsage: 0,
      solarUsage: 0,
      batteryUsage: 0,
      exportedToGrid: 0,
      storedInBattery: 0,
      peakSummer: { usage: 0, cost: 0 },
      peakWinter: { usage: 0, cost: 0 },
      partialPeakSummer: { usage: 0, cost: 0 },
      partialPeakWinter: { usage: 0, cost: 0 },
      offPeakSummer: { usage: 0, cost: 0 },
      offPeakWinter: { usage: 0, cost: 0 },
      numberOfIntervals: 0,
      dailyCharges: 0,
      semiAnnualCharges: 0,
    }

    const consumptionProfile: { [month: number]: { [hour: number]: any } } = {}

    const uniqueDays = new Set<string>()
    const semiAnnualCharge = 55.17

    const isSummerMonth = (date: Date): boolean => {
      const month = date.getMonth()
      return month >= 5 && month <= 8 // June through September
    }

    const isWeekday = (date: Date): boolean => {
      const day = date.getDay()
      return day >= 1 && day <= 5
    }

    const getRatePeriod = (hour: number): 'peak' | 'partial_peak' | 'off_peak' => {
      if (hour >= 16 && hour < 21) {
        return 'peak'
      } else if ((hour >= 15 && hour < 16) || hour >= 21 || hour == 0) {
        return 'partial_peak'
      } else {
        return 'off_peak'
      }
    }

    const findGenerationDataRow = (data: GenerationData[], month: number, day: number, hour: number): GenerationData | undefined => {
      return data.find((row) => row.month === month && row.day === day && row.hour === hour)
    }

    // Process each interval
    data.forEach((interval) => {
      const startHour = interval.intervalStart.getHours()
      const isSummer = isSummerMonth(interval.intervalStart)
      const ratePeriod = getRatePeriod(startHour)
      const isWeekdayValue = isWeekday(interval.intervalStart)
      const currentMonth = interval.intervalStart.getMonth()

      if (!consumptionProfile[currentMonth]) {
        consumptionProfile[currentMonth] = {}
      }
      if (!consumptionProfile[currentMonth][startHour]) {
        consumptionProfile[currentMonth][startHour] = {
          solarGeneration: 0,
          storedInBattery: 0,
          exportedToGrid: 0,
          fromSolar: 0,
          fromGrid: 0,
          fromBattery: 0,
          batteryLevel: 0,
          numBatteryLevelUpdates: 0,
          numSolarGenerationUpdates: 0,
          numGridUsageUpdates: 0,
          numBatteryUsageUpdates: 0,
          numSolarUsageUpdates: 0,
          numExportedToGridUpdates: 0,
          numStoredInBatteryUpdates: 0,
        }
      }

      analysis.totalUsage += interval.intervalKWh

      // Calculate solar production for this hour
      let solarProduction = 0
      const generationRow = findGenerationDataRow(
        generationData,
        interval.intervalStart.getMonth() + 1,
        interval.intervalStart.getDate(),
        interval.intervalStart.getHours(),
      )
      solarProduction = generationRow ? generationRow.acOutput / 1000 : 0

      consumptionProfile[currentMonth][startHour].solarGeneration += solarProduction
      consumptionProfile[currentMonth][startHour].fromSolar += Math.min(solarProduction, interval.intervalKWh)
      consumptionProfile[currentMonth][startHour].numSolarGenerationUpdates += 1
      consumptionProfile[currentMonth][startHour].numSolarUsageUpdates += 1

      analysis.solarUsage += Math.min(solarProduction, interval.intervalKWh)

      // Calculate net usage (negative means export)
      const netUsage = interval.intervalKWh - solarProduction

      // Track unique days for daily charge
      const dateKey = interval.intervalStart.toISOString().split('T')[0]
      uniqueDays.add(dateKey)

      // Check for semi-annual charges
      const month = interval.intervalStart.getMonth()
      const day = interval.intervalStart.getDate()
      if ((month === 3 || month === 9) && day === 1 && startHour === 0) {
        analysis.semiAnnualCharges += semiAnnualCharge
      }

      // Add to total usage (only positive net usage)
      if (netUsage > 0) {
        analysis.gridUsage += netUsage
        analysis.numberOfIntervals += 1

        // Update consumption profile for grid usage
        consumptionProfile[currentMonth][startHour].fromGrid += netUsage
        consumptionProfile[currentMonth][startHour].numGridUsageUpdates += 1

        // Calculate costs based on rate period
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.usage += netUsage
            analysis.peakSummer.cost += netUsage * utilityRates.peak_summer
          } else {
            analysis.peakWinter.usage += netUsage
            analysis.peakWinter.cost += netUsage * utilityRates.peak_winter
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.usage += netUsage
            analysis.partialPeakSummer.cost += netUsage * utilityRates.partial_peak_summer
          } else {
            analysis.partialPeakWinter.usage += netUsage
            analysis.partialPeakWinter.cost += netUsage * utilityRates.partial_peak_winter
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.usage += netUsage
            analysis.offPeakSummer.cost += netUsage * utilityRates.offPeak_summer
          } else {
            analysis.offPeakWinter.usage += netUsage
            analysis.offPeakWinter.cost += netUsage * utilityRates.offPeak_winter
          }
        }
      } else if (netUsage < 0) {
        // Handle export credits
        const exportAmount = Math.abs(netUsage)
        analysis.exportedToGrid += exportAmount
        consumptionProfile[currentMonth][startHour].exportedToGrid += exportAmount
        consumptionProfile[currentMonth][startHour].numExportedToGridUpdates += 1

        // need to know if it's a weekday or weekend
        let creditRate = 1

        if (isWeekday(interval.intervalStart)) {
          creditRate = calculateCreditRate(exportCreditsWeekDayData, month, interval.intervalStart.getHours())
        } else {
          creditRate = calculateCreditRate(exportCreditsWeekendData, month, interval.intervalStart.getHours())
        }

        // Apply export credits
        const creditValue = exportAmount * creditRate

        // Subtract credits from the appropriate period's cost
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.cost -= creditValue
          } else {
            analysis.peakWinter.cost -= creditValue
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.cost -= creditValue
          } else {
            analysis.partialPeakWinter.cost -= creditValue
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.cost -= creditValue
          } else {
            analysis.offPeakWinter.cost -= creditValue
          }
        }
      }
    })

    // Calculate daily charges
    const dailyCharge = 0.49281
    analysis.dailyCharges = uniqueDays.size * dailyCharge

    // Calculate total cost
    analysis.totalCost =
      analysis.peakSummer.cost +
      analysis.peakWinter.cost +
      analysis.partialPeakSummer.cost +
      analysis.partialPeakWinter.cost +
      analysis.offPeakSummer.cost +
      analysis.offPeakWinter.cost +
      analysis.dailyCharges +
      analysis.semiAnnualCharges

    return { analysis, consumptionProfile }
  }

  const calculateSolarPlusBatteryBill_v1 = (
    data: IntervalData[],
    utilityRates: any,
    generationData: GenerationData[],
    exportCreditsWeekDayData: ExportCreditsData[],
    exportCreditsWeekendData: ExportCreditsData[],
    batterySpecs: any,
  ): BillingAnalysis => {
    const analysis: BillingAnalysis = {
      totalCost: 0,
      totalUsage: 0,
      gridUsage: 0,
      solarUsage: 0,
      batteryUsage: 0,
      exportedToGrid: 0,
      storedInBattery: 0,
      peakSummer: { usage: 0, cost: 0 },
      peakWinter: { usage: 0, cost: 0 },
      partialPeakSummer: { usage: 0, cost: 0 },
      partialPeakWinter: { usage: 0, cost: 0 },
      offPeakSummer: { usage: 0, cost: 0 },
      offPeakWinter: { usage: 0, cost: 0 },
      numberOfIntervals: 0,
      dailyCharges: 0,
      semiAnnualCharges: 0,
    }
    const uniqueDays = new Set<string>()
    const semiAnnualCharge = 55.17
    let currentBatteryCharge = batterySpecs.capacity * (batterySpecs.reserveCapacity / 100) // Available capacity

    const isSummerMonth = (date: Date): boolean => {
      const month = date.getMonth()
      return month >= 5 && month <= 8
    }

    const isWeekday = (date: Date): boolean => {
      const day = date.getDay()
      return day >= 1 && day <= 5
    }

    const getRatePeriod = (hour: number): 'peak' | 'partial_peak' | 'off_peak' => {
      if (hour >= 16 && hour < 21) {
        return 'peak'
      } else if ((hour >= 15 && hour < 16) || hour >= 21 || hour == 0) {
        return 'partial_peak'
      } else {
        return 'off_peak'
      }
    }

    const findGenerationDataRow = (data: GenerationData[], month: number, day: number, hour: number): GenerationData | undefined => {
      return data.find((row) => row.month === month && row.day === day && row.hour === hour)
    }

    // Process each interval
    data.forEach((interval) => {
      const startHour = interval.intervalStart.getHours()
      const isSummer = isSummerMonth(interval.intervalStart)
      const ratePeriod = getRatePeriod(startHour)

      analysis.totalUsage += interval.intervalKWh

      // Calculate solar production for this hour
      let solarProduction = 0
      const generationRow = findGenerationDataRow(
        generationData,
        interval.intervalStart.getMonth() + 1,
        interval.intervalStart.getDate(),
        interval.intervalStart.getHours(),
      )
      solarProduction = generationRow ? generationRow.acOutput / 1000 : 0

      analysis.solarUsage += Math.min(solarProduction, interval.intervalKWh)

      // Calculate initial net usage (before battery)
      let netUsage = interval.intervalKWh - solarProduction

      // Battery logic
      if (netUsage < 0) {
        // Excess solar production - charge battery
        const excessEnergy = Math.abs(netUsage)
        const availableCapacity = batterySpecs.capacity - currentBatteryCharge
        const chargeAmount = Math.min(
          excessEnergy * (batterySpecs.efficiency / 100),
          availableCapacity,
          batterySpecs.maxDischargeRate / 4, // Convert hourly rate to 15-min interval
        )

        analysis.storedInBattery += chargeAmount

        currentBatteryCharge += chargeAmount
        netUsage = -(excessEnergy - chargeAmount / (batterySpecs.efficiency / 100)) // Remaining excess goes to grid
      } else if (netUsage > 0) {
        // Energy deficit - try to use battery
        const availableBatteryCharge = currentBatteryCharge - (batterySpecs.capacity * batterySpecs.reserveCapacity) / 100
        const maxDischarge = Math.min(availableBatteryCharge, batterySpecs.maxDischargeRate / 4)
        const dischargeAmount = Math.min(netUsage, maxDischarge)

        analysis.batteryUsage += dischargeAmount
        analysis.storedInBattery -= dischargeAmount

        currentBatteryCharge -= dischargeAmount
        netUsage -= dischargeAmount
      }

      // Track unique days for daily charge
      const dateKey = interval.intervalStart.toISOString().split('T')[0]
      uniqueDays.add(dateKey)

      // Check for semi-annual charges
      const month = interval.intervalStart.getMonth()
      const day = interval.intervalStart.getDate()
      if ((month === 3 || month === 9) && day === 1 && startHour === 0) {
        analysis.semiAnnualCharges += semiAnnualCharge
      }

      // Process remaining net usage
      if (netUsage > 0) {
        // Still need to draw from grid
        analysis.gridUsage += netUsage
        analysis.numberOfIntervals += 1

        // Calculate costs based on rate period
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.usage += netUsage
            analysis.peakSummer.cost += netUsage * utilityRates.peak_summer
          } else {
            analysis.peakWinter.usage += netUsage
            analysis.peakWinter.cost += netUsage * utilityRates.peak_winter
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.usage += netUsage
            analysis.partialPeakSummer.cost += netUsage * utilityRates.partial_peak_summer
          } else {
            analysis.partialPeakWinter.usage += netUsage
            analysis.partialPeakWinter.cost += netUsage * utilityRates.partial_peak_winter
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.usage += netUsage
            analysis.offPeakSummer.cost += netUsage * utilityRates.offPeak_summer
          } else {
            analysis.offPeakWinter.usage += netUsage
            analysis.offPeakWinter.cost += netUsage * utilityRates.offPeak_winter
          }
        }
      } else if (netUsage < 0) {
        // Export excess to grid
        const exportAmount = Math.abs(netUsage)
        analysis.exportedToGrid += exportAmount
        const creditRate = isWeekday(interval.intervalStart)
          ? calculateCreditRate(exportCreditsWeekDayData, interval.intervalStart.getMonth(), interval.intervalStart.getHours())
          : calculateCreditRate(exportCreditsWeekendData, interval.intervalStart.getMonth(), interval.intervalStart.getHours())

        // Apply export credits
        const creditValue = exportAmount * creditRate

        // Subtract credits from the appropriate period's cost
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.cost -= creditValue
          } else {
            analysis.peakWinter.cost -= creditValue
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.cost -= creditValue
          } else {
            analysis.partialPeakWinter.cost -= creditValue
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.cost -= creditValue
          } else {
            analysis.offPeakWinter.cost -= creditValue
          }
        }
      }
    })

    // Calculate daily charges
    const dailyCharge = 0.49281
    analysis.dailyCharges = uniqueDays.size * dailyCharge

    // Calculate total cost
    analysis.totalCost =
      analysis.peakSummer.cost +
      analysis.peakWinter.cost +
      analysis.partialPeakSummer.cost +
      analysis.partialPeakWinter.cost +
      analysis.offPeakSummer.cost +
      analysis.offPeakWinter.cost +
      analysis.dailyCharges +
      analysis.semiAnnualCharges

    return analysis
  }

  const calculateSolarPlusBatteryBill_v2 = (
    data: IntervalData[],
    utilityRates: any,
    generationData: GenerationData[],
    exportCreditsWeekDayData: ExportCreditsData[],
    exportCreditsWeekendData: ExportCreditsData[],
    batterySpecs: any,
  ): { analysis: BillingAnalysis; consumptionProfile: { [month: number]: { [hour: number]: any } } } => {
    const analysis: BillingAnalysis = {
      totalCost: 0,
      totalUsage: 0,
      gridUsage: 0,
      solarUsage: 0,
      batteryUsage: 0,
      exportedToGrid: 0,
      storedInBattery: 0,
      peakSummer: { usage: 0, cost: 0 },
      peakWinter: { usage: 0, cost: 0 },
      partialPeakSummer: { usage: 0, cost: 0 },
      partialPeakWinter: { usage: 0, cost: 0 },
      offPeakSummer: { usage: 0, cost: 0 },
      offPeakWinter: { usage: 0, cost: 0 },
      numberOfIntervals: 0,
      dailyCharges: 0,
      semiAnnualCharges: 0,
    }
    const consumptionProfile: { [month: number]: { [hour: number]: any } } = {}

    const uniqueDays = new Set<string>()
    const semiAnnualCharge = 55.17
    let currentBatteryCharge = batterySpecs.capacity * (batterySpecs.reserveCapacity / 100) // Available capacity

    const isSummerMonth = (date: Date): boolean => {
      const month = date.getMonth()
      return month >= 5 && month <= 8
    }

    const isWeekday = (date: Date): boolean => {
      const day = date.getDay()
      return day >= 1 && day <= 5
    }

    const getRatePeriod = (hour: number): 'peak' | 'partial_peak' | 'off_peak' => {
      if (hour >= 16 && hour < 21) {
        return 'peak'
      } else if ((hour >= 15 && hour < 16) || hour >= 21 || hour == 0) {
        return 'partial_peak'
      } else {
        return 'off_peak'
      }
    }

    const findGenerationDataRow = (data: GenerationData[], month: number, day: number, hour: number): GenerationData | undefined => {
      return data.find((row) => row.month === month && row.day === day && row.hour === hour)
    }

    const shouldDischargeBattery = (
      ratePeriod: 'peak' | 'partial_peak' | 'off_peak',
      isSummer: boolean,
      isWeekday: boolean,
      hour: number,
      month: number,
      currentBatteryCharge: number,
    ): { shouldDischarge: boolean; dischargeAmount: number } => {
      const minimumCharge = (batterySpecs.capacity * batterySpecs.reserveCapacity) / 100
      const availableCharge = Math.max(0, currentBatteryCharge - minimumCharge)

      // If no charge available, don't discharge
      if (availableCharge <= 0) {
        return { shouldDischarge: false, dischargeAmount: 0 }
      }

      // Calculate max discharge based on battery specs
      const maxDischarge = Math.min(availableCharge, batterySpecs.maxDischargeRate)

      // Determine discharge strategy based on rate period
      switch (ratePeriod) {
        case 'peak':
          // Always discharge during peak if we have charge
          return {
            shouldDischarge: true,
            dischargeAmount: maxDischarge,
          }

        case 'partial_peak': {
          // For partial peak, discharge if we have more than 50% of capacity
          // This ensures we save some capacity for upcoming peak periods
          // const partialPeakThreshold = batterySpecs.capacity * 0.3
          // if (currentBatteryCharge > partialPeakThreshold) {
          // Only use up to 50% of max discharge rate for partial peak
          return {
            shouldDischarge: true,
            dischargeAmount: maxDischarge,
          }
        }
        // return { shouldDischarge: false, dischargeAmount: 0 }
        // }

        case 'off_peak': {
          // For off-peak, only discharge if we have excess capacity (>80%)
          // and it's during high-usage hours (e.g., early morning)
          // const offPeakThreshold = batterySpecs.capacity * 0.2
          // const isHighUsageHour = hour >= 6 && hour <= 9 // Early morning hours
          // if (currentBatteryCharge > offPeakThreshold) {
          // Only use up to 25% of max discharge rate for off-peak
          return {
            shouldDischarge: true,
            dischargeAmount: maxDischarge,
          }
        }
        //   return { shouldDischarge: false, dischargeAmount: 0 }
        // }

        default:
          return { shouldDischarge: false, dischargeAmount: 0 }
      }
    }

    // Process each interval
    data.forEach((interval) => {
      const startHour = interval.intervalStart.getHours()
      const isSummer = isSummerMonth(interval.intervalStart)
      const ratePeriod = getRatePeriod(startHour)
      const isWeekdayValue = isWeekday(interval.intervalStart)
      const currentMonth = interval.intervalStart.getMonth()

      // Initialize month and hour if not already done
      if (!consumptionProfile[currentMonth]) {
        consumptionProfile[currentMonth] = {}
      }
      if (!consumptionProfile[currentMonth][startHour]) {
        consumptionProfile[currentMonth][startHour] = {
          solarGeneration: 0,
          storedInBattery: 0,
          exportedToGrid: 0,
          fromSolar: 0,
          fromGrid: 0,
          fromBattery: 0,
          batteryLevel: 0,
          numBatteryLevelUpdates: 0,
          numSolarGenerationUpdates: 0,
          numGridUsageUpdates: 0,
          numBatteryUsageUpdates: 0,
          numSolarUsageUpdates: 0,
          numExportedToGridUpdates: 0,
          numStoredInBatteryUpdates: 0,
        }
      }

      analysis.totalUsage += interval.intervalKWh

      // Calculate solar production for this hour
      let solarProduction = 0
      const generationRow = findGenerationDataRow(
        generationData,
        interval.intervalStart.getMonth() + 1,
        interval.intervalStart.getDate(),
        interval.intervalStart.getHours(),
      )
      solarProduction = generationRow ? generationRow.acOutput / 1000 : 0

      consumptionProfile[currentMonth][startHour].solarGeneration += solarProduction
      consumptionProfile[currentMonth][startHour].fromSolar += Math.min(solarProduction, interval.intervalKWh)
      consumptionProfile[currentMonth][startHour].numSolarGenerationUpdates += 1
      consumptionProfile[currentMonth][startHour].numSolarUsageUpdates += 1
      analysis.solarUsage += Math.min(solarProduction, interval.intervalKWh)

      // Calculate initial net usage (before battery)
      let netUsage = interval.intervalKWh - solarProduction

      // Modified battery logic with ToU optimization
      if (netUsage < 0) {
        const excessEnergy = Math.abs(netUsage)
        //const minimumCharge = (batterySpecs.capacity * batterySpecs.reserveCapacity) / 100
        const availableCapacity = Math.min(
          batterySpecs.capacity - currentBatteryCharge,
          batterySpecs.maxDischargeRate, // This is per hour now
        )
        const chargeAmount = Math.min(excessEnergy * (batterySpecs.efficiency / 100), availableCapacity)

        // Verify we're not charging beyond capacity
        if (currentBatteryCharge + chargeAmount <= batterySpecs.capacity) {
          analysis.storedInBattery += chargeAmount
          consumptionProfile[currentMonth][startHour].storedInBattery += chargeAmount
          consumptionProfile[currentMonth][startHour].numStoredInBatteryUpdates += 1

          currentBatteryCharge += chargeAmount
          consumptionProfile[currentMonth][startHour].batteryLevel = currentBatteryCharge // Changed from += to =
          consumptionProfile[currentMonth][startHour].numBatteryLevelUpdates += 1

          netUsage = -(excessEnergy - chargeAmount / (batterySpecs.efficiency / 100))
        }
      } else if (netUsage > 0) {
        const batteryDecision = shouldDischargeBattery(ratePeriod, isSummer, isWeekdayValue, startHour, currentMonth, currentBatteryCharge)

        if (batteryDecision.shouldDischarge) {
          const dischargeAmount = Math.min(
            batteryDecision.dischargeAmount,
            netUsage, // Don't discharge more than we need
          )

          // Verify we're not discharging below minimum
          const minimumCharge = (batterySpecs.capacity * batterySpecs.reserveCapacity) / 100
          if (currentBatteryCharge - dischargeAmount >= minimumCharge) {
            analysis.batteryUsage += dischargeAmount
            analysis.storedInBattery -= dischargeAmount
            consumptionProfile[currentMonth][startHour].fromBattery += dischargeAmount
            consumptionProfile[currentMonth][startHour].numBatteryUsageUpdates += 1

            currentBatteryCharge -= dischargeAmount
            consumptionProfile[currentMonth][startHour].batteryLevel = currentBatteryCharge
            consumptionProfile[currentMonth][startHour].numBatteryLevelUpdates += 1

            netUsage -= dischargeAmount
          }
        }
      }

      // Track unique days for daily charge
      const dateKey = interval.intervalStart.toISOString().split('T')[0]
      uniqueDays.add(dateKey)

      // Check for semi-annual charges
      const month = interval.intervalStart.getMonth()
      const day = interval.intervalStart.getDate()
      if ((month === 3 || month === 9) && day === 1 && startHour === 0) {
        analysis.semiAnnualCharges += semiAnnualCharge
      }

      // Process remaining net usage
      if (netUsage > 0) {
        // Still need to draw from grid
        analysis.gridUsage += netUsage
        consumptionProfile[currentMonth][startHour].fromGrid += netUsage
        consumptionProfile[currentMonth][startHour].numGridUsageUpdates += 1
        analysis.numberOfIntervals += 1

        // Calculate costs based on rate period
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.usage += netUsage
            analysis.peakSummer.cost += netUsage * utilityRates.peak_summer
          } else {
            analysis.peakWinter.usage += netUsage
            analysis.peakWinter.cost += netUsage * utilityRates.peak_winter
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.usage += netUsage
            analysis.partialPeakSummer.cost += netUsage * utilityRates.partial_peak_summer
          } else {
            analysis.partialPeakWinter.usage += netUsage
            analysis.partialPeakWinter.cost += netUsage * utilityRates.partial_peak_winter
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.usage += netUsage
            analysis.offPeakSummer.cost += netUsage * utilityRates.offPeak_summer
          } else {
            analysis.offPeakWinter.usage += netUsage
            analysis.offPeakWinter.cost += netUsage * utilityRates.offPeak_winter
          }
        }
      } else if (netUsage < 0) {
        // Export excess to grid
        const exportAmount = Math.abs(netUsage)
        analysis.exportedToGrid += exportAmount
        consumptionProfile[currentMonth][startHour].exportedToGrid += exportAmount
        consumptionProfile[currentMonth][startHour].numExportedToGridUpdates += 1

        const creditRate = isWeekday(interval.intervalStart)
          ? calculateCreditRate(exportCreditsWeekDayData, interval.intervalStart.getMonth(), interval.intervalStart.getHours())
          : calculateCreditRate(exportCreditsWeekendData, interval.intervalStart.getMonth(), interval.intervalStart.getHours())

        // Apply export credits
        const creditValue = exportAmount * creditRate

        // Subtract credits from the appropriate period's cost
        if (ratePeriod === 'peak') {
          if (isSummer) {
            analysis.peakSummer.cost -= creditValue
          } else {
            analysis.peakWinter.cost -= creditValue
          }
        } else if (ratePeriod === 'partial_peak') {
          if (isSummer) {
            analysis.partialPeakSummer.cost -= creditValue
          } else {
            analysis.partialPeakWinter.cost -= creditValue
          }
        } else {
          if (isSummer) {
            analysis.offPeakSummer.cost -= creditValue
          } else {
            analysis.offPeakWinter.cost -= creditValue
          }
        }
      }
    })

    // Calculate daily charges
    const dailyCharge = 0.49281
    analysis.dailyCharges = uniqueDays.size * dailyCharge

    // Calculate total cost
    analysis.totalCost =
      analysis.peakSummer.cost +
      analysis.peakWinter.cost +
      analysis.partialPeakSummer.cost +
      analysis.partialPeakWinter.cost +
      analysis.offPeakSummer.cost +
      analysis.offPeakWinter.cost +
      analysis.dailyCharges +
      analysis.semiAnnualCharges

    return { analysis, consumptionProfile }
  }

  const handleUsageFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    try {
      const usageData = await parseUsageCSV(file)
      us_setUsageData(usageData)
      //TODO: maybe save to opportunity?
    } catch (error) {
      console.error('Error processing file:', error)
    }
  }

  const handleGenerationFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    try {
      const generationData = await parseGenerationCSV(file)
      us_setGenerationData(generationData)
      //TODO: maybe save to opportunity?
    } catch (error) {
      console.error('Error processing file:', error)
    }
  }

  const handleNoSolarBill = () => {
    const stats = calculateBill(us_usageData, UTILITY_RATES)
    us_setBillingAnalysis(stats)
  }
  //calculate just solar
  const handleSolarOnlyBill = async () => {
    const { analysis: solarOnlyBill, consumptionProfile: solarOnlyBill_consumptionProfile } = calculateSolarOnlyBill(
      us_usageData,
      UTILITY_RATES,
      us_generationData,
      us_exportCreditsWeekDayData,
      us_exportCreditsWeekendData,
    )
    us_setSolarOnlyBill(solarOnlyBill)
    us_setSolarOnlyBill_consumptionProfile(solarOnlyBill_consumptionProfile)

    //TODO: save to proposal
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, us_proposalData.key), {
          solar_only_bill_data: solarOnlyBill,
          solar_only_bill_consumption_profile: solarOnlyBill_consumptionProfile,
        })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleSolarPlusBatteryBill = () => {
    const solarPlusBatteryBill_v1 = calculateSolarPlusBatteryBill_v1(
      us_usageData,
      UTILITY_RATES,
      us_generationData,
      us_exportCreditsWeekDayData,
      us_exportCreditsWeekendData,
      BATTERY_SPECS,
    )
    const { analysis: solarPlusBatteryBill_v2, consumptionProfile: solarPlusBatteryBill_v2_consumptionProfile } = calculateSolarPlusBatteryBill_v2(
      us_usageData,
      UTILITY_RATES,
      us_generationData,
      us_exportCreditsWeekDayData,
      us_exportCreditsWeekendData,
      BATTERY_SPECS,
    )
    us_setSolarPlusBatteryBill_v2_consumptionProfile(solarPlusBatteryBill_v2_consumptionProfile)
    us_setSolarPlusBatteryBill_v1(solarPlusBatteryBill_v1)
    us_setSolarPlusBatteryBill_v2(solarPlusBatteryBill_v2)

    //TODO: save to opportunity
    //TODO: save to proposal
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, us_proposalData.key), {
          solar_plus_battery_bill_data: solarPlusBatteryBill_v2,
          solar_plus_battery_bill_consumption_profile: solarPlusBatteryBill_v2_consumptionProfile,
        })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })

    const data = Object.entries(solarPlusBatteryBill_v2_consumptionProfile[0] || {}).map(([hour, data]) => ({
      name: `${hour}:00`,
      Solar: data.fromSolar > 0 ? data.fromSolar / 31 : 0,
      Grid: data.fromGrid > 0 ? data.fromGrid / 31 : 0,
      Battery: data.fromBattery > 0 ? data.fromBattery / 31 : 0,
      Charging: data.storedInBattery > 0 ? data.storedInBattery / 31 : 0,
      Exporting: data.exportedToGrid > 0 ? data.exportedToGrid / 31 : 0,
    }))
    us_setData(data)
  }

  const prepareDonutData = (
    consumptionProfile: { [month: number]: { [hour: number]: any } } | null,
    selectedMonth: number | null,
    selectedHour: number | null,
  ) => {
    if (!consumptionProfile || selectedMonth === null || selectedHour === null) {
      return { totalData: [], breakdownData: [] }
    }

    const hourData = consumptionProfile[selectedMonth][selectedHour]

    // Helper function to format values
    const formatValue = (value: number): number => {
      return Number((value / 31).toFixed(2))
    }

    // Calculate individual values
    const solarToHome = formatValue(hourData.fromSolar || 0)
    const gridToHome = formatValue(hourData.fromGrid || 0)
    const batteryToHome = formatValue(hourData.fromBattery || 0)
    const solarToBattery = formatValue(hourData.storedInBattery || 0)
    const solarToGrid = formatValue(hourData.exportedToGrid || 0)

    // Calculate total
    const total = solarToHome + gridToHome + batteryToHome + solarToBattery + solarToGrid

    // Prepare data for outer ring (total)
    const totalData = [{ name: 'Total Energy Flow', value: total }]

    // Prepare data for inner ring (breakdown)
    const breakdownData = [
      { name: 'Solar to Home', value: solarToHome, color: '#ffd700' },
      { name: 'Grid to Home', value: gridToHome, color: '#4169e1' },
      { name: 'Battery to Home', value: batteryToHome, color: '#32cd32' },
      { name: 'Solar to Battery', value: solarToBattery, color: '#98fb98' },
      { name: 'Solar to Grid', value: solarToGrid, color: '#9370db' },
    ].filter((item) => item.value > 0) // Remove zero values

    return { totalData, breakdownData }
  }

  // Custom tooltip component
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card className="tw-p-2 tw-bg-white">
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>{`${payload[0].name}: ${payload[0].value.toFixed(2)} kWh`}</Typography>
        </Card>
      )
    }
    return null
  }

  // Add this JSX component
  const rJSX_ConsumptionProfileDonutChart = (): JSX.Element => {
    const currentProfile = us_selectedConsumptionProfile === 'solar_only' ? us_solarOnlyBill_consumptionProfile : us_solarPlusBatteryBill_v2_consumptionProfile

    return (
      <Box>
        <Typography
          variant="h6"
          className="tw-opacity-50 tw-mb-0"
        >
          {rLIB('Energy Flow Profile (Donut)')}
        </Typography>
        <Card className="tw-p-2">
          <Grid
            container
            spacing={3}
          >
            {/*Profile Selection */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl
                fullWidth
                sx={{
                  'backgroundColor': 'white',
                  'borderRadius': 1,
                  '& .MuiInputLabel-root': {
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                  '& .MuiSelect-select': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              >
                <InputLabel>Profile Type</InputLabel>
                <Select
                  value={us_selectedConsumptionProfile}
                  onChange={(e) => {
                    us_setSelectedConsumptionProfile(e.target.value as 'solar_only' | 'solar_battery')
                    // Reset month and hour selections when switching profiles
                    us_setSelectedMonth(0)
                    us_setSelectedHour(0)
                  }}
                  label="Profile Type"
                >
                  <MenuItem value="solar_only">Solar Only</MenuItem>
                  <MenuItem value="solar_battery">Solar + Battery</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Month Selection  */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl
                fullWidth
                sx={{
                  'backgroundColor': 'white',
                  'borderRadius': 1,
                  '& .MuiInputLabel-root': {
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                  '& .MuiSelect-select': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              >
                <InputLabel>Month</InputLabel>
                <Select
                  value={us_selectedMonth ?? ''}
                  onChange={(e) => us_setSelectedMonth(Number(e.target.value))}
                  label="Month"
                >
                  {Object.keys(currentProfile || {}).map((month) => (
                    <MenuItem
                      key={month}
                      value={month}
                    >
                      {new Date(2024, Number(month), 1).toLocaleString('default', { month: 'long' })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Hour Selection */}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl
                fullWidth
                sx={{
                  'backgroundColor': 'white',
                  'borderRadius': 1,
                  '& .MuiInputLabel-root': {
                    color: 'rgba(0, 0, 0, 0.6)',
                  },
                  '& .MuiSelect-select': {
                    color: 'rgba(0, 0, 0, 0.87)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              >
                <InputLabel>Hour</InputLabel>
                <Select
                  value={us_selectedHour ?? ''}
                  onChange={(e) => us_setSelectedHour(Number(e.target.value))}
                  label="Hour"
                >
                  {us_selectedMonth !== null &&
                    Object.keys(currentProfile?.[us_selectedMonth] || {}).map((hour) => (
                      <MenuItem
                        key={hour}
                        value={hour}
                      >
                        {`${hour}:00`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
            >
              {us_solarPlusBatteryBill_v2_consumptionProfile && us_selectedMonth !== null && us_selectedHour !== null && (
                <Card
                  className="tw-p-4"
                  style={{ width: '100%', height: '600px' }} // Increased height to accommodate legend
                  sx={{ backgroundColor: 'white' }}
                >
                  <ResponsiveContainer
                    width="100%"
                    height="80%" // Reduced to make room for legend
                  >
                    <PieChart>
                      {/* Outer ring (total) */}
                      <Pie
                        data={prepareDonutData(currentProfile, us_selectedMonth, us_selectedHour).totalData}
                        cx="50%"
                        cy="50%"
                        outerRadius={'90%'}
                        innerRadius={'80%'}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                      >
                        <Cell fill="#808080" />
                      </Pie>

                      {/* Inner ring (breakdown) */}
                      <Pie
                        data={prepareDonutData(currentProfile, us_selectedMonth, us_selectedHour).breakdownData}
                        cx="50%"
                        cy="50%"
                        innerRadius={'60%'}
                        outerRadius={'75%'}
                        fill="#8884d8"
                        paddingAngle={2}
                        dataKey="value"
                      >
                        {prepareDonutData(currentProfile, us_selectedMonth, us_selectedHour).breakdownData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                          />
                        ))}
                      </Pie>
                      <RTooltip content={<CustomTooltip />} />
                    </PieChart>
                  </ResponsiveContainer>

                  {/* Legend */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    {[
                      { label: 'Solar to Home', color: '#ffd700' },
                      { label: 'Grid to Home', color: '#4169e1' },
                      { label: 'Battery to Home', color: '#32cd32' },
                      { label: 'Solar to Battery', color: '#98fb98' },
                      { label: 'Solar to Grid', color: '#9370db' },
                    ].map((item) => (
                      <Box
                        key={item.label}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 16,
                            height: 16,
                            backgroundColor: item.color,
                            borderRadius: '4px',
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Card>
              )}
            </Grid>
          </Grid>
        </Card>
      </Box>
    )
  }

  const rJSX_UsageAndProductionTabContent = (): JSX.Element => {
    return (
      <Box sx={{ padding: 2 }}>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3 }}
          spacing={2}
        >
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Usage File')}
            </Typography>
            <Card className="tw-p-2">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                This usage comes from Utility API. Download the CSV, can be in any interval between 1 min and 1 hour.
              </Typography>
              <input
                type="file"
                accept=".csv"
                onChange={handleUsageFileUpload}
                className="tw-mb-4"
              />
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Generation File')}
            </Typography>
            <Card className="tw-p-2">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                This info comes from the PVWatts Calculator. Use the 1 hour interval.
              </Typography>
              <input
                type="file"
                accept=".csv"
                onChange={handleGenerationFileUpload}
                className="tw-mb-4"
              />
            </Card>
          </Box>
          <Box
          // xs={12}
          // sm={6}
          // md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Calculation Buttons')}
            </Typography>
            <Card className="tw-p-2">
              <Button
                disabled={us_usageData == null}
                onClick={handleNoSolarBill}
              >
                No Solar Bill
              </Button>
              <Button
                disabled={us_generationData == null}
                onClick={handleSolarOnlyBill}
              >
                Calculate Solar Only Bill
              </Button>
              <Button
                disabled={us_generationData == null}
                onClick={handleSolarPlusBatteryBill}
              >
                Calculate Solar Plus Battery Bill
              </Button>
            </Card>
          </Box>
          {us_billingAnalysis && (
            <Box
            // xs={12}
            // sm={6}
            // md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {rLIB('No Solar Bill')}
              </Typography>
              <Card className="tw-p-2">
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Typography variant="body1">Total Cost: ${us_billingAnalysis.totalCost.toFixed(2)}</Typography>
                  <Typography variant="body1">Total Usage: {us_billingAnalysis.totalUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Grid Usage: {us_billingAnalysis.gridUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Solar Usage: {us_billingAnalysis.solarUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Battery Usage: {us_billingAnalysis.batteryUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Exported to Grid: {us_billingAnalysis.exportedToGrid.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Stored in Battery: {us_billingAnalysis.storedInBattery.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Daily Charges: ${us_billingAnalysis.dailyCharges.toFixed(2)}</Typography>
                  <Typography variant="body1">Semi-Annual Charges: ${us_billingAnalysis.semiAnnualCharges.toFixed(2)}</Typography>
                </Grid>
              </Card>
            </Box>
          )}
          {us_solarOnlyBill && (
            <Box
            // xs={12}
            // sm={6}
            // md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {rLIB('Solar Only Bill')}
              </Typography>
              <Card className="tw-p-2">
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Typography variant="body1">Total Cost: ${us_solarOnlyBill.totalCost.toFixed(2)}</Typography>
                  <Typography variant="body1">Total Usage: {us_solarOnlyBill.totalUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Grid Usage: {us_solarOnlyBill.gridUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Solar Usage: {us_solarOnlyBill.solarUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Battery Usage: {us_solarOnlyBill.batteryUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Exported to Grid: {us_solarOnlyBill.exportedToGrid.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Stored in Battery: {us_solarOnlyBill.storedInBattery.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Daily Charges: ${us_solarOnlyBill.dailyCharges.toFixed(2)}</Typography>
                  <Typography variant="body1">Semi-Annual Charges: ${us_solarOnlyBill.semiAnnualCharges.toFixed(2)}</Typography>
                </Grid>
              </Card>
            </Box>
          )}
          {us_solarPlusBatteryBill_v1 && (
            <Box
            // xs={12}
            // sm={6}
            // md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {rLIB('Solar Plus Battery Bill (BRUTE FORCE)')}
              </Typography>
              <Card className="tw-p-2">
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Typography variant="body1">Total Cost: ${us_solarPlusBatteryBill_v1.totalCost.toFixed(2)}</Typography>
                  <Typography variant="body1">Total Usage: {us_solarPlusBatteryBill_v1.totalUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Grid Usage: {us_solarPlusBatteryBill_v1.gridUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Solar Usage: {us_solarPlusBatteryBill_v1.solarUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Battery Usage: {us_solarPlusBatteryBill_v1.batteryUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Exported to Grid: {us_solarPlusBatteryBill_v1.exportedToGrid.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Stored in Battery: {us_solarPlusBatteryBill_v1.storedInBattery.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Daily Charges: ${us_solarPlusBatteryBill_v1.dailyCharges.toFixed(2)}</Typography>
                  <Typography variant="body1">Semi-Annual Charges: ${us_solarPlusBatteryBill_v1.semiAnnualCharges.toFixed(2)}</Typography>
                </Grid>
              </Card>
            </Box>
          )}
          {us_solarPlusBatteryBill_v2 && (
            <Box
            // xs={12}
            // sm={6}
            // md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {rLIB('Solar Plus Battery Bill (OPTIMIZED)')}
              </Typography>
              <Card className="tw-p-2">
                {/* Total Summary Card */}
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Typography variant="body1">Total Cost: ${us_solarPlusBatteryBill_v2.totalCost.toFixed(2)}</Typography>
                  <Typography variant="body1">Total Usage: {us_solarPlusBatteryBill_v2.totalUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Grid Usage: {us_solarPlusBatteryBill_v2.gridUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Solar Usage: {us_solarPlusBatteryBill_v2.solarUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Battery Usage: {us_solarPlusBatteryBill_v2.batteryUsage.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Exported to Grid: {us_solarPlusBatteryBill_v2.exportedToGrid.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Stored in Battery: {us_solarPlusBatteryBill_v2.storedInBattery.toFixed(2)} kWh</Typography>
                  <Typography variant="body1">Daily Charges: ${us_solarPlusBatteryBill_v2.dailyCharges.toFixed(2)}</Typography>
                  <Typography variant="body1">Semi-Annual Charges: ${us_solarPlusBatteryBill_v2.semiAnnualCharges.toFixed(2)}</Typography>
                </Grid>
              </Card>
            </Box>
          )}
          {us_solarPlusBatteryBill_v2_consumptionProfile && <Box>{rJSX_ConsumptionProfileDonutChart()}</Box>}
        </Masonry>
      </Box>
    )
  }

  const rJSX_Dialog = (): JSX.Element => {
    return (
      <Box>
        <Dialog
          className="bp_dialog_full_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar position="static">
            <Toolbar>
              <IconButton
                disabled
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="file-signature" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {rLIB('Proposal')}: {getProp(us_proposalData, 'name', '')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box>
              <TabsBasic
                tabs={[
                  { tabHeader: rLIB('System'), tabContent: <Box>{rJSX_ProposalSystemTabContent()}</Box> },
                  { tabHeader: rLIB('Pricing'), tabContent: <Box>{rJSX_PricingTabContent()}</Box> },
                  { tabHeader: rLIB('Documents'), tabContent: <Box>{rJSX_DocumentsTabContent()}</Box> },
                  { tabHeader: rLIB('Photos'), tabContent: <Box>{rJSX_PhotosTabContent()}</Box> },
                  { tabHeader: rLIB('Logs'), tabContent: <Box></Box> },
                  { tabHeader: rLIB('Usage'), tabContent: <Box>{rJSX_UsageAndProductionTabContent()}</Box> },
                ]}
                tabsSettings={{}}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
  }

  return rJSX_Dialog()
}
