import { Box, Button, Divider, Switch, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Vehicle_Document } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString } from 'rfbp_core/components/search'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumn,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
  TsType_TableSortBy,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import ParkedLocationCustomDialog from '../dialogs/dialog_parked_location'
import VehicleAssignmentHistoryDialog from '../dialogs/dialog_vehicle_assignment_history'
import FieldStatusToggleVehicleAssignmentDialog from '../dialogs/dialog_vehicle_field_status_toggle'
import VehicleAssignmentInTableDialog from '../dialogs/dialog_vehicle_in_table_assign'

export const TableCellEquipment = (propKey: string, propName: string | JSX.Element, sortBy: TsType_TableSortBy): TsInterface_TableColumn => {
  let equipmentCell = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const formatEquipment = (equipment: any) => {
          return Object.keys(equipment)
            .filter((key) => equipment[key] === true)
            .map((key) =>
              key
                .replace(/_/g, ' ')
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' '),
            )
            .join(', ')
        }
        const equipmentString = rowData[propKey] ? formatEquipment(rowData[propKey]) : ''

        return <>{equipmentString}</>
      },
    },
  }

  return equipmentCell
}

export const tableColumns_Rentals: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseRentalViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Rental'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure you want to delete this rental?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  const updateObject = { status: 'deleted' }
                  DatabaseSetMergeDocument(DatabaseRef_Vehicle_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then(() => resolve({ success: true }))
                    .catch((error) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                      reject(error)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: rLIB('Undelete Rental'),
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: rLIB('Undelete'),
              text: rLIB('Are you sure you want to undelete this rental?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  const updateObject = { status: 'active' }
                  DatabaseSetMergeDocument(DatabaseRef_Vehicle_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then(() => resolve({ success: true }))
                    .catch((error) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
                      reject(error)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: {
    header: {
      header_jsx: () => rLIB('Name'),
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        return (
          <Box
            className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
            sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
            onClick={() => {
              if (rowData.key != null) {
                directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseRentalViewPage.url(rowData.key as string))
              }
            }}
          >
            {rowData.name || <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}
          </Box>
        )
      },
    },
  },
  vin: {
    header: {
      header_jsx: () => rLIB('Vin'),
      header_sort_by: 'vin',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        return <Box>{rowData.vin || <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },
  associated_user_name: {
    header: {
      header_jsx: () => rLIB('Driver Name'),
      header_sort_by: 'associated_user_name',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        return <Box>{rowData.associated_user_name || <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },
  rental_company_name: {
    header: {
      header_jsx: () => rLIB('Rental Company Name'),
      header_sort_by: 'rental_company_name',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        return <Box>{rowData.rental_company_name || <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },
  rental_date_time: {
    header: {
      header_jsx: () => rLIB('Rental Date-Time'),
      header_sort_by: 'rental_date_time',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        const dateValue =
          typeof rowData.rental_date_time === 'string' || typeof rowData.rental_date_time === 'number' ? new Date(rowData.rental_date_time) : null
        return <Box>{dateValue ? dateValue.toLocaleString() : <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },

  return_date_time: {
    header: {
      header_jsx: () => rLIB('Return Date-Time'),
      header_sort_by: 'return_date_time',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        const dateValue =
          typeof rowData.return_date_time === 'string' || typeof rowData.return_date_time === 'number' ? new Date(rowData.return_date_time) : null
        return <Box>{dateValue ? dateValue.toLocaleString() : <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },

  status: {
    header: {
      header_jsx: () => rLIB('Status'),
      header_sort_by: 'status', // Sorting can use this field if needed
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
          const newStatus = event.target.checked ? 'active' : 'inactive'

          // Debugging: Log values to inspect
          console.log('Toggle Status Changed')
          console.log('Current Row Data:', rowData)
          console.log('New Status:', newStatus)
          console.log('Table Additional Data:', tableAdditionalData)

          // Update status in the database
          getClientKey(tableAdditionalData.uc_RootData_ClientKey, tableAdditionalData.uc_setRootData_ClientKey)
            .then((clientKeyResponse) => {
              const clientKey = clientKeyResponse.clientKey // Extract the actual key
              console.log('Client Key Retrieved:', clientKey)

              return DatabaseSetMergeDocument(
                DatabaseRef_Vehicle_Document(clientKey, rowData.key as any), // Pass the extracted clientKey
                { status: newStatus },
              )
            })
            .then(() => {
              console.log('Status updated successfully:', newStatus)
            })
            .catch((error) => {
              console.error('Error updating status:', error)

              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: error.error,
              })
            })
        }

        return (
          <Switch
            checked={rowData.status === 'active'} // Determines if the switch is on or off
            onChange={handleToggle} // Triggered when the switch is toggled
            color="primary"
            inputProps={{ 'aria-label': 'Status Toggle' }}
          />
        )
      },
    },
  },

  rental_location: {
    header: {
      header_jsx: () => rLIB('Rental Location'),
      header_sort_by: 'rental_location',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        return <Box>{rowData.rental_location || <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },
  total_charges: {
    header: {
      header_jsx: () => rLIB('Total Charges'),
      header_sort_by: 'total_charges',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        const value = rowData.total_charges
        return (
          <Box>
            {value != null ? (
              value.toLocaleString(undefined, { style: 'currency', currency: 'USD' })
            ) : (
              <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>
            )}
          </Box>
        )
      },
    },
  },
  vehicle_make: {
    header: {
      header_jsx: () => rLIB('Vehicle Make'),
      header_sort_by: 'vehicle_make',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        return <Box>{rowData.vehicle_make || <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },
  vehicle_model: {
    header: {
      header_jsx: () => rLIB('Vehicle Model'),
      header_sort_by: 'vehicle_model',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        return <Box>{rowData.vehicle_model || <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },
  vehicle_year: {
    header: {
      header_jsx: () => rLIB('Vehicle Year'),
      header_sort_by: 'vehicle_year',
    },
    cell: {
      cell_jsx: (rowData, tableAdditionalData, tableHooks) => {
        return <Box>{rowData.vehicle_year || <span className="tw-italic tw-text-error_light">{rLIB('Missing')}</span>}</Box>
      },
    },
  },
}

export const tableColumns_Vehicles: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseVehicleViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Vehicle'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this vehicle?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    status: 'deleted',
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Vehicle_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: rLIB('Undelete Vehicle'),
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: rLIB('Undelete'),
              text: rLIB('Are you sure you want to undelete this trailer?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'active',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_Vehicle_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Vehicle Name')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseVehicleViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  vin: TableCellBasic('vin', rLIB('Vin'), 'vin'),

  field_status: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Field Status')
      },
      header_sort_by: 'field_status',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const handleFieldStatusChange = (event: any) => {
          if (event != null && event.target != null && event.target.checked != null && rowData != null && (rowData as { key: string }).key != null) {
            if (event.target.checked) {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <FieldStatusToggleVehicleAssignmentDialog
                      us_vehicle={rowData as { key: string; name: string }}
                      tableHooks={tableHooks}
                      uc_setUserInterface_CustomDialogDisplay={tableHooks.uc_setUserInterface_CustomDialogDisplay}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                    header_color: 'success',
                    header_text: `Assign Driver for ${rowData.name}`,
                    icon: <Icon icon="pen-to-square" />,
                  },
                },
              })
            } else {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <ParkedLocationCustomDialog
                      us_vehicle={rowData as { key: string; name: string }}
                      tableHooks={tableHooks}
                      uc_setUserInterface_CustomDialogDisplay={tableHooks.uc_setUserInterface_CustomDialogDisplay}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                    header_color: 'info',
                    header_text: `Enter Parked Location for ${rowData.name}`,
                    icon: <Icon icon="pen-to-square" />,
                  },
                },
              })
            }
          }
        }

        let cellJSX = (
          <>
            <Switch
              checked={(rowData.field_status as boolean) || false}
              onChange={handleFieldStatusChange}
            />
            {rowData.field_status ? rLIB('Active') : rLIB('Parked')}
          </>
        )

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  spending_allowed: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Spending Allowed')
      },
      header_sort_by: 'spending_allowed',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const handleFieldStatusChange = (event: any) => {
          if (event != null && event.target != null && event.target.checked != null && rowData != null && (rowData as { key: string }).key != null) {
            let updateObject = {
              spending_allowed: event.target.checked,
            }
            DatabaseSetMergeDocument(DatabaseRef_Vehicle_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
              .then((res_DSMD) => {
                // tableHooks.uc_setUserInterface_SuccessDialogDisplay({ display: true, success: rLIB('Vehicle Spending Status Updated') })
              })
              .catch((rej_DSMD) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          }
        }

        let cellJSX = (
          <>
            <Switch
              checked={(rowData.spending_allowed as boolean) || false}
              onChange={handleFieldStatusChange}
            />
            {rowData.spending_allowed ? rLIB('Allowed') : rLIB('Blocked')}
          </>
        )

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  associated_user_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Assigned To')
      },
      header_sort_by: 'associated_user_name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const manageUserIcon = (
          <Icon
            icon="pen-to-square"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            tooltip={'Edit'}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <VehicleAssignmentInTableDialog
                      us_vehicle={rowData}
                      tableHooks={tableHooks}
                      uc_setUserInterface_CustomDialogDisplay={tableHooks.uc_setUserInterface_CustomDialogDisplay}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                    header_color: 'success',
                    header_text: `Vehicle Details for ${rowData.name}`,
                    icon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )

        const viewUserIcon = (
          <Icon
            icon="magnifying-glass"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            tooltip={'View'}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <VehicleAssignmentHistoryDialog
                      rowData={rowData}
                      tableHooks={tableHooks}
                      uc_setUserInterface_CustomDialogDisplay={tableHooks.uc_setUserInterface_CustomDialogDisplay}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                    header_color: 'info',
                    header_text: `User Details for ${rowData.name}`,
                    icon: (
                      <Icon
                        type="solid"
                        icon="magnifying-glass"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )

        const missingJSX = <Box className="tw-italic tw-opacity-30 tw-inline-block">{'Missing'}</Box>

        const cellJSX = (
          <>
            {rowData.associated_user_name || missingJSX} {manageUserIcon} {viewUserIcon}
          </>
        )

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
  },

  associated_garage_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Garage')
      },
      header_sort_by: 'associated_garage_name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let editGarageIcon = (
          <Icon
            icon="pen-to-square"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              let formOptions: TsInterface_UnspecifiedObject[] = []
              if (tableAdditionalData != null && tableAdditionalData != null) {
                for (let loopGarageKey in tableAdditionalData.us_activeGaragesList) {
                  let loopGarage = tableAdditionalData.us_activeGaragesList[loopGarageKey]
                  formOptions.push({
                    value: loopGarage.name,
                    key: loopGarage.key,
                  })
                }
              }
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      taskKey: rowData.key,
                    },
                    formData: rowData,
                    formInputs: {
                      associated_garage_key: {
                        data_type: 'string',
                        input_type: 'multiple_choice_radio',
                        key: 'associated_garage_key',
                        label: rLIB('Garage'),
                        required: true,
                        options: formOptions,
                        submit_on_change: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {
                      submit_button_hide: true,
                    },
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_garage_name: tableAdditionalData.us_activeGaragesList[formSubmittedData.associated_garage_key].name,
                              associated_garage_key: formSubmittedData.associated_garage_key,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_Vehicle_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Select Garage for')} {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let missingJSX = <Box className="tw-italic tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
        let cellJSX = (
          <>
            {getProp(rowData, 'associated_garage_name', missingJSX)} {editGarageIcon}
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  license_plate: TableCellBasic('license_plate', rLIB('License Plate'), 'license_plate'),
  vehicle_make: TableCellBasic('vehicle_make', rLIB('Make'), 'vehicle_make'),
  vehicle_model: TableCellBasic('vehicle_model', rLIB('Model'), 'vehicle_model'),
  vehicle_year: TableCellBasic('vehicle_year', rLIB('Year'), 'vehicle_year'),
  vehicle_tag_expiration: TableCellBasic('vehicle_tag_expiration', rLIB('Tag Expiration'), 'vehicle_tag_expiration'),
  vehicle_color: TableCellBasic('vehicle_color', rLIB('Color'), 'vehicle_color'),
  fuel_card_name: TableCellBasic('fuel_card_name', rLIB('Fuel Card Name'), 'fuel_card_name'),
  fuel_card_number: TableCellBasic('fuel_card_number', rLIB('Fuel Card Number'), 'fuel_card_number'),
  after_market_equipment: TableCellEquipment('after_market_equipment', rLIB('After Market Equipment'), 'after_market_equipment'),
  geotab_serial_number: TableCellBasic('geotab_serial_number', rLIB('Geotab Serial Number'), 'geotab_serial_number'),
  parked_location: TableCellBasic('parked_location', rLIB('Parked Location'), 'parked_location'),
}

export const tableSettings_Rentals: TsInterface_TableSettings = {
  paginated: false,
  size: 'small',
  sort_direction: 'desc',
  sort_property_default: 'rental_company_name',
  sortable: true,
  alternate_row_color_hex: themeVariables.background_hover,
  alternate_row_colors: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 180px)',

  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

export const tableSettings_Vehicles: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  collapsible_columns: true,
  sort_property: 'name',

  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
    {
      className: 'tw-hidden', // This hides rental rows
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'rental',
        comparator: '==',
        value: true,
        conditions: [],
      },
    },
  ],
  searchable: true,
  // search_settings_database: {
  //   search_type: 'firebase',
  //   search_no_data_message: s_NO_VEHICLES_FOUND_BY_SEARCH,
  //   search_property_options: [{ key: 'vin', value: rLIB('Vin') }],
  // },
  search_settings_database: {
    search_type: 'meilisearch',
    search_client_key: null,
    search_index_key: 'vehicles',
    search_filters: [],
    search_result_renderer: (option: TsInterface_UnspecifiedObject, searchInputValue: string | null, inputHooks: TsInterface_InputHooksObject): JSX.Element => {
      let searchResultJSX = (
        <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
          <Typography>
            {rLIB('Vehicle Name')}: {rJSX_HighlightedSearchString(searchInputValue, option.name)}{' '}
          </Typography>
          <Typography>
            {rLIB('Assigned Driver')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_user_name)}{' '}
          </Typography>
          <Typography>
            {rLIB('License Plate')}: {rJSX_HighlightedSearchString(searchInputValue, option.license_plate)}{' '}
          </Typography>
          <Typography>
            {rLIB('VIN')}: {rJSX_HighlightedSearchString(searchInputValue, option.vin)}{' '}
          </Typography>
          <Typography>
            {rLIB('Garage Location')}:{' '}
            <Box
              component="span"
              sx={{ opacity: 0.3 }}
            >
              {option.associated_garage_name}
            </Box>
          </Typography>
          <Typography>
            {rLIB('Field Status')}:{' '}
            <Box
              component="span"
              sx={{ opacity: 0.3 }}
            >
              {option.field_status ? rLIB('Active') : rLIB('Parked')}
            </Box>
          </Typography>
          <Button
            color={'info'}
            onClick={(event) => {
              if (option.id != null) {
                onClickAppNavigation(event, inputHooks.un_routerNavigation, ApplicationPages.AdminDatabaseVehicleViewPage.url(option.id as string))
              }
            }}
            variant="contained"
            sx={{ marginBottom: '5px' }}
          >
            <Icon icon="magnifying-glass" />
            {rLIB('View Vehicle')}
          </Button>
          <Divider />
        </Box>
      )
      return searchResultJSX
    },
  },
}
