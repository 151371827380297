///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { formInputs_UserNew, formOnChange_User, formSettings_User, formSubmission_UserCreate } from 'app/models/users/user_form'
import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Form, TsInterface_FormAdditionalData } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientPermissions } from 'rfbp_core/services/context'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesDatabaseUserNewPage']['key']

// Displayed Translatable Strings
const s_NEW_USER: JSX.Element = <Trans>New User</Trans>
const se_NEW_USER = 'New User'

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_formData_User, us_setFormData_User] = useState({})
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_NEW_USER
  }, [])

  useEffect(() => {
    us_setFormData_User({})
    return () => {}
  }, [])

  // Other Variables
  let formAdditionalData_User: TsInterface_FormAdditionalData = {
    client_type: uc_RootData_ClientPermissions.client_type,
    root_client_permissions: uc_RootData_ClientPermissions,
    redirect_url: ApplicationPages.SalesDatabaseUserListPage.url(),
  }

  // Functions

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    return (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesDatabaseUserListPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
      >
        {rLIB('Back to all users')}
      </Button>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_NEW_USER}
        pageKey={pageKey}
        content={
          <Box>
            {rJSX_BackButton()}
            <Box className="tw-my-4">
              <Card className="tw-p-4">
                <Form
                  formAdditionalData={formAdditionalData_User}
                  formData={us_formData_User}
                  formInputs={formInputs_UserNew}
                  formOnChange={formOnChange_User}
                  formSettings={formSettings_User}
                  formSubmission={formSubmission_UserCreate}
                />
              </Card>
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
