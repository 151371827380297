/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Tooltip, Typography } from '@mui/material'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskBlueprintConfig_Document, DatabaseRef_TaskBlueprints_Collection } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import {
  DatabaseRef_TaskFormDefaults_Collection,
  DatabaseRef_TaskFormDefault_Document,
  DatabaseRef_TaskForms_Collection,
} from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_TaskLibraryAdditionalConfigTab {
  // projectKey: string
  // us_taskType: any
  // us_taskCategory: any
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const TaskLibraryAdditionalConfigTab = forwardRef((props: TsInterface_TaskLibraryAdditionalConfigTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  // let pr_us_taskType: TsInterface_TaskLibraryAdditionalConfigTab['us_taskType'] = getProp(props, 'us_taskType', null)
  // let pr_us_taskCategory: TsInterface_TaskLibraryAdditionalConfigTab['us_taskCategory'] = getProp(props, 'us_taskCategory', null)

  // Hooks - useContext, useState, useReducer, other
  const [us_allTaskBlueprints, us_setAllTaskBlueprints] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskBlueprintConfig, us_setTaskBlueprintConfig] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_taskFormOptions, us_setTaskFormOptions] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_taskOwnerOptions, us_setTaskOwnerOptions] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_taskFormDefaults, us_setTaskFormDefaults] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const [us_taskCategory, us_setTaskCategory] = useState<string>('project')

  // Hooks - useEffect
  // useEffect(() => {
  //   let unsubscribeLiveData: TsType_VoidFunction
  //   const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //     let taskFormsDataOptions: TsInterface_UnspecifiedObject = {}
  //     for (let loopTaskFormKey in newData) {
  //       let loopTaskForm = newData[loopTaskFormKey]
  //       if (loopTaskForm != null && loopTaskForm['name'] != null) {
  //         taskFormsDataOptions[loopTaskFormKey] = {
  //           key: loopTaskFormKey,
  //           value: loopTaskForm['name'],
  //         }
  //         if (loopTaskForm['available_for_use'] !== true) {
  //           taskFormsDataOptions[loopTaskFormKey]['disabled'] = true
  //         }
  //       }
  //     }
  //     us_setTaskFormOptions(taskFormsDataOptions)
  //     ur_forceRerender()
  //   }
  //   getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //     .then((res_GCK) => {
  //       unsubscribeLiveData = DatabaseGetLiveCollection(
  //         DatabaseRef_TaskFormsByTaskCategoryActive_Query(res_GCK.clientKey, pr_us_taskCategory, 'name', false, null, {}),
  //         updateLiveData,
  //       )
  //     })
  //     .catch((rej_GCK) => {
  //       console.error(rej_GCK)
  //     })
  //   return () => {
  //     if (typeof unsubscribeLiveData === 'function') {
  //       unsubscribeLiveData()
  //     }
  //   }
  // }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_us_taskCategory])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllTaskBlueprints(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskBlueprints_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskBlueprintConfig(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskBlueprintConfig_Document(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskFormDefaults(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskFormDefaults_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // useEffect(() => {
  //   getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //     .then((res_GCK) => {
  //       let clientUserRoles = returnClientUserRoles(res_GCK.clientKey)
  //       let formattedUserRoles: TsInterface_UnspecifiedObject = {}
  //       for (let loopUserRoleKey in clientUserRoles) {
  //         formattedUserRoles[loopUserRoleKey] = {
  //           key: loopUserRoleKey,
  //           value: clientUserRoles[loopUserRoleKey]['name'],
  //         }
  //       }
  //       us_setTaskOwnerOptions(formattedUserRoles)
  //       ur_forceRerender()
  //     })
  //     .catch((rej_GCK) => {
  //       console.error(rej_GCK)
  //     })
  //   return () => {}
  // }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  const promptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Form') as JSX.Element,
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: rLIB('Form Name') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new task form'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          status: 'active',
          task_category: us_taskCategory,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskForms_Collection(res_GCK.clientKey), updateObject, true)
              .then((res_DAD) => {
                directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseTaskFormsViewPage.url(res_DAD.key))
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  const openEditDefaultDialog = (label: JSX.Element, formInputBaseKey: string): void => {
    // TODO - make dynamic for future defaults when needed
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskForms_Collection(res_GCK.clientKey))
          .then((res_DGC) => {
            let taskForms = res_DGC.data
            let taskFormOptions: TsInterface_UnspecifiedObject = {}
            for (let loopFormKey in taskForms) {
              taskFormOptions[loopFormKey] = {
                key: loopFormKey,
                value: taskForms[loopFormKey]['name'],
              }
            }
            let formInputs: TsInterface_FormInputs = {}
            formInputs['associated_form_key'] = {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_form_key',
              label: label,
              required: false,
              options: objectToArray(taskFormOptions).sort(dynamicSort('value', 'asc')),
            }
            // Import Dialog
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: us_taskFormDefaults,
                  formInputs: formInputs,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          let updateObject: TsInterface_UnspecifiedObject = {}
                          updateObject['associated_form_key'] = formSubmittedData['associated_form_key']
                          updateObject['associated_form_name'] = taskFormOptions[formSubmittedData['associated_form_key']]['value']
                          DatabaseSetMergeDocument(DatabaseRef_TaskFormDefault_Document(res_GCK.clientKey, formInputBaseKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: <>{label}</>,
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="pen-to-square"
                    />
                  ),
                },
              },
            })
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const openTaskSelectionDialog = (taskName: JSX.Element, configFieldKey: string): void => {
    let taskOptions: TsInterface_FormInput['options'] = []
    for (let loopTaskKey in us_allTaskBlueprints) {
      let loopTask = us_allTaskBlueprints[loopTaskKey]
      taskOptions.push({
        key: loopTask.key,
        value: loopTask.name,
      })
    }
    let formInputs: TsInterface_FormInputs = {
      [configFieldKey]: {
        data_type: 'string',
        input_type: 'multiple_choice_select',
        key: configFieldKey,
        options: taskOptions,
        label: (
          <>
            {taskName} {rLIB('Task Mapping')}
          </>
        ),
        required: true,
      },
    }

    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_taskBlueprintConfig,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskBlueprintConfig_Document(res_GCK.clientKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task')} - {taskName}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_NiceTaskMappingName = (taskMappingProp: string): JSX.Element => {
    let taskNameJSX = <></>
    let taskKey = getProp(us_taskBlueprintConfig, taskMappingProp, '')

    if (us_allTaskBlueprints != null && us_allTaskBlueprints[taskKey] != null) {
      taskNameJSX = <>{us_allTaskBlueprints[taskKey].name}</>
    } else if (us_allTaskBlueprints.length > 0) {
      taskNameJSX = <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Missing')}</Box>
    }
    return taskNameJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let missingJSX = <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Missing')}</Box>

    let tabJSX = (
      <Box className="tw-m-auto">
        <Card className="tw-p-4">
          <Box>
            <Typography variant="h6">
              <Box className="tw-inline-block tw-mr-2">{rLIB('CAPa')}: </Box>
              {/* <Box className="tw-inline-block tw-italic tw-opacity-30">{ getProp( us_taskBlueprintConfig, "associated_v2_task_mapping_capa", missingJSX ) }</Box> */}
              <Box className="tw-inline-block tw-italic tw-opacity-30">{rJSX_NiceTaskMappingName('associated_v2_task_mapping_capa')}</Box>
              <Icon
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                icon="pen-to-square"
                tooltip={rLIB('Edit')}
                onClick={() => {
                  openTaskSelectionDialog(rLIB('CAPa') as JSX.Element, 'associated_v2_task_mapping_capa')
                }}
              />
            </Typography>
            <Typography variant="h6">
              <Box className="tw-inline-block tw-mr-2">{rLIB('PTO')}: </Box>
              {/* <Box className="tw-inline-block tw-italic tw-opacity-30">{ getProp( us_taskBlueprintConfig, "associated_v2_task_mapping_pto", missingJSX ) }</Box> */}
              <Box className="tw-inline-block tw-italic tw-opacity-30">{rJSX_NiceTaskMappingName('associated_v2_task_mapping_pto')}</Box>
              <Icon
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                icon="pen-to-square"
                tooltip={rLIB('Edit')}
                onClick={() => {
                  openTaskSelectionDialog(rLIB('PTO') as JSX.Element, 'associated_v2_task_mapping_pto')
                }}
              />
            </Typography>
            <Typography variant="h6">
              {rLIB('Default Site Audit Form')}:{' '}
              <Box className="tw-inline-block tw-italic tw-opacity-30">{getProp(us_taskFormDefaults.site_audit, 'associated_form_name', missingJSX)}</Box>
              <Tooltip
                title={rLIB('Edit')}
                placement="right"
              >
                <Box
                  className="tw-inline-block"
                  onClick={() => {
                    openEditDefaultDialog(rLIB('Default Site Audit Form') as JSX.Element, 'site_audit')
                  }}
                >
                  <Icon
                    className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                    icon="pen-to-square"
                  />
                </Box>
              </Tooltip>
            </Typography>
          </Box>
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
