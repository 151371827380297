///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Base
export const DatabaseRef_ProjectMilestones_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'project_milestones', 'main')
}

export const DatabaseRef_ProjectMilestone_Document = (clientKey: string, projectMilestoneKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'project_milestones', 'main', projectMilestoneKey)
}

export const DatabaseRef_ActiveProjectMilestones_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'project_milestones', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_ProjectMilestone_ImportAliases_Document = (clientKey: string, projectMilestoneKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'project_milestones', 'import_aliases', projectMilestoneKey)
}

export const DatabaseRef_ProjectMilestone_ImportAliases_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'project_milestones', 'import_aliases')
}

export const DatabaseRef_ProjectMilestone_ImportAliases_Query = (clientKey: string, projectMilestoneKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: projectMilestoneKey }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'project_milestones', 'import_aliases'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}
