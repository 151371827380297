/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Masonry } from '@mui/lab'
import { Avatar, Box, Button, Card, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { homeBuildingTypeOptions, roofTypeOptions } from 'app/pages/projects/forms/project_home_details'
import { formInputs_NewStickyNote, formSettings_StickyNote } from 'app/pages/projects/forms/sticky_notes'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ActiveFinancePartners_Query } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_ActiveInstallPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/install_partner'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_SalesOpportunity_v2_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import { stringAvatar, stringToColor } from 'rfbp_core/components/chat'
import { DirectMultipleChoiceEdit, tsI_MultipleChoiceOption } from 'rfbp_core/components/direct_edits/direct_multiple_choice_edit'
import { DirectNumberEdit } from 'rfbp_core/components/direct_edits/direct_number_edit'
import { DirectParagraphEdit } from 'rfbp_core/components/direct_edits/direct_paragraph_edit'
import { DirectTextEdit } from 'rfbp_core/components/direct_edits/direct_text_edit'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { listOfUnitedStates } from 'rfbp_core/components/form/inputs/multiple_choice_select_state_usa_abbreviated'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  formatCurrency,
  getProp,
  objectToArray,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_OpportunityDetailsTab {
  opportunityKey: string
  readOrWrite: 'read' | 'write'
  clientKeyOverride?: string
}

///////////////////////////////
// Variables
///////////////////////////////

const tabKey = 'opportunity_details'

const statusOptions = {
  active: { key: 'active', value: 'Active' },
  closed_won: { key: 'closed_won', value: 'Closed Won' },
  closed_lost: { key: 'closed_lost', value: 'Closed Lost' },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const OpportunityDetailsTab = forwardRef((props: TsInterface_OpportunityDetailsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_opportunityKey: TsInterface_OpportunityDetailsTab['opportunityKey'] = getProp(props, 'opportunityKey', null)
  let pr_clientKey: TsInterface_OpportunityDetailsTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_readOrWrite: TsInterface_OpportunityDetailsTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')

  // Hooks - useContext, useState, useReducer, other
  const [us_minimizedAddersDetails, us_setMinimizedAddersDetails] = useState<boolean>(false)
  const [us_minimizedInstallPartnerDetails, us_setMinimizedInstallPartnerDetails] = useState<boolean>(false)
  const [us_minimizedCustomerDetails, us_setMinimizedCustomerDetails] = useState<boolean>(false)
  const [us_minimizedSalesPartnerDetails, us_setMinimizedSalesPartnerDetails] = useState<boolean>(false)
  const [us_minimizedFinancingDetails, us_setMinimizedFinancingDetails] = useState<boolean>(false)
  const [us_minimizedHomeDetails, us_setMinimizedHomeDetails] = useState<boolean>(false)
  const [us_minimizedStickyNotes, us_setMinimizedStickyNotes] = useState<boolean>(false)
  const [us_minimizedSystemDetails, us_setMinimizedSystemDetails] = useState<boolean>(false)
  const [us_rootOpportunity, us_setRootOpportunity] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Hooks - useEffect
  useEffect(() => {
    // Set Region from Local Storage
    let pageLocalStorageData = getPageLocalStorage(tabKey)
    if (getProp(pageLocalStorageData, 'us_minimizedCustomerDetails', null) != null) {
      us_setMinimizedCustomerDetails(getProp(pageLocalStorageData, 'us_minimizedCustomerDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedSalesPartnerDetails', null) != null) {
      us_setMinimizedSalesPartnerDetails(getProp(pageLocalStorageData, 'us_minimizedSalesPartnerDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedFinancingDetails', null) != null) {
      us_setMinimizedFinancingDetails(getProp(pageLocalStorageData, 'us_minimizedFinancingDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedSystemDetails', null) != null) {
      us_setMinimizedSystemDetails(getProp(pageLocalStorageData, 'us_minimizedSystemDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedHomeDetails', null) != null) {
      us_setMinimizedHomeDetails(getProp(pageLocalStorageData, 'us_minimizedHomeDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedStickyNotes', null) != null) {
      us_setMinimizedStickyNotes(getProp(pageLocalStorageData, 'us_minimizedStickyNotes', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedInstallPartnerDetails', null) != null) {
      us_setMinimizedInstallPartnerDetails(getProp(pageLocalStorageData, 'us_minimizedInstallPartnerDetails', null))
    }
    if (getProp(pageLocalStorageData, 'us_minimizedAddersDetails', null) != null) {
      us_setMinimizedAddersDetails(getProp(pageLocalStorageData, 'us_minimizedAddersDetails', null))
    }
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootOpportunity(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunity_v2_Document(actualClientKey, pr_opportunityKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_opportunityKey, pr_clientKey])

  // Functions

  const openStatusEditDialog = () => {
    let formData: TsInterface_UnspecifiedObject = {
      status: getProp(us_rootOpportunity, 'status', null),
    }
    if (getProp(us_rootOpportunity, 'timestamp_completed', null) != null) {
      formData.timestamp_completed = returnFormattedDate(new Date(), 'YYYY-MM-DD')
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: formData,
          formInputs: {
            status: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'status',
              label: rLIB('Status'),
              required: true,
              options: objectToArray(statusOptions),
            },
            timestamp_completed: {
              key: 'timestamp_completed',
              label: rLIB('Closed Date'),
              input_type: 'timestamp_date',
              required: false,
              data_type: 'string',
              conditional_display: {
                active: true,
                logic: {
                  active: true,
                  logic_type: 'comparison',
                  source: 'formData',
                  prop: 'status',
                  comparator: '!=',
                  value: 'active',
                  conditions: [],
                },
              },
              conditional_require: {
                active: true,
                logic: {
                  active: true,
                  logic_type: 'comparison',
                  source: 'formData',
                  prop: 'status',
                  comparator: '!=',
                  value: 'active',
                  conditions: [],
                },
              },
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_StickyNote,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject: TsInterface_UnspecifiedObject = {
                status: formSubmittedData.status,
              }
              if (formSubmittedData.status === 'closed_won' || formSubmittedData.status === 'closed_lost') {
                updateObject.timestamp_completed = returnDateFromUnknownDateFormat(formSubmittedData.timestamp_completed)
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    reject(rej_DSMD)
                  })
              })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'warning',
          formDialogHeaderText: rLIB('Edit Opportunity Status'),
          formDialogIcon: (
            <Icon
              icon="bars-progress"
              type="solid"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_NonEditableTextLineItem = (label: string | JSX.Element, icon: string, object: TsInterface_UnspecifiedObject, propKey: string): JSX.Element => {
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {getProp(object, propKey, missingJSX)}
        </Typography>
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableTextLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectTextEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableParagraphLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectParagraphEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            text={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: string) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableNumberLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    startAdornment: string | JSX.Element | undefined,
    endAdornment: string | JSX.Element | undefined,
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectNumberEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            number={getProp(object, propKey, '')}
            textCssClassName="tw-font-bold tw-w-full"
            onEnter={(value: number) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    // @ts-ignore
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableCurrencyLineItem = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = <></>
    if (getProp(object, propKey, null) != null) {
      editableFieldJSX = (
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {formatCurrency(getProp(object, propKey, ''))}
        </Typography>
      )
    } else {
      editableFieldJSX = missingJSX
    }
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectNumberEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            number={getProp(object, propKey, '')}
            textCssClassName=""
            startAdornment={'$'}
            onEnter={(value: number) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: value,
                    }
                    // @ts-ignore
                    if (value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    propKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    options: tsI_MultipleChoiceOption[],
  ): JSX.Element => {
    if (getProp(object, propKey, null) === '') {
      object[propKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, propKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, propKey, ''), value: getProp(object, propKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'static'}
            options={options}
            onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [propKey]: selectedOption.value,
                    }
                    if (selectedOption.value == '') {
                      updateObject = {
                        [propKey]: null,
                      }
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                        .then(() => {
                          resolve(true)
                          ur_forceRerender()
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs
  const rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptions = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    keyPropKey: string,
    valuePropKey: string,
    dbLocation: 'rootProject' | 'additionalProjectData',
    getOptions: () => Promise<tsI_MultipleChoiceOption[]>,
  ): JSX.Element => {
    if (getProp(object, keyPropKey, null) === '') {
      object[keyPropKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, valuePropKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, keyPropKey, ''), value: getProp(object, valuePropKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'dynamic'}
            loadOptions={getOptions}
            onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
              return new Promise((resolve, reject) => {
                resolve({ success: true })
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [keyPropKey]: selectedOption.key,
                      [valuePropKey]: selectedOption.value,
                    }
                    if (dbLocation === 'rootProject') {
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    } else {
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    reject(rej_GCK)
                  })
              })
            }}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  // TODO: Logs - have to look at each instance of this
  const rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit = (
    label: string | JSX.Element,
    icon: string,
    object: TsInterface_UnspecifiedObject,
    keyPropKey: string,
    valuePropKey: string,
    getOptions: () => Promise<tsI_MultipleChoiceOption[]>,
    onEnter: (selectedOption: TsInterface_UnspecifiedObject) => Promise<boolean>,
  ): JSX.Element => {
    if (getProp(object, keyPropKey, null) === '') {
      object[keyPropKey] = null
    }
    let missingJSX = (
      <Box
        component="span"
        className="tw-opacity-30 tw-italic"
      >
        {rLIB('Missing')}
      </Box>
    )
    let editableFieldJSX = (
      <Typography
        variant="body1"
        className="tw-inline-block"
      >
        {getProp(object, valuePropKey, missingJSX)}
      </Typography>
    )
    if (pr_readOrWrite === 'write') {
      editableFieldJSX = (
        <Box className="tw-inline-block">
          <DirectMultipleChoiceEdit
            displayText={editableFieldJSX}
            fullyClickable={false}
            selectedOption={{ key: getProp(object, keyPropKey, ''), value: getProp(object, valuePropKey, '') }}
            textCssClassName="tw-font-bold tw-w-full"
            optionType={'dynamic'}
            loadOptions={getOptions}
            onEnter={onEnter}
          />
        </Box>
      )
    }
    // Return
    return (
      <Box className="tw-mb-1 tw-ml-4">
        <Box
          className="tw-inline-block"
          sx={{ width: '30px' }}
        >
          <Icon
            icon={icon}
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          />
        </Box>
        <Typography
          variant="body1"
          className="tw-inline-block tw-mr-2 tw-opacity-40"
        >
          {label}:
        </Typography>
        {editableFieldJSX}
      </Box>
    )
  }

  const rJSX_MinimizeSectionIcons = (minimized: boolean, setMinimized: React.Dispatch<React.SetStateAction<boolean>>, sectionKey: string): JSX.Element => {
    return (
      <Box component="span">
        {minimized ? (
          <Icon
            icon="square-caret-down"
            tooltip={rLIB('Expand')}
            className="tw-ml-2 tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimized(false)
              setPageLocalStorage(tabKey, sectionKey, false)
            }}
          />
        ) : (
          <Icon
            icon="square-caret-up"
            tooltip={rLIB('Minimize')}
            className="tw-ml-2 tw-inline-block tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
            onClick={() => {
              setMinimized(true)
              setPageLocalStorage(tabKey, sectionKey, true)
            }}
          />
        )}
      </Box>
    )
  }

  // JSX - Details
  const rJSX_Avatar = (size: number): JSX.Element => {
    let avatarJSX = <></>
    avatarJSX = (
      <Avatar
        // eslint-disable-next-line react/no-children-prop
        children={stringAvatar(getProp(us_rootOpportunity, 'associated_customer_name', ''))['children']}
        sx={{ width: size, height: size, margin: 'auto', bgcolor: stringToColor(getProp(us_rootOpportunity, 'associated_customer_name', '')) }}
      />
    )
    return avatarJSX
  }

  const rJSX_EditStatus = (): JSX.Element => {
    return (
      <Box>
        <Stack
          direction="row"
          className="group"
          sx={{
            '&:hover .edit-icon-box': {
              opacity: 1,
            },
          }}
        >
          <Icon
            icon="bars-progress"
            type="solid"
            className="tw-inline-block tw-mr-2 tw-opacity-40 tw-mt-1"
          />
          <Box className="tw-inline-block tw-opacity-40 tw-align-top">
            {getProp(getProp(statusOptions, getProp(us_rootOpportunity, 'status', ''), {}), 'value', getProp(us_rootOpportunity, 'status', ''))}
          </Box>
          <Box
            className="edit-icon-box"
            sx={{ opacity: 0 }}
          >
            <Icon
              icon="pen-circle"
              className="tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main tw-cursor-pointer tw-mt-1 tw-align-top"
              tooltip={rLIB('Edit')}
              tooltipPlacement="right"
              onClick={() => {
                openStatusEditDialog()
              }}
            />
          </Box>
        </Stack>
        {getProp(us_rootOpportunity, 'timestamp_completed', null) != null && getProp(us_rootOpportunity, 'status', null) !== 'active' ? (
          <Typography
            variant="body1"
            className="tw-opacity-40"
          >
            <Icon
              icon="calendar"
              type="solid"
              className="tw-inline-block tw-mr-2 tw-mt-1"
            />
            {rLIB('Closed on')}: {returnFormattedDate(getProp(us_rootOpportunity, 'timestamp_completed', null), 'D MMM YYYY')}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    )
  }

  const rJSX_DetailsTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Customer Details')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedCustomerDetails, us_setMinimizedCustomerDetails, 'us_minimizedCustomerDetails')}
        </Typography>
        {us_minimizedCustomerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedCustomerDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedCustomerDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box
              className="tw-inline-block tw-align-top"
              sx={{ width: '55px', height: '55px' }}
            >
              {rJSX_Avatar(55)}
            </Box>
            <Box
              className="tw-pl-4 tw-inline-block"
              sx={{ width: 'calc(100% - 55px)' }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 700 }}
              >
                {getProp(us_rootOpportunity, 'associated_customer_name', '')}
              </Typography>
              <Typography variant="body1">{getProp(us_rootOpportunity, 'unique_identifier', '')}</Typography>
              <Typography
                variant="body1"
                className="tw-opacity-30"
              >
                <Icon
                  icon="filter-list"
                  type="solid"
                  className="tw-mr-2"
                />
                {getProp(us_rootOpportunity, 'associated_milestone_name', '')}
              </Typography>

              {rJSX_EditStatus()}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Contact')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Phone'), 'phone', us_rootOpportunity, 'associated_customer_phone', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Additional Phone'), 'phone', us_rootOpportunity, 'associated_customer_additional_phone', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Email'), 'envelope', us_rootOpportunity, 'associated_customer_email', 'rootProject')}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Address')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Address'), 'location-dot', us_rootOpportunity, 'location_address', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('City'), 'location-dot', us_rootOpportunity, 'location_city', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('County'), 'location-dot', us_rootOpportunity, 'location_county', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(rLIB('Zip'), 'location-dot', us_rootOpportunity, 'location_zip', 'rootProject', undefined, undefined)}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('State'),
                'location-dot',
                us_rootOpportunity,
                'location_state',
                'rootProject',
                objectToArray(listOfUnitedStates) as unknown as tsI_MultipleChoiceOption[],
              )}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Jurisdiction'), 'location-dot', us_rootOpportunity, 'location_jurisdiction', 'rootProject')}
              {rJSX_NonEditableTextLineItem(rLIB('Latitude'), 'location-dot', us_rootOpportunity, 'location_latitude')}
              {rJSX_NonEditableTextLineItem(rLIB('Longitude'), 'location-dot', us_rootOpportunity, 'location_longitude')}
              {rJSX_DirectlyEditableParagraphLineItem(
                rLIB('Gate Code / Access Instructions'),
                'location-dot',
                us_rootOpportunity,
                'location_access_instructions',
                'rootProject',
              )}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.primary_main }}
              >
                {rLIB('Region')}
              </Typography>

              {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptions(
                rLIB('Region'),
                'compass',
                us_rootOpportunity,
                'associated_region_key',
                'associated_region_name',
                'rootProject',
                () => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseGetCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey))
                          .then((res_DB) => {
                            let formattedOptions: { key: string; value: string }[] = []
                            for (let loopOptionKey in res_DB.data) {
                              formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                            }
                            resolve(formattedOptions)
                          })
                          .catch((rej_DB) => {
                            reject(rej_DB)
                          })
                      })
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                      })
                  })
                },
              )}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Sales Partner
  const rJSX_SalesPartnerLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Sales Partner'),
          'building',
          us_rootOpportunity,
          'associated_sales_partner_key',
          'associated_sales_partner_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Sales Partner') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the sales partner on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_sales_partner_key: selectedOption.key,
                              associated_sales_partner_name: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to archive order'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
        )}
      </Box>
    )
  }

  const rJSX_SalesPartnerTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sales Partner')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedSalesPartnerDetails, us_setMinimizedSalesPartnerDetails, 'us_minimizedSalesPartnerDetails')}
        </Typography>
        {us_minimizedSalesPartnerDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedSalesPartnerDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedSalesPartnerDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_light }}
              >
                {rLIB('Sales Partner')}
              </Typography>
              {rJSX_SalesPartnerLineItem()}
            </Box>
            <Divider className="tw-my-2" />
            <Box className="tw-mt-2">
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_light }}
              >
                {rLIB('Sales Rep')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Name'), 'location-dot', us_rootOpportunity, 'associated_sales_rep_name', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Phone'), 'phone', us_rootOpportunity, 'associated_sales_rep_phone', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Email'), 'envelope', us_rootOpportunity, 'associated_sales_rep_email', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Sales Rep Team'), 'people-group', us_rootOpportunity, 'associated_sales_rep_team_name', 'rootProject')}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Financing
  const rJSX_FinancePartnerLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Finance Partner'),
          'square-dollar',
          us_rootOpportunity,
          'associated_finance_partner_key',
          'associated_finance_partner_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveFinancePartners_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Finance Partner') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the finance partner on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_finance_partner_key: selectedOption.key,
                              associated_finance_partner_name: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to archive order'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
        )}
      </Box>
    )
  }

  const rJSX_InstallPartnerLineItem = (): JSX.Element => {
    return (
      <Box>
        {rJSX_DirectlyEditableMultipleChoiceLineItemDynamicOptionsWithCustomSubmit(
          rLIB('Install Partner'),
          'screwdriver-wrench',
          us_rootOpportunity,
          'associated_install_partner_key',
          'associated_install_partner_name',
          () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseGetCollection(DatabaseRef_ActiveInstallPartners_Query(res_GCK.clientKey))
                    .then((res_DB) => {
                      let formattedOptions: { key: string; value: string }[] = []
                      for (let loopOptionKey in res_DB.data) {
                        formattedOptions.push({ value: res_DB.data[loopOptionKey]['name'], key: loopOptionKey })
                      }
                      resolve(formattedOptions)
                    })
                    .catch((rej_DB) => {
                      reject(rej_DB)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            })
          },
          (selectedOption: TsInterface_UnspecifiedObject) => {
            return new Promise((resolve1, reject1) => {
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'error',
                  confirm_text: rLIB('Update') as JSX.Element,
                  default_value: '',
                  header: rLIB('Update Install Partner') as JSX.Element,
                  icon: (
                    <Icon
                      icon="exclamation-triangle"
                      type="solid"
                    />
                  ),
                  input_label: rLIB('Type CONFIRM to proceed') as JSX.Element,
                  input_type: 'text',
                  text: rLIB('Are you sure that you update the install partner on this project?') as JSX.Element,
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve2, reject2) => {
                      if (promptValue === 'CONFIRM') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              associated_install_partner_key: selectedOption.key,
                              associated_install_partner_name: selectedOption.value,
                            }
                            DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                              .then((res_DSMD) => {
                                resolve1(true)
                                resolve2(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject1(rej_DSMD)
                                reject2(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            resolve1(true)
                            resolve2({ close_dialog: false })
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: rLIB('Failed to archive order'),
                            details: (
                              <>
                                {rLIB('You must enter CONFIRM in order to proceed.')} {rLIB('Otherwise click dismiss')}
                              </>
                            ),
                            code: 'ER-D-PT-DO-01',
                          },
                        })
                        resolve1(true)
                        resolve2({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            })
          },
        )}
      </Box>
    )
  }

  const rJSX_FinancingTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Financing')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedFinancingDetails, us_setMinimizedFinancingDetails, 'us_minimizedFinancingDetails')}
        </Typography>
        {us_minimizedFinancingDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedFinancingDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedFinancingDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_dark }}
              >
                {rLIB('Finance Partner')}
              </Typography>
              {rJSX_FinancePartnerLineItem()}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.success_dark }}
              >
                {rLIB('Financing Terms')}
              </Typography>
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Financing Type'),
                'square-dollar',
                us_rootOpportunity,
                'financials_financing_type',
                'rootProject',
                objectToArray([
                  { key: 'Cash', value: 'Cash' },
                  { key: 'Lease', value: 'Lease' },
                  { key: 'Loan', value: 'Loan' },
                ]) as unknown as tsI_MultipleChoiceOption[],
              )}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Product Sold'), 'square-dollar', us_rootOpportunity, 'financials_product_sold', 'rootProject')}
              {rJSX_DirectlyEditableCurrencyLineItem(rLIB('Contract Amount'), 'square-dollar', us_rootOpportunity, 'financials_contract_amount', 'rootProject')}
              {rJSX_DirectlyEditableCurrencyLineItem(rLIB('Price Per Watt'), 'square-dollar', us_rootOpportunity, 'financials_price_per_watt', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Dealer Fee %'),
                'square-dollar',
                us_rootOpportunity,
                'financials_loan_dealer_fee_percent',
                'rootProject',
                undefined,
                undefined,
              )}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  const rJSX_InstallPartnerTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Install Partner')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedInstallPartnerDetails, us_setMinimizedInstallPartnerDetails, 'us_minimizedInstallPartnerDetails')}
        </Typography>
        {us_minimizedFinancingDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedInstallPartnerDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedInstallPartnerDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_dark }}
              >
                {rLIB('Install Partner')}
              </Typography>
              {rJSX_InstallPartnerLineItem()}
            </Box>

            {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
              rLIB('Virtual Site Survey Completed'),
              'home',
              us_rootOpportunity,
              'virtual_site_survey_completed',
              'rootProject',
              objectToArray([
                { key: 'yes', value: 'yes' },
                { key: 'no', value: 'no' },
              ]) as unknown as tsI_MultipleChoiceOption[],
            )}
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Adders
  const rJSX_AddersTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Adders')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedAddersDetails, us_setMinimizedAddersDetails, 'us_minimizedAddersDetails')}
        </Typography>
        {us_minimizedAddersDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedAddersDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedAddersDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_light }}
              >
                {rLIB('Adders')}
              </Typography>
              {rJSX_DirectlyEditableParagraphLineItem(rLIB('Adders'), 'square-list', us_rootOpportunity, 'notes_adders', 'rootProject')}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - System
  const rJSX_SystemTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('System')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedSystemDetails, us_setMinimizedSystemDetails, 'us_minimizedSystemDetails')}
        </Typography>
        {us_minimizedSystemDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedSystemDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedSystemDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Type')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Product Package'), 'cart-shopping', us_rootOpportunity, 'associated_product_name', 'rootProject')}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Components')}
              </Typography>
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Panel Quantity'),
                'solar-panel',
                us_rootOpportunity,
                'system_panel_quantity',
                'rootProject',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Panel Manufacturer'), 'solar-panel', us_rootOpportunity, 'system_panel_manufacturer', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Panel Model'), 'solar-panel', us_rootOpportunity, 'system_panel_model', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Inverter Quantity'),
                'solar-panel',
                us_rootOpportunity,
                'system_inverter_quantity',
                'rootProject',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableTextLineItem(
                rLIB('Inverter Manufacturer'),
                'solar-panel',
                us_rootOpportunity,
                'system_inverter_manufacturer',
                'rootProject',
              )}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Inverter Model'), 'solar-panel', us_rootOpportunity, 'system_inverter_model', 'rootProject')}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Storage Quantity'),
                'solar-panel',
                us_rootOpportunity,
                'system_storage_quantity',
                'rootProject',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Storage Manufacturer'), 'solar-panel', us_rootOpportunity, 'system_storage_manufacturer', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Storage Model'), 'solar-panel', us_rootOpportunity, 'system_storage_model', 'rootProject')}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('System Stats')}
              </Typography>
              {rJSX_DirectlyEditableNumberLineItem(rLIB('System Size'), 'solar-panel', us_rootOpportunity, 'system_size', 'rootProject', undefined, undefined)}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Est Annual Production'),
                'solar-panel',
                us_rootOpportunity,
                'system_estimated_annual_production',
                'rootProject',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Usage Offset'),
                'solar-panel',
                us_rootOpportunity,
                'system_usage_offset',
                'rootProject',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Total Storage (kWH)'),
                'solar-panel',
                us_rootOpportunity,
                'system_storage_total_kwh',
                'rootProject',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Number of Arrays'),
                'solar-panel',
                us_rootOpportunity,
                'system_number_of_arrays',
                'rootProject',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Number of Strings'),
                'solar-panel',
                us_rootOpportunity,
                'system_number_of_strings',
                'rootProject',
                undefined,
                undefined,
              )}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {rLIB('Other')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('Meter Type'), 'solar-panel', us_rootOpportunity, 'system_meter_type', 'additionalProjectData')}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Attic Run'),
                'solar-panel',
                us_rootOpportunity,
                'system_has_attic_run',
                'rootProject',
                objectToArray([
                  { key: 'yes', value: 'yes' },
                  { key: 'no', value: 'no' },
                ]) as unknown as tsI_MultipleChoiceOption[],
              )}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Home Details
  const rJSX_HomeDetailsTile = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Home Details')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedHomeDetails, us_setMinimizedHomeDetails, 'us_minimizedHomeDetails')}
        </Typography>
        {us_minimizedHomeDetails ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedHomeDetails(false)
                setPageLocalStorage(tabKey, 'us_minimizedHomeDetails', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.error_main }}
              >
                {rLIB('Home Characteristics')}
              </Typography>
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Roof Type'),
                'home',
                us_rootOpportunity,
                'home_roof_type',
                'additionalProjectData',
                objectToArray(roofTypeOptions) as unknown as tsI_MultipleChoiceOption[],
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Max Roof Pitch'),
                'home',
                us_rootOpportunity,
                'system_max_roof_pitch',
                'rootProject',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(
                rLIB('Building Type'),
                'home',
                us_rootOpportunity,
                'home_building_type',
                'additionalProjectData',
                objectToArray(homeBuildingTypeOptions) as unknown as tsI_MultipleChoiceOption[],
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Roof Age'),
                'home',
                us_rootOpportunity,
                'home_roof_age',
                'additionalProjectData',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Installation Type'), 'home', us_rootOpportunity, 'home_installation_type', 'additionalProjectData')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Shading Type'), 'home', us_rootOpportunity, 'home_shading_type', 'additionalProjectData')}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Square Footage'),
                'home',
                us_rootOpportunity,
                'home_square_footage',
                'additionalProjectData',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Year Built'),
                'home',
                us_rootOpportunity,
                'home_year_built',
                'additionalProjectData',
                undefined,
                undefined,
              )}
              {rJSX_DirectlyEditableNumberLineItem(
                rLIB('Number of Stories'),
                'home',
                us_rootOpportunity,
                'home_number_of_stories',
                'additionalProjectData',
                undefined,
                undefined,
              )}
            </Box>
            <Divider className="tw-my-2" />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 700, color: themeVariables.error_main }}
              >
                {rLIB('Entities')}
              </Typography>
              {rJSX_DirectlyEditableTextLineItem(rLIB('HOA'), 'home', us_rootOpportunity, 'associated_hoa_name', 'rootProject')}
              {rJSX_DirectlyEditableTextLineItem(rLIB('Utility Company'), 'utility-pole', us_rootOpportunity, 'associated_utility_company_name', 'rootProject')}
            </Box>
          </Card>
        )}
      </Box>
    )
  }

  // JSX - Sticky Notes
  const rJSX_EditStickyNoteIcon = (
    readOrWrite: 'read' | 'write',
    note: TsInterface_UnspecifiedObject,
    uc_RootData_ClientKey: any,
    uc_setRootData_ClientKey: any,
    uc_setUserInterface_FormDialogDisplay: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    projectKey: any,
  ) => {
    let editIconJSX = <></>
    if (readOrWrite === 'write') {
      editIconJSX = (
        <Tooltip title={rLIB('Edit Sticky Note')}>
          <Box
            className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
            onClick={() => {
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {
                      note: note.note,
                      date: returnFormattedDate(note.timestamp, 'YYYY-MM-DD'),
                    },
                    formInputs: formInputs_NewStickyNote,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: formSettings_StickyNote,
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject = {
                          sticky_notes: {
                            [note.key]: {
                              note: formSubmittedData.note,
                              timestamp: new Date(
                                returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                              ),
                            },
                          },
                        }
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, projectKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'warning',
                    formDialogHeaderText: rLIB('Edit Sticky Note'),
                    formDialogIcon: (
                      <Icon
                        icon="note"
                        type="solid"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon icon="pen-to-square"></Icon>
          </Box>
        </Tooltip>
      )
    }
    return editIconJSX
  }

  const rJSX_DeleteStickyNoteIcon = (
    readOrWrite: 'read' | 'write',
    noteKey: any,
    note: TsInterface_UnspecifiedObject,
    uc_RootData_ClientKey: any,
    uc_setRootData_ClientKey: any,
    uc_setUserInterface_ErrorDialogDisplay: any,
    uc_setUserInterface_ConfirmDialogDisplay: any,
    projectKey: any,
  ) => {
    let deleteIconJSX = <></>
    if (readOrWrite === 'write') {
      deleteIconJSX = (
        <Tooltip title={rLIB('Delete Sticky Note')}>
          <Box className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2">
            <Icon
              icon="trash"
              onClick={() => {
                uc_setUserInterface_ConfirmDialogDisplay({
                  display: true,
                  confirm: {
                    color: 'error',
                    header: <>{rLIB('Delete Sticky Note')}</>,
                    icon: <Icon icon="trash" />,
                    submit_callback: () => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              sticky_notes: {
                                [noteKey]: null,
                              },
                              sticky_notes_deleted: {
                                [noteKey]: note,
                              },
                            }
                            DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, projectKey), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                    submit_text: rLIB('Delete'),
                    text: rLIB('Are you sure that you want to delete this sticky note?'),
                  },
                })
              }}
            />
          </Box>
        </Tooltip>
      )
    }
    return deleteIconJSX
  }

  const rJSX_StickyNotesTile = (): JSX.Element => {
    let stickyNoteContent = <></>
    let newNoteButtonJSX = <></>
    if (pr_readOrWrite === 'write') {
      newNoteButtonJSX = (
        <Icon
          icon="circle-plus"
          type="solid"
          className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
          tooltip={rLIB('New Sticky Note')}
          tooltipPlacement="top"
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: {
                    date: returnFormattedDate(new Date(), 'YYYY-MM-DD'),
                  },
                  formInputs: formInputs_NewStickyNote,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: formSettings_StickyNote,
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      let newNoteId = uuidv4()
                      let updateObject = {
                        sticky_notes: {
                          [newNoteId]: {
                            timestamp: new Date(
                              returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                            ),
                            note: formSubmittedData.note,
                            key: newNoteId,
                          },
                        },
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(res_GCK.clientKey, pr_opportunityKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'warning',
                  formDialogHeaderText: rLIB('Add Sticky Note'),
                  formDialogIcon: (
                    <Icon
                      icon="note"
                      type="solid"
                    />
                  ),
                },
              },
            })
          }}
        />
      )
    }
    let stickyNotes = getProp(us_rootOpportunity, 'sticky_notes', {})
    if (objectToArray(stickyNotes).length > 0) {
      stickyNoteContent = (
        <Box>
          {objectToArray(stickyNotes)
            .sort(dynamicSort('timestamp', 'desc'))
            .map((stickyNote: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Icon
                  icon="note"
                  className="tw-mr-1"
                  sx={{ color: themeVariables.secondary_main }}
                />
                <Box
                  component="span"
                  className="tw-inline-block tw-mr-1"
                  sx={{ fontWeight: 'bold' }}
                >
                  {returnFormattedDate(stickyNote.timestamp, 'M/D/YY')}
                  {' - '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-40"
                >
                  {stickyNote.note}
                </Box>
                {rJSX_EditStickyNoteIcon(
                  pr_readOrWrite,
                  stickyNote,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_setUserInterface_FormDialogDisplay,
                  uc_setUserInterface_ErrorDialogDisplay,
                  pr_opportunityKey,
                )}
                {rJSX_DeleteStickyNoteIcon(
                  pr_readOrWrite,
                  stickyNote.key,
                  stickyNote,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_setUserInterface_ErrorDialogDisplay,
                  uc_setUserInterface_ConfirmDialogDisplay,
                  pr_opportunityKey,
                )}
              </Box>
            ))}
        </Box>
      )
    } else {
      stickyNoteContent = <Box className="tw-italic tw-opacity-50 tw-inline-block">{rLIB('No Sticky Notes')}</Box>
    }
    // Return JSX
    return (
      <Box>
        <Typography
          variant="h6"
          className=""
        >
          <Box className="tw-inline-block tw-opacity-50">{rLIB('Sticky Notes')}</Box>
          {rJSX_MinimizeSectionIcons(us_minimizedStickyNotes, us_setMinimizedStickyNotes, 'us_minimizedStickyNotes')}
          {us_minimizedStickyNotes ? <></> : newNoteButtonJSX}
        </Typography>
        {us_minimizedStickyNotes ? (
          <Box
            className="tw-p-2 tw-text-center"
            sx={{ border: '1px solid ' + themeVariables.background_paper }}
          >
            <Button
              color="inherit"
              className="tw-opacity-30"
              onClick={() => {
                us_setMinimizedStickyNotes(false)
                setPageLocalStorage(tabKey, 'us_minimizedStickyNotes', false)
              }}
            >
              {rLIB('View')}
            </Button>
          </Box>
        ) : (
          <Card className="tw-p-2">{stickyNoteContent}</Card>
        )}
      </Box>
    )
  }

  // JSX - Full Page
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3 }}
          spacing={2}
        >
          {rJSX_DetailsTile()}
          {rJSX_SalesPartnerTile()}
          {rJSX_FinancingTile()}
          {rJSX_InstallPartnerTile()}
          {rJSX_AddersTile()}
          {rJSX_SystemTile()}
          {rJSX_HomeDetailsTile()}
          {rJSX_StickyNotesTile()}
          {/* {rJSX_TimelineTile()} */}
        </Masonry>
      </Box>
    )

    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
