///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material/'
import { formSettings_Project } from 'app/models/projects/project_forms'
import { useContext, useEffect, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_PowerwallOrders_Query } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseGetCollection } from 'rfbp_core/services/database_management'
import { downloadCSV, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { ProjectListPowerwallOrdersTab } from '../projects/v2_list_tabs/tab_powerwall'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['PowerwallOpportunitiesPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_powerwallTableViewType, us_setPowerwallTableViewType] = useState<string>('active')
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Powerwall Opportunities', false) as string
  }, [])

  // Functions
  const downloadPowerwallConversionStats = () => {
    let today = new Date()
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            start_date: returnFormattedDate(firstDay, 'YYYY-MM-DDThh:mm'),
            end_date: returnFormattedDate(lastDay, 'YYYY-MM-DDThh:mm'),
          },
          formInputs: {
            start_date: {
              key: 'start_date',
              label: rLIB('Start Date'),
              input_type: 'timestamp_datetime',
              required: true,
              data_type: 'string',
            },
            end_date: {
              key: 'end_date',
              label: rLIB('End Date'),
              input_type: 'timestamp_datetime',
              required: true,
              data_type: 'string',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_Project,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              downloadPowerwallConversionStatsProper(new Date(formSubmittedData.start_date), new Date(formSubmittedData.end_date))
                .then((res_DPCSP) => {
                  resolve(res_DPCSP)
                })
                .catch((rej_DPCSP) => {
                  reject(rej_DPCSP)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Download Powerwall Conversion Stats'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const downloadPowerwallConversionStatsProper = (startDate: Date, endDate: Date) => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_PowerwallOrders_Query(res_GCK.clientKey, startDate, endDate))
            .then((res_DGC) => {
              let exportData: string[][] = [
                [
                  'ID',
                  'First Name',
                  'Last Name',
                  'Email',
                  'Phone',
                  'Number of Powerwalls',
                  'Status',
                  'Page 1 (Contact info) status',
                  'Page 2 (Home info) status',
                  'Page 3 (Backup loads)',
                  'Page 4 (Order)',
                  'Start Timstamp',
                  'Agreed Timstamp',
                  'Deposit Timstamp',
                ],
              ]
              for (let loopUserOrderKey in res_DGC.data) {
                let loopUserOrder = res_DGC.data[loopUserOrderKey]
                if (loopUserOrder != null) {
                  let row: string[] = []
                  if (loopUserOrder.key != null) {
                    row.push(loopUserOrder.key)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.first_name != null) {
                    row.push(loopUserOrder.first_name)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.last_name != null) {
                    row.push(loopUserOrder.last_name)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.email != null) {
                    row.push(loopUserOrder.email)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.phone != null) {
                    row.push(loopUserOrder.phone)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.number_of_powerwalls_to_install != null) {
                    row.push(loopUserOrder.number_of_powerwalls_to_install)
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.status != null) {
                    row.push(loopUserOrder.status)
                  } else {
                    row.push('')
                  }
                  // Page Progress
                  if (loopUserOrder.step > 0) {
                    row.push('100%')
                  } else {
                    let totalContactItems = 7
                    let contactInfoItemsComplete = 0
                    if (loopUserOrder['email'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['first_name'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['last_name'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['phone'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_time_to_contact'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_time_to_contact_other'] != null) {
                      contactInfoItemsComplete++
                    }
                    if (loopUserOrder['best_contact_method'] != null) {
                      contactInfoItemsComplete++
                    }
                    let percentComplete = Math.round((contactInfoItemsComplete / totalContactItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.step > 1) {
                    row.push('100%')
                  } else {
                    let totalHomeItems = 9
                    let homeInfoItemsComplete = 0
                    if (loopUserOrder['install_location'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['utility_company'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_square_footage'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_year_built'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['is_home_owner'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['home_type'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['include_batteries_in_order'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['has_solar'] != null) {
                      homeInfoItemsComplete++
                    }
                    if (loopUserOrder['expanding_powerwall'] != null) {
                      homeInfoItemsComplete++
                    }
                    let percentComplete = Math.round((homeInfoItemsComplete / totalHomeItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.step > 2) {
                    row.push('100%')
                  } else {
                    let totalBackupItems = 2
                    let backupLoadsItemsComplete = 0
                    if (loopUserOrder['number_of_powerwalls_to_install'] != null) {
                      backupLoadsItemsComplete++
                    }
                    if (loopUserOrder['include_batteries_in_order'] != null) {
                      backupLoadsItemsComplete++
                    }
                    let percentComplete = Math.round((backupLoadsItemsComplete / totalBackupItems) * 100).toFixed(0)
                    row.push(percentComplete + '%')
                  }
                  if (loopUserOrder.timestamp_deposit_checkout_session_created != null) {
                    row.push('100%')
                  } else if (loopUserOrder.timestamp_agreed_to_terms != null) {
                    row.push('100%')
                  } else {
                    row.push('0%')
                  }
                  if (loopUserOrder.timestamp_created != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_created, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.timestamp_agreed_to_terms != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_agreed_to_terms, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  if (loopUserOrder.timestamp_deposit_placed != null) {
                    row.push(returnFormattedDate(loopUserOrder.timestamp_deposit_placed, 'D MMM YYYY h:mma'))
                  } else {
                    row.push('')
                  }
                  exportData.push(row)
                }
              }
              // Download
              let fileName =
                'Powerwall Conversion Stats (' + returnFormattedDate(startDate, 'D MMM YYYY') + ' to ' + returnFormattedDate(endDate, 'D MMM YYYY') + ').csv'
              downloadCSV(fileName, exportData)
                .then((res_DCSV) => {
                  resolve(res_DCSV)
                })
                .catch((rej_DCSV) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DCSV.error })
                  reject(rej_DCSV)
                })
            })
            .catch((rej_DGC) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
              reject(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_ArchivedPowerwallOrdersButton = () => {
    let buttonJSX = <></>
    if (us_powerwallTableViewType === 'archived') {
      buttonJSX = (
        <Button
          variant="contained"
          color="secondary"
          className="tw-mr-2"
          startIcon={
            <Icon
              icon="filter"
              type="solid"
            />
          }
          onClick={() => {
            us_setPowerwallTableViewType('active')
          }}
        >
          {rLIB('View Active Orders')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          color="secondary"
          className="tw-mr-2"
          startIcon={
            <Icon
              icon="filter"
              type="solid"
            />
          }
          onClick={() => {
            us_setPowerwallTableViewType('archived')
          }}
        >
          {rLIB('View Archived Orders')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_DownloadPowerwallConversionStatsButton = () => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="info"
        startIcon={
          <Icon
            icon="cloud-arrow-down"
            type="solid"
          />
        }
        onClick={() => {
          downloadPowerwallConversionStats()
        }}
      >
        {rLIB('Download Conversion Stats')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageTabs: TsInterface_TabContentUrlArray = []
    pageTabs.push({
      tabUrlKey: 'Powerwall_Orders',
      tabHeader: rLIB('Powerwall Orders'),
      tabContent: <ProjectListPowerwallOrdersTab us_powerwallTableViewType={us_powerwallTableViewType} />,
      tabButtons: [
        { fullJSX: rJSX_ArchivedPowerwallOrdersButton(), minJSX: rJSX_ArchivedPowerwallOrdersButton(), sizeCutoff: 0 },
        { fullJSX: rJSX_DownloadPowerwallConversionStatsButton(), minJSX: rJSX_DownloadPowerwallConversionStatsButton(), sizeCutoff: 0 },
      ],
    })
    // JSX
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Powerwall Opportunities')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={pageTabs}
              tabsSettings={{
                tabQueryParam: 'tab',
                baseUrl: ApplicationPages['AdminActiveProjectsListPage'].url(),
                overridePageTitle: true,
                basePageTitle: rLIB('Active Projects', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
