/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Chip, Switch, Tooltip, Typography } from '@mui/material'
import { globalTaskTypes } from 'app/models/tasks/global_tasks'
import { adHocTaskAssignmentTypeInputOptions, taskCompletionSubtypeInputOptions, taskDelayReasonInputOptions } from 'app/models/tasks/task_workflow_services'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { hardcodedProjectPhases, returnClientUserRoles, schedulingTeamTypes } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskBlueprints_Collection, DatabaseRef_TaskBlueprint_Document } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskFormsByTaskCategoryActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageAction,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { arrayToObject, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_TaskLibraryTasksListTab {
  us_taskType: any
  us_taskCategory: any
}

///////////////////////////////
// Variables
///////////////////////////////

const returnProjectPhaseOptions = (): TsInterface_UnspecifiedObject[] => {
  let options: TsInterface_UnspecifiedObject[] = []
  for (let loopProjectPhaseKey in hardcodedProjectPhases) {
    let loopProjectPhase = hardcodedProjectPhases[loopProjectPhaseKey]
    options.push({
      key: loopProjectPhase.key,
      value: loopProjectPhase.name,
    })
  }
  return options
}

// Table
const tableSettings_Tasks: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  collapsible_columns: true,
  // conditional_row_styles: [
  // 	{
  // 		className: "tw-opacity-30 tw-line-through",
  // 		conditional_display: {
  // 			active: true,
  // 			logic_type: "comparison",
  // 			source: "rowData",
  // 			prop: "status",
  // 			comparator: "==",
  // 			value: "deleted",
  // 			conditions: []
  // 		},
  // 	},
  // ],
  // searchable: true,
  // search_settings_database: {
  // 	search_type: "firebase",
  // 	search_no_data_message: s_NO_TASK_FORMS_FOUND_BY_SEARCH,
  // 	search_property_options: [
  // 		{ key: "name", value: s_FORM_NAME },
  // 	]
  // },
}

// Form
const formInputs_DelayReason: TsInterface_FormInputs = {
  delay_reason: {
    key: 'delay_reason',
    label: rLIB('Delay Reason'),
    // input_type: "text_basic",
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: objectToArray(taskDelayReasonInputOptions),
  },
}

const formInputs_SowTaskCompletionType: TsInterface_FormInputs = {
  task_completion_type: {
    key: 'task_completion_type',
    label: rLIB('Task Completion Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: objectToArray(adHocTaskAssignmentTypeInputOptions),
  },
}

const formInputs_SowTaskCompletionSubtype: TsInterface_FormInputs = {
  task_completion_subtype: {
    key: 'task_completion_subtype',
    label: rLIB('Task Completion Subtype'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: objectToArray(taskCompletionSubtypeInputOptions),
  },
}

const formInputs_EstimatedDays: TsInterface_FormInputs = {
  status_green_days_cutoff: {
    key: 'status_green_days_cutoff',
    label: rLIB('Green Status Cutoff (days)'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    startAdornment: (
      <Box sx={{ color: themeVariables.success_main }}>
        <Icon icon="circle-play" />
      </Box>
    ),
  },
  status_yellow_days_cutoff: {
    key: 'status_yellow_days_cutoff',
    label: rLIB('Yellow Status Cutoff (days)'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    startAdornment: (
      <Box sx={{ color: themeVariables.warning_main }}>
        <Icon icon="triangle-exclamation" />
      </Box>
    ),
  },
}
const formInputs_TaskTeamType: TsInterface_FormInputs = {
  associated_team_type: {
    key: 'associated_team_type',
    label: rLIB('Scheduled Team Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: objectToArray(schedulingTeamTypes),
  },
}

const formInputs_TaskAbbreviation: TsInterface_FormInputs = {
  abbreviation: {
    key: 'abbreviation',
    label: rLIB('Task Abbreviation'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

const manageCellRenameTask: TsInterface_TableManageAction = {
  icon: <Icon icon="pen-to-square" />,
  label: <>{rLIB('Edit')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      const editSowItemPropmt: TsInterface_PromptDialogObject = {
        color: 'info',
        confirm_text: rLIB('Rename') as JSX.Element,
        default_value: getProp(rowData, 'name', null),
        header: rLIB('Rename Task'),
        icon: (
          <Icon
            icon="sitemap"
            type="solid"
          />
        ),
        input_label: rLIB('Task Name') as JSX.Element,
        input_type: 'text',
        text: rLIB('Enter a name for this task'),
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            let updateObject = {
              name: promptValue,
            }
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                  .then((res_DSMD) => {
                    resolve({ success: true })
                  })
                  .catch((rej_DSMD) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      }
      tableHooks.uc_setUserInterface_PromptDialogDisplay({
        display: true,
        prompt: editSowItemPropmt,
      })
    }
  },
}

const manageCellDeleteTask: TsInterface_TableManageAction = {
  icon: <Icon icon="trash" />,
  label: <>{rLIB('Delete')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
        display: true,
        confirm: {
          color: 'error',
          header: rLIB('Delete Task'),
          icon: (
            <Icon
              icon="trash"
              type="solid"
            />
          ),
          submit_text: rLIB('Delete'),
          text: rLIB('Are you sure that you want to delete this task?'),
          submit_callback: () => {
            return new Promise((resolve, reject) => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = { status: 'deleted' }
                  DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
      })
    }
  },
}
const formInputs_ProjectPhase: TsInterface_FormInputs = {
  associated_project_phase_key: {
    key: 'associated_project_phase_key',
    label: rLIB('Project Phase'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: returnProjectPhaseOptions(),
  },
}

const formInputs_AssociatedTaskForm: TsInterface_FormInputs = {
  associated_task_form_key: {
    key: 'associated_task_form_key',
    label: rLIB('Task Form'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: [],
  },
}

const formInputs_TaskOwnerType: TsInterface_FormInputs = {
  associated_owner_type: {
    key: 'associated_owner_type',
    label: rLIB('Owner User Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: [],
  },
}

const formInputs_TaskDispatcherType: TsInterface_FormInputs = {
  associated_dispatcher_type: {
    key: 'associated_dispatcher_type',
    label: rLIB('Dispatcher Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: [],
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const TaskLibraryTasksListTab = forwardRef((props: TsInterface_TaskLibraryTasksListTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_us_taskType: TsInterface_TaskLibraryTasksListTab['us_taskType'] = getProp(props, 'us_taskType', null)
  let pr_us_taskCategory: TsInterface_TaskLibraryTasksListTab['us_taskCategory'] = getProp(props, 'us_taskCategory', null)

  // Hooks - useContext, useState, useReducer, other
  const [us_taskFormOptions, us_setTaskFormOptions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskOwnerOptions, us_setTaskOwnerOptions] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let taskFormsDataOptions: TsInterface_UnspecifiedObject = {}
      for (let loopTaskFormKey in newData) {
        let loopTaskForm = newData[loopTaskFormKey]
        if (loopTaskForm != null && loopTaskForm['name'] != null) {
          taskFormsDataOptions[loopTaskFormKey] = {
            key: loopTaskFormKey,
            value: loopTaskForm['name'],
          }
          if (loopTaskForm['available_for_use'] !== true) {
            taskFormsDataOptions[loopTaskFormKey]['disabled'] = true
          }
        }
      }
      us_setTaskFormOptions(taskFormsDataOptions)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_TaskFormsByTaskCategoryActive_Query(res_GCK.clientKey, pr_us_taskCategory, 'name', false, null, {}),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_us_taskCategory])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let clientUserRoles = returnClientUserRoles(res_GCK.clientKey)
        let formattedUserRoles: TsInterface_UnspecifiedObject = {}
        for (let loopUserRoleKey in clientUserRoles) {
          formattedUserRoles[loopUserRoleKey] = {
            key: loopUserRoleKey,
            value: clientUserRoles[loopUserRoleKey]['name'],
          }
        }
        us_setTaskOwnerOptions(formattedUserRoles)
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const openTaskPropertyEditForm = (task: TsInterface_UnspecifiedObject, formInputs: TsInterface_FormInputs): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: task,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, task.key), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task')} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const openSowTaskPropertyEditForm = (
    task: TsInterface_UnspecifiedObject,
    formInputs: TsInterface_FormInputs,
    formSettings: TsInterface_FormSettings,
  ): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: task,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, task.key), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      // setLastEditedTaskKey( task.key )
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task')} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const addSowDelayReason = (task: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_DelayReason,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_hide: true,
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                delay_reasons: {},
              }
              taskUpdateObject['delay_reasons'][formSubmittedData.delay_reason] = formSubmittedData.delay_reason
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, task.key), taskUpdateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Add Delay Reason'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteSowDelayReason = (task: TsInterface_UnspecifiedObject, delayReasonKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Delay Reason'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this delay reason?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (delayReasonKey != null && task != null && task.delay_reasons != null && task.delay_reasons[delayReasonKey] != null) {
              let taskUpdateObject = {
                delay_reasons: task.delay_reasons,
              }
              delete taskUpdateObject['delay_reasons'][delayReasonKey]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseUpdateDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, task.key), taskUpdateObject)
                    .then((res_DUD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DUD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            } else {
              resolve({ success: false })
            }
          })
        },
      },
    })
  }

  const tableDatabaseEndpoint_Tasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'task_type', comparator: '==', value: tableAdditionalData.pr_us_taskType },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_TaskBlueprints_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableColumns_Tasks = () => {
    let tableColumns: TsInterface_TableColumns = {
      manage: TableCellManage({
        rename: manageCellRenameTask,
        delete: manageCellDeleteTask,
      }),
      name: TableCellBasic('name', rLIB('Task Name'), null),
      abbreviation: {
        header: {
          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
            return rLIB('Task Abbreviation')
          },
          header_sort_by: 'abbreviation',
        },
        cell: {
          cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
            let cellJSX = <>{rJSX_AbbreviationChip(rowData, tableAdditionalData.editable)}</>
            return cellJSX
          },
          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
            let cellCSS = ''
            if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
              cellCSS = 'workflow_table_highlight'
            }
            return cellCSS
          },
        },
      },
    }

    if (pr_us_taskCategory === 'project') {
      const projectTableColumns: TsInterface_TableColumns = {
        additional_work_task: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              let headerJSX = <></>
              if (
                tableAdditionalData != null &&
                tableAdditionalData.globalTaskTypes != null &&
                pr_us_taskType != null &&
                tableAdditionalData.globalTaskTypes[pr_us_taskType] != null &&
                tableAdditionalData.globalTaskTypes[pr_us_taskType]['additional_work_task'] === true
              ) {
                headerJSX = rLIB('Available as additional work') as JSX.Element
              }
              return headerJSX
            },
            header_sort_by: 'additional_work_task',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <></>
              if (
                tableAdditionalData != null &&
                tableAdditionalData.globalTaskTypes != null &&
                rowData.task_type != null &&
                tableAdditionalData.globalTaskTypes[rowData.task_type as string] != null &&
                tableAdditionalData.globalTaskTypes[rowData.task_type as string]['additional_work_task'] === true
              ) {
                cellJSX = (
                  <Box>
                    <Switch
                      disabled={true}
                      color="info"
                      checked={rowData.additional_work_task as boolean}
                      onChange={(event, value) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          additional_work_task: value,
                        }
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                // nothing
                              })
                              .catch((rej_DSMD) => {
                                console.error(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                          })
                      }}
                    />
                  </Box>
                )
              }
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        task_completion_type: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Task Type')
            },
            header_sort_by: 'task_completion_type',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <>{rJSX_SowCompletionTypeChip(rowData, tableAdditionalData.editable)}</>
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        task_completion_subtype: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Task Subtype')
            },
            header_sort_by: 'task_completion_subtype',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <>{rJSX_SowCompletionSubTypeChip(rowData, tableAdditionalData.editable)}</>
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        associated_task_form_key: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Task Form')
            },
            header_sort_by: 'associated_task_form_key',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <>{rJSX_SowFormTypeChip(rowData, tableAdditionalData.editable)}</>
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        delay_reasons: {
          cell: {
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <></>
              const rJSX_FormattedDelayReason = (delayReasonKey: string): JSX.Element => {
                let reasonJSX = <></>
                if (
                  delayReasonKey != null &&
                  taskDelayReasonInputOptions != null &&
                  taskDelayReasonInputOptions[delayReasonKey] != null &&
                  taskDelayReasonInputOptions[delayReasonKey]['value'] != null
                ) {
                  reasonJSX = <>{taskDelayReasonInputOptions[delayReasonKey]['value']}</>
                } else {
                  reasonJSX = <>{delayReasonKey}</>
                }
                return reasonJSX
              }
              const getDelayReasonsJSX = (delayReasonKey: string): JSX.Element => {
                let taskJSX = (
                  <Box className="tw-mb-1">
                    <Box className="tw-inline-block tw-mr-1">
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="square-check"
                      />
                    </Box>
                    <Box className="tw-inline-block">
                      {rJSX_FormattedDelayReason(delayReasonKey)}
                      <Tooltip title={rLIB('Delete Delay Reason')}>
                        <Typography
                          variant="body1"
                          className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                          sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                          onClick={() => {
                            if (tableAdditionalData.editable === true) {
                              deleteSowDelayReason(rowData, delayReasonKey)
                            }
                          }}
                        >
                          <>
                            <Icon
                              icon="trash"
                              type="solid"
                            ></Icon>
                          </>
                        </Typography>
                      </Tooltip>
                      {rJSX_SowDelayReasonStarIcon(rowData, delayReasonKey, true)}
                    </Box>
                  </Box>
                )
                return taskJSX
              }
              if (
                rowData != null &&
                rowData.delay_reasons != null &&
                objectToArray(rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject).length > 0
              ) {
                cellJSX = (
                  <Box>
                    <Box className="tw-mb-1">
                      <Box
                        className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (tableAdditionalData.editable === true) {
                            addSowDelayReason(rowData)
                          }
                        }}
                      >
                        <>
                          <Icon
                            size="xl"
                            className="tw-mr-1"
                            icon="circle-plus"
                            type="solid"
                          ></Icon>
                        </>
                      </Box>
                      <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Delay Reason')}</Box>
                    </Box>
                    {objectToArray(rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject)
                      .sort()
                      .map((delayReasonKey, taskIndex) => (
                        <Box key={taskIndex}>{getDelayReasonsJSX(delayReasonKey)}</Box>
                      ))}
                  </Box>
                )
              } else {
                cellJSX = (
                  <Box>
                    <Box className="tw-mb-1">
                      <Box
                        className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (tableAdditionalData.editable === true) {
                            addSowDelayReason(rowData)
                          }
                        }}
                      >
                        <>
                          <Icon
                            size="xl"
                            className="tw-mr-1"
                            icon="circle-plus"
                            type="solid"
                          ></Icon>
                        </>
                      </Box>
                      <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Delay Reason')}</Box>
                    </Box>
                  </Box>
                )
              }
              return cellJSX
            },
          },
          header: {
            header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return ''
            },
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Delay Reasons')
            },
            header_sort_by: null,
          },
        },
        associated_dispatcher_type: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Dispatcher Type')
            },
            header_sort_by: 'associated_task_group_key',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <></>
              if (rowData.task_completion_type === 'scheduled') {
                cellJSX = <>{rJSX_SowDispatcherChip(rowData, tableAdditionalData.editable)}</>
              } else {
                cellJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Not Applicable')}</Box>
              }
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        associated_team_type: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Scheduled Team Type')
            },
            header_sort_by: 'associated_task_group_key',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <></>
              if (rowData.task_completion_type === 'scheduled') {
                cellJSX = <>{rJSX_SowTeamTypeGroupChip(rowData, tableAdditionalData.editable)}</>
              } else {
                cellJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Not Applicable')}</Box>
              }
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        associated_owner_type: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Owner User Type')
            },
            header_sort_by: 'associated_owner_type',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <>{rJSX_SowOwnerTypeChip(rowData, tableAdditionalData.editable, true)}</>
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        associated_project_phase_key: {
          cell: {
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              return ''
            },
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <>{rJSX_ProjectPhaseChip(rowData, tableAdditionalData.editable)}</>
              return cellJSX
            },
          },
          header: {
            header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return ''
            },
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Project Phase')
            },
            header_sort_by: null,
          },
        },
        status_green_days_cutoff: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Status cutoff (days)')
            },
            header_sort_by: 'status_green_days_cutoff',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <>{rJSX_SowEstimatedDaysChips(rowData, tableAdditionalData.editable)}</>
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        project_milestone_mapping: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return <></>
            },
            header_sort_by: null,
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <></>
              if (rowData != null && (rowData.project_milestone_mapping != null || rowData.scheduling_project_milestone_mapping != null)) {
                cellJSX = (
                  <Box>
                    <Icon
                      tooltipPlacement="left"
                      tooltip={rLIB('Project Milestone Mapping Config')}
                      onClick={() => {
                        tableHooks.uc_setUserInterface_CustomDialogDisplay({
                          display: true,
                          dialog: {
                            dialog_jsx: (
                              <Json
                                alphabetized={true}
                                data={{
                                  project_milestone_mapping: getProp(rowData, 'project_milestone_mapping', {}),
                                  scheduling_project_milestone_mapping: getProp(rowData, 'scheduling_project_milestone_mapping', {}),
                                }}
                              />
                            ),
                            settings: {
                              max_width: 'lg',
                            },
                          },
                        })
                      }}
                      icon="diamond-turn-right"
                      sx={{ color: themeVariables.warning_main, fontSize: '20px' }}
                    />
                  </Box>
                )
              }
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        manage2: TableCellManage({
          rename: manageCellRenameTask,
          delete: manageCellDeleteTask,
        }),
      }
      tableColumns = { ...tableColumns, ...projectTableColumns }
    } else if (pr_us_taskCategory === 'administration') {
      const administrationTableColumns: TsInterface_TableColumns = {
        associated_task_form_key: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Task Form')
            },
            header_sort_by: 'associated_task_form_key',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <>{rJSX_SowFormTypeChip(rowData, tableAdditionalData.editable)}</>
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        special_instructions: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Special Instructions')
            },
            header_sort_by: 'special_instructions',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <Box></Box>
              let editIconJSX = (
                <Tooltip
                  title={rLIB('Edit Special Instructions')}
                  placement="right"
                >
                  <Box
                    className="tw-inline-block tw-ml-2 tw-opacity-30 tw-cursor-pointer"
                    onClick={() => {
                      tableHooks.uc_setUserInterface_FormDialogDisplay({
                        display: true,
                        form: {
                          form: {
                            formAdditionalData: {},
                            formData: rowData,
                            formInputs: {
                              special_instructions: {
                                data_type: 'string',
                                input_type: 'text_multiline',
                                key: 'special_instructions',
                                label: rLIB('Special Instructions'),
                                required: false,
                              },
                            },
                            formOnChange: (
                              formAdditionalData: TsInterface_FormAdditionalData,
                              formData: TsInterface_FormData,
                              formInputs: TsInterface_FormInputs,
                              formSettings: TsInterface_FormSettings,
                            ) => {},
                            formSettings: {},
                            formSubmission: (
                              formSubmittedData: TsInterface_FormSubmittedData,
                              formAdditionalData: TsInterface_FormAdditionalData,
                              formHooks: TsInterface_FormHooksObject,
                            ) => {
                              return new Promise((resolve, reject) => {
                                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                  .then((res_GCK) => {
                                    let updateObject = {
                                      special_instructions: getProp(formSubmittedData, 'special_instructions', null),
                                    }
                                    DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                      .then((res_DSMD) => {
                                        resolve(res_DSMD)
                                      })
                                      .catch((rej_DSMD) => {
                                        reject(rej_DSMD)
                                      })
                                  })
                                  .catch((rej_GCK) => {
                                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                  })
                              })
                            },
                          },
                          dialog: {
                            formDialogHeaderColor: 'success',
                            formDialogHeaderText: (
                              <>
                                {rLIB('Edit Special Instructions')} - {rowData.name}
                              </>
                            ),
                            formDialogIcon: (
                              <Icon
                                type="solid"
                                icon="pen-to-square"
                              />
                            ),
                          },
                        },
                      })
                    }}
                  >
                    <Icon icon="pen-to-square" />
                  </Box>
                </Tooltip>
              )
              if (rowData != null && rowData.special_instructions != null && rowData.special_instructions !== '') {
                cellJSX = (
                  <Box>
                    <Typography className="tw-inline-block">{rowData.special_instructions}</Typography>
                    {editIconJSX}
                  </Box>
                )
              } else {
                cellJSX = (
                  <Box>
                    <Typography className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('No Special Instructions')}</Typography>
                    {editIconJSX}
                  </Box>
                )
              }
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              return cellCSS
            },
          },
        },
        status_green_days_cutoff: {
          header: {
            header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
              return rLIB('Status cutoff (days)')
            },
            header_sort_by: 'status_green_days_cutoff',
          },
          cell: {
            cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
              let cellJSX = <>{rJSX_SowEstimatedDaysChips(rowData, tableAdditionalData.editable)}</>
              return cellJSX
            },
            cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
              let cellCSS = ''
              if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
                cellCSS = 'workflow_table_highlight'
              }
              return cellCSS
            },
          },
        },
        manage2: TableCellManage({
          rename: manageCellRenameTask,
          delete: manageCellDeleteTask,
        }),
      }
      tableColumns = { ...tableColumns, ...administrationTableColumns }
    }

    return tableColumns
  }

  // Other Variables
  formInputs_TaskOwnerType['associated_owner_type']['options'] = objectToArray(us_taskOwnerOptions)
  formInputs_TaskDispatcherType['associated_dispatcher_type']['options'] = objectToArray(us_taskOwnerOptions)
  formInputs_AssociatedTaskForm['associated_task_form_key']['options'] = objectToArray(us_taskFormOptions)
  const tableAdditionalData_Tasks: TsInterface_TableAdditionalData = {
    editable: true,
    pr_us_taskType: pr_us_taskType,
    globalTaskTypes: globalTaskTypes,
    pr_us_taskCategory: pr_us_taskCategory,
  }

  // JSX Generation
  const rJSX_AbbreviationChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let requiredTypeJSX = <></>
    if (task.abbreviation != null) {
      requiredTypeJSX = (
        <Chip
          sx={{ maxWidth: '230px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={task.abbreviation}
          variant="filled"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_TaskAbbreviation)
            }
          }}
        />
      )
    } else {
      requiredTypeJSX = (
        <Chip
          sx={{ maxWidth: '230px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_TaskAbbreviation)
            }
          }}
        />
      )
    }
    return requiredTypeJSX
  }

  const rJSX_SowCompletionTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let assignmentTypeJSX = <></>
    if (task.task_completion_type != null) {
      let labelValue = task['task_completion_type']
      // let chipVariant: "outlined" | "filled" | undefined = "outlined"
      // let chipColor: TsType_MuiComponentColors = "warning"
      let chipVariant: 'outlined' | 'filled' | undefined = 'filled'
      let chipColor: TsType_MuiComponentColors = undefined
      if (
        formInputs_SowTaskCompletionType != null &&
        formInputs_SowTaskCompletionType['task_completion_type'] != null &&
        formInputs_SowTaskCompletionType['task_completion_type']['options'] != null
      ) {
        let optionsObject = arrayToObject(formInputs_SowTaskCompletionType['task_completion_type']['options'], 'key')
        if (optionsObject != null && optionsObject[labelValue] != null && optionsObject[labelValue]['value'] != null) {
          labelValue = optionsObject[labelValue]['value']
        }
      }
      if (adHocTaskAssignmentTypeInputOptions[task.task_completion_type] == null) {
        chipColor = 'warning'
      }
      assignmentTypeJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '165px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={labelValue}
            variant={chipVariant}
            color={chipColor}
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_SowTaskCompletionType, { submit_button_hide: true })
              }
            }}
          />
        </Box>
      )
    } else {
      assignmentTypeJSX = (
        <Chip
          sx={{ maxWidth: '165px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_SowTaskCompletionType, { submit_button_hide: true })
            }
          }}
        />
      )
    }
    return assignmentTypeJSX
  }

  const rJSX_SowCompletionSubTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let assignmentTypeJSX = <></>
    if (task.task_completion_subtype != null) {
      let labelValue = task['task_completion_subtype']
      let chipVariant: 'outlined' | 'filled' | undefined = 'filled'
      let chipColor: TsType_MuiComponentColors = undefined
      if (
        formInputs_SowTaskCompletionSubtype != null &&
        formInputs_SowTaskCompletionSubtype['task_completion_subtype'] != null &&
        formInputs_SowTaskCompletionSubtype['task_completion_subtype']['options'] != null
      ) {
        let optionsObject = arrayToObject(formInputs_SowTaskCompletionSubtype['task_completion_subtype']['options'], 'key')
        if (optionsObject != null && optionsObject[labelValue] != null && optionsObject[labelValue]['value'] != null) {
          labelValue = optionsObject[labelValue]['value']
        }
      }
      if (taskCompletionSubtypeInputOptions[task.task_completion_subtype] == null) {
        chipColor = 'warning'
      }
      assignmentTypeJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '165px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={labelValue}
            variant={chipVariant}
            color={chipColor}
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_SowTaskCompletionSubtype, { submit_button_hide: true })
              }
            }}
          />
        </Box>
      )
    } else {
      assignmentTypeJSX = (
        <Chip
          sx={{ maxWidth: '165px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_SowTaskCompletionSubtype, { submit_button_hide: true })
            }
          }}
        />
      )
    }
    return assignmentTypeJSX
  }

  const rJSX_SowFormTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let chipJSX = <></>
    if (task.associated_task_form_key != null) {
      if (
        us_taskFormOptions != null &&
        us_taskFormOptions[task.associated_task_form_key] != null &&
        us_taskFormOptions[task.associated_task_form_key]['value'] != null
      ) {
        chipJSX = (
          <Box className="tw-inline-block">
            <Chip
              sx={{ maxWidth: '190px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={us_taskFormOptions[task.associated_task_form_key]['value']}
              variant="filled"
              onClick={() => {
                if (editable === true) {
                  // updateTaskFormOptionsSortOrder()
                  openSowTaskPropertyEditForm(task, formInputs_AssociatedTaskForm, { submit_button_hide: true })
                }
              }}
            />
            {/* <Box className="tw-inline-block tw-cursor-pointer tw-ml-2" onClick={ () => {
							previewTaskForm( task )
						}}>
							<Icon icon="magnifying-glass" />
						</Box> */}
          </Box>
        )
      } else {
        chipJSX = (
          <Chip
            sx={{ maxWidth: '190px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_task_form_key}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                // updateTaskFormOptionsSortOrder()
                openSowTaskPropertyEditForm(task, formInputs_AssociatedTaskForm, { submit_button_hide: true })
              }
            }}
          />
        )
      }
    } else {
      chipJSX = (
        <Chip
          sx={{ maxWidth: '190px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              // updateTaskFormOptionsSortOrder()
              openSowTaskPropertyEditForm(task, formInputs_AssociatedTaskForm, { submit_button_hide: true })
            }
          }}
        />
      )
    }
    return chipJSX
  }

  const rJSX_SowOwnerTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean, styleOverride: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    if (task.associated_owner_type != null) {
      if (
        us_taskOwnerOptions != null &&
        us_taskOwnerOptions[task.associated_owner_type] != null &&
        us_taskOwnerOptions[task.associated_owner_type]['value'] != null
      ) {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={us_taskOwnerOptions[task.associated_owner_type]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskOwnerType, { submit_button_hide: true })
              }
            }}
          />
        )
      } else {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_owner_type}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskOwnerType, { submit_button_hide: true })
              }
            }}
          />
        )
      }
    } else {
      ownerTypeJSX = (
        <Chip
          sx={{ maxWidth: '170px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_TaskOwnerType, { submit_button_hide: true })
            }
          }}
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_ProjectPhaseChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let phaseChipJSX = <></>

    if (task.associated_project_phase_key != null) {
      if (
        hardcodedProjectPhases != null &&
        hardcodedProjectPhases[task.associated_project_phase_key] != null &&
        hardcodedProjectPhases[task.associated_project_phase_key]['name'] != null
      ) {
        phaseChipJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={hardcodedProjectPhases[task.associated_project_phase_key]['name']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_ProjectPhase, { submit_button_hide: true })
              }
            }}
          />
        )
      } else {
        phaseChipJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_project_phase_key}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_ProjectPhase, { submit_button_hide: true })
              }
            }}
          />
        )
      }
    } else {
      phaseChipJSX = (
        <Chip
          sx={{ maxWidth: '170px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_ProjectPhase, { submit_button_hide: true })
            }
          }}
        />
      )
    }

    return phaseChipJSX
  }

  const rJSX_SowEstimatedDaysChips = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    // if( task.status_green_days_cutoff != null && task.status_yellow_days_cutoff != null && task.status_red_days_cutoff != null ){
    if (task.status_green_days_cutoff != null && task.status_yellow_days_cutoff != null) {
      // let blackLabel = ( task.status_red_days_cutoff + 1 ) + "+"
      let redLabel = task.status_yellow_days_cutoff + 1 + '+'
      ownerTypeJSX = (
        <Box className="tw-inline-block">
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={task.status_green_days_cutoff}
            variant="filled"
            color="success"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_EstimatedDays, {})
              }
            }}
          />
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={task.status_yellow_days_cutoff}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_EstimatedDays, {})
              }
            }}
          />
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={redLabel}
            variant="filled"
            color="error"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_EstimatedDays, {})
              }
            }}
          />
          {/* <Chip className="tw-cursor-pointer tw-mx-0.5" label={ blackLabel  } variant="filled" sx={{ background: themeVariables.palettes.custom_dark_red.main, "&:hover": { background: themeVariables.palettes.custom_dark_red.dark, opacity: 1 } }} onClick={ () => {
						if( editable === true ) {
							openSowTaskPropertyEditForm( task, formInputs_EstimatedDays, {} )
						}
					} }/> */}
        </Box>
      )
    } else {
      ownerTypeJSX = (
        <Chip
          className="tw-cursor-pointer"
          label={rLIB('Not Specified')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_EstimatedDays, {})
            }
          }}
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_SowDispatcherChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let dispatcherTypeJSX = <></>
    if (task.associated_dispatcher_type != null) {
      if (
        us_taskOwnerOptions != null &&
        us_taskOwnerOptions[task.associated_dispatcher_type] != null &&
        us_taskOwnerOptions[task.associated_dispatcher_type]['value'] != null
      ) {
        dispatcherTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={us_taskOwnerOptions[task.associated_dispatcher_type]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskDispatcherType, { submit_button_hide: true })
              }
            }}
          />
        )
      } else {
        dispatcherTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_dispatcher_type}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskDispatcherType, { submit_button_hide: true })
              }
            }}
          />
        )
      }
    } else {
      dispatcherTypeJSX = (
        <Chip
          sx={{ maxWidth: '170px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_TaskDispatcherType, { submit_button_hide: true })
            }
          }}
        />
      )
    }
    return dispatcherTypeJSX
  }

  const rJSX_SowTeamTypeGroupChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let teamTypeJSX = <></>
    if (task.associated_team_type != null) {
      if (
        schedulingTeamTypes != null &&
        schedulingTeamTypes[task.associated_team_type] != null &&
        schedulingTeamTypes[task.associated_team_type]['value'] != null
      ) {
        teamTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={schedulingTeamTypes[task.associated_team_type]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskTeamType, { submit_button_hide: true })
              }
            }}
          />
        )
      } else {
        teamTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_team_type}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskTeamType, { submit_button_hide: true })
              }
            }}
          />
        )
      }
    } else {
      teamTypeJSX = (
        <Chip
          sx={{ maxWidth: '170px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_TaskTeamType, { submit_button_hide: true })
            }
          }}
        />
      )
    }
    return teamTypeJSX
  }

  const rJSX_SowDelayReasonStarIcon = (task: TsInterface_UnspecifiedObject, delayReason: string, editable: boolean): JSX.Element => {
    let iconJSX = <></>
    if (task.default_delay_reason != null && task.default_delay_reason === delayReason) {
      iconJSX = (
        <Tooltip title={rLIB('Default Delay Reason')}>
          <Typography
            variant="body1"
            className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
            sx={{}}
            onClick={() => {
              if (editable === true) {
                let updateObject = {
                  default_delay_reason: null,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        // setLastEditedTaskKey( task.key )
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          >
            <>
              <Icon
                icon="star"
                type="solid"
              ></Icon>
            </>
          </Typography>
        </Tooltip>
      )
    } else {
      iconJSX = (
        <Tooltip title={rLIB('Default Delay Reason')}>
          <Typography
            variant="body1"
            className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
            sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
            onClick={() => {
              if (editable === true) {
                let updateObject = {
                  default_delay_reason: delayReason,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        // setLastEditedTaskKey( task.key )
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          >
            <>
              <Icon
                icon="star"
                type="thin"
              ></Icon>
            </>
          </Typography>
        </Tooltip>
      )
    }
    return iconJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_Tasks}
            tableColumns={tableColumns_Tasks()}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Tasks}
            tableSettings={tableSettings_Tasks}
          />
        </Card>
      )
    }
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
