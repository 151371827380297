///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  ButtonGroup,
  capitalize,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/'
import { Timestamp } from 'firebase/firestore'
import pluralize from 'pluralize'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
  DatabaseRef_MasterSkuItem_ManufacturerIDAndSupplier_Query,
  DatabaseRef_MasterSKU_Document,
} from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import { DatabaseRef_Order_Document } from 'rfbp_aux/services/database_endpoints/materials/orders'
import { DatabaseRef_SkuLineItemsOnOrder_Query, DatabaseRef_SkuLineItem_Document } from 'rfbp_aux/services/database_endpoints/materials/sku_line_items'
import { DatabaseRef_Supplier_Document } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { SkuImportButtonAndDialog } from 'rfbp_core/components/imports/bom_import_and_basic_dialog'
import { searchIndex } from 'rfbp_core/components/search'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseDeleteDocument,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { ProjectFilesView } from '../projects/components/files'
import { ProjectImagesView } from '../projects/components/images'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import React from 'react'
import { TableBasic, TsInterface_TableDatabaseSettings } from 'rfbp_core/components/table'

import { capitalizeFirstLetterInString } from 'rfbp_core/services/helper_functions/capitalize_first_letter_in_string'

import { Document, Font, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Register fonts (mimicking your unauth approach)
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
})
Font.register({
  family: 'Font Awesome',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/fontawesome-webfont.ttf',
})

// Styles from your unauth snippet
export const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    padding: 40,
  },
  header: {
    fontSize: 14,
    marginBottom: 4,
    fontFamily: 'Roboto',
  },
  paragraph: {
    paddingLeft: 8,
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  table: {
    display: 'flex',
    width: 'auto',
  },
  row: {
    flexDirection: 'row',
  },
  poNumberCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  poNumberCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  requestorCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  requestorCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  pickupDateCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  pickupDateCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  checkCell: {
    fontSize: 11,
    minWidth: 24,
    maxWidth: 24,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderTopWidth: 0,
    flexWrap: 'wrap',
    fontFamily: 'Font Awesome',
  },
  quantityCell: {
    fontSize: 11,
    minWidth: 40,
    maxWidth: 40,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
  },
  skuCell: {
    fontSize: 11,
    minWidth: 140,
    maxWidth: 140,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  nameCell: {
    fontSize: 11,
    minWidth: 315,
    maxWidth: 315,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
  },
  checkCellHeader: {
    fontSize: 14,
    minWidth: 24,
    maxWidth: 24,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
    fontFamily: 'Font Awesome',
  },
  quantityCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 40,
    maxWidth: 40,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  skuCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 140,
    maxWidth: 140,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  nameCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 315,
    maxWidth: 315,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  opacity_40: {
    opacity: 0.4,
  },
})

export const sendOrderFieldUpdateEmail = (orderObj: TsInterface_UnspecifiedObject, supplier: any, fieldLabel: string, newValue: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      // 1) Build the template object
      const templateObject = generateEmailTemplateObjectForOrderFieldUpdate(orderObj, fieldLabel, newValue)

      // 2) Convert template to final HTML
      const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, {
        order: orderObj, // if your template references dynamic fields
      })

      // 3) Get the supplier location key from the order
      const locationKey = orderObj.associated_supplier_location_key

      // 4) Determine the correct email using the same logic as in sendTestOrderEmail
      const locationEmail = supplier.locations[locationKey]?.location_email || 'default@supplier.com'

      // 5) Send the email
      cloudFunctionManageRequest('manageEmails', {
        function: 'sendSendgridHtmlEmail',
        to: [locationEmail], // now using the dynamically determined email
        cc: [],
        bcc: [],
        subject: `${fieldLabel} Updated for PO ${orderObj?.po_number ?? '???'}`,
        html: htmlContent,
      })
        .then((res_Email) => {
          resolve(res_Email)
        })
        .catch((err_Email) => {
          reject(err_Email)
        })
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * Builds an email template indicating that a specific field (like "Scheduled Date" or "Delivery Notes") was updated.
 */
export const generateEmailTemplateObjectForOrderFieldUpdate = (
  order: TsInterface_UnspecifiedObject,
  fieldLabel: string,
  newValue: string,
): TsInterface_EmailTemplateObject => {
  // Extract relevant fields from the order object
  const poNumber = order?.po_number ?? '???'
  const supplierName = order?.associated_supplier_name ?? '???'
  const supplierLocation = order?.associated_supplier_location_name ?? '???'
  const jobCode = order?.associated_project_id_number ?? order?.associated_project_key ?? '???'
  const taskName = order?.associated_task_name ?? '???'
  const orderKey = order?.key ?? '???'

  // Link to the supplier portal if desired
  const portalLink = `https://etwenergy.com/s/all_orders/etw_energy/${order?.associated_supplier_key ?? '???'}/`

  return {
    BODY_DIV: {
      element_type: 'div',
      style: {
        'box-sizing': 'border-box',
        'width': '100%',
        'background': '#eeeeee',
        'padding': '16px',
      },
      contents: {
        CARD_DIV: {
          element_type: 'div',
          style: {
            'max-width': '600px',
            'background': '#ffffff',
            'border-radius': '8px',
            'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
            'margin': '0 auto',
            'overflow': 'hidden',
          },
          contents: {
            HEADER_DIV: {
              element_type: 'div',
              style: {
                'box-sizing': 'border-box',
                'background': 'rgb(25, 30, 33)',
                'font-size': '20px',
                'width': '100%',
                'min-height': '70px',
                'padding': '8px',
                'text-align': 'center',
              },
              contents: {
                LOGO_IMG: {
                  element_type: 'img',
                  src_type: 'static',
                  src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                  alt: 'logo.png',
                  width: '80px',
                  height: 'auto',
                },
              },
            },
            CONTENT_DIV: {
              element_type: 'div',
              style: {
                padding: '24px',
              },
              contents: {
                TITLE: {
                  element_type: 'h1',
                  text_type: 'static',
                  text: `${fieldLabel} Updated`,
                  style: {
                    'font-size': '20px',
                    'font-weight': 'bold',
                    'margin-bottom': '16px',
                    'color': '#333333',
                  },
                },
                ORDER_INFO: {
                  element_type: 'p',
                  text_type: 'static',
                  text: `
                    <strong>Order Key:</strong> ${orderKey}<br/>
                    <strong>PO Number:</strong> ${poNumber}<br/>
                    <strong>Supplier:</strong> ${supplierName}<br/>
                    <strong>Supplier Location:</strong> ${supplierLocation}<br/>
                    <strong>Job Code:</strong> ${jobCode}<br/>
                    <strong>Task:</strong> ${taskName}<br/><br/>
                    The <strong>${fieldLabel}</strong> for this order has been changed to:
                    <br/><strong>${newValue}</strong>.
                  `,
                  style: {
                    'font-size': '14px',
                    'margin-bottom': '12px',
                    'color': '#333333',
                  },
                },
                PORTAL_LINK: {
                  element_type: 'a',
                  text_type: 'static',
                  text: 'Open Supplier Portal',
                  href_type: 'static',
                  href_url: portalLink,
                  style: {
                    'font-size': '14px',
                    'color': '#000000',
                    'text-decoration': 'underline',
                  },
                },
              },
            },
          },
        },
      },
    },
  } as TsInterface_EmailTemplateObject
}

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseMaterialsOrderViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_associatedProject, us_setAssociatedProject] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_associatedMaterialImports, us_setAssociatedMaterialImports] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedSupplier, us_setAssociatedSupplier] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lineItemSkus, us_setLineItemSkus] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadingGoogleSpreadsheet, us_setLoadingGoogleSpreadsheet] = useState<boolean>(false)
  const [us_regionsList, us_setRegionsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootOrder, us_setRootOrder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_searchIndexKey, us_setSearchIndexKey] = useState<'materials_by_sku' | 'materials'>('materials')
  const [us_searchResults, us_setSearchResults] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_searchValue, us_setSearchValue] = useState<string>('')
  // const [us_supplierLocationOptions, us_setSupplierLocationOptions] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const [localLineItemSkus, setLocalLineItemSkus] = useState(us_lineItemSkus)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [currentOrderKey, setCurrentOrderKey] = useState('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [us_lineItems, us_setLineItems] = useState<any[]>(objectToArray(us_lineItemSkus))

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Order', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionsList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedProject(newData)
      ur_forceRerender()
    }
    if (us_rootOrder != null && us_rootOrder['associated_project_key'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, us_rootOrder['associated_project_key']), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootOrder])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedSupplier(newData)
      let supplierLocationOptions: TsInterface_UnspecifiedObject = {}
      for (let loopSupplierKey in newData['locations']) {
        let loopSupplierLocation = newData['locations'][loopSupplierKey]
        if (loopSupplierLocation != null && loopSupplierLocation['status'] === 'active') {
          supplierLocationOptions[loopSupplierKey] = { key: loopSupplierKey, value: loopSupplierLocation['name'] }
        }
      }
      // us_setSupplierLocationOptions(supplierLocationOptions)
      ur_forceRerender()
    }
    if (us_rootOrder != null && us_rootOrder['associated_supplier_key'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_Supplier_Document(res_GCK.clientKey, us_rootOrder['associated_supplier_key']),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootOrder])

  useEffect(() => {
    // Get Search Results from Meilisearch
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let clientKey = res_GCK.clientKey
        searchIndex(
          clientKey,
          us_searchIndexKey,
          us_searchValue,
          ['associated_supplier_key = ' + getProp(us_rootOrder, 'associated_supplier_key', null), 'status = active'],
          searchLimit,
        )
          .then((res_SI) => {
            let searchResults = getProp(res_SI, 'hits', [])
            // Sort Search Results Alphabetically
            if (us_searchIndexKey === 'materials') {
              searchResults.sort(dynamicSort('name', 'asc'))
            }
            // Split into two arrays - Items in Cart and Items Not in Cart
            let itemsInCart: TsInterface_UnspecifiedObject[] = []
            let itemsNotInCart: TsInterface_UnspecifiedObject[] = []
            for (let loopSkuIndex in searchResults) {
              let loopSkuItem = searchResults[loopSkuIndex]
              if (
                itemKey != null &&
                loopSkuItem != null &&
                loopSkuItem['key'] != null &&
                us_lineItemSkus != null &&
                us_lineItemSkus[itemKey + '_' + loopSkuItem['key']] != null &&
                us_lineItemSkus[itemKey + '_' + loopSkuItem['key']]['associated_sku_pick_requested_quantity'] != null &&
                us_lineItemSkus[itemKey + '_' + loopSkuItem['key']]['associated_sku_pick_requested_quantity'] > 0
              ) {
                itemsInCart.push(loopSkuItem)
              } else {
                itemsNotInCart.push(loopSkuItem)
              }
            }
            // Join the two arrays
            let sortedSearchResults = itemsInCart.concat(itemsNotInCart)
            us_setSearchResults(sortedSearchResults)
          })
          .catch((rej_SI) => {
            us_setSearchResults([])
            console.error(rej_SI)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, us_lineItemSkus, us_rootOrder, us_searchValue, us_searchIndexKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootOrder(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    us_setLineItems(objectToArray(us_lineItemSkus))
  }, [us_lineItemSkus])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLineItemSkus(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SkuLineItemsOnOrder_Query(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_setLineItemSkus])

  useEffect(() => {
    if (us_lineItemSkus != null) {
      setLocalLineItemSkus({ ...us_lineItemSkus })
    }
  }, [us_lineItemSkus])

  // Other Variables
  const searchLimit = 50

  // Functions
  const decrementSkuItemQuantity = (skuItem: TsInterface_UnspecifiedObject, dropdownValue: number | string) => {
    if (!isNaN(parseFloat(dropdownValue as string))) {
      if (parseFloat(dropdownValue as string) > 1) {
        let updateObject = {
          associated_sku_pick_requested_quantity: parseFloat(dropdownValue as string) - 1,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key), updateObject)
              .then((res_DSMD) => {
                if (us_rootOrder?.initial_email_sent_to_supplier === true && us_rootOrder?.edited_order_submitted === true) {
                  const orderUpdateObject = {
                    edited_order_submitted: false,
                    last_updated: Timestamp.now(),
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, us_rootOrder.key), orderUpdateObject)
                    .then(() => {
                      // done
                    })
                    .catch((rej_OrderUpdate) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_OrderUpdate.error })
                    })
                }
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      } else {
        // If quantity is 1, we prompt the user to confirm removal
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Remove from Shopping Cart'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure that you want to remove this item from your shopping cart?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseDeleteDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key))
                      .then((res_DDD) => {
                        resolve(res_DDD)
                      })
                      .catch((rej_DDD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        reject(rej_DDD)
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      }
    }
  }

  // 3) The PDF Document
  const returnPDFDownloadDocument = (order: TsInterface_UnspecifiedObject, skus: TsInterface_UnspecifiedObject): JSX.Element => {
    let addressLineJSX = null
    let deliveryNotesJSX = null

    if (order.delivery_type === 'delivery') {
      // If you have a “project” address or store it on the order, adjust accordingly
      const address = order?.associated_project_address ?? '???'

      addressLineJSX = (
        <View style={pdfStyles.row}>
          <Text style={pdfStyles.pickupDateCell1}>{rLIB('Delivery Location')}:</Text>
          <Text style={pdfStyles.pickupDateCell2}>{address}</Text>
        </View>
      )
      deliveryNotesJSX = (
        <View style={pdfStyles.row}>
          <Text style={pdfStyles.pickupDateCell1}>{rLIB('Delivery Notes')}:</Text>
          <Text style={pdfStyles.pickupDateCell2}>{order.delivery_notes}</Text>
        </View>
      )
    }

    return (
      <Document>
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.table}>
            {/* Example rows: PO, requestor, etc. */}
            <View style={pdfStyles.row}>
              <Text style={pdfStyles.poNumberCell1}>{rLIB('PO Number')}:</Text>
              <Text style={pdfStyles.poNumberCell2}>{order.po_number}</Text>
            </View>
            <View style={pdfStyles.row}>
              <Text style={pdfStyles.requestorCell1}>{rLIB('Requestor')}:</Text>
              <Text style={pdfStyles.requestorCell2}>{order.associated_user_name ?? '???'}</Text>
            </View>
            <View style={pdfStyles.row}>
              <Text style={pdfStyles.pickupDateCell1}>{rLIB('Scheduled Date')}:</Text>
              <Text style={pdfStyles.pickupDateCell2}>{order.timestamp_scheduled_pickup_date_key}</Text>
            </View>
            <View style={pdfStyles.row}>
              <Text style={pdfStyles.pickupDateCell1}>{rLIB('Pickup/Delivery')}:</Text>
              <Text style={pdfStyles.pickupDateCell2}>{capitalizeFirstLetterInString(order.delivery_type ?? 'pickup')}</Text>
            </View>
            {addressLineJSX}
            {deliveryNotesJSX}
          </View>

          {/* Now the SKUs table */}
          <View style={pdfStyles.table}>
            <View style={pdfStyles.row}>
              <Text style={pdfStyles.checkCellHeader}>{'\uf14a'}</Text>
              <Text style={pdfStyles.quantityCellHeader}>{rLIB('Qty')}</Text>
              <Text style={pdfStyles.skuCellHeader}>{rLIB('ID')}</Text>
              <Text style={pdfStyles.nameCellHeader}>{rLIB('Description')}</Text>
            </View>

            {objectToArray(skus)
              .sort(dynamicSort('associated_sku_unique_identifier', 'asc'))
              .map((skuItem, idx) => {
                return (
                  <View
                    key={idx}
                    style={pdfStyles.row}
                  >
                    <Text style={pdfStyles.checkCell}></Text>
                    <Text style={pdfStyles.quantityCell}>
                      {skuItem.associated_sku_pick_requested_quantity * skuItem.associated_sku_pick_unit_quantity_per_package}
                    </Text>
                    <Text style={pdfStyles.skuCell}>
                      <Text>
                        {skuItem.associated_sku_unique_identifier}
                        {'\n'}
                      </Text>
                      <Text style={pdfStyles.opacity_40}>{skuItem.associated_sku_manufacturer_unique_identifier}</Text>
                    </Text>
                    <Text style={pdfStyles.nameCell}>{skuItem.associated_sku_name}</Text>
                  </View>
                )
              })}
          </View>
        </Page>
      </Document>
    )
  }

  const incrementSkuItemQuantity = (skuItem: TsInterface_UnspecifiedObject, dropdownValue: number | string) => {
    // let maxPerCart = getProp(skuItem, 'associated_sku_pick_max_per_cart', 100)
    let maxPerCart = 200
    if (!isNaN(parseFloat(dropdownValue as string)) && parseFloat(dropdownValue as string) < maxPerCart) {
      let updateObject = {
        associated_sku_pick_requested_quantity: parseFloat(dropdownValue as string) + 1,
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key), updateObject)
            .then((res_DSMD) => {
              if (us_rootOrder?.initial_email_sent_to_supplier === true && us_rootOrder?.edited_order_submitted === true) {
                const orderUpdateObject = {
                  edited_order_submitted: false,
                  last_updated: Timestamp.now(),
                }
                DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, us_rootOrder.key), orderUpdateObject)
                  .then(() => {
                    // done
                  })
                  .catch((rej_OrderUpdate) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_OrderUpdate.error })
                  })
              }
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  // const sendOrderFieldUpdateEmail = (
  //   orderObj: TsInterface_UnspecifiedObject,
  //   supplier: typeof us_associatedSupplier, // add supplier as an argument if needed
  //   fieldLabel: string,
  //   newValue: string,
  // ): Promise<any> => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       // 1) Build the template object
  //       const templateObject = generateEmailTemplateObjectForOrderFieldUpdate(orderObj, fieldLabel, newValue)

  //       // 2) Convert template to final HTML
  //       const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, {
  //         order: orderObj, // if your template references dynamic fields
  //       })

  //       // 3) Get the supplier location key from the order
  //       const locationKey = orderObj.associated_supplier_location_key

  //       // 4) Determine the correct email using the same logic as in sendTestOrderEmail
  //       const locationEmail = supplier.locations[locationKey]?.location_email || 'default@supplier.com'

  //       // 5) Send the email
  //       cloudFunctionManageRequest('manageEmails', {
  //         function: 'sendSendgridHtmlEmail',
  //         to: [locationEmail], // now using the dynamically determined email
  //         cc: [],
  //         bcc: [],
  //         subject: `${fieldLabel} Updated for PO ${orderObj?.po_number ?? '???'}`,
  //         html: htmlContent,
  //       })
  //         .then((res_Email) => {
  //           resolve(res_Email)
  //         })
  //         .catch((err_Email) => {
  //           reject(err_Email)
  //         })
  //     } catch (err) {
  //       reject(err)
  //     }
  //   })
  // }

  const sendOrderLockStatusChangeEmail = (
    order: TsInterface_UnspecifiedObject,
    skus: TsInterface_UnspecifiedObject,
    newStatus: 'locked' | 'unlocked',
    supplier: any,
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        // 1) Build template
        const templateObject = generateEmailTemplateObjectForLockStatusChange(order, newStatus)

        // 2) Convert to final HTML
        const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, {
          order,
          skus, // pass the line items for the table
        })

        // 3) Get the supplier location key from the order
        const locationKey = order.associated_supplier_location_key

        // 4) Determine the correct email using the same logic as in sendTestOrderEmail
        const locationEmail = supplier.locations[locationKey]?.location_email || 'default@supplier.com'

        // 3) Decide subject
        const subject = newStatus === 'locked' ? `Order #${order?.po_number ?? '???'} Locked` : `Order #${order?.po_number ?? '???'} Unlocked`

        // 4) Send via an authenticated CF call
        cloudFunctionManageRequest('manageEmails', {
          function: 'sendSendgridHtmlEmail',
          to: [locationEmail],
          cc: [],
          bcc: [],
          subject,
          html: htmlContent,
        })
          .then((res_Email) => {
            resolve(res_Email)
          })
          .catch((err_Email) => {
            reject(err_Email)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  const generateEmailTemplateObjectForLockStatusChange = (
    order: TsInterface_UnspecifiedObject,
    newStatus: 'locked' | 'unlocked',
  ): TsInterface_EmailTemplateObject => {
    // Extract fields from the order
    const poNumber = order?.po_number ?? '???'
    const supplierName = order?.associated_supplier_name ?? '???'
    const projectName = order?.associated_project_id_number ?? order?.associated_project_key ?? '???'

    // Decide text based on locked/unlocked
    const titleText = newStatus === 'locked' ? 'Order Locked' : 'Order Unlocked'
    const bodyText =
      newStatus === 'locked'
        ? `This order has been <strong>locked</strong> and can no longer be edited.`
        : `This order has been <strong>unlocked</strong>, and is now editable again.`

    return {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': '#eeeeee',
          'padding': '16px',
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
            },
            contents: {
              HEADER_DIV: {
                element_type: 'div',
                style: {
                  'box-sizing': 'border-box',
                  'background': 'rgb(25, 30, 33)',
                  'font-size': '20px',
                  'width': '100%',
                  'min-height': '70px',
                  'padding': '8px',
                  'text-align': 'center',
                },
                contents: {
                  LOGO_IMG: {
                    element_type: 'img',
                    src_type: 'static',
                    src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                    alt: 'logo.png',
                    width: '80px',
                    height: 'auto',
                  },
                },
              },
              CONTENT_DIV: {
                element_type: 'div',
                style: {
                  padding: '24px',
                },
                contents: {
                  TITLE: {
                    element_type: 'h1',
                    text_type: 'static',
                    text: titleText,
                    style: {
                      'font-size': '20px',
                      'font-weight': 'bold',
                      'margin-bottom': '16px',
                      'color': '#333333',
                    },
                  },
                  ORDER_INFO: {
                    element_type: 'p',
                    text_type: 'static',
                    text: `
                      <strong>PO Number:</strong> ${poNumber}<br/>
                      <strong>Supplier:</strong> ${supplierName}<br/>
                      <strong>Project:</strong> ${projectName}<br/><br/>
                      ${bodyText}
                    `,
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                      'color': '#333333',
                    },
                  },
                  SKUS_HEADER: {
                    element_type: 'h3',
                    text_type: 'static',
                    text: 'Line Items',
                    style: {
                      'font-size': '16px',
                      'font-weight': 'bold',
                      'margin-bottom': '12px',
                      'color': '#333333',
                    },
                  },
                  SKUS_TABLE: {
                    element_type: 'table',
                    table_type: 'looped_data',
                    table_style: {
                      'width': '100%',
                      'border-collapse': 'collapse',
                    },
                    tr_header_style: {
                      'background': '#f5f5f5',
                      'text-align': 'left',
                    },
                    tr_row_style: {
                      'border-bottom': '1px solid #dddddd',
                    },
                    table_columns: {
                      SKU_NUMBER: {
                        column_header: 'SKU #',
                        data_object_prop_key: 'associated_sku_manufacturer_unique_identifier',
                        th_style: {
                          'padding': '8px',
                          'font-weight': 'bold',
                        },
                        td_style: {
                          padding: '8px',
                        },
                      },
                      SKU_NAME: {
                        column_header: 'SKU Name',
                        data_object_prop_key: 'associated_sku_name',
                        th_style: {
                          'padding': '8px',
                          'font-weight': 'bold',
                        },
                        td_style: {
                          padding: '8px',
                        },
                      },
                      QUANTITY: {
                        column_header: 'Quantity',
                        data_object_prop_key: 'associated_sku_pick_requested_quantity',
                        th_style: {
                          'padding': '8px',
                          'font-weight': 'bold',
                        },
                        td_style: {
                          padding: '8px',
                        },
                      },
                    },
                    looped_data_object_key: 'skus',
                  },
                },
              },
            },
          },
        },
      },
    } as TsInterface_EmailTemplateObject
  }

  const unlockOrder = () => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'warning',
        header: rLIB('Unlock Order'),
        icon: (
          <Icon
            icon="lock-open"
            type="solid"
          />
        ),
        submit_text: rLIB('Unlock Order'),
        text: rLIB('Are you sure that you want to unlock this order?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), { status: 'new' })
                  .then((res_DSMD) => {
                    // e.g.
                    us_rootOrder.status = 'new'
                    return sendOrderLockStatusChangeEmail(us_rootOrder, us_lineItemSkus, 'unlocked', us_associatedSupplier)
                  })
                  .then((res_Email) => {
                    resolve({ success: true })
                  })
                  .catch((err) => reject(err))
              })
              .catch((rej_GCK) => reject(rej_GCK))
          })
        },
      },
    })
  }

  const lockOrder = () => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Lock Order'),
        icon: (
          <Icon
            icon="lock"
            type="solid"
          />
        ),
        submit_text: rLIB('Lock Order'),
        text: rLIB('Are you sure that you want to lock this order?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), { status: 'locked' })
                  .then((res_DSMD) => {
                    // If you keep a local version of the order:
                    us_rootOrder.status = 'locked'
                    // Now chain an email
                    return sendOrderLockStatusChangeEmail(us_rootOrder, us_lineItemSkus, 'locked', us_associatedSupplier)
                  })
                  .then((res_Email) => {
                    resolve({ success: true })
                  })
                  .catch((err) => {
                    reject(err)
                  })
              })
              .catch((rej_GCK) => {
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const updateOrderItem = (skuItem: TsInterface_UnspecifiedObject, quantity: number, showError: boolean) => {
    return new Promise((resolve, reject) => {
      if (us_rootOrder != null && us_rootOrder['key'] != null && skuItem != null && skuItem['key'] != null && skuItem['name'] != null) {
        let lineItemSkuKey = us_rootOrder['key'] + '_' + skuItem['key']

        // Prepare line item update object
        let updateObject: any = {
          associated_order_key: us_rootOrder['key'],
          associated_order_po_number: getProp(us_rootOrder, 'po_number', null),
          associated_project_id_number: getProp(us_rootOrder, 'associated_project_id_number', null),
          associated_project_key: getProp(us_rootOrder, 'associated_project_key', null),
          associated_sku_category_name: getProp(skuItem, 'associated_category_name', null),
          associated_sku_classification_name: getProp(skuItem, 'associated_classification_name', null),
          associated_sku_manufacturer_unique_identifier: getProp(skuItem, 'associated_manufacturer_unique_identifier', null),
          associated_sku_estimated_photo_url: getProp(skuItem, 'photo_url', null),
          associated_sku_key: skuItem['key'],
          associated_sku_name: skuItem['name'],
          associated_sku_pick_base_unit_of_measurement: getProp(skuItem, 'pick_base_unit_of_measurement', null),
          associated_sku_pick_estimated_price_per_package: getProp(skuItem, 'pick_estimated_price_per_package', null),
          associated_sku_pick_max_per_cart: getProp(skuItem, 'pick_max_per_cart', null),
          associated_sku_pick_packaging_type: getProp(skuItem, 'pick_packaging_type', null),
          associated_sku_pick_requested_quantity: quantity,
          associated_sku_pick_unit_quantity_per_package: getProp(skuItem, 'pick_unit_quantity_per_package', null),
          associated_sku_unique_identifier: getProp(skuItem, 'unique_identifier', null),
          associated_supplier_key: getProp(us_rootOrder, 'associated_supplier_key', null),
          associated_supplier_name: getProp(us_rootOrder, 'associated_supplier_name', null),
          associated_task_key: getProp(us_rootOrder, 'associated_task_key', null),
          associated_task_name: getProp(us_rootOrder, 'associated_task_name', null),
          associated_team_key: getProp(us_rootOrder, 'associated_team_key', null),
          associated_team_member_keys: getProp(us_rootOrder, 'associated_team_member_keys', null),
          associated_team_member_names: getProp(us_rootOrder, 'associated_team_member_names', null),
          associated_team_name: getProp(us_rootOrder, 'associated_team_name', null),
          associated_user_key: getProp(us_rootOrder, 'associated_user_key', null),
          associated_user_name: getProp(us_rootOrder, 'associated_user_name', null),
          key: lineItemSkuKey,
          timestamp_scheduled_pickup_date_key: getProp(us_rootOrder, 'timestamp_scheduled_pickup_date_key', null),
        }

        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            // Update SKU line item
            DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, lineItemSkuKey), updateObject)
              .then((res_DSMD) => {
                // Check if the initial email has been sent and update the order document
                if (us_rootOrder.initial_email_sent_to_supplier === true) {
                  const orderUpdateObject = {
                    edited_order_submitted: false,
                    last_updated: Timestamp.now(), // Optional timestamp for tracking edits
                  }

                  DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, us_rootOrder.key), orderUpdateObject)
                    .then(() => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_OrderUpdate) => {
                      console.error('Failed to update order document:', rej_OrderUpdate)
                      reject(rej_OrderUpdate)
                    })
                } else {
                  resolve(res_DSMD) // If no initial email, just resolve after SKU update
                }
              })
              .catch((rej_DSMD) => {
                if (showError === true) {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                }
                reject(rej_DSMD)
              })
          })
          .catch((rej_GCK) => {
            if (showError === true) {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            }
            reject(rej_GCK)
          })
      } else {
        let error = {
          message: rLIB('Failed to update Order Item'),
          details: rLIB('Missing Required Data'),
          code: 'ER-D-OV-UOI-01',
        }
        if (showError === true) {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
        }
        reject({ success: false, error: error })
      }
    })
  }

  const returnEstimatedInvoiceTotal = (): number => {
    let total: number = 0
    for (let loopSkuKey in us_lineItemSkus) {
      let loopSku = us_lineItemSkus[loopSkuKey]
      if (loopSku != null && loopSku['associated_sku_pick_requested_quantity'] != null && loopSku['associated_sku_pick_estimated_price_per_package'] != null) {
        let quantityPerPackageMultiplier = getProp(loopSku, 'associated_sku_pick_unit_quantity_per_package', 1)
        total += loopSku['associated_sku_pick_requested_quantity'] * loopSku['associated_sku_pick_estimated_price_per_package'] * quantityPerPackageMultiplier
      }
    }
    return total
  }

  const MESSY_attemptToLoadDataFromGoogleSpreadsheet = () => {
    us_setLoadingGoogleSpreadsheet(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        cloudFunctionManageRequest('manageData', {
          function: 'return_google_sheets_scraper',
          client_key: res_GCK.clientKey,
          google_sheet_id: '1CjWOfZHuP_rhQYeJ3DcfHTPTbqEqaKIJ3NBJgh8RYfI', // TODO: Not hardcoded
          google_sheet_range: 'Sheet1',
        })
          .then((res_CFMR) => {
            let skusFromSpreadsheet: TsInterface_UnspecifiedObject[] = []
            let spreadsheetData = getProp(getProp(res_CFMR, 'data', {}), 'values', [])
            // Loop through data rows
            for (let loopRowIndex in spreadsheetData) {
              let loopRow = spreadsheetData[loopRowIndex]
              if (loopRow.length > 0 && loopRow[2] != null && loopRow[7] != null && loopRow[8] != null) {
                let jobCode = loopRow[2]
                let skuQuantity = loopRow[7]
                let skuName = loopRow[8]
                if (jobCode != null && jobCode != '' && us_rootOrder != null && us_rootOrder['associated_project_id_number'] === jobCode) {
                  skusFromSpreadsheet.push({
                    job_code: jobCode,
                    sku_quantity: skuQuantity,
                    sku_name: skuName,
                  })
                }
              }
            }
            // Loop through skus and attempt to add to order
            let totalSkusToAdd = skusFromSpreadsheet.length
            let promiseArray1: Promise<unknown>[] = []
            let promiseArray2: Promise<unknown>[] = []
            for (let loopSkuIndex in skusFromSpreadsheet) {
              let loopSku = skusFromSpreadsheet[loopSkuIndex]
              promiseArray1.push(
                DatabaseGetDocument(DatabaseRef_MasterSKU_Document(res_GCK.clientKey, us_associatedSupplier.key + '_' + loopSku.sku_name))
                  .then((res_DGD) => {
                    skusFromSpreadsheet[loopSkuIndex]['associated_sku_item'] = res_DGD.data
                  })
                  .catch((rej_DGD) => {
                    console.error(rej_DGD)
                  }),
              )
              promiseArray1.push(
                DatabaseGetCollection(DatabaseRef_MasterSkuItem_ManufacturerIDAndSupplier_Query(res_GCK.clientKey, loopSku.sku_name, us_associatedSupplier.key))
                  .then((res_DGC) => {
                    skusFromSpreadsheet[loopSkuIndex]['associated_manufacturer_sku_items'] = res_DGC.data
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
            }
            Promise.all(promiseArray1).finally(() => {
              // Loop through and get totals and show confirm dialog
              let totalSkusAdded = 0
              let totalSkusSkipped = 0
              let totalSkusFailed = 0
              for (let loopSkuIndex in skusFromSpreadsheet) {
                let loopSku = skusFromSpreadsheet[loopSkuIndex]
                if (loopSku.associated_sku_item != null && loopSku.associated_sku_item.key != null) {
                  promiseArray2.push(
                    updateOrderItem(loopSku.associated_sku_item, loopSku.sku_quantity, false)
                      .then(() => {
                        totalSkusAdded++
                      })
                      .catch(() => {
                        totalSkusFailed++
                      }),
                  )
                } else if (
                  loopSku.associated_manufacturer_sku_items != null &&
                  objectToArray(loopSku.associated_manufacturer_sku_items).length > 0 &&
                  objectToArray(loopSku.associated_manufacturer_sku_items)[0].key != null
                ) {
                  promiseArray2.push(
                    updateOrderItem(objectToArray(loopSku.associated_manufacturer_sku_items)[0], loopSku.sku_quantity, false)
                      .then(() => {
                        totalSkusAdded++
                      })
                      .catch(() => {
                        totalSkusFailed++
                      }),
                  )
                } else {
                  totalSkusSkipped++
                }
              }
              Promise.all(promiseArray2).finally(() => {
                let textJSX = (
                  <Box>
                    <Box>{us_rootOrder['associated_project_id_number']}</Box>
                    <Box>
                      {totalSkusToAdd > 0 ? (
                        <>
                          {totalSkusToAdd} {rLIB('skus found in google spreadsheet')}
                        </>
                      ) : (
                        <>{rLIB('No skus found in google spreadsheet')}</>
                      )}
                    </Box>
                    <Box>
                      {totalSkusAdded > 0 ? (
                        <>
                          {totalSkusAdded} {rLIB('skus added')}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Box>
                      {totalSkusFailed > 0 ? (
                        <>
                          {totalSkusSkipped} {rLIB('skus failed to be added')}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Box>
                      {totalSkusSkipped > 0 ? (
                        <>
                          {totalSkusSkipped} {rLIB('skus from spreadsheet not found in database')}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                )
                uc_setUserInterface_AlertDialogDisplay({
                  display: true,
                  alert: {
                    color: 'info',
                    header: rLIB('Skus Added from Spreadsheet') as JSX.Element,
                    icon: <Icon icon="wand-magic-sparkles" />,
                    text: textJSX,
                  },
                })
              })

              // Finish with Data Loading
              us_setLoadingGoogleSpreadsheet(false)
            })

            /*
            Found X Skus on spreadsheet
            Found Y Database Matches (Z Not Found)
            Add to Order?
            */
          })
          .catch((rej_CFMR) => {
            console.error(rej_CFMR)
            us_setLoadingGoogleSpreadsheet(false)
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        us_setLoadingGoogleSpreadsheet(false)
      })
  }

  // JSX Generation
  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    // Determine the navigation target based on user role
    let targetUrl = ''
    let buttonLabel: any = rLIB('Back to Orders')

    if (uc_RootData_ClientUser.user_role === 'subcontractor_design') {
      const projectId = getProp(us_rootOrder, 'associated_project_id_number', undefined)
      targetUrl = ApplicationPages.SubKProjectViewPage.url(projectId) + '?tab=materials'
    } else {
      targetUrl = ApplicationPages.AdminActiveProjectViewPage.url(us_associatedProject.key) + '?tab=materials'
    }

    // Render the button based on shrink state
    if (!shrink) {
      return (
        <Button
          color="inherit"
          variant="outlined"
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, targetUrl)
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2"
        >
          {buttonLabel}
        </Button>
      )
    } else {
      return (
        <Tooltip
          title={buttonLabel}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, targetUrl)
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    }
  }

  const rJSX_LoadFromGoogleSpreadsheetButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          onClick={(event) => {
            MESSY_attemptToLoadDataFromGoogleSpreadsheet()
          }}
          disableElevation
          startIcon={
            us_loadingGoogleSpreadsheet ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="file-spreadsheet" />
            )
          }
          className="tw-mr-2"
          disabled={us_loadingGoogleSpreadsheet || us_rootOrder.status !== 'new'}
        >
          {rLIB('Load from google spreadsheet')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Load from google spreadsheet')}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            onClick={(event) => {
              MESSY_attemptToLoadDataFromGoogleSpreadsheet()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
            disabled={us_loadingGoogleSpreadsheet || us_rootOrder.status !== 'new'}
          >
            {us_loadingGoogleSpreadsheet ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="file-spreadsheet" />
            )}
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_UnlockButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="warning"
          variant="contained"
          onClick={(event) => {
            unlockOrder()
          }}
          disableElevation
          startIcon={<Icon icon="unlock" />}
          className="tw-mr-2"
          disabled={us_rootOrder.status !== 'locked'}
        >
          {rLIB('Unlock Order')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Unlock Order')}
          placement="top"
        >
          <Button
            color="warning"
            variant="contained"
            onClick={(event) => {
              unlockOrder()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
            disabled={us_rootOrder.status !== 'locked'}
          >
            <Icon icon="unlock" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_LockButton = (shrink: boolean): JSX.Element => {
    if (!shrink) {
      return (
        <Button
          color="error"
          variant="contained"
          onClick={() => lockOrder()}
          disableElevation
          startIcon={<Icon icon="lock" />}
          className="tw-mr-2"
          disabled={us_rootOrder.status === 'locked'} // Already locked, disable
        >
          {rLIB('Lock Order')}
        </Button>
      )
    } else {
      return (
        <Tooltip
          title={rLIB('Lock Order')}
          placement="top"
        >
          <Button
            color="warning"
            variant="contained"
            onClick={() => lockOrder()}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
            disabled={us_rootOrder.status === 'locked'}
          >
            <Icon icon="lock" />
          </Button>
        </Tooltip>
      )
    }
  }

  const rJSX_PDFDownloadButton = (shrink: boolean, order: TsInterface_UnspecifiedObject, skus: TsInterface_UnspecifiedObject): JSX.Element => {
    return (
      <PDFDownloadLink
        document={returnPDFDownloadDocument(order, skus)}
        fileName={`${order.po_number}.pdf`}
      >
        {({ loading }) => {
          // We'll reuse a helper for the full/min version
          return rJSX_DownloadButtonProper(order, loading, shrink)
        }}
      </PDFDownloadLink>
    )
  }

  const rJSX_DownloadButtonProper = (order: TsInterface_UnspecifiedObject, loading: boolean, shrink: boolean): JSX.Element => {
    if (!shrink) {
      // Full version with text
      return (
        <Button
          color="info"
          variant="contained"
          disabled={false}
          startIcon={
            loading ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="file-pdf" />
            )
          }
          sx={{}}
        >
          {rLIB('Download PDF')}
        </Button>
      )
    } else {
      // Minimal icon version
      return (
        <Tooltip
          title={rLIB('Download PDF')}
          placement="top"
        >
          <Button
            color="error"
            variant="contained"
            disabled={false}
            className="bp_icon_only_button"
            startIcon={
              loading ? (
                <Icon
                  icon="arrows-rotate"
                  className="bp_spin"
                />
              ) : (
                <Icon icon="file-pdf" />
              )
            }
            sx={{ marginLeft: 2 }}
          />
        </Tooltip>
      )
    }
  }

  // Search Tab
  const rJSX_SearchResultsCount = (): JSX.Element => {
    let countJSX = <></>
    if (us_searchValue !== '' && us_searchResults != null && us_searchResults.length >= searchLimit) {
      countJSX = (
        <Typography
          variant="body1"
          className="tw-opacity-40 tw-mb-2"
        >
          {rLIB('More than')} {us_searchResults.length} {rLIB('Search')} {pluralize('Result', us_searchResults.length)}
        </Typography>
      )
    } else if (us_searchValue !== '' && us_searchResults != null && us_searchResults.length > 0) {
      countJSX = (
        <Typography
          variant="body1"
          className="tw-opacity-40 tw-mb-2"
        >
          {us_searchResults.length} {rLIB('Search')} {pluralize('Result', us_searchResults.length)}
        </Typography>
      )
    }
    return countJSX
  }

  const rJSX_SearchBar = (): JSX.Element => {
    let searchBarJSX = (
      <Stack
        direction={'row'}
        spacing={1}
      >
        <Box sx={{ width: 'calc(100% - 0px)' }}>
          <FormControl fullWidth>
            <TextField
              color="primary"
              value={us_searchValue}
              margin="normal"
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  us_setSearchValue(event.target.value)
                  // updateSearchResults( event.target.value )
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="magnifying-glass"
                    className="tw-mr-2"
                  />
                ),
              }}
            />
          </FormControl>
          {rJSX_SearchResultsCount()}
        </Box>
        <Box className="tw-pt-4">
          <ButtonGroup variant="outlined">
            <Tooltip
              title={rLIB('Search by anything')}
              placement="top"
            >
              <Button
                sx={{ height: '56px' }}
                className="tw-align-top tw-text-center"
                color="secondary"
                variant={us_searchIndexKey === 'materials' ? 'contained' : 'outlined'}
                onClick={() => {
                  us_setSearchIndexKey('materials')
                }}
              >
                <Icon
                  icon="magnifying-glass"
                  sx={{ fontSize: '18px' }}
                />
              </Button>
            </Tooltip>
            <Tooltip
              title={rLIB('Search by SKU')}
              placement="top"
            >
              <Button
                sx={{ height: '56px' }}
                className="tw-align-top tw-text-center"
                color="secondary"
                variant={us_searchIndexKey === 'materials_by_sku' ? 'contained' : 'outlined'}
                onClick={() => {
                  us_setSearchIndexKey('materials_by_sku')
                }}
              >
                <Icon
                  icon="barcode"
                  sx={{ fontSize: '18px' }}
                />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Stack>
    )
    return searchBarJSX
  }

  const rJSX_MaterialSearchResultSkuItemImage = (photoUrl: string | null): JSX.Element => {
    let imageJSX = <></>
    if (photoUrl != null) {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: '#FFF' }}
          className="tw-mr-2 tw-mt-2 tw-flex tw-items-center tw-justify-center"
        >
          <img
            className="tw-max-w-full tw-max-h-full tw-self-center"
            src={photoUrl}
            alt={photoUrl}
            loading="lazy"
            width={'80px'}
          />
        </Box>
      )
    } else {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: themeVariables.gray_800 }}
          className="tw-mr-2 tw-mt-2 tw-text-center"
        >
          <Box className="tw-pt-3">
            <Icon
              className="tw-opacity-20"
              sx={{ fontSize: '40px' }}
              icon="circle-question"
            />
          </Box>
          <Typography
            variant="caption"
            className="tw-opacity-20"
          >
            {rLIB('No Image')}
          </Typography>
        </Box>
      )
    }
    return imageJSX
  }

  const rJSX_MaterialSearchResultOrderQuantityDropdown = (skuItem: TsInterface_UnspecifiedObject, includedInOrder: boolean) => {
    let dropdownJSX = <></>
    // Determine Order Quantity Dropdown Options
    // let maxPerCart = getProp( skuItem, "pick_max_per_cart", 100 )
    let maxPerCart = 200
    let possibleOrderQuantities: TsInterface_UnspecifiedObject[] = []
    for (let i = 1; i <= maxPerCart; i++) {
      possibleOrderQuantities.push({
        key: i,
        value: i,
      })
    }
    // Dropdown Value
    let dropdownValue = ''
    if (
      itemKey != null &&
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] != null
    ) {
      dropdownValue = us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity']
    }
    // JSX
    dropdownJSX = (
      <Stack
        direction="row"
        className="tw-justify-between"
      >
        <Box
          className="tw-inline-block tw-mr-2"
          sx={{ width: 'calc( 100% - 160px )' }}
        >
          {/* <Icon
						icon="circle-minus"
						className="tw-mr-2 tw-align-top tw-mt-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100"
						sx={{ fontSize: "20px" }}
						onClick={ () => {
							decrementSkuItemQuantity( skuItem, dropdownValue )
						}}
					/> */}
          <FormControl>
            <Select
              className="bp_thin_select_input"
              color="primary"
              value={dropdownValue}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateOrderItem(skuItem, event.target.value, true)
                }
              }}
              variant="outlined"
              notched={true}
            >
              {possibleOrderQuantities.map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['value']}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Icon
						icon="circle-plus"
						className="tw-ml-2 tw-align-top tw-mt-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100"
						sx={{ fontSize: "20px" }}
						onClick={ () => {
							incrementSkuItemQuantity( skuItem, dropdownValue )
						}}
					/> */}
          {rJSX_SearchResult_PackagingUnitQuantityText(skuItem, parseFloat(dropdownValue), dropdownValue)}
        </Box>
        <Box className="tw-inline-block">
          {includedInOrder === true ? (
            <Typography
              variant="body1"
              className="tw-inline-block tw-align-top tw-mt-1.5"
              sx={{ color: themeVariables.success_main }}
            >
              <Icon
                icon="cart-shopping"
                className="tw-mr-2"
              ></Icon>
              {rLIB('In Shopping Cart')}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    )
    return dropdownJSX
  }

  const rJSX_SearchEstimatedPrice = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let estimatedPriceJSX = <></>
    if (
      skuItem != null &&
      skuItem['pick_estimated_price_per_package'] != null &&
      itemKey != null &&
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] > 0
    ) {
      let quantityPerPackageMultiplier = getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)
      estimatedPriceJSX = (
        <Typography
          variant="body1"
          className=""
        >
          {formatCurrency(
            quantityPerPackageMultiplier *
              skuItem['pick_estimated_price_per_package'] *
              us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'],
          )}
        </Typography>
      )
    }
    return estimatedPriceJSX
  }

  const rJSX_SearchResult_PackagingUnitQuantityText = (
    skuItem: TsInterface_UnspecifiedObject,
    quantityValue: number,
    dropdownValue: number | string,
  ): JSX.Element => {
    let textJSX = <></>

    if (isNaN(quantityValue)) {
      quantityValue = 1
    }
    let totalTextJSX = <></>
    if (dropdownValue !== '') {
      totalTextJSX = (
        <Box className="tw-inline-block tw-opacity-30 tw-ml-2">
          ({getProp(skuItem, 'pick_unit_quantity_per_package', 1) * quantityValue} {rLIB('total')})
        </Box>
      )
    }
    // quantityValue *= quantityPerPackageMultiplier
    if (skuItem.pick_packaging_type != null && skuItem.pick_packaging_type !== 'ea') {
      if (skuItem.pick_base_unit_of_measurement === 'ft') {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {getProp(skuItem, 'pick_unit_quantity_per_package', 1)} {rLIB('ft')} {pluralize(skuItem['pick_packaging_type'].toString(), quantityValue)}
            {totalTextJSX}
          </Box>
        )
      } else {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {pluralize(skuItem['pick_packaging_type'].toString(), quantityValue)} {rLIB('of')} {getProp(skuItem, 'pick_unit_quantity_per_package', 1)}
            {totalTextJSX}
          </Box>
        )
      }
    } else {
      if (skuItem.pick_base_unit_of_measurement === 'ft') {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {rLIB('ft')}
            {totalTextJSX}
          </Box>
        )
      } else {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {totalTextJSX}
          </Box>
        )
      }
    }
    return textJSX
  }

  const rJSX_SearchResultSkuItem = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let includedInOrder = false
    if (
      itemKey != null &&
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] > 0
    ) {
      includedInOrder = true
    }
    // JSX
    let quantityPerPackageMultiplier = getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)
    let skuItemJSX = (
      <Card
        sx={{
          border: includedInOrder === true ? '2px solid ' + themeVariables.success_main : '2px solid ' + themeVariables.gray_800,
          borderRadius: '8px',
        }}
        className="tw-px-3 tw-pt-1 tw-pb-3 tw-my-2"
      >
        <Stack direction="row">
          {rJSX_MaterialSearchResultSkuItemImage(getProp(skuItem, 'photo_url', null))}
          <Box sx={{ width: 'calc( 100% - 88px )' }}>
            <Stack
              direction="row"
              className="tw-justify-between"
            >
              <Box sx={{ width: 'calc( 100% - 100px )' }}>
                <Box>
                  <Typography
                    variant="body1"
                    className="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis"
                  >
                    {getProp(skuItem, 'name', '')}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-40"
                >
                  {getProp(skuItem, 'unique_identifier', '')}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-opacity-40"
                >
                  {getProp(skuItem, 'associated_manufacturer_unique_identifier', '')}
                </Typography>
              </Box>
              <Box
                sx={{ width: '100px' }}
                className="tw-text-right"
              >
                {rJSX_SearchEstimatedPrice(skuItem)}
                <Typography
                  variant="body1"
                  className="tw-opacity-40"
                >
                  {getProp(skuItem, 'pick_estimated_price_per_package', null) != null
                    ? formatCurrency(quantityPerPackageMultiplier * getProp(skuItem, 'pick_estimated_price_per_package', null))
                    : '$UNK'}{' '}
                  /{getProp(skuItem, 'pick_base_unit_of_measurement', rLIB('ea'))}
                </Typography>
              </Box>
            </Stack>
            <Box className="tw-pt-1">{rJSX_MaterialSearchResultOrderQuantityDropdown(skuItem, includedInOrder)}</Box>
          </Box>
        </Stack>
      </Card>
    )
    return skuItemJSX
  }

  const rJSX_SearchResultContainer = (): JSX.Element => {
    let containerJSX = <></>
    if (us_searchValue.length > 0) {
      containerJSX = (
        <Box>
          {us_searchResults.map((skuItem: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_SearchResultSkuItem(skuItem)}</Box>
          ))}
        </Box>
      )
    }
    return containerJSX
  }

  const rJSX_SearchTabContent = (): JSX.Element => {
    let tabContentJSX = (
      <Box>
        <Box
          className="tw-m-auto"
          sx={{ maxWidth: '800px' }}
        >
          {rJSX_SearchBar()}

          {rJSX_SearchResultContainer()}
        </Box>
      </Box>
    )
    return tabContentJSX
  }

  // Shopping Cart Tab
  const rJSX_DeleteFromShoppingCartButton = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="outlined"
        startIcon={<Icon icon="trash" />}
        className="tw-align-top"
        disabled={us_rootOrder.status !== 'new'}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Remove from Shopping Cart'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to remove this item from your shopping cart?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseDeleteDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key))
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }}
      >
        {rLIB('Delete')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ShoppingCartOrderQuantityDropdown = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    // Determine Order Quantity Dropdown Options
    let possibleOrderQuantities: TsInterface_UnspecifiedObject[] = []
    // let maxPerCart = getProp( skuItem, "associated_sku_pick_max_per_cart", 100 )
    let maxPerCart = 200
    for (let i = 1; i <= maxPerCart; i++) {
      possibleOrderQuantities.push({
        key: i,
        value: i,
      })
    }
    // Dropdown Value
    let dropdownValue = ''
    if (
      itemKey != null &&
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[skuItem['key']] != null &&
      us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity'] != null
    ) {
      dropdownValue = us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity']
    }
    let dropdownJSX = <></>
    dropdownJSX = (
      <Box className="tw-inline-block">
        <Icon
          icon="circle-minus"
          className="tw-mr-2 tw-align-top tw-mt-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100"
          sx={{ fontSize: '20px' }}
          onClick={() => {
            decrementSkuItemQuantity(skuItem, dropdownValue)
          }}
        />
        <FormControl>
          <Select
            className="bp_thin_select_input"
            color="primary"
            value={dropdownValue}
            disabled={us_rootOrder.status !== 'new'}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                let updateObject = {
                  associated_sku_pick_requested_quantity: event.target.value,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key), updateObject)
                      .then((res_DSMD) => {
                        if (us_rootOrder?.initial_email_sent_to_supplier === true && us_rootOrder?.edited_order_submitted === true) {
                          const orderUpdateObject = {
                            edited_order_submitted: false,
                            last_updated: Timestamp.now(),
                          }
                          DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, us_rootOrder.key), orderUpdateObject)
                            .then(() => {
                              // done
                            })
                            .catch((rej_OrderUpdate) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_OrderUpdate.error })
                            })
                        }
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              }
            }}
            variant="outlined"
            notched={true}
          >
            {possibleOrderQuantities.map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Icon
          icon="circle-plus"
          className="tw-ml-2 tw-align-top tw-mt-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100"
          sx={{ fontSize: '20px' }}
          onClick={() => {
            incrementSkuItemQuantity(skuItem, dropdownValue)
          }}
        />
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_ShoppingCart_PackagingUnitQuantityText = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let textJSX = <></>
    let quantityValue = 1
    if (
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[skuItem['key']] != null &&
      us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity'] != null &&
      !isNaN(parseFloat(us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity']))
    ) {
      quantityValue = parseFloat(us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity'])
    }
    let totalTextJSX = <></>
    totalTextJSX = (
      <Box className="tw-inline-block tw-opacity-30 tw-ml-2">
        ({getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1) * quantityValue} {rLIB('total')})
      </Box>
    )
    if (skuItem.associated_sku_pick_packaging_type != null && skuItem.associated_sku_pick_packaging_type !== 'ea') {
      if (skuItem.associated_sku_pick_base_unit_of_measurement === 'ft') {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)} {rLIB('ft')}{' '}
            {pluralize(skuItem['associated_sku_pick_packaging_type'].toString(), quantityValue)}
            {totalTextJSX}
          </Box>
        )
      } else {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {typeof skuItem['associated_sku_pick_packaging_type'] === 'number'
              ? skuItem['associated_sku_pick_packaging_type'].toString() // Just display the number as a string
              : pluralize(
                  typeof skuItem['associated_sku_pick_packaging_type'] === 'string'
                    ? skuItem['associated_sku_pick_packaging_type'] // Use string as-is
                    : 'item', // Fallback for undefined/null
                  quantityValue,
                ).toString()}{' '}
            {rLIB('of')} {getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)}
            {totalTextJSX}
          </Box>
        )
      }
    } else {
      if (skuItem.associated_sku_pick_base_unit_of_measurement === 'ft') {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {rLIB('ft')}
            {totalTextJSX}
          </Box>
        )
      } else {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {totalTextJSX}
          </Box>
        )
      }
    }
    return textJSX
  }

  const rJSX_ShoppingCartOrderQuantityDropdownRow = (skuItem: TsInterface_UnspecifiedObject, includedInOrder: boolean): JSX.Element => {
    let dropdownRowJSX = <></>
    // JSX
    dropdownRowJSX = (
      <Stack
        className="tw-justify-between"
        direction="row"
      >
        <Box
          className="tw-inline-block tw-mr-2"
          sx={{ width: 'calc( 100% - 110px )' }}
        >
          {rJSX_ShoppingCartOrderQuantityDropdown(skuItem)}
          {rJSX_ShoppingCart_PackagingUnitQuantityText(skuItem)}
          {rJSX_ShoppingCartSupplierStatus(skuItem)}
        </Box>
        {rJSX_DeleteFromShoppingCartButton(skuItem)}
      </Stack>
    )
    return dropdownRowJSX
  }

  const rJSX_ShoppingCartSupplierStatus = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let supplierStatusJSX = <></>
    switch (getProp(skuItem, 'associated_supplier_inventory_status', null)) {
      case 'in_stock':
        supplierStatusJSX = (
          <Box className="tw-inline-block tw-mt-0.5 tw-align-top">
            <Chip
              className="tw-ml-2"
              color="success"
              variant="outlined"
              // sx={ chipSX }
              label={
                <Box>
                  <Icon
                    icon="badge-check"
                    className="tw-mr-1.5"
                    sx={{ fontSize: '16px' }}
                  />
                  <Typography
                    variant="body1"
                    className="tw-inline-block"
                  >
                    {rLIB('In Stock')}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )
        break
      case 'on_order':
        supplierStatusJSX = (
          <Box className="tw-inline-block tw-mt-0.5 tw-align-top">
            <Chip
              className="tw-ml-2"
              color="warning"
              variant="outlined"
              // sx={ chipSX }
              label={
                <Box>
                  <Icon
                    icon="clock"
                    className="tw-mr-1.5"
                    sx={{ fontSize: '16px' }}
                  />
                  <Typography
                    variant="body1"
                    className="tw-inline-block"
                  >
                    {rLIB('On Order')}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )
        break
      case 'out_of_stock':
        supplierStatusJSX = (
          <Box className="tw-inline-block tw-mt-0.5 tw-align-top">
            <Chip
              className="tw-ml-2"
              color="error"
              variant="outlined"
              // sx={ chipSX }
              label={
                <Box>
                  <Icon
                    icon="triangle-exclamation"
                    className="tw-mr-1.5"
                    sx={{ fontSize: '16px' }}
                  />
                  <Typography
                    variant="body1"
                    className="tw-inline-block"
                  >
                    {rLIB('Out of Stock')}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )
        break
      default:
        // NOTHING
        break
    }
    return supplierStatusJSX
  }

  const rJSX_ShoppingCartSkuItem = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let includedInOrder = true
    // JSX
    let quantityPerPackageMultiplier = getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)
    let skuItemJSX = (
      <Card
        sx={{
          border: '2px solid ' + themeVariables.gray_800,
          borderRadius: '8px',
        }}
        className="tw-px-3 tw-pt-1 tw-pb-3 tw-my-2"
      >
        <Stack direction="row">
          {rJSX_MaterialSearchResultSkuItemImage(getProp(skuItem, 'associated_sku_estimated_photo_url', null))}
          <Box sx={{ width: 'calc( 100% - 88px )' }}>
            <Stack
              direction="row"
              className="tw-justify-between"
            >
              <Box sx={{ width: 'calc( 100% - 100px )' }}>
                <Box>
                  <Typography
                    variant="body1"
                    className="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis"
                  >
                    {getProp(skuItem, 'associated_sku_name', '')}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-40"
                >
                  {getProp(skuItem, 'associated_sku_unique_identifier', '')}
                </Typography>
              </Box>
              <Box
                sx={{ width: '100px' }}
                className="tw-text-right"
              >
                <Typography
                  variant="body1"
                  className=" "
                >
                  {getProp(skuItem, 'associated_sku_pick_estimated_price_per_package', null) != null
                    ? formatCurrency(
                        quantityPerPackageMultiplier *
                          getProp(skuItem, 'associated_sku_pick_estimated_price_per_package', null) *
                          getProp(skuItem, 'associated_sku_pick_requested_quantity', 1),
                      )
                    : '$UNK'}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-opacity-40 "
                >
                  {getProp(skuItem, 'associated_sku_pick_estimated_price_per_package', null) != null
                    ? formatCurrency(getProp(skuItem, 'associated_sku_pick_estimated_price_per_package', null))
                    : '$UNK'}{' '}
                  /{getProp(skuItem, 'associated_sku_pick_base_unit_of_measurement', rLIB('ea'))}
                </Typography>
              </Box>
            </Stack>
            <Box className="tw-pt-1">{rJSX_ShoppingCartOrderQuantityDropdownRow(skuItem, includedInOrder)}</Box>
          </Box>
        </Stack>
      </Card>
    )
    return skuItemJSX
  }

  // (Inside your component or file)
  const rJSX_EditDateIcon = (): JSX.Element => {
    const iconJSX = (
      <Icon
        icon="pen-to-square"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: us_rootOrder,
                formInputs: {
                  timestamp_scheduled_pickup_date_key: {
                    data_type: 'string',
                    input_type: 'timestamp_date',
                    key: 'timestamp_scheduled_pickup_date_key',
                    label: rLIB('Scheduled Pickup/Delivery Date'),
                    required: true,
                  },
                },
                formOnChange: () => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        // The new date from the form
                        const newDateRaw = formSubmittedData.timestamp_scheduled_pickup_date_key || null

                        // Build the object to store in Firestore
                        const updatedData: any = {
                          timestamp_scheduled_pickup_date_key: newDateRaw,
                          scheduled_status: newDateRaw ? 'scheduled' : 'unscheduled',
                        }

                        // 1) Update Firestore
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), updatedData)
                          .then(() => {
                            // 2) If a new date is set, call our helper
                            if (newDateRaw) {
                              // fieldLabel = "Scheduled Date"
                              return sendOrderFieldUpdateEmail(us_rootOrder, us_associatedSupplier, 'Scheduled Date', newDateRaw)
                            } else {
                              return Promise.resolve({ success: true })
                            }
                          })
                          .then((res_Email) => {
                            // Done updating + optional email
                            resolve({ success: true })
                          })
                          .catch((err_Email) => {
                            console.error('Error updating or emailing scheduled date:', err_Email)
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({
                              display: true,
                              error: err_Email?.error ?? {
                                message: rLIB('Failed to update scheduled date or send email'),
                              },
                            })
                            reject(err_Email)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error('Error getting client key:', rej_GCK)
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: rej_GCK?.error,
                        })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Scheduled Pickup/Delivery Date'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
      />
    )

    // If the order isn’t “new,” hide the icon
    if (us_rootOrder.status !== 'new') {
      return <></>
    }

    return iconJSX
  }

  // const rJSX_EditSupplierLocationIcon = (): JSX.Element => {
  //   let iconJSX = (
  //     <Icon
  //       icon="pen-to-square"
  //       onClick={() => {
  //         uc_setUserInterface_FormDialogDisplay({
  //           display: true,
  //           form: {
  //             form: {
  //               formAdditionalData: {},
  //               formData: us_rootOrder,
  //               formInputs: {
  //                 associated_supplier_location_key: {
  //                   key: 'associated_supplier_location_key',
  //                   label: rLIB('Supplier Location'),
  //                   input_type: 'multiple_choice_radio',
  //                   required: true,
  //                   data_type: 'string',
  //                   options: objectToArray(us_supplierLocationOptions),
  //                 },
  //               },
  //               formOnChange: (
  //                 formAdditionalData: TsInterface_FormAdditionalData,
  //                 formData: TsInterface_FormData,
  //                 formInputs: TsInterface_FormInputs,
  //                 formSettings: TsInterface_FormSettings,
  //               ) => {},
  //               formSettings: {},
  //               formSubmission: (
  //                 formSubmittedData: TsInterface_FormSubmittedData,
  //                 formAdditionalData: TsInterface_FormAdditionalData,
  //                 formHooks: TsInterface_FormHooksObject,
  //               ) => {
  //                 return new Promise((resolve, reject) => {
  //                   getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //                     .then((res_GCK) => {
  //                       let updateObject = {
  //                         associated_supplier_location_key: formSubmittedData.associated_supplier_location_key,
  //                         associated_supplier_location_name: getProp(
  //                           getProp(us_supplierLocationOptions, formSubmittedData.associated_supplier_location_key, {}),
  //                           'value',
  //                           null,
  //                         ),
  //                       }
  //                       DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), updateObject)
  //                         .then((res_DSMD) => {
  //                           resolve(res_DSMD)
  //                         })
  //                         .catch((rej_DSMD) => {
  //                           formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
  //                           reject(rej_DSMD)
  //                         })
  //                     })
  //                     .catch((rej_GCK) => {
  //                       formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
  //                       reject(rej_GCK)
  //                     })
  //                 })
  //               },
  //             },
  //             dialog: {
  //               formDialogHeaderColor: 'success',
  //               formDialogHeaderText: rLIB('Scheduled Pickup/Delivery Date'),
  //               formDialogIcon: (
  //                 <Icon
  //                   type="solid"
  //                   icon="pen-to-square"
  //                 />
  //               ),
  //             },
  //           },
  //         })
  //       }}
  //       className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
  //       tooltip={rLIB('Edit')}
  //       tooltipPlacement="right"
  //     />
  //   )
  //   if (us_rootOrder.status !== 'new') {
  //     iconJSX = <></>
  //   }
  //   return iconJSX
  // }

  const rJSX_EditDeliveryTypeIcon = (): JSX.Element => {
    let iconJSX = (
      <Icon
        icon="pen-to-square"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: us_rootOrder,
                formInputs: {
                  delivery_type: {
                    key: 'delivery_type',
                    label: rLIB('Delivery Type'),
                    input_type: 'multiple_choice_radio',
                    submit_on_change: true,
                    required: true,
                    data_type: 'string',
                    options: [
                      { key: 'pickup', value: rLIB('Pickup') },
                      { key: 'delivery', value: rLIB('Delivery') },
                    ],
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {
                  submit_button_hide: true,
                },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), formSubmittedData)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Scheduled Pickup/Delivery Date'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
      />
    )
    if (us_rootOrder.status !== 'new') {
      iconJSX = <></>
    }
    return iconJSX
  }

  const rJSX_EditShippingNotesIcon = (): JSX.Element => {
    let iconJSX = (
      <Icon
        icon="pen-to-square"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: us_rootOrder,
                formInputs: {
                  shipping_notes: {
                    key: 'shipping_notes',
                    label: rLIB('Shipping Notes'),
                    input_type: 'text_multiline',
                    required: true,
                    data_type: 'string',
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {
                  // submit_button_hide: true,
                },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), formSubmittedData)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Shipping Notes'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
      />
    )
    if (us_rootOrder.status !== 'new') {
      iconJSX = <></>
    }
    return iconJSX
  }

  const rJSX_EditDeliveryNotesIcon = (): JSX.Element => {
    let iconJSX = (
      <Icon
        icon="pen-to-square"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: us_rootOrder,
                formInputs: {
                  delivery_notes: {
                    key: 'delivery_notes',
                    label: rLIB('Delivery Notes'),
                    input_type: 'text_multiline',
                    required: true,
                    data_type: 'string',
                  },
                },
                formOnChange: () => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        // The new notes from the form
                        const newNotes = formSubmittedData.delivery_notes || ''

                        // 1) Update Firestore with new notes
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), { delivery_notes: newNotes })
                          .then(() => {
                            // 2) If we have notes, call our helper
                            // fieldLabel = "Delivery Notes"
                            if (newNotes) {
                              return sendOrderFieldUpdateEmail(us_rootOrder, us_associatedSupplier, 'Delivery Notes', newNotes)
                            } else {
                              return Promise.resolve({ success: true })
                            }
                          })
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({
                              display: true,
                              error: rej_DSMD.error,
                            })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: rej_GCK.error,
                        })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Delivery Notes'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
      />
    )

    if (us_rootOrder.status !== 'new') {
      iconJSX = <></>
    }

    return iconJSX
  }

  const rJSX_RegionWarningBanner = (): JSX.Element => {
    let bannerJSX = <></>
    if (
      us_associatedProject != null &&
      us_associatedProject.associated_region_key != null &&
      us_rootOrder != null &&
      us_rootOrder.associated_supplier_location_key != null &&
      us_associatedSupplier != null &&
      us_associatedSupplier.locations != null &&
      us_associatedSupplier.locations[us_rootOrder.associated_supplier_location_key] != null &&
      us_associatedSupplier.locations[us_rootOrder.associated_supplier_location_key].associated_region_key != null &&
      us_associatedProject.associated_region_key !== us_associatedSupplier.locations[us_rootOrder.associated_supplier_location_key].associated_region_key
    ) {
      bannerJSX = (
        <Box
          className="tw-rounded tw-p-2"
          sx={{ background: themeVariables.warning_main }}
        >
          <Box>
            {rLIB('Region assigned to supplier location')}:{' '}
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.error_main, fontWeight: 700 }}
            >
              {getProp(
                getProp(us_regionsList, us_associatedSupplier.locations[us_rootOrder.associated_supplier_location_key].associated_region_key, {}),
                'name',
                '',
              )}
            </Box>
          </Box>
          <Box>
            {rLIB('Region assigned to project')}:{' '}
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.error_main, fontWeight: 700 }}
            >
              {getProp(getProp(us_regionsList, us_associatedProject.associated_region_key, {}), 'name', '')}
            </Box>
          </Box>
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_ShoppingCartTabContent = (): JSX.Element => {
    const handleDialogOpen = () => {
      const orderKey = getProp(us_rootOrder, 'key', '')
      setCurrentOrderKey(orderKey)
      setIsDialogOpen(true)
    }

    const handleDialogClose = () => {
      if (!isSubmitting) {
        setIsDialogOpen(false)
      }
    }

    const handleConfirmSubmit = () => {
      setIsSubmitting(true)

      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          const skus = objectToArray(localLineItemSkus).map((skuItem) => ({
            associated_sku_name: skuItem.associated_sku_name,
            associated_sku_pick_requested_quantity: skuItem.associated_sku_pick_requested_quantity,
            associated_sku_pick_estimated_price_per_package: skuItem.associated_sku_pick_estimated_price_per_package,
            associated_sku_estimated_photo_url: skuItem.associated_sku_estimated_photo_url
              ? `<img src="${skuItem.associated_sku_estimated_photo_url}" alt="SKU Image" style="max-width: 100px; max-height: 100px;" />`
              : 'No Image Available',
          }))

          return sendTestOrderEmail(currentOrderKey, us_rootOrder, us_associatedSupplier, skus)
            .then(() => {
              let batchUpdatesArray: TsInterface_DatabaseBatchUpdatesArray = [
                {
                  type: 'setMerge',
                  ref: DatabaseRef_Order_Document(res_GCK.clientKey as string, currentOrderKey),
                  data: {
                    initial_email_sent_to_supplier: true,
                    email_sent_to_supplier_timestamp: Timestamp.now(),
                    edited_order_submitted: true, // Reset edited flag
                  },
                },
              ]

              return DatabaseStagedBatchUpdate(batchUpdatesArray)
            })
            .then((res_DSBU: any) => {
              if (res_DSBU.success) {
                setIsDialogOpen(false) // Close the dialog after successful submission
              }
            })
        })
        .catch((error) => {
          console.error('Submission Error:', error)

          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: error.message || 'Failed to submit the order. Please try again.',
          })
        })
        .finally(() => {
          setIsSubmitting(false) // Stop loading and re-enable UI immediately
        })
    }

    // Prepare the email sent timestamp for display
    const emailSentTimestamp = getProp(us_rootOrder, 'email_sent_to_supplier_timestamp', null)
    const formattedTimestamp = emailSentTimestamp
      ? (() => {
          const date = new Date(emailSentTimestamp.seconds * 1000)
          const day = date.getDate()
          const month = date.toLocaleString(undefined, { month: 'short' }) // e.g., 'Nov'
          const year = date.getFullYear()
          const hours = date.getHours() % 12 || 12 // Converts to 12-hour format without leading zero
          const minutes = String(date.getMinutes()).padStart(2, '0') // Ensures two digits
          const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
          return `${day} ${month} ${year}, ${hours}:${minutes} ${ampm}`
        })()
      : ''

    let tabContentJSX = (
      <Box
        className="tw-m-auto"
        sx={{ maxWidth: '800px' }}
      >
        <Card className="tw-p-4">
          {/* PDF Download + PO */}

          {/* Existing Content */}
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Order')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'po_number', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Supplier')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_supplier_name', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Supplier Location')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_supplier_location_name', '')}</Box>
            {/* {rJSX_EditSupplierLocationIcon()} */}
          </Typography>
          {rJSX_RegionWarningBanner()}
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Project')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_project_id_number', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Task')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_task_name', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Scheduled Pickup/Delivery Date')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'timestamp_scheduled_pickup_date_key', '')}</Box>
            {rJSX_EditDateIcon()}
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Delivery Type')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{capitalize(getProp(us_rootOrder, 'delivery_type', ''))}</Box>
            {rJSX_EditDeliveryTypeIcon()}
          </Typography>
          {getProp(us_rootOrder, 'delivery_type', '') === 'delivery' ? (
            <Typography variant="h6">
              <Box className="tw-inline-block tw-mr-1">{rLIB('Delivery Notes')}:</Box>
              <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'delivery_notes', '')}</Box>
              {rJSX_EditDeliveryNotesIcon()}
            </Typography>
          ) : (
            <></>
          )}

          {getProp(us_rootOrder, 'delivery_type', '') === 'delivery' ? (
            <Typography variant="h6">
              <Box className="tw-inline-block tw-mr-1">{rLIB('Shipping Notes')}:</Box>
              <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'shipping_notes', '')}</Box>
              {rJSX_EditShippingNotesIcon()}
            </Typography>
          ) : (
            <></>
          )}
          <Divider className="tw-my-2" />
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Estimated Total')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{formatCurrency(returnEstimatedInvoiceTotal())}</Box>
          </Typography>
        </Card>
        {us_rootOrder?.initial_email_sent_to_supplier && us_rootOrder?.edited_order_submitted === false && (
          <Box
            sx={{
              backgroundColor: themeVariables.warning_main + '20', // Light yellow (20% opacity)
              borderLeft: `4px solid ${themeVariables.warning_main}`, // Strong yellow border
              color: themeVariables.warning_main, // Text color
              padding: '12px',
              marginBottom: '16px',
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 600 }}
            >
              This order has been edited since the last email was sent to the supplier.
            </Typography>
            <Typography variant="body2">
              When you're done editing, click <strong>Submit Order</strong> to send the updated order to the supplier.
            </Typography>
          </Box>
        )}

        <Box>
          {objectToArray(localLineItemSkus) // Use localLineItemSkus instead of us_lineItemSkus
            .filter((skuItem) => skuItem.associated_sku_pick_requested_quantity > 0 && !skuItem.markedForDeletion) // Exclude items marked for deletion
            .sort(dynamicSort('name', 'asc'))
            .map((skuItem: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>{rJSX_ShoppingCartSkuItem(skuItem)}</Box>
            ))}
        </Box>

        <Box>
          {/* Show warning if the cart is empty */}
          {objectToArray(localLineItemSkus).filter((sku) => sku.associated_sku_pick_requested_quantity > 0 && !sku.markedForDeletion).length === 0 && (
            <Box
              sx={{
                backgroundColor: themeVariables.warning_main + '20', // Light yellow with transparency
                borderLeft: `4px solid ${themeVariables.warning_main}`, // Yellow border for emphasis
                color: themeVariables.warning_main,
                padding: '12px',
                marginBottom: '12px',
                borderRadius: '4px',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 600 }}
              >
                Your cart is empty.
              </Typography>
              <Typography variant="body2">
                Use the <strong>Import BOM</strong> button to import a BOM, or go to the <strong>Search</strong> tab to add items.
              </Typography>
            </Box>
          )}

          <div className="tw-flex tw-items-center tw-space-x-4">
            <Button
              className="tw-mt-2"
              color="primary"
              variant="contained"
              disabled={
                (!!getProp(us_rootOrder, 'initial_email_sent_to_supplier', false) &&
                  (!('edited_order_submitted' in us_rootOrder) || !!getProp(us_rootOrder, 'edited_order_submitted', false))) ||
                objectToArray(localLineItemSkus).filter((sku) => sku.associated_sku_pick_requested_quantity > 0 && !sku.markedForDeletion).length === 0 ||
                isSubmitting // Disable if submitting
              }
              onClick={handleDialogOpen} // Open the confirmation dialog
            >
              Send Order to Supplier
            </Button>

            {/* Display timestamp if email was sent */}
            {getProp(us_rootOrder, 'initial_email_sent_to_supplier', false) && (
              <Typography
                variant="body2"
                className="tw-text-gray-600 tw-opacity-30" // Added opacity class
              >
                Email sent to supplier on: {formattedTimestamp}
              </Typography>
            )}
          </div>
        </Box>

        {/* 3. **Update the Confirmation Dialog** */}
        <Dialog
          open={isDialogOpen}
          onClose={(event, reason) => {
            if (isSubmitting && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
              return // Prevent closing while submitting
            }
            handleDialogClose()
          }}
          disableEscapeKeyDown={isSubmitting}
        >
          <DialogTitle>Confirm Submission</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to submit this order? An email will be sent to the supplier with the order details.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              color="secondary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmSubmit}
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Confirm'}
            </Button>
          </DialogActions>

          {/* Invisible overlay to block interactions without changing appearance */}
          {isSubmitting && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'transparent', // No white background
                zIndex: 1301, // Ensure it's above everything
                pointerEvents: 'none', // Prevents clicks but keeps UI visible
              }}
            />
          )}
        </Dialog>
      </Box>
    )

    return tabContentJSX
  }

  const sendTestOrderEmail = (orderId: string, order: typeof us_rootOrder, supplier: typeof us_associatedSupplier, skus: any[]) => {
    const templateObject = generateEmailTemplateObjectForOrder(orderId, supplier, skus)
    const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, { skus })

    // Get the supplier location key from the order
    const locationKey = order.associated_supplier_location_key

    // Find the corresponding location email
    const locationEmail = supplier.locations[locationKey]?.location_email || 'default@supplier.com' // Fallback email

    return cloudFunctionManageRequest('manageEmails', {
      function: 'sendSendgridHtmlEmail',
      to: [locationEmail], // Send to the correct supplier location email
      subject: `Order Submission Confirmation - PO ${order.po_number}`,
      html: htmlContent,
      cc: [],
      bcc: [],
    })
  }

  const generateEmailTemplateObjectForOrder = (orderId: string, supplier: typeof us_associatedSupplier, skus: any[]): TsInterface_EmailTemplateObject => {
    const portalLink = `https://etwenergy.com/s/all_orders/etw_energy/${supplier.key}/`

    return {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': '#eeeeee',
          'padding': '16px',
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
              'padding': '24px',
            },
            contents: {
              HEADER: {
                element_type: 'h1',
                text: `Order Submission for ${supplier.name}`,
                text_type: 'static',
                style: {
                  'font-size': '20px',
                  'font-weight': 'bold',
                  'margin-bottom': '16px',
                  'color': '#333333',
                },
              },
              ORDER_DETAILS: {
                element_type: 'p',
                text: `Order ID: ${orderId}`,
                text_type: 'static',
                style: {
                  'font-size': '14px',
                  'color': '#333333',
                  'margin-bottom': '8px',
                },
              },

              PORTAL_LINK: {
                element_type: 'a',
                text: 'Go to Supplier Portal',
                text_type: 'static',
                href_type: 'static',
                href_url: portalLink,
                style: {
                  'font-size': '14px',
                  'color': '#1a73e8',
                  'text-decoration': 'underline',
                },
              },
              SKUS_HEADER: {
                element_type: 'h3',
                text: 'Shopping Cart Details',
                text_type: 'static',
                style: {
                  'font-size': '16px',
                  'font-weight': 'bold',
                  'margin-bottom': '12px',
                  'color': '#333333',
                },
              },
              SKUS_TABLE: {
                element_type: 'table',
                table_type: 'looped_data',
                table_style: {
                  'width': '100%',
                  'border-collapse': 'collapse',
                },
                tr_header_style: {
                  'background': '#f5f5f5',
                  'text-align': 'left',
                },
                tr_row_style: {
                  'border-bottom': '1px solid #dddddd',
                },
                table_columns: {
                  IMAGE: {
                    column_header: 'Image',
                    data_object_prop_key: 'associated_sku_estimated_photo_url', // This will now contain the preformatted <img> tag
                    th_style: {
                      'padding': '8px',
                      'font-weight': 'bold',
                    },
                    td_style: {
                      padding: '8px',
                    },
                  },
                  NAME: {
                    column_header: 'SKU Name',
                    data_object_prop_key: 'associated_sku_name',
                    th_style: {
                      'padding': '8px',
                      'font-weight': 'bold',
                    },
                    td_style: {
                      padding: '8px',
                    },
                  },
                  QUANTITY: {
                    column_header: 'Quantity',
                    data_object_prop_key: 'associated_sku_pick_requested_quantity',
                    th_style: {
                      'padding': '8px',
                      'font-weight': 'bold',
                    },
                    td_style: {
                      padding: '8px',
                    },
                  },
                },
                looped_data_object_key: 'skus',
              },
            },
          },
        },
      },
    } as TsInterface_EmailTemplateObject
  }

  const rJSX_ProjectImagesTabContent = (): JSX.Element => {
    let tabContentJSX = (
      <Box>
        <ProjectImagesView projectKey={getProp(us_rootOrder, 'associated_project_key', null)} />
      </Box>
    )
    return tabContentJSX
  }

  const rJSX_ProjectDocsTabContent = (): JSX.Element => {
    let tabContentJSX = (
      <Box>
        <ProjectFilesView projectKey={getProp(us_rootOrder, 'associated_project_key', null)} />
      </Box>
    )
    return tabContentJSX
  }

  const updateReceivedStatus = (rowKey: string, newStatus: string, newQty: number) => {
    us_setLineItems((prev) =>
      prev.map((item) =>
        item.key === rowKey
          ? {
              ...item,
              item_received_status: newStatus,
              actual_quantity_received: newStatus === 'received' ? item.associated_sku_pick_requested_quantity : newStatus === 'not_received' ? 0 : newQty,
            }
          : item,
      ),
    )

    const updateObject = {
      item_received_status: newStatus,
      actual_quantity_received:
        newStatus === 'received'
          ? us_lineItems.find((item) => item.key === rowKey)?.associated_sku_pick_requested_quantity
          : newStatus === 'not_received'
            ? 0
            : newQty,
    }

    DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(uc_RootData_ClientKey as any, rowKey), updateObject)
      .then(() => {
        console.log('Updated DB for', rowKey)
      })
      .catch((error: any) => {
        console.error('Error updating DB:', error)
      })
  }

  const tableColumns_SkuLineItems: any = {
    sku_number: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>SKU Number</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => <Typography variant="body2">{rowData.associated_sku_unique_identifier}</Typography>,
      },
    },
    sku_name: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>SKU Name</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => {
          const fullName: string = rowData.associated_sku_name || ''
          const displayName: string = fullName.length > 80 ? fullName.slice(0, 80) + '...' : fullName
          return (
            <Tooltip title={fullName}>
              <Typography variant="body2">{displayName}</Typography>
            </Tooltip>
          )
        },
      },
    },

    requested_quantity: {
      header: {
        header_jsx: () => <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>Quantity Ordered</Box>,
      },
      cell: {
        cell_jsx: (rowData: any) => <Typography variant="body2">{rowData.associated_sku_pick_requested_quantity}</Typography>,
      },
    },
    received_status: {
      header: {
        header_jsx: () => (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Box>Received Status</Box>
            <Tooltip
              arrow
              title={
                <Card sx={{ minWidth: 240, boxShadow: 3, m: 1 }}>
                  <CardContent sx={{ p: 1 }}>
                    <Typography
                      variant="body2"
                      mb={1}
                    >
                      <CheckCircleIcon
                        sx={{ verticalAlign: 'middle', mr: 0.5 }}
                        color="success"
                      />
                      <strong>Received:</strong> Mark as received if you received the correct quantity.
                    </Typography>
                    <Typography
                      variant="body2"
                      mb={1}
                    >
                      <WarningAmberIcon
                        sx={{ verticalAlign: 'middle', mr: 0.5 }}
                        color="warning"
                      />
                      <strong>Partial:</strong> Mark as partially received if you received some but not all. Enter the actual quantity received.
                    </Typography>
                    <Typography variant="body2">
                      <CancelIcon
                        sx={{ verticalAlign: 'middle', mr: 0.5 }}
                        color="error"
                      />
                      <strong>Not Received:</strong> Mark as not received if you did not receive this item.
                    </Typography>
                  </CardContent>
                </Card>
              }
            >
              <Box sx={{ ml: 0.5, fontSize: 16, cursor: 'pointer' }}>
                <Icon
                  size="lg"
                  icon="circle-info"
                />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      cell: {
        cell_jsx: (rowData: any, tableAdditionalData: any) => {
          const status = rowData.item_received_status || 'unmarked'
          const actualQty =
            typeof rowData.actual_quantity_received === 'number'
              ? rowData.actual_quantity_received
              : status === 'received'
                ? rowData.associated_sku_pick_requested_quantity
                : ''

          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Mark as received">
                <IconButton onClick={() => tableAdditionalData.updateReceivedStatus(rowData.key, 'received', rowData.associated_sku_pick_requested_quantity)}>
                  <CheckCircleIcon color={status === 'received' ? 'success' : 'disabled'} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Mark as partially received">
                <IconButton onClick={() => tableAdditionalData.updateReceivedStatus(rowData.key, 'partial', Number(actualQty) || 0)}>
                  <WarningAmberIcon color={status === 'partial' ? 'warning' : 'disabled'} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Mark as not received">
                <IconButton onClick={() => tableAdditionalData.updateReceivedStatus(rowData.key, 'not_received', 0)}>
                  <CancelIcon color={status === 'not_received' ? 'error' : 'disabled'} />
                </IconButton>
              </Tooltip>
              {status === 'partial' && (
                <>
                  <TextField
                    type="text"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={actualQty}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const newVal = e.target.value
                      if (/^\d*$/.test(newVal)) {
                        const parsedQty = newVal === '' ? 0 : parseInt(newVal, 10)
                        tableAdditionalData.updateReceivedStatus(rowData.key, 'partial', parsedQty)
                      }
                    }}
                    size="small"
                    variant="outlined"
                    sx={{ width: 80, ml: 1 }}
                  />
                  <Tooltip title="Enter the actual quantity received">
                    <Box sx={{ ml: 1, cursor: 'pointer' }}>
                      <Icon
                        size="lg"
                        icon="circle-info"
                      />
                    </Box>
                  </Tooltip>
                </>
              )}
            </Box>
          )
        },
      },
    },
  }

  const tableSettings_SkuLineItems: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'asc',
    sort_property: 'associated_sku_unique_identifier',
    use_live_data: false,
    conditional_row_styles: [],
    collapsible_columns: false,
    no_data_message: rLIB('No Line Items Found') as JSX.Element,
    searchable: false,
    sticky_header: true,
    sticky_table_height: 'calc(100vh - 180px)',
    alternate_row_colors: true,
    alternate_row_color_hex: themeVariables.background_hover,
    sticky_first_column: false,
    table_file_download_name: 'Line Item Confirmation',
  }

  const SkuLineItemsTable = (): JSX.Element => {
    let tableJSX = (
      <TableBasic
        tableAdditionalData={{ updateReceivedStatus }}
        tableColumns={tableColumns_SkuLineItems}
        tableData={us_lineItems}
        tableSettings={tableSettings_SkuLineItems}
      />
    )

    return tableJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <>{rLIB('Order')}</>
    if (us_rootOrder != null && us_rootOrder.po_number != null) {
      pageHeader = (
        <>
          {rLIB('Order')} - {us_rootOrder.po_number}
        </>
      )
    }
    return pageHeader
  }

  const rJSX_ImportSkusForOrderButton = (shrink: boolean, supplierKey: string, rootOrder: TsInterface_UnspecifiedObject): JSX.Element => {
    const buttonJSX = (
      <SkuImportButtonAndDialog
        importButtonText={<span>Import BOM</span>}
        importButtonColor="info" // or "success", "primary", etc.
        importButtonShrink={shrink}
        supplierKey={supplierKey}
        rootOrder={rootOrder}
      />
    )
    return buttonJSX
  }

  const rJSX_OrderLockingButton = (shrink: boolean): JSX.Element => {
    // Show one or the other
    if (us_rootOrder.status === 'locked') {
      return rJSX_UnlockButton(shrink)
    } else {
      return rJSX_LockButton(shrink)
    }
  }

  const rJSX_Page = (): JSX.Element => {
    let tabs = [
      {
        tabUrlKey: 'Shopping_Cart',
        tabHeader: (
          <Box className="tw-flex tw-items-center">
            {rLIB('Shopping Cart')}
            {us_rootOrder?.initial_email_sent_to_supplier && us_rootOrder?.edited_order_submitted === false && (
              <Icon
                className="tw-ml-2" // Tailwind margin-left for spacing
                sx={{ color: 'warning.main', fontSize: 20, ml: 1 }} // MUI spacing (ml: 1 = 8px)
                icon={'triangle-exclamation'}
              />
            )}
          </Box>
        ),
        tabContent: rJSX_ShoppingCartTabContent(),
        tabButtons: [
          { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
          { fullJSX: rJSX_LoadFromGoogleSpreadsheetButton(false), minJSX: rJSX_LoadFromGoogleSpreadsheetButton(true), sizeCutoff: 0 },
          {
            fullJSX: rJSX_ImportSkusForOrderButton(false, us_associatedSupplier.key, us_rootOrder),
            minJSX: rJSX_ImportSkusForOrderButton(true, us_associatedSupplier.key, us_rootOrder),
            sizeCutoff: 0,
          },
          // { fullJSX: rJSX_UnlockButton(false), minJSX: rJSX_UnlockButton(true), sizeCutoff: 0 },
          {
            fullJSX: rJSX_OrderLockingButton(false),
            minJSX: rJSX_OrderLockingButton(true),
            sizeCutoff: 0,
          },
          {
            fullJSX: rJSX_PDFDownloadButton(false, us_rootOrder, localLineItemSkus),
            minJSX: rJSX_PDFDownloadButton(true, us_rootOrder, localLineItemSkus),
            sizeCutoff: 0,
          },
        ],
      },

      {
        tabUrlKey: 'Images',
        tabHeader: rLIB('Project Images'),
        tabContent: rJSX_ProjectImagesTabContent(),
        tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
      },
      {
        tabUrlKey: 'Docs',
        tabHeader: rLIB('Project Docs'),
        tabContent: rJSX_ProjectDocsTabContent(),
        tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
      },

      {
        tabUrlKey: 'Line_Item_Confirmation',
        tabHeader: rLIB('Line Item Confirmation'),
        tabContent: SkuLineItemsTable(),
        tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
      },
    ]
    if (us_rootOrder.status === 'new') {
      tabs.unshift({
        tabUrlKey: 'Search',
        tabHeader: rLIB('Search'),
        tabContent: rJSX_SearchTabContent(),
        tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
      })
    }
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={tabs}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Order', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
