///////////////////////////////
// Description
///////////////////////////////

import { AppBar, Box, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { formInputs_NewStickyNote, formInputs_StickyNote, formSettings_StickyNote } from 'app/pages/projects/forms/sticky_notes'
import { useContext, useEffect, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_SalesOpportunity_v2_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities_v2'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  objectToArray,
  replaceNewlinesWithHtmlBreaks,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_OpportunityStickyNotesDialog {
  clientKey: string
  opportunityKey: string
  readOrWrite: 'write' | 'read'
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

/* eslint-disable react/prop-types */
export const OpportunityStickyNotesDialog: React.FC<TsInterface_OpportunityStickyNotesDialog> = ({ clientKey, opportunityKey, readOrWrite }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_opportunity, us_setOpportunity] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setOpportunity(newData)
    }
    if (clientKey != null && opportunityKey != null) {
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [clientKey, opportunityKey])

  // Functions

  // JSX Generation
  const rJSXStickyNoteContent = (): JSX.Element => {
    let cellJSX = <></>
    let stickyNoteContent = <></>
    let oldEditIcon = <></>
    let addIcon = <></>
    if (readOrWrite === 'write') {
      addIcon = (
        <Icon
          icon="circle-plus"
          type="solid"
          className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
          tooltip={rLIB('New Sticky Note')}
          tooltipPlacement="top"
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: {
                    date: returnFormattedDate(new Date(), 'YYYY-MM-DD'),
                  },
                  formInputs: formInputs_NewStickyNote,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: formSettings_StickyNote,
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      let newNoteId = uuidv4()
                      let updateObject = {
                        sticky_notes: {
                          [newNoteId]: {
                            timestamp: new Date(
                              returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                            ),
                            note: formSubmittedData.note,
                            key: newNoteId,
                          },
                        },
                      }
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), updateObject)
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'warning',
                  formDialogHeaderText: rLIB('Add Sticky Note'),
                  formDialogIcon: (
                    <Icon
                      icon="note"
                      type="solid"
                    />
                  ),
                },
              },
            })
          }}
        />
      )
      oldEditIcon = (
        <Icon
          icon="note"
          className="tw-mr-2 tw-text-secondary_main tw-cursor-pointer"
          size="xl"
          tooltip={rLIB('Edit Sticky Note')}
          tooltipPlacement="right"
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: us_opportunity,
                  formInputs: formInputs_StickyNote,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: formSettings_StickyNote,
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), formSubmittedData)
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'warning',
                  formDialogHeaderText: rLIB('Edit Sticky Note'),
                  formDialogIcon: (
                    <Icon
                      icon="note"
                      className="tw-mr-2"
                    />
                  ),
                },
              },
            })
          }}
        />
      )
    } else {
      oldEditIcon = (
        <Icon
          icon="note"
          className="tw-mr-2 tw-text-secondary_main"
          size="xl"
        />
      )
    }

    let stickyNoteContentOld = <></>
    let hasOldStickyNote = false
    if (us_opportunity != null && us_opportunity.sticky_note != null && us_opportunity.sticky_note !== '') {
      hasOldStickyNote = true
      stickyNoteContentOld = (
        <Box>
          <Box
            className="tw-inline-block tw-align-top"
            sx={{ width: '32px' }}
          >
            {oldEditIcon}
          </Box>
          <Box
            className="tw-inline-block"
            sx={{ width: 'calc(100% - 32px' }}
          >
            {replaceNewlinesWithHtmlBreaks(us_opportunity.sticky_note as string)}
          </Box>
        </Box>
      )
    }

    const rJSX_EditIcon = (readOrWrite: 'read' | 'write', note: TsInterface_UnspecifiedObject, opportunityKey: any) => {
      let editIconJSX = <></>
      if (readOrWrite === 'write') {
        editIconJSX = (
          <Tooltip title={rLIB('Edit Sticky Note')}>
            <Box
              className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
              onClick={() => {
                uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: {
                        note: note.note,
                        date: returnFormattedDate(note.timestamp, 'YYYY-MM-DD'),
                      },
                      formInputs: formInputs_NewStickyNote,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: formSettings_StickyNote,
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let updateObject = {
                            sticky_notes: {
                              [note.key]: {
                                note: formSubmittedData.note,
                                timestamp: new Date(
                                  returnDateCorrectedForTimezoneOffset(returnDateFromUnknownDateFormat(formSubmittedData.date)).setHours(12, 0, 0, 0),
                                ),
                              },
                            },
                          }
                          DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'warning',
                      formDialogHeaderText: rLIB('Edit Sticky Note'),
                      formDialogIcon: (
                        <Icon
                          icon="note"
                          type="solid"
                        />
                      ),
                    },
                  },
                })
              }}
            >
              <Icon icon="pen-to-square"></Icon>
            </Box>
          </Tooltip>
        )
      }
      return editIconJSX
    }

    const rJSX_DeleteIcon = (readOrWrite: 'read' | 'write', noteKey: any, note: TsInterface_UnspecifiedObject, opportunityKey: any) => {
      let deleteIconJSX = <></>
      if (readOrWrite === 'write') {
        deleteIconJSX = (
          <Tooltip title={rLIB('Delete Sticky Note')}>
            <Box className="tw-inline-block tw-opacity-40 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2">
              <Icon
                icon="trash"
                onClick={() => {
                  uc_setUserInterface_ConfirmDialogDisplay({
                    display: true,
                    confirm: {
                      color: 'error',
                      header: <>{rLIB('Delete Sticky Note')}</>,
                      icon: <Icon icon="trash" />,
                      submit_callback: () => {
                        return new Promise((resolve, reject) => {
                          let updateObject = {
                            sticky_notes: {
                              [noteKey]: null,
                            },
                            sticky_notes_deleted: {
                              [noteKey]: note,
                            },
                          }
                          DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_v2_Document(clientKey, opportunityKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                      },
                      submit_text: rLIB('Delete'),
                      text: rLIB('Are you sure that you want to delete this sticky note?'),
                    },
                  })
                }}
              />
            </Box>
          </Tooltip>
        )
      }
      return deleteIconJSX
    }

    let stickyNotes = getProp(us_opportunity, 'sticky_notes', {})
    if (objectToArray(stickyNotes).length > 0) {
      stickyNoteContent = (
        <Box>
          {objectToArray(stickyNotes)
            .sort(dynamicSort('timestamp', 'desc'))
            .map((stickyNote: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Icon
                  icon="note"
                  className="tw-mr-1"
                  sx={{ color: themeVariables.secondary_main }}
                />
                <Box
                  component="span"
                  className="tw-inline-block tw-mr-1"
                  sx={{ fontWeight: 'bold' }}
                >
                  {returnFormattedDate(stickyNote.timestamp, 'M/D/YY')}
                  {' - '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-40"
                >
                  {stickyNote.note}
                </Box>
                {rJSX_EditIcon(readOrWrite, stickyNote, opportunityKey)}
                {rJSX_DeleteIcon(readOrWrite, stickyNote.key, stickyNote, opportunityKey)}
              </Box>
            ))}
        </Box>
      )
    } else if (hasOldStickyNote === true) {
      stickyNoteContent = <></>
    } else {
      stickyNoteContent = <Box className="tw-italic tw-opacity-50 tw-inline-block">{rLIB('No Sticky Notes')}</Box>
    }

    cellJSX = (
      <Box>
        {stickyNoteContent}
        {stickyNoteContentOld}
        <Box>{addIcon}</Box>
      </Box>
    )
    return cellJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Dialog
        className="bp_dialog_lg_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 1, color: '#fff !important' }}
            >
              <Icon icon="notes" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              <Box className="tw-inline-block">
                {rLIB('Sticky Notes')} - {us_opportunity.unique_identifier}
              </Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box className="tw-p-4">{rJSXStickyNoteContent()}</Box>
        </DialogContent>
      </Dialog>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}
