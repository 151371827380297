///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Card, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesOpportunity_PageAnalyticsSessions_Collection } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic } from 'rfbp_core/components/map'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TableCellLink } from 'rfbp_core/components/table/cells/table_cell_link'
import { TableCellURL } from 'rfbp_core/components/table/cells/table_cell_url'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog, UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnDateFromUnknownDateFormat, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_AnalyticsDialog {
  opportunity: TsInterface_UnspecifiedObject
  opportunityKey: string
}

///////////////////////////////
// Functions
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const OpportunityAnalyticsCustomDialog: React.FC<TsInterface_AnalyticsDialog> = ({ opportunity, opportunityKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const { uc_setUserInterface_FormDialogDisplay } = 					useContext( Context_UserInterface_FormDialog )
  const [us_analyticsSessions, us_setAnalyticsSessions] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAnalyticsSessions(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // eslint-disable-next-line react/prop-types
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_SalesOpportunity_PageAnalyticsSessions_Collection(res_GCK.clientKey, opportunityKey),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, opportunityKey])

  // Functions

  // JSX Generation
  const rJSX_Map = (analyticsSession: TsInterface_UnspecifiedObject): JSX.Element => {
    let mapJSX = <></>
    if (
      analyticsSession != null &&
      analyticsSession.location_metadata != null &&
      analyticsSession.location_metadata.location_latitude != null &&
      analyticsSession.location_metadata.location_longitude != null
    ) {
      mapJSX = (
        <Box className="tw-rounded">
          <MapBasic
            mapMarkers={{
              pin: {
                label: '',
                position: {
                  lat: parseFloat(analyticsSession.location_metadata.location_latitude),
                  lng: parseFloat(analyticsSession.location_metadata.location_longitude),
                },
              },
            }}
            mapSettings={{
              center_lat: parseFloat(analyticsSession.location_metadata.location_latitude),
              center_lng: parseFloat(analyticsSession.location_metadata.location_longitude),
              map_type_id: 'roadmap',
              height: '328px',
              width: '100%',
              zoom: 12,
              tilt: 0,
              render_heatmap: false,
            }}
          />
        </Box>
      )
    } else {
      mapJSX = (
        <Box className="tw-text-center">
          <Box>{rLIB('No Location Data From IP Address')}</Box>
        </Box>
      )
    }
    return mapJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            // sx={{ backgroundColor: us_backgroundColor }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="magnifying-glass-chart" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {getProp(opportunity, 'name', '')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            {objectToArray(us_analyticsSessions)
              .sort(dynamicSort('timestamp_session_started', 'desc'))
              .map((analyticsSession: TsInterface_UnspecifiedObject, index: number) => (
                <Box
                  key={index}
                  className="tw-p-4"
                >
                  <Card className="tw-p-2">
                    <Box sx={{ fontSize: '18px' }}>
                      <Box className="tw-inline-block">
                        {returnFormattedDate(returnDateFromUnknownDateFormat(analyticsSession.timestamp_session_started), 'D MMM YYYY h:mm a')}
                      </Box>
                      <Box className="tw-inline-block">
                        {analyticsSession.session_length_seconds != null && analyticsSession.session_length_seconds >= 0 ? (
                          <Box className="tw-inline-block tw-opacity-40 tw-ml-2">
                            ({(analyticsSession.session_length_seconds / 60).toFixed(2)} {rLIB('minutes')})
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                    <Grid2
                      container
                      spacing={1}
                    >
                      <Grid2
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                      >
                        <Json
                          alphabetized={true}
                          data={getProp(analyticsSession, 'metadata', {})}
                        />
                      </Grid2>
                      <Grid2
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                      >
                        {rJSX_Map(analyticsSession)}
                      </Grid2>
                    </Grid2>
                    <Box>
                      <Json
                        alphabetized={true}
                        data={getProp(analyticsSession, 'actions_taken', {})}
                      />
                    </Box>
                  </Card>
                </Box>
              ))}
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Exports
///////////////////////////////
export const tableColumns_ABPDisclosurePresets: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="pen"
        />
      ),
      label: <>{rLIB('Create Form')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          let url = ApplicationPages.AdminABPDisclosureIndexPage.url(rowData.preset_url as string)
          window.open(url, '_blank')
        }
      },
    },
  }),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Preset Name')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                let url = ApplicationPages.AdminABPDisclosureIndexPage.url(rowData.preset_url as string)
                window.open(url, '_blank')
              }}
            >
              {rowData.preset_name ? rowData.preset_name : 'N/A'}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  preset_url: TableCellBasic('preset_url', rLIB('Form Creation URL Extension'), undefined),
  table: TableCellBasic('sales_org', rLIB('Sales Org'), undefined),
  timestamp_updated: TableCellTimestamp('timestamp_updated', rLIB('Last Updated'), 'timestamp_updated', 'D MMM YYYY h:mm a', false),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
}

export const tableSettings_ABPDisclosurePresets: TsInterface_TableDatabaseSettings = {
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: false,
        logic_type: '',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [],
      },
    },
  ],
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_created',
  use_live_data: true,
}

export const tableColumns_ABPForms: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View Associated Form Data')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: (
              <Dialog
                open={true}
                onClose={() => {
                  tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                }}
              >
                <DialogContent>
                  <Json data={rowData}></Json>
                </DialogContent>
              </Dialog>
            ),
            settings: {
              max_width: 'lg',
            },
          },
        })
      },
    },
  }),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Form Name')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                tableHooks.uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <Dialog
                        open={true}
                        onClose={() => {
                          tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                        }}
                      >
                        <DialogContent>
                          <Json data={rowData}></Json>
                        </DialogContent>
                      </Dialog>
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              }}
            >
              {rowData.form_name ? rowData.form_name : 'N/A'}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  address: TableCellBasic('customer_address_1', rLIB('Customer Address'), undefined),
  city: TableCellBasic('customer_address_city', rLIB('Customer City'), undefined),
  state: TableCellBasic('customer_address_state', rLIB('Customer State'), undefined),
  zip: TableCellBasic('customer_address_zip', rLIB('Customer Zip'), undefined),
  customer_name: TableCellBasic('customer_name', rLIB('Customer Name'), undefined),
  phone: TableCellBasic('customer_address_phone', rLIB('Customer Phone'), undefined),
  email: TableCellBasic('customer_address_email', rLIB('Customer Email'), undefined),
  form_type: TableCellBasic('form_type', rLIB('Type'), undefined),
  form_status: TableCellBasic('form_status', rLIB('Status'), undefined),
  form_id: TableCellBasic('form_id', rLIB('ID'), undefined),
  sales_org: TableCellBasic('sales_org', rLIB('Sales Org'), undefined),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
  form_link: TableCellURL('status_link', rLIB('View Form'), undefined),
  view_proposal: TableCellLink('proposal_url', rLIB('Attached Proposal'), undefined, '', ''),
}

export const tableSettings_ABPForms: TsInterface_TableDatabaseSettings = {
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: false,
        logic_type: '',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [],
      },
    },
  ],
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_created',
  use_live_data: true,
}
