///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_TaskWorkflows_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main')
}

export const DatabaseRef_TaskWorkflow_Document = (clientKey: string, taskWorkflowKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey)
}

export const DatabaseRef_TaskWorkflowsActive_Query = (
  clientKey: string,
  orderBy: string,
  orderDesc: boolean,
  limit: number | null,
  queryCursorsObject: TsInterface_QueryCursorsObject,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: orderBy, desc: orderDesc }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

export const DatabaseRef_ActiveSalesPartnerTaskWorkflows_Query = (clientKey: string, salesPartnerKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'associated_sales_partner_key', comparator: '==', value: salesPartnerKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_TaskWorkflowStagingTasks_Collection = (clientKey: string, taskWorkflowKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'workflow_staging')
}

export const DatabaseRef_TaskWorkflowStagingTasks_Document = (clientKey: string, taskWorkflowKey: string, taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'workflow_staging', taskKey)
}

export const DatabaseRef_TaskWorkflowProd_Document = (clientKey: string, taskWorkflowKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'workflow_prod', 'current')
}

export const DatabaseRef_TaskWorkflowProdTasksHistory_Document = (
  clientKey: string,
  taskWorkflowKey: string,
  timestampKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'workflow_prod_history', timestampKey)
}

export const DatabaseRef_TaskWorkflowTaskGroups_Collection = (clientKey: string, taskWorkflowKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'task_groups')
}

export const DatabaseRef_TaskWorkflowProdTaskGroups_Document = (
  clientKey: string,
  taskWorkflowKey: string,
  groupKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'task_groups', groupKey)
}

export const DatabaseRef_TaskWorkflowSowItems_Collection = (clientKey: string, taskWorkflowKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'sow_items')
}

export const DatabaseRef_TaskWorkflowSowItems_Document = (clientKey: string, taskWorkflowKey: string, itemKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'sow_items', itemKey)
}

export const DatabaseRef_TaskWorkflowConfigItems_Collection = (clientKey: string, taskWorkflowKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'config')
}

export const DatabaseRef_TaskWorkflowConfigItems_Document = (clientKey: string, taskWorkflowKey: string, itemKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main', taskWorkflowKey, 'config', itemKey)
}

export const DatabaseRef_TaskWorkflows_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    limit,
  )
}

export const DatabaseRef_TaskWorkflowDelayReasons_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'delay_reasons')
}

export const DatabaseRef_TaskWorkflowDelayReason_Document = (clientKey: string, delayReasonKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_workflows', 'delay_reasons', delayReasonKey)
}
