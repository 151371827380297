///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Dialog, Divider, Tooltip, Typography } from '@mui/material/'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UnassignedProjects_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { IconMenu } from 'rfbp_core/components/icon_menu/icon_menu'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_UserPermissions,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { downloadProjectImportTemplate, importProjectsToStagingArea, projectImportMappingV2 } from './services/project_imports_v2'
import { ProjectListCreateProjectTab } from './v2_list_tabs/tab_create_project'
import { ProjectListImportsNewTab } from './v2_list_tabs/tab_imports_new'
import { ProjectListUnassignedProjectsTab } from './v2_list_tabs/tab_unassigned_projects'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminActiveProjectsListPage']['key']

// Project Import Results
// const formSettings_NewProject: TsInterface_FormSettings = {
// highlight_missing: true,
// submit_button_alignment: "right",
// submit_button_hide: false,
// submit_button_icon: <SaveIcon/>,
// submit_button_saving_icon: true,
// submit_button_text: <>{s_SAVE}</>,
// }

// Generate Job Code
// const formInputs_NewProject: TsInterface_FormInputs = {
//   header0: {
//     data_type: 'string',
//     key: 'header0',
//     input_type: 'custom_form_input_jsx',
//     label: <></>,
//     required: false,
//     renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
//       let inputJSX = (
//         <Box className="tw-text-left tw-mt-0">
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: 700, color: themeVariables.white }}
//             className="tw-mt-0"
//           >
//             {rLIB('Customer Details')}
//           </Typography>
//         </Box>
//       )
//       return inputJSX
//     },
//   },
//   associated_customer_name: {
//     key: 'associated_customer_name',
//     label: rLIB('Customer Name'),
//     input_type: 'text_basic',
//     required: true,
//     data_type: 'string',
//   },
//   associated_customer_phone: {
//     key: 'associated_customer_phone',
//     label: rLIB('Customer Phone'),
//     input_type: 'text_basic',
//     required: true,
//     data_type: 'string',
//   },
//   associated_customer_email: {
//     key: 'associated_customer_email',
//     label: rLIB('Customer Email'),
//     input_type: 'text_basic',
//     required: true,
//     data_type: 'string',
//   },
//   header1: {
//     data_type: 'string',
//     key: 'header1',
//     input_type: 'custom_form_input_jsx',
//     label: <></>,
//     required: false,
//     renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
//       let inputJSX = (
//         <Box className="tw-text-left tw-mt-0">
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: 700, color: themeVariables.white }}
//             className="tw-mt-0"
//           >
//             {rLIB('Sales Partner')}
//           </Typography>
//         </Box>
//       )
//       return inputJSX
//     },
//   },
//   // associated_sales_partner_key: {
//   //   data_type: 'string',
//   //   input_type: 'multiple_choice_select',
//   //   key: 'associated_sales_partner_key',
//   //   options: objectToArray(salesPartnerOptionsInternal),
//   //   label: <>{rLIB('Sales Partner')}</>,
//   //   required: true,
//   // },
//   // associated_region_key: {
//   //   data_type: 'string',
//   //   input_type: 'multiple_choice_select',
//   //   key: 'associated_region_key',
//   //   options: [],
//   //   label: <>{s_REGION}</>,
//   //   required: true,
//   // },
//   header2: {
//     data_type: 'string',
//     key: 'header2',
//     input_type: 'custom_form_input_jsx',
//     label: <></>,
//     required: false,
//     renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
//       let inputJSX = (
//         <Box className="tw-text-left tw-mt-3">
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: 700, color: themeVariables.white }}
//             className="tw-mt-4"
//           >
//             {rLIB('Location')}
//           </Typography>
//         </Box>
//       )
//       return inputJSX
//     },
//   },
//   location_address: {
//     key: 'location_address',
//     label: rLIB('Street Address'),
//     input_type: 'text_basic',
//     required: true,
//     data_type: 'string',
//   },
//   location_city: {
//     key: 'location_city',
//     label: rLIB('City'),
//     input_type: 'text_basic',
//     required: true,
//     data_type: 'string',
//   },
//   location_state: {
//     key: 'location_state',
//     label: rLIB('State'),
//     input_type: 'multiple_choice_select_state_usa',
//     required: true,
//     data_type: 'string',
//   },
//   location_zip: {
//     key: 'location_zip',
//     label: rLIB('Zip Code'),
//     input_type: 'text_basic',
//     required: true,
//     data_type: 'string',
//   },
//   location_county: {
//     key: 'location_county',
//     label: rLIB('County'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   location_jurisdiction: {
//     key: 'location_jurisdiction',
//     label: rLIB('Jurisdiction'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   header3: {
//     data_type: 'string',
//     key: 'header3',
//     input_type: 'custom_form_input_jsx',
//     label: <></>,
//     required: false,
//     renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
//       let inputJSX = (
//         <Box className="tw-text-left tw-mt-3">
//           <Typography
//             variant="h6"
//             sx={{ fontWeight: 700, color: themeVariables.white }}
//             className="tw-mt-4"
//           >
//             {rLIB('System Details')}
//           </Typography>
//         </Box>
//       )
//       return inputJSX
//     },
//   },
//   system_panel_manufacturer: {
//     key: 'system_panel_manufacturer',
//     label: rLIB('Panel Manufacturer'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_panel_model: {
//     key: 'system_panel_model',
//     label: rLIB('Panel Model'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_panel_quantity: {
//     key: 'system_panel_quantity',
//     label: rLIB('Panel Quantity'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_storage_manufacturer: {
//     key: 'system_storage_manufacturer',
//     label: rLIB('Storage Manufacturer'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_storage_model: {
//     key: 'system_storage_model',
//     label: rLIB('Storage Model'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_storage_quantity: {
//     key: 'system_storage_quantity',
//     label: rLIB('Storage Quantity'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_storage_total_kwh: {
//     key: 'system_storage_total_kwh',
//     label: rLIB('Total Storage (kWH)'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_inverter_manufacturer: {
//     key: 'system_inverter_manufacturer',
//     label: rLIB('Inverter Manufacturer'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_inverter_model: {
//     key: 'system_inverter_model',
//     label: rLIB('Inverter Model'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_inverter_quantity: {
//     key: 'system_inverter_quantity',
//     label: rLIB('Inverter Quantity'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_size_dc: {
//     key: 'system_size_dc',
//     label: rLIB('System Size'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },
//   system_usage_offset: {
//     key: 'system_usage_offset',
//     label: rLIB('Usage Offset'),
//     input_type: 'text_basic',
//     required: false,
//     data_type: 'string',
//   },

//   // associated_sales_partner_key
//   // associated_sales_partner_name
//   // associated_region_key
//   // associated_region_name
// }

// Filter Options
const filterOptions = {
  all: {
    key: 'all',
    label: rLIB('All Imports'),
  },
  successful: {
    key: 'successful',
    label: rLIB('Successful Imports'),
  },
  manual_review: {
    key: 'manual_review',
    label: rLIB('Manual Review Required'),
  },
  failed: {
    key: 'failed',
    label: rLIB('Failed Imports'),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Page Specific Permissions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_projectFilter, us_setProjectFilter] = useState<string>('all')
  const [us_unassignedProjects, us_setUnassignedProjects] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_RootData_UserPermissions } = useContext(Context_RootData_UserPermissions)

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnassignedProjects(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UnassignedProjects_Query(res_GCK.clientKey, 10), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  // const createNewProjectFromForm = () => {
  //   let regionOptions: TsInterface_UnspecifiedObject = {}
  //   for (let loopRegionKey in us_regionsList) {
  //     let loopRegion = us_regionsList[loopRegionKey]
  //     if (loopRegion.status === 'active') {
  //       regionOptions[loopRegionKey] = {
  //         key: loopRegionKey,
  //         value: loopRegion['name'],
  //       }
  //     }
  //   }
  //   // formInputs_NewProject.associated_region_key.options = objectToArray(regionOptions)
  //   uc_setUserInterface_FormDialogDisplay({
  //     display: true,
  //     form: {
  //       form: {
  //         formAdditionalData: {},
  //         formData: {},
  //         formInputs: formInputs_NewProject,
  //         formOnChange: (
  //           formAdditionalData: TsInterface_FormAdditionalData,
  //           formData: TsInterface_FormData,
  //           formInputs: TsInterface_FormInputs,
  //           formSettings: TsInterface_FormSettings,
  //         ) => {},
  //         formSettings: formSettings_NewProject,
  //         formSubmission: (
  //           formSubmittedData: TsInterface_FormSubmittedData,
  //           formAdditionalData: TsInterface_FormAdditionalData,
  //           formHooks: TsInterface_FormHooksObject,
  //         ) => {
  //           return new Promise((resolve, reject) => {
  //             getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //               .then((res_GCK) => {
  //                 let newProjectData: TsInterface_UnspecifiedObject = { ...formSubmittedData }
  //                 newProjectData.client_key = res_GCK.clientKey
  //                 newProjectData.function = 'createProject'
  //                 cloudFunctionManageRequest('manageProjects', newProjectData)
  //                   .then((res_CFMR) => {
  //                     if (res_CFMR != null && getProp(res_CFMR, 'key', null) != null) {
  //                       directAppNavigation(formHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(getProp(res_CFMR, 'key', null)))
  //                     } else {
  //                       formHooks.uc_setUserInterface_AlertDialogDisplay({
  //                         display: true,
  //                         alert: {
  //                           color: 'primary',
  //                           header: rLIB('Success'),
  //                           icon: (
  //                             <Icon
  //                               icon="circle-ok"
  //                               type="solid"
  //                             />
  //                           ),
  //                           text: rLIB('Project created successfully'),
  //                         },
  //                       })
  //                     }
  //                     resolve(res_CFMR)
  //                   })
  //                   .catch((rej_CFMR) => {
  //                     formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMR.error })
  //                     reject(rej_CFMR)
  //                   })
  //               })
  //               .catch((rej_GCK) => {
  //                 formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
  //                 reject(rej_GCK)
  //               })
  //           })
  //         },
  //       },
  //       dialog: {
  //         formDialogHeaderColor: 'success',
  //         formDialogHeaderText: rLIB('New Project from Form'),
  //         formDialogIcon: (
  //           <Icon
  //             type="solid"
  //             icon="pen-to-square"
  //           />
  //         ),
  //       },
  //     },
  //   })
  // }

  // JSX Generation
  const rJSX_UnassignedCountBadgeLabel = (count: number): JSX.Element => {
    let labelJSX = <>{count}</>
    if (count >= 10) {
      labelJSX = <>10+</>
    }
    return labelJSX
  }

  const rJSX_UnassignedProjectsTabHeader = (): JSX.Element => {
    let labelJSX = <></>
    if (objectToArray(us_unassignedProjects).length > 0) {
      labelJSX = (
        <Box>
          <Badge
            badgeContent={rJSX_UnassignedCountBadgeLabel(objectToArray(us_unassignedProjects).length)}
            color="error"
          >
            {rLIB('Unassigned Projects')}
          </Badge>
        </Box>
      )
    } else {
      labelJSX = <Box>{rLIB('Unassigned Projects')}</Box>
    }
    return labelJSX
  }

  const rJSX_GlobalProjectSearchResult = (
    option: TsInterface_UnspecifiedObject,
    searchInputValue: string | null,
    inputHooks: TsInterface_InputHooksObject,
    additionalSearchData: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let searchResultJSX = (
      <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
        <Typography>
          {rLIB('Job Code')}: {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}{' '}
        </Typography>
        <Typography>
          {rLIB('Customer')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_customer_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('Sales Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_sales_rep_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('CSS Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_css_rep_name)}{' '}
        </Typography>
        <Typography>
          {rLIB('Status')}: {rJSX_HighlightedSearchString(searchInputValue, option.status)}{' '}
        </Typography>
        <Typography>
          {rLIB('Address')}: {rJSX_HighlightedSearchString(searchInputValue, option.location_address)}{' '}
        </Typography>
        <Button
          tabIndex={0}
          color={'info'}
          onKeyDown={(event) => {
            if (event.key === 'ArrowDown') {
              event.preventDefault() // Prevent default arrow down behavior
              const activeElement = document.activeElement

              console.log(activeElement)

              let currentIndex = 0
              if (activeElement === document.getElementById('search_input')) {
                console.log('HIGHLGIHTING SEARCH 1')
                currentIndex = -1
              } else {
                console.log('HIGHLGIHTING OPTION 1')
                try {
                  currentIndex = inputHooks.us_searchOptions.findIndex((option: any) => inputHooks.optionRefs.current[option.id].current === activeElement)
                } catch (err) {
                  console.error(err)
                }
              }

              console.log(currentIndex)

              const nextIndex = currentIndex + 1 < inputHooks.us_searchOptions.length ? currentIndex + 1 : 0

              console.log(nextIndex)

              // refs.current[us_searchOptions[nextIndex].id].current.focus()
            }
          }}
          onClick={(event) => {
            if (option.id != null) {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              // TODO - handle other user types probably
              onClickAppNavigation(event, inputHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(option.id as string))
            }
          }}
          variant="contained"
          sx={{ marginBottom: '5px' }}
        >
          <Icon icon="magnifying-glass" />
          {rLIB('View Project')}
        </Button>
        <Divider />
      </Box>
    )
    return searchResultJSX
  }

  const rJSX_SearchProjectsButton = (): JSX.Element => {
    return (
      <Button
        variant="outlined"
        color="info"
        startIcon={<Icon icon="magnifying-glass" />}
        onClick={() => {
          if (getProp(uc_RootData_UserPermissions, 'AdminActiveProjectsListPage', false) === true) {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <Dialog
                        className="etw_region_selection_dialog"
                        keepMounted
                        onClose={() => {
                          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                        }}
                        open={true}
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            component="div"
                            className="tw-font-bold tw-mb-2"
                          >
                            {rLIB('Search Projects')}
                          </Typography>
                          <SearchInput
                            clientKey={res_GCK.clientKey}
                            searchIndexKey={'projects'}
                            searchFilters={[]}
                            searchResultRenderer={rJSX_GlobalProjectSearchResult}
                            additionalSearchData={{}}
                            sx={{
                              width: '400px',
                              background: themeVariables.background_json,
                            }}
                          />
                          <Box sx={{ height: '30vh' }}></Box>
                        </Box>
                      </Dialog>
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
                // Set Focus
                setTimeout(() => {
                  console.log(document.getElementById('search_input'))
                  document.getElementById('search_input')?.focus()
                }, 100)
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }
        }}
      >
        {rLIB('Search Projects')}
      </Button>
    )
  }

  // const rJSX_NewProjectButton = (shrink: boolean, disabled: boolean): JSX.Element => {
  //   let buttonJSX = <></>
  //   if (shrink === false) {
  //     buttonJSX = (
  //       <Button
  //         variant="contained"
  //         color="success"
  //         className="tw-mr-2"
  //         disabled={disabled}
  //         startIcon={<Icon icon="circle-plus" />}
  //         onClick={() => {
  //           createNewProjectFromForm()
  //         }}
  //       >
  //         {rLIB('Create Project from Form')}
  //       </Button>
  //     )
  //   } else {
  //     buttonJSX = (
  //       <Tooltip
  //         title={rLIB('Create Project from Form')}
  //         placement="top"
  //       >
  //         <Button
  //           color="success"
  //           variant="contained"
  //           disabled={disabled}
  //           onClick={() => {
  //             createNewProjectFromForm()
  //           }}
  //           className="tw-mr-2 bp_icon_only_button"
  //         >
  //           <Icon icon="circle-plus" />
  //         </Button>
  //       </Tooltip>
  //     )
  //   }
  //   return buttonJSX
  // }

  const rJSX_downloadImportTemplateButton = () => {
    return (
      <Tooltip title={rLIB('Download Import Template')}>
        <Button
          variant="outlined"
          color="info"
          className="bp_icon_only_button tw-mr-2"
          onClick={() => {
            downloadProjectImportTemplate()
          }}
          startIcon={<Icon icon="cloud-arrow-down" />}
        />
      </Tooltip>
    )
  }

  const rJSX_ImportProjectButton = () => {
    return (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={false}
        importButtonText={rLIB('Import Projects')}
        importDialogHeader={rLIB('Import Projects')}
        importMappingOptions={projectImportMappingV2}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  importProjectsToStagingArea(res_GCK.clientKey, spreadsheetData, false)
                    .then((res_IPT) => {
                      resolve(res_IPT)
                    })
                    .catch((rej_IPT) => {
                      reject(rej_IPT)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
  }

  const rJSX_FilterImportsButton = (): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-mr-2">
        <IconMenu
          icon={
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Icon icon="filter" />}
            >
              {getProp(filterOptions, us_projectFilter, filterOptions.all).label}
            </Button>
          }
          menuItems={[
            {
              icon: 'filter-slash',
              text: rLIB('All Imports'),
              onClick: () => {
                us_setProjectFilter('all')
              },
            },
            {
              icon: 'circle-check',
              text: rLIB('Successful Imports'),
              onClick: () => {
                us_setProjectFilter('successful')
              },
            },
            {
              icon: 'circle-pause',
              text: rLIB('Manual Review Required'),
              onClick: () => {
                us_setProjectFilter('manual_review')
              },
            },
            {
              icon: 'circle-exclamation',
              text: rLIB('Failed Imports'),
              onClick: () => {
                us_setProjectFilter('failed')
              },
            },
          ]}
        />
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageTabs: TsInterface_TabContentUrlArray = [
      {
        tabUrlKey: 'Unassigned_Projects',
        tabHeader: rJSX_UnassignedProjectsTabHeader(),
        tabContent: <ProjectListUnassignedProjectsTab />,
        tabButtons: [{ fullJSX: rJSX_SearchProjectsButton(), minJSX: rJSX_SearchProjectsButton(), sizeCutoff: 0 }],
      },
      // {
      //   tabUrlKey: 'Project_Search',
      //   tabHeader: rLIB('Project Search'),
      //   tabContent: <ProjectListProjectSearchTab />,
      //   tabButtons: [],
      // },
      {
        tabUrlKey: 'Create_Project',
        tabHeader: <>{rLIB('Create Project')}</>, // TODO:
        tabContent: <ProjectListCreateProjectTab />,
        tabButtons: [{ fullJSX: rJSX_SearchProjectsButton(), minJSX: rJSX_SearchProjectsButton(), sizeCutoff: 0 }],
      },
      // {
      //   tabUrlKey: 'Import Projects',
      //   tabHeader: rLIB('Import Projects'),
      //   tabContent: <ProjectListImportsTab />,
      //   tabButtons: [{ fullJSX: rJSX_NewProjectButton(false, true), minJSX: rJSX_NewProjectButton(true, true), sizeCutoff: 0 }],
      // },
      {
        tabUrlKey: 'Import',
        tabHeader: <>{rLIB('Import Projects')}</>, // TODO:
        tabContent: <ProjectListImportsNewTab projectFilter={us_projectFilter} />,
        tabButtons: [
          { fullJSX: rJSX_downloadImportTemplateButton(), minJSX: rJSX_downloadImportTemplateButton(), sizeCutoff: 0 },
          { fullJSX: rJSX_ImportProjectButton(), minJSX: rJSX_ImportProjectButton(), sizeCutoff: 0 },
          { fullJSX: rJSX_FilterImportsButton(), minJSX: rJSX_FilterImportsButton(), sizeCutoff: 0 },
        ],
        // tabDisabled: true,
      },
    ]
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('New Projects')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={pageTabs}
              tabsSettings={{
                tabQueryParam: 'tab',
                baseUrl: ApplicationPages['AdminActiveProjectsListPage'].url(),
                overridePageTitle: true,
                basePageTitle: rLIB('New Projects', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
