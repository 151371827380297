///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Base
export const DatabaseRef_Inverters_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main')
}

export const DatabaseRef_Inverter_Document = (clientKey: string, inverterKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main', inverterKey)
}

export const DatabaseRef_ActiveInverters_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_Inverter_ImportAliases_Document = (clientKey: string, inverterKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'import_aliases', inverterKey)
}

export const DatabaseRef_Inverter_ImportAliases_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'import_aliases')
}

export const DatabaseRef_Inverter_ImportAliases_Query = (clientKey: string, inverterKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: inverterKey }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'import_aliases'), queryOperatorsArray, [], {}, null)
}

// Files
export const DatabaseRef_InverterFiles_Collection = (clientKey: string, inverterKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main', inverterKey, 'files')
}

export const DatabaseRef_InverterFile_Document = (clientKey: string, inverterKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main', inverterKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_InverterForumThreads_Collection = (clientKey: string, inverterKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main', inverterKey, 'forum')
}

export const DatabaseRef_InverterForumThread_Document = (clientKey: string, inverterKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main', inverterKey, 'forum', threadKey)
}

export const DatabaseRef_InverterForumMessages_Collection = (clientKey: string, inverterKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main', inverterKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_InverterForumMessage_Document = (
  clientKey: string,
  inverterKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'inverters', 'main', inverterKey, 'forum', threadKey, 'messages', messageKey)
}
