/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box } from '@mui/material'
import { returnCombinedTaskRoles, returnGroupedTasksData } from 'app/models/projects/project_services'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskFormsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { DatabaseRef_ProjectTaskWorkflow_Document, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { Context_RootData_ClientKey, Context_RootData_GlobalUser, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetDocument, DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_TasksTab } from '../project_view/tab_tasks'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectView_TasksTab {
  projectKey: string
  readOrWrite: 'read' | 'write'
  levelOfAccess: 'full' | 'sales'
  clientKeyOverride?: string
}

type TsType_TaskTableFilterOptions =
  | 'all'
  | 'active_tasks'
  | 'completed_tasks'
  | 'future_tasks'
  | 'deleted'
  | 'not_deleted'
  | 'user_tasks'
  | 'unassigned'
  | 'invalid_prereq_data_structure'

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const ProjectViewTasksTab = forwardRef((props: TsInterface_ProjectView_TasksTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_projectKey: TsInterface_ProjectView_TasksTab['projectKey'] = getProp(props, 'projectKey', null)
  let pr_clientKey: TsInterface_ProjectView_TasksTab['clientKeyOverride'] = getProp(props, 'clientKeyOverride', null)
  let pr_readOrWrite: TsInterface_ProjectView_TasksTab['readOrWrite'] = getProp(props, 'readOrWrite', 'read')
  let pr_levelOfAccess: TsInterface_ProjectView_TasksTab['levelOfAccess'] = getProp(props, 'levelOfAccess', 'full')

  // Hooks - useContext, useState, useReducer, other
  const [us_combinedUserRoles, us_setCombinedUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_groupedTaskData, us_setGroupedTaskData] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_projectTaskWorkflow, us_setProjectTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasksLoaded, us_setProjectTasksLoaded] = useState<boolean>(false)
  const [us_recursiveTasks, us_setRecursiveTasks] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_reloadTaskWorkflow] = useState<number>(0)
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProjectLoaded, us_setRootProjectLoaded] = useState<boolean>(false)
  const [us_taskPrerequisitesValidityCheck, us_setTaskPrerequisitesValidityCheck] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskTableFilter, us_setTaskTableFilter] = useState<TsType_TaskTableFilterOptions>('not_deleted')
  const [us_taskTableView, us_setTaskTableView] = useState<string>('tasks') // "tasks", "groups"
  const [us_tempTaskFormOptions, us_setTempTaskFormOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_tempTaskOwnerRolesOptions, us_setTempTaskOwnerRolesOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(actualClientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setProjectTaskWorkflow(res_DGD.data)
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorkflow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorkflow, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
        us_setProjectTasksLoaded(true)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(actualClientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
      us_setProjectTasksLoaded(true)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      us_setRootProjectLoaded(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(actualClientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, pr_clientKey])

  useEffect(() => {
    if (pr_readOrWrite === 'write') {
      // TEMP - Forms - only load if tasks can be created
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
          DatabaseGetCollection(DatabaseRef_TaskFormsActive_Query(actualClientKey, 'name', false, null, {}))
            .then((res_DGC) => {
              let taskFormOptions: TsInterface_UnspecifiedObject[] = []
              for (let loopFormKey in res_DGC.data) {
                let loopForm = res_DGC.data[loopFormKey]
                taskFormOptions.push({ key: loopForm.key, value: loopForm.name })
              }
              us_setTempTaskFormOptions(taskFormOptions)
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_clientKey, pr_readOrWrite])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        let clientUserRoles = returnClientUserRoles(actualClientKey)
        us_setTempTaskOwnerRolesOptions(objectToArray(clientUserRoles))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_clientKey])

  useEffect(() => {
    if (
      us_projectTaskWorkflow != null &&
      us_projectTaskWorkflow.task_groups != null &&
      us_projectTaskWorkflow.task_groups.length > 0 &&
      us_projectTasks != null &&
      objectToArray(us_projectTasks).length > 0
    ) {
      us_setGroupedTaskData(returnGroupedTasksData(us_projectTaskWorkflow.task_groups, us_projectTasks))
    }
  }, [us_projectTaskWorkflow, us_projectTasks])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let actualClientKey = pr_clientKey ? pr_clientKey : res_GCK.clientKey
        us_setCombinedUserRoles(returnCombinedTaskRoles(actualClientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_clientKey])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    if (us_projectTasks != null && objectToArray(us_projectTasks).length > 0) {
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_projectTasks))
      us_setRecursiveTasks(tasksThatReferenceThemselves)
      let invertedBooleanObject: TsInterface_UnspecifiedObject = {}
      for (let loopKey in tasksThatReferenceThemselves) {
        invertedBooleanObject[loopKey] = !tasksThatReferenceThemselves[loopKey]
      }
      us_setTaskPrerequisitesValidityCheck(invertedBooleanObject)
    }
  }, [us_projectTasks])

  // Functions

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-m-auto">
        {rJSX_TasksTab(
          pr_levelOfAccess,
          pr_readOrWrite,
          uc_RootData_ClientKey,
          uc_RootData_GlobalUser,
          us_combinedUserRoles,
          pr_projectKey,
          us_projectTaskWorkflow,
          us_projectTasks,
          us_projectTasksLoaded,
          us_recursiveTasks,
          us_rootProject,
          us_rootProjectLoaded,
          us_setTaskTableFilter,
          us_taskTableView,
          us_setTaskTableView,
          us_groupedTaskData,
          uc_setUserInterface_FormDialogDisplay,
          us_taskPrerequisitesValidityCheck,
          us_taskTableFilter,
          us_tempTaskFormOptions,
          us_tempTaskOwnerRolesOptions,
        )}
      </Box>
    )
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
