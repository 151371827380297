/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Switch, Tooltip, Typography } from '@mui/material'
import { taskFormIconOptions } from 'app/data/general_form_options'
import { forwardRef, useContext, useEffect, useReducer } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_TaskForms_Collection,
  DatabaseRef_TaskForms_Search,
  DatabaseRef_TaskForm_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_TaskLibraryTaskFormsTab {
  // projectKey: string
  us_taskCategory: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Table
const tableSettings_TaskForms: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: false,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: rLIB('No task forms found by search') as JSX.Element,
    search_property_options: [{ key: 'name', value: rLIB('Form Name') }],
  },
}

const tableColumns_TaskForms: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTaskFormsViewPage.url(rowData.key as string))
        }
      },
    },
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Rename')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'info',
              confirm_text: rLIB('Rename'),
              default_value: rowData.name,
              header: rLIB('Rename form'),
              icon: (
                <Icon
                  icon="sitemap"
                  type="solid"
                />
              ),
              input_label: rLIB('Form Name'),
              input_type: 'text',
              text: rLIB('Enter a name for this form'),
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    name: promptValue,
                    task_category: 'project',
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Form'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this form?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: rLIB('Undelete Form'),
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: rLIB('Undelete'),
              text: rLIB('Are you sure that you want to undelete this form?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'active',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  ui_icon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: '',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let iconJSX = <></>
        if (
          rowData != null &&
          rowData.ui_icon != null &&
          taskFormIconOptions != null &&
          taskFormIconOptions[rowData.ui_icon as string] != null &&
          taskFormIconOptions[rowData.ui_icon as string]['value'] != null
        ) {
          iconJSX = taskFormIconOptions[rowData.ui_icon as string]['value']
        } else {
          iconJSX = taskFormIconOptions['none']['value']
        }
        let cellJSX = (
          <Box
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: {
                      ui_icon: {
                        data_type: 'string',
                        input_type: 'multiple_choice_radio',
                        key: 'ui_icon',
                        label: rLIB('Icon'),
                        required: false,
                        options: objectToArray(taskFormIconOptions),
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              ui_icon: getProp(formSubmittedData, 'ui_icon', null),
                            }
                            DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit Icon')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            {iconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-w-5'
        return cellCSS
      },
    },
  },
  // name: TableCellBasic( "name", rLIB("Form Name"), "name" ),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Form Name')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTaskFormsViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  notes: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Notes')
      },
      header_sort_by: 'notes',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box></Box>
        let editIconJSX = (
          <Tooltip
            title={rLIB('Edit Notes')}
            placement="right"
          >
            <Box
              className="tw-inline-block tw-ml-2 tw-opacity-30 tw-cursor-pointer"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: {
                        notes: {
                          data_type: 'string',
                          input_type: 'text_multiline',
                          key: 'notes',
                          label: rLIB('Notes'),
                          required: false,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateObject = {
                                notes: getProp(formSubmittedData, 'notes', null),
                              }
                              DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: (
                        <>
                          {rLIB('Edit Notes')} - {rowData.name}
                        </>
                      ),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          </Tooltip>
        )
        if (rowData != null && rowData.notes != null && rowData.notes !== '') {
          cellJSX = (
            <Box>
              <Typography className="tw-inline-block">{rowData.notes}</Typography>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Typography className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('No Notes')}</Typography>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  available_for_use: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Available for use')
      },
      header_sort_by: 'available_for_use',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData['available_for_use'] == null) {
          rowData['available_for_use'] = false
        }
        let cellJSX = (
          <Box>
            <Switch
              disabled={rowData == null || rowData['published_to_prod'] !== true}
              checked={rowData.available_for_use as boolean}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.checked != null && rowData != null && rowData.key != null) {
                  let updateObject = {
                    available_for_use: event.target.checked,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  project_milestone_mapping: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.project_milestone_mapping != null) {
          cellJSX = (
            <Box>
              <Icon
                tooltipPlacement="left"
                tooltip={rLIB('Project Milestone Mapping Config')}
                onClick={() => {
                  tableHooks.uc_setUserInterface_CustomDialogDisplay({
                    display: true,
                    dialog: {
                      dialog_jsx: (
                        <Json
                          alphabetized={true}
                          data={{
                            project_milestone_mapping: getProp(rowData, 'project_milestone_mapping', {}),
                          }}
                        />
                      ),
                      settings: {
                        max_width: 'lg',
                      },
                    },
                  })
                }}
                icon="diamond-turn-right"
                sx={{ color: themeVariables.warning_main, fontSize: '20px' }}
              />
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
          cellCSS = 'workflow_table_highlight'
        }
        return cellCSS
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const TaskLibraryTaskFormsTab = forwardRef((props: TsInterface_TaskLibraryTaskFormsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_us_taskCategory: TsInterface_TaskLibraryTaskFormsTab['us_taskCategory'] = getProp(props, 'us_taskCategory', null)

  // Hooks - useContext, useState, useReducer, other
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Task Forms', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  const tableAdditionalData_TaskForms: TsInterface_TableAdditionalData = {}

  // Functions
  const tableDatabaseEndpoint_TaskForms = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'task_category', comparator: '==', value: pr_us_taskCategory },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_TaskForms_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableSearchEndpoint_TaskForms = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_TaskForms.rows_per_page
    if (limit === null) {
      limit = 30
    }
    return DatabaseRef_TaskForms_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  // JSX Generation
  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_TaskForms.search_settings_database == null) {
      tableSettings_TaskForms.search_settings_database = { search_type: 'firebase' }
    }
    tableSettings_TaskForms.search_settings_database.search_endpoint = tableSearchEndpoint_TaskForms
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_TaskForms}
            tableColumns={tableColumns_TaskForms}
            tableDatabaseEndpoint={tableDatabaseEndpoint_TaskForms}
            tableSettings={tableSettings_TaskForms}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <Box className="tw-m-auto">{rJSX_Table()}</Box>
    return tabJSX
  }

  // Render

  return <>{rJSX_Tab()}</>
})
