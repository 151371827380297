///////////////////////////////
// Description
///////////////////////////////

import { Box } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_ActiveProjectMilestones_Query,
  DatabaseRef_ProjectMilestones_Collection,
  DatabaseRef_ProjectMilestone_Document,
  DatabaseRef_ProjectMilestone_ImportAliases_Collection,
  DatabaseRef_ProjectMilestone_ImportAliases_Document,
  DatabaseRef_ProjectMilestone_ImportAliases_Query,
} from 'rfbp_aux/services/database_endpoints/directory/project_milestones'
import { TsInterface_FormAdditionalData, TsInterface_FormHooksObject, TsInterface_FormInputs, TsInterface_FormSubmittedData } from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { TsInterface_ImportHooksObject } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  DatabaseGetDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { keyFromString } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { openDeleteDataItemDialog, openMasterDataDialog, openUndeleteDataItemDialog } from './all_master_data'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Unique Field Key
let uniqueFieldKey = 'name'

// Document Ref
const documentRef = DatabaseRef_ProjectMilestone_Document

// Collection Ref
const collectionRef = DatabaseRef_ProjectMilestones_Collection

// Active Collection Ref
const activeCollectionRef = DatabaseRef_ActiveProjectMilestones_Query

// Import Aliases Ref
const importAliasesDocumentRef = DatabaseRef_ProjectMilestone_ImportAliases_Document
const importAliasesCollectionRef = DatabaseRef_ProjectMilestone_ImportAliases_Collection
const importAliasesQuery = DatabaseRef_ProjectMilestone_ImportAliases_Query

// Forum Refs
const forumThreadCollectionDatabaseEndpoint = null
const forumThreadDocumentDatabaseEndpoint = null
const forumMessageCollectionDatabaseEndpoint = null
const forumMessageDocumentDatabaseEndpoint = null
const forumMessageStorageEndpoint = null

// Files Refs
const fileSystemCollectionDatabaseEndpoint = null
const fileSystemDocumentDatabaseEndpoint = null
const fileSystemStorageEndpoint = null

// Search Index Ksy
const searchIndexKey = 'project_milestones'

// View Config
const viewConfig: TsInterface_UnspecifiedObject = {
  // HEADER: {
  //   key: 'HEADER',
  //   type: 'header',
  //   label: rLIB('Header Text'),
  // },
  name: {
    key: 'name',
    label: rLIB('Name'),
    type: 'non_editable_text',
  },
  api_mapping: {
    key: 'api_mapping',
    label: rLIB('API Mapping'),
    type: 'editable_text',
  },
}

// Open View Dialog
const openViewDialog = (itemData: TsInterface_UnspecifiedObject, inputHooks: TsInterface_InputHooksObject): void => {
  openMasterDataDialog(
    documentRef,
    collectionRef,
    itemData.key,
    viewConfig,
    {
      importAliasesDocumentRef,
      importAliasesCollectionRef,
      importAliasesQuery,
    },
    {
      forumThreadCollectionDatabaseEndpoint,
      forumThreadDocumentDatabaseEndpoint,
      forumMessageCollectionDatabaseEndpoint,
      forumMessageDocumentDatabaseEndpoint,
      forumMessageStorageEndpoint,
    },
    {
      fileSystemCollectionDatabaseEndpoint,
      fileSystemDocumentDatabaseEndpoint,
      fileSystemStorageEndpoint,
    },
    inputHooks.uc_setUserInterface_CustomDialogDisplay,
    masterData_ProjectMilestones,
  )
}

// Text
const textOptions = {
  item_text: rLIB('Project Milestone'),
  new_item_text: rLIB('New Project Milestone'),
  edit_item_text: rLIB('Edit Project Milestone'),
  import_items_text: rLIB('Import Project Milestones'),
  delete_item_text: rLIB('Delete Project Milestone'),
  confirm_delete_text: rLIB('Are you sure you want to delete this project milestone?'),
  undelete_item_text: rLIB('Undelete Project Milestone'),
  confirm_undelete_text: rLIB('Are you sure you want to undelete this project milestone?'),
}

// New Form
const formInputs_NewItem: TsInterface_FormInputs = {
  name: {
    key: 'name',
    label: rLIB('Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  api_mapping: {
    key: 'api_mapping',
    label: rLIB('API Mapping'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

// Edit Form
const formInputs_EditItem: TsInterface_FormInputs = {
  // name: formInputs_NewItem.name,
  api_mapping: formInputs_NewItem.api_mapping,
}

// Import Mapping
const importMappingOptions = {
  name: { key: 'name', required: true, label: rLIB('Name', false), automatch_properties: ['Name'] },
  api_mapping: { key: 'api_mapping', required: true, label: rLIB('API Mapping', false), automatch_properties: ['API Mapping'] },
}

// Table Columns
const tableColumns: TsInterface_TableColumns = {
  manage: TableCellManage({
    view_dialog: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: rLIB('View'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openViewDialog(rowData, tableHooks as any)
      },
    },
    // edit: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="pen-to-square"
    //     />
    //   ),
    //   label: rLIB('Edit'),
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     openEditDataItemDialog(rowData, tableAdditionalData, tableHooks, textOptions, formInputs_EditItem, updateItem)
    //   },
    // },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      label: rLIB('Delete'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openDeleteDataItemDialog(rowData, tableAdditionalData, tableHooks, textOptions, deleteItem)
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="magic-wand-sparkles"
        />
      ),
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      label: rLIB('Undelete'),
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openUndeleteDataItemDialog(rowData, tableAdditionalData, tableHooks, textOptions, undeleteItem)
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Name'), 'name'),
  api_mapping: TableCellBasic('api_mapping', rLIB('API Mapping'), 'api_mapping'),
}

// Table Settings
const rJSX_MasterDataSearchResult = (
  option: TsInterface_UnspecifiedObject,
  searchInputValue: string | null,
  inputHooks: TsInterface_InputHooksObject,
): JSX.Element => {
  let searchResultJSX = (
    <Box
      sx={{ 'padding': '2px', 'marginLeft': '8px', 'marginRight': '8px', 'cursor': 'pointer', '&:hover': { backgroundColor: themeVariables.warning_main } }}
      className="tw-cursor-pointer"
      onClick={() => {
        getClientKey(inputHooks.uc_RootData_ClientKey, inputHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseGetDocument(documentRef(res_GCK.clientKey, option.id))
              .then((res_DGD) => {
                openViewDialog(res_DGD.data, inputHooks)
              })
              .catch((rej_DGD) => {
                console.error(rej_DGD)
              })
          })
          .catch((rej_GCK) => {
            console.error(rej_GCK)
          })
      }}
    >
      {option.name}
    </Box>
  )
  return searchResultJSX
}

const tableSettings: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  search_settings_database: {
    search_type: 'meilisearch',
    search_client_key: '',
    search_index_key: searchIndexKey,
    search_filters: ['status = "active"'],
    search_result_renderer: rJSX_MasterDataSearchResult,
  },
  searchable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

// Create
const createItem = (
  clientKey: string,
  formSubmittedData: TsInterface_FormSubmittedData,
  formAdditionalData: TsInterface_FormAdditionalData,
  formHooks: TsInterface_FormHooksObject,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    let newItemKey = keyFromString(formSubmittedData[uniqueFieldKey])
    let updateObject: TsInterface_UnspecifiedObject = {
      ...formSubmittedData,
      key: newItemKey,
      status: 'active',
    }
    DatabaseSetMergeDocument(documentRef(clientKey, newItemKey), updateObject)
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Update
const updateItem = (clientKey: string, itemKey: string, updateObject: TsInterface_UnspecifiedObject): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), updateObject)
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Delete
const deleteItem = (clientKey: string, itemKey: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), { status: 'deleted' })
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Undelete
const undeleteItem = (clientKey: string, itemKey: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    DatabaseSetMergeDocument(documentRef(clientKey, itemKey), { status: 'active' })
      .then((res_DSMD) => {
        resolve(res_DSMD)
      })
      .catch((rej_DSMD) => {
        reject(rej_DSMD)
      })
  })
}

// Import
const importItems = (
  clientKey: string,
  spreadsheetData: TsInterface_UnspecifiedObject,
  importAdditionalData: TsInterface_UnspecifiedObject,
  importHooks: TsInterface_ImportHooksObject,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    // Create Update Array
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
    for (let loopRowKey in spreadsheetData) {
      let itemKey = keyFromString(spreadsheetData[loopRowKey][uniqueFieldKey])
      updateArray.push({
        type: 'setMerge',
        ref: documentRef(clientKey as string, itemKey),
        data: {
          ...spreadsheetData[loopRowKey],
          status: 'active',
          key: itemKey,
        },
      })
    }
    // Save to Database
    DatabaseStagedBatchUpdate(updateArray)
      .then((res_DSBU) => {
        resolve(res_DSBU)
      })
      .catch((rej_DSBU) => {
        reject(rej_DSBU)
      })
  })
}

// View
// TODO:

///////////////////////////////
// Exports
///////////////////////////////

export const masterData_ProjectMilestones = {
  uniqueFieldKey: uniqueFieldKey,
  new_item: {
    text: textOptions.new_item_text,
    form_inputs: formInputs_NewItem,
    form_settings: {},
    function: createItem,
  },
  // import_items: {
  //   text: textOptions.import_items_text,
  //   mapping: importMappingOptions,
  //   function: importItems,
  // },
  table: {
    columns: tableColumns,
    settings: tableSettings,
    database_ref: collectionRef,
  },
  full_exports: {
    documentRef: documentRef,
    collectionRef: collectionRef,
    activeCollectionRef: activeCollectionRef,
    importAliasesDocumentRef: importAliasesDocumentRef,
    importAliasesCollectionRef: importAliasesCollectionRef,
    importAliasesQuery: importAliasesQuery,
    textOptions: textOptions,
    formInputs_NewItem: formInputs_NewItem,
    formInputs_EditItem: formInputs_EditItem,
    importMappingOptions: importMappingOptions,
    tableColumns: tableColumns,
    tableSettings: tableSettings,
    createItem: createItem,
    updateItem: updateItem,
    deleteItem: deleteItem,
    undeleteItem: undeleteItem,
    importItems: importItems,
  },
}
