/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material/'
import { Document, Font, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { capitalizeFirstLetterInString } from 'rfbp_core/services/helper_functions/capitalize_first_letter_in_string'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

import {
  TableBasic,
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'

import { capitalizeAllWordsInString } from 'rfbp_core/services/helper_functions/capitalize_all_words_in_string'

import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
// @ts-expect-error - TODO: reason for error
import logoSrc2 from '../../images/logos/logo_temp.png'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_OrderSkusDialog {
  clientKey: string
  order: TsInterface_UnspecifiedObject
  skus: TsInterface_UnspecifiedObject
  supplier: TsInterface_UnspecifiedObject
  project: any
}

///////////////////////////////
// Variables
///////////////////////////////

// PDF Generation - Register Font
Font.register({ family: 'Roboto', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf' })
// Font.register({ family: 'Font Awesome', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/fontawesome-webfont.ttf' })
Font.register({ family: 'Font Awesome', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/fontawesome-webfont.ttf' })

// Styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    padding: 40,
  },
  header: {
    fontSize: 14,
    marginBottom: 4,
    fontFamily: 'Roboto',
  },
  paragraph: {
    paddingLeft: 8,
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  table: {
    display: 'flex',
    width: 'auto',
  },
  row: {
    flexDirection: 'row',
  },
  poNumberCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  poNumberCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  requestorCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  requestorCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  pickupDateCell1: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  pickupDateCell2: {
    fontSize: 16,
    minWidth: 200,
    paddingBottom: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  checkCell: {
    fontSize: 11,
    minWidth: 24,
    maxWidth: 24,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderTopWidth: 0,
    flexWrap: 'wrap',
    fontFamily: 'Font Awesome',
  },
  quantityCell: {
    fontSize: 11,
    minWidth: 40,
    maxWidth: 40,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
  },
  skuCell: {
    fontSize: 11,
    minWidth: 140,
    maxWidth: 140,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  nameCell: {
    fontSize: 11,
    minWidth: 315,
    maxWidth: 315,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexWrap: 'wrap',
  },
  checkCellHeader: {
    fontSize: 14,
    minWidth: 24,
    maxWidth: 24,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
    fontFamily: 'Font Awesome',
  },
  quantityCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 40,
    maxWidth: 40,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  skuCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 140,
    maxWidth: 140,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  nameCellHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    minWidth: 315,
    maxWidth: 315,
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    borderLeftWidth: 0,
    backgroundColor: '#ddd',
    flexWrap: 'wrap',
  },
  opacity_40: {
    opacity: 0.4,
  },
})

export const tableSettings_MaterialUnscheduledOrders: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  sortable: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'timestamp_scheduled_pickup_date_key',
}

export const tableColumns_UnscheduledMaterialOrders: TsInterface_TableColumns = {
  po_number: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('PO Number')
      },
      header_sort_by: 'po_number',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        switch (rowData.status) {
          case 'new':
            chipColor = 'primary'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="square-pen"
                className="tw-mr-2"
              />
            )
            break
          case 'deleted':
            chipColor = 'error'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="trash"
                className="tw-mr-2"
              />
            )
            break
          case 'locked':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="lock"
                className="tw-mr-2"
              />
            )
            break
          case 'submitted':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="paper-plane"
                className="tw-mr-2"
              />
            )
            break
          case 'confirmed':
            chipColor = 'success'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="badge-check"
                className="tw-mr-2"
              />
            )
            break
          case 'invoiced':
            chipColor = 'info'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="message-dollar"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Tooltip
            title={
              <>
                {capitalizeAllWordsInString(getProp(rowData, 'status', ''))} | {rLIB('Pickup Date')} -{' '}
                {getProp(rowData, 'timestamp_scheduled_pickup_date_key', '')}
              </>
            }
            placement="right"
          >
            <Chip
              label={
                <Box className="tw-align-top">
                  {iconJSX}
                  {rowData.po_number}
                </Box>
              }
              color={chipColor}
              variant="outlined"
              className="tw-cursor-pointer"
              sx={{ margin: '2px' }}
              onClick={() => {
                tableAdditionalData.openOrderDialog(rowData, rowData.key) // Trigger the dialog
              }}
            />
          </Tooltip>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  delivery_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Delivery Type')
      },
      header_sort_by: 'delivery_type',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        let textJSX = <></>
        if (rowData.delivery_type == null) {
          rowData.delivery_type = 'pickup'
        }
        switch (rowData.delivery_type) {
          case 'pickup':
            chipColor = 'primary'
            textJSX = <>{rLIB('Pickup')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="person-dolly"
                className="tw-mr-2"
              />
            )
            break
          case 'delivery':
            chipColor = 'secondary'
            textJSX = <>{rLIB('Delivery')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="truck"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Chip
            label={
              <Box className="tw-align-top">
                {iconJSX}
                {textJSX}
              </Box>
            }
            color={chipColor}
            variant="outlined"
            sx={{ margin: '2px' }}
          />
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
  associated_supplier_name: TableCellBasic('associated_supplier_name', rLIB('Supplier'), 'associated_supplier_name'),
  associated_supplier_location_name: TableCellBasic('associated_supplier_location_name', rLIB('Supplier Location'), 'associated_supplier_location_name'),
}

export const tableSettings_MaterialScheduledOrders: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  sortable: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'timestamp_scheduled_pickup_date_key',
}

export const tableColumns_ScheduledMaterialOrders: TsInterface_TableColumns = {
  po_number: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('PO Number')
      },
      header_sort_by: 'po_number',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        switch (rowData.status) {
          case 'new':
            chipColor = 'primary'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="square-pen"
                className="tw-mr-2"
              />
            )
            break
          case 'deleted':
            chipColor = 'error'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="trash"
                className="tw-mr-2"
              />
            )
            break
          case 'locked':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="lock"
                className="tw-mr-2"
              />
            )
            break
          case 'submitted':
            chipColor = 'warning'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="paper-plane"
                className="tw-mr-2"
              />
            )
            break
          case 'confirmed':
            chipColor = 'success'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="badge-check"
                className="tw-mr-2"
              />
            )
            break
          case 'invoiced':
            chipColor = 'info'
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="message-dollar"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Tooltip
            title={
              <>
                {capitalizeAllWordsInString(getProp(rowData, 'status', ''))} | {rLIB('Pickup Date')} -{' '}
                {getProp(rowData, 'timestamp_scheduled_pickup_date_key', '')}
              </>
            }
            placement="right"
          >
            <Chip
              label={
                <Box className="tw-align-top">
                  {iconJSX}
                  {rowData.po_number}
                </Box>
              }
              color={chipColor}
              variant="outlined"
              className="tw-cursor-pointer"
              sx={{ margin: '2px' }}
              onClick={() => {
                tableAdditionalData.openOrderDialog(rowData, rowData.key) // Trigger the dialog
              }}
            />
          </Tooltip>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  timestamp_scheduled_pickup_date_key: TableCellBasic('timestamp_scheduled_pickup_date_key', rLIB('Scheduled Date'), 'timestamp_scheduled_pickup_date_key'),

  delivery_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Delivery Type')
      },
      header_sort_by: 'delivery_type',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
        let iconJSX = <></>
        let textJSX = <></>
        if (rowData.delivery_type == null) {
          rowData.delivery_type = 'pickup'
        }
        switch (rowData.delivery_type) {
          case 'pickup':
            chipColor = 'primary'
            textJSX = <>{rLIB('Pickup')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="person-dolly"
                className="tw-mr-2"
              />
            )
            break
          case 'delivery':
            chipColor = 'secondary'
            textJSX = <>{rLIB('Delivery')}</>
            iconJSX = (
              <Icon
                sx={{ fontSize: '16px' }}
                icon="truck"
                className="tw-mr-2"
              />
            )
            break
        }
        let cellJSX = (
          <Chip
            label={
              <Box className="tw-align-top">
                {iconJSX}
                {textJSX}
              </Box>
            }
            color={chipColor}
            variant="outlined"
            sx={{ margin: '2px' }}
          />
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },

  associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Job Code'), 'associated_project_id_number'),
  associated_supplier_name: TableCellBasic('associated_supplier_name', rLIB('Supplier'), 'associated_supplier_name'),
  associated_supplier_location_name: TableCellBasic('associated_supplier_location_name', rLIB('Supplier Location'), 'associated_supplier_location_name'),
}

export const generateEmailTemplateObjectForLockedOrder = (order: TsInterface_UnspecifiedObject): TsInterface_EmailTemplateObject => {
  // Extract fields from the order
  const poNumber = order?.po_number ?? '???'
  const supplierName = order?.associated_supplier_name ?? '???'
  const projectName = order?.associated_project_id_number ?? order?.associated_project_key ?? '???'

  // Return the template object
  return {
    BODY_DIV: {
      element_type: 'div',
      style: {
        'box-sizing': 'border-box',
        'width': '100%',
        'background': '#eeeeee',
        'padding': '16px',
      },
      contents: {
        CARD_DIV: {
          element_type: 'div',
          style: {
            'max-width': '600px',
            'background': '#ffffff',
            'border-radius': '8px',
            'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
            'margin': '0 auto',
            'overflow': 'hidden',
          },
          contents: {
            HEADER_DIV: {
              element_type: 'div',
              style: {
                'box-sizing': 'border-box',
                'background': 'rgb(25, 30, 33)',
                'font-size': '20px',
                'width': '100%',
                'min-height': '70px',
                'padding': '8px',
                'text-align': 'center',
              },
              contents: {
                LOGO_IMG: {
                  element_type: 'img',
                  src_type: 'static',
                  src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                  alt: 'logo.png',
                  width: '80px',
                  height: 'auto',
                },
              },
            },
            CONTENT_DIV: {
              element_type: 'div',
              style: {
                padding: '24px',
              },
              contents: {
                TITLE: {
                  element_type: 'h1',
                  text_type: 'static',
                  text: 'Order Locked',
                  style: {
                    'font-size': '20px',
                    'font-weight': 'bold',
                    'margin-bottom': '16px',
                    'color': '#333333',
                  },
                },
                ORDER_INFO: {
                  element_type: 'p',
                  text_type: 'static',
                  text: `
                  <strong>PO Number:</strong> ${poNumber}<br/>
                  <strong>Supplier:</strong> ${supplierName}<br/>
                  <strong>Project:</strong> ${projectName}<br/><br/>
                  This order has been <strong>locked</strong> and can no longer be edited.
                `,
                  style: {
                    'font-size': '14px',
                    'margin-bottom': '12px',
                    'color': '#333333',
                  },
                },
                SKUS_HEADER: {
                  element_type: 'h3',
                  text_type: 'static',
                  text: 'Line Items',
                  style: {
                    'font-size': '16px',
                    'font-weight': 'bold',
                    'margin-bottom': '12px',
                    'color': '#333333',
                  },
                },
                SKUS_TABLE: {
                  element_type: 'table',
                  table_type: 'looped_data',
                  table_style: {
                    'width': '100%',
                    'border-collapse': 'collapse',
                  },
                  tr_header_style: {
                    'background': '#f5f5f5',
                    'text-align': 'left',
                  },
                  tr_row_style: {
                    'border-bottom': '1px solid #dddddd',
                  },
                  table_columns: {
                    SKU_NUMBER: {
                      column_header: 'SKU #',
                      data_object_prop_key: 'associated_sku_manufacturer_unique_identifier',
                      th_style: {
                        'padding': '8px',
                        'font-weight': 'bold',
                      },
                      td_style: {
                        padding: '8px',
                      },
                    },
                    SKU_NAME: {
                      column_header: 'SKU Name',
                      data_object_prop_key: 'associated_sku_name',
                      th_style: {
                        'padding': '8px',
                        'font-weight': 'bold',
                      },
                      td_style: {
                        padding: '8px',
                      },
                    },
                    QUANTITY: {
                      column_header: 'Quantity',
                      data_object_prop_key: 'associated_sku_pick_requested_quantity',
                      th_style: {
                        'padding': '8px',
                        'font-weight': 'bold',
                      },
                      td_style: {
                        padding: '8px',
                      },
                    },
                  },
                  // We'll assume you pass "skus" in the data objects
                  looped_data_object_key: 'skus',
                },
              },
            },
          },
        },
      },
    },
  } as TsInterface_EmailTemplateObject
}

///////////////////////////////
// Functions
///////////////////////////////

// eslint-disable-next-line react/prop-types
const OrderSkusDialog: React.FC<TsInterface_OrderSkusDialog> = ({ order, skus, clientKey, supplier, project }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_updatingLockStatus, us_setUpdatingLockStatus] = useState<boolean>(false)
  const [us_updatingSkuStatusObject, us_setUpdatingSkuStatusObject] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const [isMarkAsShippedDialogOpen, setIsMarkAsShippedDialogOpen] = useState(false)
  const [isSubmittingMarkAsShipped, setIsSubmittingMarkAsShipped] = useState(false)
  const [isMarkAsDeliveredDialogOpen, setIsMarkAsDeliveredDialogOpen] = useState(false)
  const [isSubmittingMarkAsDelivered, setIsSubmittingMarkAsDelivered] = useState(false)
  const [isEditDeliveryFieldsDialogOpen, setIsEditDeliveryFieldsDialogOpen] = useState(false)
  const [isSubmittingEditDeliveryFields, setIsSubmittingEditDeliveryFields] = useState(false)
  const [trackingNumber, setTrackingNumber] = useState('')
  const [driverContact, setDriverContact] = useState('')
  const [shippingNotes, setShippingNotes] = useState<string>(order.shipping_notes || '')
  const [deliveryNotes, setDeliveryNotes] = useState<string>(order.delivery_notes || '')
  const [isEditingShipping, setIsEditingShipping] = useState(false)
  const [isEditingDelivery, setIsEditingDelivery] = useState(false)

  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    // Nothing
  }, [])

  // Functions
  const returnPDFDownloadDocument = (order: TsInterface_UnspecifiedObject, skus: TsInterface_UnspecifiedObject) => {
    let addressLineJSX = <></>
    let deliveryNotesJSX = <></>
    if (getProp(order, 'delivery_type', 'pickup') === 'delivery') {
      // eslint-disable-next-line react/prop-types
      let address = project.location_address + ', ' + project.location_city + ', ' + project.location_state + ' ' + project.location_zip
      addressLineJSX = (
        <View style={styles.row}>
          <Text style={styles.pickupDateCell1}>{rLIB('Delivery Location')}:</Text>
          <Text style={styles.pickupDateCell2}>{address}</Text>
        </View>
      )
      deliveryNotesJSX = (
        <View style={styles.row}>
          <Text style={styles.pickupDateCell1}>{rLIB('Delivery Notes')}:</Text>
          <Text style={styles.pickupDateCell2}>{order.delivery_notes}</Text>
        </View>
      )
    }

    // Create PDF
    let qrCodePDFJSX = (
      <Document>
        <Page
          size="A4"
          style={styles.page}
        >
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.poNumberCell1}>{rLIB('Location')}:</Text>
              <Text style={styles.poNumberCell2}>{supplier.name}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.poNumberCell1}>{rLIB('Job Code')}:</Text>
              <Text style={styles.poNumberCell2}>{order.associated_project_id_number}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.poNumberCell1}>{rLIB('PO Number')}:</Text>
              <Text style={styles.poNumberCell2}>{order.po_number}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.requestorCell1}>{rLIB('Requestor')}:</Text>
              <Text style={styles.requestorCell2}>{order.associated_user_name}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.pickupDateCell1}>{rLIB('Scheduled Date')}:</Text>
              <Text style={styles.pickupDateCell2}>{order.timestamp_scheduled_pickup_date_key}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.pickupDateCell1}>{rLIB('Pickup or Delivery')}:</Text>
              <Text style={styles.pickupDateCell2}>{capitalizeFirstLetterInString(getProp(order, 'delivery_type', 'pickup'))}</Text>
            </View>
            {addressLineJSX}
            {deliveryNotesJSX}
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.checkCellHeader}>{'\uf14a'}</Text>
              <Text style={styles.quantityCellHeader}>{'Qty'}</Text>
              <Text style={styles.skuCellHeader}>{'ID'}</Text>
              <Text style={styles.nameCellHeader}>{'Description'}</Text>
            </View>
            {objectToArray(skus)
              .sort(dynamicSort('associated_sku_unique_identifier', 'asc'))
              .map((skuItem, index) => (
                <View
                  key={index}
                  style={styles.row}
                >
                  <Text style={styles.checkCell}></Text>
                  <Text style={styles.quantityCell}>
                    {getProp(skuItem, 'associated_sku_pick_requested_quantity', 1) * getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)}
                  </Text>
                  <Text style={styles.skuCell}>
                    <Text>
                      {skuItem['associated_sku_unique_identifier']}
                      {'\n'}
                    </Text>
                    <Text style={styles.opacity_40}>{skuItem['associated_sku_manufacturer_unique_identifier']}</Text>
                  </Text>
                  <Text style={styles.nameCell}>{skuItem['associated_sku_name']}</Text>
                </View>
              ))}
          </View>
        </Page>
      </Document>
    )
    return qrCodePDFJSX
  }

  const sendDeliveryNotesUpdateEmailUnauth = (order: TsInterface_UnspecifiedObject, newNotes: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        // 1) Build the template object
        const templateObject = generateEmailTemplateObjectForDeliveryNotesUpdate(order, newNotes)

        // 2) Generate the final HTML
        const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, {
          order, // if the template references dynamic fields
        })

        // 3) Get the supplier location key from the order
        const locationKey = order.associated_supplier_location_key

        // 4) Determine the correct email using the same logic as in sendTestOrderEmail
        const locationEmail = supplier.locations[locationKey]?.location_email || 'default@supplier.com'

        // 3) Send via your unauth CF
        cloudFunctionUnauthenticatedRequests({
          function: 'sendSendgridHtmlEmail',
          to: [locationEmail], // now using the dynamically determined email
          cc: [],
          bcc: [],
          subject: `Delivery Notes Updated for PO ${order?.po_number ?? '???'}`,
          html: htmlContent,
        })
          .then((res_Email) => {
            resolve(res_Email)
          })
          .catch((err_Email) => {
            reject(err_Email)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  /**
   * Builds the TsInterface_EmailTemplateObject for a “Delivery Notes Updated” email.
   *
   * Shows PO #, Supplier, Project, and the new delivery notes.
   */
  const generateEmailTemplateObjectForDeliveryNotesUpdate = (order: TsInterface_UnspecifiedObject, newNotes: string): TsInterface_EmailTemplateObject => {
    // Extract relevant fields
    const poNumber = order?.po_number ?? '???'
    const supplierName = order?.associated_supplier_name ?? '???'
    const supplierLocation = order?.associated_supplier_location_name ?? '???'
    const jobCode = order?.associated_project_id_number ?? order?.associated_project_key ?? '???'
    const taskName = order?.associated_task_name ?? '???'
    const orderKey = order?.key ?? '???'

    const portalLink = `https://etwenergy.com/s/all_orders/etw_energy/${order?.associated_supplier_key ?? '???'}/`

    // Build a snippet of HTML describing the new notes
    const messageHtml = `
      <p>
        <strong>Order Key:</strong> ${orderKey}<br/>
        <strong>PO Number:</strong> ${poNumber}<br/>
        <strong>Supplier:</strong> ${supplierName}<br/>
        <strong>Supplier Location:</strong> ${supplierLocation}<br/>
        <strong>Job Code:</strong> ${jobCode}<br/>
        <strong>Task:</strong> ${taskName}<br/><br/>
        The <strong>Delivery Notes</strong> for this order have been changed to:
        <br/><strong>${newNotes}</strong>.
      </p>
    `

    return {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': '#eeeeee',
          'padding': '16px',
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
            },
            contents: {
              HEADER_DIV: {
                element_type: 'div',
                style: {
                  'box-sizing': 'border-box',
                  'background': 'rgb(25, 30, 33)',
                  'font-size': '20px',
                  'width': '100%',
                  'min-height': '70px',
                  'padding': '8px',
                  'text-align': 'center',
                },
                contents: {
                  LOGO_IMG: {
                    element_type: 'img',
                    src_type: 'static',
                    src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                    alt: 'ETW Logo',
                    width: '80px',
                    height: 'auto',
                  },
                },
              },
              CONTENT_DIV: {
                element_type: 'div',
                style: {
                  padding: '24px',
                },
                contents: {
                  TITLE: {
                    element_type: 'h1',
                    text_type: 'static',
                    text: 'Delivery Notes Updated',
                    style: {
                      'font-size': '20px',
                      'font-weight': 'bold',
                      'margin-bottom': '16px',
                      'color': '#333333',
                    },
                  },
                  MESSAGE: {
                    element_type: 'p',
                    text_type: 'static',
                    text: messageHtml,
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                      'color': '#333333',
                    },
                  },
                  PORTAL_LINK: {
                    element_type: 'a',
                    text_type: 'static',
                    text: 'Open Supplier Portal',
                    href_type: 'static',
                    href_url: portalLink,
                    style: {
                      'font-size': '14px',
                      'color': '#000000',
                      'text-decoration': 'underline',
                    },
                  },
                },
              },
            },
          },
        },
      },
    } as TsInterface_EmailTemplateObject
  }

  const updateSkuItemStatus = (
    skuItem: TsInterface_UnspecifiedObject,
    newStatus: string,
    order: TsInterface_UnspecifiedObject, // pass the order in so we can reference info
  ) => {
    // Only proceed if we're not already updating this SKU
    if (us_updatingSkuStatusObject[skuItem.key] == null) {
      // Optimistically show the update in the UI
      us_setUpdatingSkuStatusObject((prevState) => ({
        ...prevState,
        [skuItem.key]: newStatus,
      }))

      // 1) Update the SKU’s status in Firestore (via CF)
      cloudFunctionUnauthenticatedRequests({
        function: 'updateSkuLineItemSupplierStatus',
        client_key: clientKey,
        line_item_key: skuItem.key,
        status: newStatus,
      })
        .then(() => {
          // 2) Update local data
          skus[skuItem.key]['associated_supplier_inventory_status'] = newStatus

          // 3) If out_of_stock, send the email
          if (newStatus === 'out_of_stock') {
            const templateObject = generateEmailTemplateObjectForOutOfStock(skuItem, order)
            const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, {
              skuItem,
              order,
            })

            // 3) Get the supplier location key from the order
            const locationKey = order.associated_supplier_location_key

            // 4) Determine the correct email using the same logic as in sendTestOrderEmail
            const locationEmail = supplier.locations[locationKey]?.location_email || 'default@supplier.com'

            const skuNumber = skuItem?.sku_number ?? skuItem?.associated_sku_manufacturer_unique_identifier ?? '???'

            // Use the server function name & param keys as defined in your backend
            return cloudFunctionUnauthenticatedRequests({
              function: 'sendSendgridHtmlEmail',
              to: [locationEmail], // now using the dynamically determined email
              cc: [],
              bcc: [],
              subject: `Out-of-Stock Notification for SKU #${skuNumber}`,
              html: htmlContent,
            })
          } else {
            // If not out_of_stock, skip email with a resolved Promise
            return Promise.resolve({ success: true })
          }
        })
        .then(() => {
          // 4) Both update & email succeeded (or was skipped). Reset UI & rerender
          us_setUpdatingSkuStatusObject((prevState) => ({
            ...prevState,
            [skuItem.key]: null,
          }))
          ur_forceRerender()
        })
        .catch((error) => {
          // If either the update or email failed, log and show an error
          console.error(error)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: rLIB('Failed to update SKU status or send email'),
              details: rLIB('An unknown error occurred'),
              code: 'ER-D-USOL-USIS-02',
            },
          })

          // Reset the UI state so we’re not stuck in “updating”
          us_setUpdatingSkuStatusObject((prevState) => ({
            ...prevState,
            [skuItem.key]: null,
          }))
          ur_forceRerender()
        })
    }
  }

  /**
   * Builds a TsInterface_EmailTemplateObject for a “Locked Order” email.
   *
   * Shows PO number, supplier, project, and any other details you want.
   */
  /**
   * Builds a TsInterface_EmailTemplateObject for a “Locked Order” email
   * that includes PO number, supplier, project, and a table of SKU line items.
   */

  // updateSkuItemStatus remains the same except for style changes below, so I'll just show the template function update
  const generateEmailTemplateObjectForOutOfStock = (
    skuItem: TsInterface_UnspecifiedObject,
    order: TsInterface_UnspecifiedObject,
  ): TsInterface_EmailTemplateObject => {
    // Extract fields from whichever side has them
    const supplierName = skuItem?.associated_supplier_name ?? order?.associated_supplier_name ?? '???'

    const poNumber = order?.po_number ?? skuItem?.associated_order_po_number ?? '???'

    const skuName = skuItem?.associated_sku_name ?? '???'

    // Some items might use a different field for SKU #:
    const skuNumber = skuItem?.sku_number ?? skuItem?.associated_sku_manufacturer_unique_identifier ?? '???'

    // The “project name” might be stored in either 'associated_project_id_number' or 'associated_project_key'
    const projectName = order?.associated_project_id_number ?? order?.associated_project_key ?? '???'

    // Build the Out-of-Stock message
    const outOfStockMessage = `
    <p>
      Supplier: <strong>${supplierName}</strong><br/>
      PO Number: <strong>${poNumber}</strong><br/>
      Project: <strong>${projectName}</strong><br/>
      SKU Name: <strong>${skuName}</strong><br/>
      SKU #: <strong>${skuNumber}</strong>
    </p>
    <p>This SKU has just been marked as <strong>Out of Stock</strong>.</p>
  `
    return {
      BODY_DIV: {
        element_type: 'div',
        style: {
          'box-sizing': 'border-box',
          'width': '100%',
          'background': '#eeeeee',
          'padding': '16px',
        },
        contents: {
          CARD_DIV: {
            element_type: 'div',
            style: {
              'max-width': '600px',
              'background': '#ffffff',
              'border-radius': '8px',
              'box-shadow': '0 4px 8px rgba(0, 0, 0, 0.1)',
              'margin': '0 auto',
              'overflow': 'hidden',
            },
            contents: {
              // 1) Header with the dark background & logo
              HEADER_DIV: {
                element_type: 'div',
                style: {
                  'box-sizing': 'border-box',
                  'background': 'rgb(25, 30, 33)',
                  'font-size': '20px',
                  'width': '100%',
                  'min-height': '70px',
                  'padding': '8px',
                  'text-align': 'center',
                },
                contents: {
                  LOGO_IMG: {
                    element_type: 'img',
                    src_type: 'static',
                    src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
                    alt: 'logo.png',
                    width: '80px',
                    height: 'auto',
                    style: {},
                  },
                },
              },
              // 2) Main content area
              CONTENT_DIV: {
                element_type: 'div',
                style: {
                  padding: '24px',
                },
                contents: {
                  HEADER: {
                    element_type: 'h1',
                    text: 'Out of Stock Notification',
                    text_type: 'static',
                    style: {
                      'font-size': '20px',
                      'font-weight': 'bold',
                      'margin-bottom': '16px',
                    },
                  },
                  MESSAGE: {
                    element_type: 'p',
                    text_type: 'static',
                    text: outOfStockMessage, // Inline HTML
                    style: {
                      'font-size': '14px',
                      'margin-bottom': '12px',
                    },
                  },
                  PORTAL_LINK: {
                    element_type: 'a',
                    text: 'Open Supplier Portal',
                    text_type: 'static',
                    href_type: 'static',
                    href_url: `https://etwenergy.com/s/all_orders/etw_energy/${order?.associated_supplier?.key ?? order?.associated_supplier_key ?? '???'}/`,
                    style: {
                      'font-size': '14px',
                      // removed the old #1a73e8 color
                      'color': '#000000',
                      'text-decoration': 'underline',
                    },
                  },
                },
              },
            },
          },
        },
      },
    } as TsInterface_EmailTemplateObject
  }

  // JSX Generation
  const rJSX_DownloadButtonProper = (order: TsInterface_UnspecifiedObject, disabled: boolean, loading: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="contained"
        // disabled={disabled || order.status === 'new'}
        startIcon={
          loading ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="file-pdf" />
          )
        }
      >
        {rLIB('Download PDF')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MaterialSearchResultSkuItemImage = (photoUrl: string | null): JSX.Element => {
    let imageJSX = <></>
    if (photoUrl != null) {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: '#FFF' }}
          className="tw-mr-2 tw-mt-2 tw-flex tw-items-center tw-justify-center"
        >
          <img
            className="tw-max-w-full tw-max-h-full tw-self-center"
            src={photoUrl}
            alt={photoUrl}
            loading="lazy"
            width={'80px'}
          />
        </Box>
      )
    } else {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: themeVariables.gray_800 }}
          className="tw-mr-2 tw-mt-2 tw-text-center"
        >
          <Box className="tw-pt-3">
            <Icon
              className="tw-opacity-20"
              sx={{ fontSize: '40px' }}
              icon="circle-question"
            />
          </Box>
          <Typography
            variant="caption"
            className="tw-opacity-20"
          >
            {rLIB('No image')}
          </Typography>
        </Box>
      )
    }
    return imageJSX
  }

  const rJSX_PDFDownloadButton = (order: TsInterface_UnspecifiedObject, skus: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = (
      <PDFDownloadLink
        document={returnPDFDownloadDocument(order, skus)}
        fileName={order.po_number + '.pdf'}
      >
        {({ blob, url, loading, error }) => (loading ? rJSX_DownloadButtonProper(order, true, loading) : rJSX_DownloadButtonProper(order, false, loading))}
      </PDFDownloadLink>
    )
    return buttonJSX
  }

  const rJSX_LockButton = (order: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = (
      <Button
        color="warning"
        variant="contained"
        className="tw-mr-2"
        disabled={us_updatingLockStatus || order.status === 'locked'}
        startIcon={
          us_updatingLockStatus ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="lock" />
          )
        }
        onClick={() => {
          us_setUpdatingLockStatus(true)
          // 1) Update order status to locked
          cloudFunctionUnauthenticatedRequests({
            function: 'updateSupplierOrder',
            client_key: clientKey,
            order_key: order.key,
            prop: 'status',
            value: 'locked',
          })
            .then((res_CFUR) => {
              // 2) Locally reflect the changes
              order['status'] = 'locked'
              us_setUpdatingLockStatus(false)
              ur_forceRerender()

              // 3) Build the email template + HTML
              const templateObject = generateEmailTemplateObjectForLockedOrder(order)
              const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, { order, skus })

              // 3) Get the supplier location key from the order
              const locationKey = order.associated_supplier_location_key

              // 4) Determine the correct email using the same logic as in sendTestOrderEmail
              const locationEmail = supplier.locations[locationKey]?.location_email || 'default@supplier.com'

              // 4) Send the email
              return cloudFunctionUnauthenticatedRequests({
                function: 'sendSendgridHtmlEmail',
                to: [locationEmail], // now using the dynamically determined email
                cc: [],
                bcc: [],
                subject: `Order #${order.po_number ?? '???'} Locked`,
                html: htmlContent,
              })
            })
            .then((res_Email) => {
              console.log('Lock email sent successfully:')
            })
            .catch((error) => {
              console.error(error)
              us_setUpdatingLockStatus(false)
              ur_forceRerender()
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: rLIB('Failed to lock order or send email'),
                  details: rLIB('An unknown error occurred'),
                  code: 'ER-D-USOL-LOCK-01',
                },
              })
            })
        }}
      >
        {rLIB('Lock')}
      </Button>
    )

    return buttonJSX
  }

  const rJSX_MarkAsShippedButton = (order: TsInterface_UnspecifiedObject): JSX.Element => {
    const handleMarkAsShippedSubmit = () => {
      setIsSubmittingMarkAsShipped(true)

      const updateObject = {
        delivery_status: 'shipped',
        tracking_number: trackingNumber,
        driver_contact_info: driverContact,
        shipping_notes: shippingNotes,
      }

      cloudFunctionUnauthenticatedRequests({
        function: 'updateSupplierOrderMultipleFields',
        client_key: clientKey,
        order_key: order.key,
        updateObject,
      })
        .then((res) => {
          order.delivery_status = 'shipped'
          order.tracking_number = trackingNumber
          order.driver_contact_info = driverContact
          order.shipping_notes = shippingNotes
          setIsSubmittingMarkAsShipped(false)
          setIsMarkAsShippedDialogOpen(false)
          ur_forceRerender()
        })
        .catch((err) => {
          console.error('Failed to mark as shipped:', err)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: rLIB('Failed to update delivery status'),
              details: rLIB('An unknown error occurred'),
              code: 'ER-D-USOL-DELIVERY-01',
            },
          })
          setIsSubmittingMarkAsShipped(false)
        })
    }

    return (
      <>
        {(!order.delivery_status || order.delivery_status === 'pending') && (
          <Button
            color="warning"
            className="tw-ml-2"
            variant="contained"
            onClick={() => setIsMarkAsShippedDialogOpen(true)}
          >
            Mark As Shipped
          </Button>
        )}

        <Dialog
          open={isMarkAsShippedDialogOpen}
          onClose={() => setIsMarkAsShippedDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Mark Order as Shipped</DialogTitle>
          <DialogContent>
            <TextField
              label="Tracking Number"
              fullWidth
              variant="outlined"
              margin="normal"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
            />
            <TextField
              label="Driver Contact Info"
              fullWidth
              variant="outlined"
              margin="normal"
              value={driverContact}
              onChange={(e) => setDriverContact(e.target.value)}
            />
            <TextField
              label="Shipping Notes"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={3}
              value={shippingNotes}
              onChange={(e) => setShippingNotes(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsMarkAsShippedDialogOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleMarkAsShippedSubmit}
              color="primary"
            >
              {isSubmittingMarkAsShipped ? (
                <Icon
                  icon="arrows-rotate"
                  className="bp_spin"
                />
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const rJSX_MarkAsDeliveredButton = (order: TsInterface_UnspecifiedObject): JSX.Element => {
    const handleMarkAsDeliveredSubmit = () => {
      setIsSubmittingMarkAsDelivered(true)

      const updateObject = {
        delivery_status: 'delivered',
        delivery_notes: deliveryNotes,
        // proof_of_delivery: proofOfDelivery,
      }

      cloudFunctionUnauthenticatedRequests({
        function: 'updateSupplierOrderMultipleFields',
        client_key: clientKey,
        order_key: order.key,
        updateObject,
      })
        .then((res) => {
          order.delivery_status = 'delivered'
          order.delivery_notes = deliveryNotes
          // order.proof_of_delivery = proofOfDelivery
          setIsSubmittingMarkAsDelivered(false)
          setIsMarkAsDeliveredDialogOpen(false)
          ur_forceRerender()
        })
        .catch((err) => {
          console.error('Failed to mark as delivered:', err)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: rLIB('Failed to update delivery status'),
              details: rLIB('An unknown error occurred'),
              code: 'ER-D-USOL-DELIVERY-02',
            },
          })
          setIsSubmittingMarkAsDelivered(false)
        })
    }

    return (
      <>
        <Button
          color="primary"
          variant="contained"
          className="tw-ml-2"
          onClick={() => setIsMarkAsDeliveredDialogOpen(true)}
          disabled={order.delivery_status !== 'shipped'}
        >
          Mark As Delivered
        </Button>

        <Dialog
          open={isMarkAsDeliveredDialogOpen}
          onClose={() => setIsMarkAsDeliveredDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Mark Order as Delivered</DialogTitle>
          <DialogContent>
            <TextField
              label="Delivery Notes"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={3}
              value={deliveryNotes}
              onChange={(e) => setDeliveryNotes(e.target.value)}
            />
            {/* <TextField
              label="Proof of Delivery"
              fullWidth
              variant="outlined"
              margin="normal"
              value={proofOfDelivery}
              onChange={(e) => setProofOfDelivery(e.target.value)}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsMarkAsDeliveredDialogOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleMarkAsDeliveredSubmit}
              color="primary"
              disabled={!deliveryNotes}
            >
              {isSubmittingMarkAsDelivered ? (
                <Icon
                  icon="arrows-rotate"
                  className="bp_spin"
                />
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const rJSX_EditDeliveryFieldsButton = (order: TsInterface_UnspecifiedObject): JSX.Element => {
    const handleEditDeliveryFieldsSubmit = () => {
      setIsSubmittingEditDeliveryFields(true)

      const updateObject = {
        driver_contact_info: driverContact,
        tracking_number: trackingNumber,
        shipping_notes: shippingNotes,
        delivery_notes: deliveryNotes,
        // proof_of_delivery: proofOfDelivery,
      }

      cloudFunctionUnauthenticatedRequests({
        function: 'updateSupplierOrderMultipleFields',
        client_key: clientKey,
        order_key: order.key,
        updateObject,
      })
        .then((res) => {
          order.driver_contact_info = driverContact
          order.tracking_number = trackingNumber
          order.shipping_notes = shippingNotes
          order.delivery_notes = deliveryNotes
          // order.proof_of_delivery = proofOfDelivery
          setIsSubmittingEditDeliveryFields(false)
          setIsEditDeliveryFieldsDialogOpen(false)
          ur_forceRerender()
        })
        .catch((err) => {
          console.error('Failed to update delivery fields:', err)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: rLIB('Failed to update delivery fields'),
              details: rLIB('An unknown error occurred'),
              code: 'ER-D-USOL-DELIVERY-03',
            },
          })
          setIsSubmittingEditDeliveryFields(false)
        })
    }

    const handleOpenDialog = () => {
      setTrackingNumber(order.tracking_number || '')
      setDriverContact(order.driver_contact_info || '')
      setShippingNotes(order.shipping_notes || '')
      setDeliveryNotes(order.delivery_notes || '')
      // setProofOfDelivery(order.proof_of_delivery || '')
      setIsEditDeliveryFieldsDialogOpen(true)
    }

    return (
      <>
        <Button
          color="secondary"
          variant="outlined"
          className="tw-mx-2"
          startIcon={<Icon icon="edit" />}
          onClick={handleOpenDialog}
        >
          Edit Delivery Fields
        </Button>

        <Dialog
          open={isEditDeliveryFieldsDialogOpen}
          onClose={() => setIsEditDeliveryFieldsDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Edit Delivery Fields</DialogTitle>
          <DialogContent>
            <TextField
              label="Tracking Number"
              fullWidth
              variant="outlined"
              margin="normal"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
            />
            <TextField
              label="Driver Contact Info"
              fullWidth
              variant="outlined"
              margin="normal"
              value={driverContact}
              onChange={(e) => setDriverContact(e.target.value)}
            />
            <TextField
              label="Shipping Notes"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={3}
              value={shippingNotes}
              onChange={(e) => setShippingNotes(e.target.value)}
            />
            <TextField
              label="Delivery Notes"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={3}
              value={deliveryNotes}
              onChange={(e) => setDeliveryNotes(e.target.value)}
            />
            {/* <TextField
              label="Proof of Delivery"
              fullWidth
              variant="outlined"
              margin="normal"
              value={proofOfDelivery}
              onChange={(e) => setProofOfDelivery(e.target.value)}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsEditDeliveryFieldsDialogOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditDeliveryFieldsSubmit}
              color="primary"
              disabled={!trackingNumber || !driverContact}
            >
              {isSubmittingEditDeliveryFields ? (
                <Icon
                  icon="arrows-rotate"
                  className="bp_spin"
                />
              ) : (
                'Submit'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const rJSX_InStockButton = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = <></>
    let variant: 'outlined' | 'filled' = 'outlined'
    let color: 'success' | 'warning' | 'error' | 'default' = 'default'
    let chipSX: TsInterface_UnspecifiedObject = { color: themeVariables.gray_600 }
    let iconJSX = (
      <Icon
        icon="badge-check"
        className="tw-mr-1.5"
        sx={{ fontSize: '16px' }}
      />
    )
    if (skuItem != null && skuItem['associated_supplier_inventory_status'] === 'in_stock') {
      variant = 'filled'
      color = 'success'
      chipSX = {}
    }
    if (us_updatingSkuStatusObject[skuItem.key] === 'in_stock') {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-mr-1.5"
          sx={{ fontSize: '16px' }}
        />
      )
    }
    buttonJSX = (
      <Chip
        className="tw-ml-2 tw-cursor-pointer"
        color={color}
        variant={variant}
        sx={chipSX}
        onClick={() => {
          updateSkuItemStatus(skuItem, 'in_stock', order)
        }}
        label={
          <Box>
            {iconJSX}
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {rLIB('In Stock')}
            </Typography>
          </Box>
        }
      />
    )
    return buttonJSX
  }

  const rJSX_OnOrderButton = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = <></>
    let variant: 'outlined' | 'filled' = 'outlined'
    let color: 'success' | 'warning' | 'error' | 'default' = 'default'
    let chipSX: TsInterface_UnspecifiedObject = { color: themeVariables.gray_600 }
    let iconJSX = (
      <Icon
        icon="clock"
        className="tw-mr-1.5"
        sx={{ fontSize: '16px' }}
      />
    )
    if (skuItem != null && skuItem['associated_supplier_inventory_status'] === 'on_order') {
      variant = 'filled'
      color = 'warning'
      chipSX = {}
    }
    if (us_updatingSkuStatusObject[skuItem.key] === 'on_order') {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-mr-1.5"
          sx={{ fontSize: '16px' }}
        />
      )
    }
    buttonJSX = (
      <Chip
        className="tw-ml-2 tw-cursor-pointer"
        color={color}
        variant={variant}
        sx={chipSX}
        onClick={() => {
          updateSkuItemStatus(skuItem, 'on_order', order)
        }}
        label={
          <Box>
            {iconJSX}
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {rLIB('On Order')}
            </Typography>
          </Box>
        }
      />
    )
    return buttonJSX
  }

  const rJSX_OutOfStockButton = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = <></>
    let variant: 'outlined' | 'filled' = 'outlined'
    let color: 'success' | 'warning' | 'error' | 'default' = 'default'
    let chipSX: TsInterface_UnspecifiedObject = { color: themeVariables.gray_600 }
    let iconJSX = (
      <Icon
        icon="triangle-exclamation"
        className="tw-mr-1.5"
        sx={{ fontSize: '16px' }}
      />
    )
    if (skuItem != null && skuItem['associated_supplier_inventory_status'] === 'out_of_stock') {
      variant = 'filled'
      color = 'error'
      chipSX = {}
    }
    if (us_updatingSkuStatusObject[skuItem.key] === 'out_of_stock') {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-mr-1.5"
          sx={{ fontSize: '16px' }}
        />
      )
    }
    buttonJSX = (
      <Chip
        className="tw-ml-2 tw-cursor-pointer"
        color={color}
        variant={variant}
        sx={chipSX}
        onClick={() => {
          updateSkuItemStatus(skuItem, 'out_of_stock', order)
        }}
        label={
          <Box>
            {iconJSX}
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {rLIB('Out of Stock')}
            </Typography>
          </Box>
        }
      />
    )
    return buttonJSX
  }

  const handleSaveNotes = (field: 'shipping_notes' | 'delivery_notes', value: string) => {
    if (!value) return

    const updateObject = { [field]: value }

    cloudFunctionUnauthenticatedRequests({
      function: 'updateSupplierOrderMultipleFields',
      client_key: clientKey,
      order_key: order.key,
      updateObject,
    })
      .then((res) => {
        // 1) DB update done
        order[field] = value
        ur_forceRerender()

        // 2) If it's "delivery_notes," we send an email
        if (field === 'delivery_notes') {
          // We'll pass the entire "order" object + the new notes
          return sendDeliveryNotesUpdateEmailUnauth(order, value)
        } else {
          // if it's shipping_notes or something else, skip email
          return Promise.resolve({ success: true })
        }
      })
      .then((res_Email) => {
        console.log('Delivery notes updated + email (if any) success:')
      })
      .catch((err) => {
        console.error(`Failed to update ${field}:`, err)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            message: rLIB('Failed to update'),
            details: rLIB('An unknown error occurred'),
            code: `ER-D-USOL-NOTES-01`,
          },
        })
      })
  }

  const rJSX_DeliveryAddress = (): JSX.Element => {
    if (getProp(order, 'delivery_type', 'pickup') !== 'delivery') {
      return <></>
    }

    // Construct the delivery address
    const address = [project.location_address, project.location_city, project.location_state, project.location_zip].filter(Boolean).join(', ')

    return (
      <Box className="tw-space-y-3">
        {/* DELIVERY LOCATION (Plain Text) */}
        <Box className="tw-mb-1">
          <Typography
            variant="h6"
            className="tw-mr-2 tw-inline-block"
          >
            {rLIB('Delivery Location')}:
          </Typography>
          <Typography
            variant="h6"
            className="tw-mr-2 tw-inline-block tw-opacity-40"
          >
            {address}
          </Typography>
        </Box>

        {/* SHIPPING NOTES (No Extra Border) */}
        <Box className="tw-mb-1">
          <Typography
            variant="h6"
            className="tw-mr-2 tw-inline-block"
          >
            {rLIB('Shipping Notes')}:
          </Typography>
          <Icon
            icon="pen-to-square"
            className="tw-mr-2 tw-inline-block tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 tw-transition-opacity"
            tooltip={rLIB('Edit shipping notes')}
            tooltipPlacement="top"
            onClick={() => setIsEditingShipping(true)}
          />
          {isEditingShipping ? (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={5}
              value={shippingNotes}
              onChange={(e) => setShippingNotes(e.target.value)}
              autoFocus
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  'fontSize': '1rem',
                  'borderColor': 'rgba(0, 0, 0, 0.23)', // Keeps a subtle border when editing
                  '&:hover': {
                    borderColor: 'rgba(0, 0, 0, 0.5)', // Slightly darker on hover
                  },
                  '&.Mui-focused': {
                    borderColor: 'rgba(0, 0, 0, 0.5)', // No blue focus outline
                  },
                },
              }}
              onBlur={() => {
                setIsEditingShipping(false)
                handleSaveNotes('shipping_notes', shippingNotes)
              }}
            />
          ) : (
            <Typography
              variant="h6"
              className="tw-mr-2 tw-inline-block tw-opacity-40 tw-cursor-pointer hover:tw-opacity-100"
              onClick={() => setIsEditingShipping(true)}
            >
              {shippingNotes || 'Click to add shipping notes...'}
            </Typography>
          )}
        </Box>

        {/* DELIVERY NOTES (No Extra Border) */}
        <Box className="tw-mb-1">
          <Typography
            variant="h6"
            className="tw-mr-2 tw-inline-block"
          >
            {rLIB('Delivery Notes')}:
          </Typography>
          <Icon
            icon="pen-to-square"
            className="tw-mr-2 tw-inline-block tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 tw-transition-opacity"
            tooltip={rLIB('Edit delivery notes')}
            tooltipPlacement="top"
            onClick={() => setIsEditingDelivery(true)}
          />
          {isEditingDelivery ? (
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={5}
              value={deliveryNotes}
              onChange={(e) => setDeliveryNotes(e.target.value)}
              autoFocus
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  'fontSize': '1rem',
                  'borderColor': 'rgba(0, 0, 0, 0.23)', // Keeps a subtle border when editing
                  '&:hover': {
                    borderColor: 'rgba(0, 0, 0, 0.5)', // Slightly darker on hover
                  },
                  '&.Mui-focused': {
                    borderColor: 'rgba(0, 0, 0, 0.5)', // No blue focus outline
                  },
                },
              }}
              onBlur={() => {
                setIsEditingDelivery(false)
                handleSaveNotes('delivery_notes', deliveryNotes)
              }}
            />
          ) : (
            <Typography
              variant="h6"
              className="tw-mr-2 tw-inline-block tw-opacity-40 tw-cursor-pointer hover:tw-opacity-100"
              onClick={() => setIsEditingDelivery(true)}
            >
              {deliveryNotes || 'Click to add delivery notes...'}
            </Typography>
          )}
        </Box>
      </Box>
    )
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar
              sx={{
                display: 'flex',
                flexDirection: 'column', // Two rows stacked vertically
                alignItems: 'flex-start', // Left-align by default
                paddingY: 1,
                paddingX: 2,
              }}
            >
              {/* --- First row: PO Number + Shipped/Delivered Status --- */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%', // So left and right content spread out
                }}
              >
                {/* Left side: Icon + PO Number + Delivery Status */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    aria-label="menu"
                    color="inherit"
                    disabled
                    edge="start"
                    size="large"
                    sx={{ mr: 2, color: '#fff !important' }}
                  >
                    <Icon icon="clipboard-list-check" />
                  </IconButton>

                  <Typography
                    component="span"
                    variant="h6"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'common.white', // adjust color if needed
                    }}
                  >
                    {order.po_number}
                    {order.delivery_status && (
                      <Box
                        className="tw-flex tw-items-center tw-ml-4 tw-px-2 tw-py-1 tw-rounded tw-border"
                        sx={{
                          borderColor: order.delivery_status === 'delivered' ? themeVariables.success_main : themeVariables.warning_main,
                        }}
                      >
                        <Icon
                          className="tw-mr-1"
                          icon={order.delivery_status === 'delivered' ? 'check-circle' : 'truck'}
                          sx={{
                            fontSize: '20px',
                            color: order.delivery_status === 'delivered' ? themeVariables.success_main : themeVariables.warning_main,
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            color: order.delivery_status === 'delivered' ? themeVariables.success_main : themeVariables.warning_main,
                          }}
                        >
                          {order.delivery_status === 'delivered' ? 'Order was Delivered' : 'Order was Shipped'}
                        </Typography>
                      </Box>
                    )}
                  </Typography>
                </Box>

                {/* (Optional) Right side in first row if needed */}
                {/* Put anything you want on the right side of the first row here */}
                <Box />
              </Box>

              {/* --- Second row: Buttons --- */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1, // Spacing between buttons
                  marginTop: 1,
                  flexWrap: 'wrap', // If too many buttons, they wrap to next line
                }}
              >
                {rJSX_LockButton(order)}
                {rJSX_PDFDownloadButton(order, skus)}

                {/* Conditionally render shipping/delivery buttons */}
                {order.timestamp_scheduled_pickup_date_key && (
                  <>
                    {!order.delivery_status && rJSX_MarkAsShippedButton(order)}
                    {order.delivery_status === 'shipped' && rJSX_MarkAsDeliveredButton(order)}
                    {(order.delivery_status === 'shipped' || order.delivery_status === 'delivered') && rJSX_EditDeliveryFieldsButton(order)}
                  </>
                )}
              </Box>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box className="tw-p-4">
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Location')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'associated_supplier_location_name', '')}
                </Typography>
              </Box>
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Job Code')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'associated_project_id_number', '')}
                </Typography>
              </Box>
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('PO Number')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'po_number', '')}
                </Typography>
              </Box>
              {/* <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Requestor')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'associated_user_name', '')}
                </Typography>
              </Box> */}
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Scheduled Date')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {getProp(order, 'timestamp_scheduled_pickup_date_key', '')}
                </Typography>
              </Box>
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Pickup or Delivery')}:
                </Typography>
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block tw-opacity-40"
                >
                  {capitalizeFirstLetterInString(getProp(order, 'delivery_type', 'pickup'))}
                </Typography>
              </Box>
              {rJSX_DeliveryAddress()}
            </Box>
            <Divider className="tw-mb-2" />
            <Box className="tw-p-4">
              <Box className="tw-mb-1">
                <Typography
                  variant="h6"
                  className="tw-mr-2 tw-inline-block"
                >
                  {rLIB('Skus List')}:
                </Typography>
              </Box>
              {objectToArray(skus)
                .sort(dynamicSort('associated_sku_unique_identifier', 'asc'))
                .map((skuItem: TsInterface_UnspecifiedObject, index: number) => (
                  <Stack
                    direction="row"
                    key={index}
                  >
                    {rJSX_MaterialSearchResultSkuItemImage(getProp(skuItem, 'associated_sku_estimated_photo_url', null))}
                    <Box sx={{ width: 'calc( 100% - 88px )' }}>
                      <Stack
                        direction="row"
                        className="tw-justify-between"
                      >
                        <Box sx={{ width: 'calc( 100% - 100px )' }}>
                          <Box>
                            <Typography
                              variant="body1"
                              className="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis"
                            >
                              {getProp(skuItem, 'associated_sku_name', '')}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body1"
                            className="tw-opacity-40"
                          >
                            {getProp(skuItem, 'associated_sku_unique_identifier', '')}
                          </Typography>
                          <Box>
                            {rLIB('Requested Quantity')}:
                            <Chip
                              className="tw-ml-2"
                              label={
                                <Typography variant="body1">
                                  {getProp(skuItem, 'associated_sku_pick_requested_quantity', 1) *
                                    getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)}
                                </Typography>
                              }
                            />
                            {rJSX_InStockButton(skuItem)}
                            {rJSX_OnOrderButton(skuItem)}
                            {rJSX_OutOfStockButton(skuItem)}
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                ))}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

function getDayOfWeek(dateString: string) {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const date = new Date(dateString)
  const dayIndex = date.getDay()
  return daysOfWeek[dayIndex]
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const clientKey: string = params.ck as string
  const supplierKey: string = params.sk as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_dataLoadedStatus, us_setDataLoadedStatus] = useState<string>('loading')

  const [us_loadingOrderDetails, us_setLoadingOrderDetails] = useState<boolean>(false)
  const [us_orderToLoad, us_setOrderToLoad] = useState<string>('loading')
  const [us_ordersList, us_setOrdersList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unscheduledOrdersList, us_setUnscheduledOrdersList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_ordersSortedData, us_setOrdersSortedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedLocationFilter, us_setSelectedLocationFilter] = useState<string>('ALL LOCATIONS')
  const [us_supplier, us_setSupplier] = useState<TsInterface_UnspecifiedObject>({})
  const [us_supplierLocations, us_setSupplierLocations] = useState<TsInterface_UnspecifiedObject>({})
  const today = new Date()
  const sixMonthsOut = new Date()
  sixMonthsOut.setMonth(sixMonthsOut.getMonth() + 6)
  const [us_weekStartDateKey, us_setWeekStartDateKey] = useState<string>(formatDateString(today))
  const [us_weekEndDateKey, us_setWeekEndDateKey] = useState<string>(formatDateString(sixMonthsOut))

  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  const [us_allOrders, us_setAllOrders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_skus, us_setSkus] = useState<TsInterface_UnspecifiedObject>({})

  // { sort-end } - hooks

  function formatDateString(date: Date): string {
    return date.toISOString().slice(0, 10)
  }

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('All Orders', false) as string
  }, [])

  useEffect(() => {
    if (us_dataLoadedStatus !== 'success') return

    let finalSortedData: TsInterface_UnspecifiedObject = {}

    // Ensure start and end dates are properly formatted strings
    const startDate = us_weekStartDateKey
    const endDate = us_weekEndDateKey

    for (let loopOrderKey in us_ordersList) {
      let loopOrder = us_ordersList[loopOrderKey]
      if (!loopOrder) continue

      // Use the stored string directly for filtering
      let orderDateKey = loopOrder['timestamp_scheduled_pickup_date_key']

      // Filter by location
      const locationOk = us_selectedLocationFilter === 'ALL LOCATIONS' || loopOrder['associated_supplier_location_key'] === us_selectedLocationFilter

      // Date range check (Compare strings directly)
      const dateOk = orderDateKey >= startDate && orderDateKey <= endDate

      if (locationOk && dateOk) {
        const dateGrouping = orderDateKey || 'No Date'
        if (!finalSortedData[dateGrouping]) {
          finalSortedData[dateGrouping] = {
            date: dateGrouping,
            orders: {},
          }
        }
        finalSortedData[dateGrouping].orders[loopOrderKey] = loopOrder
      }
    }

    us_setOrdersSortedData(finalSortedData)
  }, [us_ordersList, us_dataLoadedStatus, us_weekStartDateKey, us_weekEndDateKey, us_selectedLocationFilter])

  //Get All Orders for Supplier
  useEffect(() => {
    // us_setDataLoadedStatus('loading')

    if (clientKey && supplierKey) {
      cloudFunctionUnauthenticatedRequests({
        function: 'getAggregateSupplierOrderDetails',
        client_key: clientKey,
        supplier_key: supplierKey,
      })
        .then((res_CFUR: any) => {
          if (res_CFUR.data.success) {
            us_setAllOrders(res_CFUR.data.orders)
            us_setSkus(res_CFUR.data.skus)
          } else {
            console.error('Response does not indicate success:', res_CFUR)
          }
        })
        .catch((rej_CFUR) => {
          console.error('Error during API call:', rej_CFUR)
        })
    } else {
      // us_setDataLoadedStatus('error') // Error state if clientKey or supplierKey is missing
    }
  }, [clientKey, supplierKey])

  useEffect(() => {
    us_setDataLoadedStatus('loading')

    cloudFunctionUnauthenticatedRequests({
      function: 'getAllScheduledSupplierOrders',
      client_key: clientKey,
      supplier_key: supplierKey,
    })
      .then((res_CFUR) => {
        us_setDataLoadedStatus('success')

        // Store every order
        const fetchedOrders = getProp(getProp(res_CFUR, 'data', {}), 'orders', {})
        us_setOrdersList(fetchedOrders)

        // If you need supplier info, locations, etc.
        const supplierData = getProp(getProp(res_CFUR, 'data', {}), 'supplier', {})
        us_setSupplier(supplierData)

        // Example filtering for active locations
        const allLocations = getProp(supplierData, 'locations', {})
        let supplierLocations: TsInterface_UnspecifiedObject = {}
        for (let loopLocationKey in allLocations) {
          let loopLocation = allLocations[loopLocationKey]
          if (loopLocation != null && loopLocation.status === 'active') {
            supplierLocations[loopLocationKey] = loopLocation
          }
        }
        us_setSupplierLocations(supplierLocations)
      })
      .catch((rej_CFUR) => {
        us_setDataLoadedStatus('error')
      })
  }, [clientKey, supplierKey])

  useEffect(() => {
    us_setDataLoadedStatus('loading')

    if (clientKey != null && supplierKey != null) {
      cloudFunctionUnauthenticatedRequests({
        function: 'getAllSupplierUnassignedOrders',
        client_key: clientKey,
        supplier_key: supplierKey,
      })
        .then((res_CFUR) => {
          us_setDataLoadedStatus('success')
          us_setUnscheduledOrdersList(getProp(getProp(res_CFUR, 'data', {}), 'orders', {}))
          // us_setSupplier(getProp(getProp(res_CFUR, 'data', {}), 'supplier', {}))

          // Extract active supplier locations
          let supplierLocations: TsInterface_UnspecifiedObject = {}
          let allLocations = getProp(getProp(getProp(res_CFUR, 'data', {}), 'supplier', {}), 'locations', {})
          for (let loopLocationKey in allLocations) {
            let loopLocation = allLocations[loopLocationKey]
            if (loopLocation != null && loopLocation.status === 'active') {
              supplierLocations[loopLocationKey] = loopLocation
            }
          }
          us_setSupplierLocations(supplierLocations)
        })
        .catch((rej_CFUR) => {
          us_setDataLoadedStatus('error')
        })
    } else {
      us_setDataLoadedStatus('loading')
    }
  }, [clientKey, supplierKey])

  // Functions
  // const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
  //   return new Promise((resolve, reject) => {
  //     us_setSelectedDate(new Date(newDate))
  //     ur_forceRerender()
  //     resolve({ success: true })
  //   })
  // }

  const handleChangeStartDate = (newDateParam: any): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      // 1) Convert string to JS Date
      const newDate = new Date(newDateParam)
      // Check if that's valid:
      if (isNaN(newDate.getTime())) {
        // If invalid, log a warning or set a default
        console.warn('handleChangeStartDate → Invalid date:', newDateParam)
        resolve({ success: false })
        return
      }

      // 2) Create a local "midnight" date
      const localMidnight = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())

      // 3) Format to YYYY-MM-DD
      const formattedDate = localMidnight.toISOString().slice(0, 10)

      // 4) Update your state
      us_setWeekStartDateKey(formattedDate)
      resolve({ success: true })
    })
  }

  const handleChangeEndDate = (newDateParam: any): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      // 1) Convert string to JS Date if needed
      const newDate = new Date(newDateParam)
      if (isNaN(newDate.getTime())) {
        console.warn('handleChangeEndDate → Invalid date:', newDateParam)
        resolve({ success: false })
        return
      }

      // 2) Create local midnight
      const localMidnight = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())

      // 3) Format as "YYYY-MM-DD"
      const formattedDate = localMidnight.toISOString().slice(0, 10)

      // 4) Set state
      us_setWeekEndDateKey(formattedDate)
      resolve({ success: true })
    })
  }

  const openOrderDialog = (order: TsInterface_UnspecifiedObject, orderKey: string) => {
    us_setLoadingOrderDetails(true)
    cloudFunctionUnauthenticatedRequests({
      function: 'getSupplierOrderDetails',
      client_key: clientKey,
      supplier_key: supplierKey,
      order_key: order.key,
    })
      .then((res_CFUR) => {
        us_setLoadingOrderDetails(false)
        let skus: TsInterface_UnspecifiedObject = getProp(getProp(res_CFUR, 'data', {}), 'skus', {})
        let project: TsInterface_UnspecifiedObject = getProp(getProp(res_CFUR, 'data', {}), 'project', {})
        uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: (
              <OrderSkusDialog
                order={order}
                skus={skus}
                clientKey={clientKey}
                supplier={us_supplier}
                project={project}
              />
            ),
            settings: {
              max_width: 'lg',
            },
          },
        })
      })
      .catch((rej_CFUR) => {
        us_setLoadingOrderDetails(false)
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            message: rLIB('Failed to view order details'),
            details: rLIB('An unknown error occurred'),
            code: 'ER-D-USOL-OOD-02',
          },
        })
      })
  }

  // JSX Generation
  const rJSX_HeaderLocation = (): JSX.Element => {
    let headerJSX = <></>
    if (getProp(us_supplier, 'name', null) != null) {
      headerJSX = (
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.primary_main }}
        >
          {getProp(us_supplier, 'name', '')}
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_MaterialSearchResultSkuItemImage = (photoUrl: string | null): JSX.Element => {
    let imageJSX = <></>
    if (photoUrl != null) {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: '#FFF' }}
          className="tw-mr-2 tw-mt-2 tw-flex tw-items-center tw-justify-center"
        >
          <img
            className="tw-max-w-full tw-max-h-full tw-self-center"
            src={photoUrl}
            alt={photoUrl}
            loading="lazy"
            width={'80px'}
          />
        </Box>
      )
    } else {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: themeVariables.gray_800 }}
          className="tw-mr-2 tw-mt-2 tw-text-center"
        >
          <Box className="tw-pt-3">
            <Icon
              className="tw-opacity-20"
              sx={{ fontSize: '40px' }}
              icon="circle-question"
            />
          </Box>
          <Typography
            variant="caption"
            className="tw-opacity-20"
          >
            {rLIB('No image')}
          </Typography>
        </Box>
      )
    }
    return imageJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let headerJSX = (
      <Box className="tw-text-center tw-w-full">
        <Box
          className="tw-cursor-pointer tw-m-auto"
          component="img"
          sx={{ width: 189, height: 54 }}
          alt="logo"
          src={logoSrc2}
        />
        <Typography variant="h5">
          {rLIB('Requested Orders')} {rJSX_HeaderLocation()}
        </Typography>
        <Divider className="tw-my-3" />
      </Box>
    )
    return headerJSX
  }

  const rJSX_ErrorDisplay = (): JSX.Element => {
    let errorsJSX = <></>
    errorsJSX = (
      <Box
        className="tw-text-center tw-m-auto tw-rounded-lg tw-p-4"
        sx={{ maxWidth: '600px', border: '2px solid ' + themeVariables.error_main }}
      >
        <Typography
          variant="h5"
          sx={{ color: themeVariables.error_main }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
          />
          {rLIB('Failed to load order data')}
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: themeVariables.white }}
          className="tw-mt-2"
        >
          {rLIB('If this error persists please contact ETW Energy for support')}
        </Typography>
      </Box>
    )
    return errorsJSX
  }

  // const rJSX_DatePicker = (shrinkButton: boolean): JSX.Element => {
  //   let buttonJSX = (
  //     <Box className="tw-inline-block tw-align-top tw-mr-2">
  //       <DatePicker
  //         key={'calendar_date'}
  //         datePickerText={rLIB('Date')}
  //         datePickerDate={us_selectedDate}
  //         datePickerDisabled={false}
  //         datePickerDateOnChange={changeCalendarDate}
  //         datePickerSettings={{ thin_input: true }}
  //       />
  //     </Box>
  //   )
  //   return buttonJSX
  // }

  const rJSX_DatePicker = (): JSX.Element => {
    return (
      <Box className="tw-inline-flex tw-align-top tw-mr-2 tw-gap-2">
        <DatePicker
          key="calendar_date_start"
          datePickerText={rLIB('Start Date')}
          datePickerDate={new Date(us_weekStartDateKey)} // Convert "YYYY-MM-DD" to a Date object
          datePickerDisabled={false}
          datePickerDateOnChange={handleChangeStartDate}
          datePickerSettings={{ thin_input: true }}
        />
        <DatePicker
          key="calendar_date_end"
          datePickerText={rLIB('End Date')}
          datePickerDate={new Date(us_weekEndDateKey)}
          datePickerDisabled={false}
          datePickerDateOnChange={handleChangeEndDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
  }

  const rJSX_LocationFilterDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    dropdownJSX = (
      <Select
        autoWidth={true}
        onChange={(event, value) => {
          us_setSelectedLocationFilter(event.target.value)
          // changeIfConditionType(conditionalItem, event.target.value, saveFormulaChangeToDatabase, calculationData, nestedLogicPropPathArray)
        }}
        className="bp_thin_select_input"
        value={us_selectedLocationFilter || ''}
      >
        <MenuItem
          key={'ALL LOCATIONS'}
          value={'ALL LOCATIONS'}
        >
          {rLIB('All Locations')}
        </MenuItem>
        {objectToArray(us_supplierLocations)
          .sort(dynamicSort('name', null))
          .map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
            >
              {option['name']}
            </MenuItem>
          ))}
      </Select>
    )

    return dropdownJSX
  }

  const rJSX_OrdersForDay = (dateOrders: TsInterface_UnspecifiedObject): JSX.Element => {
    // Utility function to capitalize the delivery status
    const capitalizeStatus = (status: string): string => {
      return status.charAt(0).toUpperCase() + status.slice(1)
    }

    let dateOrdersJSX = <></>
    if (objectToArray(dateOrders).length === 0) {
      dateOrdersJSX = (
        <Box className="tw-opacity-30 tw-mb-3 tw-ml-8">
          <Typography variant="h6">{rLIB('No Orders')}</Typography>
        </Box>
      )
    } else {
      dateOrdersJSX = (
        <Box className="tw-mb-3 tw-ml-8">
          {objectToArray(dateOrders).map((order: TsInterface_UnspecifiedObject, index: number) => (
            <Box
              key={index}
              className="tw-mb-3"
            >
              <Button
                disabled={us_loadingOrderDetails}
                color={order.status === 'new' ? 'primary' : 'warning'}
                variant="outlined"
                className="tw-my-1 tw-cursor-pointer"
                onClick={() => {
                  us_setOrderToLoad(order.key)
                  openOrderDialog(order, order.key)
                }}
                startIcon={
                  us_orderToLoad === order.key && us_loadingOrderDetails ? (
                    <Icon
                      icon="arrows-rotate"
                      className="bp_spin"
                    />
                  ) : (
                    <Icon icon="cart-shopping" />
                  )
                }
              >
                <Box>
                  <Typography variant="h6">{order.po_number}</Typography>
                  <Typography
                    variant="body2"
                    className="tw-text-white tw-mt-1"
                  >
                    {`Delivery Status: ${capitalizeStatus(order.delivery_status || 'Staged')}`}
                  </Typography>
                </Box>
              </Button>
            </Box>
          ))}
        </Box>
      )
    }
    return dateOrdersJSX
  }

  const rJSX_OrdersTable = (): JSX.Element => {
    let tableJSX = (
      <Box>
        {objectToArray(us_ordersSortedData).map((date: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>
            <Typography variant="h6">
              {getDayOfWeek(date.date)} {date.date}
            </Typography>
            {rJSX_OrdersForDay(date.orders)}
          </Box>
        ))}
      </Box>
    )
    return tableJSX
  }

  const rJSX_UnscheduledOrdersTable = (): JSX.Element => {
    let tableJSX = (
      <Box>
        <Card>
          <TableBasic
            tableAdditionalData={{ openOrderDialog }}
            tableColumns={tableColumns_UnscheduledMaterialOrders}
            tableData={objectToArray(us_unscheduledOrdersList)}
            tableSettings={tableSettings_MaterialUnscheduledOrders}
          />
        </Card>
        {/* {objectToArray(us_unscheduledOrdersList).map((order: TsInterface_UnspecifiedObject, index: number) => (
          <Box
            key={index}
            className="tw-mb-3"
          >
            <Typography variant="h6">{order.po_number}</Typography>
            <Typography
              variant="body1"
              className="tw-opacity-40"
            >
              {order.associated_supplier_location_name || 'No Location'}
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              className="tw-my-1 tw-cursor-pointer"
              onClick={() => openOrderDialog(order, order.key)}
              startIcon={<Icon icon="cart-shopping" />}
            >
              View Order
            </Button>
          </Box>
        ))} */}
      </Box>
    )
    return tableJSX
  }

  const rJSX_ScheduledOrdersTab = (): JSX.Element => {
    let contentJSX = <></>

    if (us_dataLoadedStatus === 'loading') {
      contentJSX = (
        <Box className="tw-text-center">
          <CircularProgress />
        </Box>
      )
    } else if (us_dataLoadedStatus === 'success') {
      // Flatten the orders data
      const flattenedOrders = Object.values(us_ordersSortedData).flatMap((dateObj: any) => Object.values(dateObj.orders || {}))

      contentJSX = (
        <Box className="tw-p-4">
          <Box className="tw-mb-2">
            {rJSX_DatePicker()}
            {rJSX_LocationFilterDropdown()}
          </Box>
          <Card>
            <TableBasic
              tableAdditionalData={{ openOrderDialog }}
              tableColumns={tableColumns_ScheduledMaterialOrders}
              tableData={objectToArray(flattenedOrders)} // Pass the flattened orders data
              tableSettings={tableSettings_MaterialScheduledOrders}
            />
          </Card>

          {/* {rJSX_OrdersTable()} */}
        </Box>
      )
    } else if (us_dataLoadedStatus === 'error') {
      contentJSX = <Box className="tw-p-4">{rJSX_ErrorDisplay()}</Box>
    }

    return contentJSX
  }

  const rJSX_UnscheduledOrdersTab = (): JSX.Element => {
    let contentJSX = <></>
    if (us_dataLoadedStatus === 'loading') {
      contentJSX = (
        <Box className="tw-text-center">
          <CircularProgress />
        </Box>
      )
    } else if (us_dataLoadedStatus === 'success') {
      contentJSX = (
        <Box className="tw-p-4">
          <Box className="tw-mb-2">
            {/* {rJSX_DatePicker(false)}
            {rJSX_LocationFilterDropdown()} */}
          </Box>
          {rJSX_UnscheduledOrdersTable()}
        </Box>
      )
    } else if (us_dataLoadedStatus === 'error') {
      contentJSX = <Box className="tw-p-4">{rJSX_ErrorDisplay()}</Box>
    }
    return contentJSX
  }

  const rJSX_TotalOnHandTab = (): JSX.Element => {
    let contentJSX = <></>

    if (us_dataLoadedStatus === 'loading') {
      contentJSX = (
        <Box className="tw-text-center">
          <CircularProgress />
        </Box>
      )
    } else if (us_dataLoadedStatus === 'success') {
      // Aggregate SKUs
      const aggregatedSkus: {
        [key: string]: {
          name: string
          totalQuantity: number
          photoUrl: string | null
          uniqueIdentifier: string
        }
      } = {}

      Object.values(us_skus).forEach((skuList) => {
        Object.values(skuList).forEach((sku: any) => {
          const skuKey = sku.associated_sku_key
          if (!aggregatedSkus[skuKey]) {
            aggregatedSkus[skuKey] = {
              name: sku.associated_sku_name,
              totalQuantity: sku.associated_sku_pick_requested_quantity || 0,
              photoUrl: sku.associated_sku_estimated_photo_url || null,
              uniqueIdentifier: sku.associated_sku_unique_identifier || '',
            }
          } else {
            aggregatedSkus[skuKey].totalQuantity += sku.associated_sku_pick_requested_quantity || 0
          }
        })
      })

      contentJSX = (
        <Box className="tw-p-4">
          <Typography
            variant="h6"
            className="tw-mb-4"
          >
            Aggregate SKUs
          </Typography>
          <Divider className="tw-mb-2" />
          <Box>
            {Object.entries(aggregatedSkus)
              .sort((a, b) => {
                const identifierA = String(a[1].uniqueIdentifier || '') // Ensure string type
                const identifierB = String(b[1].uniqueIdentifier || '') // Ensure string type
                return identifierA.localeCompare(identifierB)
              })
              .map(([skuKey, skuData]) => (
                <Stack
                  direction="row"
                  key={skuKey}
                  className="tw-mb-4"
                >
                  {rJSX_MaterialSearchResultSkuItemImage(skuData.photoUrl)}
                  <Box sx={{ width: 'calc( 100% - 88px )' }}>
                    <Stack
                      direction="row"
                      className="tw-justify-between"
                    >
                      <Box sx={{ width: 'calc( 100% - 100px )' }}>
                        <Box>
                          <Typography
                            variant="body1"
                            className="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis"
                          >
                            {skuData.name}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          className="tw-opacity-40"
                        >
                          {skuData.uniqueIdentifier || 'Unknown Identifier'}
                        </Typography>
                        <Box>
                          {rLIB('Requested Quantity')}:
                          <Chip
                            className="tw-ml-2"
                            label={<Typography variant="body1">{skuData.totalQuantity}</Typography>}
                          />
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              ))}
          </Box>
        </Box>
      )
    } else if (us_dataLoadedStatus === 'error') {
      contentJSX = (
        <Box className="tw-p-4">
          <Typography
            variant="h6"
            color="error"
          >
            Failed to load data.
          </Typography>
        </Box>
      )
    }

    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box>
        {rJSX_PageHeader()}
        <TabsBasic
          tabs={[
            {
              tabHeader: 'Unscheduled Orders',
              tabContent: rJSX_UnscheduledOrdersTab(),
            },
            {
              tabHeader: 'Scheduled For Delivery',
              tabContent: rJSX_ScheduledOrdersTab(),
            },
            {
              tabHeader: 'Total On-Hand Requirement',
              tabContent: rJSX_TotalOnHandTab(),
            },
          ]}
          tabsSettings={{}}
        />
        {/* <Box>
          {rJSX_ScheduledOrdersTab()}
        </Box> */}
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
