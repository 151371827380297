///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Base
export const DatabaseRef_BuildingType_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'building_types', 'main')
}

export const DatabaseRef_BuildingType_Document = (clientKey: string, buildingTypeKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'building_types', 'main', buildingTypeKey)
}

export const DatabaseRef_ActiveBuildingTypes_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'building_types', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_BuildingType_ImportAliases_Document = (clientKey: string, buildingTypeKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'building_types', 'import_aliases', buildingTypeKey)
}

export const DatabaseRef_BuildingType_ImportAliases_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'building_types', 'import_aliases')
}

export const DatabaseRef_BuildingType_ImportAliases_Query = (clientKey: string, buildingTypeKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_master_data_key', comparator: '==', value: buildingTypeKey }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'building_types', 'import_aliases'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}
