///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material/'
import { globalTaskTypes, rJSX_TaskCategorySelector, rJSX_TaskTypeSelector } from 'app/models/tasks/global_tasks'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskBlueprint_Document } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskForms_Collection } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import {
  DatabaseRef_TaskWorkflowDelayReasons_Collection,
  DatabaseRef_TaskWorkflows_Collection,
} from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import { DatabaseAddDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { TaskLibraryAdditionalConfigTab } from './tabs/tab_task_additional_config'
import { TaskLibraryDelayReasonsTab } from './tabs/tab_task_delay_reasons'
import { TaskLibraryTaskFormsTab } from './tabs/tab_task_forms'
import { TaskLibraryTasksListTab } from './tabs/tab_task_library'
import { TaskLibraryTaskOutcomesTab } from './tabs/tab_task_outcomes'
import { TaskLibraryTaskWorkflowsTab } from './tabs/tab_task_workflows'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseTasksListPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_taskType, us_setTaskType] = useState<string>('')
  const [us_taskCategory, us_setTaskCategory] = useState<string>('project')
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const un_routerNavigation = useNavigate()

  // Hooks - useEffect

  useEffect(() => {
    document.title = rLIB('Task Library', false) as string
  }, [])

  useEffect(() => {
    let taskTypesArray = []
    try {
      taskTypesArray = objectToArray(globalTaskTypes).filter((taskType: TsInterface_UnspecifiedObject) => taskType.task_category === 'project')
    } catch (error) {
      console.log('error', error)
    }
    if (taskTypesArray.length > 0) {
      us_setTaskType(taskTypesArray[0].key)
    }
  }, [])

  // Other Variables
  const newTaskPromptObject: TsInterface_PromptDialogObject = {
    color: 'success',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: (
      <>
        {rLIB('Create new')} {getProp(globalTaskTypes[us_taskType], 'name', us_taskType)} {rLIB('task')}
      </>
    ),
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: rLIB('Task Name') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new task'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let newTaskKey = us_taskType + '_' + keyFromString(promptValue) + '_' + new Date().getTime().toString()
        let updateObject = {
          name: promptValue,
          status: 'active',
          task_type: us_taskType,
          key: newTaskKey,
          additional_work_task: getProp(globalTaskTypes[us_taskType], 'additional_work_task', false),
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, newTaskKey), updateObject)
              .then((res_DAD) => {
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  const newTaskFormPromptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Form') as JSX.Element,
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: rLIB('Form Name') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new task form'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          status: 'active',
          task_category: us_taskCategory,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskForms_Collection(res_GCK.clientKey), updateObject, true)
              .then((res_DAD) => {
                directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseTaskFormsViewPage.url(res_DAD.key))
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  const newWorkflowPromptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Workflow'),
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: rLIB('Workflow Name') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new workflow'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          workflow_version_number: 2,
          status: 'active',
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskWorkflows_Collection(res_GCK.clientKey), updateObject, true)
              .then((res_DAD) => {
                directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseTaskWorkflowViewPage.url(res_DAD.key))
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  const delayReasonPromptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Delay Reason'),
    icon: (
      <Icon
        icon="pause"
        type="solid"
      />
    ),
    input_label: rLIB('Delay Reason Name') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new delay reason'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskWorkflowDelayReasons_Collection(res_GCK.clientKey), updateObject, true)
              .then((res_DAD) => {
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  // Functions

  // JSX Generation
  const rJSX_NewTaskFormButton = (): JSX.Element => {
    let newTaskFormButtonJSX = <></>
    newTaskFormButtonJSX = (
      <Button
        color="success"
        variant="contained"
        className="tw-mr-2"
        disabled={us_taskType == null || us_taskType === ''}
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: newTaskFormPromptObject,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Task Form')}
      </Button>
    )
    return newTaskFormButtonJSX
  }

  const rJSX_NewTaskButton = (minimize: boolean = false): JSX.Element => {
    let newTaskButtonJSX = <></>
    newTaskButtonJSX = (
      <Button
        color="success"
        variant="contained"
        className="tw-mr-2"
        disabled={us_taskType == null || us_taskType === ''}
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: newTaskPromptObject,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Task')}
      </Button>
    )
    return newTaskButtonJSX
  }

  const rJSX_NewDelayReasonButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: delayReasonPromptObject,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Delay Reason')}
      </Button>
    )
  }
  const rJSX_NewWorkflowButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: newWorkflowPromptObject,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Task Workflow')}
      </Button>
    )
  }

  const rJSX_NewOutcomeButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        disabled={true}
        onClick={() => {
          // TODO: Implement
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Task Outcome')}
      </Button>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Task Library')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Task Library',
                  tabHeader: rLIB('Task Library'),
                  tabButtons: [
                    {
                      fullJSX: rJSX_TaskCategorySelector(us_taskCategory, us_setTaskCategory, us_setTaskType),
                      minJSX: rJSX_TaskCategorySelector(us_taskCategory, us_setTaskCategory, us_setTaskType),
                      sizeCutoff: 0,
                    },
                    {
                      fullJSX: rJSX_TaskTypeSelector(us_taskType, us_setTaskType, 'all', us_taskCategory),
                      minJSX: rJSX_TaskTypeSelector(us_taskType, us_setTaskType, 'all', us_taskCategory),
                      sizeCutoff: 0,
                    },
                    { fullJSX: rJSX_NewTaskButton(false), minJSX: rJSX_NewTaskButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: (
                    <TaskLibraryTasksListTab
                      us_taskType={us_taskType}
                      us_taskCategory={us_taskCategory}
                    />
                  ),
                },
                {
                  tabUrlKey: 'Task Forms',
                  tabHeader: rLIB('Task Forms'),
                  tabButtons: [
                    {
                      fullJSX: rJSX_TaskCategorySelector(us_taskCategory, us_setTaskCategory, us_setTaskType),
                      minJSX: rJSX_TaskCategorySelector(us_taskCategory, us_setTaskCategory, us_setTaskType),
                      sizeCutoff: 0,
                    },
                    { fullJSX: rJSX_NewTaskFormButton(), minJSX: rJSX_NewTaskFormButton(), sizeCutoff: 0 },
                  ],
                  tabContent: <TaskLibraryTaskFormsTab us_taskCategory={us_taskCategory} />,
                },
                {
                  tabUrlKey: 'Outcomes',
                  tabHeader: rLIB('Outcomes'),
                  tabButtons: [
                    {
                      fullJSX: rJSX_NewOutcomeButton(),
                      minJSX: rJSX_NewOutcomeButton(),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: <TaskLibraryTaskOutcomesTab />,
                },
                {
                  tabUrlKey: 'Workflows',
                  tabHeader: rLIB('Workflows'),
                  tabButtons: [
                    {
                      fullJSX: rJSX_NewWorkflowButton(),
                      minJSX: rJSX_NewWorkflowButton(),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: <TaskLibraryTaskWorkflowsTab />,
                },
                {
                  tabUrlKey: 'Delay Reasons',
                  tabHeader: rLIB('Delay Reasons'),
                  tabButtons: [
                    {
                      fullJSX: rJSX_NewDelayReasonButton(),
                      minJSX: rJSX_NewDelayReasonButton(),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: <TaskLibraryDelayReasonsTab />,
                },
                {
                  tabUrlKey: 'Additional_Config',
                  tabHeader: rLIB('Additional Config'),
                  tabButtons: [
                    // {
                    //   fullJSX: rJSX_TaskCategorySelector(us_taskCategory, us_setTaskCategory, us_setTaskType),
                    //   minJSX: rJSX_TaskCategorySelector(us_taskCategory, us_setTaskCategory, us_setTaskType),
                    //   sizeCutoff: 0,
                    // },
                    // {
                    //   fullJSX: rJSX_TaskTypeSelector(us_taskType, us_setTaskType, 'all', us_taskCategory),
                    //   minJSX: rJSX_TaskTypeSelector(us_taskType, us_setTaskType, 'all', us_taskCategory),
                    //   sizeCutoff: 0,
                    // },
                  ],
                  tabContent: <TaskLibraryAdditionalConfigTab />,
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseTasksListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Task Library', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
