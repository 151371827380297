///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_SkuLineItems_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'materials', 'sku_line_items', 'main')
}

export const DatabaseRef_SkuLineItem_Document = (clientKey: string, skuItemKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'sku_line_items', 'main', skuItemKey)
}

export const DatabaseRef_SkuLineItemsOnOrder_Query = (clientKey: string, orderKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_order_key', comparator: '==', value: orderKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'sku_line_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_SkuLineItemsForUser_DateRange_Query = (
  clientKey: string,
  userKey: string,
  startDateKey: string,
  endDateKey: string,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_user_key', comparator: '==', value: userKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '>=', value: startDateKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '<=', value: endDateKey },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_scheduled_pickup_date_key', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'sku_line_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_SkuLineItemsForSupplier_DateRage_Query = (
  clientKey: string,
  supplierKey: string,
  startDateKey: string,
  endDateKey: string,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_supplier_key', comparator: '==', value: supplierKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '>=', value: startDateKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '<=', value: endDateKey },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_scheduled_pickup_date_key', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'sku_line_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_SkuLineItemsForProject_DateRage_Query = (
  clientKey: string,
  projectKey: string,
  startDateKey: string,
  endDateKey: string,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '>=', value: startDateKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '<=', value: endDateKey },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_scheduled_pickup_date_key', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'sku_line_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_SkuLineItemsByProject_Query = (clientKey: string, projectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'materials', 'sku_line_items', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}
