import { Alert, Box, Button, CircularProgress, Link, MenuItem, Select, Snackbar, styled, Switch, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { ChangeEvent, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseAddDocument, DatabaseGetDocument, StorageUploadFile } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { TsInterface_SalesOpportunityV2 } from '../sales_tools/interfaces/i_sales_tools'
import { cashDisclosureForm, generateFormName, leaseDisclosureForm, stateUtilityMap } from './abp_helpers'
import { DatabaseRef_ABPApplicationFee_Document, DatabaseRef_ABPDegradation_Document, DatabaseRef_ABPForm_Collection, StorageRef_ABPProposal } from './database'
import {
  TsInterface_CashDisclosureForm,
  TsInterface_DynamicFormInput,
  TsInterface_FormSubmissionBundle,
  TsInterface_LeaseDisclosureForm,
  TsInterface_RecGroupValueMap,
  TsInterface_ValidCompanyNameURLs,
} from './interfaces/i_abp_disclosure_forms'

type Action =
  | { type: 'CHANGE_INPUT'; field: string; value: string; input: TsInterface_DynamicFormInput }
  | { type: 'RESET_FORM' }
  | { type: 'LOADED_PRESETS'; presets: TsInterface_UnspecifiedObject }
  | { type: 'CHANGE_PRESET_STATUS'; field: string; value: boolean }

type ABPFormType = 'LEASE' | 'PURCHASE'

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '800px',
  margin: '0 auto',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}))

const StyledSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledFormControl = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 'bold',
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  'width': '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.light,
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  'width': '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.light,
    },
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const Container = (): JSX.Element => {
  return <StyledContainer>{rJSX_PageContent()}</StyledContainer>
}

export const validateSalesOrgInfo = (salesOrg: string, salesRepName: string, salesRepEmail: string, salesRepPhone: string, isForPreset: boolean = false) => {
  // when saving a preset, we do not need the rep info, just the sales org
  if (isForPreset) {
    if (!salesOrg) {
      return false
    } else {
      return true
    }
  }

  // for the form, we need all the rep info in addition to the sales org
  if (salesOrg === '' || salesRepName === '' || salesRepEmail === '' || salesRepPhone === '') {
    return false
  }
  return true
}

export const validateFormData = (
  form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm,
  formSubmissionBundle: TsInterface_FormSubmissionBundle,
  forPreset: boolean = false,
) => {
  if (formSubmissionBundle.us_utilityGroupIsInvalid) {
    formSubmissionBundle.us_setValidationsPassed(false)
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: <Box>Utility group not found. Rec value cannot be calculated.</Box>,
      severity: 'error',
    })
    return false
  }
  for (const [key, value] of Object.entries(form)) {
    if (value.validation_check) {
      // when submitting a form preset, we should not have to validate customer information...this will only
      // be true for admins as only admins can create presets
      if (forPreset) {
        if (key.toLowerCase().includes('customer')) {
          continue
        }
      }

      if (!value.validation_check(value.value)) {
        formSubmissionBundle.us_setValidationsPassed(false)
        formSubmissionBundle.us_setSnackbar({
          open: true,
          message: <Box>Validation check failed for field "{value.label}".</Box>,
          severity: 'error',
        })
        return false
      }
    }
  }
  formSubmissionBundle.us_setValidationsPassed(true)
  return true
}

const getRECSizeBracket = (size: number) => {
  if (size <= 10) {
    return '<=10'
  } else if (size <= 25) {
    return '10-25'
  } else {
    return '>25'
  }
}

const getRECGroup = (form: TsInterface_CashDisclosureForm | TsInterface_LeaseDisclosureForm, us_setSnackbar: any): string => {
  try {
    if (form['electric_utility'].value === 'Municipal Utility' || form['electric_utility'].value === 'Rural Electric Cooperative') {
      const utilityName = form['muni_coop_name'].value
      const group = stateUtilityMap[getProp(form, 'customer_address_state', '').value][utilityName]?.group
      if (group) {
        return group
      } else {
        return ''
      }
    } else {
      for (const [key, value] of Object.entries(stateUtilityMap[getProp(form, 'customer_address_state', '').value])) {
        if (value.mappedUtility === form['electric_utility'].value) {
          // check to ensure our lookup is correct
          return value.group
        }
      }
    }
    return ''
  } catch (err: any) {
    console.error('ERROR GETTING REC GROUP', err)
    const utilityNames = Object.keys(stateUtilityMap[getProp(form, 'customer_address_state', '').value]).join(', ')
    us_setSnackbar({
      open: true,
      message: (
        <Box>
          Error getting REC group, ensure that you have the correct utility name. Possible names include: {utilityNames}. Details: {err}
        </Box>
      ),
      severity: 'error',
    })
    return ''
  }
}

const getExpectedRecValue = (
  form: TsInterface_CashDisclosureForm | TsInterface_LeaseDisclosureForm,
  inverterEfficiency: number,
  us_setSnackbar: any,
  us_recGroupValues: TsInterface_RecGroupValueMap,
  us_setUtilityGroupIsInvalid: any,
): number => {
  let project_size_kw_ac = 0

  // safety check in case we have not yet updated the project size in ac
  if (form['project_size_kw_ac'].value === 0) {
    project_size_kw_ac = form['project_size_kw_dc'].value * inverterEfficiency
  } else {
    project_size_kw_ac = form['project_size_kw_ac'].value
  }

  if (project_size_kw_ac < 0 || project_size_kw_ac > 100) {
    us_setSnackbar({
      open: true,
      message: <Box>Project AC size is less than 0 kW or greater than 100 kW</Box>,
      severity: 'error',
    })
  }

  const recGroup = getRECGroup(form, us_setSnackbar)

  if (recGroup === '') {
    us_setUtilityGroupIsInvalid(true)
    us_setSnackbar({
      open: true,
      message: <Box>Utility group not found. Rec value cannot be calculated.</Box>,
      severity: 'error',
    })
    return 0
  }

  const sizeBracket = getRECSizeBracket(project_size_kw_ac) // should be AC watts

  // interface is designed to accommodate multiple groups, but there should only be an A group and a B group
  // TODO: these values change every year, can be manually changed in the sales tools on EOS
  // ^ the backend is already wired up, just need a UI change to allow for this
  if (recGroup === 'A') {
    if (sizeBracket === '<=10') {
      // return 73.71
      return us_recGroupValues['A']['<=10']
    } else if (sizeBracket === '10-25') {
      // return 63.53
      return us_recGroupValues['A']['10-25']
    } else {
      // return 55.89
      return us_recGroupValues['A']['>25']
    }
  } else {
    if (sizeBracket === '<=10') {
      // return 83.87
      return us_recGroupValues['B']['<=10']
    } else if (sizeBracket === '10-25') {
      // return 77.53
      return us_recGroupValues['B']['10-25']
    } else {
      // return 70.23
      return us_recGroupValues['B']['>25']
    }
  }
}

const calculateNumExpectedRecPayments = (grossElectricProduction: number, degradation: number): number => {
  return Math.floor(grossElectricProduction * ((1 - (1 - degradation) ** 15) / degradation / 1000)) // based on Raphael's excel formula in the input sheet
}

const convertDCtoAC = (dcWatts: number, inverterEfficiency: number): number => {
  // define the DC and AC value mappings
  const dcThresholds: number[] = [0.0, 5.3, 10.6, 16.0, 21.3, 26.6, 31.9, 37.2, 42.6]
  const acValues: number[] = [3.8, 7.6, 11.4, 15.2, 19.0, 22.8, 26.6, 30.4, 34.2]

  // find the last index where the DC threshold is less than or equal to input
  const index = dcThresholds.reduce((acc, threshold, idx) => (dcWatts >= threshold ? idx : acc), -1)

  // if no threshold is found (input is smaller than all thresholds), return the lowest AC value
  if (index === -1) {
    return acValues[0]
  }

  // return the corresponding AC value
  return acValues[index]
}

const getRecValue = (
  form: TsInterface_CashDisclosureForm | TsInterface_LeaseDisclosureForm,
  formSubmissionBundle: TsInterface_FormSubmissionBundle,
  inverterEfficiency: number,
  degradation: number,
) => {
  const grossElectricProduction = Number(form['gross_electric_production'].value)
  return (
    calculateNumExpectedRecPayments(grossElectricProduction, degradation) * // calculate the number of expected rec payments
    getExpectedRecValue(
      form,
      inverterEfficiency,
      formSubmissionBundle.us_setSnackbar,
      formSubmissionBundle.us_recGroupValues,
      formSubmissionBundle.us_setUtilityGroupIsInvalid,
    )
  )
}

export const precalculatePurchaseFormFields = (
  form: TsInterface_CashDisclosureForm,
  formSubmissionBundle: TsInterface_FormSubmissionBundle,
  degradation: number,
  applicationFeeCoefficient: number,
  batterySize: number = 13.5, // TODO: this will eventually be another parameter we need to supportd
  inverterEfficiency: number = 0.71428571428,
  recPayoutAfterFeesPercentage: number = 0.85,
): TsInterface_CashDisclosureForm => {
  const numBatteries = Number(form['include_battery'].value)
  const feePercentage = 0.05

  try {
    for (const [key, value] of Object.entries(form)) {
      switch (key) {
        // case 'final_amount_owed': REMOVED BECAUSE IT IS NOT A SUM TOTAL BUT ANOTHER PAYMENT
        //   form[key as keyof TsInterface_CashDisclosureForm].value = Number(
        //     Number(form['initial_deposit_owed'].value) + Number(form['installation_owed'].value),
        //   ).toFixed(2)
        //   break
        case 'project_size_kw_ac':
          form[key as keyof TsInterface_CashDisclosureForm].value = convertDCtoAC(Number(form['project_size_kw_dc'].value), inverterEfficiency).toFixed(1) // step function, always increase in steps of 9.6 (buckets)
          break
        case 'expected_rec_value':
          form[key as keyof TsInterface_CashDisclosureForm].value = Number(getRecValue(form, formSubmissionBundle, inverterEfficiency, degradation)).toFixed(2)
          break
        case 'rec_customer_payment':
          form[key as keyof TsInterface_CashDisclosureForm].value = (
            Number(getRecValue(form, formSubmissionBundle, inverterEfficiency, degradation).toFixed(2)) * recPayoutAfterFeesPercentage
          ).toFixed(2)
          break
        case 'form_name':
          form[key as keyof TsInterface_CashDisclosureForm].value = generateFormName(formSubmissionBundle.us_companyNameURL, form)
          break
        case 'collateral_fee_amount':
          form[key as keyof TsInterface_CashDisclosureForm].value = (
            Number(getRecValue(form, formSubmissionBundle, inverterEfficiency, degradation).toFixed(2)) *
            recPayoutAfterFeesPercentage *
            feePercentage
          ).toFixed(2)
          break
        case 'fee_amount_1':
          if (form['fee_name_1'].value !== 'Application Fee') {
            // TODO: maybe change this from being hard-coded so if we change the name of the fee it changes? Unlikely to change though
            break
          }
          form[key as keyof TsInterface_CashDisclosureForm].value = (
            applicationFeeCoefficient * convertDCtoAC(Number(form['project_size_kw_dc'].value), inverterEfficiency)
          ).toFixed(2)
          break
        case 'battery_size':
          form[key as keyof TsInterface_CashDisclosureForm].value = (batterySize * numBatteries).toFixed(2)
          break
        case 'include_battery':
          form[key as keyof TsInterface_CashDisclosureForm].value = numBatteries > 0 ? 'Yes' : 'No' // hacky because the form field is written as a boolean, but it is actually yes/no based on whether there are batteries
          break
        default:
          break
      }
    }
  } catch (err: any) {
    console.error('ERROR PRECALCULATING PURCHASE FORM FIELDS', err)
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: <Box>Error precalculating fields, details: {err}</Box>,
      severity: 'error',
    })
  }

  return form
}

export const precalculateLeaseFormFields = (
  form: TsInterface_LeaseDisclosureForm,
  formSubmissionBundle: TsInterface_FormSubmissionBundle,
  degradation: number,
  batterySize: number = 13.5,
  inverterEfficiency: number = 0.71428571428,
): TsInterface_LeaseDisclosureForm => {
  const numBatteries = Number(form['include_battery'].value)
  const initialAmount = Number(form['initial_amount_due'].value)
  const termYears = Number(form['term_years'].value)
  const initialPayment = Number(form['initial_amount_due'].value)
  const escRate = Number(form['escalation_rate'].value) / 100 // put in decimal form
  const years = Number(form['term_years'].value)

  // sum up payments for each year
  let totalPayments = 0

  try {
    for (const [key, value] of Object.entries(form)) {
      switch (key) {
        case 'project_size_kw_ac':
          form[key as keyof TsInterface_LeaseDisclosureForm].value = convertDCtoAC(Number(form['project_size_kw_dc'].value), inverterEfficiency).toFixed(1) // step function, always increase in steps of 9.6 (buckets)
          break
        case 'form_name':
          form[key as keyof TsInterface_LeaseDisclosureForm].value = generateFormName(formSubmissionBundle.us_companyNameURL, form)
          break
        case 'battery_size':
          form[key as keyof TsInterface_LeaseDisclosureForm].value = (batterySize * numBatteries).toFixed(2)
          break
        case 'include_battery':
          form[key as keyof TsInterface_LeaseDisclosureForm].value = numBatteries > 0 ? 'Yes' : 'No' // hacky because the form field is written as a boolean, but it is actually yes/no based on whether there are batteries
          break
        case 'expected_rec_value':
          form[key as keyof TsInterface_LeaseDisclosureForm].value = getRecValue(form, formSubmissionBundle, inverterEfficiency, degradation).toFixed(2)
          break
        case 'final_amount_due':
          form[key as keyof TsInterface_LeaseDisclosureForm].value = ((initialAmount * 12 * Math.pow(1 + escRate, termYears - 1)) / 12).toFixed(2)
          break
        case 'total_payments_amount':
          for (let year = 0; year < years; year++) {
            // calculate 12 monthly payments for this year
            for (let month = 0; month < 12; month++) {
              // use the same yearly escalation rate, but apply it to all 12 monthly payments
              const monthlyPayment = initialPayment * Math.pow(1 + escRate, year)
              totalPayments += monthlyPayment
            }
          }
          form[key as keyof TsInterface_LeaseDisclosureForm].value = totalPayments.toFixed(2)
          break
        default:
          break
      }
    }
  } catch (err: any) {
    console.error('ERROR PRECALCULATING LEASE FORM FIELDS')
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: <Box>Error precalculating fields, details: {err}</Box>,
      severity: 'error',
    })
  }

  return form
}

const validateURL = (formSubmissionBundle: any): boolean => {
  // this is valid because it updates the preset
  if (!formSubmissionBundle.us_newFormURL) {
    return true
  }

  try {
    // check to see if the url is properly formatted
    if (
      formSubmissionBundle.us_newFormURL?.split('_')[formSubmissionBundle.us_newFormURL?.split('_').length - 1] !== 'purchase' &&
      formSubmissionBundle.us_newFormURL?.split('_')[formSubmissionBundle.us_newFormURL?.split('_').length - 1] !== 'lease'
    ) {
      formSubmissionBundle.us_setSnackbar({
        open: true,
        message: (
          <Box>
            URL Error:{' '}
            {
              'URL is not valid, it must include the lowercase company name and end with  "_purchase" or "_lease". The company name may be split by "_" or it may be one word.'
            }
          </Box>
        ),
        severity: 'error',
      })
      return false
    }

    // check to see if the url is already being used
    if (formSubmissionBundle.us_newFormURL in formSubmissionBundle.us_validCompanyURLs) {
      formSubmissionBundle.us_setSnackbar({
        open: true,
        message: <Box>URL Error: {'URL is already in use.'}</Box>,
        severity: 'error',
      })
      return false
    }

    return true
  } catch (err: any) {
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: <Box>Error saving preset, details: {err}</Box>,
      severity: 'error',
    })

    return false
  }
}

export const handleSavePreset = (formSubmissionBundle: TsInterface_FormSubmissionBundle) => {
  try {
    let form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm

    let newPreset = false

    if (formSubmissionBundle.us_newFormURL) {
      newPreset = true
    }

    if (formSubmissionBundle.us_formType === 'LEASE') {
      form = formSubmissionBundle.ur_leaseForm
    } else {
      form = formSubmissionBundle.ur_cashForm
    }

    const numBatteries = form['include_battery'].value

    // calculate precalculated fields for purchase form
    if (formSubmissionBundle.us_formType === 'LEASE') {
      form = precalculateLeaseFormFields(form as TsInterface_LeaseDisclosureForm, formSubmissionBundle, formSubmissionBundle.us_degradation)
    } else {
      form = precalculatePurchaseFormFields(
        form as TsInterface_CashDisclosureForm,
        formSubmissionBundle,
        formSubmissionBundle.us_degradation,
        formSubmissionBundle.us_applicationFee,
      )
    }

    let formData: any = {}

    // validate sales org info
    if (
      !validateSalesOrgInfo(
        formSubmissionBundle.us_repOrg,
        formSubmissionBundle.us_repName as string,
        formSubmissionBundle.us_repEmail as string,
        formSubmissionBundle.us_repPhone as string,
        true,
      )
    ) {
      formSubmissionBundle.us_setSnackbar({
        open: true,
        message: <Box>Sales Org is required.</Box>,
        severity: 'error',
      })
      form['include_battery'].value = numBatteries // resetting this value because the API requires a boolean, but the form field is a number
      return
    }

    // validate form data
    if (!validateFormData(form, formSubmissionBundle, formSubmissionBundle.us_isAdmin)) {
      form['include_battery'].value = numBatteries // resetting this value because the API requires a boolean, but the form field is a number
      return
    }

    // validate url if a new preset is being created
    if (!validateURL(formSubmissionBundle)) {
      form['include_battery'].value = numBatteries // resetting this value because the API requires a boolean, but the form field is a number
      return
    }

    // add the sales org to the form data
    formData['sales_org'] = formSubmissionBundle.us_repOrg

    // reduce the form to a simple object for the api
    for (const [key, value] of Object.entries(form)) {
      if (newPreset) {
        if (key == 'preset_name' || key == 'preset_url' || key == 'sales_org' || key == 'timestamp_created' || key == 'timestamp_updated' || key == 'key') {
          continue
        }
      }
      formData[key] = value.value
    }

    // return a list of keys of entries in the savedPresets object where the is_preset field is true
    const presetList = Object.entries(form)
      .map(([key, value]) => {
        if (value.is_preset) {
          return key
        }
      })
      .filter((key) => key !== undefined)

    // @ts-ignore, hacky fix to restore the include_battery field because it's a numbered input but the API is poorly named
    form['include_battery'].value = numBatteries

    cloudFunctionUnauthenticatedRequests({
      function: 'saveABPDisclosureFormPreset',
      form_data: formData,
      preset_fields: presetList,
      new_form_url: formSubmissionBundle.us_newFormURL,
    })
      .then((res: any) => {
        if (res.data.success) {
          formSubmissionBundle.us_setSnackbar({
            open: true,
            message: <Box>Preset saved successfully!</Box>,
            severity: 'success',
          })
        }
      })
      .catch((err: any) => {
        formSubmissionBundle.us_setSnackbar({
          open: true,
          message: <Box>Error saving preset, details: {err}</Box>,
          severity: 'error',
        })
      })
  } catch (err: any) {
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: <Box>Error saving preset, details: {err}</Box>,
      severity: 'error',
    })
  }
}

export const createNewSalesOpportunity = (
  form: any, // going to use the reduced, precalculated form data
  formSubmissionBundle: TsInterface_FormSubmissionBundle,
): Promise<unknown> => {
  try {
    const salesOpportunity: TsInterface_SalesOpportunityV2 = {
      associated_customer_email: form['customer_address_email'],
      associated_customer_first_name: form['customer_name'].split(' ')[0],
      associated_customer_last_name: form['customer_name'].length > 1 ? form['customer_name'].split(' ')[1] : '', // in case for some reason there is only one name
      associated_customer_name: form['customer_name'],
      associated_customer_phone: form['customer_address_phone'],
      // associated_user_key: '',
      associated_utility_company_name: form['electric_utility'],
      // home_roof_quality: false,
      // home_roof_type: '',
      location_address: form['customer_address_1'],
      location_city: form['customer_address_city'],
      // location_latitude: 0,
      // location_longitude: 0,
      location_state: form['customer_address_state'],
      location_zip: form['customer_address_zip'],
      product_type: 'Solar',
      solarSystemData: {
        cash_savings: 0,
        financials_cash_base_price: 0,
        financials_cash_price_after_incentives: 0,
        financials_federal_incentive: 0,
        financials_lease_monthly_payment: 0,
        financials_lease_monthly_savings: 0,
        financials_lease_year_one_savings: 0,
        financials_srec_incentive: 0,
        panel_quantity: 0,
        selected_system_size: form['project_size_kw_dc'],
        system_estimated_annual_production: form['gross_electric_production'],
        system_monthly_electricity_bill: 0,
        system_usage_offset: 0,
      },
      status: 'unassigned',
      timestamp_created: new Date().toISOString(),
      associated_sales_rep_name: formSubmissionBundle.us_repName,
      associated_sales_rep_phone: formSubmissionBundle.us_repPhone,
      associated_sales_rep_email: formSubmissionBundle.us_repEmail,
      associated_sales_partner_name: formSubmissionBundle.us_repOrg,
    }

    return new Promise((resolve, reject) => {
      cloudFunctionUnauthenticatedRequests({
        function: 'createNewSalesOpportunity',
        opportunity_data: salesOpportunity,
      })
        .then((res: any) => {
          resolve(res)
        })
        .catch((err: any) => {
          console.error('ERROR CREATING NEW SALES OPPORTUNITY', err)
          reject(err)
        })
    })
  } catch (err: any) {
    console.error('ERROR CREATING NEW SALES OPPORTUNITY', err)
    return Promise.reject(err)
  }
}

export const handleFormSubmit = (formSubmissionBundle: TsInterface_FormSubmissionBundle) => {
  try {
    let form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm
    if (formSubmissionBundle.us_formType === 'LEASE') {
      form = formSubmissionBundle.ur_leaseForm
    } else {
      form = formSubmissionBundle.ur_cashForm
    }

    const numBatteries = form['include_battery'].value

    // calculate precalculated fields for purchase form
    if (formSubmissionBundle.us_formType === 'LEASE') {
      form = precalculateLeaseFormFields(form as TsInterface_LeaseDisclosureForm, formSubmissionBundle, formSubmissionBundle.us_degradation)
    } else {
      form = precalculatePurchaseFormFields(
        form as TsInterface_CashDisclosureForm,
        formSubmissionBundle,
        formSubmissionBundle.us_degradation,
        formSubmissionBundle.us_applicationFee,
      )
    }

    let formData: any = {}

    // validate sales org info
    if (
      !validateSalesOrgInfo(
        formSubmissionBundle.us_repOrg,
        formSubmissionBundle.us_repName as string,
        formSubmissionBundle.us_repEmail as string,
        formSubmissionBundle.us_repPhone as string,
      )
    ) {
      form['include_battery'].value = numBatteries
      return
    }

    // validate form data
    if (!validateFormData(form, formSubmissionBundle, formSubmissionBundle.us_isAdmin)) {
      form['include_battery'].value = numBatteries
      return
    }

    // ensure there is a sales rep org name, this is required for saving presets
    if (!formSubmissionBundle.us_repOrg) {
      formSubmissionBundle.us_setSnackbar({
        open: true,
        message: <Box>Sales rep org is required.</Box>,
        severity: 'error',
      })
      form['include_battery'].value = numBatteries
      return
    }

    // reduce the form to a simple object for the api
    for (const [key, value] of Object.entries(form)) {
      formData[key] = value.value
    }

    // @ts-ignore, hacky fix to restore the include_battery field because it's a weird input
    form['include_battery'].value = numBatteries

    // submit a form to the backend
    cloudFunctionUnauthenticatedRequests({
      function: 'createDisclosureForm',
      form_data: formData,
      form_type: formData['form_type'],
    })
      .then((res: any) => {
        if (res.data.success && formSubmissionBundle.us_repEmail) {
          // save the form to the database
          console.log(formSubmissionBundle.us_proposalURL)
          DatabaseAddDocument(
            DatabaseRef_ABPForm_Collection('etw_energy'),
            {
              ...formData,
              form_name: res.data.form_name ? res.data.form_name : formData['form_name'],
              form_status: res.data.FormStatus,
              sales_org: formSubmissionBundle.us_repOrg,
              proposal_url: formSubmissionBundle.us_proposalURL ? formSubmissionBundle.us_proposalURL : '',
              form_generation_url: formSubmissionBundle.us_companyNameURL,
              timestamp_created: new Date(),
              form_id: res.data.FormID,
              status_link: `https://etwenergy.com/a/abp_disclosure/${formSubmissionBundle.us_companyNameURL}/status/${res.data.FormID}`,
            },
            true,
          )
            .then(() => {
              // send the sales rep an email
              cloudFunctionUnauthenticatedRequests({
                function: 'sendSendgridEmail',
                to_array: [formSubmissionBundle.us_repEmail],
                cc_array: [],
                bcc_array: [],
                subject: `Disclosure Form Created - ${res.data.form_name ? res.data.form_name : formData['form_name']}`,
                body: `A disclosure form has been successfully created by ${formSubmissionBundle.us_repName} for ${res.data.form_name ? res.data.form_name : formData['form_name']}. \nThe form ID is ${res.data.FormID}. \nThe form status is "${res.data.FormStatus}". \nThe reference number is ${res.data.reference_number}. \n\nHere is the link where you can view the form status: https://etwenergy.com/a/abp_disclosure/${formSubmissionBundle.us_companyNameURL}/status/${res.data.FormID}`,
              })
                .then(() => {
                  // create sales opportunity
                  createNewSalesOpportunity(formData, formSubmissionBundle)
                    .then(() => {
                      formSubmissionBundle.us_setSnackbar((previousSnackbar: any) => ({
                        ...previousSnackbar,
                        message: (
                          <Box>
                            {previousSnackbar.message}
                            <br />
                            <br />
                            Sales opportunity created successfully!
                          </Box>
                        ),
                      }))
                    })
                    .catch((err: any) => {
                      console.error('ERROR CREATING NEW SALES OPPORTUNITY', err)
                    })
                })
                .catch((err) => {
                  formSubmissionBundle.us_setSnackbar({
                    open: true,
                    message: (
                      <Box>
                        Error sending email to sales rep.
                        <br />
                        Message: {err.response.data.message}
                        <br />
                        Details: {err.response.data.details}
                      </Box>
                    ),
                    severity: 'error',
                  })
                })
            })
            .catch((err: any) => {
              formSubmissionBundle.us_setSnackbar({
                open: true,
                message: (
                  <Box>
                    Error saving form to database.
                    <br />
                    Message: {err.response.data.message}
                    <br />
                    Details: {err.response.data.details}
                  </Box>
                ),
                severity: 'error',
              })
            })
        }

        formSubmissionBundle.us_setSnackbar({
          open: true,
          message: (
            <Box>
              Form submitted successfully!
              <br />
              <br />
              {Object.entries(res.data).map(([key, value]) => (
                <Box key={key}>
                  <Typography variant="body1">
                    {key}: {String(value)}
                  </Typography>
                </Box>
              ))}
            </Box>
          ),
          severity: 'success',
        })
      })
      .catch((err) => {
        console.error(err)
        formSubmissionBundle.us_setSnackbar({
          open: true,
          message: (
            <Box>
              Error submitting form. Please try again. Remember to check below each field for that field's instructions.
              <br />
              Details: {err.response.data.message}
            </Box>
          ),
          severity: 'error',
        })
      })
  } catch (err: any) {
    formSubmissionBundle.us_setSnackbar({
      open: true,
      message: (
        <Box>
          Error submitting form. This is likely due to a server error, please contact ETW Energy for support.
          <br />
        </Box>
      ),
      severity: 'error',
    })
  }
}

const readAsDataURL = (file: any) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onerror = reject
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.readAsDataURL(file)
  })
}

const fileOnSelect = (event: React.ChangeEvent<HTMLInputElement>, formSubmissionBundle: TsInterface_FormSubmissionBundle): TsType_UnknownPromise => {
  let form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm
  if (formSubmissionBundle.us_formType === 'LEASE') {
    form = formSubmissionBundle.ur_leaseForm
  } else {
    form = formSubmissionBundle.ur_cashForm
  }

  // generate a form name if it is not provided, this is to match the form to its proposal and allows companies to have multiple proposals uploaded, one for each form
  let formName = form.form_name.value
  if (!form.form_name.value) {
    formName = generateFormName(formSubmissionBundle.us_companyNameURL, form)
  }

  return new Promise((resolve, reject) => {
    if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
      let promiseArray: TsType_UnknownPromise[] = []
      let files = event.target.files
      let readFiles: TsInterface_UnspecifiedObject = {}
      let file = files[0]
      if (file != null && typeof file === 'object') {
        promiseArray.push(
          readAsDataURL(file)
            .then((res_RADU) => {
              readFiles[0] = {
                file_name: file.name,
                file: file,
                data_url: res_RADU,
              }
            })
            .catch((rej_RADU) => {
              // Nothing
            }),
        )
      }
      Promise.all(promiseArray).finally(() => {
        let clientKey = 'etw_energy'
        let userKey = formSubmissionBundle.us_companyNameURL
        if (clientKey != null && userKey != null && formName) {
          StorageUploadFile(StorageRef_ABPProposal(clientKey, userKey, readFiles[0].file_name), readFiles[0].file, {
            clientKey: clientKey,
            userKey: userKey,
          })
            .then((res_SUF: any) => {
              formSubmissionBundle.us_setProposalURL?.(res_SUF.url)

              // save the signed url to the db
              cloudFunctionUnauthenticatedRequests({
                // TODO: works, but need to make sure to have the version number of the form in the form name
                function: 'saveSignedURLForProposal',
                client_key: clientKey,
                proposal_key: formSubmissionBundle.us_companyNameURL,
                form_name: formName,
                signed_url: res_SUF.url,
              })
                .then((res: any) => {
                  resolve(res)
                })
                .catch((rej: any) => {
                  console.error('rej', rej)
                  reject(rej)
                })
            })
            .catch((rej_SUF) => {
              console.error('rej_SUF', rej_SUF)
              reject(rej_SUF)
            })
        } else {
          console.error('Missing Required Keys')
          reject({
            success: false,
            error: {
              message: rLIB('Failed to upload file'),
              details: rLIB('Missing Required Keys'),
              code: 'ER-D-PF-FOS-01',
            },
          })
        }
      })
    } else {
      console.error('No file selected')
      reject({
        success: false,
        error: {
          message: rLIB('Failed to upload file'),
          details: rLIB('Invalid Document Selection'),
          code: 'ER-D-PF-FOS-02',
        },
      })
    }
  })
}

const validateNewFormURL = (url: string, salesOrg: string, formType: string): boolean => {
  if (url === '') return true // empty is valid
  const formattedSalesOrg = salesOrg.toLowerCase().replace(/\s+/g, '_')
  return url === `${formattedSalesOrg}_${formType?.toLowerCase()}`
}

const rJSX_PageContent = (): JSX.Element => {
  const [us_selectedPreset, us_setSelectedPreset] = useState('None')
  const [us_savedPresets, us_setSavedPresets] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formType, us_setFormType] = useState<ABPFormType | ''>('')
  const [us_showAllFields, us_setShowAllFields] = useState(false)
  const [us_presetFields, us_setPresetFields] = useState<string[]>([])
  const [us_snackbar, us_setSnackbar] = useState({
    open: false,
    message: <></>,
    severity: 'success' as 'success' | 'error',
  })
  const [us_repName, us_setRepName] = useState('')
  const [us_repOrg, us_setRepOrg] = useState('')
  const [us_repEmail, us_setRepEmail] = useState('')
  const [us_repPhone, us_setRepPhone] = useState('')
  const [us_isAdmin, us_setIsAdmin] = useState(false)
  const [us_companyNameURL, us_setCompanyNameURL] = useState(useParams().id || '')
  const [us_validationsPassed, us_setValidationsPassed] = useState(false)
  const [us_companyValid, us_setCompanyValid] = useState(true)
  const [us_recGroupValues, us_setRecGroupValues] = useState<TsInterface_RecGroupValueMap>({})
  const [us_newFormURL, us_setNewFormURL] = useState('')
  const [us_utilityGroupIsInvalid, us_setUtilityGroupIsInvalid] = useState(false)
  const [us_proposalURL, us_setProposalURL] = useState('')
  const [us_validCompanyURLs, us_setValidCompanyURLs] = useState<TsInterface_ValidCompanyNameURLs>({})
  const [us_degradation, us_setDegradation] = useState<number>()
  const [us_applicationFee, us_setApplicationFee] = useState<number>()

  const leaseFormReducer = (state: TsInterface_LeaseDisclosureForm, action: Action): TsInterface_LeaseDisclosureForm => {
    switch (action.type) {
      case 'CHANGE_INPUT':
        return {
          ...state,
          [action.field]: {
            ...action.input,
            value: action.value,
          },
        }
      case 'CHANGE_PRESET_STATUS':
        return {
          ...state,
          [action.field]: {
            ...state[action.field as keyof TsInterface_LeaseDisclosureForm],
            is_preset: action.value,
          },
        }
      case 'LOADED_PRESETS':
        if (action.presets) {
          const updatedState = { ...state }
          for (const [key, value] of Object.entries(action.presets)) {
            if (updatedState[key as string as keyof TsInterface_LeaseDisclosureForm]) {
              updatedState[key as string as keyof TsInterface_LeaseDisclosureForm] = {
                ...updatedState[key as string as keyof TsInterface_LeaseDisclosureForm],
                value: value,
              }
            }
          }
          return updatedState
        }
        return state
      case 'RESET_FORM':
        return { ...leaseDisclosureForm }
      default:
        return state
    }
  }

  const cashFormReducer = (state: TsInterface_CashDisclosureForm, action: Action): TsInterface_CashDisclosureForm => {
    switch (action.type) {
      case 'CHANGE_INPUT':
        return {
          ...state,
          [action.field]: {
            ...state[action.field as keyof TsInterface_CashDisclosureForm],
            value: action.value,
          },
        }
      case 'CHANGE_PRESET_STATUS':
        return {
          ...state,
          [action.field]: {
            ...state[action.field as keyof TsInterface_CashDisclosureForm],
            is_preset: action.value,
          },
        }
      case 'LOADED_PRESETS':
        if (action.presets) {
          const updatedState = { ...state }
          for (const [key, value] of Object.entries(action.presets)) {
            if (updatedState[key as string as keyof TsInterface_CashDisclosureForm]) {
              updatedState[key as string as keyof TsInterface_CashDisclosureForm] = {
                ...updatedState[key as string as keyof TsInterface_CashDisclosureForm],
                value: value,
              }
            }
          }
          return updatedState
        }
        return state
      case 'RESET_FORM':
        return { ...cashDisclosureForm }
      default:
        return state
    }
  }

  const [ur_leaseForm, ur_leaseFormDispatch] = useReducer(leaseFormReducer, { ...leaseDisclosureForm })
  const [ur_cashForm, ur_cashFormDispatch] = useReducer(cashFormReducer, { ...cashDisclosureForm })

  // load in the form constants from the db, TODO: make a backend endpoint for this, calling directly since Raphael wants this done in a few days
  useEffect(() => {
    const getFormConstants = async () => {
      const res1 = await DatabaseGetDocument(DatabaseRef_ABPDegradation_Document('etw_energy'))
      const degradation = res1.data.degradation
      const res2 = await DatabaseGetDocument(DatabaseRef_ABPApplicationFee_Document('etw_energy'))
      const applicationFee = res2.data.application_fee
      us_setDegradation(degradation / 100) // because it is entered as a percentage in the settings
      us_setApplicationFee(applicationFee)
    }

    if (!us_degradation || !us_applicationFee) {
      getFormConstants()
    }
  }, [])

  // load in the rec group values from the db
  useEffect(() => {
    const getABPRecGroupValues = async () => {
      return new Promise((resolve, reject) => {
        cloudFunctionUnauthenticatedRequests({ function: 'getABPRecGroupValues' })
          .then((res: any) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    getABPRecGroupValues()
      .then((res: any) => {
        us_setRecGroupValues(res)
      })
      .catch((err) => {
        us_setSnackbar({
          open: true,
          message: <Box>Error loading rec group values, details: {err}</Box>,
          severity: 'error',
        })
      })
  }, [])

  useEffect(() => {
    // get valid company name urls from db, along with associated form type and admin status
    const getValidCompanyNameURLs = async () => {
      return new Promise((resolve, reject) => {
        cloudFunctionUnauthenticatedRequests({ function: 'getABPValidCompanyNameURLs' })
          .then((res: any) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    getValidCompanyNameURLs()
      .then((res: any) => {
        const validCompanyNameURLs: TsInterface_ValidCompanyNameURLs = res

        // add in the admin urls, these are fixed and should never change
        validCompanyNameURLs['etw_admin_purchase'] = {
          form_type: 'PURCHASE',
          is_admin: true,
          sales_org: 'Admin',
        }
        validCompanyNameURLs['etw_admin_lease'] = {
          form_type: 'LEASE',
          is_admin: true,
          sales_org: 'Admin',
        }

        us_setValidCompanyURLs(validCompanyNameURLs)

        // if the url is in the list of valid urls, then the form type and admin status are set
        if (us_companyNameURL in validCompanyNameURLs) {
          us_setFormType(validCompanyNameURLs[us_companyNameURL].form_type as ABPFormType)
          us_setIsAdmin(validCompanyNameURLs[us_companyNameURL].is_admin)
          !validCompanyNameURLs[us_companyNameURL].is_admin && us_setRepOrg(validCompanyNameURLs[us_companyNameURL].sales_org) // we only set the rep org name if the company is not admin, otherwise we are on the admin preset creation page and thus want to fill it in
          us_setCompanyValid(true)
        } else {
          us_setCompanyValid(false)
          us_setSnackbar({
            open: true,
            message: <Box>Invalid company name url.</Box>,
            severity: 'error',
          })
        }
      })
      .catch((err) => {
        us_setSnackbar({
          open: true,
          message: <Box>Error loading valid company name urls, details: {err}</Box>,
          severity: 'error',
        })
      })
  }, [])

  // load in the presets from the db
  useEffect(() => {
    // inline function to get the presets from the db
    const getABPDisclosureFormPresets = async () => {
      return new Promise((resolve, reject) => {
        cloudFunctionUnauthenticatedRequests({
          function: 'getABPDisclosureFormPresets',
        })
          .then((res: any) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    // get the presets from the db
    getABPDisclosureFormPresets()
      .then((res: any) => {
        // set the saved presets based on the url - this does not apply to the admin pages
        us_setSavedPresets(res)
        let companyNameElements = us_companyNameURL.toLowerCase().split('_')
        for (const key in res) {
          let keyMatches = true
          for (const element of companyNameElements) {
            if (!key.toLowerCase().includes(element)) {
              keyMatches = false
            }
          }

          // if the key of the database-stored preset contains the url elements of the company name, set the selected preset to that key
          if (keyMatches) {
            us_setSelectedPreset(key)
            us_setPresetFields(Object.keys(res[key]))
          }
        }
      })
      .catch((err) => {
        us_setSnackbar({
          open: true,
          message: <Box>Error loading presets, details: {err}</Box>,
          severity: 'error',
        })
      })
  }, [])

  // populate the preset fields of the form here for the admin pages
  useEffect(() => {
    if (us_selectedPreset !== 'None' && us_selectedPreset !== '' && us_isAdmin && us_formType === 'LEASE') {
      for (const key in us_savedPresets[us_selectedPreset]) {
        ur_leaseFormDispatch({
          type: 'CHANGE_INPUT',
          field: key,
          value: us_savedPresets[us_selectedPreset][key],
          input: ur_leaseForm[key as keyof TsInterface_LeaseDisclosureForm],
        })
      }
    } else if (us_selectedPreset !== 'None' && us_selectedPreset !== '' && us_isAdmin && us_formType === 'PURCHASE') {
      for (const key in us_savedPresets[us_selectedPreset]) {
        ur_cashFormDispatch({
          type: 'CHANGE_INPUT',
          field: key,
          value: us_savedPresets[us_selectedPreset][key],
          input: ur_cashForm[key as keyof TsInterface_CashDisclosureForm],
        })
      }
    }
  }, [us_selectedPreset])

  // load in the proposal url from the db if there is one
  useEffect(() => {
    const getProposalURL = async (proposalKey: string, formName: string) => {
      return new Promise((resolve, reject) => {
        cloudFunctionUnauthenticatedRequests({ function: 'getSignedURLForProposal', proposal_key: proposalKey, form_name: formName })
          .then((res: any) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    let form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm

    if (us_formType === 'LEASE') {
      form = ur_leaseForm
    } else {
      form = ur_cashForm
    }

    // get the proposal url from the db when the form has the proper info in order to create a name
    if (
      us_proposalURL === '' &&
      us_companyNameURL &&
      form['customer_name'].value &&
      form['customer_address_1'].value &&
      form['customer_address_phone'].value &&
      !us_isAdmin
    ) {
      getProposalURL(us_companyNameURL, generateFormName(us_companyNameURL, form))
        .then((res: any) => {
          us_setProposalURL(res.signed_url)
        })
        .catch((err) => {
          if (err.response?.data?.details?.error?.details && err.response?.data?.details?.error?.details === 'No Document for specified database location') {
            // us_setSnackbar({ open: true, message: <Box>No proposal found for this form.</Box>, severity: 'error' })
            us_setProposalURL('')
          } else {
            us_setSnackbar({ open: true, message: <Box>Error loading proposal url, details: {err}</Box>, severity: 'error' })
          }
        })
    }
  }, [us_companyNameURL, ur_leaseForm, ur_cashForm])

  const rJSX_DynamicFormTextInput = (input: TsInterface_DynamicFormInput, key: string): JSX.Element => {
    let form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm
    if (us_formType === 'LEASE') {
      form = ur_leaseForm
    } else {
      form = ur_cashForm
    }
    return (
      <StyledFormControl>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
          {us_isAdmin && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="caption"
                sx={{ mr: 1 }}
              >
                Save Preset
              </Typography>
              <Switch
                size="small"
                checked={getProp(form, key, false).is_preset}
                onChange={(e) => {
                  if (us_formType === 'LEASE') {
                    ur_leaseFormDispatch({
                      type: 'CHANGE_PRESET_STATUS',
                      field: key,
                      value: e.target.checked,
                    })
                  } else {
                    ur_cashFormDispatch({
                      type: 'CHANGE_PRESET_STATUS',
                      field: key,
                      value: e.target.checked,
                    })
                  }
                }}
              />
            </Box>
          )}
          <StyledLabel variant="subtitle1">{input.label}</StyledLabel>
        </Box>
        <StyledTextField
          name={key}
          value={input.value}
          helperText={input.instructions}
          onChange={(e) => {
            if (us_formType === 'LEASE') {
              ur_leaseFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value, input: input })
            } else {
              ur_cashFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value, input: input })
            }
          }}
        />
      </StyledFormControl>
    )
  }

  const rJSX_DynamicFormSelectInput = (input: TsInterface_DynamicFormInput, key: string): JSX.Element => {
    let form: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm
    if (us_formType === 'LEASE') {
      form = ur_leaseForm
    } else {
      form = ur_cashForm
    }
    return (
      <StyledFormControl>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
          {us_isAdmin && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="caption"
                sx={{ mr: 1 }}
              >
                Save Preset
              </Typography>
              <Switch
                size="small"
                checked={getProp(form, key, false).is_preset}
                onChange={(e) => {
                  if (us_formType === 'LEASE') {
                    ur_leaseFormDispatch({
                      type: 'CHANGE_PRESET_STATUS',
                      field: key,
                      value: e.target.checked,
                    })
                  } else {
                    ur_cashFormDispatch({
                      type: 'CHANGE_PRESET_STATUS',
                      field: key,
                      value: e.target.checked,
                    })
                  }
                }}
              />
            </Box>
          )}
          <StyledLabel variant="subtitle1">{input.label}</StyledLabel>
        </Box>
        <StyledSelect
          labelId={`${key}-label`}
          id={key}
          name={key}
          value={input.value}
          onChange={(e) => {
            if (us_formType === 'LEASE') {
              ur_leaseFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value as string, input: input })
            } else {
              ur_cashFormDispatch({ type: 'CHANGE_INPUT', field: key, value: e.target.value as string, input: input })
            }
          }}
        >
          {input.options?.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </StyledSelect>
        {input.instructions && (
          <Typography
            variant="caption"
            className="tw-text-gray_400 tw-ml-3"
          >
            {input.instructions}
          </Typography>
        )}
      </StyledFormControl>
    )
  }

  const determineIfConditionalDependencyIsMet = (conditionalDependencies: object): boolean => {
    // TODO: part of our hacky solution for the one field with an OR clause for ALL dependencies
    if (Object.keys(conditionalDependencies).includes('or')) {
      return Object.entries(conditionalDependencies).some(([key, values]) => {
        if (key !== 'or') {
          try {
            if (us_formType === 'LEASE') {
              if (values.includes('any') && ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value.length > 0) {
                return true
              }
              return (
                values.includes(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value) ||
                values.includes(String(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value))
              )
            } else {
              if (values.includes('any') && ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value.length > 0) {
                return true
              }
              return (
                values.includes(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value) ||
                values.includes(String(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value))
              )
            }
          } catch (err: any) {
            console.error('ERROR DETERMINING IF CONDITIONAL DEPENDENCY IS MET')
            us_setSnackbar({
              open: true,
              message: <Box>Error determining if conditional dependency is met, details: {err}</Box>,
              severity: 'error',
            })
          }
        }
      })
    }

    return Object.entries(conditionalDependencies).every(([key, values]) => {
      try {
        if (us_formType === 'LEASE') {
          if (values.includes('any') && ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value.length > 0) {
            return true
          }
          return (
            values.includes(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value) ||
            values.includes(String(ur_leaseForm[key as string as keyof TsInterface_LeaseDisclosureForm].value))
          )
        } else {
          if (values.includes('any') && ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value.length > 0) {
            return true
          }
          return (
            values.includes(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value) ||
            values.includes(String(ur_cashForm[key as string as keyof TsInterface_CashDisclosureForm].value))
          )
        }
      } catch (err: any) {
        console.error('ERROR DETERMINING IF CONDITIONAL DEPENDENCY IS MET')
        us_setSnackbar({
          open: true,
          message: <Box>Error determining if conditional dependency is met, details: {err}</Box>,
          severity: 'error',
        })
      }
    })
  }

  const rJSX_CreateDynamicForm = (): JSX.Element => {
    let formData: TsInterface_LeaseDisclosureForm | TsInterface_CashDisclosureForm

    // set the form based on the form type and set the preset fields before creating the dynamic form
    if (us_formType === 'LEASE') {
      formData = ur_leaseForm
      if (us_selectedPreset !== 'None' && us_selectedPreset !== '' && !us_isAdmin) {
        // if we are on the admin page, we set the form values directly in a useeffect so that they can be edited, otherwise they are immutable
        for (const key in us_savedPresets[us_selectedPreset]) {
          if (key == 'preset_name' || key == 'preset_url' || key == 'sales_org' || key == 'timestamp_created' || key == 'timestamp_updated' || key == 'key') {
            continue
          }
          formData[key as keyof TsInterface_LeaseDisclosureForm].value = us_savedPresets[us_selectedPreset][key]
        }
      }
    } else {
      formData = ur_cashForm
      if (us_selectedPreset !== 'None' && us_selectedPreset !== '' && !us_isAdmin) {
        for (const key in us_savedPresets[us_selectedPreset]) {
          if (key == 'preset_name' || key == 'preset_url' || key == 'sales_org' || key == 'timestamp_created' || key == 'timestamp_updated' || key == 'key') {
            continue
          }
          formData[key as keyof TsInterface_CashDisclosureForm].value = us_savedPresets[us_selectedPreset][key]
        }
      }
    }

    return (
      <Grid2
        container
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'flex-start' }}
      >
        {Object.keys(formData).map((key: string) => {
          // ignore any fee fields when not an admin
          if (!us_isAdmin && key.toLowerCase().includes('fee')) {
            return null
          }

          // ignore any appended fields that we use for our own purposes
          if (key == 'preset_name' || key == 'preset_url' || key == 'sales_org' || key == 'timestamp_created' || key == 'timestamp_updated' || key == 'key') {
            return null
          }

          // if the field is a preset field, we ignore it
          if (
            us_formType === 'LEASE' &&
            us_selectedPreset !== 'None' &&
            us_savedPresets[us_selectedPreset] &&
            us_presetFields.includes(key)
            // key != 'project_lessor_legal_name'
          ) {
            return null
          } else if (
            us_formType === 'PURCHASE' &&
            us_selectedPreset !== 'None' &&
            us_savedPresets[us_selectedPreset] &&
            us_presetFields.includes(key)
            // key != 'seller_legal_name'
          ) {
            return null
          }

          let formField: TsInterface_DynamicFormInput
          if (us_formType === 'LEASE') {
            formField = ur_leaseForm[key as keyof typeof ur_leaseForm]
          } else {
            formField = ur_cashForm[key as keyof typeof ur_cashForm]
          }

          const isTextOrNumber = formField.input_type === 'text' || formField.input_type === 'number'

          // handle required fields
          if (isTextOrNumber && formField.is_required) {
            return <Box key={key}>{rJSX_DynamicFormTextInput(formField, key)}</Box>
          }

          if (formField.input_type === 'dropdown' && formField.is_required) {
            return <Box key={key}>{rJSX_DynamicFormSelectInput(formField, key)}</Box>
          }

          // handle conditional fields
          if ((!formField.is_required && Object.keys(formField.conditional_dependencies).length > 0) || (us_showAllFields && key !== 'form_type')) {
            const conditional_dependencies_met = determineIfConditionalDependencyIsMet(formField.conditional_dependencies)
            if (conditional_dependencies_met || us_showAllFields) {
              if (isTextOrNumber) {
                return <Box key={key}>{rJSX_DynamicFormTextInput(formField, key)}</Box>
              }
              if (formField.input_type === 'dropdown') {
                return <Box key={key}>{rJSX_DynamicFormSelectInput(formField, key)}</Box>
              }
            }
          }

          return null
        })}
      </Grid2>
    )
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    us_setSnackbar({ ...us_snackbar, open: false })
  }

  if (!us_degradation || !us_applicationFee) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  return us_companyValid && us_degradation && us_applicationFee ? (
    <Box>
      {!us_isAdmin ? (
        <StyledTitle variant="h4">ABP Disclosure {us_formType === 'LEASE' ? 'Lease' : 'Purchase'} Form</StyledTitle>
      ) : (
        <StyledTitle variant="h4">ABP Disclosure {us_formType === 'LEASE' ? 'Lease' : 'Purchase'} Preset</StyledTitle>
      )}
      {us_isAdmin && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <Typography variant="overline">Show all form fields</Typography>
            <Switch
              checked={us_showAllFields}
              onChange={() => us_setShowAllFields(!us_showAllFields)}
              color="primary"
            />
          </Box>
          {us_showAllFields && (
            <Typography
              variant="caption"
              className="tw-mb-2"
            >
              <strong style={{ color: '#ffeb3b' }}>
                Caution: This will show all fields, including those that are not required and/or precalculated. This is useful for debugging and for admins to
                set a specific field value, however, do not use this to fill in the form—many fields are precalculated upon submission and may overwrite entered
                values. Only use this to set specific field values that are not precalculated.
              </strong>
            </Typography>
          )}
        </Box>
      )}
      {us_isAdmin && (
        <Box className="tw-flex tw-flex-col tw-gap-4 tw-w-6/12 tw-mb-4">
          Select a preset to load:
          <StyledSelect
            labelId="preset-select-label"
            id="preset-select"
            value={us_selectedPreset}
            onChange={(e) => {
              us_setSelectedPreset(e.target.value as string)
              // us_setRepOrg(getOrgNameFromPresetName(e.target.value as string))
            }}
          >
            {Object.keys(us_savedPresets).map((preset: any) => {
              if (us_formType === 'LEASE' && !preset.includes(' - Lease')) {
                return
              }
              if (us_formType === 'PURCHASE' && !preset.includes(' - Purchase')) {
                return
              }
              return (
                <MenuItem
                  key={preset}
                  value={preset}
                >
                  {preset}
                </MenuItem>
              )
            })}
          </StyledSelect>
        </Box>
      )}
      <Box className="tw-flex tw-flex-col tw-gap-4 tw-w-6/12 tw-mb-4">
        <TextField
          label="Sales Org"
          value={us_repOrg ? us_repOrg : ''}
          disabled={!us_isAdmin}
          onChange={(e) => {
            us_setRepOrg(e.target.value)
          }}
        />
        {!us_isAdmin && (
          <>
            <TextField
              label="Sales Rep Name"
              value={us_repName}
              onChange={(e) => us_setRepName(e.target.value)}
            />
            <TextField
              label="Sales Rep Email"
              value={us_repEmail}
              onChange={(e) => us_setRepEmail(e.target.value)}
            />
            <TextField
              label="Sales Rep Phone"
              value={us_repPhone}
              onChange={(e) => us_setRepPhone(e.target.value)}
            />
          </>
        )}
      </Box>
      <StyledSection>{us_formType && rJSX_CreateDynamicForm()}</StyledSection>
      {us_isAdmin && (
        <StyledSection sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Typography variant="body1">
            Enter the URL extension to access this form. You will not be able to change this URL once it is set. Ensure that it follows a snake-case format,
            i.e. etw_energy_purchase. It must begin with the company name, separated by underscores as necessary (if the company name does not match the sales
            org name, this form will not work properly). Additionally, it must end in either "_purchase" or "_lease" to be valid. Note that a URL is not
            required if you are updating your current preset and not creating a new one.
          </Typography>
          <TextField
            value={us_newFormURL}
            placeholder={`ex. ${us_repOrg.toLowerCase().replace(/\s+/g, '_')}_${us_formType?.toLowerCase()}`}
            onChange={(e) => us_setNewFormURL(e.target.value.toLowerCase())}
            error={us_newFormURL !== '' && !validateNewFormURL(us_newFormURL, us_repOrg, us_formType)}
            helperText={
              us_newFormURL !== '' && !validateNewFormURL(us_newFormURL, us_repOrg, us_formType)
                ? `URL must be "${us_repOrg.toLowerCase().replace(/\s+/g, '_')}_${us_formType?.toLowerCase()}"`
                : ''
            }
            title={
              us_newFormURL !== '' && !validateNewFormURL(us_newFormURL, us_repOrg, us_formType)
                ? `URL must match sales org name (${us_repOrg}) converted to lowercase with underscores`
                : ''
            }
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <StyledButton
              variant="contained"
              color="primary"
              disabled={
                (!us_newFormURL && (us_selectedPreset === 'None' || us_selectedPreset === '')) ||
                (us_newFormURL !== '' && !validateNewFormURL(us_newFormURL, us_repOrg, us_formType))
              }
              onClick={() => {
                const formSubmissionBundle: TsInterface_FormSubmissionBundle = {
                  us_formType,
                  ur_leaseForm,
                  ur_cashForm,
                  us_companyNameURL,
                  us_setSnackbar,
                  us_setValidationsPassed,
                  us_repName,
                  us_repEmail,
                  us_repOrg,
                  us_recGroupValues,
                  us_newFormURL,
                  us_setUtilityGroupIsInvalid,
                  us_utilityGroupIsInvalid,
                  us_isAdmin,
                  us_validCompanyURLs,
                  us_degradation,
                  us_applicationFee,
                }
                handleSavePreset(formSubmissionBundle)
              }}
            >
              Save Preset
            </StyledButton>
          </Box>
          <Typography
            variant="caption"
            className="tw-text-gray_400"
          >
            Presets allow you to save the majority of your form data and load it later.
          </Typography>
        </StyledSection>
      )}
      {!us_isAdmin && (
        <Box className="tw-flex tw-flex-col tw-gap-4 tw-w-6/12 tw-mb-4">
          <FileUploadButton
            multiple={false}
            button={{
              text: <Typography>Upload Proposal</Typography>,
              icon: (
                <Icon
                  icon="cloud-arrow-up"
                  className="tw-mr-2"
                ></Icon>
              ),
              className: '',
              color: 'info',
              variant: 'contained',
              // we need the elements necessary to generate a form name to use as a key in the database
              disabled:
                (!(ur_cashForm.customer_address_1.value && ur_cashForm.customer_name.value) &&
                  !(ur_leaseForm.customer_address_1.value && ur_leaseForm.customer_name.value)) ||
                !us_companyNameURL,
            }}
            accept={''}
            onChange={function (event: ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): any {
              const formSubmissionBundle: TsInterface_FormSubmissionBundle = {
                us_formType,
                ur_leaseForm,
                ur_cashForm,
                us_companyNameURL,
                us_setSnackbar,
                us_setValidationsPassed,
                us_repName,
                us_repEmail,
                us_repOrg,
                us_recGroupValues,
                us_setProposalURL,
                us_isAdmin,
                us_degradation,
                us_applicationFee,
              }
              fileOnSelect(event, formSubmissionBundle)
            }}
            additionalFileUploadParams={{}}
          />
          {us_proposalURL && (
            <Typography>
              Proposal URL:{' '}
              {
                <Link
                  href={us_proposalURL}
                  target="_blank" // these two attributes are necessary to open the link in a new tab
                  rel="noopener noreferrer"
                >
                  Proposal
                </Link>
              }
            </Typography>
          )}
        </Box>
      )}
      {!us_companyNameURL.includes('etw_admin') && (
        <Box className="tw-flex tw-justify-center tw-mt-4">
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => {
              const formSubmissionBundle: TsInterface_FormSubmissionBundle = {
                us_formType,
                ur_leaseForm,
                ur_cashForm,
                us_companyNameURL,
                us_setSnackbar,
                us_setValidationsPassed,
                us_repName,
                us_repEmail,
                us_repOrg,
                us_repPhone,
                us_recGroupValues,
                us_setUtilityGroupIsInvalid,
                us_utilityGroupIsInvalid,
                us_isAdmin,
                us_degradation,
                us_applicationFee,
                us_proposalURL,
              }
              handleFormSubmit(formSubmissionBundle)
            }}
          >
            Submit
          </StyledButton>
        </Box>
      )}
      <Snackbar
        open={us_snackbar.open}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={us_snackbar.severity}
          sx={{ width: '100%' }}
        >
          {us_snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  ) : (
    <Box sx={{ textAlign: 'center', py: 8 }}>
      <Typography
        variant="h4"
        color="error"
        gutterBottom
      >
        Company Not Found
      </Typography>
      <Typography variant="body1">The specified company "{us_companyNameURL}" is not recognized. Please check the URL and try again.</Typography>
    </Box>
  )
}

export default Container
