///////////////////////////////
// Imports
///////////////////////////////

import { doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_ClientSharingSettings_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'settings', 'sharing')
}
