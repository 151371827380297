/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Typography } from '@mui/material'
import { forwardRef, useContext, useEffect, useReducer } from 'react'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TableCellBasic, TsInterface_TableColumns } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { taskOutcomes } from '../data/hardcoded_task_outcomes'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_TaskLibraryTaskOutcomesTab {
  // projectKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

const tableColumns_TaskOutcomes: TsInterface_TableColumns = {
  value: TableCellBasic('value', rLIB('Outcome Name'), 'value'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const TaskLibraryTaskOutcomesTab = forwardRef((props: TsInterface_TaskLibraryTaskOutcomesTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  // let pr_projectKey: TsInterface_TaskLibraryTaskOutcomesTab['projectKey'] = getProp(props, 'projectKey', null)

  // Hooks - useContext, useState, useReducer, other
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Workflows', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  // JSX Generation
  const rJSX_TaskOutcomeTabContent = (): JSX.Element => {
    return (
      <Box>
        <Box className="tw-mb-2 tw-p-2 tw-bg-warning_main tw-rounded">
          <Typography>
            <Icon
              icon="exclamation-triangle"
              type="solid"
              className="tw-mr-2"
            />
            {rLIB('Task Outcomes are currently hardcoded')}
          </Typography>
        </Box>
        <Card className="">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_TaskOutcomes}
            tableData={objectToArray(taskOutcomes)}
            tableSettings={{
              paginated: false,
              size: 'small',
              sort_direction: 'desc',
              sort_property_default: 'name',
              sortable: true,
              // alternate_row_color_hex: themeVariables.background_hover,
              // alternate_row_colors: true,
              sticky_header: true,
              sticky_table_height: 'calc(100vh - 180px)',
            }}
          />
        </Card>
      </Box>
    )
  }

  // Render

  return <>{rJSX_TaskOutcomeTabContent()}</>
})
