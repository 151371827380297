/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
DESCRIPTION / USAGE:
example component description

TODO:

*/

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Card, Typography } from '@mui/material'
import { forwardRef, useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_TaskWorkflowDelayReasons_Collection,
  DatabaseRef_TaskWorkflowDelayReason_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DirectMultipleChoiceEdit } from 'rfbp_core/components/direct_edits/direct_multiple_choice_edit'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseDeleteDocument, DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_TaskLibraryDelayReasonsTab {
  // projectKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const delayReasonTypeOptions = [
  { key: 'default', value: rLIB('Default') },
  { key: 'excused', value: rLIB('Excused') },
]

// Table

const tableColumns_PhaseDelayReasons: TsInterface_TableColumns = {
  manage: TableCellManage({
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Rename')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'info',
              confirm_text: rLIB('Rename'),
              default_value: rowData.name,
              header: rLIB('Rename Delay Reason'),
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: rLIB('Delay Reason'),
              input_type: 'text',
              text: rLIB('Enter a name for this delay reason'),
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    name: promptValue,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowDelayReason_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Delay Reason'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this delay reason?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  DatabaseDeleteDocument(DatabaseRef_TaskWorkflowDelayReason_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string))
                    .then((res_DSMD) => {
                      // TODO - Log
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Delay Reason'), 'name'),
  delay_reason_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const rJSX_TypeIcon = (type: string) => {
          if (type === 'excused') {
            return (
              <Icon
                icon="circle"
                className="tw-mr-2"
                sx={{ color: themeVariables.success_dark }}
              />
            )
          }
          return (
            <Icon
              icon="circle"
              className="tw-mr-2"
              sx={{ color: themeVariables.warning_main }}
            />
          )
        }
        let editableFieldJSX = (
          <Typography
            variant="body1"
            className="tw-inline-block"
          >
            {rJSX_TypeIcon(getProp(rowData, 'delay_reason_type', 'default'))}
            {capitalize(getProp(rowData, 'delay_reason_type', 'default'))}
          </Typography>
        )

        let cellJSX = (
          <Box className="tw-flex">
            <DirectMultipleChoiceEdit
              displayText={editableFieldJSX}
              fullyClickable={false}
              selectedOption={{ key: getProp(rowData, 'delay_reason_type', ''), value: getProp(rowData, 'delay_reason_type', '') }}
              textCssClassName="tw-font-bold tw-w-full"
              optionType={'static'}
              options={delayReasonTypeOptions}
              onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        delay_reason_type: selectedOption.key,
                      }
                      if (selectedOption.value == '') {
                        updateObject = {
                          delay_reason_type: null,
                        }
                      }
                      DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowDelayReason_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then(() => {
                          resolve(true)
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                })
              }}
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Delay Reason Type') as JSX.Element
      },
      header_sort_by: 'input_type',
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const TaskLibraryDelayReasonsTab = forwardRef((props: TsInterface_TaskLibraryDelayReasonsTab, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  // let pr_projectKey: TsInterface_TaskLibraryDelayReasonsTab['projectKey'] = getProp(props, 'projectKey', null)

  // Hooks - useContext, useState, useReducer, other
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_phaseDelayReasons, us_setPhaseDelayReasons] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Workflows', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPhaseDelayReasons(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowDelayReasons_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables

  // Functions

  // JSX Generation

  const rJSX_DelayReasonTabContent = (): JSX.Element => {
    return (
      <Box>
        <Card className="">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_PhaseDelayReasons}
            tableData={objectToArray(us_phaseDelayReasons)}
            tableSettings={{
              paginated: false,
              size: 'small',
              sort_direction: 'asc',
              sort_property_default: 'name',
              sortable: true,
              sticky_header: true,
              sticky_table_height: 'calc(100vh - 180px)',
            }}
          />
        </Card>
      </Box>
    )
  }

  // Render

  return <>{rJSX_DelayReasonTabContent()}</>
})
