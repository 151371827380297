/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Json } from 'rfbp_core/components/code_display'
import { DirectMultipleChoiceEdit, tsI_MultipleChoiceOption } from 'rfbp_core/components/direct_edits/direct_multiple_choice_edit'
import { DirectNumberEdit } from 'rfbp_core/components/direct_edits/direct_number_edit'
import { DirectParagraphEdit } from 'rfbp_core/components/direct_edits/direct_paragraph_edit'
import { DirectTextEdit } from 'rfbp_core/components/direct_edits/direct_text_edit'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { TsInterface_TableAdditionalData, TsInterface_TableDataRow, TsInterface_TableHooks } from 'rfbp_core/components/table'
import { TabsBasic, TsInterface_TabContentArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseDeleteDocument,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { masterData_SystemAccessories } from './accessories'
import { masterData_BuildingTypes } from './building_type'
import { masterData_DesignPartners } from './design_partners'
import { masterData_FinancePartners } from './finance_partners'
import { masterData_FinancingTypes } from './financing_type'
import { masterData_Hoas } from './hoas'
import { masterData_InstallPartners } from './install_partners'
import { masterData_Jurisdictions } from './jurisdictions'
import { masterData_LeadSources } from './lead_sources'
import { masterData_OpportunityMilestones } from './opportunity_milestones'
import { masterData_ProjectMilestones } from './project_milestones'
import { masterData_Regions } from './regions'
import { masterData_SalesPartners } from './sales_partners'
import { masterData_SalesPartnerAdders } from './sales_partner_adders'
import { masterData_SalesPartnerDiscounts } from './sales_partner_discounts'
import { masterData_SalesPartnerIncentives } from './sales_partner_incentives'
import { masterData_SalesPartnerSOW } from './sales_partner_sow'
import { masterData_SystemInverters } from './system_inverters'
import { masterData_SystemModules } from './system_modules'
import { masterData_SystemMountTypes } from './system_mount_types'
import { masterData_SystemProductPackages } from './system_product_packages'
import { masterData_SystemRacking } from './system_racking'
import { masterData_SystemStorage } from './system_storage'
import { masterData_Utilities } from './utilities'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_MasterDataForumRefs {
  forumThreadCollectionDatabaseEndpoint: any
  forumThreadDocumentDatabaseEndpoint: any
  forumMessageCollectionDatabaseEndpoint: any
  forumMessageDocumentDatabaseEndpoint: any
  forumMessageStorageEndpoint: any
}

interface TsInterface_MasterDataFilesRefs {
  fileSystemCollectionDatabaseEndpoint: any
  fileSystemDocumentDatabaseEndpoint: any
  fileSystemStorageEndpoint: any
}

interface TsInterface_MasterDataImportAliasRefs {
  importAliasesDocumentRef: any
  importAliasesCollectionRef: any
  importAliasesQuery: any
}

interface TsInterface_MasterDataViewDialog {
  masterDataItemKey: string
  documentRef: any
  collectionRef: any
  detailsConfig: TsInterface_UnspecifiedObject
  importAliasesRefs: TsInterface_MasterDataImportAliasRefs
  forumRefs: TsInterface_MasterDataForumRefs
  filesRefs: TsInterface_MasterDataFilesRefs
  readOrWrite: 'read' | 'write'
  masterDataAllProps: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

const masterDataList: TsInterface_UnspecifiedObject = {
  building_types: {
    key: 'building_types',
    name: rLIB('Building Types'),
    data: masterData_BuildingTypes,
  },
  design_partners: {
    key: 'design_partners',
    name: rLIB('Design Partners'),
    data: masterData_DesignPartners,
  },
  // document_tab_names: {
  //   key: 'document_tab_names',
  //   name: rLIB('Document Tab Names'),
  //   data: masterData_documentTabNames,
  // },
  finance_partners: {
    key: 'finance_partners',
    name: rLIB('Finance Partners'),
    data: masterData_FinancePartners,
  },
  financing_types: {
    key: 'financing_types',
    name: rLIB('Financing Types'),
    data: masterData_FinancingTypes,
  },
  hoas: {
    key: 'hoas',
    name: rLIB('HOAs'),
    data: masterData_Hoas,
  },
  install_partners: {
    key: 'install_partners',
    name: rLIB('Install Partners'),
    data: masterData_InstallPartners,
  },
  jurisdictions: {
    key: 'jurisdictions',
    name: rLIB('Jurisdictions'),
    data: masterData_Jurisdictions,
  },
  lead_sources: {
    key: 'lead_sources',
    name: rLIB('Lead Sources'),
    data: masterData_LeadSources,
  },
  opportunity_milestones: {
    key: 'opportunity_milestones',
    name: rLIB('Opportunity Milestones'),
    data: masterData_OpportunityMilestones,
  },
  project_milestones: {
    key: 'project_milestones',
    name: rLIB('Project Milestones'),
    data: masterData_ProjectMilestones,
  },
  regions: {
    key: 'regions',
    name: rLIB('Regions'),
    data: masterData_Regions,
  },
  sales_partners: {
    key: 'sales_partners',
    name: rLIB('Sales Partners'),
    data: masterData_SalesPartners,
  },
  sales_partner_adders: {
    key: 'sales_partner_adders',
    name: rLIB('Sales Partner - Adders'),
    data: masterData_SalesPartnerAdders,
  },
  sales_partner_discounts: {
    key: 'sales_partner_discounts',
    name: rLIB('Sales Partner - Discounts'),
    data: masterData_SalesPartnerDiscounts,
  },
  sales_partner_incentives: {
    key: 'sales_partner_incentives',
    name: rLIB('Sales Partner - Incentives'),
    data: masterData_SalesPartnerIncentives,
  },
  sales_partner_sow: {
    key: 'sales_partner_sow',
    name: rLIB('Sales Partner - SOW'),
    data: masterData_SalesPartnerSOW,
  },
  // sales_partner_tasks_pending_approval: {
  //   key: 'sales_partner_tasks_pending_approval',
  //   name: rLIB('Sales Partner - Tasks Pending Approval'),
  // },
  system_accessories: {
    key: 'system_accessories',
    name: rLIB('System - Accessories'),
    data: masterData_SystemAccessories,
  },
  system_inverters: {
    key: 'system_inverters',
    name: rLIB('System - Inverters'),
    data: masterData_SystemInverters,
  },
  system_modules: {
    key: 'system_modules',
    name: rLIB('System - Modules'),
    data: masterData_SystemModules,
  },
  system_mount_types: {
    key: 'system_mount_types',
    name: rLIB('System - Roof/Mount Types'),
    data: masterData_SystemMountTypes,
  },
  system_product_packages: {
    key: 'system_product_packages',
    name: rLIB('System - Product Packages'),
    data: masterData_SystemProductPackages,
  },
  system_racking: {
    key: 'system_racking',
    name: rLIB('System - Racking'),
    data: masterData_SystemRacking,
  },
  system_storage: {
    key: 'system_storage',
    name: rLIB('System - Storage'),
    data: masterData_SystemStorage,
  },
  // teams: {
  //   key: 'teams',
  //   name: rLIB('Teams'),
  // },
  utilities: {
    key: 'utilities',
    name: rLIB('Utilities'),
    data: masterData_Utilities,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const createImportAlias = (
  uc_setUserInterface_PromptDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  clientKey: string,
  masterDataItemKey: string,
  masterDataItemName: string,
  importAliasesDocumentRef: any,
  collectionRef: any,
) => {
  uc_setUserInterface_PromptDialogDisplay({
    display: true,
    prompt: {
      color: 'success',
      confirm_text: rLIB('Create Import Alias'),
      default_value: '',
      header: rLIB('Create Import Alias'),
      icon: (
        <Icon
          icon="circle-plus"
          type="solid"
        />
      ),
      input_label: rLIB('Import Alias'),
      input_type: 'text',
      text: rLIB('Type the name of the import alias you want to create'),
      submit_callback: (promptValue: string) => {
        return new Promise((resolve, reject) => {
          // Check and see if this import alias already exists
          // let newAliasKey = keyFromString(promptValue)
          let newAliasKey = promptValue
          let proceed = true
          let promiseArray: Promise<unknown>[] = []
          // Check for existing alias
          promiseArray.push(
            DatabaseGetDocument(importAliasesDocumentRef(clientKey, newAliasKey))
              .then((res_DGD) => {
                if (res_DGD != null && res_DGD.data != null && objectToArray(res_DGD.data).length > 0) {
                  proceed = false
                }
              })
              .catch((rej_DGD) => {
                // proceed = true
              }),
          )
          // Check for identical master data match
          let queryRef = generateDatabaseQuery(collectionRef(clientKey as string), [{ prop: 'name', comparator: '==', value: newAliasKey }], [], {}, 1)

          promiseArray.push(
            DatabaseGetCollection(queryRef).then((res_DGC) => {
              if (res_DGC != null && res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                proceed = false
              }
            }),
          )
          //
          Promise.all(promiseArray).finally(() => {
            if (proceed) {
              DatabaseSetMergeDocument(importAliasesDocumentRef(clientKey, newAliasKey), {
                associated_master_data_key: masterDataItemKey,
                associated_master_data_name: masterDataItemName,
                key: newAliasKey,
                name: promptValue,
              })
                .then((res_DSMD) => {
                  resolve(res_DSMD)
                })
                .catch((rej_DSMD) => {
                  reject(rej_DSMD)
                })
            } else {
              reject({})
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: rLIB('Failed to create alias'),
                  details: rLIB('This alias already exists'),
                  code: 'ER-D-AMD-CIA-01',
                },
              })
            }
          })
        })
      },
    },
  })
}

const deleteImportAlias = (clientKey: string, importAliasKey: string, uc_setUserInterface_ConfirmDialogDisplay: any, importAliasesDocumentRef: any) => {
  uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'error',
      icon: <Icon icon="trash" />,
      header: rLIB('Delete Import Alias'),
      text: rLIB('Are you sure that you want to delete this import alias?'),
      submit_text: rLIB('Delete'),
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          DatabaseDeleteDocument(importAliasesDocumentRef(clientKey, importAliasKey))
            .then((res_DGD) => {
              resolve(res_DGD)
            })
            .catch((rej_GCK) => {
              reject(rej_GCK)
            })
        })
      },
    },
  })
}

const MasterDataViewDialog: React.FC<TsInterface_MasterDataViewDialog> = ({
  masterDataItemKey,
  documentRef,
  collectionRef,
  detailsConfig,
  importAliasesRefs,
  forumRefs,
  filesRefs,
  readOrWrite,
  masterDataAllProps,
}): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_dialogTabs, us_setDialogTabs] = useState<TsInterface_TabContentArray>([])
  const [us_dataItem, us_setDataItem] = useState<TsInterface_UnspecifiedObject>({})
  const [us_dataItemImportAliases, us_setDataItemImportAliases] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Hooks - useEffect
  useEffect(() => {
    // Get Task
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDataItem(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(documentRef(res_GCK.clientKey, masterDataItemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, masterDataItemKey, documentRef])

  useEffect(() => {
    // Get Task
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDataItemImportAliases(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(importAliasesRefs.importAliasesQuery(res_GCK.clientKey, masterDataItemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, masterDataItemKey, importAliasesRefs])

  useEffect(() => {
    // Other JSX Functions
    const rJSX_DirectlyEditableTextLineItem = (label: string | JSX.Element, propKey: string, object: TsInterface_UnspecifiedObject): JSX.Element => {
      if (getProp(object, propKey, null) === '') {
        object[propKey] = null
      }
      let missingJSX = (
        <Box
          component="span"
          className="tw-opacity-30 tw-italic"
        >
          {rLIB('Missing')}
        </Box>
      )
      let editableFieldJSX = (
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {getProp(object, propKey, missingJSX)}
        </Typography>
      )
      if (readOrWrite === 'write') {
        editableFieldJSX = (
          <Box className="tw-inline-block">
            <DirectTextEdit
              displayText={editableFieldJSX}
              fullyClickable={false}
              text={getProp(object, propKey, '')}
              textCssClassName="tw-font-bold tw-w-full"
              onEnter={(value: string) => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: value,
                      }
                      if (value == '') {
                        updateObject = {
                          [propKey]: null,
                        }
                      }
                      DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, masterDataItemKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                })
              }}
            />
          </Box>
        )
      }
      // Return
      return (
        <Box className="tw-mb-1 tw-ml-4">
          <Typography
            variant="body1"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          >
            {label}:
          </Typography>
          {editableFieldJSX}
        </Box>
      )
    }
    const rJSX_DirectlyEditableParagraphLineItem = (label: string | JSX.Element, propKey: string, object: TsInterface_UnspecifiedObject): JSX.Element => {
      if (getProp(object, propKey, null) === '') {
        object[propKey] = null
      }
      let missingJSX = (
        <Box
          component="span"
          className="tw-opacity-30 tw-italic"
        >
          {rLIB('Missing')}
        </Box>
      )
      let editableFieldJSX = (
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {getProp(object, propKey, missingJSX)}
        </Typography>
      )
      if (readOrWrite === 'write') {
        editableFieldJSX = (
          <Box className="tw-inline-block">
            <DirectParagraphEdit
              displayText={editableFieldJSX}
              fullyClickable={false}
              text={getProp(object, propKey, '')}
              textCssClassName="tw-font-bold tw-w-full"
              onEnter={(value: string) => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: value,
                      }
                      if (value == '') {
                        updateObject = {
                          [propKey]: null,
                        }
                      }
                      DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, masterDataItemKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                })
              }}
            />
          </Box>
        )
      }
      // Return
      return (
        <Box className="tw-mb-1 tw-ml-4">
          <Typography
            variant="body1"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          >
            {label}:
          </Typography>
          {editableFieldJSX}
        </Box>
      )
    }
    const rJSX_DirectlyEditableNumberLineItem = (label: string | JSX.Element, propKey: string, object: TsInterface_UnspecifiedObject): JSX.Element => {
      if (getProp(object, propKey, null) === '') {
        object[propKey] = null
      }
      let missingJSX = (
        <Box
          component="span"
          className="tw-opacity-30 tw-italic"
        >
          {rLIB('Missing')}
        </Box>
      )
      let editableFieldJSX = (
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {getProp(object, propKey, missingJSX)}
        </Typography>
      )
      if (readOrWrite === 'write') {
        editableFieldJSX = (
          <Box className="tw-inline-block">
            <DirectNumberEdit
              displayText={editableFieldJSX}
              fullyClickable={false}
              number={getProp(object, propKey, 0)}
              textCssClassName="tw-font-bold tw-w-full"
              onEnter={(value: number) => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: value,
                      }
                      // @ts-ignore
                      if (value == '') {
                        updateObject = {
                          [propKey]: null,
                        }
                      }
                      DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, masterDataItemKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                })
              }}
            />
          </Box>
        )
      }
      // Return
      return (
        <Box className="tw-mb-1 tw-ml-4">
          <Typography
            variant="body1"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          >
            {label}:
          </Typography>
          {editableFieldJSX}
        </Box>
      )
    }
    const rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions = (
      label: string | JSX.Element,
      propKey: string,
      object: TsInterface_UnspecifiedObject,
      options: tsI_MultipleChoiceOption[],
    ): JSX.Element => {
      if (getProp(object, propKey, null) === '') {
        object[propKey] = null
      }
      let missingJSX = (
        <Box
          component="span"
          className="tw-opacity-30 tw-italic"
        >
          {rLIB('Missing')}
        </Box>
      )
      let editableFieldJSX = (
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {getProp(object, propKey, missingJSX)}
        </Typography>
      )
      if (readOrWrite === 'write') {
        editableFieldJSX = (
          <Box className="tw-inline-block">
            <DirectMultipleChoiceEdit
              displayText={editableFieldJSX}
              fullyClickable={false}
              selectedOption={{ key: getProp(object, propKey, ''), value: getProp(object, propKey, '') }}
              textCssClassName="tw-font-bold tw-w-full"
              optionType={'static'}
              options={options}
              onEnter={(selectedOption: TsInterface_UnspecifiedObject) => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        [propKey]: selectedOption.value,
                      }
                      if (selectedOption.value == '') {
                        updateObject = {
                          [propKey]: null,
                        }
                      }
                      DatabaseSetMergeDocument(documentRef(res_GCK.clientKey, masterDataItemKey), updateObject)
                        .then(() => {
                          resolve(true)
                        })
                        .catch((rej_DSD) => {
                          reject(rej_DSD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                })
              }}
            />
          </Box>
        )
      }
      // Return
      return (
        <Box className="tw-mb-1 tw-ml-4">
          <Typography
            variant="body1"
            className="tw-inline-block tw-mr-2 tw-opacity-40"
          >
            {label}:
          </Typography>
          {editableFieldJSX}
        </Box>
      )
    }
    // Detail Line Item
    const rJSX_DetailLineItem = (item: TsInterface_UnspecifiedObject): JSX.Element => {
      let lineItemJSX = null
      switch (item.type) {
        case 'header':
          lineItemJSX = (
            <Box key={item.key}>
              <Typography variant="h6">{item.label}</Typography>
            </Box>
          )
          break
        case 'editable_text':
          lineItemJSX = <Box>{rJSX_DirectlyEditableTextLineItem(item.label, item.key, us_dataItem)}</Box>
          break
        case 'editable_text_multiline':
          lineItemJSX = <Box>{rJSX_DirectlyEditableParagraphLineItem(item.label, item.key, us_dataItem)}</Box>
          break
        case 'editable_number':
          lineItemJSX = <Box>{rJSX_DirectlyEditableNumberLineItem(item.label, item.key, us_dataItem)}</Box>
          break
        case 'editable_multiple_choice_static':
          lineItemJSX = <Box>{rJSX_DirectlyEditableMultipleChoiceLineItemStaticOptions(item.label, item.key, us_dataItem, getProp(item, 'options', []))}</Box>
          break
        case 'non_editable_text':
          lineItemJSX = (
            <Stack
              key={item.key}
              className="tw-ml-4"
              direction="row"
              spacing={1}
            >
              <Box className="tw-opacity-30">{item.label}:</Box>
              <Box>{getProp(us_dataItem, item.key, rJSX_MissingText())}</Box>
            </Stack>
          )
          break
        case 'non_editable_text_array':
          lineItemJSX = (
            <Stack
              key={item.key}
              className="tw-ml-4"
              direction="row"
              spacing={1}
            >
              <Box className="tw-opacity-30">{item.label}:</Box>
              <Box>{Array.isArray(getProp(us_dataItem, item.key, [])) ? getProp(us_dataItem, item.key, []).join(', ') : rJSX_MissingText()}</Box>
              <Json data={getProp(us_dataItem, item.key, [])} />
            </Stack>
          )
          break
        case 'non_editable_date':
          lineItemJSX = (
            <Stack
              key={item.key}
              className="tw-ml-4"
              direction="row"
              spacing={1}
            >
              <Box className="tw-opacity-30">{item.label}:</Box>
              <Box>{getProp(us_dataItem, item.key, null) ? new Date(getProp(us_dataItem, item.key, null)).toLocaleDateString() : rJSX_MissingText()}</Box>
            </Stack>
          )
          break
        case 'custom_jsx':
          lineItemJSX = (
            <Stack
              key={item.key}
              className="tw-ml-4"
              direction="row"
              spacing={1}
            >
              <Box className="tw-opacity-30">{item.label}:</Box>
              <Box>
                {item.renderCustomViewJSX(us_dataItem, readOrWrite, {
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_setUserInterface_ConfirmDialogDisplay,
                  uc_setUserInterface_ErrorDialogDisplay,
                  uc_setUserInterface_FormDialogDisplay,
                  uc_setUserInterface_PromptDialogDisplay,
                })}
              </Box>
            </Stack>
          )
          break
        default:
          lineItemJSX = <Json data={item} />
      }
      return lineItemJSX
    }
    // Tabs
    let dialogTabs: TsInterface_TabContentArray = [
      {
        tabHeader: rLIB('Details'),
        tabContent: (
          <Box className="tw-p-2">
            {objectToArray(detailsConfig).map((item: TsInterface_UnspecifiedObject, index: number) => {
              return <Box key={index}>{rJSX_DetailLineItem(item)}</Box>
            })}
          </Box>
        ),
      },
      {
        tabHeader: rLIB('Import Aliases'),
        tabContent: (
          <Box className="tw-p-2">
            <Button
              variant="contained"
              color="success"
              className="tw-ml-4 tw-mb-2"
              startIcon={<Icon icon="circle-plus" />}
              onClick={() => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    if (res_GCK.clientKey != null) {
                      createImportAlias(
                        uc_setUserInterface_PromptDialogDisplay,
                        uc_setUserInterface_ErrorDialogDisplay,
                        res_GCK.clientKey,
                        masterDataItemKey,
                        getProp(us_dataItem, 'name', null),
                        importAliasesRefs.importAliasesDocumentRef,
                        collectionRef,
                      )
                    }
                  })
                  .catch(() => {
                    console.error('Error getting client key')
                  })
              }}
            >
              {rLIB('Add Import Alias')}
            </Button>
            {objectToArray(us_dataItemImportAliases).map((item: TsInterface_UnspecifiedObject, index: number) => {
              return (
                <Box
                  key={index}
                  className="tw-pl-4"
                >
                  <Stack
                    direction="row"
                    spacing={1}
                  >
                    <Icon
                      icon="thumbtack"
                      className="tw-mt-2"
                      sx={{ color: themeVariables.success_main }}
                    />
                    <Typography variant="h6">{item.name}</Typography>
                    <Icon
                      className="tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100 hover:tw-text-error_main tw-ml-2 tw-align-top tw-mt-2"
                      icon="trash"
                      tooltip={rLIB('Delete')}
                      tooltipPlacement="right"
                      onClick={() => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            deleteImportAlias(res_GCK.clientKey, item.key, uc_setUserInterface_ConfirmDialogDisplay, importAliasesRefs.importAliasesDocumentRef)
                          })
                          .catch(() => {
                            console.error('Error getting client key')
                          })
                      }}
                    />
                  </Stack>
                </Box>
              )
            })}
            <Box>
              {objectToArray(us_dataItemImportAliases).length === 0 && <Box className="tw-ml-4 tw-opacity-50 tw-italic">{rLIB('No import aliases')}</Box>}
            </Box>
          </Box>
        ),
      },
    ]
    if (
      forumRefs != null &&
      forumRefs['forumThreadCollectionDatabaseEndpoint'] != null &&
      forumRefs['forumThreadDocumentDatabaseEndpoint'] != null &&
      forumRefs['forumMessageCollectionDatabaseEndpoint'] != null &&
      forumRefs['forumMessageDocumentDatabaseEndpoint'] != null &&
      forumRefs['forumMessageStorageEndpoint'] != null
    ) {
      dialogTabs.push({
        tabHeader: rLIB('Notes'),
        tabContent: (
          <Box className="tw-p-4">
            <ForumBasic
              forumThreadCollectionDatabaseEndpoint={(clientKey) => {
                return forumRefs['forumThreadCollectionDatabaseEndpoint'](clientKey, masterDataItemKey)
              }}
              forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
                return forumRefs['forumThreadDocumentDatabaseEndpoint'](clientKey, masterDataItemKey, threadKey)
              }}
              forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
                return forumRefs['forumMessageCollectionDatabaseEndpoint'](clientKey, masterDataItemKey, threadKey)
              }}
              forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
                return forumRefs['forumMessageDocumentDatabaseEndpoint'](clientKey, masterDataItemKey, threadKey, messageKey)
              }}
              forumMessageStorageEndpoint={(clientKey, threadKey, messageKey, fileName) => {
                return forumRefs['forumMessageStorageEndpoint'](clientKey, masterDataItemKey, threadKey, fileName)
              }}
              forumSettings={{
                allow_thread_creation: true,
                scrollbar_track_color: '#272b33',
                scrollbar_thumb_color: '#888',
                input_text_color: '#fff',
                base_message_container_offset_px: 336,
              }}
            />
          </Box>
        ),
      })
    }
    if (
      filesRefs != null &&
      filesRefs['fileSystemCollectionDatabaseEndpoint'] != null &&
      filesRefs['fileSystemDocumentDatabaseEndpoint'] != null &&
      filesRefs['fileSystemStorageEndpoint'] != null
    ) {
      dialogTabs.push({
        tabHeader: rLIB('Files'),
        tabContent: (
          <Box className="tw-p-4">
            <FileSystemBasic
              fileSystemCollectionDatabaseEndpoint={(clientKey) => {
                return filesRefs['fileSystemCollectionDatabaseEndpoint'](clientKey, masterDataItemKey)
              }}
              fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
                return filesRefs['fileSystemDocumentDatabaseEndpoint'](clientKey, masterDataItemKey, fileKey)
              }}
              fileSystemStorageEndpoint={(clientKey, fileName) => {
                return filesRefs['fileSystemStorageEndpoint'](clientKey, masterDataItemKey, fileName)
              }}
              fileSystemHardCodedFolders={{}}
              fileSystemSettings={{
                allow_file_archiving: true,
                allow_file_movement: true,
                allow_file_unarchiving: true,
                allow_file_uploads: true,
                allow_folder_creation: true,
                allow_folder_deletion: true,
                allow_folder_movement: true,
                allow_folder_rename: true,
                archive_filter_visible: true,
              }}
            />
          </Box>
        ),
      })
    }
    us_setDialogTabs(dialogTabs)
  }, [
    detailsConfig,
    documentRef,
    filesRefs,
    forumRefs,
    masterDataItemKey,
    readOrWrite,
    uc_RootData_ClientKey,
    uc_setRootData_ClientKey,
    uc_setUserInterface_ConfirmDialogDisplay,
    uc_setUserInterface_ErrorDialogDisplay,
    uc_setUserInterface_FormDialogDisplay,
    uc_setUserInterface_PromptDialogDisplay,
    us_dataItem,
    us_dataItemImportAliases,
    importAliasesRefs,
    collectionRef,
  ])

  // Functions

  // JSX Generation
  const rJSX_DeletedBanner = (): JSX.Element => {
    if (getProp(us_dataItem, 'status', null) === 'deleted') {
      return (
        <Box
          className="tw-text-center tw-px-2 tw-py-1 tw-rounded tw-mt-2 tw-mx-2"
          sx={{ backgroundColor: themeVariables.error_main }}
        >
          <Icon
            icon="trash"
            className="tw-mr-2"
          />
          {rLIB('Deleted')}
        </Box>
      )
    }
    return <></>
  }

  const rJSX_MissingText = (): JSX.Element => {
    return <Box className="tw-opacity-50">Missing Data</Box>
  }

  const rJSX_UpdateStatusButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (readOrWrite === 'write') {
      if (
        getProp(us_dataItem, 'status', null) === 'active' &&
        masterDataAllProps != null &&
        masterDataAllProps.full_exports != null &&
        masterDataAllProps.full_exports.deleteItem != null
      ) {
        buttonJSX = (
          <Button
            color="inherit"
            variant="outlined"
            startIcon={<Icon icon="trash" />}
            onClick={() => {
              uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'error',
                  icon: <Icon icon="trash" />,
                  header: rLIB('Delete Database Item'),
                  text: rLIB('Are you sure that you want to delete this database item?'),
                  submit_text: rLIB('Delete'),
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          if (res_GCK.clientKey != null) {
                            masterDataAllProps.full_exports
                              .deleteItem(res_GCK.clientKey, masterDataItemKey)
                              .then(() => {
                                resolve(true)
                              })
                              .catch(() => {
                                reject({})
                              })
                          }
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
              })
            }}
          >
            {rLIB('Delete')}
          </Button>
        )
      } else if (
        getProp(us_dataItem, 'status', null) === 'deleted' &&
        masterDataAllProps != null &&
        masterDataAllProps.full_exports != null &&
        masterDataAllProps.full_exports.undeleteItem != null
      ) {
        buttonJSX = (
          <Button
            color="inherit"
            variant="outlined"
            startIcon={<Icon icon="magic-wand-sparkles" />}
            onClick={() => {
              uc_setUserInterface_ConfirmDialogDisplay({
                display: true,
                confirm: {
                  color: 'warning',
                  icon: <Icon icon="magic-wand-sparkles" />,
                  header: rLIB('Undelete Database Item'),
                  text: rLIB('Are you sure that you want to undelete this database item?'),
                  submit_text: rLIB('Undelete'),
                  submit_callback: () => {
                    return new Promise((resolve, reject) => {
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          if (res_GCK.clientKey != null) {
                            masterDataAllProps.full_exports
                              .undeleteItem(res_GCK.clientKey, masterDataItemKey)
                              .then(() => {
                                resolve(true)
                              })
                              .catch(() => {
                                reject({})
                              })
                          }
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
              })
            }}
          >
            {rLIB('Undelete')}
          </Button>
        )
      }
    }
    return buttonJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_full_width"
          // className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="database" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">{getProp(us_dataItem, 'name', '')}</Box>
              </Typography>
              {rJSX_UpdateStatusButton()}
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            {rJSX_DeletedBanner()}
            <TabsBasic
              tabs={us_dialogTabs}
              tabsSettings={{}}
            />
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Exports
///////////////////////////////

export const openCreateDataItemDialog = null

export const openEditDataItemDialog = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  textOptions: TsInterface_UnspecifiedObject,
  formInputs_EditItem: TsInterface_FormInputs,
  updateFunction: (clientKey: string, itemKey: string, formSubmittedData: TsInterface_FormSubmittedData) => Promise<unknown>,
) => {
  tableHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: rowData,
        formInputs: formInputs_EditItem,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                updateFunction(res_GCK.clientKey, rowData.key as string, formSubmittedData)
                  .then((res_EM) => {
                    resolve(res_EM)
                  })
                  .catch((rej_EM) => {
                    reject(rej_EM)
                  })
              })
              .catch((rej_GCK) => {
                reject(rej_GCK)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: textOptions.edit_item_text,
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

export const openDeleteDataItemDialog = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  textOptions: TsInterface_UnspecifiedObject,
  deleteFunction: (clientKey: string, itemKey: string) => Promise<unknown>,
) => {
  tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'error',
      header: textOptions.delete_item_text,
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      submit_text: rLIB('Delete'),
      text: textOptions.confirm_delete_text,
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              deleteFunction(res_GCK.clientKey, rowData.key as string)
                .then((res_DF) => {
                  resolve(res_DF)
                })
                .catch((rej_DF) => {
                  reject(rej_DF)
                })
            })
            .catch((rej_GCK) => {
              reject(rej_GCK)
            })
        })
      },
    },
  })
}

export const openUndeleteDataItemDialog = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  textOptions: TsInterface_UnspecifiedObject,
  undeleteFunction: (clientKey: string, itemKey: string) => Promise<unknown>,
) => {
  tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'warning',
      header: textOptions.undelete_item_text,
      icon: (
        <Icon
          icon="magic-wand-sparkles"
          type="solid"
        />
      ),
      submit_text: rLIB('Undelete'),
      text: textOptions.confirm_undelete_text,
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              undeleteFunction(res_GCK.clientKey, rowData.key as string)
                .then((res_DF) => {
                  resolve(res_DF)
                })
                .catch((rej_DF) => {
                  reject(rej_DF)
                })
            })
            .catch((rej_GCK) => {
              reject(rej_GCK)
            })
        })
      },
    },
  })
}

export const getMasterDataList = (clientType: string, userRole: string) => {
  let combinedKey = clientType + '_' + userRole

  // DatabaseRef_ClientUser_Document

  let visibleMasterDataList: TsInterface_UnspecifiedObject = {}

  switch (combinedKey) {
    case 'Sales_admin':
      visibleMasterDataList = {
        finance_partners: masterDataList.finance_partners,
        // financing_types: masterDataList.financing_types,
        // lead_sources: masterDataList.lead_sources,
        install_partners: masterDataList.install_partners,
        opportunity_milestones: masterDataList.opportunity_milestones,
        regions: masterDataList.regions,
        sales_partners: masterDataList.sales_partners,
        // sales_partner_adders: masterDataList.sales_partner_adders,
        // sales_partner_discounts: masterDataList.sales_partner_discounts,
        // sales_partner_incentives: masterDataList.sales_partner_incentives,
        // sales_partner_sow: masterDataList.sales_partner_sow,
        // system_inverters: masterDataList.system_inverters,
        // system_modules: masterDataList.system_modules,
        // system_mount_types: masterDataList.system_mount_types,
        // system_product_packages: masterDataList.system_product_packages,
        // system_racking: masterDataList.system_racking,
        // system_storage: masterDataList.system_storage,
      }
      break
    case 'Installer_admin':
      visibleMasterDataList = masterDataList
      break
    case 'Installer_subcontractor_design':
      visibleMasterDataList = {
        jurisdictions: masterDataList.jurisdictions,
      }
      break
    default:
      visibleMasterDataList = {}
      break
  }

  return visibleMasterDataList
}

export const openMasterDataDialog = (
  documentRef: any,
  collectionRef: any,
  itemKey: string,
  detailsConfig: TsInterface_UnspecifiedObject,
  importAliasesRefs: TsInterface_MasterDataImportAliasRefs,
  forumRefs: TsInterface_MasterDataForumRefs,
  filesRefs: TsInterface_MasterDataFilesRefs,
  uc_setUserInterface_CustomDialogDisplay: any,
  masterDataAllProps: TsInterface_UnspecifiedObject,
) => {
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <MasterDataViewDialog
          masterDataItemKey={itemKey}
          documentRef={documentRef}
          collectionRef={collectionRef}
          detailsConfig={detailsConfig}
          importAliasesRefs={importAliasesRefs}
          forumRefs={forumRefs}
          filesRefs={filesRefs}
          readOrWrite={'write'} // TODO:
          masterDataAllProps={masterDataAllProps}
        />
      ),
      settings: { max_width: 'lg' },
    },
  })
}
