///////////////////////////////
// Imports
///////////////////////////////

import { returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const projectExportSpreadsheetColumns = [
  {
    key: 'id_number',
    label: 'Job Code',
  },
  {
    key: 'associated_customer_name',
    label: 'Customer Name',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'associated_task_workflow_name',
    label: 'Workflow',
  },
  {
    key: 'associated_customer_name',
    label: 'Project Start Date',
    valueDetermination: (project: TsInterface_UnspecifiedObject) => {
      let value = ''
      if (
        project != null &&
        project['associated_task_workflow_key'] != null &&
        project['timestamp_workflow_selection'] != null &&
        project['timestamp_workflow_selection'][project['associated_task_workflow_key']] != null
      ) {
        value = returnFormattedDate(project['timestamp_workflow_selection'][project['associated_task_workflow_key']], 'D MMM YY')
      }
      return value
    },
  },
  {
    key: 'associated_customer_phone',
    label: 'Phone',
  },
  {
    key: 'associated_customer_additional_phone',
    label: 'Additional Contact Details',
  },
  {
    key: 'associated_customer_email',
    label: 'Email',
  },
  {
    key: 'location_address',
    label: 'Address',
  },
  {
    key: 'location_city',
    label: 'City',
  },
  {
    key: 'location_county',
    label: 'County',
  },
  {
    key: 'location_zip',
    label: 'Zip',
  },
  {
    key: 'location_state',
    label: 'State',
  },
  {
    key: 'location_latitude',
    label: 'Latitude',
  },
  {
    key: 'location_longitude',
    label: 'Longitude',
  },
  {
    key: 'location_access_instructions',
    label: 'Gate Code / Access Instructions',
  },
  {
    key: 'associated_region_name',
    label: 'Region',
  },
  {
    key: 'associated_jurisdiction_name',
    label: 'Jurisdiction',
  },
  {
    key: 'associated_hoa_name',
    label: 'HOA',
  },
  {
    key: 'associated_utility_company_name',
    label: 'Utility Company',
  },
  {
    key: 'location_distance_from_warehouse',
    label: 'Distance from warehouse',
  },
  {
    key: 'associated_sales_partner_name',
    label: 'Sales Partner',
  },
  {
    key: 'associated_sales_rep_name',
    label: 'Sales Rep Name',
  },
  {
    key: 'associated_sales_rep_phone',
    label: 'Sales Rep Phone',
  },
  {
    key: 'associated_sales_rep_email',
    label: 'Sales Rep Email',
  },
  {
    key: 'associated_sales_rep_team_name',
    label: 'Sales Rep Team',
  },
  {
    key: 'financials_financing_type',
    label: 'Financing Type',
  },
  {
    key: 'associated_finance_partner_name',
    label: 'Finance Partner',
  },
  // {
  //   key: 'financials_solar_epc_amount',
  //   label: 'Solar EPC Amount',
  // },
  // {
  //   key: 'financials_loan_amount',
  //   label: 'Loan Amount',
  // },
  // {
  //   key: 'financials_apr',
  //   label: 'APR',
  // },
  // {
  //   key: 'financials_loan_term',
  //   label: 'Loan Term',
  // },
  // {
  //   key: 'financials_solar_plus_battery_epc_amount',
  //   label: 'Total Solar + Battery EPC Amount',
  // },
  // {
  //   key: 'financials_solar_rate',
  //   label: 'Solar Rate',
  // },
  // {
  //   key: 'financials_escalator',
  //   label: 'Escalator',
  // },
  // {
  //   key: 'financials_est_y1_solar_monthly_payment',
  //   label: 'Est. Y1 Solar Monthly Payment',
  // },
  // {
  //   key: 'financials_est_y1_battery_monthly_payment',
  //   label: 'Est. Y1 Battery Monthly Payment',
  // },
  // {
  //   key: 'financials_loan_term',
  //   label: 'Loan Term',
  // },
  // {
  //   key: 'financials_dealer_fee',
  //   label: 'Dealer Fee',
  // },
  // {
  //   key: 'financials_dealer_fee_percentage',
  //   label: 'Dealer Fee (%)',
  // },
  {
    key: 'associated_product_name',
    label: 'Product Package',
  },
  {
    key: 'system_size_dc',
    label: 'System Size DC',
  },
  {
    key: 'system_size_ac',
    label: 'System Size AC',
  },
  {
    key: 'system_estimated_annual_production',
    label: 'Est. Y1 Production',
  },
  {
    key: 'system_storage_total_kwh',
    label: 'Total Storage',
  },
  {
    key: 'system_panel_quantity',
    label: 'Module Quantity',
  },
  {
    key: 'associated_system_module_name',
    label: 'Module',
  },
  {
    key: 'system_inverter_quantity',
    label: 'Inverter Quantity',
  },
  {
    key: 'associated_system_inverter_name',
    label: 'Inverter',
  },
  {
    key: 'system_racking_quantity',
    label: 'Racking Quantity',
  },
  {
    key: 'associated_system_racking_name',
    label: 'Racking',
  },
  {
    key: 'system_storage_quantity',
    label: 'Storage Quantity',
  },
  {
    key: 'associated_system_storage_name',
    label: 'Storage',
  },
  {
    key: 'associated_system_accessories',
    label: 'Accessories',
    valueDetermination: (project: TsInterface_UnspecifiedObject) => {
      let value = ''
      for (let loopAccessoryKey in project['associated_system_accessories']) {
        let loopAccessory = project['associated_system_accessories'][loopAccessoryKey]
        value += loopAccessory['quantity'] + ' ' + loopAccessory['name'] + ', '
      }
      return value
    },
  },
  // {
  //   key: 'home_building_type',
  //   label: 'Home Type',
  // },
  // {
  //   key: 'associated_mount_type_name',
  //   label: 'Roof/Mount Type',
  // },
  {
    key: 'system_max_roof_pitch',
    label: 'Max Roof Pitch',
  },
  // {
  //   key: 'home_number_of_stories',
  //   label: 'Number of Stories',
  // },
  {
    key: 'system_number_of_arrays',
    label: 'Number of Arrays',
  },
  {
    key: 'system_number_of_strings',
    label: 'Number of Strings',
  },
  // {
  //   key: 'home_design_notes',
  //   label: 'Design Notes',
  // },
  {
    key: 'system_has_attic_run',
    label: 'Attic Run',
  },
  // {
  //   key: 'home_paint_conduit',
  //   label: 'Paint Conduit',
  // },
  // {
  //   key: 'home_minimize_panels_at_front_of_house',
  //   label: 'Minimize panels at front of house',
  // },
  {
    key: 'system_usage_offset',
    label: 'Usage Offset',
  },
  // {
  //   key: 'home_other_customer_requests',
  //   label: 'Other Requests',
  // },
]
